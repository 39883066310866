import './assets/styles/SelectField.scss';

import { ChevronDownIcon, ChevronUpIcon } from '@elseu/sdu-titan';
import classNames from 'classnames';
import SelectFieldOption from 'components/selectfield/SelectFieldOption';
import React, { useMemo, useState } from 'react';
import Select, { components } from 'react-select';
import Async from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import Creatable from 'react-select/creatable';
// exports
export { components } from 'react-select';

const selectFontSize = 16;

const SingleValue = (props: any) => {
  const { data, children } = props;
  return (
    <components.SingleValue {...props}>
      {data && (data.icon || data.image) && <img alt="" src={data.icon || data.image} />}
      {children}
    </components.SingleValue>
  );
};

const getStyles = (styles: any, focused: boolean, disabled: boolean) => ({
  menuPortal: (provided: any) => ({
    ...provided,
    zIndex: 99999999,
  }),
  input: (provided: any) => ({
    ...provided,
    // display: 'inline-flex',
    fontSize: selectFontSize,
  }),
  control: (provided: any) => ({
    ...provided,
    opacity: disabled ? '0.5' : '1',
    borderRadius: '2px',
    borderWidth: '1px',
    fontSize: selectFontSize,
    minHeight: '40px',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '5px',
    lineHeight: '1',
    minHeight: '40px',
    fontSize: selectFontSize,
    marginBottom: '0px',
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    marginTop: 0,
    marginBottom: 0,
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
  }),
  multiValue: (provided: any) => ({
    ...provided,
    overflow: 'hidden',
    borderRadius: '2px',
    margin: '2px 4px',
    verticalAlign: 'middle',
    fontSize: selectFontSize,
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    cursor: 'pointer',
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: 'transparent',
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: '999999999',
    overflow: 'hidden',
    fontSize: 12,
    backgroundColor: '#ffffff',
    border: '1px solid #ebeaea',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.08);',
    borderRadius: '2px',
  }),
  menuList: (provided: any) => ({
    ...provided,
    margin: '0',
    padding: '0',
    fontSize: selectFontSize,
  }),
  option: (provided: any) => ({
    ...provided,
    padding: '10px',
    fontSize: selectFontSize,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    padding: '4px 4px',
    fontSize: selectFontSize,
  }),
  singleValue: (provided: any) => ({
    ...provided,
    lineHeight: '20px',
  }),
  ...styles,
});

const getTheme = (theme: any) => ({
  ...theme,
  borderRadius: '2px',
  colors: {
    ...theme.colors,
  },
});

export type SelectFieldProps = {
  selectRef?: any;
  isDisabled?: boolean;
  isReadyOnly?: boolean | undefined;
  className?: string;
  value?: any;
  isMulti?: boolean;
  placeholder?: string;
  options?: any[];
  optionRenderer?: (props: any) => React.ReactElement;
  error?: string | boolean;
  isLoading?: boolean;
  isCreatable?: boolean;
  isSearchable?: boolean;
  styles?: any;
  formatCreateLabel?: () => null;
  onChange: (value: any, action: any) => void;
  shouldBlurInputOnSelect?: boolean;
  shouldHideSelectedOptions?: boolean;
  isClearable?: boolean;
  onCreate?: (val: any) => void;
  isAsync?: boolean;
  componentPlaceholder?: any;
  requestOptions?: (value: string, callback: (results: []) => void) => void;
  requestOptionsDelay?: number;
  requestOptionsPromise?: (value: string) => Promise<any[]>;
  isMenuPortalActive?: boolean;
  noResultsMessage?: string;
  componentInput?: any;
  componentMultiValueLabel?: any;
  componentSingleValue?: any;
  onFocus?: () => void;
  onBlur?: () => void;
  [key: string]: any;
};

export type SelectFieldOptionType = {
  value: string;
  label: string;
  subtitle?: string;
  image?: string;
  item?: any;
};

/**
 * SelectField
 */
const SelectField: React.FC<SelectFieldProps> = ({
  selectRef,
  isDisabled = false,
  className,
  value,
  isMulti = false,
  placeholder = 'Selecteer ...',
  options = [],
  optionRenderer = (props: any) => (
    <SelectFieldOption
      icon={
        props.data.icon || props.data.image ? (
          <img alt="" src={props.data.icon || props.data.image} />
        ) : undefined
      }
      subtitle={props.data.subtitle || undefined}
    >
      {props.children}
    </SelectFieldOption>
  ),
  error,
  isMenuPortalActive = true,
  isLoading,
  isCreatable,
  isSearchable,
  isClearable = false,
  isReadyOnly = false,
  shouldBlurInputOnSelect = true,
  shouldHideSelectedOptions = false,
  styles = {},
  formatCreateLabel = (value: any) => {
    return (
      <>
        Maak{' '}
        <i>
          <strong>{value}</strong>
        </i>{' '}
        aan
      </>
    );
  },
  onChange,
  onCreate,
  isAsync,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  requestOptions = () => {},
  requestOptionsDelay = 100,
  requestOptionsPromise,
  noResultsMessage = 'Geen resultaten',
  componentPlaceholder,
  componentInput,
  componentMultiValueLabel,
  componentSingleValue,
  onFocus,
  onBlur,
  ...otherProps
}) => {
  // manually checking focus because some select elements don't support the isFocus state.
  const [focused, setFocussed] = useState(false);
  // eslint-disable-next-line no-nested-ternary
  const Component = isCreatable
    ? isAsync
      ? AsyncCreatableSelect
      : Creatable
    : isAsync
    ? Async
    : Select;

  // eslint-disable-next-line react/no-unstable-nested-components
  const Option = (props: any) => (
    <components.Option {...props}>{optionRenderer(props)}</components.Option>
  );

  const otherComponents = useMemo(() => {
    return {
      SingleValue: componentSingleValue || SingleValue,
      MultiValueLabel: componentMultiValueLabel || components.MultiValueLabel,
      Placeholder: componentPlaceholder || components.Placeholder,
      Input: componentInput || components.Input,
      DropdownIndicator: (props: any) => {
        const { selectProps } = props;
        const chevron = selectProps.menuIsOpen ? <ChevronUpIcon /> : <ChevronDownIcon />;
        return <components.DropdownIndicator {...props}>{chevron}</components.DropdownIndicator>;
      },
    };
  }, [componentPlaceholder, componentInput, componentMultiValueLabel, componentSingleValue]);

  let syncDelayTimeout: any = null;

  const loadOptionsWithDelay = (completeValue: any, callback: (results: []) => void) => {
    // set timeout before send the callback. (The prevents multiple calls at once.)
    // clear the current timeout if it is already set.
    if (syncDelayTimeout !== null) {
      clearTimeout(syncDelayTimeout);
    }

    syncDelayTimeout = setTimeout(
      () => requestOptions(completeValue, callback),
      requestOptionsDelay,
    );
  };

  if (isMenuPortalActive) {
    otherProps.menuPortalTarget = document.body;
  }

  const extraProps = {
    readOnly: isReadyOnly,
    shouldHideSelectedOptions,
  };

  const compEl = React.createElement(Component, {
    ...otherProps,
    ref: selectRef,
    cacheOptions: true,
    defaultOptions: true,
    className: 'c-select-field__component',
    classNamePrefix: 'c-select-field__component__select',
    components: { Option, ...otherComponents },
    placeholder,
    value,
    isMulti,
    isLoading,
    isSearchable,
    isDisabled,
    isClearable,
    options,
    blurInputOnSelect: shouldBlurInputOnSelect,
    styles: getStyles(styles, focused, isDisabled),
    theme: getTheme,
    onFocus: () => {
      setFocussed(true);
      if (onFocus) {
        onFocus();
      }
    },
    onBlur: () => {
      setFocussed(false);
      if (onBlur) {
        onBlur();
      }
    },
    onChange: (val: any, action: any) => {
      onChange(val, action);
    },
    noOptionsMessage: () => noResultsMessage,
    onCreateOption: onCreate,
    formatCreateLabel,
    loadOptions: requestOptionsPromise ?? loadOptionsWithDelay,
    ...extraProps,
  });

  return (
    <div
      className={classNames(className, 'c-select-field', {
        'c-select-field--disabled': isDisabled,
        'c-select-field--error': error,
      })}
    >
      {compEl}
    </div>
  );
};

export default SelectField;
