import type { InputProps } from '@elseu/sdu-titan';
import { Input } from '@elseu/sdu-titan';
import { formFieldErrorProps } from 'components/formfields/helpers/formFieldErrorProps';
import type { FocusEvent } from 'react';
import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import type { FormValueTransform } from './helpers/defaultTransform';
import { defaultTransform } from './helpers/defaultTransform';

export interface InputFieldProps extends InputProps {
  label: string;
  name: string;
  transform?: FormValueTransform;
  isErrorHidden?: boolean;
  rules?: Record<string, any>;
}

export const InputField = ({
  name,
  transform = defaultTransform,
  isErrorHidden = false,
  rules,
  onBlur,
  spaceAfter = 4,
  ...props
}: InputFieldProps) => {
  const { control } = useFormContext();

  const handleBlur = useCallback(
    (handler: (e: FocusEvent) => void, event: FocusEvent<HTMLInputElement, Element>) => {
      handler(event);
      onBlur?.(event);
    },
    [onBlur],
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onBlur, onChange, ...field }, formState: { errors } }) => (
        <Input
          {...formFieldErrorProps(errors, name, isErrorHidden)}
          autoComplete="off"
          spaceAfter={spaceAfter}
          spellCheck="false"
          value={transform.input(value)}
          onBlur={(event) => handleBlur(onBlur, event)}
          onInputChange={(value) => onChange(transform.output(value))}
          {...props}
          {...field}
        />
      )}
      rules={rules}
    />
  );
};
