import Actions from 'actions/Actions';
import OwnTool from 'models/dossier/OwnTool';
import { createHook, createStore } from 'react-sweet-state';
import sweetFetchState from 'stores/utils/SweetFetchState';
import sweetPaging from 'stores/utils/SweetPagingState';

// state keys
export const STATE_OWN_TOOLS = 'ownTools';
export const STATE_OWN_TOOLS_SELECT = 'ownToolsSelect';
export const STATE_OWN_TOOLS_CREATE = 'ownToolsCreate';
export const STATE_OWN_TOOLS_UPDATE = 'ownToolsUpdate';
export const STATE_OWN_TOOLS_DELETE = 'ownToolsDelete';

/**
 * Own tool store
 */
const Store = createStore({
  name: 'OwnToolsState',
  initialState: {
    [STATE_OWN_TOOLS]: sweetPaging.init(),
    [STATE_OWN_TOOLS_SELECT]: sweetFetchState.init(),
    [STATE_OWN_TOOLS_CREATE]: sweetFetchState.init(),
    [STATE_OWN_TOOLS_UPDATE]: sweetFetchState.init(),
    [STATE_OWN_TOOLS_DELETE]: sweetFetchState.init(),
  },
  actions: {
    /**
     * Reset a fetch state
     */
    resetFetchState: sweetFetchState.reset,

    /**
     * Reset paging state
     *
     * @returns {function(...[*]=)}
     */
    ownToolsReset:
      () =>
      ({ dispatch }) =>
        dispatch(sweetPaging.reset(STATE_OWN_TOOLS)),

    /**
     * Get tools
     *
     * @param {array} filters
     * @param {string} sort
     * @param {number} page
     * @param {number} limit
     * @returns {function(...[*]=)}
     */
    ownTools:
      (filters, sort, page = 1, limit = 25) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy } = sweetPaging.state(
          STATE_OWN_TOOLS,
          (response) => OwnTool.createInstancesByArray(response.data.ownTools),
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(Actions.getAPIService().ownTools(filters, sort, page, limit));
      },

    /**
     * Get more details of a specific tool
     *
     * @param {string} toolId
     * @returns {function(...[*]=)}
     */
    ownToolsDetails:
      (toolId) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy, setContext } = sweetFetchState.withContextId(
          STATE_OWN_TOOLS_SELECT,
          toolId,
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(Actions.getAPIService().toolDetails(toolId)).then(
          (response) => {
            const tool = sweetPaging.getItem(toolId, getState()[STATE_OWN_TOOLS]) || {};
            const newTool = OwnTool.createInstance({
              ...tool,
              ...response.data,
            });
            setContext(newTool);
            dispatch(sweetPaging.addItem(STATE_OWN_TOOLS, newTool));
          },
          () => null,
        );
      },

    /**
     * Create own tool item
     *
     * @param {object} values
     * @param {File} attachment
     * @returns {function(...[*]=)}
     */
    createOwnTool:
      (values, attachment) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy } = sweetFetchState.state(
          STATE_OWN_TOOLS_CREATE,
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(Actions.getAPIService().createTool(values, attachment)).then(
          (response) => {
            const tool = OwnTool.createInstance(response.data);
            dispatch(sweetPaging.addItem(STATE_OWN_TOOLS, tool));
          },
          () => null,
        );
      },

    /**
     * Update own tool item
     *
     * @param {string} toolId
     * @param {object} values
     * @returns {function(...[*]=)}
     */
    updateOwnTool:
      (toolId, values) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy } = sweetFetchState.withContextId(
          STATE_OWN_TOOLS_UPDATE,
          toolId,
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(Actions.getAPIService().updateTool(toolId, values)).then(
          (response) => {
            const tool = OwnTool.createInstance(response.data);
            dispatch(sweetPaging.addItem(STATE_OWN_TOOLS, tool));
          },
          () => null,
        );
      },

    /**
     * Delete own tool item
     *
     * @param {string} toolId
     * @returns {function(...[*]=)}
     */
    deleteOwnTool:
      (toolId) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy } = sweetFetchState.withContextId(
          STATE_OWN_TOOLS_DELETE,
          toolId,
          dispatch,
          getState,
        );
        if (isBusy()) {
          return;
        }

        doRequest(Actions.getAPIService().deleteTool(toolId)).then(
          () => dispatch(sweetPaging.removeItem(STATE_OWN_TOOLS, toolId)),
          () => null,
        );
      },
  },
});

export const useOwnToolsState = createHook(Store);
