import DataModel, { PropTypes } from 'models/base/DataModel';

import StatusGroup from './StatusGroup';

/**
 * Card label
 */
export default class Label extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    name: PropTypes.string,
    color: PropTypes.string,
    position: PropTypes.number,
    statusGroup: PropTypes.shape(StatusGroup.propTypes),
  };

  /**
   * @type {string}
   */
  name = 'Geen status';

  /**
   * @type {string}
   */
  color;

  /**
   * @type {number}
   */
  position;

  /**
   * @type {StatusGroup}
   */
  statusGroup;

  /**
   *
   * @returns {string}
   */
  getName() {
    return this.name;
  }

  /**
   *
   * @returns {string}
   */
  getColor() {
    return this.color;
  }

  /**
   *
   * @returns {number}
   */
  getPosition() {
    return this.position;
  }

  /**
   *
   * @returns {{color: string, label: string, value: string}}
   */
  mapForPhaseSelect() {
    return {
      color: this.getColor(),
      label: this.getName(),
      value: this.getIdentity(),
      statusGroup: this.statusGroup,
    };
  }

  /**
   * Create a card label instance from object
   *
   * @param object
   * @returns {self}
   */
  static createFromPhaseSelectObject(object) {
    return Label.createInstance({
      id: object.value,
      color: object.color,
      name: object.label,
      statusGroup: StatusGroup.createInstance(object.statusGroup),
    });
  }
}
