import { ReactComponent as ClientImageComponent } from 'components/client/assets/images/contacts-filled.svg';
import React from 'react';

import Avatar from './Avatar';

type AvatarClientProps = {
  className?: string;
  size?: number;
  name?: string;
};

/**
 * Avatar component for client
 *
 * @param {string|null} className
 * @param {number|null} size
 * @param {string|null} name
 * @returns
 */
const AvatarClient: React.FC<AvatarClientProps> = ({ className, size, name }) => (
  <Avatar
    hasBackground
    isInline
    isRounded
    avatarComponent={<ClientImageComponent />}
    className={className}
    hasBorder={false}
    name={name}
    size={size}
  />
);

export default AvatarClient;
