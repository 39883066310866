import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
  StyledHTML,
} from '@elseu/sdu-titan';
import React from 'react';

export type ConfirmDialogProps = React.PropsWithChildren<{
  className?: string;
  isDisabled?: boolean;
  isShown?: boolean;
  isConfirmDisabled?: boolean;
  title?: string;
  confirmTitle?: string | React.ReactNode;
  confirmAppearance?: 'primary' | 'secondary' | 'clear' | 'danger';
  cancelAppearance?: 'primary' | 'secondary' | 'clear' | 'danger';
  size?: 's' | 'm' | 'l' | 'xl' | 'full';
  cancelTitle?: string;
  isCloseShown?: boolean;
  zIndex?: number;
  onConfirm: () => void;
  onCancel: () => void;
}>;

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  title = 'Confirm',
  isShown = true,
  isCloseShown = false,
  size = 'm',
  confirmAppearance = 'primary',
  cancelAppearance = 'clear',
  isDisabled,
  isConfirmDisabled,
  confirmTitle = 'OK',
  cancelTitle = 'Annuleren',
  zIndex,
  onCancel,
  onConfirm,
  children,
}) => (
  <Modal
    isCloseable={isCloseShown}
    isShown={isShown}
    size={size}
    zIndex={zIndex}
    onClose={() => onCancel()}
  >
    <ModalHeader hasCloseButton={isCloseShown} title={title} />
    <ModalContent>
      <StyledHTML>{children}</StyledHTML>
    </ModalContent>
    <ModalActions>
      <Button isDisabled={isDisabled} variant={cancelAppearance} onClick={onCancel}>
        {cancelTitle}
      </Button>
      <Button
        isDisabled={isDisabled || isConfirmDisabled}
        variant={confirmAppearance}
        onClick={onConfirm}
      >
        {confirmTitle}
      </Button>
    </ModalActions>
  </Modal>
);

export default ConfirmDialog;
