import React from 'react';

import { ReactComponent } from './assets/svg/OneDriveIcon.svg';
import type { IconComponentProps } from './IconComponent';
import IconComponent from './IconComponent';

const OneDriveIcon = (props: IconComponentProps) => IconComponent(props, <ReactComponent />);

OneDriveIcon.defaultProps = IconComponent.defaultProps;
OneDriveIcon.propTypes = IconComponent.propTypes;

export default OneDriveIcon;
