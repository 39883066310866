import type { ToggleProps } from '@elseu/sdu-titan';
import { Checkbox } from '@elseu/sdu-titan';
import { formFieldErrorProps } from 'components/formfields/helpers/formFieldErrorProps';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import type { FormValueTransform } from './helpers/defaultTransform';
import { defaultTransform } from './helpers/defaultTransform';

export interface CheckboxFieldProps extends ToggleProps {
  label: string;
  name: string;
  transform?: FormValueTransform;
  isErrorHidden?: boolean;
}

export const CheckboxField = ({
  name,
  transform = defaultTransform,
  isErrorHidden = false,
  ...props
}: CheckboxFieldProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onBlur, onChange, ...field }, formState: { errors } }) => (
        <Checkbox
          {...formFieldErrorProps(errors, name, isErrorHidden)}
          isChecked={value || false}
          onChange={(value) => onChange(transform.output(value))}
          {...props}
          {...field}
        />
      )}
    />
  );
};
