import type { QueryClient, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import { createEmptyClient, normalizeClient } from 'entity/crm/normalizer';
import type { Client } from 'entity/crm/types';
import { useMemo } from 'react';

type useClientDetailsReturnType = {
  client: Client;
  isBusy: boolean;
  isSuccess: boolean;
  query: UseQueryResult;
};

export const clientDetailsQueryFunc = async (id: string, withExtraMetaInfo: boolean) => {
  const res = await Actions.getAPIService().getCrm().getClientDetails(id, withExtraMetaInfo);
  return res.isSuccess() ? normalizeClient(res.getData()) : undefined;
};

export const useClientDetails = (
  id: string,
  withExtraMetaInfo = false,
  enabled = true,
): useClientDetailsReturnType => {
  const query = useQuery({
    queryKey: ['client', id, withExtraMetaInfo],
    queryFn: () => clientDetailsQueryFunc(id, withExtraMetaInfo),
    staleTime: 60 * 1000,
    enabled,
  });

  return useMemo(
    () => ({
      client: query.data || createEmptyClient(),
      isBusy: query.isLoading,
      isSuccess: query.isSuccess,
      query,
    }),
    [query],
  );
};

// eslint-disable-next-line max-len
export const getClientDetailsByQueryClient = async (
  id: string,
  queryClient: QueryClient,
): Promise<Client | undefined> => {
  return await queryClient.fetchQuery({
    queryKey: ['client', id],
    queryFn: async () => {
      const res = await Actions.getAPIService().getCrm().getClientDetails(id);
      return res.isSuccess() ? normalizeClient(res.getData()) : undefined;
    },
    staleTime: 60 * 1000, // 1 minute
  });
};
