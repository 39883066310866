import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import { normalizeSignaling } from 'entity/signaling/normalizer';
import type { Signaling } from 'entity/signaling/types';
import { useMemo } from 'react';

type useSignalingDetailsReturnType = {
  signaling?: Signaling;
  query: UseQueryResult;
};

export const useSignalingDetails = (id?: string): useSignalingDetailsReturnType => {
  const query = useQuery({
    queryKey: ['signaling', id],
    queryFn: async () => {
      const res = await Actions.getAPIService()
        .getSignaling()
        .getSignalingDetails(id ?? '');
      return res.isSuccess() ? normalizeSignaling(res.getData()) : undefined;
    },
    staleTime: 60 * 1000,
    enabled: !!id,
  });
  return useMemo(
    () => ({
      signaling: query.data,
      query,
    }),
    [query],
  );
};
