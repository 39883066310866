import DataModel, { PropTypes } from 'models/base/DataModel';
import BerkleyModelSession from 'models/dossier/BerkleySessionModel';
import Client from 'models/dossier/Client';
import CloudAttachment from 'models/dossier/CloudAttachment';
import CloudLink from 'models/dossier/CloudLink';
import Dossier from 'models/dossier/Dossier';
import Label from 'models/dossier/Label';
import TaskList from 'models/dossier/TaskList';
import Profile from 'models/me/Profile';

import AllAttachment from '../attachment/Attachment';
import Attachment from './Attachment';
import AuditLog from './AuditLog';
import CardUser from './CardUser';
import Comment from './Comment';
import Consideration from './Consideration';
import ContextGroup from './ContextGroup';
import LegalSource from './LegalSource';

/**
 * Card
 */
export default class Card extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    name: PropTypes.string,
    subtitle: PropTypes.string,
    notes: PropTypes.string,
    section: PropTypes.string,
    type: PropTypes.string,
    subType: PropTypes.string,
    color: PropTypes.string,
    stackOrder: PropTypes.number,
    client: PropTypes.shape(Client.propTypes),
    position: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
    summary: PropTypes.arrayOf(PropTypes.string),
    consideration: PropTypes.shape(Consideration.propTypes),
    attachments: PropTypes.arrayOf(PropTypes.shape(Attachment.propTypes)),
    comments: PropTypes.arrayOf(PropTypes.shape(Comment.propTypes)),
    contextGroups: PropTypes.arrayOf(PropTypes.shape(ContextGroup.propTypes)),
    assignedUser: PropTypes.shape(CardUser.propTypes),
    metaInfo: PropTypes.shape({
      countAttachments: PropTypes.numberOrString,
      countComments: PropTypes.numberOrString,
      countReactions: PropTypes.numberOrString,
      createdAt: PropTypes.string,
    }),
    relatedCards: PropTypes.arrayOf(
      PropTypes.shape({
        ...DataModel.propTypes,
        name: PropTypes.string,
        subtitle: PropTypes.string,
        section: PropTypes.string,
        type: PropTypes.string,
        color: PropTypes.string,
        dossier: PropTypes.any,
      }),
    ),
    auditLog: PropTypes.arrayOf(PropTypes.shape(AuditLog.propTypes)),
    taskLists: PropTypes.arrayOf(PropTypes.shape(TaskList.propTypes)),
    taskList: PropTypes.shape(TaskList.propTypes),
    cloudLinks: PropTypes.arrayOf(PropTypes.shape(CloudLink.propTypes)),
    cloudAttachments: PropTypes.arrayOf(PropTypes.shape(CloudAttachment.propTypes)),
    berkleyModelSessions: PropTypes.arrayOf(PropTypes.shape(BerkleyModelSession.propTypes)),
    label: PropTypes.shape(Label.propTypes),
    deadlineDate: PropTypes.string,
    completedDateTime: PropTypes.string,
    isArchived: PropTypes.bool,
    permissions: PropTypes.shape({
      canView: PropTypes.bool,
      canEdit: PropTypes.bool,
      canArchive: PropTypes.bool,
    }),
    dossier: PropTypes.any,
    allAttachments: PropTypes.arrayOf(PropTypes.shape(AllAttachment.propTypes)),
    createdBy: PropTypes.shape(Profile.PropTypes),
    legalSources: PropTypes.arrayOf(PropTypes.shape(LegalSource.propTypes)),
  };

  /**
   * Name of this card.
   * @type {string}
   */
  name;

  /**
   * Subtitle of this card.
   * @type {string}
   */
  subtitle;

  /**
   * A large description of a card in Markdown format.
   * @type {string}
   */
  notes;

  /**
   * Card type.
   * @type {string}
   */
  type = 'own';

  /**
   * Card subType.
   * @type {string}
   */
  subType;

  /**
   * Section of this card.
   * @type {string}
   */
  section;

  /**
   * Optional consideration that was attached to this card
   * @type {Consideration|null}
   */
  consideration;

  /**
   * @type {Client|undefined}
   */
  client;

  /**
   * @type {boolean}
   */
  isArchived = false;

  /**
   * @type {Object|undefined}
   */
  permissions;

  /**
   * @type {string}
   */
  color;

  /**
   * @type {{x: number, y: number}}
   */
  position = {
    x: 0,
    y: 0,
  };

  /**
   * @type {string}
   */
  summary;

  /**
   * @type {Attachment[]|undefined}
   */
  attachments;

  /**
   * @type {Comment[]|undefined}
   */
  comments;

  /**
   * @type {ContextGroup[]|undefined}
   */
  contextGroups;

  /**
   * Stack order
   * @type {number}
   */
  stackOrder;

  /**
   * Assigned card user instance.
   * @type {CardUser|undefined}
   */
  assignedUser;

  /**
   * @type {Label|undefined}
   */
  label;

  /**
   * Deadline date
   * @type {string}
   */
  deadlineDate;

  /**
   * Completed date
   * @type {string}
   */
  completedDateTime;

  /**
   * Total amount of comments, reactions, attachments and the creation date of the card.
   * @type {{createdAt: string, countAttachments: number, countComments: number, countReactions: number}}
   */
  metaInfo = {
    countAttachments: 0,
    countComments: 0,
    countReactions: 0,
    createdAt: '',
  };

  /**
   * @type {AuditLog[]|undefined}
   */
  auditLog;

  /**
   * @type {TaskList[]|undefined}
   */
  taskLists;

  /**
   * @type {TaskList}
   */
  taskList;

  /**
   * @type {CloudLink[]|undefined}
   */
  cloudLinks;

  /**
   * @type {CloudAttachment[]|undefined}
   */
  cloudAttachments;

  /**
   * @type {BerkleyModelSession[]|undefined}
   */
  berkleyModelSessions;

  /**
   * @type {Dossier}
   */
  dossier;

  /**
   * @type {AllAttachment[]|undefined}
   */
  allAttachments;

  /**
   * @type {Profile}
   */
  createdBy;

  /**
   * @type {LegalSource[]|undefined}
   */
  legalSources;

  /**
   * @returns {string}
   */
  getName() {
    return this.name;
  }

  /**
   * @return {string}
   */
  getNotes() {
    return this.notes;
  }

  /**
   * @return {string}
   */
  getSubType() {
    return this.subType;
  }

  /**
   * @returns {string}
   */
  getSection() {
    return this.section;
  }

  /**
   * @returns {Consideration|null}
   */
  getConsideration() {
    return this.consideration;
  }

  /**
   * @returns {string}
   */
  getColor() {
    return this.color;
  }

  /**
   * @returns {{x: number, y: number}}
   */
  getPosition() {
    return this.position;
  }

  /**
   *
   * @returns {String[]}
   */
  getSummary() {
    return this.summary;
  }

  /**
   *
   * @returns {Attachment[]}
   */
  getAttachments() {
    if (this.attachments === undefined) {
      this.attachments = [];
    }
    return this.attachments;
  }

  /**
   *
   * @returns {Comment[]}
   */
  getComments() {
    if (!this.comments) {
      this.comments = [];
    }
    return this.comments;
  }

  /**
   * @param {string} uploadId
   * @returns {Attachment|null}
   */
  getAttachmentByUploadId(uploadId) {
    return this.getAttachments()
      .filter((a) => a.uploadId === uploadId)
      .shift();
  }

  /**
   *
   * @param {string} attachmentId
   * @returns {Attachment|null}
   */
  getAttachmentByAttachmentId(attachmentId) {
    return this.getAttachments()
      .filter((a) => a.id === attachmentId)
      .shift();
  }

  /**
   * Retrieve context groups
   * @returns {ContextGroup[]}
   */
  getContextGroups() {
    if (!this.contextGroups) {
      this.contextGroups = [];
    }
    return this.contextGroups;
  }

  /**
   * Retrieve stack order
   *
   * @returns {number}
   */
  getStackOrder() {
    return this.stackOrder;
  }

  /**
   * Assigned user of the card, can be null.
   * @returns {CardUser}
   */
  getAssignedUser() {
    if (!this.assignedUser) {
      this.assignedUser = CardUser.createInstance();
    }
    return this.assignedUser;
  }

  /**
   *
   * @returns {Label}
   */
  getLabel() {
    if (!this.label) {
      this.label = Label.createInstance();
    }
    return this.label;
  }

  /**
   *
   * @returns {string}
   */
  getDeadlineDate() {
    return this.deadlineDate;
  }

  /**
   * Get card meta information like total attachments, comments, reactions and creation date.
   * @returns {{createdAt: string, countAttachments: number, countComments: number, countReactions: number}}
   */
  getMetaInfo() {
    return this.metaInfo;
  }

  /**
   * @returns {Card[]}
   */
  getRelatedCards() {
    if (!this.relatedCards) {
      this.relatedCards = [];
    }
    return this.relatedCards;
  }

  /**
   * @returns {AuditLog[]}
   */
  getAuditLog() {
    if (!this.auditLog) {
      this.auditLog = [];
    }
    return this.auditLog;
  }

  /**
   * @returns {TaskList[]}
   */
  getTaskLists() {
    if (!this.taskLists) {
      this.taskLists = [];
    }
    return this.taskLists;
  }

  /**
   * @returns {CloudLink[]}
   */
  getCloudLinks() {
    if (!this.cloudLinks) {
      this.cloudLinks = [];
    }
    return this.cloudLinks;
  }

  /**
   * @returns {CloudAttachment[]}
   */
  getCloudAttachments() {
    if (!this.cloudAttachments) {
      this.cloudAttachments = [];
    }
    return this.cloudAttachments;
  }

  /**
   * @returns {Attachment[]}
   */
  getAllAttachments() {
    if (!this.allAttachments) {
      this.allAttachments = [];
    }
    return this.allAttachments;
  }

  /**
   * @returns {BerkleyModelSession[]}
   */
  getBerkleyModelSessions() {
    if (!this.berkleyModelSessions) {
      this.berkleyModelSessions = [];
    }

    return this.berkleyModelSessions;
  }

  /**
   * @return {Client}
   */
  getClient() {
    if (!this.client) {
      this.client = Client.createInstance();
    }
    return this.client;
  }

  /**
   * @return {boolean}
   */
  getIsArchived() {
    return this.isArchived;
  }

  /**
   *  @returns {Object}
   */
  getPermissions() {
    if (this.permissions === undefined) {
      this.permissions = {
        canView: false,
        canEdit: false,
        canArchive: false,
      };
    }
    return this.permissions;
  }

  /**
   * @return {Dossier}
   */
  getDossier() {
    return this.dossier;
  }

  /**
   * @return {Profile}
   */
  getCreatedBy() {
    return this.createdBy;
  }

  /**
   * @return {LegalSource[]}
   */
  getLegalSources() {
    if (this.legalSources) {
      this.legalSources = [];
    }
    return this.legalSources;
  }

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'attachments') {
      value = Attachment.createInstancesByArray(value);
    } else if (fieldName === 'consideration') {
      value = Consideration.createInstance(value);
    } else if (fieldName === 'comments') {
      value = Comment.createInstancesByArray(value);
    } else if (fieldName === 'contextGroups') {
      value = ContextGroup.createInstancesByArray(value);
    } else if (fieldName === 'assignedUser') {
      value = CardUser.createInstance(value);
    } else if (fieldName === 'relatedCards') {
      value = Card.createInstancesByArray(value);
    } else if (fieldName === 'auditLog') {
      value = AuditLog.createInstancesByArray(value);
    } else if (fieldName === 'taskLists') {
      value = TaskList.createInstancesByArray(value);
    } else if (fieldName === 'taskList') {
      value = TaskList.createInstance(value);
    } else if (fieldName === 'cloudLinks') {
      value = CloudLink.createInstancesByArray(value);
    } else if (fieldName === 'cloudAttachments') {
      value = CloudAttachment.createInstancesByArray(value);
    } else if (fieldName === 'berkleyModelSessions') {
      value = BerkleyModelSession.createInstancesByArray(value);
    } else if (fieldName === 'label') {
      if (value !== null && value !== undefined) {
        // only set object when it is not null
        value = Label.createInstance(value);
      }
    } else if (fieldName === 'client') {
      value = Client.createInstance(value);
    } else if (fieldName === 'dossier') {
      if (value !== null && value !== undefined) {
        // only set object when it is not null
        value = Dossier.createInstance(value);
      }
    } else if (fieldName === 'allAttachments') {
      value = AllAttachment.createInstancesByArray(value);
    } else if (fieldName === 'legalSources') {
      value = LegalSource.createInstancesByArray(value);
    } else if (fieldName === 'createdBy') {
      value = Profile.createInstance(value);
    }

    return super.set(fieldName, value);
  }

  /**
   * Get the default color used for the given section, defaults to yellow (#fff199)
   * Results: "#cfe4a8"
   * Facts:   "#99d5f1"
   * Default  "#fff199"
   *
   * @param {string} section
   * @return {string}
   */
  static getColorBySectionName(section) {
    if (section === 'results') {
      return '#cfe4a8';
    } else if (section === 'facts') {
      return '#99d5f1';
    }

    return '#fff199';
  }

  /**
   * Get the subtitle used for the given section, defaults to "Open overweging".
   * Results: "Eigen beslissing"
   * Facts: "Eigen feit"
   * Default "Open overweging"
   *
   * @param {string} section
   * @return {string}
   */
  static getSubtitleBySectionName(section) {
    if (section === 'results') {
      return 'Open beslissing';
    } else if (section === 'facts') {
      return 'Open feit';
    }

    return 'Open overweging';
  }
}
