import type { TitanTheme } from '@elseu/sdu-titan';
import { useTheme } from 'styled-components';

type StatusColor = keyof TitanTheme['statusColors'];

export const useColorConverter = () => {
  const theme = useTheme() as TitanTheme;

  if (typeof theme.statusColors === 'undefined') {
    throw new Error('Theme does not contain statusColors');
  }

  const statusColorToHex = (
    label: StatusColor | string | undefined,
    luminosity: 'light' | 'medium' | 'dark' = 'medium',
    type: 'text' | 'component' = 'component',
  ): string => {
    if (!label || label.startsWith('#')) {
      return theme.statusColors.default[luminosity][type];
    }

    return theme.statusColors[label as StatusColor][luminosity][type];
  };

  return {
    statusColorToHex,
  };
};
