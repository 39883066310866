import { createLastVisitPhaseUrl, createVisitPhaseStorageKey } from 'helpers/dossierhelper';
import DataModel, { PropTypes } from 'models/base/DataModel';
import AuditLog from 'models/dossier/AuditLog';
import Card from 'models/dossier/Card';
import DossierClient from 'models/dossier/DossierClient';
import DossierTeam from 'models/dossier/DossierTeam';
import DossierUser from 'models/dossier/DossierUser';
import Label from 'models/dossier/Label';
import Topic from 'models/dossier/Topic';
import UserWithRole from 'models/dossier/UserWithRole';
import User from 'models/me/User';
import LocalStorageService from 'services/LocalStorageService';

/**
 * Dossier
 */
export default class Dossier extends DataModel {
  // roles that dossier can have.
  static ROLE_VIEWER = 'VIEWER';
  static ROLE_EDITOR = 'EDITOR';
  static ROLE_OWNER = 'OWNER';

  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    name: PropTypes.string,
    description: PropTypes.string,
    cards: PropTypes.arrayOf(PropTypes.shape({})),
    // cards: PropTypes.arrayOf(PropTypes.shape(Card.propTypes)),
    topics: PropTypes.arrayOf(PropTypes.shape(Topic.propTypes)),
    clients: PropTypes.arrayOf(PropTypes.shape(DossierClient.propTypes)),
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    inventoryId: PropTypes.string,
    adviceId: PropTypes.string,
    analysisId: PropTypes.string,
    executionId: PropTypes.string,
    isArchived: PropTypes.bool,
    archivedAt: PropTypes.string,
    owners: PropTypes.arrayOf(PropTypes.shape(User.propTypes)),
    editors: PropTypes.arrayOf(PropTypes.shape(User.propTypes)),
    dossierUsers: PropTypes.arrayOf(PropTypes.shape(DossierUser.propTypes)),
    dossierTeams: PropTypes.arrayOf(PropTypes.shape(DossierTeam.propTypes)),
    users: PropTypes.arrayOf(PropTypes.shape(UserWithRole.propTypes)),
    isPrivateCollaboration: PropTypes.bool,
    isTemplate: PropTypes.bool,
    permissions: PropTypes.shape({
      canView: PropTypes.bool,
      canEdit: PropTypes.bool,
      canDelete: PropTypes.bool,
      canArchive: PropTypes.bool,
      canAddCard: PropTypes.bool,
      canManageTopics: PropTypes.bool,
      canManageOwners: PropTypes.bool,
      canDuplicate: PropTypes.bool,
      canUpdateTriggers: PropTypes.bool,
      canEditPermissions: PropTypes.bool,
    }),
    auditLog: PropTypes.arrayOf(PropTypes.shape(AuditLog.propTypes)),
    phaseLabels: PropTypes.shape({
      inventory: PropTypes.shape(Label.propTypes),
      analysis: PropTypes.shape(Label.propTypes),
      advice: PropTypes.shape(Label.propTypes),
      execution: PropTypes.shape(Label.propTypes),
    }),
  };

  /**
   * @type {string}
   */
  inventoryId;

  /**
   * @type {string}
   */
  executionId;

  /**
   * Dossier name.
   * @type {string}
   */
  name;

  /**
   * Dossier description, telling us shortly what this dossierAnalysis is about.
   * @type {string}
   */
  description;

  /**
   * @type {Card[]|undefined}
   */
  cards;

  /**
   * @type {Topic[]|undefined}
   */
  topics;

  /**
   * @type {string}
   */
  adviceId;

  /**
   * @type(DossierClient[]}
   */
  clients;

  /**
   * @type {string}
   */
  createdAt;

  /**
   * @type {User[]|undefined}
   */
  owners;

  /**
   * @type {User[]|undefined}
   */
  editors;

  /**
   * @type {DossierUser[]|undefined}
   */
  dossierUsers;

  /**
   * @type {DossierTeam[]|undefined}
   */
  dossierTeams;

  /**
   * @type {boolean}
   */
  isPrivateCollaboration;

  /**
   * @type {boolean}
   */
  isTemplate;

  /**
   * @type {Object|undefined}
   */
  permissions;

  /**
   * @type {AuditLog[]|undefined}
   */
  auditLog;

  /**
   * @type {object|undefined}
   */
  phaseLabels;

  /**
   * @type {string}
   */
  analysisId;

  /**
   * @type {boolean}
   */
  isArchived;

  /**
   * @type {string}
   */
  archivedAt;

  /**
   * @returns {string}
   */
  getName() {
    return this.name;
  }

  /**
   * @returns {string}
   */
  getDescription() {
    return this.description;
  }

  /**
   * @returns {DossierClient[]}
   */
  getClients() {
    if (this.clients === undefined) {
      this.clients = [];
    }
    return this.clients;
  }

  /**
   * @returns {Card[]}
   */
  getCards() {
    if (!this.cards) {
      this.cards = [];
    }
    return this.cards;
  }

  /**
   * @returns {Topic[]}
   */
  getTopics() {
    if (this.topics === undefined) {
      this.topics = [];
    }
    return this.topics;
  }

  /**
   * @returns {string}
   */
  getCreatedAt() {
    return this.createdAt;
  }

  /**
   * @returns {string}
   */
  getUpdatedAt() {
    return this.updatedAt;
  }

  /**
   * @returns {User[]}
   */
  getOwners() {
    if (this.owners === undefined) {
      this.owners = [];
    }
    return this.owners;
  }

  /**
   * @returns {User[]}
   */
  getEditors() {
    if (this.editors === undefined) {
      this.editors = [];
    }
    return this.editors;
  }

  /**
   * @returns {DossierUser[]}
   */
  getDossierUsers() {
    if (this.dossierUsers === undefined) {
      this.dossierUsers = [];
    }
    return this.dossierUsers;
  }

  /**
   * @returns {DossierTeam[]|*}
   */
  getDossierTeams() {
    if (this.dossierTeams === undefined) {
      this.dossierTeams = [];
    }
    return this.dossierTeams;
  }

  /**
   * @returns {UserWithRole[]}
   */
  getUsers() {
    if (this.users === undefined) {
      this.users = [];
    }

    return this.users;
  }

  /**
   *  @returns {any}
   */
  getPermissions() {
    if (this.permissions === undefined) {
      this.permissions = {
        canView: false,
        canEdit: false,
        canDelete: false,
        canAddCard: false,
        canManageTopics: false,
        canManageOwners: false,
        canDuplicate: false,
        canUpdateTriggers: false,
        canEditPermissions: false,
      };
    }
    return this.permissions;
  }

  /**
   * @returns {boolean}
   */
  getIsPrivateCollaboration() {
    return this.isPrivateCollaboration;
  }

  /**
   * @returns {boolean}
   */
  getIsTemplate() {
    return this.isTemplate;
  }

  /**
   * @returns {AuditLog[]}
   */
  getAuditLog() {
    if (!this.auditLog) {
      this.auditLog = [];
    }
    return this.auditLog;
  }

  /**
   * @returns {boolean}
   */
  getIsArchived() {
    return this.isArchived;
  }

  /**
   * @returns {string}
   */
  getArchivedAt() {
    return this.archivedAt;
  }

  /**
   * Get the last phase url that the user visited.
   *
   * @return {string}
   */
  getLastPhaseUrl() {
    return Dossier.createLastVisitPhaseUrl(this.id);
  }

  /**
   * Get users with a specific role.
   *
   * @param {array} roles
   * @returns {array}
   */
  getUsersWithRoles(roles = []) {
    return this.getUsers().filter((user) => {
      return roles.indexOf(user.getRole()) >= 0;
    });
  }

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'cards') {
      value = Card.createInstancesByArray(value);
    } else if (fieldName === 'topics') {
      value = Topic.createInstancesByArray(value);
    } else if (fieldName === 'clients' && value) {
      value = DossierClient.createInstancesByArray(value);
    } else if (fieldName === 'owners') {
      value = User.createInstancesByArray(value);
    } else if (fieldName === 'editors') {
      value = User.createInstancesByArray(value);
    } else if (fieldName === 'dossierUsers') {
      value = DossierUser.createInstancesByArray(value);
    } else if (fieldName === 'dossierTeams') {
      value = DossierTeam.createInstancesByArray(value);
    } else if (fieldName === 'users') {
      value = UserWithRole.createInstancesByArray(value);
    } else if (fieldName === 'labels') {
      value = {
        inventory: Label.createInstance(value.inventory),
        analysis: Label.createInstance(value.analysis),
        advice: Label.createInstance(value.advice),
        execution: Label.createInstance(value.execution),
      };
    }
    return super.set(fieldName, value);
  }

  // #region static functions
  /**
   * @param {string|undefined} dossiers
   * @param {string} dossierId
   * @param {string} cardId
   * @return {Card|boolean}
   */
  static getCardFromDossiers(dossiers, dossierId, cardId) {
    const dossier = dossiers?.get(dossierId);
    const cards = dossier?.getCards() || [];

    return cards.filter((c) => c.id === cardId).shift();
  }

  /**
   * Sets the last phase that the user visit in a dossier.
   *
   * @param {string} dossierId
   * @param {string} phase
   */
  static setLastVisitPhase(dossierId, phase) {
    const lastVisitPhaseKey = createVisitPhaseStorageKey(dossierId);
    LocalStorageService.setItem(lastVisitPhaseKey, phase);

    // purge the localstorage to only store the last maxItems dossier history items.
    // @see https://trello.com/c/b8DM0fzD/469-bug-wit-scherm-inventarisatie-fase
    let itemCount = 0;
    const maxItems = 20;
    Object.keys(LocalStorageService.getItems()).forEach((key) => {
      // only purge dossier_ items.
      if (key.startsWith('dossier_')) {
        itemCount++;
        // make sure we don't purge the last item, and only purge if we have reached the
        // maxItems count.
        if (key !== lastVisitPhaseKey && itemCount >= maxItems) {
          LocalStorageService.removeItem(key);
        }
      }
    });
  }

  /**
   * Get the last phase that the user visit in a dossier.
   *
   * @param {string} dossierId
   */
  static getLastVisitPhase(dossierId) {
    return LocalStorageService.getItem(createVisitPhaseStorageKey(dossierId), 'inventory');
  }

  /**
   * Create url for a specific dossier
   *
   * @param dossierId
   * @return {string}
   */
  static createLastVisitPhaseUrl(dossierId) {
    return createLastVisitPhaseUrl(dossierId);
  }

  // #endregion
}
