import DataModel, { PropTypes } from 'models/base/DataModel';
import Client from 'models/crm/Client';
import ClientUser from 'models/crm/ClientUser';

/**
 * Dossier client
 */
export default class DossierClient extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    company: PropTypes.shape(Client.propTypes),
    person: PropTypes.shape(ClientUser.propTypes),
    client: PropTypes.shape(Client.propTypes),
  };

  /**
   * @type {Client}
   */
  company;

  /**
   * @type {ClientUser}
   */
  person;

  /**
   * @type {Client}
   */
  client;

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'person' && value) {
      value = ClientUser.createInstance(value);
    } else if (fieldName === 'company' && value) {
      value = Client.createInstance(value);
    } else if (fieldName === 'client' && value) {
      value = Client.createInstance(value);
    }

    return super.set(fieldName, value);
  }
}
