import Actions from 'actions/Actions';
import CardSectionAttachments from 'components/card/CardSectionAttachments';
import type { Attachment, CloudAttachment, CloudLink } from 'entity/Attachment/types';
import useFetchState from 'hooks/useFetchState';
import React, { useEffect, useState } from 'react';

type Props = {
  isReadOnly?: boolean;
  isDisabled?: boolean;
  cardId: string;
  dossierId: string;
  isOneDriveEnabled?: boolean;
  cloudLinkTypes: any[];
  attachments: Attachment[];
  cloudAttachments: CloudAttachment[];
  cloudLinks: CloudLink[];
  onCardNeedsUpdate: () => void;
  onBusy?: (isBusy: boolean) => void;
};

/**
 * Internal state management for card attachment section
 *
 * @constructor
 */
const CardSectionAttachmentsState: React.FC<Props> = ({
  isReadOnly = false,
  isDisabled = false,
  cardId,
  dossierId,
  isOneDriveEnabled = false,
  cloudLinkTypes,
  attachments,
  cloudAttachments,
  cloudLinks,
  onCardNeedsUpdate,
  onBusy = () => null,
}) => {
  const [uploaded, setUploaded] = useState<any[]>([]);
  const [isBusy, setIsBusy] = useState(false);
  const apiService = Actions.getAPIService();

  // region attachments
  const [{ isBusy: attachmentAddIsBusy }, { doRequest: attachmentAddRequest }] = useFetchState();
  const [{ isBusy: attachmentDeleteIsBusy }, { doRequest: attachmentDeleteRequest }] =
    useFetchState();

  /**
   * Add attachment event.
   *
   * @param {FileUploaderItem[]} attachments
   */
  const onAttachmentAdd = (attachments: any[]) => {
    setIsBusy(true);
    const result = attachments.reduce((accPromuise, attachment) => {
      return accPromuise.then(
        () => {
          if (attachment.file) {
            setUploaded([...uploaded, attachment]);
            return attachmentAddRequest(
              apiService.createAttachment(dossierId, cardId, attachment.file),
            );
          } else {
            return Promise.resolve();
          }
        },
        () => null,
      );
    }, Promise.resolve());

    result.then(() => {
      onCardNeedsUpdate();
      setIsBusy(false);
    });
  };

  /**
   * Delete attachment event
   *
   * @param {object} attachment
   */
  const onAttachmentDelete = (attachment: any) => {
    const attachmentCardId = attachment.cardId || cardId;
    attachmentDeleteRequest(
      apiService.deleteAttachment(dossierId, attachmentCardId, attachment.id),
    ).then(
      () => onCardNeedsUpdate(),
      () => null,
    );
  };
  // endregion

  // region cloud link
  const [
    { isBusy: cloudLinkAddIsBusy, error: cloudLinkAddError, hasError: cloudLinkAddHasError },
    { doRequest: cloudLinkAddRequest },
  ] = useFetchState();
  const [
    { isBusy: cloudLinkEditIsBusy, error: cloudLinkEditError, hasError: cloudLinkEditHasError },
    { doRequest: cloudLinkEditRequest },
  ] = useFetchState();
  const [{ isBusy: cloudLinkDeleteIsBusy }, { doRequest: cloudLinkDeleteRequest }] =
    useFetchState();

  /**
   * Add cloud link event
   */
  const onCloudLinkAdd = (data: any) => {
    cloudLinkAddRequest(apiService.createCardCloudLink(dossierId, cardId, data)).then(
      () => onCardNeedsUpdate(),
      () => null,
    );
  };

  /**
   * Edit cloud link event
   */
  const onCloudLinkEdit = (cloudLink: any) => {
    const cloudLinkCardId = cloudLink.cardId || cardId;
    const values = { ...cloudLink };
    delete values.id;
    delete values.cardId;

    cloudLinkEditRequest(
      apiService.updateCardCloudLink(dossierId, cloudLinkCardId, cloudLink.id, values),
    ).then(
      () => onCardNeedsUpdate(),
      () => null,
    );
  };

  /**
   * Delete cloud link event
   */
  const onCloudLinkDelete = (cloudLink: any) => {
    const cloudLinkCardId = cloudLink.cardId || cardId;
    cloudLinkDeleteRequest(
      apiService.deleteCardCloudLink(dossierId, cloudLinkCardId, cloudLink.id),
    ).then(
      () => onCardNeedsUpdate(),
      () => null,
    );
  };
  // endregion

  // send busy event when any of the fetch calls are busy.
  useEffect(() => {
    const returnBusy =
      attachmentAddIsBusy ||
      attachmentDeleteIsBusy ||
      cloudLinkAddIsBusy ||
      cloudLinkEditIsBusy ||
      cloudLinkDeleteIsBusy ||
      isBusy;
    onBusy(returnBusy);
    // eslint-disable-next-line
  }, [
    attachmentAddIsBusy,
    attachmentDeleteIsBusy,
    cloudLinkAddIsBusy,
    cloudLinkEditIsBusy,
    cloudLinkDeleteIsBusy,
    isBusy,
  ]);

  return (
    <CardSectionAttachments
      attachments={attachments}
      cardId={cardId}
      cloudAttachments={cloudAttachments}
      cloudLinks={cloudLinks}
      cloudLinkTypes={cloudLinkTypes}
      creatingCloudLinkErrors={cloudLinkAddError?.getData() || undefined}
      dossierId={dossierId}
      hasCreatingCloudLinkError={cloudLinkAddHasError}
      hasUpdatingCloudLinkError={cloudLinkEditHasError}
      isCreatingCloudLink={cloudLinkAddIsBusy}
      isDisabled={isDisabled}
      isOneDriveEnabled={isOneDriveEnabled}
      isReadOnly={isReadOnly}
      isUpdatingCloudLink={cloudLinkEditIsBusy}
      updatingCloudLinkErrors={cloudLinkEditError?.getData() || undefined}
      uploaded={uploaded}
      onAttachmentAdd={onAttachmentAdd}
      onAttachmentDelete={onAttachmentDelete}
      onAttachmentEdit={() => onCardNeedsUpdate()}
      onCloudAttachmentDoRefresh={() => onCardNeedsUpdate()}
      onCloudAttachmentItemDelete={() => onCardNeedsUpdate()}
      onCloudAttachmentUploadProgress={(uploading: boolean) => setIsBusy(uploading)}
      onCloudLinkAdd={onCloudLinkAdd}
      onCloudLinkDelete={onCloudLinkDelete}
      onCloudLinkEdit={onCloudLinkEdit}
    />
  );
};

export default CardSectionAttachmentsState;
