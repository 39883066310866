/**
 * Gets the ids from specific type of client
 *
 * @param {array} clients
 * @param {string} type
 * @returns {array}
 */
export const mapValueFromClientsWithType = (clients, type) => {
  return clients.filter((item) => item.type === type).map((item) => item.value);
};

/**
 * Remap client object for a select
 *
 * @param {object} clientItem
 * @returns {{image: *, label: *, type: *, value: *, email: *}}
 */
export const remapClientItemForSelect = (clientItem) => {
  return {
    value: clientItem.id,
    label: clientItem.getDisplayName(),
    image: clientItem.getAvatarUrl(),
    email: clientItem.getEmail(),
    type: clientItem.getType(),
  };
};

/**
 * Check if a user is linked to a dossier
 *
 * @param {array} dossierUsers
 * @param {string} userId
 * @returns {boolean}
 */
export const isUserLinkedToDossier = (dossierUsers, userId) => {
  return dossierUsers && dossierUsers.indexOf(userId) !== -1;
};

/**
 * Remap user data structure to post values.
 *
 * @param {array|undefined} users
 * @returns {*}
 */
export const remapUsersForApi = (users) => {
  return users?.map((user) => ({
    user: user.getUser().getIdentity(),
    role: user.getRole(),
  }));
};

/**
 * Remap team data structure to post values.
 *
 * @param {array|undefined} teams
 * @returns {*}
 */
export const remapTeamsForApi = (teams) => {
  return teams?.map((team) => ({
    team: team.getTeam().getIdentity(),
    role: team.getRole(),
  }));
};
