import { defaultStyles, Link, Text } from '@elseu/sdu-titan';
import type { Ticket } from 'entity/dossiers/types';
import type { ReactNode } from 'react';
import React from 'react';
import styled from 'styled-components';

import { generateTicketParentUrl, getTicketParentName } from './utils/generateTicketUrl';

const StyledText = styled(Text)`
  ${defaultStyles.ellipsis}
`;
const StyledLink = styled(Link)`
  ${defaultStyles.ellipsis}
  max-width: 100%;
`;

type TicketParentProps = {
  ticket: Ticket;
  icon?: ReactNode;
};

export const TicketParent = ({ ticket, icon }: TicketParentProps) => {
  const parentName = getTicketParentName(ticket);
  const parentLink = generateTicketParentUrl(ticket);

  if (!parentName) {
    return null;
  }

  return (
    <StyledText color="grey70" type="paragraphSmall">
      {parentLink ? (
        <StyledLink
          prefixAdornment={icon}
          style={{ fontSize: 'inherit' }}
          to={parentLink}
          onClick={(event) => {
            event?.stopPropagation();
          }}
        >
          {parentName}
        </StyledLink>
      ) : (
        parentName
      )}
    </StyledText>
  );
};
