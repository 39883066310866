import DataModel, { PropTypes } from 'models/base/DataModel';

import Profile from '../me/Profile';
import Attachment from './Attachment';
import PartialConsideration from './PartialConsideration';
import Source from './Source';
import Tool from './Tool';

/**
 * CommentReaction
 */
export default class CommentReaction extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    content: PropTypes.string,
    createdDateTime: PropTypes.string,
    user: PropTypes.shape(Profile.propTypes),
    context: PropTypes.oneOfType([
      PropTypes.shape(Attachment.propTypes),
      PropTypes.shape(Source.propTypes),
      PropTypes.shape(Tool.propTypes),
      PropTypes.shape(PartialConsideration.propTypes),
      PropTypes.bool,
    ]),
  };

  /**
   * @type {string}
   */
  content;

  /**
   * @type {PartialConsideration|Tool|Source|Attachment}
   */
  context;

  /**
   * @type {string}
   */
  createdDateTime;

  /**
   * @type {Profile}
   */
  user;

  /**
   * @returns {string}
   */
  getContent() {
    return this.content;
  }

  /**
   * @returns {PartialConsideration|Tool|Source|Attachment}
   */
  getContext() {
    return this.context;
  }

  /**
   * @returns {string}
   */
  getCreatedDateTime() {
    return this.createdDateTime;
  }

  /**
   * @returns {Profile}
   */
  getUser() {
    return this.user;
  }

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'user') {
      value = Profile.createInstance(value);
    } else if (fieldName === 'comments') {
      value = CommentReaction.createInstancesByArray(value);
    } else if (fieldName === 'context') {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (value.contextType || false) {
        switch (value.contextType) {
          case 'tool':
            value = Tool.createInstance(value);
            break;
          case 'partial_consideration':
            value = PartialConsideration.createInstance(value);
            break;
          case 'source':
            value = Source.createInstance(value);
            break;
          case 'attachment':
            value = Attachment.createInstance(value);
            break;
          default:
            value = [];
            break;
        }
      }
    }

    return super.set(fieldName, value);
  }
}
