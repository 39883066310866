import ContactNote from 'models/crm/ContactNote';
import { useEffect, useState } from 'react';
import { STATE_CONTACT_NOTES_SELECT, useContactNoteState } from 'stores/crm/ContactNoteState';
import { sweetFetchStateForContextID } from 'stores/utils/SweetFetchState';

/**
 * Note details hook
 *
 * @param {string} contactNoteId
 * @returns {object}
 */
export const useContactNoteDetails = (contactNoteId) => {
  const [contactNoteState, contactNoteActions] = useContactNoteState();
  const [contactNote, setContactNote] = useState(ContactNote.createInstance({}));

  const contactNoteFetchState = sweetFetchStateForContextID(
    contactNoteState[STATE_CONTACT_NOTES_SELECT],
    contactNoteId,
  );
  const isBusy = contactNoteFetchState.isBusy;
  const isSuccess = contactNoteFetchState.isSuccess;
  const selectingContext = contactNoteFetchState.context;

  const doRequest = () => {
    if (contactNoteId && contactNoteId !== 'new') {
      contactNoteActions.getContactNote(contactNoteId);
    }
  };

  useEffect(doRequest, [contactNoteId, contactNoteActions]);
  useEffect(
    () => setContactNote(selectingContext || ContactNote.createInstance()),
    [selectingContext],
  );

  return { contactNote, isBusy, isSuccess, doRequest };
};
