import { PropTypes } from 'models/base/DataModel';
import User from 'models/me/User';

/**
 * UserWithRole
 */
export default class UserWithRole extends User {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...User.propTypes,
    role: PropTypes.string,
  };

  /**
   * @type {string}
   */
  role;

  /**
   * @returns {string}
   */
  getRole() {
    return this.role;
  }
}
