import { normalizeCard, normalizeDossier } from 'entity/dossiers/normalizer';

import type { ContactNote, ContactNoteCard } from './types';

export const normalizeContactNote = (result: any): ContactNote => ({
  ...normalizeDossier(result),
  contactNoteCard: result?.contactNoteCard
    ? normalizeContactNoteCard(result?.contactNoteCard)
    : undefined,
});

export const normalizeContactNoteCard = (result: any): ContactNoteCard => normalizeCard(result);
