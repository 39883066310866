import { ActionMenu, Badge, ChevronDownIcon, Flex, MenuItemOption, Tag } from '@elseu/sdu-titan';
import type { StatusColors } from '@elseu/sdu-titan/dist/types/types/status-colors';
import type { Status } from 'entity/dossiers/types';
import type Label from 'models/dossier/Label';
import React, { useMemo } from 'react';

export type TicketStatusSelectFieldProps = {
  isDisabled?: boolean;
  value: Status['id'];
  label?: string;
  onChange?: (ticketId: Status['id']) => void;
  availableLabels: Status[];
  hasPortal?: boolean;
};

const TicketStatusSelectField: React.FC<TicketStatusSelectFieldProps> = ({
  isDisabled,
  value,
  label = 'Status selecteren',
  onChange,
  availableLabels,
  hasPortal = true,
}) => {
  const options = useMemo(() => {
    return availableLabels
      .map((item) => (item as any as Label).mapForPhaseSelect())
      .map((item) => ({
        prefixAdornment: (
          <Badge size="xs" status={(item.color || 'default') as StatusColors} variant="medium" />
        ),
        ...item,
      }));
  }, [availableLabels]);

  const selectedOption = useMemo(() => {
    return value && options.find((option) => option.value === value);
  }, [options, value]);

  return (
    <ActionMenu
      hasPortal={hasPortal}
      label={label}
      trigger={
        <Tag prefixAdornment={selectedOption ? selectedOption.prefixAdornment : null}>
          {selectedOption ? selectedOption.label : 'Geen status'}
          <Flex alignItems="center" pl={1}>
            <ChevronDownIcon />
          </Flex>
        </Tag>
      }
    >
      {options.map((item) => (
        <MenuItemOption
          key={item.value}
          isChecked={!!selectedOption && item.value === selectedOption.value}
          isDisabled={isDisabled}
          item={item}
          prefixAdornment={item.prefixAdornment}
          onClick={() => onChange?.(item.value)}
        />
      ))}
    </ActionMenu>
  );
};

export default TicketStatusSelectField;
