import DataModel, { PropTypes } from 'models/base/DataModel';
import Team from 'models/system/Team';

/**
 * DossierTeam
 */
export default class DossierTeam extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    team: PropTypes.shape(Team.propTypes),
    role: PropTypes.string,
  };

  /**
   * @type {Team}
   */
  team;

  /**
   * @type {string}
   */
  role;

  /**
   * @returns {string}
   */
  getRole() {
    return this.role;
  }

  /**
   * @returns {Team}
   */
  getTeam() {
    return this.team;
  }

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'team') {
      value = Team.createInstance(value);
    }
    return super.set(fieldName, value);
  }
}
