import './assets/styles/Date.scss';

import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

export enum DateAppearanceTypes {
  APPEARANCE_DEFAULT = 'default',
  APPEARANCE_CALENDAR = 'calendar',
  APPEARANCE_SHORT_DATE = 'short_date',
  APPEARANCE_SIMPLE_DATE = 'simple_date',
}

type Props = {
  className?: string;
  date: any;
  format?: string;
  isExpandingDisabled?: boolean;
  appearance?: DateAppearanceTypes;
  autoUpdateEvery?: number;
  onClick?: () => void;
};

/**
 * Create dateString
 *
 * @param {any} date
 * @param {DateAppearanceTypes} appearance
 * @returns {string}
 */
const createDateString = (date: any, appearance: DateAppearanceTypes) => {
  let displayText = moment(date).fromNow();
  let monthText = moment(date).format('MMM').replace(/\./, '');
  monthText = monthText.charAt(0).toUpperCase() + monthText.slice(1);

  switch (appearance) {
    case DateAppearanceTypes.APPEARANCE_CALENDAR:
      displayText = moment(date).calendar(null, {
        sameDay: '[Vandaag]',
        nextDay: '[Morgen]',
        nextWeek: 'dddd',
        lastDay: '[Gisteren]',
        lastWeek: '[Afgelopen] dddd',
        sameElse: 'DD.MM.YYYY',
      });
      break;
    case DateAppearanceTypes.APPEARANCE_SHORT_DATE:
      // the first replace remove all dot of the of the date and the second uppercases the first letter.
      displayText = moment(date).format('D --- YYYY').replace(/---/, monthText);
      break;
    case DateAppearanceTypes.APPEARANCE_DEFAULT:
      // fall back to the fromNow() when we get a formatted date.
      if (displayText === moment(date).format('DD.MM.YYYY')) {
        displayText = moment(date).fromNow();
      }
      break;
    case DateAppearanceTypes.APPEARANCE_SIMPLE_DATE:
      displayText = moment(date).format('DD-MM-YYYY').replace(/---/, monthText);
      break;
    default:
      displayText = moment(date).fromNow();
      break;
  }

  return displayText;
};

/**
 * Date component that will display a friendly date format how many ago the date was, after a click the date will be a
 * normal readable date format.
 *
 * @param {string} className
 * @param {string} date
 * @param {string} appearance
 * @param {string} format
 * @param {boolean} disableExpanding
 * @param {function} onClick
 * @param {number} autoUpdateEvery
 * @return {*}
 * @constructor
 */
const Date: React.FC<Props> = ({
  className,
  date,
  appearance,
  format,
  isExpandingDisabled,
  autoUpdateEvery,
  onClick = () => null,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [displayTextState, setDisplayTextState] = useState(
    createDateString(date, appearance ?? DateAppearanceTypes.APPEARANCE_DEFAULT),
  );

  useEffect(
    () =>
      setDisplayTextState(
        createDateString(date, appearance ?? DateAppearanceTypes.APPEARANCE_DEFAULT),
      ),
    [date, appearance],
  );

  // auto update text at each interval
  useEffect(() => {
    if (autoUpdateEvery === undefined || autoUpdateEvery < 1) {
      return;
    }

    // it is useless set interval for date difference is larger then 1 day.;
    if (moment(moment()).diff(moment(date), 'days') > 1) {
      return;
    }
    const interValRef = setInterval(() => {
      setDisplayTextState(
        createDateString(date, appearance ?? DateAppearanceTypes.APPEARANCE_DEFAULT),
      );
    }, autoUpdateEvery);
    return () => {
      clearInterval(interValRef);
    };
  }, [date, appearance, autoUpdateEvery]);

  return (
    <span
      aria-hidden="true"
      className={classNames('c-date', className || '')}
      role="button"
      tabIndex={0}
      onClick={(event) => {
        if (isExpandingDisabled) {
          onClick();
          return;
        }

        event.stopPropagation();
        event.preventDefault();

        setExpanded(!expanded);
        onClick();
      }}
    >
      {date && (expanded ? moment(date).format(format) : displayTextState)}
    </span>
  );
};

Date.defaultProps = {
  autoUpdateEvery: -1,
  format: 'DD.MM.YYYY HH:mm',
  appearance: DateAppearanceTypes.APPEARANCE_DEFAULT,
  isExpandingDisabled: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick: () => {},
};

export default Date;
