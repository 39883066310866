import type { Icon } from '@elseu/sdu-titan';
import { DrawerContainer, DrawerContent, DrawerWithToggle, Link, Tag } from '@elseu/sdu-titan';
import List from 'components/list/List';
import ListItem from 'components/list/ListItem';
import React from 'react';
import styled from 'styled-components';

export type TagDrawerListItem = {
  key: string;
  name: string;
  to?: string;
  onClick?: () => void;
};

export type TagDrawerListProps = {
  className?: string;
  items: TagDrawerListItem[];
  header?: string;
  width?: number | 'auto' | '100%';
  firstItemType?: 'tag' | 'text';
  moreItemType?: 'tag' | 'text';
  iconLeft?: React.FunctionComponentElement<Icon>;
};

type EmptyElementProps = React.PropsWithChildren<{
  className?: string;
}>;

const TagContainerStyled = styled.div`
  display: inline-flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;
`;

const ClickableTag = styled(Tag)`
  cursor: pointer;
`;

const EmptyElement: React.FC<EmptyElementProps> = ({ className, children }) => (
  <span className={className}>{children}</span>
);

const TagDrawerList: React.FC<TagDrawerListProps> = ({
  className,
  items,
  header,
  width = 480,
  firstItemType = 'tag',
  moreItemType = 'tag',
  iconLeft,
}) => {
  if (items.length === 0) {
    return null;
  }

  const firstItem = items[0];

  const FirstItemElement = firstItemType === 'tag' ? Tag : EmptyElement;

  return (
    <TagContainerStyled>
      {firstItem.to || firstItem.onClick ? (
        <Link
          className={className}
          prefixAdornment={iconLeft}
          to={firstItem.to}
          onClick={firstItem.onClick}
        >
          <FirstItemElement>{firstItem.name}</FirstItemElement>
        </Link>
      ) : (
        <FirstItemElement className={className}>{firstItem.name}</FirstItemElement>
      )}
      {items.length > 1 && (
        <DrawerWithToggle
          button={
            moreItemType === 'text' ? (
              <Link className={className}>+{items.length - 1}</Link>
            ) : (
              <ClickableTag className={className}>+{items.length - 1}</ClickableTag>
            )
          }
          position="right"
          width={width}
        >
          <DrawerContainer header={header}>
            <DrawerContent>
              <List isDivided>
                {items.map((item) => (
                  <ListItem key={item.key}>
                    {item.to || item.onClick ? (
                      <Link to={item.to} onClick={item.onClick}>
                        {item.name}
                      </Link>
                    ) : (
                      item.name
                    )}
                  </ListItem>
                ))}
              </List>
            </DrawerContent>
          </DrawerContainer>
        </DrawerWithToggle>
      )}
    </TagContainerStyled>
  );
};

export default TagDrawerList;
