import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import { normalizeContactNote } from 'entity/contactnote/normalizer';
import type { ContactNote } from 'entity/contactnote/types';
import { useMemo } from 'react';

type useContactNoteDetailsReturnType = {
  contactNote?: ContactNote;
  query: UseQueryResult;
};

export const useContactNoteDetails = (
  contactNoteId: string | undefined,
): useContactNoteDetailsReturnType => {
  const query = useQuery({
    queryKey: ['contactnote', contactNoteId],
    queryFn: async () => {
      const res = await Actions.getAPIService().contactNotes().getContactNote(contactNoteId);
      return normalizeContactNote(res.data);
    },
    enabled: !!contactNoteId,
  });

  return useMemo(
    () => ({
      contactNote: query.data,
      query,
    }),
    [query],
  );
};
