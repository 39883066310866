import { useMutation } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import type { TaskPayload } from 'services/api/TaskApi';

interface useTaskMutationParams {
  id?: string;
  task: TaskPayload;
}

export const useTaskUpdate = () => {
  return useMutation({
    mutationFn: ({ id, task }: useTaskMutationParams) =>
      Actions.getAPIService()
        .tasks()
        .updateTask(id || '', task),
  });
};
