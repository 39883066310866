import type { StatusMessageProps } from '@elseu/sdu-titan';
import type { ReactNode } from 'react';
import { useCallback, useEffect } from 'react';
import { createGlobalState } from 'react-use';

type GlobalMessage = {
  key: string;
  message: ReactNode;
  type?: StatusMessageProps['type'];
  onDismiss?: () => void;
  dismissAfter?: number;
  createdAt: number;
};
type GlobalMessages = GlobalMessage[];
const useGlobalError = createGlobalState<GlobalMessages>([]);

type NewGlobalMessage = Omit<GlobalMessage, 'createdAt'>;

type UseGlobalErrorsReturn = {
  messages: GlobalMessages;
  addMessage: (newMessage: NewGlobalMessage) => void;
  removeMessage: (key: string) => void;
  clear: () => void;
};

// Check for removal in ms
const REMOVAL_INTERVAL_DURATION = 1000;

// Default message display duration in ms
const DEFAULT_MESSAGE_DURATION = 4000;

const isMessageOverdue = (message: GlobalMessage) => {
  return Date.now() > message.createdAt + (message.dismissAfter || DEFAULT_MESSAGE_DURATION);
};

/**
 * Hook to manage global messages.
 *
 * It will add and remove messages from the global state.
 */
export const useGlobalMessages = (): UseGlobalErrorsReturn => {
  const [messages, setMessages] = useGlobalError();

  /**
   * Add a new message to the global state.
   * If a message with the same key already exists, it will be replaced.
   */
  const addMessage = useCallback(
    (newMessage: NewGlobalMessage) => {
      setMessages((prevMessages) => {
        const existingMessageIndex = prevMessages.findIndex(
          (error) => newMessage.key === error.key,
        );

        // remove the existing message if it exists
        if (existingMessageIndex !== -1) {
          prevMessages.splice(existingMessageIndex, 1);
        }

        // add again to the beginning of the array
        return [{ createdAt: Date.now(), ...newMessage }, ...prevMessages];
      });
    },
    [setMessages],
  );

  /**
   * Remove a message from the global state.
   * If the message has a onDismiss callback, it will be called.
   */
  const removeMessage = useCallback(
    (key: string) => {
      setMessages((prevMessages) => {
        const message = prevMessages.find((message) => message.key === key);

        message?.onDismiss?.();

        return prevMessages.filter((message) => message.key !== key);
      });
    },
    [setMessages],
  );

  /**
   * Remove messages that are older than the dismissAfter duration.
   * This will be checked every REMOVAL_INTERVAL_DURATION.
   */
  useEffect(() => {
    const interval = setInterval(() => {
      setMessages((prevMessages) => {
        if (prevMessages.length === 0) {
          return prevMessages;
        }

        const hasMessagesToBeRemoved = prevMessages.some(isMessageOverdue);

        if (!hasMessagesToBeRemoved) return prevMessages;

        return prevMessages.reduce((messagesToKeep, message) => {
          const isOverdue = isMessageOverdue(message);

          if (isOverdue) {
            message.onDismiss?.();
          } else {
            messagesToKeep.push(message);
          }

          return messagesToKeep;
        }, [] as GlobalMessages);
      });
    }, REMOVAL_INTERVAL_DURATION);

    return () => clearInterval(interval);
  }, [setMessages]);

  const clear = useCallback(() => {
    setMessages([]);
  }, [setMessages]);

  return {
    messages,
    addMessage,
    removeMessage,
    clear,
  };
};
