/**
 * Helper method to determine a new item position based on two given other item positions.
 *
 * @param {number|null} itemTopPosition
 * @param {number|null} itemBottomPosition
 * @returns {number}
 */
export const itemDetermineNewPosition = (itemTopPosition = null, itemBottomPosition = null) => {
  // magic number used for the function itemDetermineNewPosition
  const positionMagicNumber = 16384;

  // if both are empty then return the magic number
  if (itemTopPosition == null && itemBottomPosition == null) {
    return positionMagicNumber; // magic number to start a list with.
  }

  // position is the top of the list.
  if (itemTopPosition == null && itemBottomPosition != null) {
    return itemBottomPosition / 2;
  } else if (itemTopPosition != null && itemBottomPosition == null) {
    // bottom of the list.
    return itemTopPosition + positionMagicNumber;
  } else {
    // somewhere in the middle of the list.
    return (itemTopPosition + itemBottomPosition) / 2;
  }
};
