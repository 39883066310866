import type {
  ColumnSort,
  InitialTableState,
  OnChangeFn,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import keyBy from 'lodash/keyBy';
import { useEffect, useMemo, useState } from 'react';
import LocalStorageService from 'services/LocalStorageService';
import type { ApiFilter, TitanTableSortMapping, TitanTableUriState } from 'types/types';

/**
 * Create sort query string of the sort by
 *
 * @param {{id: string, desc: boolean}} sortBy
 * @param sortMapping TitanTableSortMapping[]
 * @returns
 */
export function titanTableSortByForUri(
  sortBy: ColumnSort[],
  sortMapping?: TitanTableSortMapping[],
): string {
  const sortMapped = keyBy(sortMapping ?? [], 'tableAccessor');
  return sortBy
    .map(
      (sort) =>
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (sort.desc ? '-' : '') + (sortMapped[sort.id] ? sortMapped[sort.id].sortName : sort.id),
    )
    .join(',');
}

/**
 * Create a filter of the global filter
 *
 * @param globalFilter
 * @returns
 */
export function titanTableCreateSearchFilter(globalFilter?: string): ApiFilter[] {
  return globalFilter ? [{ name: 'search', value: globalFilter }] : [];
}

/**
 * Extract the state of the table and create object with parameters for in use in a query
 *
 * @param state
 * @param sortMapping TitanTableSortMapping[]
 * @returns
 */
export function titanTableUriElementFromState(
  state: any,
  sortMapping?: TitanTableSortMapping[],
): TitanTableUriState {
  const { pagination, sorting, globalFilter } = state;
  const { pageIndex, pageSize } = pagination;
  return {
    filters: titanTableCreateSearchFilter(globalFilter),
    sort: titanTableSortByForUri(sorting, sortMapping ?? []),
    page: pageIndex + 1,
    pageSize,
  };
}

const storeKey = (stateName: string) => `table_${stateName}`;
const loadDefaultPageSize = (stateName: string, defaultPageSize: number): number =>
  LocalStorageService.getItem(storeKey(stateName), defaultPageSize);
const storeDefaultPageSize = (stateName: string, defaultPageSize: number) =>
  LocalStorageService.setItem(storeKey(stateName), defaultPageSize);

const getPageSizeOrDefault = (
  stateName: string | undefined,
  defaultPagination: PaginationState,
): PaginationState => {
  return stateName
    ? {
        ...defaultPagination,
        pageSize: loadDefaultPageSize(stateName, defaultPagination.pageSize),
      }
    : defaultPagination;
};

type useTitanTableStateParams = {
  defaultSort?: SortingState;
  defaultPagination?: PaginationState;
  stateName?: string;
  sortMapping?: TitanTableSortMapping[];
};

type TableStateObject = {
  pagination?: PaginationState;
  sorting?: SortingState;
  globalFilter?: string;
};

export type TableProviderStateProps = {
  hasPagination?: boolean;
  hasAsyncData: boolean;
  state: TableStateObject;
  onPaginationChange?: OnChangeFn<PaginationState>;
  onSortingChange: OnChangeFn<SortingState>;
  onGlobalFilterChange: OnChangeFn<any>;
  initialState?: InitialTableState;
};

type useTitanTableStateReturn = {
  tableState: TableStateObject;
  filters: ApiFilter[];
  sort: string;
  page: number;
  pageSize: number;
  isSearching: boolean;
  setPaginationState: OnChangeFn<PaginationState>;
  setSortingState: OnChangeFn<SortingState>;
  setGlobalFilterState: OnChangeFn<any>;
  tableProps: TableProviderStateProps;
};

export function useTitanTableState({
  sortMapping,
  defaultSort = [],
  defaultPagination = { pageIndex: 0, pageSize: 10 },
  stateName,
}: useTitanTableStateParams): useTitanTableStateReturn {
  const [paginationState, setPaginationState] = useState<PaginationState>(
    getPageSizeOrDefault(stateName, defaultPagination),
  );
  const [sortingState, setSortingState] = useState<SortingState>(defaultSort);
  const [globalFilterState, setGlobalFilterState] = useState<any>('');
  const [currentStateName, setCurrentStateName] = useState<string | undefined>(stateName);

  useEffect(() => {
    if (stateName && currentStateName !== stateName) {
      setCurrentStateName(stateName);
      const defaultPageSize = loadDefaultPageSize(stateName, defaultPagination.pageSize);
      setPaginationState((value) => ({ ...value, pageSize: defaultPageSize }));
    }
  }, [currentStateName, defaultPagination.pageSize, stateName]);

  useEffect(() => {
    if (stateName) {
      storeDefaultPageSize(stateName, paginationState.pageSize);
    }
  });

  const filters = useMemo(
    () => titanTableCreateSearchFilter(globalFilterState),
    [globalFilterState],
  );
  const sort = titanTableSortByForUri(sortingState, sortMapping ?? []);

  // reset paging when global filter is used or sorting is used.
  useEffect(() => {
    setPaginationState((pagination) => ({ ...pagination, pageIndex: 0 }));
  }, [globalFilterState, sortingState]);

  const tableState = {
    pagination: paginationState,
    sorting: sortingState,
    globalFilter: globalFilterState,
  };

  const isSearching = useMemo(() => globalFilterState !== '', [globalFilterState]);

  return {
    tableState,
    filters,
    sort,
    page: paginationState.pageIndex + 1,
    pageSize: paginationState.pageSize,
    isSearching,
    setPaginationState,
    setSortingState,
    setGlobalFilterState,
    tableProps: {
      hasPagination: true,
      hasAsyncData: true,
      state: tableState,
      onGlobalFilterChange: setGlobalFilterState,
      onPaginationChange: setPaginationState,
      onSortingChange: setSortingState,
    },
  };
}
