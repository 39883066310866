import { useMutation } from '@tanstack/react-query';

import Actions from '../../actions/Actions';
import type { Ticket } from '../../entity/dossiers/types';

export const useUpdateTicket = () => {
  return useMutation({
    mutationFn: ({ id, ticket }: { id: Ticket['id']; ticket: Partial<Ticket> }) =>
      Actions.getAPIService().tickets().updateTicket(id, ticket),
  });
};
