/**
 * Gender value to dutch naming
 *
 * @type {{female: string, male: string}}
 */
export const GENDER_TYPES = {
  male: 'Man',
  female: 'Vrouw',
};

export const getGenderText = (value) => {
  return GENDER_TYPES[value];
};
