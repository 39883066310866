import { useQueryClient } from '@tanstack/react-query';

export const useInvalidateTaskCache = () => {
  const queryClient = useQueryClient();

  return (dossierId: string) => {
    queryClient.invalidateQueries({ queryKey: ['tasks'] });
    queryClient.invalidateQueries({ queryKey: ['tasks-clients'] });
    if (dossierId) {
      queryClient.invalidateQueries({ queryKey: ['dossier-tasks', dossierId] });
    }
  };
};
