import './assets/styles/NotesEditor.scss';

import MarkdownViewer from 'components/markdownviewer/MarkdownViewer';
import RichTextEditor from 'components/richtexteditor/RichTextEditor';
import React, { useImperativeHandle, useState } from 'react';

type Props = {
  isDisabled?: boolean;
  content: string;
  userSuggestions: any[];
  placeholder?: string;
  hasEditorSpellCheck?: boolean;
  isEditorFocused?: boolean;
  isEditorForcedVisible?: boolean;
  onChange: (content: string) => void;
  onPost: (content: string) => void;
  onMentionAdded: (mention: any) => void;
  onEditorFocus: () => void;
  onEditorBlur: () => void;
  onDirty?: () => void;
};

type refHandle = {
  showEditor: (isShown: boolean) => void;
};

/**
 * Notes Editor
 *
 * @param {boolean} isDisabled
 * @param {string} content
 * @param {array} userSuggestions
 * @param {boolean} hasEditorSpellCheck
 * @param {boolean} isEditorForcedVisible
 * @param {boolean} isEditorFocused
 * @param {string} placeholder
 * @param {function} onChange
 * @param {function} onPost
 * @param {function} onMentionAdded
 * @param {function} onEditorFocus
 * @param {function} onEditorBlur
 * @returns {JSX.Element}
 * @constructor
 */
const NotesEditor = React.forwardRef<refHandle, Props>(
  (
    {
      isDisabled = true,
      content,
      userSuggestions,
      placeholder = 'Voeg een beschrijving toe',
      hasEditorSpellCheck = true,
      isEditorFocused = false,
      isEditorForcedVisible,
      onChange = () => null,
      onPost = () => null,
      onMentionAdded = () => null,
      onEditorFocus = () => null,
      onEditorBlur = () => null,
      onDirty = () => null,
    },
    ref,
  ) => {
    const [editorActive, setEditorActive] = useState(false);
    const showEditor = editorActive || content.trim().length === 0 || isEditorForcedVisible;
    const showToolBarOnFocus = content.trim().length === 0;

    useImperativeHandle(ref, () => ({
      showEditor: setEditorActive,
    }));

    return (
      <div className="c-notes-editor">
        {showEditor === true ? (
          <RichTextEditor
            content={content}
            hasEditorSpellCheck={hasEditorSpellCheck}
            isDisabled={isDisabled}
            isFocused={isEditorFocused}
            isToolbarShownOnFocus={showToolBarOnFocus}
            placeholder={placeholder}
            userSuggestions={userSuggestions}
            onBlur={() => {
              setEditorActive(false);
              onEditorBlur();
            }}
            onChange={(newContent) => {
              if (newContent !== content) {
                onChange(newContent);
                onPost(newContent);
              }
            }}
            onEditorStateChange={(newContent) => {
              if (newContent !== content) {
                onDirty();
              }
            }}
            onFocus={() => {
              setEditorActive(true);
              onEditorFocus();
            }}
            onMentionAdded={onMentionAdded}
          />
        ) : (
          <div
            aria-hidden="true"
            className="c-notes-editor__display"
            role="button"
            tabIndex={0}
            onClick={() => {
              setEditorActive(true);
              onEditorFocus();
            }}
          >
            <MarkdownViewer source={content} />
          </div>
        )}
      </div>
    );
  },
);

export default NotesEditor;
