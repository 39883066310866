import type { FilterItemProp } from '@elseu/sdu-titan';
import { FilterCheckbox } from '@elseu/sdu-titan';
import { ticketTypeObject } from 'constants/TicketTypeObject';
import React from 'react';

type Props = FilterItemProp<string | undefined> & {
  hasSearch?: boolean;
};

const items = Object.values(ticketTypeObject).map((ticketType) => ({
  value: ticketType.id,
  label: ticketType.label,
}));

const FilterTicketType: React.FC<Props> = ({
  onChange,
  value,
  hasSearch = true,
  ...otherProps
}) => {
  return (
    <FilterCheckbox
      {...otherProps}
      hasSearch={hasSearch}
      items={items}
      value={value ? [value] : []}
      onChange={(values) => {
        const oldValues = value ? [value] : [];
        const valueDiff = oldValues.filter((x) => values.includes(x));
        const newValues = values.filter((x) => !valueDiff.includes(x));
        onChange(newValues.length > 0 ? newValues[0] : undefined);
      }}
    />
  );
};

export default FilterTicketType;
