import { Button, Drawer, DrawerLoader, EmptyState, Flex } from '@elseu/sdu-titan';
import { useClientDetails } from 'hooks/queries';
import React from 'react';
import styled from 'styled-components';

import { ClientQuickLookContent } from './ClientQuickLookContent';

type Props = {
  isShown: boolean;
  clientId: string;
  onClose: () => void;
};

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: inherit;
`;

export const ClientQuickLookDrawer: React.FC<Props> = ({ isShown, clientId, onClose }) => {
  const { client, query } = useClientDetails(clientId, true, isShown);

  return (
    <Drawer isShown={isShown} position="right" width={720} zIndex={1000010} onClose={onClose}>
      {query.isLoading && <DrawerLoader loadingText="Bezig met laden..." />}
      {query.isSuccess && <ClientQuickLookContent client={client} onClose={onClose} />}
      {query.isError && (
        <NotFoundContainer>
          <Flex alignItems="center" flexDirection="column" justifyContent="center">
            <EmptyState
              isCentered
              description="Klant kan niet worden gevonden omdat deze mogelijk verwijderd is."
              illustration="looking-glass"
              title="Klant niet gevonden"
            >
              <Button variant="clear" onClick={onClose}>
                Sluiten
              </Button>
            </EmptyState>
          </Flex>
        </NotFoundContainer>
      )}
    </Drawer>
  );
};
