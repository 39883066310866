import { Clickable } from '@elseu/sdu-titan';
import React, { useState } from 'react';

import { ClientQuickLookDrawer } from './quicklook/ClientQuickLookDrawer';

type Props = {
  clientId?: string;
};

export const ClientQuickLook: React.FC<React.PropsWithChildren<Props>> = ({
  clientId,
  children,
}) => {
  const [isShown, setIsShown] = useState<boolean>(false);

  if (!clientId) {
    return <>{children}</>;
  }

  return (
    <>
      <Clickable onClick={() => setIsShown(true)}>{children}</Clickable>
      <ClientQuickLookDrawer
        clientId={clientId}
        isShown={isShown}
        onClose={() => setIsShown(false)}
      />
    </>
  );
};
