import type { FilterItemProp } from '@elseu/sdu-titan';
import { Box, CheckboxTree, FilterItemsWithSearchContainer, Loader } from '@elseu/sdu-titan';
import { useQuery } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import { normalizeClient } from 'entity/crm/normalizer';
import type { Client } from 'entity/crm/types';
import { normalizeOverviewResults } from 'helpers/normalizehelper';
import debounce from 'lodash/debounce';
import React, { useMemo, useState } from 'react';
import type JSendResponse from 'services/JSendResponse';

type Props = FilterItemProp<string | undefined>;

const FilterClient: React.FC<Props> = ({ value, onChange }) => {
  const [search, setSearch] = useState<string>('');
  const query = useQuery({
    queryKey: ['filter-client', search],
    queryFn: () =>
      Actions.getAPIService()
        .getCrm()
        .clientsSearch(search)
        .then((response: JSendResponse) =>
          normalizeOverviewResults(response.getData(), 'clients', normalizeClient),
        ),
  });

  const items: any[] = useMemo(() => {
    if (!query.data) {
      return [];
    }

    const retValue = query.data.results.map((client: Client) => ({
      label: client.displayName ?? '',
      value: client.id,
    }));
    return retValue;
  }, [query.data]);

  return (
    <FilterItemsWithSearchContainer onSearchChange={debounce(setSearch, 500)}>
      {query.isLoading ? (
        <Box px={8} py={8}>
          <Loader height={72} variant="spinner" />
        </Box>
      ) : (
        <CheckboxTree
          data={items}
          value={value ? [value] : undefined}
          onChange={(value) => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (value.length > 0 && value[0] === null) {
              onChange(undefined);
            }
            onChange(value.length > 0 ? value[0] : undefined);
          }}
        />
      )}
    </FilterItemsWithSearchContainer>
  );
};

export default FilterClient;
