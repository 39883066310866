import DataModel, { PropTypes } from 'models/base/DataModel';

/**
 * Paging
 */
export default class Paging extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    previousPage: PropTypes.number,
    page: PropTypes.number,
    nextPage: PropTypes.number,
    maxPerPage: PropTypes.number,
    numberOfResults: PropTypes.number,
    totalPages: PropTypes.number,
  };

  /**
   * @type {number}
   */
  previousPage = 1;

  /**
   * @type {number}
   */
  page = 1;

  /**
   * @type {number}
   */
  nextPage = 1;

  /**
   * @type {number}
   */
  maxPerPage = 25;

  /**
   * @type {number}
   */
  numberOfResults = 0;

  /**
   * @type {number}
   */
  totalPages = 1;

  /**
   * @return {number}
   */
  getPreviousPage() {
    return this.previousPage;
  }

  /**
   * @return {number}
   */
  getPage() {
    return this.page;
  }

  /**
   * @return {number}
   */
  getNextPage() {
    return this.nextPage;
  }

  /**
   * @return {number}
   */
  getMaxPerPage() {
    return this.maxPerPage;
  }

  /**
   * @return {number}
   */
  getNumberOfResults() {
    return this.numberOfResults;
  }

  /**
   * @return {number}
   */
  getTotalPages() {
    return this.totalPages;
  }

  /**
   * @returns {boolean}
   */
  hasNextPage() {
    return this.page < this.totalPages;
  }
}
