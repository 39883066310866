import './assets/styles/OptionSeparator.scss';

import classNames from 'classnames';
import React from 'react';

type Props = React.PropsWithChildren<{
  className?: string;
  isDisabled?: boolean;
}>;

/**
 * Option item
 *
 * @param {string} className
 * @param {boolean} disabled
 * @param {*} children
 * @returns {*}
 * @constructor
 */
const OptionSeparator: React.FC<Props> = ({ className, isDisabled = false, children }) => (
  <li
    className={classNames('c-option-separator', className, {
      'c-option-separator-disabled': isDisabled,
    })}
  >
    <span className="c-option-separator__label">{children}</span>
  </li>
);

export default OptionSeparator;
