import { Box, Divider, Flex, ListItem, Text } from '@elseu/sdu-titan';
import React from 'react';
import styled from 'styled-components';

import type { IPermission } from './helpers';
import { createPermissionAvatar, IPermissionRole } from './helpers';
import type { IPermissionRoleSelect } from './PermissionRoleSelect';
import { PermissionRoleSelect } from './PermissionRoleSelect';

const PermissionRowItem = styled(Flex)`
  min-height: 40px;
`;

type IPermissionRow = {
  isDisabled: boolean;
  isSingleAdmin?: boolean;
  permission: IPermission;
  disabledRoles?: IPermissionRoleSelect['disabledRoles'];
  onRemove: (permission: IPermission) => void;
  onChange: (permission: IPermission) => void;
};

export const PermissionRow = ({
  isDisabled = false,
  isSingleAdmin = false,
  disabledRoles,
  permission,
  onRemove,
  onChange,
}: IPermissionRow) => (
  <>
    <ListItem
      disabled={isDisabled}
      prefixAdornment={
        <Flex alignItems="center">
          <Box pl={6} pr={2}>
            {createPermissionAvatar(permission)}
          </Box>
        </Flex>
      }
    >
      <PermissionRowItem alignItems="center" justifyContent="space-between" py={1}>
        <Box>
          <Text isBlock color="grey80" type="labelBold">
            {permission.name}
          </Text>
          {permission.email && (
            <Text isBlock color="grey70" type="label">
              {permission.email}
            </Text>
          )}
        </Box>
        <Flex alignItems="center" style={{ opacity: isDisabled ? 0.2 : 1 }}>
          <PermissionRoleSelect
            disabledRoles={disabledRoles}
            isDisabled={isDisabled}
            isSingleAdmin={isSingleAdmin}
            role={permission.role}
            onChange={(value) => {
              if (
                value === IPermissionRole.ROLE_OWNER ||
                value === IPermissionRole.ROLE_EDITOR ||
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                value === IPermissionRole.ROLE_VIEWER
              ) {
                onChange({ ...permission, role: value });
              }
            }}
            onRemove={() => onRemove(permission)}
          />
        </Flex>
      </PermissionRowItem>
    </ListItem>
    <ListItem>
      <Divider />
    </ListItem>
  </>
);
