import './assets/styles/TextField.scss';

import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

type Props = {
  id?: string;
  value?: string;
  hasError?: boolean;
  isFocused?: boolean;
  isDisabled?: boolean;
  isSecured?: boolean;
  isCompact?: boolean;
  isClearable?: boolean;
  className?: string;
  placeholder?: string;
  type?: string;
  onChange: (value?: string) => void;
  onEnter?: (value?: string) => void;
  onClear?: () => void;
  [key: string]: any;
};

/**
 * TextField
 *
 * @param {string} id
 * @param {string} value
 * @param {boolean} error
 * @param {boolean} isSecured
 * @param {boolean} isFocused
 * @param {boolean} isDisabled
 * @param {boolean} isReadOnly
 * @param {boolean} isCompact
 * @param {string} className
 * @param {string} type
 * @param {string} placeholder
 * @param {bool} isClearable
 * @param {function} onChange
 * @param {function} onEnter
 * @param {function} onClear
 * @param {object} otherProps
 * @return {*}
 * @constructor
 */
const TextField: React.FC<Props> = ({
  id,
  value,
  hasError,
  isSecured,
  isCompact = true,
  isFocused,
  isDisabled,
  isReadOnly,
  className,
  type = 'text',
  placeholder,
  isClearable = false,
  onChange,
  onEnter,
  onClear,
  ...otherProps
}) => {
  const textFieldRef = useRef<any | null>(null);

  // make sure this text field will actually get the focus if the focus property changes.
  useEffect(() => {
    if (textFieldRef.current) {
      if (isFocused) {
        textFieldRef.current.focus();
      } else {
        textFieldRef.current.blur();
      }
    }
  }, [isFocused]);

  return (
    <div
      className={classNames('c-text-field', className, {
        'c-text-field--disabled': isDisabled,
        'c-text-field--compact': isCompact,
        'c-text-field--error': hasError,
        'c-text-field--clearable': isClearable,
      })}
    >
      <input
        {...otherProps}
        ref={textFieldRef}
        className="c-text-field__input"
        disabled={isDisabled}
        id={id}
        placeholder={placeholder}
        readOnly={isReadOnly}
        type={isSecured ? 'password' : type}
        value={value || ''}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && onEnter) {
            onEnter(value);
          }
        }}
      />
      {isClearable && value !== '' && value !== undefined && (
        <div
          aria-hidden="true"
          className={classNames('c-text-field__clearable', {
            'c-text-field__clearable--disabled': isDisabled,
          })}
          role="button"
          tabIndex={0}
          onClick={() => {
            if (!isDisabled && onClear) {
              onClear();
            }
          }}
        >
          <svg height="16" viewBox="0 0 10 10" width="16" xmlns="http://www.w3.org/2000/svg">
            {/* eslint-disable-next-line max-len */}
            <path
              clipRule="evenodd"
              d="M7.07859 1.50716L7.78569 0.800049L9.19991 2.21426L8.4928 2.92137L6.41418 4.99998L8.4928 7.0786L9.19991 7.7857L7.78569 9.19992L7.07858 8.49281L4.99997 6.4142L2.92137 8.4928L2.21426 9.1999L0.80005 7.78569L1.50716 7.07858L3.58576 4.99998L1.50716 2.92138L0.800049 2.21427L2.21426 0.800061L2.92137 1.50717L4.99997 3.58577L7.07859 1.50716Z"
              fillRule="evenodd"
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default TextField;
