import './assets/styles/TagList.scss';

import classNames from 'classnames';
import TagListItem from 'components/taglist/TagListItem';
import React from 'react';

type Props = {
  className?: string;
  tags?: string[];
  isClickable?: boolean;
  isSingleNode?: boolean;
  canDelete?: boolean;
  onDelete?: (key?: number, tag?: string) => void;
};

/**
 * TagList
 */
const TagList: React.FC<Props> = ({
  className,
  tags,
  isClickable = true,
  isSingleNode = false,
  canDelete,
  onDelete = () => null,
}) =>
  !isSingleNode ? (
    <ul className={classNames('c-tag-list', className)}>
      {tags?.map((tag, key) => (
        <TagListItem
          // eslint-disable-next-line react/no-array-index-key
          key={`tag-${key}-${tag}`}
          canDelete={canDelete}
          className={classNames('c-tag-list__tag', { 'c-tag-list__clickable': isClickable })}
          element="li"
          onDelete={() => onDelete(key, tag)}
        >
          {tag}
        </TagListItem>
      ))}
    </ul>
  ) : (
    <TagListItem
      canDelete={canDelete}
      className={classNames('c-tag-list__single-node', className, {
        'c-tag-list__clickable': isClickable,
      })}
      element="ul"
      onDelete={onDelete}
    >
      {tags?.map((tag, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={`tag-${key}-${tag}`} className="c-tag-list__tag">
          {tag}
        </li>
      ))}
    </TagListItem>
  );

export default TagList;
