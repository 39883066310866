import { Flex, Link, List, ListItem, media } from '@elseu/sdu-titan';
import { useResultContext } from '@elseu/sdu-titan-search';
import ApplicationConstants from 'constants/ApplicationConstants';
import React from 'react';
import styled from 'styled-components';

const NdfrSearchContainer = styled.div``;

const NdfrSearchFooter = styled(Flex)`
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.components.autocompleteInput.backgroundColor};
  border-top: ${({ theme }) => theme.components.autocompleteInput.border};
  padding: ${({ theme }) => theme.components.inPageSearch.controlsPaddingMobile};
`;

const NdfrSearchFooterLink = styled(Link)`
  display: block;
  margin: auto;
  padding: ${({ theme }) => theme.components.inPageSearch.searchLinkPadding};
`;

const SuggesionListItem = styled(ListItem)`
  margin: ${({ theme }) => theme.components.autocompleteSuggestions.suggestionItem.margin};
  padding: ${({ theme }) => theme.components.autocompleteSuggestions.suggestionItem.padding};

  ${({ theme }) => media.lessThan('s')`
    padding: ${theme.components.autocompleteSuggestions.suggestionItem.mobilePadding};
  `}

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) =>
      theme.components.autocompleteSuggestions.suggestionItem.backgroundColorHover};
  }
`;

type Props = {
  onItemSelect: (item: any) => void;
};

const NdfrSearchResults: React.FC<Props> = ({ onItemSelect }) => {
  const { results, query } = useResultContext();
  const resultsSliced = results.slice(0, 3);

  return (
    <NdfrSearchContainer>
      <List variant="horizontal-stretch">
        {resultsSliced.map((result) => (
          <SuggesionListItem key={result.id} onClick={() => onItemSelect(result)}>
            {result.title}
          </SuggesionListItem>
        ))}
      </List>
      <NdfrSearchFooter align-items="center">
        <NdfrSearchFooterLink
          target="_blank"
          to={`${ApplicationConstants.APPLICATION_NDFR_DOMAIN}/zoeken?query=${encodeURIComponent(
            query,
          )}`}
        >
          Zoek verder in NDFR
        </NdfrSearchFooterLink>
      </NdfrSearchFooter>
    </NdfrSearchContainer>
  );
};

export default NdfrSearchResults;
