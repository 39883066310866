import type { AsyncSelectOptionProps } from '../AsyncSelectField';

export const mapItemsToSelectOptions = (items?: any[]): AsyncSelectOptionProps[] | null => {
  if (!items) return null;
  return items.map((item) => ({
    label: item.name || '',
    value: item.id || '',
  }));
};

export const mapItemToSelectOption = (item: any): AsyncSelectOptionProps | null => {
  if (!item) return null;
  return {
    label: item.name || '',
    value: item.id || '',
  };
};
