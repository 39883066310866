import './assets/styles/ContactNoteTable.scss';

import type { TableColumns } from '@elseu/sdu-titan';
import {
  ActionMenu,
  Button,
  Link,
  MenuItem,
  PlusIcon,
  StatusMessage,
  Table,
  TableCommandBar,
  TableProvider,
} from '@elseu/sdu-titan';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import classNames from 'classnames';
import { ClientQuickLook } from 'components/client/ClientQuickLook';
import ColorTextLabel from 'components/colorlabel/ColorTextLabel';
import ConfirmDialog from 'components/confirmdialog/ConfirmDialog';
import Date, { DateAppearanceTypes } from 'components/date/Date';
import ArchiveIcon from 'components/icons/ArchiveIcon';
import { Lazy } from 'components/lazy/Lazy';
import Radio from 'components/radio/Radio';
import TagDrawerList from 'components/tagdrawerlist/TagDrawerList';
import { normalizeContactNoteTableItem } from 'entity/table/normalizer';
import type { ContactNoteTableItem } from 'entity/table/types';
import type { TableProviderStateProps } from 'helpers/TitanTableHelpers';
import Dossier from 'models/dossier/Dossier';
import React, { lazy, useMemo, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

export type { ContactNoteTableItem };
export { normalizeContactNoteTableItem };

const CrmClientViewContactNoteDetails = lazy(
  () => import('../../containers/crm/src/client/CrmClientViewContactNoteDetails'),
);
const ContactNoteCreateContainer = lazy(
  () => import('../../containers/contactnote/src/ContactNoteCreateContainer'),
);
const ContactNoteEditContainer = lazy(
  () => import('../../containers/contactnote/src/ContactNoteEditContainer'),
);
const ContactNoteMailToContainer = lazy(
  () => import('../../containers/contactnote/src/ContactNoteMailToContainer'),
);

type Props = {
  data: ContactNoteTableItem[];
  isDisabled?: boolean;
  isLoading: boolean;
  totalCount: number;
  view: 'client' | 'contactnotes' | 'relatedCard';
  defaultPageSize?: number;
  shouldDisableRoutes?: boolean;
  shouldHideNewButton?: boolean;
  commandBarContent?: React.ReactNode;
  tableStateProps: TableProviderStateProps;
};

const columnViewSettings: Record<string, string[]> = {
  client: ['name', 'dossiers', 'updatedAt', 'labelSort', 'isArchived', 'id'],
  contactnotes: ['name', 'client', 'dossiers', 'createdAt', 'labelSort', 'isArchived', 'id'],
  relatedCard: ['cardId', 'name', 'assignedUserFullName', 'updatedAt', 'labelSort', 'isArchived'],
};

const ContactNoteTable: React.FC<Props> = ({
  data,
  isLoading,
  isDisabled = false,
  shouldDisableRoutes = false,
  shouldHideNewButton = false,
  view,
  defaultPageSize = 10,
  totalCount = 0,
  commandBarContent,
  tableStateProps,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState<boolean>(false);
  const [deleteContext, setDeleteContext] = useState<ContactNoteTableItem | undefined>();
  const [deleteErrorMessage, setDeleteErrorMessage] = useState<string | undefined>(undefined);

  const onSuccessHandler = (_data: any, contactItem: ContactNoteTableItem) => {
    queryClient.invalidateQueries({
      queryKey: ['client-contactnotes'],
    });
    queryClient.invalidateQueries({
      queryKey: ['contactnotes'],
    });
    queryClient.invalidateQueries({
      queryKey: ['contactnote', contactItem.id],
    });
  };

  const archiveToggleContactNote = useMutation({
    mutationFn: (contactItem: ContactNoteTableItem) => {
      if (contactItem.isArchived) {
        return Actions.getAPIService().contactNotes().unarchiveContactNote(contactItem.id);
      }
      return Actions.getAPIService().contactNotes().archiveContactNote(contactItem.id);
    },
    onSuccess: onSuccessHandler,
  });

  const deleteContactNote = useMutation({
    mutationFn: (contactItem: ContactNoteTableItem) =>
      Actions.getAPIService().contactNotes().deleteContactNote(contactItem.id),
    onSuccess: (_data: any, contactItem: ContactNoteTableItem) => {
      onSuccessHandler(_data, contactItem);
      setIsDeleteConfirmOpen(false);
      setDeleteContext(undefined);
      setDeleteErrorMessage(undefined);
    },
    onError: (error: any) => setDeleteErrorMessage(error.message),
  });

  const usableColumns: TableColumns<ContactNoteTableItem> = useMemo(
    () => [
      {
        id: 'cardId',
        header: ' ',
        accessorKey: 'cardId',
        enableSorting: false,
        size: 16,
        cell: ({ row }) => <Radio value={row.original.cardId} />,
      },
      {
        id: 'name',
        header: 'Onderwerp',
        accessorKey: 'name',
        cell: ({ row }) => (
          <Link
            className={classNames({
              'c-contact-note-table__archived-item': row.original.isArchived,
            })}
            to={`view/${row.original.id}`}
          >
            {row.original.name}
          </Link>
        ),
      },
      {
        id: 'assignedUserFullName',
        header: 'Gekoppeld aan',
        accessorKey: 'assignedUserFullName',
      },
      {
        id: 'client',
        header: 'Klant',
        size: 250,
        accessorKey: 'client',
        cell: ({ row }) =>
          row.original.client?.map((item: any) => (
            <ClientQuickLook key={`client_${item.id}`} clientId={item.id}>
              <Link
                className={classNames({
                  'c-contact-note-overview__archived-item': row.original.isArchived,
                })}
              >
                {item.displayName}
              </Link>
            </ClientQuickLook>
          )),
      },
      {
        id: 'dossiers',
        header: 'Dossier',
        accessorKey: 'dossiers',
        enableSorting: false,
        size: 300,
        cell: ({ row }) => (
          <TagDrawerList
            className={classNames({
              'c-contact-note-table__archived-item': row.original.isArchived,
            })}
            firstItemType="text"
            header="Dossier's"
            items={(row.original.dossiers ?? []).map((item) => ({
              key: item.id,
              name: item.name,
              to: Dossier.createLastVisitPhaseUrl(item.id),
            }))}
          />
        ),
      },
      {
        id: 'updatedAt',
        header: 'Gewijzigd op',
        accessorKey: 'updatedAt',
        size: 160,
        cell: ({ row }) => (
          <Date
            appearance={DateAppearanceTypes.APPEARANCE_SHORT_DATE}
            className={classNames({
              'c-contact-note-table__archived-item': row.original.isArchived,
            })}
            date={row.original.updatedAt}
          />
        ),
      },
      {
        id: 'createdAt',
        header: 'Aangemaakt op',
        accessorKey: 'createdAt',
        size: 160,
        cell: ({ row }) => (
          <Date
            appearance={DateAppearanceTypes.APPEARANCE_SHORT_DATE}
            className={classNames({
              'c-contact-note-table__archived-item': row.original.isArchived,
            })}
            date={row.original.createdAt}
          />
        ),
      },
      {
        id: 'labelSort',
        header: 'Status',
        accessorKey: 'labelSort',
        enableSorting: false,
        size: 200,
        cell: ({ row }) => (
          <ColorTextLabel isSmall color={row.original.label?.color ?? ''}>
            {row.original.label?.name}
          </ColorTextLabel>
        ),
      },
      {
        id: 'isArchived',
        header: ' ',
        accessorKey: 'isArchived',
        size: 20,
        enableSorting: false,
        cell: ({ row }) =>
          row.original.isArchived ? (
            <ArchiveIcon color="secondary-dark-grey" size={16} title="Gearchiveerd" />
          ) : (
            <span />
          ),
      },
      {
        id: 'id',
        header: ' ',
        accessorKey: 'id',
        size: 20,
        enableSorting: false,
        cell: ({ row }) => (
          <ActionMenu
            defaultShown={false} // temp-fix to get ActionMenu working
            disabled={isDisabled}
            label="Acties"
            popoverOptions={{
              strategy: 'fixed',
            }}
            popoverPlacement="bottom-end"
          >
            <MenuItem
              item={{
                label: 'Bekijken',
              }}
              onClick={() => navigate(`view/${row.original.id}`)}
            />
            <MenuItem
              isDisabled={!row.original.canEdit}
              item={{
                label: 'Bewerken',
              }}
              onClick={() => navigate(`edit/${row.original.id}`)}
            />
            <MenuItem
              isDisabled={!row.original.canEdit}
              item={{
                label: 'Mail naar notitie',
              }}
              onClick={() => navigate(`mailto/${row.original.id}`)}
            />
            <MenuItem
              isDisabled={!row.original.canEdit}
              item={{
                label: row.original.isArchived ? 'Heropenen' : 'Archiveren',
              }}
              onClick={() => archiveToggleContactNote.mutate(row.original)}
            />
            <MenuItem
              isDisabled={!row.original.canDelete}
              item={{
                label: 'Verwijderen',
              }}
              onClick={() => {
                setDeleteContext(row.original);
                setDeleteErrorMessage(undefined);
                setIsDeleteConfirmOpen(true);
              }}
            />
          </ActionMenu>
        ),
      },
    ],
    [navigate, isDisabled, archiveToggleContactNote],
  );

  const columns: TableColumns<ContactNoteTableItem> = useMemo(() => {
    const fieldsToFilter = columnViewSettings[view];
    return usableColumns.filter((column) =>
      column.id ? fieldsToFilter.includes(column.id) : false,
    );
  }, [view, usableColumns]);

  return (
    <>
      <TableProvider
        key={`contact-note-overview-${view}-${defaultPageSize}`}
        columns={columns}
        data={data}
        isLoading={isLoading}
        totalCount={totalCount}
        {...tableStateProps}
      >
        <TableCommandBar
          buttons={
            !shouldHideNewButton
              ? [
                  <Button
                    key="new"
                    iconLeft={<PlusIcon />}
                    isDisabled={isDisabled}
                    onClick={() => navigate('new')}
                  >
                    Nieuwe notitie
                  </Button>,
                ]
              : []
          }
        >
          {commandBarContent}
        </TableCommandBar>
        <Table
          emptyStateProps={{
            title: 'Geen notities',
            description: 'Er geen zijn notities gevonden.',
          }}
          width="100%"
        />
      </TableProvider>
      <ConfirmDialog
        cancelTitle="Nee"
        confirmAppearance="danger"
        confirmTitle="Ja, verwijder"
        isDisabled={deleteContactNote.isPending}
        isShown={isDeleteConfirmOpen}
        title="Verwijder notitie"
        onCancel={() => {
          setIsDeleteConfirmOpen(false);
          setDeleteContext(undefined);
        }}
        onConfirm={() => {
          deleteContext && deleteContactNote.mutate(deleteContext);
        }}
      >
        <StatusMessage isShown={!!deleteErrorMessage} spaceAfter={4} type="danger">
          {deleteErrorMessage}
        </StatusMessage>
        Weet u zeker dat u deze notitie <strong>{deleteContext?.name}</strong> wil verwijderen?
      </ConfirmDialog>

      {!shouldDisableRoutes && (
        <Routes>
          <Route
            element={
              <Lazy>
                <ContactNoteCreateContainer />
              </Lazy>
            }
            path="new"
          />
          <Route
            element={
              <Lazy>
                <CrmClientViewContactNoteDetails />
              </Lazy>
            }
            path="view/:contactNoteId/*"
          />
          <Route
            element={
              <Lazy>
                <ContactNoteEditContainer />
              </Lazy>
            }
            path="edit/:contactNoteId"
          />
          <Route
            element={
              <Lazy>
                <ContactNoteMailToContainer />
              </Lazy>
            }
            path="mailto/:contactNoteId"
          />
        </Routes>
      )}
    </>
  );
};

export default ContactNoteTable;
