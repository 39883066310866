import './assets/styles/CommentEditor.scss';

import classNames from 'classnames';
import Avatar, { AvatarSizesEnum } from 'components/avatar/Avatar';
import RemoveIcon from 'components/icons/RemoveIcon';
import ListIcon from 'components/listicon/ListIcon';
import RichTextEditor from 'components/richtexteditor/RichTextEditor';
import type { ContextItem, ContextItemAttachment } from 'entity/dossiers/types';
import { filesize } from 'filesize';
import React, { useCallback, useEffect, useState } from 'react';
import type { FileRejection } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { v1 as uuidv1 } from 'uuid';

type CommentContextItemProps = {
  error?: string;
  context: ContextItem;
  isDisabled: boolean;
  isDeleting: boolean;
  onDelete?: () => void;
};

type CommentAddContextButtonProps = {
  isDisabled: boolean;
  // eslint-disable-next-line react/boolean-prop-naming
  error?: any;
  contextMaxFileSize?: number;
  context?: ContextItem;
  onChange: (attachment: ContextItemAttachment) => void;
};

type CommentEditorProps = {
  isDisabled?: boolean;
  avatar?: string;
  content: string;
  error?: string;
  context?: ContextItem;
  onContextChange?: (contextItem: ContextItem, content: string) => void;
  onContextDelete?: (contextItem: ContextItem, content: string) => void;
  isDeletingContext?: boolean;
  contextMaxFileSize?: number;
  userSuggestions?: any[];
  // eslint-disable-next-line react/boolean-prop-naming
  editorHideSubmit?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming
  editorHideAttachments?: boolean;
  isEditorAlwaysCollapsed?: boolean;
  hasEditorSpellCheck?: boolean;
  isEditorFocused?: boolean;
  onChange: (content: string, context: ContextItem | undefined) => void;
  onPost?: (content: string | undefined, context: ContextItem | undefined) => void;
  onMentionAdded?: (mention: any) => void;
  onEditorFocus?: () => void;
  onEditorBlur?: () => void;
};

const CommentContextItem: React.FC<CommentContextItemProps> = ({
  error,
  context,
  isDisabled,
  isDeleting,
  onDelete,
}) => {
  return (
    <div className="c-comments-editor__context">
      <div
        className={classNames('c-comments-editor__context-item', {
          'c-comments-editor__context-item--with-description': context.description,
          'c-comments-editor__context-item--deleting': isDeleting,
          'c-comments-editor__context-item--error': error,
        })}
        title={error || ''}
      >
        <ListIcon
          className="c-comments-editor__context-item__icon"
          type={context.iconName || context.type}
        />
        <span className="c-comments-editor__context-item__title">
          {onDelete && (
            <span
              aria-hidden="true"
              className="c-comments-editor__context-item__remove"
              role="button"
              tabIndex={0}
              onClick={() => {
                if (isDisabled) {
                  return;
                }
                if (!isDeleting) {
                  onDelete();
                }
              }}
            >
              <RemoveIcon />
            </span>
          )}
          <span className="c-comments-editor__context-item__title__text">{context.name}</span>
        </span>
      </div>
    </div>
  );
};

const CommentAddContextButton: React.FC<CommentAddContextButtonProps> = ({
  isDisabled,
  error,
  contextMaxFileSize = 60000000,
  context,
  onChange,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[], _rejectedFiles: FileRejection[]) => {
      acceptedFiles.forEach((file: File) => {
        const attachment: ContextItemAttachment = {
          uploadId: uuidv1(),
          name: file.name,
          type: 'attachment',
          contextType: 'attachment',
          displaySize: filesize(file.size, { round: 0 }).toString(),
          iconName: 'file',
          file,
          rejected: false,
          reason: '',
        };

        onChange(attachment);
      });
    },
    [onChange],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: isDisabled || context !== undefined,
    multiple: false,
    maxSize: contextMaxFileSize,
    noDrag: true,
  });

  return (
    <div
      {...getRootProps({
        className: classNames(
          'c-comments-editor__attachment_button c-rich-text-editor__toolbar__button',
          {
            control__attachment__disabled: isDisabled || context !== undefined,
            control__attachment__error: error,
          },
        ),
      })}
    >
      <input {...getInputProps()} />
    </div>
  );
};

const CommentEditor: React.FC<CommentEditorProps> = ({
  isDisabled = false,
  avatar,
  content,
  error,
  context,
  onContextChange = () => null,
  onContextDelete = () => null,
  isDeletingContext = false,
  contextMaxFileSize,
  userSuggestions,
  editorHideSubmit,
  editorHideAttachments = false,
  isEditorAlwaysCollapsed = false,
  hasEditorSpellCheck = true,
  isEditorFocused,
  onChange,
  onPost,
  onMentionAdded,
  onEditorFocus = () => null,
  onEditorBlur = () => null,
}) => {
  const [contentState, setContentState] = useState<string | undefined>(content);
  useEffect(() => setContentState(content), [content]);

  return (
    <div className="c-comments-editor">
      {avatar && (
        <div className="c-comments-editor__avatar">
          <Avatar isRounded size={AvatarSizesEnum.SIZE_LARGE} url={avatar} />
        </div>
      )}
      <div className="c-comments-editor__editor">
        <RichTextEditor
          content={contentState}
          contextItem={
            context && (
              <CommentContextItem
                context={context}
                isDeleting={isDeletingContext}
                isDisabled={isDisabled}
                onDelete={() => onContextDelete(context, content)}
              />
            )
          }
          extraToolbarButtons={
            !editorHideAttachments && (
              <CommentAddContextButton
                context={context}
                contextMaxFileSize={contextMaxFileSize}
                error={error}
                isDisabled={isDisabled && !contentState}
                onChange={(newContext) => onContextChange(newContext, content)}
              />
            )
          }
          hasEditorSpellCheck={hasEditorSpellCheck}
          isDisabled={isDisabled}
          isEditorSubmitHidden={editorHideSubmit}
          isFocused={isEditorFocused}
          isToolbarShownOnFocus={!isEditorAlwaysCollapsed}
          placeholder="Voeg een notitie of aantekening toe"
          postButtonTitle="Plaats"
          userSuggestions={userSuggestions}
          onBlur={() => onEditorBlur()}
          onChange={(newContent) => {
            setContentState(newContent);
            onChange(newContent, context);
          }}
          onFocus={() => onEditorFocus()}
          onMentionAdded={onMentionAdded}
          onPost={(newContent) => {
            setContentState(newContent);
            // small timeout before saving.
            if (onPost) {
              setTimeout(() => onPost(newContent, context), 250);
            }
          }}
        />
      </div>
    </div>
  );
};

export default CommentEditor;
