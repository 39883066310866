import type { AnimateLayoutChanges } from '@dnd-kit/sortable';
import {
  defaultAnimateLayoutChanges,
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Flex, Link, PlusIcon, Text, withTitan } from '@elseu/sdu-titan';
import type { Row } from '@tanstack/react-table';
import CreateTicketMenu from 'components/ticket/CreateTicketMenu';
import { useKanban } from 'containers/tickets/src/hooks/useKanban';
import type { Ticket } from 'entity/dossiers/types';
import React from 'react';
import styled from 'styled-components';

import { useTicketStatuses } from '../../containers/tickets/src/hooks/useTicketStatuses';
import { TicketErrorBoundary } from '../../containers/tickets/src/TicketErrorBoundary';
import { KanbanCard } from './KanbanCard';

export interface KanbanColumnProps {
  id: string;
  items: any[];
  name: string;
  isAddAllowed?: boolean;
  style?: string;
  subtitle?: string;
}

const StyledFlex = styled(Flex)`
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  overflow-y: scroll;
`;

const KanbanColumnDroppable = styled(Flex)`
  overflow: auto;

  &[data-hover='true'] {
    background-color: ${({ theme }) => theme.designTokens.colors.grey10};
  }
`;

const KanbanColumnContainer = styled(Flex)`
  height: 100%;
`;

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

const KanbanColumn = withTitan<KanbanColumnProps>(
  ({ id, name, isAddAllowed, items, subtitle, style }) => {
    const { active, isDragging, over, setNodeRef, transition, transform } = useSortable({
      id,
      data: {
        type: 'container',
        children: items,
      },
      animateLayoutChanges,
    });

    const { getFirstStatusFromStatusGroup } = useTicketStatuses();

    const { isDragging: isCardDragging, onTicketCreate } = useKanban<Row<Ticket>>();

    const isOverContainer = over
      ? (id === over.id && active?.data.current?.type !== 'container') ||
        !!items.find((item) => item.id === over.id)
      : false;

    return (
      <KanbanColumnContainer flex={1} flexDirection="column" px={1}>
        <Flex justifyContent="space-between" pb={2} px={2}>
          <Flex gap={2}>
            <Text color="grey80" type="labelBold">
              {name}
            </Text>
            <Text color="grey70" type="label">
              {items.length}
            </Text>
          </Flex>
          {subtitle && (
            <Text color="grey70" type="labelSmall">
              {subtitle}
            </Text>
          )}
        </Flex>

        <KanbanColumnDroppable
          ref={setNodeRef}
          data-dragging={isCardDragging}
          data-hover={isOverContainer}
          flex={1}
          flexDirection="column"
          justifyContent="stretch"
          style={{
            transition,
            transform: CSS.Translate.toString(transform),
            opacity: isDragging ? 0.5 : undefined,
          }}
        >
          {items.length > 0 && (
            <StyledFlex flexDirection="column" gap={4} justifyContent="stretch" px={2} py={2}>
              <SortableContext items={items} strategy={rectSortingStrategy}>
                {items.map((row) => (
                  <TicketErrorBoundary key={row.original.id} ticket={row.original}>
                    <KanbanCard row={row} style={style} />
                  </TicketErrorBoundary>
                ))}
              </SortableContext>
            </StyledFlex>
          )}
          {isAddAllowed && (
            <Flex px={2} py={2}>
              <CreateTicketMenu
                initialStatus={getFirstStatusFromStatusGroup(id)}
                trigger={
                  <Link prefixAdornment={<PlusIcon />} variant="secondary">
                    Toevoegen
                  </Link>
                }
                onCreate={onTicketCreate}
              />
            </Flex>
          )}
        </KanbanColumnDroppable>
      </KanbanColumnContainer>
    );
  },
  { name: 'KanbanColumn' },
);

export { KanbanColumn };
