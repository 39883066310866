import { OrderedMap } from 'immutable';
import warning from 'warning';

import DataModel from './DataModel';

/**
 * DataModelMap
 *
 * A DataModelMap represents a collection of Model class objects stored and indexed by their
 * unique identity. This collection will give convenient methods to fast-ly create, access and
 * manipulate a collection of Model classes.
 */
export default class DataModelMap {
  /**
   * Internal immutable data to keep track of all models in this collection.
   *
   * @private
   * @type {Immutable.OrderedMap}
   */
  data;

  /**
   * Constructor, allows for constructing and filling models directly.
   * @param {Array} models
   */
  constructor(models = []) {
    this.data = this.convertModelsIntoMap(models);
  }

  /**
   * @private
   * @param models
   * @return {Immutable.OrderedMap}
   */
  convertModelsIntoMap(models = []) {
    let map = OrderedMap();
    (Array.isArray(models) ? models : [models]).forEach((model) => {
      if (!DataModel.instanceOf(model)) {
        warning(
          false,
          'Warning: Trying to add an object that does not extend the Model interface in ' +
            `${this.constructor.name}.`,
        );
      } else if (model.id === null || model.id === undefined) {
        warning(
          false,
          'Warning: Trying to add a model without an identity, each model should have a unique ' +
            `identity to be stored in ${this.constructor.name}.`,
        );
      } else {
        map = map.set(model.id, model);
      }
    });
    return map;
  }

  /**
   * Retrieve a new DataModelMap with the given model(s) merged with any present models.
   * @param {DataModel|DataModel[]} models
   * @return {DataModelMap}
   */
  add(models) {
    const data = this.data.merge(this.convertModelsIntoMap(models));

    return new DataModelMap([...Object.values(data.toObject())]);
  }

  /**
   * Retrieve a model by its identity or return the value of defaultValue.
   * @param {number|string} identity
   * @param {*} defaultValue
   * @return {*}
   */
  get(identity, defaultValue = null) {
    if (this.has(identity)) {
      return this.data.get(identity);
    }
    return defaultValue;
  }

  /**
   * Retrieve an indicator if this collection hold a model with this identity.
   * @param {number|string} identity
   * @return {boolean}
   */
  has(identity) {
    return this.data.has(identity);
  }

  /**
   * Remove the model with the given identity from the collection.
   * @param {number|number[]|string|string[]} identity
   * @return {DataModelMap}
   */
  remove(identity) {
    const data = this.data.remove(identity);

    return new DataModelMap(Object.values(data.toObject()));
  }

  /**
   * Retrieve an array with all models in this collection.
   * @return {Array}
   */
  values() {
    return [...Object.values(this.data.toObject())];
  }

  /**
   * Retrieve the amount of models in this collection.
   * @return {number}
   */
  count() {
    return this.data.count();
  }

  /**
   * Sort all models according to the sorter function and return a new instance with sorted models.
   * @param {function} sorter
   * @return {DataModelMap}
   */
  sort(sorter) {
    return new DataModelMap(Object.values(this.data.toObject()).sort(sorter));
  }

  /**
   * Create a new DataModelMap with the given models.
   * @param {Array} models
   * @return {DataModelMap}
   */
  static createInstance(models = []) {
    return new this(models);
  }
}
