import DataModel, { PropTypes as ModelPropTypes } from 'models/base/DataModel';

import AttachmentFolder from '../attachment/AttachmentFolder';

/**
 * Cloud link
 */
export default class CloudAttachment extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    name: ModelPropTypes.string,
    url: ModelPropTypes.string,
    type: ModelPropTypes.string,
    iconName: ModelPropTypes.string,
    createdDateTime: ModelPropTypes.string,
    updatedDateTime: ModelPropTypes.string,
    cardId: ModelPropTypes.string,
    phase: ModelPropTypes.string,
    attachmentFolder: ModelPropTypes.shape(AttachmentFolder.propTypes),
    displayDate: ModelPropTypes.string,
  };

  /**
   * Name of the Cloud Link
   *
   * @type {string}
   */
  name;

  /**
   * Url of the Cloud Link
   *
   * @type {string}
   */
  url;

  /**
   * Type of the Cloud Link
   *
   * @type {string}
   */
  type;

  /**
   * @type {string}
   */
  iconName;

  /**
   * Card id
   *
   * @type {string}
   */
  cardId;

  /**
   * Dossier phase this cloud attachment is linked to.
   * @type {string}
   */
  phase;

  /**
   * @type {AttachmentFolder|undefined}
   */
  attachmentFolder;

  /**
   * @type {string}
   */
  displayDate;

  /**
   * @returns {string}
   */
  getName() {
    return this.name;
  }

  /**
   *
   * @returns {string}
   */
  getUrl() {
    return this.url;
  }

  /**
   *
   * @returns {string}
   */
  getType() {
    return this.type;
  }

  /**
   *
   * @returns {string}
   */
  getIconName() {
    return this.iconName;
  }

  /**
   *
   * @returns {string}
   */
  getCardId() {
    return this.cardId;
  }

  /**
   * @return {string}
   */
  getPhase() {
    return this.phase;
  }

  /**
   * @return {AttachmentFolder}
   */
  getAttachmentFolder() {
    return this.attachmentFolder;
  }

  getDisplayDate() {
    return this.displayDate;
  }

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'attachmentFolder') {
      value = AttachmentFolder.createInstance(value);
    }
    return super.set(fieldName, value);
  }
}
