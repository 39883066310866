import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import { normalizeTeam } from 'entity/system/normalizer';
import type { Team } from 'entity/system/types';
import { normalizeOverviewResults } from 'helpers/normalizehelper';
import TeamModel from 'models/system/Team';
import { useMemo } from 'react';

type useTeamsReturnType = {
  teams: Team[];
  teamsAsModels: TeamModel[];
  query: UseQueryResult;
};

/**
 * Hook to get all teams that are available
 */
export const useTeams = (): useTeamsReturnType => {
  const query = useQuery({
    queryKey: ['teams', 'use'],
    queryFn: async () => {
      const res = await Actions.getAPIService().teams().getTeams([], '', 1, -1);
      return res.isSuccess()
        ? normalizeOverviewResults(res.getData(), 'teams', normalizeTeam).results
        : undefined;
    },
    staleTime: 60 * 1000,
  });

  // NOTE: teamsAsModels can be removed when models/system/Team is not used anymore.
  // @ts-ignore
  return useMemo(
    () => ({
      teams: query.data ?? [],
      teamsAsModels: TeamModel.createInstancesByArray(query.data ?? []),
      query,
    }),
    [query],
  );
};
