import type { InputProps } from '@elseu/sdu-titan';
import { Input } from '@elseu/sdu-titan';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import ConfirmModal from './ConfirmModal';

type Props = {
  className?: string;
  isDisabled?: boolean;
  title?: string;
  isRequired?: boolean;
  label?: string;
  value?: string;
  inputSuffixAdornment?: InputProps['suffixAdornment'];
  error?: string;
  onConfirm: (value?: string) => void;
  onCancel: () => void;
};

/**
 * Basic modal with input field
 *
 * @param {string} className
 * @param {boolean} isDisabled
 * @param {string} title
 * @param {boolean} isRequired
 * @param {string} label
 * @param {string} value
 * @param {string} error
 * @param {function} onConfirm
 * @param {function} onCancel
 * @returns {*}
 * @constructor
 */
const InputModal: React.FC<Props> = ({
  className,
  isDisabled,
  title,
  isRequired,
  label,
  value,
  error,
  inputSuffixAdornment,
  onConfirm,
  onCancel,
}) => {
  const [valueState, setValueState] = useState<string | undefined>(value);
  const [valueErrorState, setValueErrorState] = useState<string | undefined>(undefined);

  useEffect(() => setValueState(value), [value]);
  useEffect(() => setValueErrorState(error), [error]);

  /**
   * On confirm event.
   */
  const onConfirmEvent = () => {
    if (isRequired === true) {
      if (valueState === undefined || valueState.trim().length === 0) {
        setValueErrorState('Dit veld is verplicht.');
        return;
      }
    }
    onConfirm(valueState);
    // reset value state
    setValueState(value);
    setValueErrorState(undefined);
  };

  // render
  return (
    <ConfirmModal
      shouldUsePortal
      className={classNames('c-input-modal', className)}
      isDisabled={isDisabled}
      title={title}
      onCancel={() => {
        onCancel();
        setValueState(value);
      }}
      onConfirm={() => onConfirmEvent()}
    >
      <Input
        errorText={valueErrorState}
        hasError={!!valueErrorState}
        label={label ?? ''}
        name="inputModal"
        suffixAdornment={inputSuffixAdornment}
        value={valueState}
        onInputChange={setValueState}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onConfirmEvent();
          }
        }}
      />
    </ConfirmModal>
  );
};

InputModal.defaultProps = {
  title: 'Invoer',
  value: '',
  isRequired: true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onConfirm: (value) => {
    throw new Error('No onConfirm event given');
  },
  onCancel: () => {
    throw new Error('No onCancel event given');
  },
};

export default InputModal;
