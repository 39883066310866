import './assets/styles/CardSectionAuditLog.scss';

import { useInfiniteQuery } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import classNames from 'classnames';
import AuditLogWithPaging from 'components/auditlog/AuditLogWithPaging';
import Collapsible from 'components/collapsible/Collapsible';
import { normalizeAuditLog } from 'entity/dossiers/normalizer';
import { normalizeOverviewResults } from 'helpers/normalizehelper';
import type { PropsWithChildren } from 'react';
import React, { useEffect, useState } from 'react';
import type JSendResponse from 'services/JSendResponse';

type CardSectionAuditLogProps = {
  className?: string;
  cardId?: string;
  isRefresh?: boolean;
  isOpen?: boolean;
  shouldHideOpenButton?: boolean;
  onLoaded: (isLoaded: boolean) => void;
};

const getAuditlog = (cardId: string, page = 1) => {
  return Actions.getAPIService()
    .auditLogCard(cardId, page)
    .then((response: JSendResponse) =>
      normalizeOverviewResults(response.getData(), 'auditLog', normalizeAuditLog),
    );
};

interface CardSectionAuditLogCollapsibleWrapperProps {
  shouldHideOpenButton: boolean;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const CollapsableCardSectionAuditLogWrapper: React.FC<
  PropsWithChildren<CardSectionAuditLogCollapsibleWrapperProps>
> = ({ shouldHideOpenButton, isOpen, setIsOpen, children }) => {
  if (shouldHideOpenButton) return children;
  return (
    <Collapsible
      hideText="Toon activiteiten log"
      isCollapsed={!isOpen}
      showText="Verberg activiteiten log"
      onToggle={(toggle) => setIsOpen(!toggle)}
    >
      {children}
    </Collapsible>
  );
};

const CardSectionAuditLog: React.FC<CardSectionAuditLogProps> = ({
  className,
  cardId = '',
  isRefresh = false,
  isOpen: isDefaultOpened = false,
  shouldHideOpenButton = false,
  onLoaded = () => null,
}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpened);
  const { data, isFetching, isFetchingNextPage, isSuccess, fetchNextPage, refetch } =
    useInfiniteQuery({
      queryKey: ['card-auditlog', cardId],
      queryFn: ({ pageParam = 1 }) => getAuditlog(cardId, pageParam),
      initialPageParam: 1,
      getNextPageParam: (lastPage) =>
        lastPage.paging.totalPages !== lastPage.paging.page ? lastPage.paging.nextPage : undefined,
      enabled: isOpen && cardId !== '',
      staleTime: 60 * 5000, // 5 minutes,
    });

  useEffect(() => {
    if (isSuccess) {
      onLoaded(true);
    }
  }, [isSuccess, onLoaded]);

  const values = data?.pages
    .map(({ results }) => results)
    .reduce((prevValues, currentValues) => [...prevValues, ...currentValues]);
  const paging = data?.pages.at(-1)?.paging;

  useEffect(() => {
    if (isRefresh && isOpen) {
      refetch();
    } else {
      onLoaded(false);
    }
  }, [isOpen, isRefresh, onLoaded, refetch]);

  return (
    <div className={classNames('c-card-section-audit-log', className)}>
      <CollapsableCardSectionAuditLogWrapper
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        shouldHideOpenButton={shouldHideOpenButton}
      >
        <AuditLogWithPaging
          isLoading={isFetching || isFetchingNextPage}
          items={values || []}
          loadingTitle="Bezig met ophalen..."
          paging={paging}
          onNextPage={() => {
            fetchNextPage();
          }}
        />
      </CollapsableCardSectionAuditLogWrapper>
    </div>
  );
};

export default CardSectionAuditLog;
