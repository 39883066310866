import './assets/styles/TextAreaAutoSizeField.scss';

import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

type Props = {
  id?: string;
  value?: string;
  className?: string;
  hasError?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  isFocused?: boolean;
  placeholder?: string;
  onChange: (value: string | undefined) => void;
  onEnter?: (value: string | undefined) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  [key: string]: any;
};

/**
 * TextAreaAutoSizeField
 *
 * @param {string} id
 * @param {string} value
 * @param {boolean} hasError
 * @param {boolean} isReadOnly
 * @param {boolean} isDisabled
 * @param {boolean} isFocused
 * @param {string} className
 * @param {string} placeholder
 * @param {function} onChange
 * @param {function} onEnter
 * @param {function} onKeyDown
 * @param {object} otherProps
 * @return {*}
 * @constructor
 */
const TextAreaAutoSizeField: React.FC<Props> = ({
  id,
  value,
  hasError,
  isReadOnly,
  isDisabled,
  isFocused = false,
  className,
  placeholder,
  onChange,
  onEnter,
  onKeyDown,
  ...otherProps
}) => {
  const textAreaFieldRef = useRef<HTMLTextAreaElement>(null);

  // make sure this text field will actually get the focus if the focus property changes.
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (textAreaFieldRef.current) {
      if (isFocused) {
        textAreaFieldRef.current.focus();
      } else {
        textAreaFieldRef.current.blur();
      }
    }
  }, [isFocused]);

  return (
    <div
      className={classNames('c-text-area-auto-size-field', className, {
        'c-text-area-auto-size-field--error': hasError,
        'c-text-area-auto-size-field--disabled': isDisabled,
      })}
    >
      <TextareaAutosize
        {...otherProps}
        ref={textAreaFieldRef}
        className="c-text-area-auto-size-field__textarea"
        disabled={isDisabled}
        id={id}
        placeholder={placeholder}
        readOnly={isReadOnly}
        value={value || ''}
        onChange={(event) => {
          const nextValue = onEnter
            ? `${event.target.value}`.replace(/(\r\n|\n|\r)/gm, '')
            : event.target.value;
          onChange(nextValue);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && onEnter) {
            onEnter(value);
            return;
          }
          // redirect onKeydown if pressed.
          if (onKeyDown) {
            onKeyDown(event);
          }
        }}
      />
    </div>
  );
};

TextAreaAutoSizeField.defaultProps = {
  isFocused: false,
};

export default TextAreaAutoSizeField;
