import './assets/styles/CardSectionAttachments.scss';

import Actions from 'actions/Actions';
import AttachmentList from 'components/attachmentlist/AttachmentList';
import CardAttachmentAddButton from 'components/card/CardAttachmentAddButton';
import CardAttachmentCloudLinkModal from 'components/card/CardAttachmentCloudLinkModal';
import CardAttachmentOneDrive from 'components/card/CardAttachmentOneDrive';
import CardSection from 'components/card/CardSection';
import ConfirmDialog from 'components/confirmdialog/ConfirmDialog';
import InputModal from 'components/modal/InputModal';
import { EmailViewerRoutes } from 'containers/email/EmailViewerRoutes';
import type { Attachment, CloudAttachment, CloudLink } from 'entity/Attachment/types';
import useFetchState from 'hooks/useFetchState';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

type Props = {
  isReadOnly?: boolean;
  isDisabled?: boolean;
  isProcessing?: boolean;
  cloudLinkTypes: any[];
  cloudLinks: CloudLink[];
  attachments: Attachment[];
  uploaded: any[];
  cloudAttachments: CloudAttachment[];
  maxFileSize?: number;
  dossierId: string;
  cardId: string;
  isOneDriveEnabled: boolean;
  onCloudAttachmentDoRefresh: () => void;
  onCloudAttachmentUploadProgress: (uploading: any) => void;
  onCloudAttachmentItemDelete: () => void;
  isCreatingCloudLink?: boolean;
  hasCreatingCloudLinkError?: boolean;
  creatingCloudLinkErrors?: any;
  isUpdatingCloudLink?: boolean;
  hasUpdatingCloudLinkErrors?: boolean;
  updatingCloudLinkError?: any;
  hasUpdatingCloudLinkError?: boolean;
  updatingCloudLinkErrors?: any;
  onAttachmentAdd: (files: any[]) => void;
  onAttachmentEdit: () => void;
  onAttachmentDelete: (attachment: any) => void;
  onCloudLinkAdd: (item: any) => void;
  onCloudLinkEdit: (item: any) => void;
  onCloudLinkDelete: (cloudLinks: any) => void;
  cloudDocumentSectionTitle?: string;
  uploadsSectionTitle?: string;
  sectionTitle?: string;
};

/**
 * CardSectionAttachments
 *
 * @param {boolean} isReadOnly
 * @param {boolean} isDisabled
 * @param {boolean} isProcessing
 * @param {array} cloudLinkTypes
 * @param {array} cloudLinks
 * @param {array} attachments
 * @param {array} uploaded
 * @param {array} cloudAttachments
 * @param {number} maxFileSize
 * @param {string} dossierId
 * @param {string} cardId
 * @param {boolean} isOneDriveEnabled
 * @param {boolean} isCreatingCloudLink
 * @param {boolean} hasCreatingCloudLinkError
 * @param {object} creatingCloudLinkErrors
 * @param {boolean} isUpdatingCloudLink
 * @param {boolean} hasUpdatingCloudLinkError
 * @param {object} updatingCloudLinkErrors
 * @param {function} onAttachmentAdd
 * @param {function} onAttachmentEdit
 * @param {function} onAttachmentDelete
 * @param {function} onCloudLinkAdd
 * @param {function} onCloudLinkEdit
 * @param {function} onCloudLinkDelete
 * @param {function} onCloudAttachmentDoRefresh
 * @param {function} onCloudAttachmentUploadProgress
 * @param {function} onCloudAttachmentItemDelete
 * @param {array} berkleyModelSessions
 * @param {function} onBerkleyModelSessionsBusy
 * @param {function} onBerkleyModelSessionsRefresh
 * @param {string} cloudDocumentSectionTitle
 * @param {string} uploadsSectionTitle
 * @param {string} sectionTitle
 * @param {string} addFileButtonText
 * @return {*}
 * @constructor
 */
const CardSectionAttachments: React.FC<Props> = ({
  isReadOnly = false,
  isDisabled = false,
  cloudLinkTypes,
  cloudLinks,
  attachments,
  cloudAttachments,
  maxFileSize,
  dossierId,
  cardId,
  isProcessing,
  isOneDriveEnabled = false,
  onCloudAttachmentDoRefresh,
  onCloudAttachmentUploadProgress,
  onCloudAttachmentItemDelete,
  isCreatingCloudLink,
  hasCreatingCloudLinkError,
  creatingCloudLinkErrors,
  isUpdatingCloudLink,
  hasUpdatingCloudLinkError,
  updatingCloudLinkErrors,
  onAttachmentAdd,
  onAttachmentEdit,
  onAttachmentDelete,
  onCloudLinkAdd,
  onCloudLinkEdit,
  onCloudLinkDelete,
  cloudDocumentSectionTitle = 'Cloud bestanden links',
  uploadsSectionTitle = 'Bestanden uploads',
  sectionTitle = 'Bestanden',
}) => {
  const navigate = useNavigate();
  const [emailCardId, setEmailCardId] = useState<string | undefined>(cardId);

  // modal states
  const [showNewCloudLinkModal, setShowNewCloudLinkModal] = useState(false);
  const [submittingNewCloudLink, setSubmittingNewCloudLink] = useState(false);
  const [creatingCloudLinkErrorsState, setCreatingCloudLinkErrorsState] = useState({});

  const [showEditCloudLinkModal, setShowEditCloudLinkModal] = useState(false);
  const [submittingEditCloudLink, setSubmittingEditCloudLink] = useState(false);
  const [updatingCloudLinkErrorsState, setUpdatingCloudLinkErrorsState] = useState({});
  const [editCloudLink, setEditCloudLink] = useState(undefined);

  // cloud document confirm dialog state
  const [showCloudLinkDeleteConfirm, setShowCloudLinkDeleteConfirm] = useState(false);
  const [deleteCloudLink, setDeleteCloudLink] = useState<CloudAttachment | undefined>(undefined);

  // attachment rename state
  const [attachmentRenameDialog, setAttachmentRenameDialog] = useState(false);
  const [attachmentRenameItem, setAttachmentRenameItem] = useState<Attachment | undefined>(
    undefined,
  );
  const [attachmentRenameName, setAttachmentRenameName] = useState<string | undefined>(undefined);
  const [attachmentRenameExtension, setAttachmentRenameExtension] = useState<string | undefined>(
    undefined,
  );
  const [
    {
      isBusy: attachmentModifyIsBusy,
      error: attachmentModifyError,
      hasError: attachmentModifyHasError,
    },
    { doRequest: attachmentModifyRequest, reset: attachmentModifyReset },
  ] = useFetchState();

  // attachment confirm dialog state
  const [showAttachmentDeleteConfirm, setShowAttachmentDeleteConfirm] = useState(false);
  const [deleteAttachment, setDeleteAttachment] = useState<Attachment | undefined>(undefined);

  // check if form has done submitting and no error has happened.
  useEffect(() => {
    if (submittingNewCloudLink && !isCreatingCloudLink && !hasCreatingCloudLinkError) {
      setSubmittingNewCloudLink(false);
      setShowNewCloudLinkModal(false);
    }

    if (submittingEditCloudLink && !isUpdatingCloudLink && !hasUpdatingCloudLinkError) {
      setSubmittingEditCloudLink(false);
      setShowEditCloudLinkModal(false);
    }
    // eslint-disable-next-line
  }, [
    isCreatingCloudLink,
    hasCreatingCloudLinkError,
    isUpdatingCloudLink,
    hasUpdatingCloudLinkError,
  ]);

  // set error properties to a state.
  useEffect(
    () => setCreatingCloudLinkErrorsState(creatingCloudLinkErrors),
    [creatingCloudLinkErrors],
  );
  useEffect(
    () => setUpdatingCloudLinkErrorsState(updatingCloudLinkErrors),
    [updatingCloudLinkErrors],
  );

  /**
   * Set the confirm state for cloud document delete modal
   *
   * @param {object} cloudDocument
   */
  const displayCloudDocumentDeleteConfirm = (cloudDocument: CloudAttachment) => {
    setDeleteCloudLink(cloudDocument);
    setShowCloudLinkDeleteConfirm(true);
  };

  /**
   * Set the confirm state for attachment delete  modal
   *
   * @param {object} attachment
   */
  const displayAttachmentDeleteConfirm = (attachment: Attachment) => {
    setDeleteAttachment(attachment);
    setShowAttachmentDeleteConfirm(true);
  };

  /**
   * Open attachment rename dialog
   *
   * @param {Attachment} attachment
   */
  const displayAttachmentRename = (attachment: Attachment) => {
    setAttachmentRenameDialog(true);
    const attachmentName = attachment.name;
    const extension = attachmentName ? attachmentName.split('.').pop() : undefined;
    const newFilename = attachmentName ? attachmentName.split('.') : [];
    newFilename.pop(); // remove extension
    const filename = newFilename.join('.');

    setAttachmentRenameItem(attachment);
    setAttachmentRenameName(filename);
    setAttachmentRenameExtension(extension);
  };

  /**
   * Rename attachment
   *
   * @param {Attachment} attachment
   * @param {string} newName
   */
  const doAttachmentRename = (attachment: Attachment, newName: string | undefined) => {
    const newFilename = `${newName}.${attachmentRenameExtension}`;

    attachmentModifyRequest(
      Actions.getAPIService().updateAttachment(
        dossierId,
        attachment.cardId ?? '',
        attachment.id ?? '',
        { name: newFilename },
      ),
    ).then(
      () => {
        onAttachmentEdit();
        setAttachmentRenameDialog(false);
        setAttachmentRenameItem(undefined);
        setAttachmentRenameName(undefined);
        setAttachmentRenameExtension(undefined);
      },
      () => null,
    );
  };

  return (
    <CardSection className="c-card-section-attachments" title={sectionTitle}>
      {/* region cloud documents */}
      <AttachmentList
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        items={cloudLinks}
        options={[
          {
            action: 'edit',
            label: 'Bestand wijzigen',
          },
          {
            action: 'delete',
            label: 'Verwijderen',
          },
        ]}
        title={cloudDocumentSectionTitle}
        onMenuItemSelect={(item, action) => {
          switch (action) {
            case 'edit':
              setEditCloudLink(item);
              setUpdatingCloudLinkErrorsState({});
              setShowEditCloudLinkModal(true);
              break;
            case 'delete':
              displayCloudDocumentDeleteConfirm(item);
              break;
            default:
              break;
          }
        }}
      />
      {/* endregion */}

      {/* region attachments */}
      <AttachmentList
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        items={attachments}
        options={[
          {
            action: 'download',
            label: 'Downloaden',
          },
          {
            action: 'rename',
            label: 'Hernoemen',
          },
          {
            action: 'delete',
            label: 'Verwijderen',
          },
        ]}
        title={uploadsSectionTitle}
        onItemClick={(item) => {
          if (item.name.toLowerCase().endsWith('.msg')) {
            setEmailCardId(item.cardId);
            navigate(`email/attachment/${item.id}`);
            return;
          }
          window.open(item.url, '_blank');
        }}
        onMenuItemSelect={(item, action) => {
          switch (action) {
            case 'download':
              window.open(item.url, '_blank');
              break;
            case 'rename':
              displayAttachmentRename(item);
              break;
            case 'delete':
              displayAttachmentDeleteConfirm(item);
              break;
            default:
              break;
          }
        }}
      />
      {/* endregion */}

      {/* region add button */}
      {isOneDriveEnabled ? (
        <CardAttachmentOneDrive
          cardId={cardId}
          cloudAttachments={cloudAttachments}
          dossierId={dossierId}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          onItemAdded={() => {
            onCloudAttachmentDoRefresh();
          }}
          onItemClick={(item) => {
            if (item.name.toLowerCase().endsWith('.msg')) {
              setEmailCardId(item.cardId);
              navigate(`email/cloud_attachment/${item.id}`);
              return;
            }
            window.open(item.url, '_blank');
          }}
          onItemDelete={onCloudAttachmentItemDelete}
          onItemRenamed={() => {
            onCloudAttachmentDoRefresh();
          }}
          onNewCloudLink={() => {
            setCreatingCloudLinkErrorsState({});
            setShowNewCloudLinkModal(true);
          }}
          onUploadProgress={onCloudAttachmentUploadProgress}
        />
      ) : (
        !isReadOnly && (
          <CardAttachmentAddButton
            isDisabled={isDisabled}
            maxFileSize={maxFileSize}
            onNewCloudLink={() => {
              setCreatingCloudLinkErrorsState({});
              setShowNewCloudLinkModal(true);
            }}
            onNewFileUpload={(files) => {
              onAttachmentAdd(files);
            }}
          />
        )
      )}
      {/* endregion */}
      {/* region uploading tool as cloud attachement */}
      {isProcessing && (
        <div className="c-card-section-attachments__processing">
          <div>Uploaden & verwerken</div>
        </div>
      )}
      {/* endregion */}
      {/* region attachment modals */}
      {showAttachmentDeleteConfirm && (
        <ConfirmDialog
          cancelTitle="Nee"
          confirmAppearance="danger"
          confirmTitle="Ja, verwijder"
          title="Bestand verwijderen?"
          zIndex={1001000}
          onCancel={() => {
            setShowAttachmentDeleteConfirm(false);
            setDeleteAttachment(undefined);
          }}
          onConfirm={() => {
            setShowAttachmentDeleteConfirm(false);
            onAttachmentDelete(deleteAttachment);
          }}
        >
          Weet u zeker dat u dit bestand wilt verwijderen?
        </ConfirmDialog>
      )}
      {attachmentRenameDialog && (
        <InputModal
          isRequired
          error={
            attachmentModifyHasError
              ? attachmentModifyError.data?.name || attachmentModifyError.message
              : undefined
          }
          inputSuffixAdornment={
            attachmentRenameExtension ? `.${attachmentRenameExtension}` : undefined
          }
          isDisabled={attachmentModifyIsBusy}
          label="Naam"
          title="Bestand hernoemen"
          value={attachmentRenameName}
          onCancel={() => {
            attachmentModifyReset();
            setAttachmentRenameDialog(false);
            setAttachmentRenameItem(undefined);
            setAttachmentRenameName(undefined);
          }}
          onConfirm={(value) => {
            if (attachmentRenameItem) {
              doAttachmentRename(attachmentRenameItem, value);
            }
          }}
        />
      )}
      {/* endregion */}

      {/* region cloud document modals */}
      {showNewCloudLinkModal && (
        <CardAttachmentCloudLinkModal
          cloudLinkTypes={cloudLinkTypes}
          error={creatingCloudLinkErrorsState}
          submiting={isCreatingCloudLink}
          onCancel={() => setShowNewCloudLinkModal(false)}
          onSubmit={(data) => {
            setSubmittingNewCloudLink(true);
            onCloudLinkAdd(data);
          }}
        />
      )}

      {showEditCloudLinkModal && (
        <CardAttachmentCloudLinkModal
          cloudLink={editCloudLink}
          cloudLinkTypes={cloudLinkTypes}
          confirmText="Opslaan"
          error={updatingCloudLinkErrorsState}
          modalTitle="Cloud bestand bewerken"
          submiting={isUpdatingCloudLink}
          onCancel={() => setShowEditCloudLinkModal(false)}
          onSubmit={(data) => {
            setSubmittingEditCloudLink(true);
            onCloudLinkEdit(data);
          }}
        />
      )}

      {showCloudLinkDeleteConfirm && (
        <ConfirmDialog
          cancelTitle="Nee"
          confirmAppearance="danger"
          confirmTitle="Ja, verwijder"
          title="Verwijderen gekoppeld cloud document"
          zIndex={1001000}
          onCancel={() => {
            setShowCloudLinkDeleteConfirm(false);
            setDeleteCloudLink(undefined);
          }}
          onConfirm={() => {
            setShowCloudLinkDeleteConfirm(false);
            onCloudLinkDelete(deleteCloudLink);
          }}
        >
          Let op! U verwijdert hiermee alleen de link naar dit bestand. Als u het bestand volledig
          wilt verwijderen, dan dient u het bestand zelf in de cloud dienst te verwijderen.
        </ConfirmDialog>
      )}
      {/* endregion */}
      <EmailViewerRoutes cardId={emailCardId} dossierId={dossierId} />
    </CardSection>
  );
};

export default CardSectionAttachments;
