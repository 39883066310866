import { Button, DrawerContainer, DrawerContent, Flex, Text } from '@elseu/sdu-titan';
import { normalizeDisplayName } from 'entity/crm/normalizer';
import type { Client } from 'entity/crm/types';
import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { ClientClassificationTag } from '../ClientClassificationTag';
import { ClientQuickDetails } from './ClientQuickDetails';
import { ClientQuickLink } from './ClientQuickLink';

type Props = {
  client: Client;
  onClose: () => void;
};

const StyledFooter = styled.div`
  display: flex;
  gap: 1rem;
  margin-left: auto;
`;

export const ClientQuickLookContent: React.FC<Props> = ({ client, onClose }) => {
  const navigate = useNavigate();
  const urlPrefix = `/crm/clients/client/details/${client.id}`;

  return (
    <DrawerContainer
      footer={
        <StyledFooter>
          <Button variant="clear" onClick={onClose}>
            Sluiten
          </Button>
          <Button
            variant="primary"
            onClick={() => navigate(`/crm/clients/client/details/${client.id}`)}
          >
            Naar klant
          </Button>
        </StyledFooter>
      }
      header={normalizeDisplayName(client, true)}
      subtitle={
        <Flex alignItems="center" gap={4}>
          <ClientClassificationTag classification={client.classification} />
          {client.showClientId && <Text type="label">Klantnummer: {client.showClientId}</Text>}
        </Flex>
      }
    >
      <DrawerContent>
        <ClientQuickDetails client={client} />
        <Text isBlock spaceAfter={4} type="headerSmall">
          Snelkoppelingen
        </Text>
        <ClientQuickLink
          count={client.metaInfo?.countContactNotes}
          icon="contactnotes"
          label="Notities"
          to={`${urlPrefix}/contact_notes`}
        />
        <ClientQuickLink
          count={client.metaInfo?.countSignalings}
          icon="signalings"
          label="Adviessignalering"
          to={`${urlPrefix}/signaling`}
        />
        <ClientQuickLink
          count={client.metaInfo?.countDossiers}
          icon="dossiers"
          label="Dossiers"
          to={`${urlPrefix}/dossiers`}
        />
        <ClientQuickLink
          count={client.metaInfo?.countAllAttachments}
          icon="attachments"
          label="Bestanden"
          to={`${urlPrefix}/attachments`}
        />
        <ClientQuickLink
          count={client.metaInfo?.countTasks}
          icon="tasks"
          label="Taken"
          to={`${urlPrefix}/tasks`}
        />
        {client.classification === 'business' && (
          <ClientQuickLink
            count={client.metaInfo?.countClientUsers}
            icon="clientusers"
            label="Contactpersonen"
            to={`${urlPrefix}/client_users`}
          />
        )}
      </DrawerContent>
    </DrawerContainer>
  );
};
