import { Link, StarFilledIcon, StatusMessage } from '@elseu/sdu-titan';
import { useAnnouncements } from 'hooks/queries/useAnnouncements';
import React, { useCallback, useEffect, useState } from 'react';

import { announcementSetLastMessageId, announcementShowNotification } from './announcementHelper';
import { AnnouncementsDialog } from './AnnouncementsDialog';

export const Announcements: React.FC = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [isMessageOpen, setIsMessageOpen] = useState(false);

  const { data = [] } = useAnnouncements();

  const ids = data.map((item) => item.id);

  useEffect(() => {
    setIsMessageOpen(announcementShowNotification(ids));
  }, [ids]);

  const hideStatusMessage = useCallback(() => {
    setIsMessageOpen(false);
    setOpenDialog(false);

    if (ids.length > 0) {
      announcementSetLastMessageId(ids[0]);
    }
  }, [ids]);

  if (data.length === 0) {
    return null;
  }

  return (
    <>
      <StatusMessage
        isCloseable
        alignment="center"
        icon={<StarFilledIcon />}
        isShown={isMessageOpen}
        type="information"
        onClose={() => hideStatusMessage()}
      >
        <Link onClick={() => setOpenDialog(true)}>
          Addify is verbeterd met nieuwe features, klik om ze te bekijken.
        </Link>
      </StatusMessage>
      <AnnouncementsDialog isShown={openDialog} onToggle={() => hideStatusMessage()} />
    </>
  );
};
