import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { Flex, withTitan } from '@elseu/sdu-titan';
import type { ContainersWithItems } from 'hooks/dnd-kit/useDnDMultipleContainers';
import React from 'react';
import styled from 'styled-components';

import type { KanbanColumnProps } from './KanbanColumn';
import { KanbanColumn } from './KanbanColumn';

interface KanbanBoardProps<Item> {
  columns: KanbanColumnProps[];
  items: ContainersWithItems<Item>;
}

const StyledKanbanBoard = styled(Flex)`
  height: calc(100vh - 224px);
  justify-content: stretch;
  margin: 0 -0.75rem;
  flex: 1;
  overflow-x: auto;
`;

const StyledKanbanColumnContainer = styled.div<{ numberOfColumns: number }>`
  ${({ numberOfColumns }) => `width: ${100 / numberOfColumns}%`};
  min-width: 300px;
`;

const KanbanBoard = withTitan<KanbanBoardProps<any>>(
  ({ columns, items }) => {
    return (
      <SortableContext items={[...Object.keys(columns)]} strategy={horizontalListSortingStrategy}>
        <StyledKanbanBoard alignItems="stretch" justifyContent="stretch">
          {columns.map((column) => (
            <StyledKanbanColumnContainer key={column.id} numberOfColumns={columns.length}>
              <KanbanColumn {...column} items={items[column.id]} />
            </StyledKanbanColumnContainer>
          ))}
        </StyledKanbanBoard>
      </SortableContext>
    );
  },
  { name: 'KanbanBoard' },
);

export { KanbanBoard };
