import type { ContactNote, ContactNoteCard } from 'entity/contactnote/types';
import type { Client } from 'entity/crm/types';
import type { Dossier, Label } from 'entity/dossiers/types';
import type { User } from 'entity/system/types';
import type { ApiFilter } from 'types/types';

import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';
import type { JSendResponse } from './types';

export type ContactNotePayload = Pick<ContactNoteCard, 'name' | 'summary' | 'notes' | 'subType'> & {
  user: User['id'];
  client: Client['id'];
  dossier: Dossier['id'];
  label: Label['id'];
};

type GetContactNotesResponse = JSendResponse<{
  contactNotes: ContactNote[];
}>;

type GetContactNoteResponse = JSendResponse<ContactNote>;
type UpdateContactNoteResponse = JSendResponse<ContactNote>;

/**
 * Contact note API
 */
export default class ContactNoteApi extends APIEndpoint {
  /**
   * Get contact notes
   */
  getContactNotes(
    filters: ApiFilter[],
    sort = '',
    page = 1,
    pageSize = 10,
  ): Promise<GetContactNotesResponse> {
    const url = this.buildUrl('crm/contact_notes', filters, sort, page, pageSize);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  /**
   * Get contact notes from a client
   */
  getContactNotesFromClient(
    clientId: ContactNote['id'],
    filters: ApiFilter[],
    sort = '',
    page = 1,
    pageSize = 10,
  ): Promise<GetContactNotesResponse> {
    const url = this.buildUrl(
      `crm/clients/${clientId}/contact_notes`,
      filters,
      sort,
      page,
      pageSize,
    );
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  /**
   * Contact note details
   */
  getContactNote(contactNoteId: ContactNote['id']): Promise<GetContactNoteResponse> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `crm/contact_notes/${contactNoteId}`,
    );
  }

  /**
   * Create contact note
   */
  createContactNote(values: ContactNotePayload): Promise<UpdateContactNoteResponse> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'crm/contact_notes',
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }

  /**
   * Modify contact note
   */
  updateContactNote(
    contactNoteId: ContactNote['id'],
    values: ContactNotePayload,
  ): Promise<UpdateContactNoteResponse> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `crm/contact_notes/${contactNoteId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * Archive contact note
   */
  archiveContactNote(contactNoteId: ContactNote['id']): Promise<JSendResponse> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `crm/contact_notes/${contactNoteId}/archive`,
      JSendRequest.REQUEST_METHOD_PATCH,
    );
  }

  /**
   * Unarchive contact note
   */
  unarchiveContactNote(contactNoteId: ContactNote['id']): Promise<JSendResponse> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `crm/contact_notes/${contactNoteId}/unarchive`,
      JSendRequest.REQUEST_METHOD_PATCH,
    );
  }

  /**
   * Delete contact note
   */
  deleteContactNote(contactNoteId: ContactNote['id']): Promise<JSendResponse> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `crm/contact_notes/${contactNoteId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }
}
