import {
  ChatAltIcon,
  DownloadIcon,
  FolderClosedIcon,
  HistoryIcon,
  LibraryIcon,
  LightbulbIcon,
  StickyNoteIcon,
  TabsProvider,
  TasklistIcon,
} from '@elseu/sdu-titan';
import type { TabsProviderProps } from '@elseu/sdu-titan/dist/types/components/Tabs/TabsProvider';
import type { TabProps } from '@elseu/sdu-titan/dist/types/components/Tabs/types';
import QuestionIcon from 'components/icons/QuestionIcon';
import type { Card } from 'entity/dossiers/types';
import type { PropsWithChildren } from 'react';
import React, { createContext, useMemo, useState } from 'react';

export type TabId =
  | 'attachments'
  | 'tasks'
  | 'comments'
  | 'auditlog'
  | 'tools'
  | 'links'
  | 'legal'
  | 'export';
interface ExtendedTabProps extends TabProps {
  id: TabId;
}

export const getTabIcon = (tab: TabId) => {
  switch (tab) {
    case 'attachments':
      return <FolderClosedIcon />;
    case 'tasks':
      return <TasklistIcon />;
    case 'comments':
      return <ChatAltIcon />;
    case 'auditlog':
      return <HistoryIcon />;
    case 'legal':
      return <LibraryIcon />;
    case 'links':
      return <StickyNoteIcon />;
    case 'tools':
      return <LightbulbIcon />;
    case 'export':
      return <DownloadIcon />;
    default:
      return <QuestionIcon />;
  }
};

export const shouldRenderTab = (tab: TabId, tabs: ExtendedTabProps[]) => {
  return tabs.map((tab) => tab.id).includes(tab);
};

interface CardSidebarTabProviderProps extends TabsProviderProps {
  defaultActiveId: TabId;
  card?: Card;
  cardType: 'contactNote' | 'task' | 'subdossier' | 'dossier';
}

type CardSidebarTabContextProps = Pick<CardSidebarTabProviderProps, 'activeId'> & {
  setActiveId: (value: TabId) => void;
  tabs: ExtendedTabProps[];
};

const CardSidebarTabsContext = createContext<CardSidebarTabContextProps | undefined>(undefined);

export const useCardSidebarTabs = () => {
  const context = React.useContext(CardSidebarTabsContext);
  if (!context) {
    throw new Error('useCardSidebarTabs must be used within a CardSidebarTabProvider');
  }
  return context;
};

export const CardSidebarTabProvider = ({
  defaultActiveId,
  card,
  cardType,
  children,
}: PropsWithChildren<CardSidebarTabProviderProps>) => {
  const [activeId, setActiveId] = useState<TabId | undefined>(defaultActiveId);

  const tabs: ExtendedTabProps[] = useMemo(() => {
    switch (cardType) {
      case 'task':
        return [
          {
            id: 'attachments',
            label: 'Attachments',
          },
          {
            id: 'comments',
            label: 'Comments',
          },
          {
            id: 'auditlog',
            label: 'Auditlog',
          },
        ];
      case 'contactNote': {
        return [
          {
            id: 'tasks',
            label: 'Tasks',
          },
          {
            id: 'attachments',
            label: 'Attachments',
          },
          {
            id: 'comments',
            label: 'Comments',
          },
          {
            id: 'auditlog',
            label: 'Auditlog',
          },
          {
            id: 'links',
            label: 'Dossiers',
          },
        ];
      }
      case 'subdossier': {
        const tabs: ExtendedTabProps[] = [
          {
            id: 'tasks',
            label: 'Tasks',
          },
          {
            id: 'comments',
            label: 'Comments',
          },
          {
            id: 'auditlog',
            label: 'Auditlog',
          },
          {
            id: 'legal',
            label: 'Juridische bronnen',
          },
          {
            id: 'tools',
            label: 'Tools',
          },
        ];

        if (card?.type === 'advice') {
          tabs.push({
            id: 'export',
            label: 'Exporteren',
          });
        }

        if (card?.type === 'inventory') {
          tabs.push({
            id: 'links',
            label: 'Contact-notities',
          });
        }
        return tabs;
      }
      default:
        return [];
    }
  }, [card?.type, cardType]);

  const context = useMemo(
    () => ({
      activeId,
      setActiveId,
      tabs,
    }),
    [activeId, tabs],
  );

  return (
    <CardSidebarTabsContext.Provider value={context}>
      <TabsProvider activeId={activeId} onActiveIdChange={(value) => setActiveId(value as TabId)}>
        {children}
      </TabsProvider>
    </CardSidebarTabsContext.Provider>
  );
};
