const subdossierNames = {
  ADVICE: 'Advies',
  ANALYSIS: 'Analyse',
  INVENTORY: 'Inventarisatie',
  EXECUTION: 'Uitvoering',
};

export const formatCardName = (name = ''): string => {
  if (Object.keys(subdossierNames).includes(name)) {
    return subdossierNames[name as keyof typeof subdossierNames];
  }
  return name;
};
