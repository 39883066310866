import DataModel, { PropTypes } from 'models/base/DataModel';

export default class StatusGroup extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    name: PropTypes.string,
    position: PropTypes.number,
  };

  name: string | undefined;

  position: number | undefined;

  /**
   *
   * @returns {string}
   */
  getName() {
    return this.name;
  }

  /**
   *
   * @returns {number}
   */
  getPosition() {
    return this.position;
  }
}
