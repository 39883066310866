import React from 'react';

import { ReactComponent } from './assets/svg/AddCircleIcon.svg';
import type { IconComponentProps } from './IconComponent';
import IconComponent from './IconComponent';

const AddCircleIcon = (props: IconComponentProps) => IconComponent(props, <ReactComponent />);

AddCircleIcon.propTypes = IconComponent.propTypes;
AddCircleIcon.defaultProps = IconComponent.defaultProps;

export default AddCircleIcon;
