import { Box, Input, RadioGroup, Text } from '@elseu/sdu-titan';
import type { RadioProps } from '@elseu/sdu-titan/dist/types/components/Radio/Radio';
import SubOfficeLinkSelect from 'components/subofficelinkselect/SubOfficeLinkSelect';
import React from 'react';
import styled from 'styled-components';

import type { OnRenderEditParams } from '../types';

const ErrorText = styled(Text)`
  margin: ${({ theme }) => theme.components.input.errorTextMargin};
`;

export const textFieldRender = ({
  field,
  onChange,
  value,
  hasError,
  errorText,
  isDisabled,
}: OnRenderEditParams) => (
  <Input
    key={field.name}
    disabled={isDisabled}
    errorText={errorText}
    hasError={hasError}
    label={field.label}
    name={field.name}
    placeholder={field.placeholder}
    spaceAfter={4}
    value={value}
    onInputChange={(value) => onChange(value)}
    {...field.renderOptions}
  />
);

export const radioGroupFieldRender = ({
  field,
  onChange,
  value,
  errorText,
  hasError,
  isDisabled,
}: OnRenderEditParams) => {
  const options: RadioProps[] =
    field.renderOptions?.options?.map((option: RadioProps) => ({
      ...option,
      disabled: isDisabled,
    })) || [];

  return (
    <RadioGroup
      key={field.name}
      errorText={errorText}
      hasError={hasError}
      label={field.label}
      name={field.name}
      spaceAfter={4}
      spaceBetween={4}
      value={value}
      onChange={(value) => onChange(value)}
      {...field.renderOptions}
      options={options}
    />
  );
};

export const subOfficeFieldRender = ({
  field,
  hasError,
  errorText,
  onChange,
  value,
  isDisabled,
}: OnRenderEditParams) => {
  return (
    <Box key={field.name} spaceAfter={4}>
      <Text spaceAfter={2} type="labelBold">
        {field.label}
      </Text>
      <SubOfficeLinkSelect
        isClearable
        isCreatable
        errors={errorText}
        isDisabled={isDisabled}
        isMenuPortalActive={false}
        isMulti={false}
        selected={value}
        onChange={(value) => {
          if (value instanceof Array) {
            onChange(
              value.map((subOffice: any) => ({
                id: subOffice.value,
                name: subOffice.label,
              })),
            );
            return;
          }

          if (value instanceof Object) {
            onChange({
              id: value.value ?? '',
              name: value.label ?? '',
            });
            return;
          }

          onChange(undefined);
        }}
      />
      {errorText && hasError && (
        <ErrorText color="secondary70" type="labelTiny">
          {errorText}
        </ErrorText>
      )}
    </Box>
  );
};
