import { Lazy } from 'components/lazy/Lazy';
import React, { lazy } from 'react';
import { Route, Routes } from 'react-router';

const EmailAttachmentContainer = lazy(() => import('./EmailAttachmentContainer'));
const EmailCloudAttachmentContainer = lazy(() => import('./EmailCloudAttachmentContainer'));

type Props = {
  dossierId?: string;
  cardId?: string;
};

export const EmailViewerRoutes: React.FC<Props> = (props) => (
  <Routes>
    <Route
      element={
        <Lazy>
          <EmailAttachmentContainer {...props} />
        </Lazy>
      }
      path="email/attachment/:attachmentId"
    />
    <Route
      element={
        <Lazy>
          <EmailCloudAttachmentContainer {...props} />
        </Lazy>
      }
      path="email/cloud_attachment/:cloudAttachmentId"
    />
  </Routes>
);
