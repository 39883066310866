import React from 'react';

import { ReactComponent } from './assets/svg/ArchiveIcon.svg';
import type { IconComponentProps } from './IconComponent';
import IconComponent from './IconComponent';

const ArchiveIcon = (props: IconComponentProps) => IconComponent(props, <ReactComponent />);

ArchiveIcon.propTypes = IconComponent.propTypes;
ArchiveIcon.defaultProps = IconComponent.defaultProps;

export default ArchiveIcon;
