import DataModel, { PropTypes } from 'models/base/DataModel';
import User from 'models/me/User';

/**
 * AuditLog
 */
export default class AuditLog extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    createdAt: PropTypes.string,
    activity: PropTypes.string,
    activityDescription: PropTypes.string,
    userAgent: PropTypes.string,
    ipAddress: PropTypes.string,
    additionalData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    user: PropTypes.shape(User.propTypes),
  };

  /**
   * @type {string}
   */
  createdAt;

  /**
   * @type {string}
   */
  activity;

  /**
   * @type {string}
   */
  activityDescription;

  /**
   * @type {string}
   */
  userAgent;

  /**
   * @type {string}
   */
  ipAddress;

  /**
   * @type array
   */
  additionalData;

  /**
   * @type User
   */
  user;

  /**
   * @return {string}
   */
  getCreatedAt() {
    return this.createdAt;
  }

  /**
   * @return {string}
   */
  getActivity() {
    return this.activity;
  }

  /**
   * @return {string}
   */
  getActivityDescription() {
    return this.activityDescription;
  }

  /**
   * @return {string}
   */
  getUserAgent() {
    return this.userAgent;
  }

  /**
   * @return {string}
   */
  getIpAddress() {
    return this.ipAddress;
  }

  /**
   * @return {object}
   */
  getAdditionalData() {
    return this.additionalData;
  }

  /**
   * @return {User}
   */
  getUser() {
    return this.user;
  }

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'User') {
      value = User.createInstance(value);
    }

    return super.set(fieldName, value);
  }
}
