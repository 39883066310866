import type { SearchResult } from './types';

// region search result
export const normalizeSearchResult = (result: any): SearchResult => {
  return {
    id: result.id,
    title: result.title,
    designation: result.designation,
    metadata: Object.keys(result.metaData).map((key) => ({
      label: key,
      value: result.metaData[key],
    })),
    teaser: result.previewText,
    link: result.url,
  };
};
// endregion
