import { Box, Card, Divider, Flex } from '@elseu/sdu-titan';
import type { Client } from 'entity/crm/types';
import { chunk } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import DetailsValueField from '../DetailsValueField';
import {
  ClientFieldQuickForBusiness,
  ClientFieldQuickForBusinessFooter,
  ClientFieldQuickForPrivate,
  ClientFieldQuickForPrivateFooter,
} from '../helpers/ClientFieldsForType';
import type { CrmEditFieldType } from '../types';

type Props = {
  client: Client;
};

type ClientFieldsRenderProps = {
  client: Client;
  fields: CrmEditFieldType[];
  isFlexed?: boolean;
};

const ClientFieldBoxStyled = styled(Box)`
  flex: 0 0 180px;
  width: 180px;
`;

const StyledVDivider = styled(Divider).attrs({
  direction: 'vertical',
  spaceAfter: 3,
  spaceBefore: 3,
})`
  height: auto;
`;

const DetailsValueFieldFlex = styled(Box)`
  flex: 1 1 auto;
`;

const ClientFieldsRender: React.FC<ClientFieldsRenderProps> = ({
  client,
  fields,
  isFlexed = false,
}) => {
  const RenderWrapper = isFlexed ? DetailsValueFieldFlex : React.Fragment;

  return (
    <>
      {fields.map((field: CrmEditFieldType) =>
        field.renderView instanceof Function ? (
          <RenderWrapper key={field.name}>
            <DetailsValueField label={field.label}>
              {field.renderView({
                field,
                value: client[field.name as keyof Client] || undefined,
                isDisabled: false,
              })}
            </DetailsValueField>
          </RenderWrapper>
        ) : (
          <></>
        ),
      )}
    </>
  );
};

export const ClientQuickDetails: React.FC<Props> = ({ client }) => {
  const { classification } = client;
  const fields =
    classification === 'private' ? ClientFieldQuickForPrivate : ClientFieldQuickForBusiness;
  const fieldsFooter =
    classification === 'private'
      ? ClientFieldQuickForPrivateFooter
      : ClientFieldQuickForBusinessFooter;

  const fieldsChunked = chunk(fields, 2);

  return (
    <Card isBordered spaceAfter={8}>
      <Flex alignItems="top">
        {fieldsChunked.map((fields, index) => {
          return (
            <React.Fragment key={`field_${index.toString()}`}>
              <ClientFieldBoxStyled key={`field_${index.toString()}`}>
                <ClientFieldsRender client={client} fields={fields} />
              </ClientFieldBoxStyled>
              {index !== fieldsChunked.length - 1 && <StyledVDivider />}
            </React.Fragment>
          );
        })}
      </Flex>
      {fieldsFooter.length > 0 && (
        <>
          <Divider spaceAfter={3} spaceBefore={3} />
          <Flex alignItems="top" gap={8}>
            <ClientFieldsRender isFlexed client={client} fields={fieldsFooter} />
          </Flex>
        </>
      )}
    </Card>
  );
};
