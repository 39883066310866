import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import { createEmptyClientUser, normalizeClientUser } from 'entity/crm/normalizer';
import type { ClientUser } from 'entity/crm/types';
import { useMemo } from 'react';

type useClientUserDetailsReturnType = {
  clientUser: ClientUser;
  isBusy: boolean;
  isSuccess: boolean;
  query: UseQueryResult;
};

export const useClientUserDetails = (id: string): useClientUserDetailsReturnType => {
  const query = useQuery({
    queryKey: ['clientuser', id],
    queryFn: async () => {
      const res = await Actions.getAPIService().getCrm().getClientUserDetails(id);
      return res.isSuccess() ? normalizeClientUser(res.getData()) : undefined;
    },
    staleTime: 60 * 1000,
  });

  return useMemo(
    () => ({
      clientUser: query.data || createEmptyClientUser(),
      isBusy: query.isLoading,
      isSuccess: query.isSuccess,
      query,
    }),
    [query],
  );
};
