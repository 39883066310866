import { Flex, Label, Link, List, ListItem, PlusIcon, Text } from '@elseu/sdu-titan';
import React, { useMemo, useState } from 'react';

import type { IPermission } from './helpers';
import { IPermissionRole } from './helpers';
import PermissionDrawer from './PermissionDrawer';
import { PermissionRow } from './PermissionRow';

type IPermissions = {
  isDisabled?: boolean;
  canManageOwners?: boolean;
  availablePermissions: IPermission[];
  permissions: IPermission[];
  onAdd: (permissions: IPermission[]) => void;
  onRemove: (permission: IPermission) => void;
  onChange: (permission: IPermission) => void;
};

export const Permissions: React.FC<IPermissions> = ({
  isDisabled = false,
  canManageOwners = false,
  availablePermissions = [],
  permissions = [],
  onAdd,
  onRemove,
  onChange,
}) => {
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);

  const ownerCount = useMemo(
    () => permissions.filter((permission) => permission.role === IPermissionRole.ROLE_OWNER).length,
    [permissions],
  );

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" spaceAfter={2}>
        <Label>Gebruikers en groepen</Label>
        <Link
          disabled={isDisabled}
          prefixAdornment={<PlusIcon />}
          onClick={() => setIsPanelOpen(true)}
        >
          Toevoegen
        </Link>
      </Flex>
      <List spaceAfter={2} type="ul" variant="vertical-stretch">
        {permissions.map(({ ...permission }) => (
          <PermissionRow
            key={permission.id}
            disabledRoles={!canManageOwners ? [IPermissionRole.ROLE_OWNER] : []}
            isDisabled={
              isDisabled || (!canManageOwners && permission.role === IPermissionRole.ROLE_OWNER)
            }
            isSingleAdmin={ownerCount === 1 && permission.role === IPermissionRole.ROLE_OWNER}
            permission={permission}
            onChange={onChange}
            onRemove={onRemove}
          />
        ))}
        {permissions.length === 0 && (
          <ListItem>
            <Text type="label">Er zijn geen machtigingen ingesteld.</Text>
          </ListItem>
        )}
      </List>
      {isPanelOpen && (
        <PermissionDrawer
          disabledRoles={!canManageOwners ? [IPermissionRole.ROLE_OWNER] : []}
          isDisabled={isDisabled}
          permissions={availablePermissions}
          onClose={() => setIsPanelOpen(false)}
          onSave={(permissions) => {
            onAdd(permissions);
            setIsPanelOpen(false);
          }}
        />
      )}
    </>
  );
};
