import './assets/styles/ContactNotePreview.scss';

import classNames from 'classnames';
import Avatar from 'components/avatar/Avatar';
import AvatarClient from 'components/avatar/AvatarClient';
import CardPanel from 'components/card/CardPanel';
import ColorTextLabel from 'components/colorlabel/ColorTextLabel';
import Date from 'components/date/Date';
import type { IOptionItem } from 'components/options/Options';
import type { ContactNoteCard } from 'entity/contactnote/types';
import { normalizeDossier } from 'entity/dossiers/normalizer';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  isDisabled?: boolean;
  item: ContactNoteCard;
  link?: string;
  actions: IOptionItem[];
};

/**
 * Contact note preview
 *
 * @param {boolean} isDisabled
 * @param {object} item
 * @param {string} link
 * @param {array} actions
 * @returns {JSX.Element}
 * @constructor
 */
const ContactNotePreview: React.FC<Props> = ({ isDisabled, item, link, actions }) => {
  const { id, metaInfo, assignedUser } = item;
  const label = item.label;
  const dossier = normalizeDossier(item.dossier);
  const { name, clients } = dossier;
  const { updatedAt } = metaInfo ?? {};

  const previewCard = (
    <CardPanel
      actions={actions}
      className={classNames('c-contactnote-preview', {
        'c-contact-note-preview--has-link': !!link,
      })}
      isDisabled={isDisabled}
      title="Notitie"
    >
      <div className="c-contact-note-preview__content">
        <div className="c-contact-note-preview__title">{name}</div>
        <div className="c-contact-note-preview__date">
          <Date date={updatedAt} />
        </div>
        <div className="c-contact-note-preview__clients">
          {clients?.map(({ client }) => (
            <div key={`${id}${client?.id ?? ''}`} className="c-contact-note-preview__clients__item">
              <AvatarClient name={client?.displayName} size={24} />
            </div>
          ))}
        </div>
        <div className="c-contact-note-preview__assigned-user">
          {assignedUser?.id && (
            <Avatar hasBorder isInline isRounded size={24} url={assignedUser.avatarUrl} />
          )}
        </div>
        <div className="c-contact-note-preview__card-label">
          {label && <ColorTextLabel color={label.color}>{label.name}</ColorTextLabel>}
        </div>
      </div>
    </CardPanel>
  );

  if (link) {
    return (
      <Link className="c-contact-note-preview__link" to={link}>
        {previewCard}
      </Link>
    );
  }

  return previewCard;
};

export default ContactNotePreview;
