import DataModelMap from 'models/base/DataModelMap';
import Paging from 'models/base/Paging';

/**
 * Initial paging state object
 *
 * @returns {
 * {
 * isBusy: boolean,
 * totalItems: number,
 * hasNextPage: boolean,
 * values: [],
 * _dataModelMap: DataModelMap,
 * paging: Paging,
 * hasError: boolean,
 * error: undefined
 * }
 * }
 */
const stateInit = () => ({
  _dataModelMap: new DataModelMap([]),
  paging: new Paging(),
  values: [],
  isBusy: false,
  hasError: false,
  hasNextPage: false,
  totalItems: 0,
  error: undefined,
});

// #region dispatch
/**
 * Reset paging state
 *
 * @param stateKey
 * @returns {function({setState: *}): *}
 */
const dispatchResetState =
  (stateKey) =>
  ({ setState }) =>
    setState({ [stateKey]: stateInit() });

/**
 * Set paging state to busy
 *
 * @param {string} stateKey
 * @param {boolean} shouldReset
 * @returns {function(...[*]=)}
 */
const dispatchSetStateToBusy =
  (stateKey, shouldReset) =>
  ({ setState, getState }) => {
    const currentState = shouldReset ? stateInit() : getState()[stateKey];

    setState({
      [stateKey]: {
        ...currentState,
        isBusy: true,
      },
    });
  };

/**
 * Set paging state to complete and set a new model map and paging
 *
 * @param {string} stateKey
 * @param {DataModelMap} newDataModelMap
 * @param {Paging} paging
 * @returns {function(...[*]=)}
 */
const dispatchSetStateToSuccess =
  (stateKey, newDataModelMap, paging) =>
  ({ setState }) => {
    const currentState = stateInit();
    setState({
      [stateKey]: {
        ...currentState,
        _dataModelMap: newDataModelMap,
        values: newDataModelMap.values(),
        paging,
        hasNextPage: paging.hasNextPage(),
        totalItems: paging.getNumberOfResults(),
      },
    });
  };

/**
 * Get item from paging object
 *
 * @param {string} stateKey
 * @param {DataModelMap} newDataModelMap
 * @returns {*}
 */
export const updateResults =
  (stateKey, newDataModelMap) =>
  ({ setState, getState }) => {
    const currentState = getState()[stateKey];
    setState({
      [stateKey]: {
        ...currentState,
        _dataModelMap: newDataModelMap,
        values: newDataModelMap.values(),
      },
    });
  };

/**
 * Get item from paging object
 *
 * @param {string} stateKey
 * @param {array} newValues
 * @returns {*}
 */
export const pagingNewResults =
  (stateKey, newValues) =>
  ({ dispatch }) => {
    const newDataModelMap = new DataModelMap(newValues);
    dispatch(updateResults(stateKey, newDataModelMap));
  };

/**
 * Set paging state to error
 *
 * @param {string} stateKey
 * @param {object} response
 * @returns {function(...[*]=)}
 */
const dispatchSetStateToError =
  (stateKey, response) =>
  ({ getState, setState }) => {
    const currentState = getState()[stateKey];
    setState({
      [stateKey]: {
        ...currentState,
        hasError: true,
        error: response,
      },
    });
  };
// #endregion

/**
 * Add item to the paging state
 *
 * @param {string} stateKey
 * @param {object} item
 * @returns {function(...[*]=)}
 */
const pagingAddItem =
  (stateKey, item) =>
  ({ getState, setState }) => {
    const currentState = getState()[stateKey];
    const countResult = currentState._dataModelMap.has(item.id) ? 0 : 1;
    const newDataModelMap = currentState._dataModelMap.add(item);

    const newPaging = Paging.createInstance({
      ...currentState.paging,
      numberOfResults: currentState.paging.numberOfResults + countResult,
    });

    setState({
      [stateKey]: {
        ...currentState,
        _dataModelMap: newDataModelMap,
        values: newDataModelMap.values(),
        paging: newPaging,
        totalItems: newPaging.getNumberOfResults(),
      },
    });
  };

/**
 * Remove item from paging state
 *
 * @param {string} stateKey
 * @param {string} itemId
 * @returns {function(...[*]=)}
 */
const pagingRemoveItem =
  (stateKey, itemId) =>
  ({ getState, setState }) => {
    const currentState = getState()[stateKey];
    const newDataModelMap = currentState._dataModelMap.remove(itemId);
    const newPaging = Paging.createInstance({
      ...currentState.paging,
      numberOfResults: currentState.paging.numberOfResults - 1,
    });

    setState({
      [stateKey]: {
        ...currentState,
        _dataModelMap: newDataModelMap,
        values: newDataModelMap.values(),
        paging: newPaging,
        totalItems: newPaging.getNumberOfResults(),
      },
    });
  };

/**
 * Get item from paging object
 *
 * @param {string} itemId
 * @param {object} state
 * @returns {*}
 */
export const pagingGetItem = (itemId, state) => {
  return state._dataModelMap.get(itemId);
};

/**
 * Get item from paging object
 *
 * @param {object} state
 * @returns {*}
 */
export const pagingGetResults = (state) => {
  return state._dataModelMap;
};

/**
 * Sweet paging state create
 *
 * @param {string} stateKey
 * @param {function} resultsHandle
 * @param {function} dispatch
 * @param {function} getState
 * @returns {{doRequest: doRequest, isBusy: (function(): *), reset: resetState}}
 */
export const sweetPagingState = (stateKey, resultsHandle, dispatch, getState) => {
  /**
   * Reset paging state
   */
  const resetState = () => {
    dispatch(dispatchResetState(stateKey));
  };

  /**
   * Do request
   * @param {Promise} requestPromise
   * @param {boolean} shouldReset
   */
  const doRequest = (requestPromise, shouldReset = false) => {
    dispatch(dispatchSetStateToBusy(stateKey, shouldReset));

    requestPromise
      .then((response) => {
        const newResults = getState()[stateKey]._dataModelMap.add(resultsHandle(response));
        dispatch(
          dispatchSetStateToSuccess(
            stateKey,
            newResults,
            Paging.createInstance(response.data._paging),
          ),
        );
      })
      .catch((response) => dispatchSetStateToError(stateKey, response));
  };

  /**
   * return current busy state
   *
   * @returns {*}
   */
  const isBusyState = () => getState()[stateKey].isBusy;
  const getPagingState = () => getState()[stateKey].paging;
  const getResultsState = () => getState()[stateKey]._dataModelMap;

  return {
    doRequest,
    reset: resetState,
    isBusy: isBusyState,
    getPaging: getPagingState,
    getResults: getResultsState,
  };
};

/**
 * Reset paging state
 *
 * @type {function(*): function({setState: *}): *}
 */
export const sweetPagingReset = dispatchResetState;

/**
 * Default exports
 */
export default {
  // state
  init: stateInit,
  reset: sweetPagingReset,
  state: sweetPagingState,

  // items manage
  addItem: pagingAddItem,
  getItem: pagingGetItem,
  removeItem: pagingRemoveItem,

  // results manage
  getResults: pagingGetResults,
  newResults: pagingNewResults,
  updateResults,
};
