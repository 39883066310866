/* eslint-disable no-nested-ternary */
import {
  Button,
  Drawer,
  DrawerContainer,
  DrawerContent,
  DrawerLoader,
  EmptyState,
  Flex,
} from '@elseu/sdu-titan';
import type { ConfirmDialogProps } from 'components/confirmdialog/ConfirmDialog';
import ConfirmDialog from 'components/confirmdialog/ConfirmDialog';
import React, { StrictMode, useCallback, useState } from 'react';
import styled from 'styled-components';

const StyledFooter = styled.div`
  display: flex;
  gap: 1rem;
  margin-left: auto;
`;

const CompletedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: inherit;
`;

export type FormDrawerProps = React.PropsWithChildren<{
  className?: string;
  isDisabled?: boolean;
  title: string;
  subtitle?: string;
  isShown?: boolean;
  width?: 'auto' | '100%' | number;
  isConfirmDisabled?: boolean;
  confirmTitle?: string;
  cancelTitle?: string;
  isCloseShown?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  isCompleted?: boolean;
  completedTitle?: string;
  completedDescription?: string;
  zIndex?: number;
  cancelConfirmOnClose?: boolean;
  cancelConfirmTitle?: string;
  cancelConfirmMessage?: string;
  cancelConfirmProps?: Omit<
    ConfirmDialogProps,
    'title' | 'children' | 'zIndex' | 'onCancel' | 'onConfirm'
  >;
  onCompletedClick?: () => void;
  onConfirm: () => void;
  onCancel: () => void;
}>;

/**
 * FormDrawer
 *
 * @param {string} className
 * @param {boolean} isDisabled
 * @param {boolean} isShown
 * @param {number} width
 * @param {string} confirmTitle
 * @param {boolean} isConfirmDisabled
 * @param {string} cancelTitle
 * @param {boolean} isCloseShown
 * @param {string} title
 * @param {string} subtitle
 * @param {function} onCancel
 * @param {function} onConfirm
 * @param {ReactNode} children
 * @constructor
 */
const FormDrawer: React.FC<FormDrawerProps> = ({
  isDisabled,
  isShown = true,
  width = 480, // keep in mind that titan design system tells that only 480 or 720 are allowed.
  confirmTitle = 'Ok',
  isConfirmDisabled = false,
  cancelTitle = 'Annuleren',
  isCloseShown = true,
  isLoading = false,
  loadingText = 'Laden...',
  isCompleted = false,
  completedTitle = 'Informatie opgeslagen',
  completedDescription = 'Informatie is met success opgeslagen.',
  zIndex = 1013,
  title,
  subtitle,
  cancelConfirmOnClose = false,
  cancelConfirmTitle = 'Bevestig',
  cancelConfirmMessage = 'Weet je zeker dat item wilt annuleren?',
  cancelConfirmProps,
  onCompletedClick,
  onCancel,
  onConfirm,
  children,
}) => {
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);

  const cancelHandle = useCallback(() => {
    if (cancelConfirmOnClose) {
      setShowCancelConfirm(true);
      return;
    }
    onCancel();
  }, [cancelConfirmOnClose, onCancel]);

  return (
    <StrictMode>
      <Drawer
        isShown={isShown}
        position="right"
        width={width}
        zIndex={zIndex}
        onClose={isCloseShown ? cancelHandle : undefined}
      >
        {isLoading ? (
          <DrawerLoader loadingText={loadingText} />
        ) : isCompleted ? (
          <CompletedContainer>
            <Flex alignItems="center" flexDirection="column" justifyContent="center">
              <EmptyState
                isCentered
                description={completedDescription}
                illustration="success"
                title={completedTitle}
              >
                <Button variant="clear" onClick={onCompletedClick}>
                  Sluiten
                </Button>
              </EmptyState>
            </Flex>
          </CompletedContainer>
        ) : (
          <DrawerContainer
            footer={
              <StyledFooter>
                <Button isDisabled={isDisabled} variant="clear" onClick={cancelHandle}>
                  {cancelTitle}
                </Button>
                <Button isDisabled={isConfirmDisabled || isDisabled} onClick={onConfirm}>
                  {confirmTitle}
                </Button>
              </StyledFooter>
            }
            header={title}
            headerVariant={undefined}
            subtitle={subtitle}
          >
            <DrawerContent>{children}</DrawerContent>
          </DrawerContainer>
        )}
      </Drawer>
      <ConfirmDialog
        cancelTitle="Nee"
        confirmTitle="Ja"
        isShown={showCancelConfirm}
        title={cancelConfirmTitle}
        zIndex={zIndex + 10}
        onCancel={() => setShowCancelConfirm(false)}
        onConfirm={() => {
          setShowCancelConfirm(false);
          onCancel();
        }}
        {...cancelConfirmProps}
      >
        {cancelConfirmMessage}
      </ConfirmDialog>
    </StrictMode>
  );
};
// note: strict mode is required because the Drawer component in combination with the modal component has strange
// behavior regarding the rendering of title and subtitle elements.

export default FormDrawer;
