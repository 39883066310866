import 'moment/locale/nl';

import { Avatar, Link, Navigation } from '@elseu/sdu-titan';
import { Announcements } from 'components/announcements/Announcements';
import ApplicationTitle from 'components/applicationtitle/ApplicationTitle';
import DrawerProfile from 'components/drawerprofile/DrawerProfile';
import { FetchLoader } from 'components/loader/FetchLoader';
import { NotificationNavigationIcon } from 'components/notificationcenter/NotificationNavigationIcon';
import OneDriveUserAuthChecker from 'components/onedriveuserauthchecker/OneDriveUserAuthChecker';
import PageLoader from 'components/page/PageLoader';
import SearchAutocomplete from 'components/search/SearchAutocomplete';
import constants from 'constants/ApplicationConstants';
import { MainMenu, ProfileMenu, SettingsMenu } from 'constants/MenuConstants';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAccountState } from 'stores/account/AccountState';

/**
 * Application container
 *
 * @returns {JSX.Element}
 * @constructor
 */
const ApplicationContainer = ({
  shouldHideSidebarItems,
  shouldHideProfileItem,
  shouldHideSearchbar,
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [accountState, accountActions] = useAccountState();
  const [showProfileDrawer, setShowProfileDrawer] = useState(false);

  // extract properties from local application and account state.
  const { token, authenticated, me, authenticatedFetchState, meFetchState, showMeUpdateIndicator } =
    accountState;

  const profile = me.profile;
  const settings = me.settings;
  const oneDriveActive = settings?.isPluginActive('onedrive') || false;

  const brandingName = settings?.brandingName || 'ADDIFY';
  const brandingImage = settings?.brandingImage || undefined;

  useEffect(() => {
    document.title = settings?.brandingName || 'Sdu Addify';
  }, [settings]);

  // set moment locale to nl (on component mount)
  useEffect(() => {
    moment.locale('nl');
  }, []);

  // get settings if a token is set and the user is authenticated.
  useEffect(() => {
    if (token && authenticated) {
      accountActions.getSettings();
    }
    // eslint-disable-next-line
  }, [token, authenticated]);

  // region extract search query text and search tab
  const params = new URLSearchParams(location.search);
  const urlQuery = params.get('query') ?? '';
  // endregion

  const [isShown, setShown] = useState(false);

  const onSelectOrSubmit = (query) => {
    setShown(false);
    navigate(`/search?query=${query}`);
  };

  // wait for the settings to be loaded.
  if ((meFetchState.isBusy && showMeUpdateIndicator) || !profile || !settings) {
    return (
      <div className="c-application loading">
        <PageLoader />
        <div className="loading-circle loading-circle__1" />
        <div className="loading-logo" />
      </div>
    );
  }

  // menu items for profile drawer
  let menuItems = [...ProfileMenu];
  const adminItemsSpliceIndex = 3;

  if (profile.isAdmin()) {
    menuItems = [
      ...[...ProfileMenu].splice(0, adminItemsSpliceIndex),
      ...SettingsMenu,
      ...[...ProfileMenu].splice(adminItemsSpliceIndex, ProfileMenu.length),
    ];
  }

  return (
    <>
      <OneDriveUserAuthChecker
        doProfileRefresh={() => accountActions.getSettings(true)}
        isPluginActive={oneDriveActive}
        meProfile={profile}
        onNeedToRefreshToken={() => accountActions.getSettings(true)}
      />
      <FetchLoader />
      <Navigation
        isSticky
        avatar={
          !shouldHideProfileItem && (
            <Link onClick={() => setShowProfileDrawer(!showProfileDrawer)}>
              <Avatar size={40} src={profile.avatarUrl} />
            </Link>
          )
        }
        brandName={brandingName}
        isLoading={authenticatedFetchState.isBusy}
        isSearchShown={isShown}
        logoComponent={<ApplicationTitle brandImage={brandingImage} brandName={brandingName} />}
        matchFunction={(href) => {
          if (href && href.length <= 1) {
            return location.pathname === href;
          }
          return location.pathname.startsWith(href);
        }}
        searchComponent={
          constants.APPLICATION_SEARCH_ENABLED && !shouldHideSearchbar ? (
            <SearchAutocomplete defaultQuery={urlQuery} onSelectOrSubmit={onSelectOrSubmit} />
          ) : (
            <></>
          )
        }
        sidebarItems={!shouldHideSidebarItems ? MainMenu : undefined}
        widgets={[<NotificationNavigationIcon key="notificationCenterIcon" />]}
        onToggleSearch={setShown}
      />
      <DrawerProfile
        isShown={showProfileDrawer}
        menuItems={menuItems}
        profile={profile}
        versionNumber={constants.APPLICATION_VERSION}
        onClose={() => setShowProfileDrawer(false)}
      />
      <Announcements />
      <div className="c-application">
        <article>{children}</article>
      </div>
    </>
  );
};

ApplicationContainer.defaultProps = {
  shouldHideSidebarItems: false,
  shouldHideProfileItem: false,
  shouldHideSearchbar: false,
};

ApplicationContainer.propTypes = {
  shouldHideSidebarItems: PropTypes.bool,
  shouldHideProfileItem: PropTypes.bool,
  shouldHideSearchbar: PropTypes.bool,
};

export default ApplicationContainer;
