import type { FilterItemProp } from '@elseu/sdu-titan';
import { Box, CheckboxTree, FilterItemsWithSearchContainer, Loader } from '@elseu/sdu-titan';
import { useQuery } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import type { SubOffice } from 'entity/crm/types';
import debounce from 'lodash/debounce';
import React, { useMemo, useState } from 'react';

type Props = FilterItemProp<string | undefined> & {
  isNotLinkedOptionShown?: boolean;
  isAllOptionsShown?: boolean;
};

const FilterSubOffice: React.FC<Props> = ({
  isAllOptionsShown,
  isNotLinkedOptionShown,
  value,
  onChange,
}) => {
  const [search, setSearch] = useState<string>('');
  const query = useQuery({
    queryKey: ['filter-suboffice', search],
    queryFn: () => Actions.getAPIService().getCrm().subOffices(search, '', 1, 50),
  });

  const items: any[] = useMemo(() => {
    if (!query.data) {
      return [];
    }

    let retValue = (query.data.data.subOffices || []).map((subOfficeInner: SubOffice) => ({
      label: subOfficeInner.name,
      value: subOfficeInner.id,
    }));

    if (!search) {
      if (isAllOptionsShown) {
        retValue = [
          {
            value: null,
            label: 'Alle',
          },
          ...retValue,
        ];
      }
      if (isNotLinkedOptionShown) {
        retValue = [
          {
            value: '<NULL>',
            label: 'Alle (zonder vestiging)',
          },
          ...retValue,
        ];
      }
    }
    return retValue;
  }, [isAllOptionsShown, isNotLinkedOptionShown, query.data, search]);

  return (
    <FilterItemsWithSearchContainer onSearchChange={debounce(setSearch, 500)}>
      {query.isLoading ? (
        <Box px={8} py={8}>
          <Loader height={72} variant="spinner" />
        </Box>
      ) : (
        <CheckboxTree
          data={items}
          value={value ? [value] : undefined}
          onChange={(value) => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (value.length > 0 && value[0] === null) {
              onChange(undefined);
            }
            onChange(value.length > 0 ? value[0] : undefined);
          }}
        />
      )}
    </FilterItemsWithSearchContainer>
  );
};

export default FilterSubOffice;
