import { Link, Text } from '@elseu/sdu-titan';
import Email from 'components/email/Email';
import Phone from 'components/phone/Phone';
import type { ContactPerson } from 'entity/crm/types';
import React from 'react';

import type { OnRenderViewParams } from '../types';

export const textFieldRender = ({ value }: OnRenderViewParams) => (
  <Text isBlock isTruncated type="paragraph">
    {value || '-'}
  </Text>
);

export const todoFieldRender = () => <div>TODO</div>;

export const subOfficeFieldRender = ({ value }: OnRenderViewParams) => (
  <Text isBlock isTruncated type="paragraph">
    {value?.name || '-'}
  </Text>
);

export const radioGroupFieldRender = ({ field, value }: OnRenderViewParams) => {
  if (!value || !field.renderOptions?.options) {
    return <div>-</div>;
  }

  const option = field.renderOptions.options.find((option: any) => option.value === value);
  return (
    <Text isBlock isTruncated type="paragraph">
      {option?.label || '-'}
    </Text>
  );
};

export const emailFieldRender = ({ value }: OnRenderViewParams) =>
  value ? (
    <Text isBlock isTruncated type="paragraph">
      <Email email={value} />
    </Text>
  ) : (
    <div>-</div>
  );

export const phoneFieldRender = ({ value }: OnRenderViewParams) =>
  value ? <Phone number={value} /> : <div>-</div>;

export const clientUserRender = ({ value }: OnRenderViewParams) =>
  value ? (
    <Link to={`/crm/client-users/client-user/details/${value.id}`}>{value?.displayName}</Link>
  ) : (
    <div>-</div>
  );

export const contactPersonsRender = ({ value }: OnRenderViewParams) =>
  value && value.length > 0 ? (
    <div>{value.map((item: ContactPerson) => item.displayName).join(', ')}</div>
  ) : (
    <div>-</div>
  );

export const addressRender = ({ value }: OnRenderViewParams) =>
  value ? (
    <>
      <Text isBlock spaceAfter={1} type="label">
        {value.street}&nbsp;{value.number}&nbsp;{value.addition}
      </Text>
      <Text isBlock spaceAfter={1} type="label">
        {value.zipcode}&nbsp;{value.place}
      </Text>
      {value.country && (
        <Text isBlock type="label">
          {value.country}
        </Text>
      )}
    </>
  ) : (
    <div>-</div>
  );
