import type { Ticket, TicketParentCard, TicketParentDossier } from 'entity/dossiers/types';

import { formatCardName } from './formatCardName';

export const getParentDossier = (ticket: Ticket): TicketParentDossier | undefined =>
  ticket.parents.find((parent) => parent.type === 'dossier') as TicketParentDossier;

export const getParentCard = (ticket: Ticket): TicketParentCard | undefined =>
  ticket.parents.find((parent) => parent.type === 'card') as TicketParentCard;

export const getParentDossierByType = (
  ticket: Ticket,
  dossierType: string,
): TicketParentDossier | undefined =>
  ticket.parents.find(
    (parent) => parent.type === 'dossier' && parent.dossierType === dossierType,
  ) as TicketParentDossier;

/**
 * Get the name of the ticket's parent
 */
export const getTicketParentName = (ticket: Ticket): string | null => {
  switch (ticket.type) {
    case 'task': {
      const parentCard = getParentCard(ticket);

      if (parentCard) {
        return formatCardName(parentCard.name);
      }

      return null;
    }
    case 'subdossier_canvas':
    case 'subdossier_document': {
      const parentDossier = getParentDossier(ticket);

      if (parentDossier) {
        return formatCardName(parentDossier.name);
      }

      return null;
    }
    case 'note_phone':
    case 'note_email':
    case 'note_conversation': {
      const parentDossier = getParentDossierByType(ticket, 'normal');

      if (parentDossier) {
        return formatCardName(parentDossier.name);
      }

      return null;
    }
    default:
      return null;
  }
};

/**
 * Generate the URL for the ticket based on the ticket type
 * and its parents or clients
 */
export const generateTicketUrl = (ticket: Ticket): string => {
  switch (ticket.type) {
    case 'dossier':
      return `/beta/dossiers/${ticket.source.id}`;
    case 'subdossier_canvas':
    case 'subdossier_document': {
      const dossier = getParentDossier(ticket)!;
      return `/beta/dossiers/${dossier.id}/subdossier/${ticket.source.id}`;
    }
    default:
      throw new Error(`Unsupported ticket type: ${ticket.type}`);
  }
};

/**
 * Generate the URL for the ticket's parent
 */
export const generateTicketParentUrl = (ticket: Ticket): string | null => {
  switch (ticket.type) {
    case 'dossier':
      return `/beta/dossiers/${ticket.source.id}`;
    case 'task': {
      const client = ticket.clients[0];
      const dossier = getParentDossier(ticket);
      const parentCard = getParentCard(ticket);

      if (dossier && parentCard) {
        switch (dossier.dossierType) {
          case 'contact_note':
            return `/crm/clients/client/details/${client.id}/contact_notes/view/${dossier.id}`;
          case 'client':
            return `/crm/clients/client/details/${client.id}/details`;
          case 'normal': {
            if (parentCard.cardType === 'own') {
              return `/dossiers/analyse/${dossier.id}/results/cards/own/${parentCard.id}`;
            }

            if (parentCard.cardType === 'template') {
              return `/dossiers/analyse/${dossier.id}/considerations/cards/template/${parentCard.id}`;
            }

            return `/beta/dossiers/${dossier.id}/subdossier/${parentCard.id}`;
          }
          default:
            return '';
        }
      }

      return null;
    }
    case 'subdossier_canvas':
    case 'subdossier_document': {
      const dossier = getParentDossier(ticket)!;
      return `/beta/dossiers/${dossier.id}`;
    }
    case 'note_phone':
    case 'note_email':
    case 'note_conversation': {
      const linkedDossier = getParentDossierByType(ticket, 'normal');

      if (linkedDossier) {
        return `/beta/dossiers/${linkedDossier.id}`;
      }

      return null;
    }

    default:
      return null;
  }
};
