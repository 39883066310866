import { useSortable } from '@dnd-kit/sortable';
import { Card, withTitan } from '@elseu/sdu-titan';
import type { Row } from '@tanstack/react-table';
import { useKanban } from 'containers/tickets/src/hooks/useKanban';
import type { Ticket } from 'entity/dossiers/types';
import type { MotionProps } from 'framer-motion';
import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

export interface KanbanCardProps {
  row: Row<any>;
  style?: string;
}

const KanbanCardContainer = styled(motion.div)`
  &[role='button'] {
    cursor: grab;
  }

  &[data-dragging='true'] {
    opacity: 0.2;
  }
`;

const KanbanCard = withTitan<KanbanCardProps>(
  ({ row, style }) => {
    const { onCardClick, renderCardContent } = useKanban<Row<Ticket>>();
    const { attributes, setNodeRef, listeners, transform, isDragging } = useSortable({
      id: row.id,
      transition: null,
    });

    const draggingAnimationProps =
      transform &&
      ({
        animate: {
          x: transform.x,
          y: transform.y,
          zIndex: isDragging ? 10 : 0,
        },
      } as MotionProps);

    const draggingProps = { ...draggingAnimationProps, ...attributes, ...listeners };

    return (
      <KanbanCardContainer
        ref={setNodeRef}
        transition={{
          duration: 0,
        }}
        {...draggingProps}
        data-dragging={isDragging}
        tabIndex={-1}
      >
        <Card
          isBordered
          backgroundVariant={style === 'highlight' ? 'grey' : 'white'}
          px={4}
          py={4}
          tabIndex={0}
          onClick={() => onCardClick?.(row.original)}
        >
          {renderCardContent?.(row.original)}
        </Card>
      </KanbanCardContainer>
    );
  },
  { name: 'KanbanCard' },
);

export { KanbanCard };
