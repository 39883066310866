import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';

/**
 * One Drive API class
 */
export default class OneDriveApi extends APIEndpoint {
  // #region details
  /**
   * Get cloud attachment details
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} cloudAttachmentId
   */
  getCloudAttachmentDetails(dossierId, cardId, cloudAttachmentId) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `cards/${cardId}/cloud_attachments/${cloudAttachmentId}`,
    );
  }

  // endregion

  // region modify
  /**
   * Update cloud attachment details
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} cloudAttachmentId
   * @param {object} values
   */
  updateCloudAttachment(dossierId, cardId, cloudAttachmentId, values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `cards/${cardId}/cloud_attachments/${cloudAttachmentId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  // endregion

  // region delete
  /**
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} cloudAttachmentId
   * @param {boolean} deleteOnCloud
   * @returns {Promise}
   */
  removeCloudAttachment(dossierId, cardId, cloudAttachmentId, deleteOnCloud = false) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `cards/${cardId}/cloud_attachments/${cloudAttachmentId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
      { deleteOnCloud },
    );
  }

  // #endregion

  // #region upload files
  /**
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} fileType
   * @param {object} values
   * @returns {Promise}
   */
  addCloudAttachmentWithType(dossierId, cardId, fileType, values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `cards/${cardId}/cloud_attachments/${fileType}`,
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }

  /**
   * Upload a file from computer to cloud.
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string|undefined} attachmentFolder
   * @param {string|undefined} displayDate
   * @param {File} file
   */
  addCloudAttachmentWithFile(
    dossierId,
    cardId,
    file,
    attachmentFolder = undefined,
    displayDate = undefined,
  ) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).upload(
      `cards/${cardId}/cloud_attachments`,
      file,
      { attachmentFolder, displayDate },
    );
  }

  /**
   * Add cloud attachment from a one Drive file id
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} oneDriveId
   * @param {string|undefined} attachmentFolder
   * @param {string|undefined} displayDate
   * @returns {Promise}
   */
  addCloudAttachmentWithOneDriveId(
    dossierId,
    cardId,
    oneDriveId,
    attachmentFolder = undefined,
    displayDate = undefined,
  ) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `cards/${cardId}/cloud_attachments/personal_cloud_file`,
      JSendRequest.REQUEST_METHOD_POST,
      {
        id: oneDriveId,
        attachmentFolder,
        displayDate,
      },
    );
  }

  // #endregion

  // #region login urls
  /**
   * Get OneDrive login url for authentication used for admin user.
   *
   * @returns {Promise}
   */
  adminLoginUrl() {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request('onedrive/admin/login-url');
  }

  /**
   * Get OneDrive login url for authentication used for normal user.
   *
   * @returns {Promise}
   */
  userLoginUrl() {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request('onedrive/login-url');
  }

  // #endregion

  // #region admin settings
  /**
   * Get OneDrive settings for adminstrator panel.
   *
   * @returns {Promise}
   */
  adminSettings() {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request('onedrive/admin');
  }

  /**
   * Save settings for OneDrive plugin
   *
   * @param {object} values
   * @returns {Promise}
   */
  adminSaveSettings(values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'onedrive/admin/settings',
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * @param {string} driveId
   * @returns {Promise}
   */
  validateSharepointDrive(driveId) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'onedrive/admin/validate_share_point',
      JSendRequest.REQUEST_METHOD_POST,
      { driveId },
    );
  }

  // #endregion
}
