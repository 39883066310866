import { PropTypes } from 'models/base/DataModel';
import ContactNoteCard from 'models/crm/ContactNoteCard';
import Dossier from 'models/dossier/Dossier';

/**
 * Contact note model
 */
export default class ContactNote extends Dossier {
  static propTypes = {
    ...Dossier.propTypes,
    contactNoteCard: PropTypes.any,
  };

  /**
   * @type ContactNoteCard
   */
  contactNoteCard;

  /**
   * @returns {ContactNoteCard}
   */
  getContactNoteCard() {
    return this.contactNoteCard;
  }

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'contactNoteCard') {
      value = ContactNoteCard.createInstance(value);
    }

    return super.set(fieldName, value);
  }
}
