import { useQuery } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import type { LinkSelectorElementProps } from 'components/card/CardSectionLinkDossierLinkModal';
import RadioGroup from 'components/radio/RadioGroup';
import { normalizeContactNote } from 'entity/contactnote/normalizer';
import { normalizeOverviewResults } from 'helpers/normalizehelper';
import { useTitanTableState } from 'helpers/TitanTableHelpers';
import React, { useMemo, useState } from 'react';
import type { ApiFilter } from 'types/types';

import ContactNoteTable, { normalizeContactNoteTableItem } from './ContactNoteTable';

// default settings
const DEFAULT_SORT = 'createdAt';

const sortMapping = [
  {
    sortName: 'client.name',
    tableAccessor: 'client',
  },
];

const getContactNotesOverview = (
  filter: ApiFilter[],
  sort: string | undefined,
  page = 1,
  pageSize: number,
) => {
  return Actions.getAPIService()
    .contactNotes()
    .getContactNotes(filter, sort, page, pageSize)
    .then((data) => normalizeOverviewResults(data.data, 'contactNotes', normalizeContactNote));
};

const ContactNoteLinkTable: React.FC<LinkSelectorElementProps> = ({
  dossierId,
  cardId,
  clients,
  onSelect,
}) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>();

  const { page, pageSize, filters, sort, tableProps } = useTitanTableState({
    defaultSort: [
      {
        id: DEFAULT_SORT,
        desc: true,
      },
    ],
    stateName: 'contactnotes',
    sortMapping,
  });

  const queryFilters: ApiFilter[] = useMemo(() => {
    return [
      ...filters,
      {
        name: 'clients',
        value: clients.toString(),
      },
    ];
  }, [filters, clients]);

  const query = useQuery({
    queryKey: [
      'contactnotes',
      dossierId,
      cardId,
      {
        page,
        pageSize,
        sort,
        filters,
      },
    ],
    queryFn: () => getContactNotesOverview(queryFilters, sort, page, pageSize),
  });
  const data = useMemo(
    () => (query.data?.results ?? []).map(normalizeContactNoteTableItem),
    [query.data?.results],
  );

  return (
    <RadioGroup
      selected={selectedValue}
      onChange={(value: string) => {
        setSelectedValue(value);
        onSelect(value);
      }}
    >
      <ContactNoteTable
        shouldDisableRoutes
        shouldHideNewButton
        data={data}
        defaultPageSize={pageSize}
        isLoading={query.isLoading}
        tableStateProps={tableProps}
        totalCount={query.data?.paging.numberOfResults ?? 0}
        view="relatedCard"
      />
    </RadioGroup>
  );
};

export default ContactNoteLinkTable;
