import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import { useInvalidateDossierCache } from 'hooks/invalidate/useInvalidateDossierCache';
import type JSendResponse from 'services/JSendResponse';

type SuccesHandlerType = (data: JSendResponse) => void;
type ErrorHandlerType = (error: JSendResponse) => void;

type DossierMutateParams = {
  onSuccess?: SuccesHandlerType;
  onError?: ErrorHandlerType;
};

type ArchiveDossierMutateParams = {
  dossierId: string;
  isArchived: boolean;
};

export const useDossierDeleteMutate = ({
  onSuccess,
  onError,
}: DossierMutateParams): UseMutationResult<JSendResponse, any, string, any> => {
  const invalidateDossierCache = useInvalidateDossierCache();

  return useMutation<JSendResponse, any, string, any>({
    mutationFn: (dossierId): Promise<JSendResponse> => {
      return Actions.getAPIService()
        .getDossiers()
        .deleteDossier(dossierId) as Promise<JSendResponse>;
    },
    onSuccess: (data, variables) => {
      invalidateDossierCache(variables);
      onSuccess?.(data);
    },
    onError: (error) => {
      onError?.(error);
    },
  });
};

export const useDossierArchiveMutate = ({
  onSuccess,
  onError,
}: DossierMutateParams): UseMutationResult<JSendResponse, any, ArchiveDossierMutateParams, any> => {
  const invalidateDossierCache = useInvalidateDossierCache();

  return useMutation<JSendResponse, any, ArchiveDossierMutateParams, any>({
    mutationFn: (params: ArchiveDossierMutateParams): Promise<JSendResponse> => {
      if (params.isArchived) {
        return Actions.getAPIService()
          .getDossiers()
          .dossierUnArchive(params.dossierId) as Promise<JSendResponse>;
      }
      return Actions.getAPIService()
        .getDossiers()
        .dossierArchive(params.dossierId) as Promise<JSendResponse>;
    },
    onSuccess: (data, variables) => {
      invalidateDossierCache(variables.dossierId);
      onSuccess?.(data);
    },
    onError: (error) => {
      onError?.(error);
    },
  });
};
