import './assets/styles/Options.scss';

import classNames from 'classnames';
import EllipsisIcon from 'components/icons/EllipsisIcon';
import OptionItem from 'components/options/OptionItem';
import OptionSeparator from 'components/options/OptionSeparator';
import { useOutsideComponentClicked } from 'hooks';
import React, { useEffect, useState } from 'react';

export enum OptionItemType {
  OPTION_ITEM = 'option-item',
  OPTION_SEPARATOR = 'option-separator',
}

export type IOptionItem = {
  type?: OptionItemType;
  label: string | React.ReactElement;
  isDisabled?: boolean;
  image?: any;
  onClick?: (event: any, context: any) => void;
};

type Props = React.PropsWithChildren<{
  className?: string;
  context?: any;
  options: IOptionItem[];
  isRtl?: boolean;
  isToggled?: boolean;
  isDisabled?: boolean;
  hasNoIcon?: boolean;
  icon?: React.ReactElement;
  onToggle?: (toggle: boolean) => void;
}>;

/**
 * Options
 *
 * The options component represents an ellipsis icon indicating that more options are available. When clicked the
 * component will render a popup menu with items.
 *
 * @param {Object} props
 * @return {*}
 * @constructor
 */
const Options: React.FC<Props> = ({
  className,
  context,
  options = [],
  isRtl = false,
  isToggled = false,
  icon = <EllipsisIcon clickable color="primary-blue" colorHover="primary-blue" size={15} />,
  hasNoIcon,
  isDisabled = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onToggle = () => {},
  children,
}) => {
  const { ref, isClickedOutside, setIsClickedOutSide } = useOutsideComponentClicked();
  const [toggledState, setToggledState] = useState<boolean>(isToggled);
  useEffect(() => setToggledState(isToggled), [isToggled]);
  useEffect(() => {
    if (isClickedOutside && toggledState) {
      setToggledState(false);
      onToggle(false);
    }
    setIsClickedOutSide(false); // reset state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClickedOutside]);

  if (options.length === 0) {
    return null;
  }

  return (
    <div
      ref={ref}
      aria-hidden="true"
      className={classNames(className, 'c-options', {
        'c-options--rtl': isRtl,
        'c-options--open': toggledState,
        'c-options--disabled': isDisabled,
      })}
      role="button"
      tabIndex={0}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        if (!isDisabled) {
          setToggledState(!toggledState);
          onToggle(!toggledState);
        }
      }}
    >
      {toggledState ? (
        <ul className="c-options__menu">
          {options.map((option, key) => {
            switch (option.type || OptionItemType.OPTION_ITEM) {
              case OptionItemType.OPTION_ITEM:
                return (
                  <OptionItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`action-${key}`}
                    className={classNames('options__list__item', {
                      'options__list__item-disabled': option.isDisabled,
                    })}
                    image={option.image}
                    isDisabled={!(!isDisabled && !option.isDisabled)}
                    onClick={(event) => {
                      option.onClick?.(event, context);
                    }}
                  >
                    {option.label}
                  </OptionItem>
                );
              case OptionItemType.OPTION_SEPARATOR:
                return (
                  <OptionSeparator
                    // eslint-disable-next-line react/no-array-index-key
                    key={`action-${key}`}
                    className={classNames('options__list__item', {
                      'options__list__item-disabled': option.isDisabled,
                    })}
                  >
                    {option.label}
                  </OptionSeparator>
                );
              default:
                return null;
            }
          })}
          {children}
        </ul>
      ) : (
        !hasNoIcon && icon
      )}
    </div>
  );
};

export default Options;
