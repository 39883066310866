import { normalizeDisplayName } from 'entity/crm/normalizer';
import DataModel, { PropTypes } from 'models/base/DataModel';
import Address from 'models/crm/Address';
import { getGenderText } from 'models/crm/GenderTypes';
import SubOffice from 'models/system/SubOffice';
import Team from 'models/system/Team';

/**
 * Classification types
 */
const CLASSIFICATION_TYPES = {
  business: 'Bedrijf',
  private: 'Prive',
};

/**
 * Client
 */
export default class Client extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    clientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    showClientId: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        ...DataModel.propTypes,
        user_id: PropTypes.number,
        firstName: PropTypes.string,
        initials: PropTypes.string,
        infix: PropTypes.string,
        surname: PropTypes.string,
        gender: PropTypes.string,
        phone: PropTypes.string,
        mobilePhone: PropTypes.string,
        email: PropTypes.string,
        recordChangeDate: PropTypes.string,
        lang: PropTypes.string,
        locale: PropTypes.string,
      }),
    ),
    fax: PropTypes.string,
    sbiCode: PropTypes.string,
    kvkSize: PropTypes.string,
    kvkNumber: PropTypes.string,
    fiscalNumber: PropTypes.string,
    bsnNumber: PropTypes.string,
    fiscalNumberOb: PropTypes.string,
    fiscalNumberLh: PropTypes.string,
    initials: PropTypes.string,
    infix: PropTypes.string,
    surname: PropTypes.string,
    gender: PropTypes.string,
    physicalAddress: PropTypes.shape(Address.propTypes),
    postalAddress: PropTypes.shape(Address.propTypes),
    classification: PropTypes.string,
    subOfficeName: PropTypes.string,
    subOffice: PropTypes.shape(SubOffice.propTypes),
    recordChangeDateTime: PropTypes.string,
    isManual: PropTypes.bool,
    metaInfo: PropTypes.shape({
      countDossiers: PropTypes.number,
    }),
    permissions: PropTypes.shape({
      canView: PropTypes.bool,
      canEdit: PropTypes.bool,
      canDelete: PropTypes.bool,
      canSetPermissions: PropTypes.bool,
      canXbrlView: PropTypes.bool,
    }),
    teams: PropTypes.arrayOf(PropTypes.shape(Team.propTypes)),
  };

  /**
   * @type {string|number}
   */
  clientId;

  /**
   * @type {string}
   */
  showClientId;

  /**
   * @type {string}
   */
  name;

  /**
   * @type {string}
   */
  phone;

  /**
   * @type {string}
   */
  email;
  /**
   * @type {ClientUser[]|undefined}
   */
  users = [];

  /**
   * @type {string}
   */
  fax;

  /**
   * @type {string}
   */
  sbiCode;

  /**
   * @type {string}
   */
  kvkSize;

  /**
   * @type {string}
   */
  kvkNumber;

  /**
   * @type {string}
   */
  fiscalNumber;

  /**
   * @type {string}
   */
  bsnNumber;

  /**
   * @type {string}
   */
  fiscalNumberOb;

  /**
   * @type {string}
   */
  fiscalNumberLh;

  /**
   * @type {string}
   */
  initials;

  /**
   * @type {string}
   */
  infix;

  /**
   * @type {string}
   */
  surname;

  /**
   * @type {string}
   */
  gender;
  /**
   * @type {Address|undefined}
   */
  physicalAddress;

  /**
   * @type {Address|undefined}
   */
  postalAddress;

  /**
   * @type {string}
   */
  classification;

  /**
   * @type {string}
   */
  subOfficeName;

  /**
   * @type {SubOffice}
   */
  subOffice;

  /**
   * @type {string}
   */
  recordChangeDateTime;

  /**
   * @type {boolean|undefined}
   */
  isManual;

  /**
   * @type {object}
   */
  metaInfo;

  /**
   * @type {object|undefined}
   */
  permissions;

  /**
   * @type {array}
   */
  teams;

  /**
   * @return {string}
   */
  getDisplayName() {
    return normalizeDisplayName(this);
  }

  /**
   * @return {string|number}
   */
  getClientId() {
    return this.clientId;
  }

  /**
   * @return {string}
   */
  getShowClientId() {
    return this.showClientId;
  }

  /**
   * @return {string}
   */
  getName() {
    return this.name;
  }

  /**
   * @return {string}
   */
  getPhone() {
    return this.phone;
  }

  /**
   * @return {string}
   */
  getEmail() {
    return this.email;
  }

  /**
   * @return {ClientUser[]}
   */
  getUsers() {
    if (!this.users) {
      return [];
    }
    return this.users;
  }

  /**
   * @return {string}
   */
  getFax() {
    return this.fax;
  }

  /**
   * @return {string}
   */
  getKvkSize() {
    return this.kvkSize;
  }

  /**
   * @return {string}
   */
  getKvkNumber() {
    return this.kvkNumber;
  }

  /**
   * @return {string}
   */
  getFiscalNumber() {
    return this.fiscalNumber;
  }

  /** q
   * @return {string}
   */
  getBsnNumber() {
    return this.bsnNumber;
  }

  /**
   * @return {string}
   */
  getFiscalNumberOb() {
    return this.fiscalNumberOb;
  }

  /**
   * @return {string}
   */
  getFiscalNumberLh() {
    return this.fiscalNumberLh;
  }

  /**
   * @return {string}
   */
  getInitials() {
    return this.initials;
  }

  /**
   * @return {*}
   */
  getInfix() {
    return this.infix;
  }

  /**
   * @return {string}
   */
  getSurname() {
    return this.surname;
  }

  /**
   * @return {string}
   */
  getGender() {
    return this.gender;
  }

  /**
   * @return {string}
   */
  getGenderText() {
    return this.getGender() && getGenderText(this.getGender());
  }

  /**
   * @return {Address}
   */
  getPhysicalAddress() {
    if (!this.physicalAddress) {
      return Address.createInstance({});
    }
    return this.physicalAddress;
  }

  /**
   * @return {Address}
   */
  getPostalAddress() {
    if (!this.postalAddress) {
      return Address.createInstance({});
    }
    return this.postalAddress;
  }

  /**
   * @returns {string}
   */
  getClassification() {
    return this.classification;
  }

  /**
   * @returns {string}
   */
  getClassificationText() {
    return (this.classification && CLASSIFICATION_TYPES[this.classification]) || undefined;
  }

  /**
   * @returns {string}
   */
  getSubOfficeName() {
    return this.subOfficeName;
  }

  /**
   * @return {string}
   */
  getRecordChangeDateTime() {
    return this.recordChangeDateTime;
  }

  /**
   * @return {string}
   */
  getSbiCode() {
    return this.sbiCode;
  }

  /**
   * @return {boolean}
   */
  getIsManual() {
    if (this.isManual === undefined) {
      return true;
    }
    return this.isManual;
  }

  /**
   * @return {object}
   */
  getMetaInfo() {
    return this.metaInfo;
  }

  /**
   * @return {array}
   */
  getCountDossiers() {
    return this.metaInfo.countDossiers;
  }

  /**
   * @return {string}
   */
  getAvatarUrl() {
    return '';
  }

  /**
   * @return {string}
   */
  getType() {
    return 'company';
  }

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'physicalAddress') {
      value = Address.createInstance(value);
    }

    if (fieldName === 'postalAddress') {
      value = Address.createInstance(value);
    }

    if (fieldName === 'teams') {
      value = Team.createInstancesByArray(value);
    }

    return super.set(fieldName, value);
  }

  // region helper methods for client permissions.

  /**
   * @return {boolean}
   */
  getCanView() {
    return this.permissions?.canView || false;
  }

  /**
   * @return {boolean}
   */
  getCanEdit() {
    return this.permissions?.canEdit || false;
  }

  /**
   * @return {boolean}
   */
  getCanDelete() {
    return this.permissions?.canDelete || false;
  }

  /**
   * @return {boolean}
   */
  getCanSetPermissions() {
    return this.permissions?.canSetPermissions || false;
  }

  /**
   * @return {boolean}
   */
  getCanXbrlView() {
    return this.permissions?.canXbrlView || false;
  }

  // endregion

  /**
   * @return {Team[]}
   */
  getTeams() {
    return this.teams || [];
  }
}
