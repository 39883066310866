import { StatusMessage } from '@elseu/sdu-titan';
import { useQuery } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import type { LinkSelectorElementProps } from 'components/card/CardSectionLinkDossierLinkModal';
import type { DossierTableItem } from 'components/dossier/DossierTableView';
import DossierTable, { normalizeDossierTableItem } from 'components/dossier/DossierTableView';
import RadioGroup from 'components/radio/RadioGroup';
import { normalizeDossier } from 'entity/dossiers/normalizer';
import { normalizeOverviewResults } from 'helpers/normalizehelper';
import { useTitanTableState } from 'helpers/TitanTableHelpers';
import React, { useMemo, useState } from 'react';
import type { ApiFilter } from 'types/types';

// default table settings
const DEFAULT_SORT = 'name';

const getDossiers = (filter: ApiFilter[], sort: string | undefined, page = 1, pageSize: number) => {
  return Actions.getAPIService()
    .getDossiers()
    .getDossiers(filter, sort, page, pageSize)
    .then((data) => normalizeOverviewResults(data.data, 'dossiers', normalizeDossier));
};

const DossierLinkDossierTable: React.FC<LinkSelectorElementProps> = ({
  dossierId,
  cardId,
  clients,
  onSelect,
}) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { page, pageSize, filters, sort, tableProps } = useTitanTableState({
    defaultSort: [
      {
        id: DEFAULT_SORT,
        desc: false,
      },
    ],
    stateName: `dossier-link-overview`,
  });

  const queryFilters: ApiFilter[] = useMemo(() => {
    return [
      ...filters,
      {
        name: 'clients',
        value: clients.toString(),
      },
    ];
  }, [filters, clients]);

  const query = useQuery({
    queryKey: [
      'dossiers',
      dossierId,
      cardId,
      {
        page,
        pageSize,
        sort,
        filter: queryFilters,
      },
    ],
    queryFn: () => getDossiers(queryFilters, sort, page, pageSize),
  });

  const data = useMemo<DossierTableItem[]>(
    () => (query.data?.results ?? []).map(normalizeDossierTableItem),
    [query.data],
  );

  const onSelectHandle = async (dossierId: string) => {
    try {
      const response = await Actions.getAPIService().dossierInventory(dossierId);
      setSelectedValue(dossierId);
      onSelect(response.data?.card?.id);
    } catch {
      setErrorMessage('Er ging iets niet goed bij het koppelen van de kaart.');
    }
  };

  return (
    <RadioGroup selected={selectedValue} onChange={(value: string) => onSelectHandle(value)}>
      {errorMessage && <StatusMessage type="danger">{errorMessage}</StatusMessage>}
      <DossierTable
        data={data}
        defaultPageSize={pageSize}
        isLoading={query.isLoading}
        tableStateProps={tableProps}
        totalCount={query.data?.paging.numberOfResults ?? 0}
        view="relatedCard"
      />
    </RadioGroup>
  );
};

export default DossierLinkDossierTable;
