import './assets/styles/UserSelectField.scss';

import classNames from 'classnames';
import Avatar from 'components/avatar/Avatar';
import SelectField, { components } from 'components/selectfield/SelectField';
import SelectFieldOption from 'components/selectfield/SelectFieldOption';
import React from 'react';

import { ReactComponent as UserImageComponent } from './assets/images/avatar.svg';
import TeamImage, { ReactComponent as TeamImageComponent } from './assets/images/team.svg';

const UserSelectPlaceholder = ({ selectProps }: any) => (
  <div className="c-user-select__placeholder">
    <div className="c-user-select__placeholder__image">
      {selectProps.placeholderIconType === 'team' ? <TeamImageComponent /> : <UserImageComponent />}
    </div>
    <div className="c-user-select__placeholder__text">{selectProps.placeholder}</div>
  </div>
);

const UserSelectInput = (props: any) => {
  const { selectProps } = props;
  if (selectProps.value && selectProps.value.length > 0) {
    return (
      <div className="c-user-select__input-with-value">
        <components.Input {...props} />
      </div>
    );
  } else {
    return (
      <div className="c-user-select__input">
        <div className="c-user-select__input__image">
          {selectProps.placeholderIconType === 'team' ? (
            <TeamImageComponent />
          ) : (
            <UserImageComponent />
          )}
        </div>
        <div className="c-user-select__input__text">
          <components.Input {...props} />
        </div>
      </div>
    );
  }
};

const UserSelectOption = ({ data, children }: any) => (
  <SelectFieldOption
    className="c-user-select__option"
    icon={
      <Avatar
        isRounded
        hasBorder={data.type !== 'team'}
        size={30}
        url={data.type === 'team' ? TeamImage : data.image}
      />
    }
    subtitle={data.email || undefined}
  >
    {children}
  </SelectFieldOption>
);

const UserMultiValueLabel = ({ data }: any) => {
  return (
    <Avatar
      isInline
      isRounded
      className="c-user-select__avatar-label"
      hasBorder={false}
      name={data.label}
      size={30}
      url={data.type === 'team' ? TeamImage : data.image}
    />
  );
};

const UserSingleValue = (props: any) => {
  const { data } = props;
  return (
    <components.SingleValue {...props}>
      <Avatar
        isRounded
        className="c-user-select__avatar-label"
        hasBorder={false}
        name={data.label}
        size={30}
        url={data.type === 'team' ? TeamImage : data.image}
      />
    </components.SingleValue>
  );
};

export type UserSelectFieldOption = {
  label?: string | null;
  value?: string | null;
  email?: string | null;
  image?: string | null;
  type?: string;
  user?: any | null;
};

type UserSelectFieldProps = {
  isDisabled?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  className?: string;
  options: UserSelectFieldOption[];
  value: UserSelectFieldOption[];
  placeholder?: string;
  placeholderIconType?: string;
  hasError?: boolean;
  noOptionsText?: string;
  isCreatable?: boolean;
  isASyncEnabled?: boolean;
  aSyncOnInputChange?: () => null;
  onCreate?: (value: any) => null;
  onChange: (value: any) => void;
  [key: string]: any;
};

/**
 * UserSelectField
 *
 * @param {boolean} isDisabled
 * @param {boolean} isClearable
 * @param {boolean} isMulti
 * @param {string} className
 * @param {array} options
 * @param {array} value
 * @param {function} onChange
 * @param {boolean} hasError
 * @param {string} placeholder
 * @param {string} placeholderIconType
 * @param {string} noOptionsText
 * @param {boolean} isCreatable
 * @param {function} onCreate
 * @param {boolean} isASyncEnabled
 * @param {function} aSyncOnInputChange
 * @param {object} otherProps
 * @return {*}
 * @constructor
 */
const UserSelectField: React.FC<UserSelectFieldProps> = ({
  isDisabled = false,
  isClearable = false,
  isMulti = true,
  className,
  options = [],
  value = [],
  hasError,
  placeholder = 'Niet toegewezen',
  placeholderIconType = 'user',
  noOptionsText,
  isCreatable = false,
  onCreate,
  onChange,
  isASyncEnabled = false,
  aSyncOnInputChange,
  ...otherProps
}) => (
  <SelectField
    {...otherProps}
    isSearchable
    className={classNames('c-user-select', className)}
    componentInput={UserSelectInput}
    componentMultiValueLabel={UserMultiValueLabel}
    componentPlaceholder={UserSelectPlaceholder}
    componentSingleValue={UserSingleValue}
    error={hasError}
    isAsync={isASyncEnabled}
    isClearable={isClearable}
    isCreatable={isCreatable}
    isDisabled={isDisabled}
    isMulti={isMulti}
    optionRenderer={(props: any) => <UserSelectOption {...props} />}
    options={options}
    placeholder={placeholder}
    placeholderIconType={placeholderIconType}
    requestOptions={aSyncOnInputChange}
    value={value}
    onChange={(val: any) => onChange(isMulti ? val : [val])}
    onCreate={(val: any) => (onCreate ? onCreate(val) : null)}
  />
);

export default UserSelectField;
