/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import './assets/styles/CardSectionLinkDossier.scss';

import { ActionMenu, ChevronDownIcon, Link, MenuItem, PlusIcon, Text } from '@elseu/sdu-titan';
import Actions from 'actions/Actions';
import classNames from 'classnames';
import CardSection from 'components/card/CardSection';
import CardSectionLinkDossierCreateDossier from 'components/card/CardSectionLinkDossierCreateDossier';
import CardSectionLinkDossierLinkModal, {
  useCardSectionLinkDossierLinkModal,
} from 'components/card/CardSectionLinkDossierLinkModal';
import ConfirmDialog from 'components/confirmdialog/ConfirmDialog';
import ContactNotePreview from 'components/contactnote/ContactNotePreview';
import DossierCardPreview from 'components/dossier/DossierCardPreview';
import RemoveIcon from 'components/icons/RemoveIcon';
import { normalizeDossier } from 'entity/dossiers/normalizer';
import type { RelatedCard } from 'entity/dossiers/types';
import { useCardDetails, useDossierDetails } from 'hooks/queries';
import useFetchState from 'hooks/useFetchState';
import React, { useEffect, useMemo, useState } from 'react';

export const enum CardSectionLinkDossierLinkTypes {
  NORMAL = 'NORMAL',
  SIGNAL = 'SIGNAL',
  CONTACT_NOTE = 'CONTACT_NOTE',
}

type Props = {
  isDisabled?: boolean;
  isReadOnly?: boolean;
  title?: string;
  dossierId: string;
  cardId?: string;
  relatedCards: RelatedCard[];
  readOnlyText?: string;
  showLinkTypes?: CardSectionLinkDossierLinkTypes[];
  hasGrid?: boolean;
  onCardsNeedsUpdate: () => void;
  onBusy?: (isBusy: boolean) => void;
};

type LinkTypesType = {
  name: string;
  type: CardSectionLinkDossierLinkTypes;
  cardType: string;
  newButtonText: string;
  cardRender: (
    item: RelatedCard,
    index: number,
    isDisabled: boolean | undefined,
    actions: any[],
  ) => React.ReactElement;
};

/**
 * Type of dossier that can be linked.
 */
const linkTypes: LinkTypesType[] = [
  {
    name: 'Dossiers',
    type: CardSectionLinkDossierLinkTypes.NORMAL,
    cardType: 'inventory',
    newButtonText: 'Dossier koppelen',
    cardRender: (item, index, isDisabled, actions) =>
      item.dossier ? (
        <DossierCardPreview
          actions={actions}
          isDisabled={isDisabled}
          item={item.dossier && normalizeDossier(item.dossier)}
          link={`/dossiers/inventory/${item.dossier?.id}`}
        />
      ) : (
        <div>Geen dossier informatie onbekend.</div>
      ),
  },
  {
    name: 'Notities',
    type: CardSectionLinkDossierLinkTypes.CONTACT_NOTE,
    cardType: 'contact_note',
    newButtonText: 'Notitie koppelen',
    cardRender: (item, index, isDisabled, actions) => (
      <ContactNotePreview
        actions={actions}
        isDisabled={isDisabled}
        item={item}
        link={`/contact_note/${item?.dossier?.id}`}
      />
    ),
  },
];

/**
 * CardSectionLinkDossier
 *
 * @param {boolean} isDisabled
 * @param {boolean} isReadOnly
 * @param {string} title
 * @param {string} dossierId
 * @param {string} cardId
 * @param {Card} card
 * @param {Card[]} relatedCards
 * @param {string} readOnlyText
 * @param {string} showLinkTypes
 * @param {boolean} hasGrid
 * @param {function} onCardsNeedsUpdate
 * @param {function} onBusy
 * @return {JSX.Element}
 * @constructor
 */
const CardSectionLinkDossier: React.FC<Props> = ({
  isDisabled,
  isReadOnly,
  title = 'Koppelingen',
  dossierId,
  cardId,
  relatedCards,
  readOnlyText = 'Geen koppelingen',
  showLinkTypes = [
    CardSectionLinkDossierLinkTypes.NORMAL,
    CardSectionLinkDossierLinkTypes.SIGNAL,
    CardSectionLinkDossierLinkTypes.CONTACT_NOTE,
  ],
  hasGrid = false,
  onCardsNeedsUpdate,
  onBusy = () => null,
}) => {
  const { dossier } = useDossierDetails(dossierId);
  const { card } = useCardDetails(cardId);
  const [linkModalState, linkModalDispatch] = useCardSectionLinkDossierLinkModal();
  const displayTypes = linkTypes.filter((linkItem) => showLinkTypes.includes(linkItem.type));
  const [{ isBusy: deleteIsBusy }, { doRequest: deleteDoRequest }] = useFetchState();

  const [createDossierModalShow, setCreateDossierModalShow] = useState(false);

  // region confirm dialog for deleting linked cards
  const [deleteLinkedCard, setDeleteLinkedCard] = useState<RelatedCard | undefined>(undefined);
  const [deleteLinkedCardDisabled, setDeleteLinkedCardDisabled] = useState(false);
  // endregion

  const clients: string[] = useMemo(() => {
    const dossierClients = dossier?.clients?.map((clientUser) => clientUser?.client?.id) || [];
    return [...dossierClients, card?.client?.id].filter(
      (clientId) => clientId !== undefined,
    ) as string[];
  }, [dossier, card]);

  /**
   * When the link card modal is requested to be opened.
   *
   * @para {object} displayType
   */
  const onOpenLinkModal = (displayType: LinkTypesType) => {
    linkModalDispatch({
      type: 'show',
      data: {
        type: displayType.type,
        clients,
      },
    });
  };

  const isBusy = deleteIsBusy;
  useEffect(() => {
    onBusy(isBusy);
  }, [isBusy, onBusy]);

  return (
    <CardSection className="c-card-section-link-dossier" title={title}>
      {isReadOnly === true && relatedCards.length === 0 && <span>{readOnlyText}</span>}
      {isReadOnly === false &&
        displayTypes.length > 0 &&
        displayTypes.map((displayType) => (
          <div key={`section-${displayType.type}`} className="c-card-section-link-dossier__section">
            {displayTypes.length > 1 && (
              <Text isBlock color="grey100" spaceAfter={3} type="labelBold">
                {displayType.name}
              </Text>
              // <div className="c-card-section-link-dossier__section__title">{displayType.name}</div>
            )}
            <div className="c-card-section-link-dossier__section__content">
              <div
                className={classNames('c-card-section-link-dossier__items', {
                  'c-card-section-link-dossier__items--grid': hasGrid,
                })}
              >
                {relatedCards
                  .filter((relatedCard) => relatedCard.type === displayType.cardType)
                  .map((relatedCard, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={`${displayType.type}-${index}`}>
                      {displayType.cardRender(relatedCard, index, isDisabled, [
                        {
                          image: <RemoveIcon size={16} />,
                          label: 'Verwijderen',
                          onClick: (event: React.MouseEvent<any>) => {
                            event.stopPropagation();
                            event.preventDefault();

                            setDeleteLinkedCard(relatedCard);
                          },
                        },
                      ])}
                    </React.Fragment>
                  ))}
              </div>
              {!isReadOnly && (
                <div className="c-card-section-link-dossier__actions">
                  {displayType.type === 'NORMAL' ? (
                    <ActionMenu
                      defaultShown={false} // temp-fix to get ActionMenu working
                      label={displayType.newButtonText}
                      popoverWidth={250}
                      trigger={
                        <Link prefixAdornment={<PlusIcon />} suffixAdornment={<ChevronDownIcon />}>
                          {displayType.newButtonText}
                        </Link>
                      }
                    >
                      <MenuItem
                        item={{ label: 'Nieuw dossier aanmaken' }}
                        onClick={() => setCreateDossierModalShow(true)}
                      />
                      <MenuItem
                        item={{ label: 'Bestaand dossier koppelen' }}
                        onClick={() => onOpenLinkModal(displayType)}
                      />
                    </ActionMenu>
                  ) : (
                    <Link
                      prefixAdornment={<PlusIcon />}
                      onClick={() => onOpenLinkModal(displayType)}
                    >
                      {displayType.newButtonText}
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      {linkModalState.show && (
        <CardSectionLinkDossierLinkModal
          {...linkModalState}
          cardId={cardId}
          dossierId={dossierId}
          onBusy={onBusy}
          onClose={() => linkModalDispatch({ type: 'hide' })}
          onLinked={() => {
            onCardsNeedsUpdate();
            linkModalDispatch({ type: 'hide' });
          }}
        />
      )}
      {createDossierModalShow && card && (
        <CardSectionLinkDossierCreateDossier
          card={card}
          clients={clients ?? []}
          dossierId={dossierId}
          isDisabled={isDisabled}
          relatedCards={relatedCards}
          showLinkTypes={showLinkTypes}
          onBusy={onBusy}
          onCancel={() => setCreateDossierModalShow(false)}
          onCardNeedsUpdate={onCardsNeedsUpdate}
        />
      )}
      {deleteLinkedCard && (
        <ConfirmDialog
          cancelTitle="Nee"
          confirmAppearance="danger"
          confirmTitle="Ja, verwijder"
          isConfirmDisabled={deleteLinkedCardDisabled}
          isDisabled={deleteLinkedCardDisabled}
          title="Verwijderen koppeling"
          zIndex={1001000}
          onCancel={() => {
            setDeleteLinkedCardDisabled(false);
            setDeleteLinkedCard(undefined);
          }}
          onConfirm={() => {
            setDeleteLinkedCardDisabled(true);
            deleteDoRequest(
              Actions.getAPIService().deleteRelatedCard(
                dossierId,
                cardId ?? '',
                deleteLinkedCard?.id ?? '',
              ),
            ).then(
              () => {
                onCardsNeedsUpdate();
                setDeleteLinkedCard(undefined);
                setDeleteLinkedCardDisabled(false);
              },
              () => {
                setDeleteLinkedCardDisabled(false);
              },
            );
          }}
        >
          Weet u zeker dat u de koppeling naar <strong>{deleteLinkedCard?.dossier?.name}</strong>{' '}
          wil verwijderen?
        </ConfirmDialog>
      )}
    </CardSection>
  );
};

CardSectionLinkDossier.defaultProps = {
  title: 'Koppelingen',
  readOnlyText: 'Geen koppelingen',
  hasGrid: false,
  showLinkTypes: [
    CardSectionLinkDossierLinkTypes.NORMAL,
    CardSectionLinkDossierLinkTypes.SIGNAL,
    CardSectionLinkDossierLinkTypes.CONTACT_NOTE,
  ],
};

export default CardSectionLinkDossier;
