import './assets/scss/BerkleyNewSessionModal.scss';

import BerkleySessionForm from 'components/berkley/BerkleySessionForm';
import HighlightBox, { IHighlightBoxAppearance } from 'components/highlightbox/HighlightBox';
import ConfirmModal from 'components/modal/ConfirmModal';
import type { ContextItem } from 'entity/dossiers/types';
import React, { useEffect, useState } from 'react';
import {
  errorMessagesTranslations,
  STATE_BERKLEY_SESSION_MODEL_CREATE,
  useBerkleyModelSessionState,
} from 'stores/dossier/BerkleyModelSessionState';
import { sweetFetchStateForContextID } from 'stores/utils/SweetFetchState';

type Props = {
  isDisabled?: boolean;
  dossierId: string;
  cardId?: string;
  item?: ContextItem;
  onBusy: () => void;
  onError: () => void;
  onSaved: () => void;
  onCancel: () => void;
};

/**
 * Create new Berkeley session modal
 *
 * @param {boolean} isDisabled
 * @param {string} dossierId
 * @param {string} cardId
 * @param {object} item
 * @param {function} onBusy
 * @param {function} onError
 * @param {function} onSaved
 * @param {function} onCancel
 * @returns {JSX.Element}
 * @constructor
 */
const BerkleyNewSessionModal: React.FC<Props> = ({
  isDisabled,
  dossierId,
  cardId,
  item,
  onBusy,
  onError,
  onSaved,
  onCancel,
}) => {
  const [berkleyState, berkleyStateActions] = useBerkleyModelSessionState();
  const [values, setValues] = useState({});

  const { isBusy, isSuccess, hasError, errorContext, errorMessage } = sweetFetchStateForContextID(
    berkleyState[STATE_BERKLEY_SESSION_MODEL_CREATE],
  );

  useEffect(() => {
    if (isSuccess) {
      berkleyStateActions.resetFetchState(STATE_BERKLEY_SESSION_MODEL_CREATE);
      onSaved();
    }
  }, [isSuccess, onSaved, berkleyStateActions]);

  const [lastBusy, setLastBusy] = useState(isBusy);
  useEffect(() => {
    if (isBusy !== lastBusy) {
      if (isBusy) {
        onBusy();
      }
      setLastBusy(isBusy);
    }
  }, [isBusy, lastBusy, onBusy]);

  const [lastHasError, setLastHasError] = useState(hasError);
  useEffect(() => {
    if (hasError !== lastHasError) {
      if (hasError) {
        onError();
      }
      setLastHasError(hasError);
    }
  }, [hasError, lastHasError, onError]);

  return (
    <ConfirmModal
      shouldUsePortal
      className="c-berkley-new-session-modal"
      confirmTitle="Maak sessie"
      isDisabled={isDisabled || isBusy}
      title="Interactieve sessie model starten"
      onCancel={() => {
        berkleyStateActions.resetFetchState(STATE_BERKLEY_SESSION_MODEL_CREATE);
        onCancel();
      }}
      onConfirm={() => {
        if (item?.berkleyModelId) {
          berkleyStateActions.createBerkleySession(dossierId, cardId, {
            ...values,
            berkleyModelId: item.berkleyModelId,
          });
        }
      }}
    >
      {errorMessage && (errorContext === undefined || errorContext === null) && (
        <HighlightBox
          appearance={IHighlightBoxAppearance.APPEARANCE_RED}
          className="c-berkley-new-session-modal__error-message"
        >
          {(errorMessage && errorMessagesTranslations[errorMessage]) ?? errorMessage}
        </HighlightBox>
      )}
      <BerkleySessionForm
        error={errorContext || {}}
        hasInformation={false}
        isDisabled={isDisabled || isBusy}
        item={item}
        values={values}
        onChange={(key, value) => setValues({ ...values, [key]: value })}
      />
    </ConfirmModal>
  );
};
export default BerkleyNewSessionModal;
