import { SearchForm, SearchIcon } from '@elseu/sdu-titan';
import { SearchProvider, SearchResultsExtension, useSearchContext } from '@elseu/sdu-titan-search';
import type * as PopperJS from '@popperjs/core';
import applicationConstants from 'constants/ApplicationConstants';
import debounce from 'lodash/debounce';
import React, { useCallback, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { useAccountState } from 'stores/account/AccountState';
import styled from 'styled-components';

import NdfrSearchResults from './NdfrSearchResults';

const { NDFR_SEARCH_URL } = applicationConstants;

type Props = {
  onItemSelect: (item: any) => void;
};

const PopupOverSuggestions = styled.div`
  box-sizing: border-box;
  border-radius: 0 0 ${({ theme }) => theme.borderRadius[1]} ${({ theme }) => theme.borderRadius[1]};
  border: ${({ theme }) => theme.components.autocompleteSuggestions.border};
  border-top-color: ${({ theme }) => theme.components.autocompleteSuggestions.borderTopColor};
  box-shadow: ${({ theme }) => theme.components.autocompleteSuggestions.boxShadow};
  background-color: ${({ theme }) => theme.components.autocompleteSuggestions.backgroundColor};
  padding: ${({ theme }) => theme.components.autocompleteSuggestions.padding};
  padding-bottom: 0;
  z-index: 999;
`;

const NdfrSearchBar: React.FC<Props> = ({ onItemSelect }) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const modifiers = useMemo<Array<PopperJS.Modifier<any, any>>>(
    () => [
      {
        name: 'sameWidth',
        enabled: true,
        phase: 'beforeWrite',
        requires: ['computeStyles'],
        fn({ state }: any) {
          state.styles.popper.width = `${state.rects.reference.width}px`;
        },
        effect({ state }: any) {
          state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
        },
      },
    ],
    [],
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers,
  });

  const searchContext = useSearchContext();

  const handleSuggestions = useCallback(
    (query: string) => {
      if (query.length > 2) {
        setShowSuggestions(true);
        searchContext.search(query);
      } else {
        setShowSuggestions(false);
      }
    },
    [searchContext],
  );

  return (
    <div>
      <div ref={setReferenceElement}>
        <SearchForm
          autoComplete="off"
          isLoading={searchContext.isLoading}
          label="Zoeken in NDFR"
          prefixAdornment={<SearchIcon />}
          onInputChange={debounce(handleSuggestions, 500)}
          onSubmit={handleSuggestions}
        />
      </div>
      {showSuggestions && (
        <PopupOverSuggestions ref={setPopperElement} style={styles.popper} {...attributes.popper}>
          <NdfrSearchResults onItemSelect={onItemSelect} />
        </PopupOverSuggestions>
      )}
    </div>
  );
};

const NdfrSearch: React.FC<Props> = ({ onItemSelect }) => {
  const [accountState] = useAccountState();
  // @ts-ignore
  const { token: accessToken } = accountState;

  return (
    <SearchProvider
      accessToken={accessToken}
      applicationKey="ndfr"
      facets={{}}
      loadOnInitial={false}
      query={{ text: null }}
      scope={{}}
      searchGraphqlUrl={NDFR_SEARCH_URL}
      searchResultsExtensionFragment={SearchResultsExtension}
    >
      <NdfrSearchBar onItemSelect={onItemSelect} />
    </SearchProvider>
  );
};

export default NdfrSearch;
