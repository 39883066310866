import { useQueryClient } from '@tanstack/react-query';

export type InvalidateFileManagerParams = {
  clientId?: string;
  dossierId?: string;
};

export const useInvalidateFileManagerQuery = () => {
  const queryClient = useQueryClient();

  return (dossierId: string) => {
    queryClient.invalidateQueries({
      queryKey: ['attachmentFolders', dossierId],
    });
    queryClient.invalidateQueries({
      queryKey: ['client-attachments-dossiers', dossierId],
    });
    queryClient.invalidateQueries({
      queryKey: ['client-attachments-dossiers-cards', dossierId],
    });
  };
};
