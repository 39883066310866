import './assets/scss/BerkleyNewSessionForm.scss';

import { Input } from '@elseu/sdu-titan';
import { AttachmentFolderSelect } from 'components/attachmentfolder/AttachmentFolderSelect';
import ContextGroupItem from 'components/contextgroups/ContextGroupItem';
import type { AttachmentFolder } from 'entity/Attachment/types';
import React from 'react';

type ContextItemType = {
  name?: string;
  description?: string;
  type: string;
};

type Props = {
  isDisabled?: boolean;
  item?: ContextItemType;
  error: any;
  values: any;
  hasInformation?: boolean;
  attachmentFolders?: AttachmentFolder[];
  onChange: (key: string, value: any) => void;
};

const BerkleySessionForm: React.FC<Props> = ({
  isDisabled,
  item,
  error,
  values,
  attachmentFolders,
  hasInformation,
  onChange,
}) => (
  <div className="c-berkley-new-session-form">
    {item && (
      <ContextGroupItem
        description={item.description || ''}
        iconType={item.type}
        isDisabled={isDisabled}
        title={item.name || ''}
      />
    )}

    <Input
      disabled={isDisabled}
      errorText={error.name}
      hasError={!!error.name}
      label="Naam"
      name="name"
      spaceAfter={4}
      value={values.name}
      onInputChange={(value) => onChange('name', value)}
    />
    {attachmentFolders && (
      <AttachmentFolderSelect
        errorText={error.attachmentFolder || undefined}
        hasError={!!error.attachmentFolder}
        isDisabled={isDisabled}
        label="Map"
        name="attachmentFolder"
        options={attachmentFolders}
        spaceAfter={4}
        value={values?.attachmentFolder}
        onChange={(value) => onChange('attachmentFolder', value)}
      />
    )}
    {hasInformation && (
      <p>
        Voor het (automatisch) invullen van dit model worden gegevens van de klant(en) uit dit
        dossier naar deze tool verstuurd.
      </p>
    )}
  </div>
);

export default BerkleySessionForm;
