import AttachmentFolder from 'models/attachment/AttachmentFolder';
import DataModel, { PropTypes as ModelPropTypes, PropTypes } from 'models/base/DataModel';

/**
 * Attachment
 *
 * Contains for any type of file that can be attached to a client or dossier, these can be
 * cloud links, cloud attachments or regular attachments.
 */
export default class Attachment extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    name: ModelPropTypes.string,
    url: ModelPropTypes.string,
    type: ModelPropTypes.string,
    extension: PropTypes.string,
    createdDateTime: ModelPropTypes.string,
    updatedDateTime: ModelPropTypes.string,
    phase: PropTypes.string,
    attachmentType: PropTypes.string,
    cardId: PropTypes.string,
    dossierId: PropTypes.string,
    attachmentFolder: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    displayDate: ModelPropTypes.string,
  };

  /**
   * Name of the attachment
   *
   * @type {string}
   */
  name;

  /**
   * Url of the attachment
   *
   * @type {string}
   */
  url;

  /**
   * Type of the attachment
   *
   * @type {string}
   */
  type;

  /**
   * @type {string}
   */
  extension;

  /**
   * @type {string}
   */
  createdDateTime;

  /**
   * @type {string}
   */
  updatedDateTime;

  /**
   * Dossier phase this attachment is linked to.
   *
   * @type {string}
   */
  phase;

  /**
   * Attachment type
   *
   * @type {string}
   */
  attachmentType;

  /**
   * @type {string}
   */
  cardId;

  /**
   * @type {string}
   */
  dossierId;

  /**
   * @type {AttachmentFolder|undefined}
   */
  attachmentFolder;

  /**
   * @type {string}
   */
  displayDate;

  /**
   * @returns {string}
   */
  getName() {
    return this.name;
  }

  /**
   * @returns {string}
   */
  getUrl() {
    return this.url;
  }

  /**
   * @returns {string}
   */
  getType() {
    return this.type;
  }

  /**
   * @return {string}
   */
  getExtension() {
    return this.extension;
  }

  /**
   * @returns {string}
   */
  getCreatedDateTime() {
    return this.createdDateTime;
  }

  /**
   * @returns {string}
   */
  getUpdatedDateTime() {
    return this.updatedDateTime;
  }

  /**
   * @return {string}
   */
  getPhase() {
    return this.phase;
  }

  getPhaseText() {
    return this.phase;
  }

  /**
   * @return {string}
   */
  getAttachmentType() {
    return this.attachmentType;
  }

  getCardId() {
    return this.cardId;
  }

  getdossierId() {
    return this.dossierId;
  }

  /**
   * @return {AttachmentFolder}
   */
  getAttachmentFolder() {
    return this.attachmentFolder;
  }

  /**
   * @return {string}
   */
  getDisplayDate() {
    return this.displayDate;
  }

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'attachmentFolder') {
      value = AttachmentFolder.createInstance(value);
    }
    return super.set(fieldName, value);
  }
}
