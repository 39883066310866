import type { SpaceProp } from '@elseu/sdu-titan';
import { Box, Tag, Text } from '@elseu/sdu-titan';
import moment from 'moment';
import React from 'react';

export type TaskDeadlineDateProps = {
  date: string | Date;
  format?: string;
  showOnlyWhen?: 'overdue' | 'future';
  isCompleted?: boolean;
  renderAs?: 'tag' | 'text';
  /** The margin bottom of the component */
  spaceAfter?: SpaceProp;
};

const getStatusByDate = (date: Date) => {
  const today = new Date();
  const tomorrow = new Date();

  today.setHours(0, 0, 0, 0);
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);

  if (date < today) {
    return 'error';
  }

  if (date < tomorrow) {
    return 'warning';
  }

  return 'default';
};

const getTextColorByStatus = (status: string) => {
  switch (status) {
    case 'error':
      return 'danger50';
    case 'warning':
      return 'grey80';
    default:
      return 'grey80';
  }
};

const TaskDeadlineDate: React.FC<TaskDeadlineDateProps> = ({
  date,
  format = 'DD-MM-YYYY',
  showOnlyWhen,
  isCompleted = false,
  renderAs = 'tag',
}) => {
  const convertedDate = date instanceof Date ? date : new Date(date);
  const displayDate = moment(date).format(format);
  const deadlineStatus = getStatusByDate(convertedDate);
  const textColor = getTextColorByStatus(deadlineStatus);

  if (
    (showOnlyWhen === 'overdue' && deadlineStatus !== 'error') ||
    (showOnlyWhen === 'future' && deadlineStatus === 'error')
  ) {
    return <></>;
  }

  if (isCompleted) {
    return (
      <Box px={2} py={0}>
        <Text color="grey40" type="labelSmall">
          {displayDate}
        </Text>
      </Box>
    );
  }

  if (renderAs === 'text') {
    return (
      <Text color={textColor} type="labelSmall">
        {displayDate}
      </Text>
    );
  }

  return <Tag status={deadlineStatus}>{displayDate}</Tag>;
};

export default TaskDeadlineDate;
