import type { Attachment } from 'entity/Attachment/types';
import { normalizeDisplayName } from 'entity/crm/normalizer';
import type { Client, ClientUser } from 'entity/crm/types';
import type { Dossier } from 'entity/dossiers/types';
import type { Signal } from 'entity/signaling/types';

import type {
  AttachmentTableItem,
  ClientSignalingTableItem,
  ClientTableItem,
  ClientUserTableItem,
  ContactNoteTableItem,
  DossierTableItem,
  SignalingSignalTableItem,
  TaskTableItem,
} from './types';

export const normalizeDossierTableItem = (item: Dossier): DossierTableItem => ({
  id: item.id ?? '',
  name: item.name ?? '',
  isArchived: item.isArchived,
  topics: item.topics,
  owners: item.owners ?? [],
  clients: item.clients ?? [],
  inventoryCardId: item.id ?? '',
  updatedAt: item.updatedAt,
  canView: item.permissions.canView,
  canEdit: item.permissions.canEdit,
  canDelete: item.permissions.canDelete,
  canDuplicate: item.permissions.canDuplicate,
});

export const normalizeAttachmentTableItem = (item: Attachment): AttachmentTableItem => {
  let attachmentType = 'Onbekend';
  switch (item.attachmentType) {
    case 'attachment':
      attachmentType = 'Bestand';
      break;
    case 'cloud_attachment':
      attachmentType = 'Cloud bestand';
      break;
    case 'cloud_link':
      attachmentType = 'Cloud link';
      break;
    case 'berkley_session':
      attachmentType = 'Berkeley sessie';
      break;
  }

  return {
    id: item.id ?? '',
    name: item.name ?? '',
    url: item.url ?? '',
    dossierId: item.dossierId ?? '',
    dossierName: item.dossier?.name ?? '',
    phase: item.phase ?? '',
    updatedAt: item.updatedDateTime,
    extension: item.extension,
    attachmentType,
  };
};

export const normalizeClientSignalingTableItem = (item: Signal): ClientSignalingTableItem => ({
  id: item.id ?? '',
  name: item.dossier?.name ?? '',
  notes: item.notes,
  isArchived: item.isArchived === false ? item.dossier?.isArchived ?? false : true,
  assignedUser: item.assignedUser,
  contactPersons: item.contactPersons,
  canView: item.permissions.canView,
  label: item.label,
  dossierId: item.dossier?.id ?? '',
});

/**
 * Normalize contact note item for a table item
 *
 * @param {any} item
 * @returns
 */
export const normalizeContactNoteTableItem = (item: any): ContactNoteTableItem => {
  const card = item.contactNoteCard;
  const clients = item.clients ?? [];
  const dossiers = (card?.relatedCards ?? [])
    .filter((card: any) => card?.type === 'inventory')
    .map((card: any) => ({
      id: card.dossier?.id,
      name: card.dossier?.name,
    }));

  return {
    id: item.id,
    name: item.name,
    client: clients.map((client: any) => ({
      id: client.client.id,
      name: client.client.name,
      displayName: normalizeDisplayName(client.client),
    })),
    dossiers: dossiers || [],
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
    isArchived: item.isArchived,
    label: card.label,
    labelSort: card.label?.name,
    assignedUserFullName: card.assignedUser ? card.assignedUser.fullName : undefined,
    canEdit: item.permissions.canEdit || false,
    canDelete: item.permissions.canDelete || false,
    cardId: card.id,
  };
};

export const normalizeClientUserTableItem = (item: ClientUser): ClientUserTableItem => ({
  id: item.id ?? '',
  firstName: item.firstName,
  displayName: item.displayName,
  email: item.email,
  isPrimary: false,
  phone: item.phone,
  mobilePhone: item.mobilePhone,
  isManual: item.isManual ?? false,
  canView: item.permissions.canView,
  canEdit: item.permissions.canEdit,
  canDelete: item.permissions.canDelete,
});

export const normalizeClientTableItem = (item: Client): ClientTableItem => ({
  id: item.id ?? '',
  showClientId: item.showClientId,
  classification: item.classification,
  name: item.name,
  infix: item.infix,
  surname: item.surname,
  displayName: normalizeDisplayName(item, true),
  primaryClientUser: item.primaryClientUser?.displayName,
  primaryClientUserId: item.primaryClientUser?.id,
  isManual: item.isManual ?? false,
  contactPersons: item.contactPersons ?? [],
  subOfficeName: item.subOffice?.name,
  dossierCount: item.metaInfo?.countDossiers ?? 0,
  signalingCount: item.metaInfo?.countSignalings ?? 0,
  canView: item.permissions.canView,
  canEdit: item.permissions.canEdit,
  canDelete: item.permissions.canDelete,
});

export const normalizeSignalingSignalTableItem = (signal: Signal): SignalingSignalTableItem => ({
  id: signal.id ?? '',
  client: signal.client?.displayName,
  clientNumber: signal.client?.showClientId,
  status: signal.label,
  assignedUser: signal.assignedUser?.fullName,
  contactPersons: signal.contactPersons,
  statusDescription: signal.notes,
  permissions: signal.permissions,
  isArchived: signal.isArchived ?? false,
  signalingName: signal.dossier?.name,
  signalingId: signal.dossier?.id,
  isCheckedInTable: false,
});

export const normalizeTaskTableItem = (task: any): TaskTableItem => ({
  id: task.id,
  name: task.name,
  url: task.url,
  position: task.position,
  createdAt: task.createdAt,
  deadlineDate: task.deadlineDate,
  completedDateTime: task.completedDateTime,
  taskListName: task.taskList.name,
  dossierId: task?.dossier?.id ?? '',
  dossierName: task?.dossier?.name ?? '',
  cardId: task.card.id,
  taskListId: task.taskList.id,
  canView: task?.dossier?.permissions?.canView ?? false,
  canEdit: task?.dossier?.permissions?.canEdit ?? false,
  isCompleted: !!task.completedDateTime,
  cardType: task.card.type,
  assignedUserFullName: task.assignedUser !== null ? task.assignedUser?.fullName : undefined,
  taskListTotalCount: task.taskList.totalCount ?? 0,
  taskListCompletedCount: task.taskList.completedCount ?? 0,
});
