import './assets/styles/CardAttachmentCloudLinkModal.scss';

import FormField from 'components/formfield/FormField';
import ListIcon from 'components/listicon/ListIcon';
import ConfirmModal from 'components/modal/ConfirmModal';
import SelectField from 'components/selectfield/SelectField';
import TextField from 'components/textfield/TextField';
import type { CloudLink } from 'entity/Attachment/types';
import React, { useEffect, useRef, useState } from 'react';

import dropboxImage from './assets/images/dropbox.svg';
import googledriveImage from './assets/images/googledrive.svg';
import onedriveImage from './assets/images/onedrive.svg';

type Props = {
  cloudLinkTypes?: any[];
  cloudLink?: CloudLink;
  modalTitle?: string;
  confirmText?: string;
  error: any;
  // eslint-disable-next-line react/boolean-prop-naming
  submiting?: boolean;
  onCancel: () => void;
  onSubmit: (value: any) => void;
};

/**
 * CardAttachmentCloudLinkModal
 *
 * @param {array} cloudLinkTypes
 * @param {object} cloudLink
 * @param {string} modalTitle
 * @param {string} confirmText
 * @param {object} submiting
 * @param {boolean} error
 * @param {function} onCancel
 * @param {function} onSubmit
 * @return {*}
 * @constructor
 */
const CardAttachmentCloudLinkModal: React.FC<Props> = ({
  cloudLinkTypes,
  cloudLink,
  modalTitle = 'Cloud bestand toevoegen',
  confirmText = 'Bestand toevoegen',
  error,
  submiting = false,
  onCancel,
  onSubmit,
}) => {
  const [formUrl, setFormUrl] = useState<string | undefined>('');
  const [formName, setFormName] = useState<string | undefined>('');
  const [formType, setFormType] = useState<any | undefined>(undefined);

  const formRef = useRef(null);

  /**
   * cloudLink propery watch
   */
  useEffect(() => {
    if (cloudLink) {
      setFormUrl(cloudLink.url);
      setFormName(cloudLink.name);

      // get correct type value from text
      if (cloudLinkTypes) {
        setFormType(
          cloudLinkTypes
            .filter((item) => item.id === cloudLink.type)
            .map((item) => ({
              value: item.id,
              label: item.name,
            }))
            .shift(),
        );
      }
    }
  }, [cloudLink, cloudLinkTypes]);

  return (
    <ConfirmModal
      isCloseShown
      shouldUsePortal
      cancelTitle="Annuleren"
      className="c-card-attachment-cloud-document-modal"
      confirmButtonAlignment="left"
      confirmTitle={confirmText}
      isDisabled={submiting}
      title={
        <span className="header__title">
          {modalTitle} <img alt="" src={onedriveImage} />
          <img alt="" src={dropboxImage} />
          <img alt="" src={googledriveImage} />
        </span>
      }
      onCancel={onCancel}
      onConfirm={() => {
        onSubmit({
          ...cloudLink,
          url: formUrl,
          name: formName,
          type: formType?.value || null,
        });
      }}
    >
      <form ref={formRef}>
        <FormField error={error?.url || ''} label="Link">
          <TextField
            required
            hasError={!!error?.url}
            isDisabled={submiting}
            name="url"
            placeholder="Vul hier een unieke OneDrive, Google Drive, of Dropbox link toe"
            type="url"
            value={formUrl}
            onChange={setFormUrl}
          />
        </FormField>
        <FormField error={error?.name || ''} label="Naam">
          <TextField
            required
            hasError={!!error?.name}
            isDisabled={submiting}
            name="name"
            placeholder="Vul hier een herkenbare naam voor het bestand in"
            value={formName}
            onChange={setFormName}
          />
        </FormField>
        <FormField error={error?.type || ''} label="Type bestand">
          <SelectField
            error={!!error?.type}
            isDisabled={submiting}
            isSearchable={false}
            name="type"
            optionRender={(value: any) => (
              <div className="option-cloud-document-type">
                <ListIcon className="option-cloud-document-type__icon" type={value.value} />
                <div className="option-cloud-document-type__value">{value.label}</div>
              </div>
            )}
            options={(cloudLinkTypes ?? []).map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            })}
            value={formType}
            onChange={setFormType}
          />
        </FormField>
      </form>
    </ConfirmModal>
  );
};

export default CardAttachmentCloudLinkModal;
