import './assets/styles/List.scss';

import classNames from 'classnames';
import React from 'react';

type Props = React.PropsWithChildren<{
  className?: string;
  isDivided?: boolean;
}>;

/**
 * List
 *
 * @param {string} className
 * @param {boolean} isDivided
 * @param {Node} children
 * @returns {Node}
 * @constructor
 */
const List: React.FC<Props> = ({ className, isDivided, children }) => (
  <ul
    className={classNames('c-list', className, {
      'c-list--divided': isDivided,
    })}
  >
    {children}
  </ul>
);

List.defaultProps = {
  isDivided: false,
};

export default List;
