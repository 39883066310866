import type { SpaceProp } from '@elseu/sdu-titan';
import { Box, Label, spacing } from '@elseu/sdu-titan';
import React from 'react';
import styled from 'styled-components';

type FormGroupHorizontalProps = React.PropsWithChildren<{
  label: string;
  gridTemplateColumns?: string;
  spaceAfter?: SpaceProp;
}>;

const Grid = styled.div<Pick<FormGroupHorizontalProps, 'gridTemplateColumns'>>`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  gap: ${spacing(3)};
  align-items: center;
`;
export const FormGroupHorizontal: React.FC<FormGroupHorizontalProps> = ({
  label,
  children,
  gridTemplateColumns = '1fr 3fr',
  spaceAfter = 4,
}) => {
  return (
    <Box spaceAfter={spaceAfter}>
      <Grid gridTemplateColumns={gridTemplateColumns}>
        <Label>{label}</Label>
        {children}
      </Grid>
    </Box>
  );
};
