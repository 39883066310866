import './assets/styles/CloudBrowser.scss';

import Actions from 'actions/Actions';
import classNames from 'classnames';
import FileBrowser, { FileBrowserSelectionType } from 'components/filebrowser/FileBrowser';
import FormDrawer from 'components/formdrawer/FormDrawer';
import React, { useEffect, useState } from 'react';

export { FileBrowserSelectionType } from 'components/filebrowser/FileBrowser';

export enum CloudBrowserUserTypes {
  USER = 'user',
  ADMIN = 'admin',
}

enum CloudBrowserDefaultTitle {
  FILE = 'Selecteer een bestand',
  FOLDER = 'Selecteer een folder',
}

type Props = {
  className?: string;
  title?: string;
  selectionType?: FileBrowserSelectionType;
  userType?: CloudBrowserUserTypes;
  onSelect: (itemId: string[], item: any[]) => void;
  onCancel: () => void;
};

/**
 * CloudBrowser
 *
 * @param {string} className
 * @param {string} title
 * @param {string} selectionType
 * @param {string} userType
 * @param {function} onSelect
 * @param {function} onCancel
 * @returns {*}
 * @constructor
 */
const CloudBrowser: React.FC<Props> = ({
  className,
  title,
  selectionType = FileBrowserSelectionType.FILE,
  userType = CloudBrowserUserTypes.USER,
  onSelect = () => null,
  onCancel = () => null,
}) => {
  const [selectedItemId, setSelectedItemId] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(false);

  /**
   * Load items callback
   *
   * @param {(items: any[]) => void} resolve
   * @param {() => void} reject
   * @param {string} [itemId]
   */
  const onLoadItems = (resolve: (items: any[]) => void, reject: () => void, itemId?: string) => {
    Actions.getAPIService()
      .cloudBrowser()
      .getFileList(userType.toString() || CloudBrowserUserTypes.USER, itemId)
      .then(
        ({ status, data }) => {
          if (status === 'success') {
            let items = data.items.map((item: any) => ({
              ...item,
              type: item.iconName === 'folder' ? 'folder' : 'file',
            }));
            // filter out files from items
            if (selectionType === FileBrowserSelectionType.FOLDER) {
              items = items.filter((item: any) => item.type !== ' folder');
            }
            resolve(items);
          } else {
            reject();
          }
        },
        () => {
          reject();
        },
      );
  };

  useEffect(() => {
    let disableConfirm = true;
    if (selectedItems.length > 0) {
      disableConfirm = false;
    }
    setIsConfirmDisabled(disableConfirm);
  }, [selectedItems.length]);

  return (
    <FormDrawer
      isCloseShown
      className={classNames('c-cloud-browser', className)}
      confirmTitle="Selecteer"
      isConfirmDisabled={isConfirmDisabled}
      title={
        title ||
        (selectionType === FileBrowserSelectionType.FOLDER
          ? CloudBrowserDefaultTitle.FOLDER
          : CloudBrowserDefaultTitle.FILE)
      }
      width={720}
      zIndex={9999999}
      onCancel={() => onCancel()}
      onConfirm={() => onSelect(selectedItemId, selectedItems)}
    >
      <FileBrowser
        className="c-cloud-browser__file-browser"
        selectionType={selectionType}
        onLoadItems={onLoadItems}
        onSelectItem={(itemId, item) => {
          setSelectedItemId(itemId);
          setSelectedItems((items) => [...items, item]);
        }}
      />
    </FormDrawer>
  );
};

export default CloudBrowser;
