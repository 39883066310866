import './assets/styles/AttachmentField.scss';

import { StatusMessage } from '@elseu/sdu-titan';
import classNames from 'classnames';
import Date from 'components/date/Date';
import type { FileUploaderItem, FileUploaderProps } from 'components/fileuploader/FileUploader';
import FileUploader from 'components/fileuploader/FileUploader';
import ListIcon from 'components/listicon/ListIcon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { Accept } from 'react-dropzone';

export type AttachmentFieldType = {
  id?: string;
  uploadId?: string;
  name: string;
  iconName?: string;
  type?: string;
  file?: File;
  size?: number;
  createdDateTime?: string;
  reason?: string;
  hasError?: boolean;
};

type AttachmentFieldSimpleProps = {
  className?: string;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  hasError?: boolean;
  maxFileSize?: number;
  maxFiles?: number;
  isMultiple?: boolean;
  accept?: Accept;
  files: AttachmentFieldType[];
  isFilesListHiddenWhenUploading?: boolean;
  isUploading?: boolean;
  uploadingIndex?: number;
  uploadingMax?: number;
  actionMenuItems?: FileUploaderProps['actionMenuItems'];
  onAddFile?: (file: AttachmentFieldType) => void;
  onRemoveFile?: (file: AttachmentFieldType) => void;
  onChange?: (files: AttachmentFieldType[]) => void;
  onMaxFilesReached?: (isReached: boolean) => void;
};

type AttachmentFieldSimpleFileListProps = {
  isDisabled?: boolean;
  isReadOnly?: boolean;
  files: AttachmentFieldType[];
  onRemove: (index: number) => void;
};

const AttachmentFieldSimpleFileList: React.FC<AttachmentFieldSimpleFileListProps> = ({
  isDisabled,
  isReadOnly,
  files,
  onRemove,
}) => {
  const [attachmentHoverKey, setAttachmentHoverKey] = useState<number | false>();

  return (
    <>
      {files.map((fileItem, key) => {
        return (
          <li
            key={`attachment-${fileItem.id || fileItem.uploadId}`}
            className={classNames('attachments__item', {
              attachments__item__error: !!fileItem.reason,
              attachments__item__delete: attachmentHoverKey === key,
            })}
          >
            <ListIcon type={fileItem.iconName || fileItem.type} />
            <span className="attachments__item__name">
              {fileItem.name}
              {!isReadOnly && (
                <div
                  aria-hidden="true"
                  className="attachments__item__name__remove"
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    if (!isDisabled) {
                      onRemove(key);
                    }
                  }}
                  onMouseEnter={() => {
                    if (!isDisabled) {
                      setAttachmentHoverKey(key);
                    }
                  }}
                  onMouseLeave={() => {
                    if (!isDisabled) {
                      setAttachmentHoverKey(false);
                    }
                  }}
                />
              )}
            </span>
            <span className="attachments__item__date">
              <Date date={fileItem.createdDateTime} />
            </span>
            {fileItem.reason && (
              <span className="attachments__item__reason">{fileItem.reason}</span>
            )}
          </li>
        );
      })}
    </>
  );
};

const AttachmentFieldSimple: React.FC<AttachmentFieldSimpleProps> = ({
  className,
  isDisabled = false,
  isReadOnly = false,
  hasError,
  accept,
  files,
  isMultiple = false,
  maxFileSize = 60000000,
  maxFiles,
  isUploading = false,
  isFilesListHiddenWhenUploading = false,
  uploadingIndex = 1,
  uploadingMax = 1,
  actionMenuItems,
  onAddFile,
  onRemoveFile,
  onChange,
}) => {
  const [maxFilesReached, setMaxFilesReached] = useState<boolean>(false);
  const onFilesChange = useCallback(
    (uploadFiles: FileUploaderItem[]) => {
      if (isReadOnly) {
        return;
      }

      const newFiles: AttachmentFieldType[] = isMultiple ? [...files] : [];

      uploadFiles.forEach((uploadFile) => {
        const attachment: AttachmentFieldType = {
          uploadId: uploadFile.id,
          name: uploadFile.file.name,
          file: uploadFile.file,
          hasError: uploadFile.hasError,
          reason: uploadFile.errorMessage,
        };
        onAddFile?.(attachment);
        newFiles.push(attachment);
      });

      if (maxFiles) {
        onChange?.(newFiles.splice(0, maxFiles));

        return;
      }
      onChange?.(newFiles);
    },
    [isReadOnly, isMultiple, files, maxFiles, onChange, onAddFile],
  );

  const onFileRemoveClick = (index: number) => {
    if (isDisabled) {
      return;
    }
    const newFiles = [...files];
    const fileRemoved = newFiles.splice(index, 1);

    if (fileRemoved.length > 0) {
      onRemoveFile?.(fileRemoved[0]);
    }

    onChange?.(newFiles);
  };

  const hasFileList = !(isFilesListHiddenWhenUploading && isUploading);
  // eslint-disable-next-line no-nested-ternary
  const showUploader = isMultiple ? true : isUploading ? true : files.length === 0;

  const isUploaderDisabled = useMemo(() => {
    if (maxFiles && !isDisabled) {
      return files.length >= maxFiles;
    }

    return isDisabled;
  }, [isDisabled, maxFiles, files]);

  useEffect(() => {
    if (maxFiles) {
      setMaxFilesReached(files.length >= maxFiles);
    }
  }, [files, maxFiles]);

  return (
    <div className={classNames('c-attachment-field', 'attachments', className)}>
      <ul
        className={classNames('attachments', {
          attachments__disabled: isDisabled,
        })}
      >
        <StatusMessage isRounded isShown={maxFilesReached} spaceAfter={4} type="danger">
          Per keer kunnen maximaal {maxFiles} bestanden worden toegevoegd. De overige zijn niet
          meegenomen.
        </StatusMessage>
        {hasFileList && (
          <AttachmentFieldSimpleFileList
            files={files}
            isDisabled={isDisabled}
            isReadOnly={isReadOnly}
            onRemove={onFileRemoveClick}
          />
        )}
        <li className="attachments__item__uploader">
          {!isReadOnly && showUploader && (
            <FileUploader
              accept={accept}
              actionMenuItems={actionMenuItems}
              hasError={hasError}
              isDisabled={isUploaderDisabled}
              isMultiple={isMultiple}
              isUploading={isUploading}
              maxFileSize={maxFileSize}
              uploadingIndex={uploadingIndex}
              uploadingMax={uploadingMax}
              uploadMaxDescription={` (max. ${maxFiles} bestanden)`}
              onFilesChange={onFilesChange}
            />
          )}
        </li>
      </ul>
    </div>
  );
};

export default AttachmentFieldSimple;
