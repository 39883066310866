import {
  normalizeAttachment,
  normalizeCloudAttachment,
  normalizeCloudLink,
} from 'entity/Attachment/normalizer';
import type { Attachment, CloudAttachment, CloudLink } from 'entity/Attachment/types';
import { normalizeClient } from 'entity/crm/normalizer';
import {
  createEmptyLabel,
  normalizeCardMetaInfo,
  normalizeDossierTeam,
  normalizeDossierUser,
  normalizeLabel,
  normalizeRelatedCard,
  normalizeTaskList,
} from 'entity/dossiers/normalizer';
import type { DossierTeam, DossierUser, RelatedCard, TaskList } from 'entity/dossiers/types';
import { normalizeUser, normalizeUserWithRole } from 'entity/system/normalizer';
import type { UserWithRole } from 'entity/system/types';
import { convertToDate, normalizeMap } from 'helpers/normalizehelper';

import type {
  Signal,
  Signaling,
  SignalingCustomValue,
  SignalingPredicate,
  SignalingPredicateGroup,
  SignalingResult,
  SignalingStatistics,
  SignalTableItem,
} from './types';

// region signaling
export const normalizeSignaling = (result: any): Signaling => ({
  id: result.id,
  name: result.name,
  description: result.description,
  createdAt: convertToDate(result.createdAt) ?? new Date(),
  updatedAt: convertToDate(result.updatedAt) ?? new Date(),
  isArchived: result.isArchived,
  archivedAt: convertToDate(result.archivedAt),
  url: result.url,
  version: result.version,
  hasPredicates: result.hasPredicates ?? false,
  dossierUsers: normalizeMap<DossierUser>(result?.dossierUsers, normalizeDossierUser),
  dossierTeams: normalizeMap<DossierTeam>(result?.dossierTeams, normalizeDossierTeam),
  users: normalizeMap<UserWithRole>(result?.users, normalizeUserWithRole),
  signalingType: result?.signalingType,
  hasTriggers: result?.signalingType === 'sdu' || (result.hasPredicates ?? false),
  signalCount: result?.signalCount ?? 0,
  signalOpenCount: result?.signalOpenCount ?? 0,
  permissions: {
    canView: result.permissions?.canView ?? false,
    canEdit: result.permissions?.canEdit ?? false,
    canArchive: result.permissions?.canArchive ?? false,
    canDelete: result.permissions?.canDelete ?? false,
    canUpdateClients: result.permissions?.canUpdateClients ?? false,
    canUpdateTriggers: result.permissions?.canUpdateTriggers ?? false,
    canDuplicate: result.permissions?.canDuplicate ?? false,
    canEditPermissions: result.permissions?.canEditPermissions ?? false,
  },
  isNewForUser: result.isNewForUser ?? false,
});

// endregion

// region signaling predicate group
export const normalizeSignalingPredicateGroup = (result: any): SignalingPredicateGroup => ({
  id: result.id,
  priority: result.priority,
  type: result.type,
  signalingPredicates: normalizeMap<SignalingPredicate>(
    result.signalingPredicates,
    normalizeSignalingPredicate,
  ),
});

export const createEmptySignalingPredicateGroup = (): SignalingPredicateGroup => ({
  id: undefined,
  priority: 1,
  type: 'or',
  signalingPredicates: [createEmptySignalingPredicate()],
});
// endregion

// region signaling predicate

export const normalizeSignalingPredicate = (result: any): SignalingPredicate => ({
  id: result.id,
  indicator: result.indicator,
  operator: result.operator,
  value: result.value,
});

export const createEmptySignalingPredicate = (): SignalingPredicate => ({
  id: undefined,
  indicator: '',
  operator: 'eq',
  value: '',
});
// endregion

export const normalizeSignal = (result: any): Signal => ({
  id: result?.id,
  name: result?.name,
  notes: result?.notes,
  isArchived: result?.isArchived ?? false,
  archivedAt: convertToDate(result.archivedAt),
  assignedUser: result.assignedUser ? normalizeUser(result.assignedUser) : undefined,
  contactPersons: result.contactPersons,
  label: result.label ? normalizeLabel(result.label) : createEmptyLabel(),
  relatedCards: normalizeMap<RelatedCard>(result?.relatedCards, normalizeRelatedCard),
  taskLists: normalizeMap<TaskList>(result?.taskLists, normalizeTaskList),
  signalingResults: normalizeMap<SignalingResult>(
    result?.signalingResults,
    normalizeSignalingResult,
  ),
  signalingCustomValues: normalizeMap<SignalingCustomValue>(
    result?.signalingCustomValues,
    normalizeSignalingCustomValue,
  ),
  attachments: normalizeMap<Attachment>(result.attachments ?? [], normalizeAttachment),
  cloudAttachments: normalizeMap<CloudAttachment>(
    result.cloudAttachments ?? [],
    normalizeCloudAttachment,
  ),
  cloudLinks: normalizeMap<CloudLink>(result.cloudLinks ?? [], normalizeCloudLink),
  permissions: {
    canView: result?.permissions?.canView || false,
    canEdit: result?.permissions?.canView || false,
    canArchive: result?.permissions?.canArchive || false,
  },
  dossier: result.dossier ? normalizeSignaling(result.dossier) : undefined,
  client: result.client ? normalizeClient(result.client) : undefined,
  metaInfo: result.metaInfo ? normalizeCardMetaInfo(result.metaInfo) : undefined,
});

/**
 * Normalize signal item for a table item
 */
export const normalizeSignalTableItem = (item: Signaling): SignalTableItem => {
  return {
    id: item.id ?? '',
    name: item.name,
    url: item.url,
    count: item.signalOpenCount,
    signalingType: item.signalingType,
    description: item.description,
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
    isArchived: item.isArchived,
    canArchive: item.permissions.canArchive || false,
    canView: item.permissions.canView || false,
    canEdit: item.permissions.canEdit || false,
    canDelete: item.permissions.canDelete || false,
    canUpdateClients: item.permissions.canUpdateClients || false,
    canUpdateTriggers: item.permissions.canUpdateTriggers || false,
    canDuplicate: item.permissions.canDuplicate || false,
    isNew: item.isNewForUser,
  };
};

export const normalizeSignalingResult = (item: any): SignalingResult => {
  return {
    id: item.id,
    year: item.year,
    name: item.name,
    result: item.result,
    predicates: (item.predicates ?? []).map((predicateItem: any) => ({
      operator: predicateItem.operator,
      value: predicateItem.value,
    })),
  };
};

export const normalizeSignalingCustomValue = (item: any): SignalingCustomValue => {
  return {
    id: item.id ?? '',
    name: item.name ?? '',
    value: item.value,
    priority: item.priority,
  };
};

export const normalizeSignalingStatistics = (item: any): SignalingStatistics => ({
  countSignalCount: item.countSignalCount ?? 0,
  countSignalingNew: item.countSignalingNew ?? 0,
  countSignalingSdu: item.countSignalingSdu ?? 0,
  countSignalingUser: item.countSignalingUser ?? 0,
});
