import { useMutation } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import type { TaskPayload } from 'services/api/TaskApi';

export const useTaskCreate = () => {
  return useMutation({
    mutationFn: ({ task }: { task: TaskPayload }) =>
      Actions.getAPIService().tasks().createTask(task),
  });
};
