import { InputVariant } from '@elseu/sdu-titan';
import type { InputFieldProps } from 'components/formfields/InputField';
import { InputField } from 'components/formfields/InputField';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { FormGroupHorizontal } from '../../../formgroup/FormGroupHorizontal';

export const TicketNameFormField: React.FC<Partial<InputFieldProps>> = (props) => {
  const { setFocus } = useFormContext();
  const { control } = useFormContext();

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  return (
    <FormGroupHorizontal label="Naam*" spaceAfter={6}>
      <Controller
        control={control}
        name="name"
        render={({ field: { value, onChange } }) => (
          <InputField
            isLabelHidden
            required
            label="Naam"
            name="name"
            placeholder="Naamloos"
            rules={{ required: 'Naam is verplicht' }}
            spaceAfter={0}
            value={value}
            variant={InputVariant.CLEAR}
            onChange={(value) => onChange(value)}
            {...props}
          />
        )}
      />
    </FormGroupHorizontal>
  );
};
