import type { SidebarItemProps } from '@elseu/sdu-titan';
import {
  BellOutlineIcon,
  BoxAltIcon,
  ChatIcon,
  CheckboxIcon,
  ExitIcon,
  GridIcon,
  HelpIcon,
  HomeIcon,
  MagazineIcon,
  NewsIcon,
  NtfrIcon,
  People2Icon,
  PeopleIcon,
  RadioIcon,
  SettingsIcon,
  UserIcon,
} from '@elseu/sdu-titan';
import ApplicationConstants from 'constants/ApplicationConstants';

const toolsUrl = ApplicationConstants.APPLICATION_TOOL_URL;
const ndfrUrl = ApplicationConstants.APPLICATION_NDFR_DOMAIN;

/**
 * Main menu items
 */
export const MainMenu: SidebarItemProps[] = [
  {
    id: 'menu_dashboard',
    title: 'Dashboard',
    to: '/',
    icon: HomeIcon,
    // exact: true,
  },
  {
    id: 'menu_workload',
    title: 'Werkoverzicht (beta)',
    to: '/tickets',
    icon: GridIcon,
  },
  {
    id: 'menu_signaling',
    title: 'Adviessignaleringen',
    to: '/signaling',
    icon: RadioIcon,
  },
  {
    id: 'menu_task',
    title: 'Taken',
    to: '/tasks',
    icon: CheckboxIcon,
  },
  {
    id: 'menu_dossier',
    title: 'Dossiers',
    to: '/dossiers',
    icon: BoxAltIcon,
  },
  {
    id: 'menu_crm_clients',
    title: 'Klanten',
    to: '/crm/clients',
    icon: People2Icon,
  },
  {
    id: 'menu_crm_clientusers',
    title: 'Contactpersonen',
    to: '/crm/client-users',
    icon: PeopleIcon,
  },
  {
    id: 'menu_contact_note',
    title: 'Notities',
    to: '/contact_note',
    icon: ChatIcon,
  },
  {
    id: 'menu_tools',
    to: toolsUrl,
    title: 'Tools',
    icon: MagazineIcon,
  },
  {
    id: 'menu_ndfr',
    to: ndfrUrl,
    title: 'NDFR',
    icon: NtfrIcon,
  },
];

/**
 * Profile menu items
 */
export const ProfileMenu: SidebarItemProps[] = [
  {
    id: 'profileMenu_profile',
    title: 'Profiel',
    icon: UserIcon,
    to: '/settings/profile',
  },
  {
    id: 'profileMenu_notifications',
    title: 'Mijn notificaties',
    icon: BellOutlineIcon,
    to: '/settings/notifications',
  },
  {
    id: 'settingsMenu_own_templates',
    title: 'Eigen templates',
    icon: SettingsIcon,
    to: '/settings/own-templates',
  },
  {
    id: 'settingsMenu_groups',
    title: 'Groepen',
    icon: PeopleIcon,
    to: '/settings/groups',
  },
  {
    id: 'profileMenu_support',
    title: 'Support & voorwaarden',
    icon: HelpIcon,
    to: 'https://www.sdu.nl/fiscaal/producten-diensten/taxvice/contact-beleid-voorwaarden',
  },
  {
    id: 'profileMenu_newsletter',
    to: 'https://www.sdu.nl/service/nieuwsbrieven-gratis/inschrijving-taxvice',
    title: 'Nieuwsbrief',
    icon: NewsIcon,
  },
  {
    id: 'profileMenu_logout',
    title: 'Logout',
    icon: ExitIcon,
    to: '/logout',
  },
];

export const SettingsMenu: SidebarItemProps[] = [
  {
    id: 'settingsMenu',
    title: 'Plugins',
    icon: SettingsIcon,
    to: '/settings/plugins',
  },
  {
    id: 'adminMenu',
    title: 'Beheer',
    icon: SettingsIcon,
    to: '/settings',
  },
];
