import { ActionMenu, MenuItem, Text } from '@elseu/sdu-titan';
import ConfirmDialog from 'components/confirmdialog/ConfirmDialog';
import type { Ticket } from 'entity/dossiers/types';
import { useRemoveTicket } from 'hooks/mutate/useRemoveTicket';
import React, { useState } from 'react';

type TicketActionsColumnProps = {
  ticket: Ticket;
  hasPortal?: boolean;
};

export const TicketActions = ({
  ticket,
  hasPortal,
}: TicketActionsColumnProps): React.ReactElement => {
  const { mutate: remove } = useRemoveTicket();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={(event) => event.stopPropagation()}>
      <ConfirmDialog
        className="c-state-item-component__confirm"
        confirmAppearance="danger"
        confirmTitle="Ja, verwijder"
        isShown={showConfirmDelete}
        title="Verwijderen"
        zIndex={2050}
        onCancel={() => setShowConfirmDelete(false)}
        onConfirm={() => {
          remove(ticket.id);
          setShowConfirmDelete(false);
        }}
      >
        <Text isBlock spaceAfter={4} type="paragraph">
          Weet u zeker dat u <strong>{ticket.name}</strong> wilt verwijderen?
        </Text>
      </ConfirmDialog>
      <ActionMenu hasPortal={hasPortal} label="Actions" popoverPlacement="bottom-start">
        <MenuItem
          item={{
            label: 'Verwijderen',
            value: 'Verwijderen',
          }}
          onClick={() => setShowConfirmDelete(true)}
        />
      </ActionMenu>
    </div>
  );
};
