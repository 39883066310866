/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import './assets/styles/CardAttachmentOneDrive.scss';

import Actions from 'actions/Actions';
import AttachmentList from 'components/attachmentlist/AttachmentList';
import { ButtonAppearanceTypes } from 'components/button/Button';
import ConfirmModal from 'components/modal/ConfirmModal';
import InputModal from 'components/modal/InputModal';
import OneDriveFileDetails from 'components/onedrivefiledetails/OneDriveFileDetails';
import OneDriveUploader from 'components/onedriveuploader/OneDriveUploader';
import type { CloudAttachment } from 'entity/Attachment/types';
import useFetchState from 'hooks/useFetchState';
import React, { useState } from 'react';

type Props = {
  isDisabled?: boolean;
  isReadOnly?: boolean;
  dossierId: string;
  cardId: string;
  cloudAttachments: CloudAttachment[];
  onUploadProgress: (uploading: any) => void;
  onItemDelete: (id: string, deleteOnCloud: boolean) => void;
  onItemAdded: () => void;
  onItemRenamed: () => void;
  onItemClick: (item: any) => void;
  onNewCloudLink: () => void;
};

/**
 * Card attachment One Drive
 *
 * @param {boolean} isDisabled
 * @param {boolean} isReadOnly
 * @param {string} dossierId
 * @param {string} cardId
 * @param {function} onUploadProgress
 * @param {array} cloudAttachments
 * @param {function} onItemDelete
 * @param {function} onItemAdded
 * @param {function} onItemRenamed
 * @param {function} onNewCloudLink
 * @returns {*}
 * @constructor
 */
const CardAttachmentOneDrive: React.FC<Props> = ({
  isDisabled,
  isReadOnly,
  dossierId,
  cardId,
  onUploadProgress,
  cloudAttachments,
  onItemDelete,
  onItemAdded,
  onItemRenamed,
  onNewCloudLink,
  onItemClick,
}) => {
  // details dialog
  const [detailsDialog, setDetailsDialog] = useState(false);
  const [detailsItem, setDetailsItem] = useState({});

  // rename dialog states
  const [cloudAttachmentRenameDialog, setCloudAttachmentRenameDialog] = useState(false);
  const [cloudAttachmentRenameItem, setCloudAttachmentRenameItem] = useState<
    CloudAttachment | undefined
  >(undefined);
  const [cloudAttachmentRenameName, setCloudAttachmentRenameName] = useState<string | undefined>(
    undefined,
  );
  const [cloudAttachmentRenameExtension, setCloudAttachmentRenameExtension] = useState(undefined);
  const [
    {
      isBusy: cloudAttachmentModifyIsBusy,
      error: cloudAttachmentModifyError,
      hasError: cloudAttachmentModifyHasError,
    },
    { doRequest: cloudAttachmentModifyRequest, reset: cloudAttachmentModifyReset },
  ] = useFetchState();

  // delete dialog states
  const [deleteItemDialog, setDeleteItemDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteItem, setDeleteItem] = useState(undefined);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState(undefined);

  const [isUploading, setIsUploading] = useState(false);
  const [uploadIndex, setUploadIndex] = useState(0);
  const [uploadCount, setUploadCount] = useState(0);
  const [errorMessages, setErrorMessages] = useState<any | undefined>(undefined);

  // #region upload functions
  /**
   * One drive file selected
   *
   * @param {string[]} itemId
   */
  const oneDriveFile = (itemId: string[]) => {
    const uploadPromises = [...itemId];
    setUploadingState(true);
    setUploadCount(itemId.length);
    setErrorMessages(undefined);

    const result = uploadPromises.reduce((accumulatorPromise, fileId, fileIdIndex) => {
      return accumulatorPromise
        .then(() => {
          setUploadIndex(fileIdIndex + 1);
          if (fileId) {
            return Actions.getAPIService()
              .oneDrive()
              .addCloudAttachmentWithOneDriveId(dossierId, cardId, fileId);
          } else {
            return Promise.resolve();
          }
        })
        .catch((error: any) => {
          doErrorHandling(error);
        });
    }, Promise.resolve());

    result.then(() => {
      setUploadingState(false);
      sendOnItemAdded();
    });
  };

  /**
   * Upload file as a cloud attachment
   *
   * @param {array} uploadFiles
   */
  const onUploadFile = (uploadFiles: any[]) => {
    if (uploadFiles && uploadFiles.length > 0) {
      setUploadingState(true);
      setUploadCount(uploadFiles.length);
      setErrorMessages(undefined);

      const uploadPromises = [...uploadFiles];

      // run every upload in serial by reducing the array.
      const result = uploadPromises.reduce((accumulatorPromise, uploadFile, uploadFileIndex) => {
        return accumulatorPromise
          .then(() => {
            setUploadIndex(uploadFileIndex + 1);
            return Actions.getAPIService()
              .oneDrive()
              .addCloudAttachmentWithFile(dossierId, cardId, uploadFile.file);
          })
          .catch((error: any) => {
            doErrorHandling(error, uploadFile);
          });
      }, Promise.resolve());

      // set uploading state to false en send the event sendOnItemAdded
      result.then(() => {
        setUploadingState(false);
        sendOnItemAdded();
      });
    }
  };

  /**
   * Create file event handling.
   *
   * @param {string} type
   * @param {string} filename
   */
  const createFile = (type: string, filename: string) => {
    setUploadingState(true);
    setErrorMessages(undefined);
    Actions.getAPIService()
      .oneDrive()
      .addCloudAttachmentWithType(dossierId, cardId, type, { name: filename })
      .then(
        () => {
          setUploadingState(false);
          sendOnItemAdded();
        },
        (error) => doErrorHandling(error),
      )
      .finally(() => setUploadingState(false));
  };

  /**
   * Show rename file dialog
   *
   * @param {CloudAttachment} cloudAttachment
   */
  const cloudAttachmentRename = (cloudAttachment: any) => {
    const attachmentName = cloudAttachment.name;
    const extension = attachmentName ? attachmentName.split('.').pop() : undefined;
    const newFilename = attachmentName ? attachmentName.split('.') : [];
    newFilename.pop(); // remove extension
    const filename = newFilename.join('.');

    setCloudAttachmentRenameItem(cloudAttachment);
    setCloudAttachmentRenameName(filename);
    setCloudAttachmentRenameExtension(extension);
    setCloudAttachmentRenameDialog(true);
  };

  /**
   * Do renaming of file
   *
   * @param {CloudAttachment} cloudAttachment
   * @param {string} newName
   */
  const doCloudAttachmentRename = (
    cloudAttachment: CloudAttachment,
    newName: string | undefined,
  ) => {
    setCloudAttachmentRenameName(newName);
    const newFilename = `${newName}.${cloudAttachmentRenameExtension}`;

    cloudAttachmentModifyRequest(
      Actions.getAPIService()
        .oneDrive()
        .updateCloudAttachment(dossierId, cloudAttachment.cardId ?? '', cloudAttachment.id ?? '', {
          name: newFilename,
        }),
    ).then(
      () => {
        onItemRenamed();
        setCloudAttachmentRenameDialog(false);
        setCloudAttachmentRenameItem(undefined);
        setCloudAttachmentRenameName('');
        setCloudAttachmentRenameExtension(undefined);
      },
      () => null,
    );
  };

  /**
   * Error handling of API calls.
   *
   * @param {object|undefined} error
   * @param {object} uploadFile
   */
  const doErrorHandling = (error: any, uploadFile: any = undefined) => {
    const errorPrefix = uploadFile.name ? `${uploadFile.name}: ` : '';

    let errorMessage = `${errorPrefix}Er is een onbekend fout opgetreden.`;
    if (error && error.status === 'error') {
      if (error.data?.file) {
        errorMessage = errorPrefix + error.data.file;
      } else {
        errorMessage = errorPrefix + error.message;
      }
    }

    setErrorMessages((prevErrorMessages: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (prevErrorMessages) {
        return [...prevErrorMessages, errorMessage];
      } else {
        return [errorMessage];
      }
    });
  };
  // #endregion

  // #region send events
  /**
   * Send onItemAdded if it set
   */
  const sendOnItemAdded = () => {
    setDeleteItemDialog(false);
    if (onItemAdded) {
      onItemAdded();
    }
  };

  /**
   * Send onItemDelete if it set
   */
  const sendOnItemDelete = (item: CloudAttachment, deleteOnCloud: any) => {
    setIsDeleting(true);
    setDeleteErrorMessage(undefined);

    const attachmentCardId = item.cardId || cardId;
    Actions.getAPIService()
      .oneDrive()
      .removeCloudAttachment(dossierId, attachmentCardId, item.id ?? '', deleteOnCloud)
      .then(
        () => {
          setDeleteItemDialog(false);
          if (onItemDelete) {
            onItemDelete(item.id ?? '', deleteOnCloud);
          }
        },
        (data) => {
          setDeleteErrorMessage(data.message);
        },
      )
      .finally(() => setIsDeleting(false));
  };

  /**
   * Sets the upload state and send the event.
   *
   * @param uploading
   */
  const setUploadingState = (uploading: any) => {
    setIsUploading(uploading);
    if (onUploadProgress) {
      onUploadProgress(uploading);
    }
  };
  // #endregion

  /**
   * Get cloud attachment details.
   *
   * @param {Object} cloudAttachment
   */
  const cloudAttachmentDetails = (cloudAttachment: any) => {
    setDetailsDialog(true);
    setDetailsItem(cloudAttachment);
    /*
      Commented out so that it can be enabled for later.
    Actions.getAPIService()
      .oneDrive()
      .getCloudAttachmentDetails(dossierId, cardId, cloudAttachment.id)
      .then(({status, data}) => {
        if (status === 'success') {
          setDetailsItem({
            ...detailsItem,
            ...data,
          });
        }
      });
     */
  };

  // render
  return (
    <>
      <OneDriveUploader
        // eslint-disable-next-line react/no-array-index-key
        error={errorMessages?.map((errorMessage: any, index: number) => (
          <div key={`error-upload${index.toString()}`}>{errorMessage}</div>
        ))}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        isUploading={isUploading}
        uploadCount={uploadCount}
        uploadIndex={uploadIndex}
        onCreateFile={createFile}
        onErrorClose={() => setErrorMessages(undefined)}
        onNewCloudLink={onNewCloudLink}
        onOneDriveFile={oneDriveFile}
        onUploadFile={onUploadFile}
      >
        <AttachmentList
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          items={cloudAttachments}
          options={[
            {
              action: 'download',
              label: 'Downloaden',
            },
            {
              action: 'details',
              label: 'Details',
            },
            {
              action: 'rename',
              label: 'Hernoemen',
            },
            {
              action: 'delete',
              label: 'Verwijderen',
            },
          ]}
          title="Cloud documenten"
          onItemClick={onItemClick}
          onMenuItemSelect={(item, action) => {
            switch (action) {
              case 'download':
                window.open(item.url, '_blank');
                break;
              case 'rename':
                cloudAttachmentRename(item);
                break;
              case 'details':
                cloudAttachmentDetails(item);
                break;
              case 'delete':
                setDeleteItemDialog(true);
                setDeleteItem(item);
                break;
              default:
                break;
            }
          }}
        />
      </OneDriveUploader>
      {detailsDialog && (
        <OneDriveFileDetails {...detailsItem} onClose={() => setDetailsDialog(false)} />
      )}
      {cloudAttachmentRenameDialog && (
        <InputModal
          isRequired
          error={
            cloudAttachmentModifyHasError
              ? cloudAttachmentModifyError.data?.name || cloudAttachmentModifyError.message
              : undefined
          }
          inputSuffixAdornment={
            cloudAttachmentRenameExtension ? `.${cloudAttachmentRenameExtension}` : undefined
          }
          isDisabled={cloudAttachmentModifyIsBusy}
          label="Naam"
          title="Cloud bestand hernoemen"
          value={cloudAttachmentRenameName || ''}
          onCancel={() => {
            cloudAttachmentModifyReset();
            setCloudAttachmentRenameDialog(false);
            setCloudAttachmentRenameItem(undefined);
            setCloudAttachmentRenameName(undefined);
          }}
          onConfirm={(value) => {
            cloudAttachmentRenameItem && doCloudAttachmentRename(cloudAttachmentRenameItem, value);
          }}
        />
      )}
      {deleteItemDialog && deleteItem && (
        <ConfirmModal
          isCloseShown
          shouldUsePortal
          buttons={[
            {
              title: 'Koppeling verwijderen',
              appearance: ButtonAppearanceTypes.APPEARANCE_SECONDARY,
              onClick: () => sendOnItemDelete(deleteItem, false),
            },
            {
              title: 'Volledig verwijderen',
              appearance: ButtonAppearanceTypes.APPEARANCE_SECONDARY,
              onClick: () => sendOnItemDelete(deleteItem, true),
            },
            {
              title: 'Annuleren',
              appearance: ButtonAppearanceTypes.APPEARANCE_LINK,
              onClick: () => setDeleteItemDialog(false),
            },
          ]}
          className="c-card-attachment-one-drive__delete-modal"
          isDisabled={isDisabled || isDeleting}
          title="Verwijderen gekoppeld cloud document"
          onCancel={() => setDeleteItemDialog(false)}
          onConfirm={() => setDeleteItemDialog(false)}
        >
          {deleteErrorMessage && (
            <div className="c-card-attachment-one-drive__delete-modal__error-reason">
              {deleteErrorMessage}
            </div>
          )}
          <p>Wilt u alleen de koppeling naar dit document verwijderen?</p>
          <p>Of wilt u ook het document in de cloud omgeving verwijderen?</p>
        </ConfirmModal>
      )}
    </>
  );
};

export default CardAttachmentOneDrive;
