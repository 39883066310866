import Attachment from 'models/attachment/AttachmentFolder';
import DataModel, { PropTypes } from 'models/base/DataModel';

/**
 * AttachmentFolder
 */
export default class AttachmentFolder extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    name: PropTypes.string,
    canDelete: PropTypes.bool,
    allAttachmentCount: PropTypes.number,
    allAttachments: PropTypes.arrayOf(
      PropTypes.shape({
        ...DataModel.propTypes,
        name: PropTypes.string,
        url: PropTypes.string,
        type: PropTypes.string,
        extension: PropTypes.string,
        createdDateTime: PropTypes.string,
        updatedDateTime: PropTypes.string,
        phase: PropTypes.string,
        attachmentType: PropTypes.string,
        cardId: PropTypes.string,
        dossierId: PropTypes.string,
        displayDate: PropTypes.string,
      }),
    ),
  };

  /**
   * @type {string}
   */
  name;

  /**
   * @type {boolean}
   */
  canDelete = false;

  /**
   * @type {number}
   */
  allAttachmentCount = 0;

  /**
   * @type {Attachment[]}
   */
  allAttachments;

  /**
   * @return {string|undefined}
   */
  getName() {
    return this.name;
  }

  /**
   * @return {boolean}
   */
  getCanDelete() {
    return this.canDelete;
  }

  /**
   * @return {number}
   */
  getAllAttachmentCount() {
    return this.allAttachmentCount;
  }

  /**
   * @return {Attachment[]}
   */
  getAllAttachments() {
    return this.allAttachments;
  }

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'allAttachments') {
      value = Attachment.createInstancesByArray(value);
    }
    return super.set(fieldName, value);
  }
}
