import Actions from 'actions/Actions';
import BerkleyModelSession from 'models/dossier/BerkleySessionModel';
import { createHook, createStore } from 'react-sweet-state';
import sweetFetchState, { DEFAULT_CONTEXT_LIST_ID } from 'stores/utils/SweetFetchState';

// region state keys
export const STATE_BERKLEY_SESSION_MODEL_SELECT = 'berkleySessionModelSelect';
export const STATE_BERKLEY_SESSION_MODEL_CREATE = 'berkleySessionModelCreate';
export const STATE_BERKLEY_SESSION_MODEL_UPDATE = 'berkleySessionModelUpdate';
export const STATE_BERKLEY_SESSION_MODEL_DELETE = 'berkleySessionModelDelete';
// endregion

// region initial state
const initialState = {
  [STATE_BERKLEY_SESSION_MODEL_SELECT]: sweetFetchState.init(),
  [STATE_BERKLEY_SESSION_MODEL_CREATE]: sweetFetchState.init(),
  [STATE_BERKLEY_SESSION_MODEL_UPDATE]: sweetFetchState.init(),
  [STATE_BERKLEY_SESSION_MODEL_DELETE]: sweetFetchState.init(),
};
// endregion

// region error message translations
/** @type {Record<string, string>} */
export const errorMessagesTranslations = {
  'Failed to create berkley bridge session.': 'Fout bij het aanmaken van Berkeley sessie',
};
// endregion

const actions = {
  /**
   * Reset a fetch state
   */
  resetFetchState: sweetFetchState.reset,

  /**
   * Get berkley session details
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} berkleySessionModelId
   * @returns {(function({getState: *, dispatch: *}))|*}
   */
  getBerkleySessionDetails:
    (dossierId, cardId, berkleySessionModelId) =>
    ({ getState, dispatch }) => {
      const { doRequest, isBusy, setContext } = sweetFetchState.withContextId(
        STATE_BERKLEY_SESSION_MODEL_SELECT,
        berkleySessionModelId,
        dispatch,
        getState,
      );

      if (isBusy()) {
        return;
      }

      doRequest(
        Actions.getAPIService()
          .berkleySessionModel()
          .getBerkleySessionModelDetails(dossierId, cardId, berkleySessionModelId),
      ).then(
        (response) => {
          if (response.isSuccess()) {
            const berkleySessionModel = BerkleyModelSession.createInstance(response.data);
            setContext(berkleySessionModel);
          }
        },
        () => null,
      );
    },

  /**
   * Create Berkley session
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {object} values
   * @returns {(function({getState: *, dispatch: *}))|*}
   */
  createBerkleySession:
    (dossierId, cardId, values) =>
    ({ getState, dispatch }) => {
      const { doRequest, isBusy, setContext } = sweetFetchState.withContextId(
        STATE_BERKLEY_SESSION_MODEL_CREATE,
        DEFAULT_CONTEXT_LIST_ID,
        dispatch,
        getState,
      );

      if (isBusy()) {
        return;
      }

      doRequest(
        Actions.getAPIService()
          .berkleySessionModel()
          .createBerkleySessionModel(dossierId, cardId, values),
      ).then(
        (response) => {
          if (response.isSuccess()) {
            const berkleySessionModel = BerkleyModelSession.createInstance(response.data);
            setContext(berkleySessionModel);

            const { setContext: setContextSelect } = sweetFetchState.withContextId(
              STATE_BERKLEY_SESSION_MODEL_SELECT,
              berkleySessionModel.id,
              dispatch,
              getState,
            );
            setContextSelect(berkleySessionModel);
          }
        },
        () => null,
      );
    },

  /**
   * Update Berkley session
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} berkleySessionModelId
   * @param {object} values
   * @returns {(function({getState: *, dispatch: *}))|*}
   */
  updateBerkleySession:
    (dossierId, cardId, berkleySessionModelId, values) =>
    ({ getState, dispatch }) => {
      const { doRequest, isBusy, setContext } = sweetFetchState.withContextId(
        STATE_BERKLEY_SESSION_MODEL_UPDATE,
        berkleySessionModelId,
        dispatch,
        getState,
      );

      if (isBusy()) {
        return;
      }

      doRequest(
        Actions.getAPIService()
          .berkleySessionModel()
          .updateBerkleySessionModel(dossierId, cardId, berkleySessionModelId, values),
      ).then(
        (response) => {
          if (response.isSuccess()) {
            const berkleySessionModel = BerkleyModelSession.createInstance(response.data);
            setContext(berkleySessionModel);

            const { setContext: setContextSelect } = sweetFetchState.withContextId(
              STATE_BERKLEY_SESSION_MODEL_SELECT,
              berkleySessionModelId,
              dispatch,
              getState,
            );
            setContextSelect(berkleySessionModel);
          }
        },
        () => null,
      );
    },

  /**
   * Delete Berkley session
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} berkleySessionModelId
   * @returns {(function({getState: *, dispatch: *}))|*}
   */
  deleteBerkleySession:
    (dossierId, cardId, berkleySessionModelId) =>
    ({ getState, dispatch }) => {
      const { doRequest, isBusy, setContext } = sweetFetchState.withContextId(
        STATE_BERKLEY_SESSION_MODEL_DELETE,
        berkleySessionModelId,
        dispatch,
        getState,
      );

      if (isBusy()) {
        return;
      }

      doRequest(
        Actions.getAPIService()
          .berkleySessionModel()
          .deleteBerkleySessionModel(dossierId, cardId, berkleySessionModelId),
      ).then(
        () => setContext({ dossierId, cardId, berkleySessionModelId }),
        () => null,
      );
    },
};

const Store = createStore({
  name: 'BerkleyModelSessionState',
  initialState,
  actions,
});

export const useBerkleyModelSessionState = createHook(Store);
