import './assets/styles/ListIcon.scss';

import classNames from 'classnames';
import React from 'react';

type Props = {
  className?: string;
  type?: string;
  isInline?: boolean;
};

const ListIcon: React.FC<Props> = ({ type, className, isInline, ...contextProps }) => (
  <span
    {...contextProps}
    className={classNames('c-list-icon', type, className, { 'c-list-icon__inline': isInline })}
  />
);

ListIcon.defaultProps = {
  isInline: false,
};

export default React.memo(ListIcon);
