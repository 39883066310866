import Color from 'color';

// cannot create a typescript file from this because Color package types are outdated.

/**
 * Create colors from a specific color
 *
 * @param {string|null|undefined} color: ;
 * @returns {{border: *, color: *, text: string, borderFocus: string}}
 */
export const createColorSwatch = (color = '#ffffff') => {
  const parseColor = color === '' || color === null ? '#ffffff' : color;

  return {
    color: parseColor.toLowerCase(),
    border: Color(parseColor).darken(0.2).hex().toLowerCase(),
    borderFocus: Color(parseColor).darken(0.5).hex().toLowerCase(),
    text: Color(parseColor).isLight() ? 'rgba(0, 0, 0, 0.75)' : 'rgba(255, 255, 255, 1)',
  };
};

/**
 * Add opacity to color
 *
 * @param {string} color;
 * @param {number} opacity
 * @returns
 */
export const addOpacityToColor = (color, opacity) => {
  return Color(color).alpha(opacity);
};
