import { useReducer } from 'react';

// Fetch states
export const FETCH_STATES = {
  UNKNOWN: 'UNKNOWN', // running a fetch call
  SUCCESS: 'SUCCESS', // fetch state is done
  BUSY: 'BUSY', // running a fetch call
  ERROR: 'ERROR', // fetch state has error
  RESET: 'RESET', // reset fetch state
};

/**
 * Initial state
 */
const initialState = {
  currentState: FETCH_STATES.UNKNOWN,
  isBusy: false,
  response: undefined,
  error: undefined,
  hasError: false,
};

/**
 * fetch state reducer
 *
 * @param {object} state
 * @param {object} action
 * @returns {object}
 */
function fetchStateReducer(state, action) {
  switch (action.type) {
    case FETCH_STATES.BUSY:
      return {
        currentState: FETCH_STATES.BUSY,
        isBusy: true,
      };
    case FETCH_STATES.SUCCESS:
      return {
        currentState: FETCH_STATES.SUCCESS,
        isBusy: false,
        response: action.payload,
        hasError: false,
        error: undefined,
      };
    case FETCH_STATES.ERROR:
      return {
        currentState: FETCH_STATES.SUCCESS,
        isBusy: false,
        response: action.payload,
        hasError: true,
        error: action.payload,
      };
    case FETCH_STATES.RESET:
      return { ...initialState };
    default:
      return new Error('action type not supported');
  }
}

/**
 * Fetch state
 *
 * @returns {Array}
 */
function useFetchState() {
  const [state, dispatch] = useReducer(fetchStateReducer, initialState, () => initialState);

  /**
   * Run request of fetch
   *
   * @param {Promise} fetchPromise
   * @returns Promise
   */
  const doRequest = (fetchPromise) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: FETCH_STATES.BUSY });
      fetchPromise
        .then((response) => {
          dispatch({ type: FETCH_STATES.SUCCESS, payload: response });
          resolve(response);
        })
        .catch((response) => {
          dispatch({ type: FETCH_STATES.ERROR, payload: response });
          reject(response);
        });
    });
  };

  /**
   * Reset state
   */
  const reset = () => dispatch({ type: FETCH_STATES.RESET });

  return [state, { doRequest, reset }];
}

export default useFetchState;
