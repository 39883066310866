import './assets/styles/SelectFieldOption.scss';

import classNames from 'classnames';
import React from 'react';

type Props = React.PropsWithChildren<{
  className?: string;
  icon?: any;
  subtitle?: string;
}>;

const SelectFieldOption: React.FC<Props> = ({ className, icon, subtitle, children }) => (
  <div
    className={classNames('c-select-field-option', className, {
      'c-select-field-option--has-icon': !!icon,
      'c-select-field-option--has-subtitle': !!subtitle,
    })}
  >
    {icon && <div className="c-select-field-option__icon">{icon}</div>}
    <div className="c-select-field-option__content">
      <div className="c-select-field-option__title">{children}</div>
      {subtitle && <div className="c-select-field-option__subtitle">{subtitle}</div>}
    </div>
  </div>
);

export default SelectFieldOption;
