import './assets/styles/CardSectionComments.scss';

import { Box, Divider, Loader, Text } from '@elseu/sdu-titan';
import ConfirmDialog from 'components/confirmdialog/ConfirmDialog';
import LoadMore from 'components/loadmore/LoadMore';
import type { Paging } from 'entity/base/types';
import type { Comment, CommentReaction, ContextItem } from 'entity/dossiers/types';
import type { User } from 'entity/system/types';
import { isMentionUserLinkedToDossier, removeMentionFromContent } from 'helpers/mentionhelper';
import React, { Fragment, useEffect, useState } from 'react';

import CommentView from '../comments/Comment';
import CommentEditor from '../comments/CommentEditor';
import editImage from './assets/images/edit.svg';
import replyImage from './assets/images/reply.svg';
import trashImage from './assets/images/trash.svg';
import CardSection from './CardSection';

type CommentUnion = Comment & CommentReaction;

type CommentDisplayProps = {
  isDisabled?: boolean;
  isReadOnly?: boolean;
  comment: CommentReaction;
  currentUser: User;
  isReaction?: boolean;
  isEditing?: boolean;
  shouldResetEditorState?: boolean;
  error: Record<string, any>;
  userSuggestions: any[];
  dossierUsers: string[];
  onOpenReply?: () => void;
  onOpenEdit?: () => void;
  onPost: (content: string, context: ContextItem | undefined) => void;
  onDelete?: () => void;
  onNewUserForMention: () => void;
};

type CommentEditorStateProps = {
  isDisabled?: boolean;
  content?: string;
  context?: ContextItem;
  avatar?: string;
  userSuggestions: any[];
  dossierUsers: string[];
  shouldResetEditorState?: boolean;
  error?: Record<string, any>;
  onPost: (content: string, context: ContextItem | undefined) => void;
  onNewUserForMention: () => void;
};

type CardSectionCommentsViewProps = {
  isLoading?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  profile: User;
  comments: Comment[];
  paging?: Paging;
  shouldResetEditorState?: boolean;
  errors: Record<string, any>;
  userSuggestions: any[];
  dossierUsers: string[];
  title?: string;
  readOnlyText?: string;
  dossierTypeMessageText?: string;
  dossierTypeTitleText?: string;
  // eslint-disable-next-line react/boolean-prop-naming
  editorHideAttachments?: boolean;
  onNextPage: () => void;
  onCommentNew: (
    content: string,
    context: ContextItem | undefined,
    parentComment: Comment | undefined,
  ) => void;
  onCommentEdit: (content: string, context: ContextItem | undefined, comment: CommentUnion) => void;
  onCommentDelete: (comment: CommentUnion) => void;
  onNewUserForMention: () => void;
};

/**
 * Get correct actions for comment of a user
 *
 * @param {object} comment
 * @param {object} user
 * @param {boolean} isDisabled
 * @param {boolean} isReaction
 * @param {function} onActionFired
 * @returns {[]}
 */
const getCommentActionsForUser = (
  comment: CommentUnion,
  user: User,
  isDisabled: boolean,
  isReaction: boolean,
  onActionFired: (action: string) => void,
) => {
  const actions = [];

  if (!isReaction) {
    actions.push({
      label: 'Reageer',
      isDisabled,
      image: replyImage,
      onClick: () => onActionFired('reply'),
    });
  }

  if (comment.user.id === user.id) {
    actions.push({
      label: 'Bewerk bericht',
      isDisabled,
      image: editImage,
      onClick: () => onActionFired('edit'),
    });

    actions.push({
      label: 'Verwijder bericht',
      isDisabled: isDisabled || (comment.comments && comment.comments.length > 0),
      image: trashImage,
      onClick: () => onActionFired('delete'),
    });
  }

  return actions;
};

/**
 * Helper function to get error message for comment.
 *
 * @param {string} commentId
 * @param {Record<string, any>} errors
 * @returns {*|undefined}
 */
const getErrorForComment = (commentId: string, errors: Record<string, any>) => {
  return errors[commentId] || undefined;
};

/**
 * Comment display component
 *
 * @param {boolean} isDisabled
 * @param {boolean} isReadOnly
 * @param {object} comment
 * @param {object} currentUser
 * @param {boolean} isReaction
 * @param {boolean} isEditing
 * @param {boolean} shouldResetEditorState
 * @param {object} error
 * @param {array} userSuggestions
 * @param {array} dossierUsers
 * @param {function} onOpenReply
 * @param {function} onOpenEdit
 * @param {function} onPost
 * @param {function} onDelete
 * @returns {JSX.Element}
 * @constructor
 */
const CommentDisplay: React.FC<CommentDisplayProps> = ({
  isDisabled = false,
  isReadOnly,
  comment,
  currentUser,
  isReaction = false,
  isEditing,
  shouldResetEditorState,
  error,
  userSuggestions,
  dossierUsers,
  onOpenReply,
  onOpenEdit,
  onPost,
  onDelete,
  onNewUserForMention,
}) => {
  const { user, content, context, createdDateTime } = comment;

  /**
   * Action is called for comment menu
   */
  const onActionFired = (action: string) => {
    switch (action) {
      case 'reply':
        if (onOpenReply) {
          onOpenReply();
        }
        break;
      case 'edit':
        if (onOpenEdit) {
          onOpenEdit();
        }
        break;
      case 'delete':
        if (onDelete) {
          onDelete();
        }
        break;
      default:
        break;
    }
  };

  if (isEditing && !isReadOnly) {
    // #region editor
    return (
      <CommentEditorState
        avatar={user.avatarUrl}
        content={content}
        context={context}
        dossierUsers={dossierUsers}
        error={error}
        isDisabled={isDisabled}
        shouldResetEditorState={shouldResetEditorState}
        userSuggestions={userSuggestions}
        onNewUserForMention={onNewUserForMention}
        onPost={onPost}
      />
    );
    // #endregion
  } else {
    // #region display
    return (
      <CommentView
        actions={
          !isReadOnly
            ? getCommentActionsForUser(comment, currentUser, isDisabled, isReaction, onActionFired)
            : []
        }
        content={content}
        context={context}
        createdDateTime={createdDateTime}
        currentUser={currentUser}
        isDisabled={isDisabled}
        user={user}
      />
    );
    // #endregion
  }
};

/**
 * Comment editor state
 *
 * @param {boolean} isDisabled
 * @param {string} content
 * @param {object} context
 * @param {string} avatar
 * @param {boolean} shouldResetEditorState
 * @param {any} userSuggestions
 * @param {any} dossierUsers
 * @param {any} error
 * @param {function} onPost
 * @returns {JSX.Element}
 * @constructor
 */
const CommentEditorState: React.FC<CommentEditorStateProps> = ({
  isDisabled,
  content,
  context,
  avatar,
  userSuggestions,
  dossierUsers,
  shouldResetEditorState = false,
  error,
  onPost,
  onNewUserForMention,
}) => {
  const [contentState, setContentState] = useState<string | undefined>(content || '');
  const [contextState, setContextState] = useState<ContextItem | undefined>(context);
  const [errorState, setErrorState] = useState<any>(error || undefined);

  const [showContextConfirm, setShowConfirmContext] = useState(false);

  const [confirmAssignUserOpen, setConfirmAssignUserOpen] = useState(false);
  const [confirmAssignUser, setConfirmAssignUser] = useState<Record<string, any>>({});

  useEffect(() => setContentState(content || ''), [content]);
  useEffect(() => setContextState(context), [context]);
  useEffect(() => setErrorState(error), [error]);

  // reset the editor
  useEffect(() => {
    if (shouldResetEditorState) {
      setContentState(content || '');
      setContextState(context);
    }
    // eslint-disable-next-line
  }, [shouldResetEditorState]);

  const handleMentionConfirm = (confirmed: boolean) => {
    setConfirmAssignUserOpen(false);

    // remove mentioned user and set new content
    if (!confirmed) {
      setContentState(removeMentionFromContent(contentState ?? '', confirmAssignUser.id));
    } else {
      onNewUserForMention();
    }
  };

  return (
    <>
      <CommentEditor
        avatar={avatar}
        content={contentState ?? ''}
        context={contextState}
        error={errorState}
        isDisabled={isDisabled}
        isEditorAlwaysCollapsed={false}
        isEditorFocused={false}
        userSuggestions={userSuggestions}
        onChange={(changeContent, changeContext) => {
          setContentState(changeContent);
          setContextState(changeContext);
        }}
        onContextChange={(ctx) => setContextState(ctx)}
        onContextDelete={(ctx) => {
          if (ctx.id) {
            setShowConfirmContext(true);
          } else {
            setContextState(undefined);
            setErrorState(undefined);
          }
        }}
        onMentionAdded={(mention: any) => {
          if (!isMentionUserLinkedToDossier(dossierUsers, mention)) {
            setConfirmAssignUserOpen(true);
            setConfirmAssignUser(mention);
          }
        }}
        onPost={(newContent: string | undefined, newContext: ContextItem | undefined) => {
          setContentState(newContent);
          setContextState(newContext);
          onPost(newContent ?? '', newContext);
        }}
      />
      {showContextConfirm && (
        <ConfirmDialog
          cancelTitle="Nee"
          className="comment-confirm-dialog"
          confirmAppearance="danger"
          confirmTitle="Ja, verwijder"
          title="Bestand of koppeling verwijderen?"
          onCancel={() => setShowConfirmContext(false)}
          onConfirm={() => {
            setContextState(undefined);
            setErrorState(undefined);
            setShowConfirmContext(false);
          }}
        >
          Weet u zeker dat u dit bestand of deze koppeling wilt verwijderen?
        </ConfirmDialog>
      )}
      {confirmAssignUserOpen && (
        <ConfirmDialog
          cancelTitle="Nee"
          confirmAppearance="danger"
          confirmTitle="Ja, koppel"
          isDisabled={isDisabled}
          title="Gebruiker koppelen aan dossier"
          onCancel={() => handleMentionConfirm(false)}
          onConfirm={() => handleMentionConfirm(true)}
        >
          De geselecteerde gebruiker is niet gekoppeld aan dit dossier.
          <br />
          Wilt u de gebruiker koppelen als bewerker aan dit dossier en een notificatie sturen?
        </ConfirmDialog>
      )}
    </>
  );
};

/**
 * Card section comments display
 *
 * @param {boolean} isLoading
 * @param {boolean} isDisabled
 * @param {boolean} isReadOnly
 * @param {object} profile
 * @param {array} comments
 * @param {object} paging
 * @param {boolean} shouldResetEditorState
 * @param {*} errors
 * @param {array} userSuggestions
 * @param {array} dossierUsers
 * @param {function} onNextPage
 * @param {function} onCommentNew
 * @param {function} onCommentEdit
 * @param {function} onCommentDelete
 * @param {string} title
 * @param {string} readOnlyText
 * @returns {JSX.Element}
 * @constructor
 */
const CardSectionCommentsView: React.FC<CardSectionCommentsViewProps> = ({
  isLoading,
  isDisabled = false,
  isReadOnly,

  profile,
  comments,
  paging,

  shouldResetEditorState,
  errors,

  // region suggestions/mention
  userSuggestions,
  dossierUsers,
  // endregion

  // region text
  title = 'Chat',
  readOnlyText = 'Geen chats.',
  // endregion

  // region events
  onNextPage,
  onCommentNew,
  onCommentEdit,
  onCommentDelete,
  onNewUserForMention,
  // endregion
}) => {
  const [commentEditing, setCommentEditing] = useState<string | undefined>();
  const [showReplyEditor, setShowReplyEditor] = useState<string[]>([]);

  // #region delete
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteComment, setDeleteComment] = useState<CommentUnion | undefined>(undefined);

  useEffect(() => {
    if (shouldResetEditorState) {
      setCommentEditing(undefined);
    }
  }, [shouldResetEditorState]);

  const onDeleteComment = (comment: CommentUnion) => {
    setDeleteComment(comment);
    setShowDeleteConfirm(true);
  };
  // #endregion

  // #region default comment props
  const defaultCommentProps = {
    isLoading,
    isDisabled,
    isReadOnly,
    currentUser: profile,
  };
  // #endregion

  return (
    <CardSection className="c-card-section-comments comments" title={title}>
      {/* region readonly no comments  */}
      {isReadOnly === true && comments.length === 0 && <span>{readOnlyText}</span>}
      {/* endregion */}

      {/* region post editor */}
      {!isReadOnly && (
        <CommentEditorState
          avatar={profile.avatarUrl}
          dossierUsers={dossierUsers}
          error={getErrorForComment('new', errors)}
          isDisabled={isDisabled}
          shouldResetEditorState={shouldResetEditorState}
          userSuggestions={userSuggestions}
          onNewUserForMention={onNewUserForMention}
          onPost={(content, context) => onCommentNew(content, context, undefined)}
        />
      )}
      {/* endregion */}

      {/* region display comments */}
      {comments.length > 0 && (
        <div className="comments__list">
          <Divider spaceAfter={5} spaceBefore={5} />
          {comments.map((comment, commentIndex) => {
            return (
              <Fragment key={`c-comment-${comment.id}`}>
                <CommentDisplay
                  {...defaultCommentProps}
                  comment={comment}
                  dossierUsers={dossierUsers}
                  error={getErrorForComment(comment.id, errors)}
                  isDisabled={isDisabled}
                  isEditing={commentEditing === comment.id}
                  shouldResetEditorState={shouldResetEditorState}
                  userSuggestions={userSuggestions}
                  onDelete={() => onDeleteComment(comment)}
                  onNewUserForMention={onNewUserForMention}
                  onOpenEdit={() => setCommentEditing(comment.id)}
                  onOpenReply={() => {
                    setShowReplyEditor((oldReplies) => {
                      const newReplies = [...oldReplies];
                      if (!newReplies.includes(comment.id)) {
                        newReplies.push(comment.id);
                      }
                      return newReplies;
                    });
                  }}
                  onPost={(content, context) => onCommentEdit(content, context, comment)}
                />
                <Box className="comment__reactions" pl={12} pt={5}>
                  {/* eslint-disable-next-line  @typescript-eslint/no-unnecessary-condition */}
                  {comment.comments?.map((reaction) => (
                    <Fragment key={`c-comment-${reaction.id}`}>
                      <Divider spaceAfter={5} spaceBefore={5} />
                      <CommentDisplay
                        {...defaultCommentProps}
                        isReaction
                        comment={reaction}
                        dossierUsers={dossierUsers}
                        error={getErrorForComment(reaction.id, errors)}
                        isDisabled={isDisabled}
                        isEditing={commentEditing === reaction.id}
                        shouldResetEditorState={shouldResetEditorState}
                        userSuggestions={userSuggestions}
                        onDelete={() => onDeleteComment(reaction)}
                        onNewUserForMention={onNewUserForMention}
                        onOpenEdit={() => setCommentEditing(reaction.id)}
                        onPost={(content, context) => onCommentEdit(content, context, reaction)}
                      />
                    </Fragment>
                  ))}
                  {!isReadOnly &&
                    // eslint-disable-next-line  @typescript-eslint/no-unnecessary-condition
                    (showReplyEditor.includes(comment.id) ||
                      (comment.comments && comment.comments.length > 0)) && (
                      <>
                        <Divider spaceAfter={5} spaceBefore={5} />
                        <Box className="comment__reaction" pl={0}>
                          <CommentEditorState
                            avatar={profile.avatarUrl}
                            dossierUsers={dossierUsers}
                            error={getErrorForComment(`new-${comment.id}`, errors)}
                            isDisabled={isDisabled}
                            shouldResetEditorState={shouldResetEditorState}
                            userSuggestions={userSuggestions}
                            onNewUserForMention={onNewUserForMention}
                            onPost={(content, context) => onCommentNew(content, context, comment)}
                          />
                        </Box>
                      </>
                    )}
                </Box>
                {commentIndex < comments.length - 1 && <Divider spaceAfter={5} spaceBefore={5} />}
              </Fragment>
            );
          })}
        </div>
      )}
      {/* endregion */}

      {/* region loading */}
      {isLoading && (
        <Box className="comments__list comments__list__loading" py={5}>
          <Loader height={32} variant="spinner" />
          <Text type="label">Bezig met ophalen...</Text>
        </Box>
      )}
      {/* endregion */}

      {/* region paging */}
      {!isLoading && paging && paging.nextPage !== paging.page && (
        <Box className="comments__list comments__list__paging" px={3}>
          <LoadMore hasMoreContent isDisabled={isLoading} onLoadMore={onNextPage} />
        </Box>
      )}
      {/* endregion */}

      {/* region delete confirm dialog */}
      {showDeleteConfirm && (
        <ConfirmDialog
          cancelTitle="Nee"
          className="comment-confirm-dialog"
          confirmAppearance="danger"
          confirmTitle="Ja, verwijder"
          isDisabled={isDisabled || isLoading}
          title="Bericht verwijderen?"
          zIndex={1001000}
          onCancel={() => setShowDeleteConfirm(false)}
          onConfirm={() => {
            if (deleteComment) {
              onCommentDelete(deleteComment);
              setShowDeleteConfirm(false);
            }
          }}
        >
          Weet u zeker dat u dit bericht wilt verwijderen?
        </ConfirmDialog>
      )}
      {/* endregion */}
    </CardSection>
  );
};

export default CardSectionCommentsView;
