import { SearchForm, SearchIcon } from '@elseu/sdu-titan';
import ApplicationConstants from 'constants/ApplicationConstants';
import React, { useCallback } from 'react';

type Props = {
  isShown: boolean;
  onSearched: () => void;
};

const NdfrSearchSimple: React.FC<Props> = ({ isShown, onSearched }) => {
  const handleSubmit = useCallback(
    (query: string) => {
      window.open(
        `${ApplicationConstants.APPLICATION_NDFR_DOMAIN}/zoeken?query=${encodeURIComponent(query)}`,
        '_blank',
      );
      onSearched();
    },
    [onSearched],
  );

  if (!isShown) {
    return null;
  }

  return (
    <SearchForm
      autoComplete="off"
      label="Zoeken in NDFR"
      placeholder="Zoeken in NDFR"
      prefixAdornment={<SearchIcon />}
      onSubmit={handleSubmit}
    />
  );
};

export default NdfrSearchSimple;
