import type { UniqueIdentifier } from '@dnd-kit/core';
import type { TableEmptyStateProps } from '@elseu/sdu-titan/dist/types/components/Table/TableEmptyState';
import type { KanbanColumnProps } from 'components/kanban/KanbanColumn';
import type { UseDnDMultipleContainersReturnProps } from 'hooks/dnd-kit/useDnDMultipleContainers';
import type { ReactElement } from 'react';
import { createContext, useContext } from 'react';
import type { RepositionTicketPayload } from 'services/api/TicketApi';

type UpdatePayload = {
  ticketId: UniqueIdentifier;
  statusId?: UniqueIdentifier;
  otherId?: UniqueIdentifier;
  placement?: RepositionTicketPayload['placement'];
};

export interface KanbanProps {
  /** The empty state props. Will show an empty state when no results found. */
  emptyStateProps?: TableEmptyStateProps;
  renderCardContent?: (row: any) => ReactElement;
  onCardClick?: (row: any) => void;
  onTicketUpdate: (payload: UpdatePayload) => void;
  onTicketCreate: () => void;
  columns: KanbanColumnProps[];
}

export type KanbanContextProps<Item> = Pick<
  KanbanProps,
  'renderCardContent' | 'onCardClick' | 'onTicketCreate'
> &
  Pick<
    UseDnDMultipleContainersReturnProps<Item>,
    'isDragging' | 'onDragCancel' | 'onDragEnd' | 'onDragOver' | 'onDragStart'
  >;

export const KanbanContext = createContext<KanbanContextProps<any> | undefined>(undefined);

export const useKanban = <Item>() => {
  const context = useContext(KanbanContext as React.Context<KanbanContextProps<Item> | undefined>);
  if (context === undefined) {
    throw new Error('useKanban must be used within the context of Kanban');
  }
  return context;
};
