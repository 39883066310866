import { normalizeConsideration, normalizeTopic } from 'entity/dossiers/normalizer';
import type { Topic } from 'entity/dossiers/types';
import { convertToDate, normalizeMap } from 'helpers/normalizehelper';

import type { TemplateTask, TemplateTaskList } from './types';

export const normalizeTemplateTaskList = (data: any): TemplateTaskList => ({
  id: data.id,
  name: data.name,
  topics: normalizeMap<Topic>(data.topics, normalizeTopic),
  stage: data.stage,
  consideration: data.consideration ? normalizeConsideration(data.consideration) : undefined,
  createdDateTime: convertToDate(data.createdDateTime),
  templateTasks: normalizeMap<TemplateTask>(data.templateTasks, normalizeTemplateTask),
});

export const normalizeTemplateTask = (data: any): TemplateTask => ({
  id: data.id,
  name: data.name,
  position: data.position,
});
