import type { ApiFilter } from 'types/types';

import type { StatusGroup } from '../../entity/dossiers/types';
import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';
import type { JSendResponse } from './types';

type GetTicketsResponse = JSendResponse<{
  statusGroups: StatusGroup[];
}>;

export default class StatusApi extends APIEndpoint {
  /**
   * Returns a list of status groups
   */
  getStatusGroups(
    filters: ApiFilter[] = [],
    sort = '',
    page = 1,
    pageSize = 100,
  ): Promise<GetTicketsResponse> {
    const url = this.buildUrl('status-groups', filters, sort, page, pageSize);

    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }
}
