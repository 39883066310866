import './assets/styles/LoadMore.scss';

import { Button } from '@elseu/sdu-titan';
import React from 'react';

type Props = {
  isDisabled?: boolean;
  hasMoreContent: boolean;
  onLoadMore: () => void;
};

/**
 * LoadMore
 *
 * Represents a load more block with a centered load more button that is only rendered
 * if more content is available.
 *
 * @param {boolean} disabled
 * @param {boolean} hasMoreContent
 * @param {function} onLoadMore
 * @return {*|JSX.Element}
 * @constructor
 */
const LoadMore: React.FC<Props> = ({ isDisabled, hasMoreContent, onLoadMore }) =>
  hasMoreContent ? (
    <div className="c-load-more">
      <Button isDisabled={isDisabled} size="S" onClick={onLoadMore}>
        Laad meer
      </Button>
    </div>
  ) : null;

export default LoadMore;
