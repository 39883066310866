import Actions from 'actions/Actions';
import Client from 'models/crm/Client';
import AuditLog from 'models/dossier/AuditLog';
import Team from 'models/system/Team';
import TeamUser from 'models/system/TeamUser';
import { createHook, createStore } from 'react-sweet-state';
import sweetFetchState from 'stores/utils/SweetFetchState';
import sweetPaging from 'stores/utils/SweetPagingState';

// state keys
export const STATE_TEAMS = 'teams';
export const STATE_TEAMS_SELECT = 'teamsSelect';
export const STATE_TEAMS_CREATE = 'teamsCreate';
export const STATE_TEAMS_UPDATE = 'teamsUpdate';
export const STATE_TEAMS_DELETE = 'teamsDelete';
export const STATE_TEAMS_AUDIT = 'teamsAudit';
export const STATE_TEAMS_USERS = 'teamsUsers';
export const STATE_TEAMS_USERS_ADD = 'teamsUsersAdd';
export const STATE_TEAMS_USERS_REMOVE = 'teamsUsersRemove';
export const STATE_TEAMS_USERS_SET_ROLE = 'teamsUsersSetRole';
export const STATE_TEAMS_CLIENTS = 'teamsClients';
export const STATE_TEAMS_CLIENTS_ADD = 'teamsClientsAdd';
export const STATE_TEAMS_CLIENTS_REMOVE = 'teamsClientsRemove';

const Store = createStore({
  name: 'TeamState',
  initialState: {
    [STATE_TEAMS]: sweetPaging.init(),
    [STATE_TEAMS_SELECT]: sweetFetchState.init(),
    [STATE_TEAMS_CREATE]: sweetFetchState.init(),
    [STATE_TEAMS_UPDATE]: sweetFetchState.init(),
    [STATE_TEAMS_DELETE]: sweetFetchState.init(),
    [STATE_TEAMS_AUDIT]: sweetPaging.init(),
    [STATE_TEAMS_USERS]: sweetPaging.init(),
    [STATE_TEAMS_USERS_ADD]: sweetFetchState.init(),
    [STATE_TEAMS_USERS_REMOVE]: sweetFetchState.init(),
    [STATE_TEAMS_USERS_SET_ROLE]: sweetFetchState.init(),
    [STATE_TEAMS_CLIENTS]: sweetPaging.init(),
    [STATE_TEAMS_CLIENTS_ADD]: sweetFetchState.init(),
    [STATE_TEAMS_CLIENTS_REMOVE]: sweetFetchState.init(),
  },
  actions: {
    /**
     * Reset a fetch state
     */
    resetFetchState: sweetFetchState.reset,

    /**
     * Get teams
     *
     * @param {boolean} shouldReset
     * @param {array} filters
     * @param {string} sort
     * @param {number} page
     * @param {number} maxPage
     * @returns {function({getState: *, dispatch: *})}
     */
    getTeams:
      (shouldReset, filters, sort, page, maxPage = 10) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy } = sweetPaging.state(
          STATE_TEAMS,
          (response) => Team.createInstancesByArray(response.data.teams),
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(
          Actions.getAPIService().teams().getTeams(filters, sort, page, maxPage),
          shouldReset,
        );
      },

    /**
     * Get team
     *
     * @param {string} teamId
     * @returns {function({getState: *, dispatch: *})}
     */
    getTeam:
      (teamId) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy, setContext } = sweetFetchState.withContextId(
          STATE_TEAMS_SELECT,
          teamId,
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(Actions.getAPIService().teams().teamDetails(teamId)).then(
          (response) => {
            const team = sweetPaging.getItem(teamId, getState()[STATE_TEAMS]) || {};
            const newTeam = Team.createInstance({
              ...team,
              ...response.getData(),
            });
            setContext(newTeam);
            dispatch(sweetPaging.addItem(STATE_TEAMS, newTeam));
          },
          () => null,
        );
      },

    /**
     * Get team audit log
     *
     * @param {string} teamId
     * @param {boolean} shouldReset
     * @returns {function({getState?: *, dispatch?: *}): undefined}
     */
    getTeamAuditLog:
      (teamId, shouldReset = true) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy, getPaging } = sweetPaging.state(
          STATE_TEAMS_AUDIT,
          (response) => AuditLog.createInstancesByArray(response.data.auditLog),
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        const nextPage = shouldReset ? 1 : getPaging().getNextPage();
        doRequest(Actions.getAPIService().teams().teamAuditLog(teamId, nextPage), shouldReset);
      },

    /**
     * Create team
     *
     * @param {object} values
     * @returns {function({getState: *, dispatch: *})}
     */
    createTeam:
      (values) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy, setContext } = sweetFetchState.state(
          STATE_TEAMS_CREATE,
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(Actions.getAPIService().teams().createTeam(values)).then(
          (response) => {
            const team = Team.createInstance(response.data);
            const { setContext: setContextSelect } = sweetFetchState.withContextId(
              STATE_TEAMS_SELECT,
              team.getIdentity(),
              dispatch,
              getState,
            );
            setContext(team);
            setContextSelect(team);
            dispatch(sweetPaging.addItem(STATE_TEAMS, team));
          },
          () => null,
        );
      },

    /**
     * Update team
     *
     * @param {string} teamId
     * @param {object} values
     * @returns {function({getState: *, dispatch: *})}
     */
    updateTeam:
      (teamId, values) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy, setContext } = sweetFetchState.withContextId(
          STATE_TEAMS_UPDATE,
          teamId,
          dispatch,
          getState,
        );
        const { setContext: setContextSelect } = sweetFetchState.withContextId(
          STATE_TEAMS_SELECT,
          teamId,
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(Actions.getAPIService().teams().updateTeam(teamId, values)).then(
          (response) => {
            const team = Team.createInstance(response.data);
            setContext(team);
            setContextSelect(team);
            dispatch(sweetPaging.addItem(STATE_TEAMS, team));
          },
          () => null,
        );
      },

    /**
     * Delete team
     *
     * @param {string} teamId
     * @returns {function({getState: *, dispatch: *})}
     */
    deleteTeam:
      (teamId) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy } = sweetFetchState.withContextId(
          STATE_TEAMS_DELETE,
          teamId,
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(Actions.getAPIService().teams().deleteTeam(teamId)).then(
          () => dispatch(sweetPaging.removeItem(STATE_TEAMS, teamId)),
          () => null,
        );
      },

    /**
     * Get users from a team
     *
     * @param {string} teamId
     * @param {boolean} shouldReset
     * @param {array} filters
     * @param {string} sort
     * @param {number} page
     * @returns {function({getState: *, dispatch: *})}
     */
    getUsersFromTeam:
      (teamId, shouldReset, filters, sort, page) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy } = sweetPaging.state(
          STATE_TEAMS_USERS,
          (response) => TeamUser.createInstancesByArray(response.data.teamUsers),
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(
          Actions.getAPIService().teams().teamUsers(teamId, filters, sort, page),
          shouldReset,
        );
      },

    /**
     * Add users to team
     *
     * @param {string} teamId
     * @param {array} userIds
     * @param {string} role
     * @returns {function({getState: *, dispatch: *})}
     */
    addUsersToTeam:
      (teamId, userIds, role) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy } = sweetFetchState.withContextId(
          STATE_TEAMS_USERS_ADD,
          teamId,
          dispatch,
          getState,
        );
        const { setContext: setContextSelect, getContext: getContextSelect } =
          sweetFetchState.withContextId(STATE_TEAMS_SELECT, teamId, dispatch, getState);

        if (isBusy()) {
          return;
        }

        doRequest(
          Actions.getAPIService().teams().teamAddUsers(teamId, { users: userIds, role }),
        ).then(
          (response) => {
            const team = getContextSelect();
            const newTeam = Team.createInstance({
              ...team,
              ...response.getData(),
            });
            setContextSelect(newTeam);
            dispatch(sweetPaging.addItem(STATE_TEAMS, newTeam));
          },
          () => null,
        );
      },

    /**
     * Remove users to team
     *
     * @param {string} teamId
     * @param {array} userIds
     * @returns {function({getState: *, dispatch: *})}
     */
    removeUsersFromTeam:
      (teamId, userIds) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy } = sweetFetchState.withContextId(
          STATE_TEAMS_USERS_REMOVE,
          teamId,
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(Actions.getAPIService().teams().teamRemoveUsers(teamId, { users: userIds })).then(
          () => {
            userIds.forEach((userId) =>
              dispatch(sweetPaging.removeItem(STATE_TEAMS_USERS, userId)),
            );
          },
          () => null,
        );
      },

    /**
     * Set a role for a user in a team
     *
     * @param {string} teamId
     * @param {string} userId
     * @param {string} role
     * @returns {function({getState?: *, dispatch?: *})}
     */
    setRoleForUser:
      (teamId, userId, role) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy } = sweetFetchState.withContextId(
          STATE_TEAMS_USERS_SET_ROLE,
          teamId + userId,
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(Actions.getAPIService().teams().teamSetRoleForUser(teamId, userId, role)).then(
          (response) => {
            const teamUser = TeamUser.createInstance(response.getData());
            dispatch(sweetPaging.addItem(STATE_TEAMS_USERS, teamUser));
          },
          () => null,
        );
      },

    /**
     * Get clients from team
     *
     * @param {string} teamId
     * @param {boolean} shouldReset
     * @param {array} filters
     * @param {string} sort
     * @param {number} page
     * @returns {function({getState: *, dispatch: *})}
     */
    getClientsFromTeam:
      (teamId, shouldReset, filters, sort, page) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy } = sweetPaging.state(
          STATE_TEAMS_CLIENTS,
          (response) => Client.createInstancesByArray(response.data.clients),
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(
          Actions.getAPIService().teams().teamClients(teamId, filters, sort, page),
          shouldReset,
        );
      },

    /**
     * Add clients to team
     *
     * @param {string} teamId
     * @param {array} clients
     * @returns {function({getState: *, dispatch: *})}
     */
    addClientsToTeam:
      (teamId, clients) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy } = sweetFetchState.withContextId(
          STATE_TEAMS_CLIENTS_ADD,
          teamId,
          dispatch,
          getState,
        );
        const { setContext: setContextSelect, getContext: getContextSelect } =
          sweetFetchState.withContextId(STATE_TEAMS_SELECT, teamId, dispatch, getState);

        if (isBusy()) {
          return;
        }

        doRequest(Actions.getAPIService().teams().teamAddClients(teamId, { clients })).then(
          (response) => {
            const team = getContextSelect();
            const newTeam = Team.createInstance({
              ...team,
              ...response.getData(),
            });
            setContextSelect(newTeam);
            dispatch(sweetPaging.addItem(STATE_TEAMS, newTeam));
          },
          () => null,
        );
      },

    /**
     * Remove clients to team
     *
     * @param {string} teamId
     * @param {array} clientIds
     * @returns {function({getState: *, dispatch: *})}
     */
    removeClientsFromTeam:
      (teamId, clientIds) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy } = sweetFetchState.withContextId(
          STATE_TEAMS_CLIENTS_REMOVE,
          teamId,
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(
          Actions.getAPIService().teams().teamRemoveClients(teamId, { clients: clientIds }),
        ).then(
          () => {
            clientIds.forEach((clientId) =>
              dispatch(sweetPaging.removeItem(STATE_TEAMS_CLIENTS, clientId)),
            );
          },
          () => null,
        );
      },
  },
});

export const useTeamState = createHook(Store);
