import { Box, Flex, Link, Text } from '@elseu/sdu-titan';
import { NotificationTypeIcon } from 'components/notificationcenter/NotificationTypeIcon';
import React from 'react';
import styled from 'styled-components';

type ClientQuickLinkProps = {
  icon: string;
  label: string;
  count?: number;
  to?: string;
  onClick?: () => void;
};

const BoxFullFlexStyled = styled(Box)`
  flex: 1 1 auto;
`;

const IconContainer = styled.div`
  flex: 0 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ClientQuickLink: React.FC<ClientQuickLinkProps> = ({
  icon,
  label,
  count = 0,
  to,
  onClick,
}) => {
  return (
    <Box backgroundColor="grey5" borderRadius={1} px={6} py={4} spaceAfter={2}>
      <Flex gap={2}>
        <IconContainer>
          <NotificationTypeIcon icon={icon} isViewed={true} />
        </IconContainer>
        <BoxFullFlexStyled>
          <Text color="grey80" type="label">
            {label} ({count})
          </Text>
        </BoxFullFlexStyled>
        <Link target="_blank" to={to} onClick={onClick}>
          Bekijken
        </Link>
      </Flex>
    </Box>
  );
};
