import type { SpaceProp } from '@elseu/sdu-titan';
import { Badge, Tag } from '@elseu/sdu-titan';
import type { StatusColors } from '@elseu/sdu-titan/dist/types/types/status-colors';
import type { Ticket } from 'entity/dossiers/types';
import React from 'react';

interface TicketStatusTagProps extends Pick<Ticket, 'status'> {
  /** The margin bottom of the component */
  spaceAfter?: SpaceProp;
}
export const TicketStatusTag = ({ status, spaceAfter }: TicketStatusTagProps) => {
  if (!status) return null;
  return (
    <Tag
      prefixAdornment={
        <Badge size="xs" status={(status.color || 'default') as StatusColors} variant="medium" />
      }
      spaceAfter={spaceAfter}
      status="default"
    >
      {status.name}
    </Tag>
  );
};
