import Card from 'models/dossier/Card';
import { useEffect, useMemo, useState } from 'react';
import {
  CARD_ADVICE_ID,
  CARD_ANALYSE_ID,
  CARD_EXECUTION_ID,
  CARD_INVENTORY_ID,
  STATE_CARDS_SELECT,
  useCardState,
} from 'stores/dossier/CardState';
import { sweetFetchStateForContextID } from 'stores/utils/SweetFetchState';
export { CARD_ANALYSE_ID } from 'stores/dossier/CardState';
export { CARD_INVENTORY_ID } from 'stores/dossier/CardState';
export { CARD_ADVICE_ID } from 'stores/dossier/CardState';
export { CARD_EXECUTION_ID } from 'stores/dossier/CardState';

const phaseCardIs = [CARD_ANALYSE_ID, CARD_INVENTORY_ID, CARD_ADVICE_ID, CARD_EXECUTION_ID];

/**
 * Get details of card in hook
 *
 * @param {string} dossierId
 * @param {string} cardId
 * @param {string} section
 * @returns {{doRequest, isBusy, card, isSuccess }}
 */
export const useCardDetails = (dossierId, cardId, section = 'results') => {
  const defaultCardValues = useMemo(
    () => ({
      dossierId,
      section,
      title: '',
      color: Card.getColorBySectionName(section),
      subtitle: Card.getSubtitleBySectionName(section),
    }),
    [dossierId, section],
  );

  const [cardStore, cardActions] = useCardState();
  const [card, setCard] = useState(Card.createInstance(defaultCardValues));
  const hookCardId = phaseCardIs.includes(cardId) ? `${dossierId}_${cardId}` : cardId;
  const cardFetchState = sweetFetchStateForContextID(cardStore[STATE_CARDS_SELECT], hookCardId);
  const isBusy = cardFetchState.isBusy;
  const isSuccess = cardFetchState.isSuccess;
  const selectingContext = cardFetchState.context;

  const doRequest = () => {
    cardActions.getCardByCardId(dossierId, cardId);
  };
  useEffect(doRequest, [cardId, dossierId, cardActions]);
  useEffect(
    () => setCard(selectingContext || Card.createInstance(defaultCardValues)),
    [selectingContext, defaultCardValues],
  );

  return { card, isBusy, isSuccess, doRequest };
};
