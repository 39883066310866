import DataModel, { PropTypes as DataPropTypes } from 'models/base/DataModel';

/**
 * Address
 */
export default class Address extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    street: DataPropTypes.string,
    number: DataPropTypes.string,
    addition: DataPropTypes.string,
    zipcode: DataPropTypes.string,
    place: DataPropTypes.string,
    country: DataPropTypes.string,
  };

  /**
   * @type {string|undefined|null}
   */
  street;

  /**
   * @type {string|undefined|null}
   */
  number;

  /**
   * @type {string|undefined|null}
   */
  addition;

  /**
   * @type {string}
   */
  zipcode;

  /**
   * @type {string}
   */
  place;

  /**
   * @type {string}
   */
  country;

  getStreet() {
    return this.street;
  }

  /**
   * Get full address.
   *
   * @return {string}
   */
  getFullStreetAddress() {
    return [this.street, this.number, this.addition]
      .filter((item) => !(item === undefined || item === null))
      .join(' ')
      .trim();
  }

  /**
   * @return {string}
   */
  getNumber() {
    return this.number;
  }

  /**
   * @return {string}
   */
  getAddition() {
    return this.addition;
  }

  /**
   * @return {string}
   */
  getZipcode() {
    return this.zipcode;
  }

  /**
   * @return {string}
   */
  getPlace() {
    return this.place;
  }

  /**
   * @return {string}
   */
  getCountry() {
    return this.country;
  }
}
