import './assets/styles/Collapsible.scss';

import { ChevronDownIcon, ChevronRightIcon } from '@elseu/sdu-titan';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

type CollapsibleProps = React.PropsWithChildren<{
  className?: string;
  isCollapsed?: boolean;
  collapseButtonPosition?: 'start' | 'endline';
  collapseAnimation?: 'up-down' | 'right-down';
  showText?: string;
  hideText?: string;
  onOpen?: () => void;
  onClose?: () => void;
  onToggle?: (open: boolean) => void;
}>;

/**
 * Collapsible component
 *
 * @param {string} className
 * @param {boolean} isCollapsed
 * @param {string} showText
 * @param {string} hideText
 * @param {string} collapseButtonPosition
 * @param {string} collapseAnimation
 * @param {node} children
 * @param {function} onToggle
 * @param {function} onOpen
 * @param {function} onClose
 * @returns {*}
 * @constructor
 */
const Collapsible: React.FC<CollapsibleProps> = ({
  className,
  isCollapsed = true,
  showText = 'Hide',
  hideText = 'Show',
  collapseButtonPosition = 'endline',
  collapseAnimation = 'up-down',
  onToggle = () => null,
  onOpen = () => null,
  onClose = () => null,
  children,
}) => {
  const [collapsedState, setCollapsedState] = useState<boolean>(isCollapsed);
  const [animate, setAnimate] = useState<boolean>(false);

  /**
   * Handle collapsed state change
   *
   * @param {boolean} nextCollapsedState
   */
  const collapseEventHandle = (nextCollapsedState: boolean) => {
    setCollapsedState(nextCollapsedState);
    setAnimate(true);

    // pass through the next collapsed state and call our collapse handler.
    onToggle(nextCollapsedState);
    if (nextCollapsedState) {
      onClose();
    } else {
      onOpen();
    }
  };

  // check collapsed prop
  useEffect(() => {
    if (isCollapsed !== collapsedState) {
      collapseEventHandle(isCollapsed);
    }
    // eslint-disable-next-line
  }, [isCollapsed]);

  const ChevronIcon = collapseAnimation === 'up-down' ? ChevronDownIcon : ChevronRightIcon;

  return (
    <div
      className={classNames('collapsible', className, {
        collapsible__show: !collapsedState,
        collapsible__hide: collapsedState,
        collapsible__animate: animate,
        'collapsible__animate--up-down': collapseAnimation === 'up-down',
        'collapsible__animate--right-down': collapseAnimation === 'right-down',
      })}
    >
      <div className="collapsible__title">
        <div
          aria-hidden="true"
          className="collapsible__title__button"
          role="button"
          tabIndex={0}
          onClick={() => collapseEventHandle(!collapsedState)}
        >
          {collapseButtonPosition === 'start' && (
            <div className="collapsible__title__arrow collapsible__title__arrow--start">
              <ChevronIcon />
            </div>
          )}
          <div className="collapsible__title__text">
            {!collapsedState ? showText : hideText}
            {collapseButtonPosition === 'endline' && (
              <span className="collapsible__title__arrow collapsible__title__arrow--endline">
                <ChevronIcon />
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="collapsible__content__holder">
        <div className="collapsible__content">{children}</div>
      </div>
    </div>
  );
};

export default Collapsible;
