import type { TagDrawerListProps } from 'components/tagdrawerlist/TagDrawerList';
import TagDrawerList from 'components/tagdrawerlist/TagDrawerList';
import React, { useCallback, useState } from 'react';

import { ClientQuickLookDrawer } from './quicklook/ClientQuickLookDrawer';

export type QuickLookClientItem = {
  id: string;
  name: string;
};

type Props = Omit<TagDrawerListProps, 'items'> & {
  items: QuickLookClientItem[];
};

export const ClientQuickLookMultiple: React.FC<Props> = ({ items, ...tagProps }) => {
  const [quickLookClientId, setQuickLookClientId] = useState<string | undefined>(undefined);
  const [isQuickLookShown, setIsQuicklookShown] = useState<boolean>(false);

  const openQuickLook = useCallback((item: QuickLookClientItem) => {
    setQuickLookClientId(item.id);
    setIsQuicklookShown(true);
  }, []);

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <TagDrawerList
        {...tagProps}
        items={items.map((item) => ({
          key: item.id,
          name: item.name,
          onClick: () => openQuickLook(item),
        }))}
      />
      {quickLookClientId && (
        <ClientQuickLookDrawer
          clientId={quickLookClientId}
          isShown={isQuickLookShown}
          onClose={() => setIsQuicklookShown(false)}
        />
      )}
    </>
  );
};
