import { ActionMenu, Avatar, Flex, Link, MenuItem } from '@elseu/sdu-titan';
import CheckBox from 'components/checkbox/CheckBox';
import type { User } from 'entity/system/types';
import React from 'react';
import styled from 'styled-components';

import TaskDeadlineDate from './TaskDeadlineDate';

type TaskListItemDisplayProps = {
  isDisabled?: boolean;
  name?: string;
  deadlineDate?: string;
  assignedUser?: User | null;
  completedDateTime?: string;
  isReadOnlyClickable?: boolean;
  isReadOnlyAllowCheckbox?: boolean;
  onCheckboxChange?: (checked: boolean) => void;
  onTitleClick?: () => void;
  onDelete?: () => void;
};

const StyledCheckBox = styled(CheckBox)`
  width: 24px;
  height: 24px;
`;

const TaskListItemDisplay: React.FC<TaskListItemDisplayProps> = ({
  isDisabled = false,
  name,
  deadlineDate,
  assignedUser,
  completedDateTime,
  isReadOnlyClickable,
  isReadOnlyAllowCheckbox = false,
  onCheckboxChange = () => null,
  onTitleClick = () => null,
  onDelete,
}) => {
  return (
    <Flex alignItems="start" flex={1} gap={2} justifyContent="space-between">
      <Flex gap={3}>
        <StyledCheckBox
          isChecked={!!completedDateTime}
          isDisabled={isDisabled}
          onChange={(value) => {
            if (isReadOnlyAllowCheckbox) {
              onCheckboxChange(value);
            }
          }}
        />
        <Flex flex={1} flexDirection="column" gap={1}>
          {/* TODO: strikethrough */}
          <Link
            disabled={!isReadOnlyClickable}
            onClick={() => {
              if (isReadOnlyClickable) {
                onTitleClick();
              }
            }}
          >
            {name}
          </Link>
          {deadlineDate && (
            <TaskDeadlineDate
              date={deadlineDate}
              format="D MMMM YYYY"
              renderAs="text"
              spaceAfter={1}
            />
          )}
        </Flex>
      </Flex>

      <Flex gap={2}>
        {!!assignedUser && <Avatar size={20} src={assignedUser.avatarUrl} />}
        {onDelete && (
          <ActionMenu
            defaultShown={false}
            label="Taak acties"
            popoverOptions={{
              strategy: 'fixed',
            }}
            popoverPlacement="bottom-end"
          >
            <MenuItem
              item={{
                label: 'Verwijderen',
              }}
              onClick={() => onDelete()}
            />
          </ActionMenu>
        )}
      </Flex>
    </Flex>
  );
};

export default TaskListItemDisplay;
