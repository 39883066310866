import './assets/styles/Radio.scss';

import classNames from 'classnames';
import React from 'react';
import { Radio as ReactRadio } from 'react-radio-group';

type Props = React.PropsWithChildren<{
  id?: string;
  isDisabled?: boolean;
  value?: any;
}>;

/**
 * Radio item
 */
const Radio: React.FC<Props> = ({ id, isDisabled, value, children }) => (
  <label className={classNames('c-radio radio', { 'radio-disabled': isDisabled })}>
    {/* @ts-ignore (supresses 'ReactRadio' cannot be used as a JSX component.) */}
    <ReactRadio disabled={isDisabled} id={id} value={value} />
    {children}
  </label>
);

export default Radio;
