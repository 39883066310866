import type { UniqueIdentifier } from '@dnd-kit/core';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { TableEmptyState, useTable, withTitan } from '@elseu/sdu-titan';
import type { Row } from '@tanstack/react-table';
import type { KanbanProps } from 'containers/tickets/src/hooks/useKanban';
import { KanbanContext } from 'containers/tickets/src/hooks/useKanban';
import { useTicketStatuses } from 'containers/tickets/src/hooks/useTicketStatuses';
import type { Ticket } from 'entity/dossiers/types';
import type { ContainersWithItems } from 'hooks/dnd-kit/useDnDMultipleContainers';
import { useDnDMultipleContainers } from 'hooks/dnd-kit/useDnDMultipleContainers';
import React, { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { KanbanBoard } from './KanbanBoard';
import { KanbanCard } from './KanbanCard';
import type { KanbanColumnProps } from './KanbanColumn';

function mapRowsToItems(columns: KanbanColumnProps[], rows: Array<Row<Ticket>>) {
  return columns.reduce((containers, column, index) => {
    const items = rows.filter((row) => {
      if (!row.original.status && index === 0) return true;
      return row.original.status?.statusGroup?.id === column.id;
    });
    containers[column.id] = items;

    return containers;
  }, {} as ContainersWithItems<Row<Ticket>>);
}

export const getDragOverItem = (id: UniqueIdentifier, items: any[]) => {
  const activeIndex = items.findIndex((item) => item.id === id);
  const nextItem = items.length > activeIndex + 1 && items[activeIndex + 1];
  const previousItem = activeIndex > 0 && items[activeIndex - 1];

  if (nextItem || previousItem) {
    return {
      ...(nextItem || previousItem),
      placement: nextItem ? 'before' : 'after',
    };
  }

  return null;
};

export const Kanban = withTitan<KanbanProps>(
  ({
    emptyStateProps,
    onCardClick,
    onTicketUpdate,
    onTicketCreate,
    columns,
    renderCardContent,
  }) => {
    const { instance, isLoading } = useTable();
    const { getFirstStatusFromStatusGroup, getStatusesFor } = useTicketStatuses();

    const { getRowModel } = instance;
    const rows = getRowModel().rows;

    const { items, setItems, activeId, isDragging, ...props } = useDnDMultipleContainers({
      items: mapRowsToItems(columns, rows),
      onChange: ({ containerId, activeId, items }) => {
        const currentTicket = rows.find((row) => row.original.id === activeId)?.original;
        const dragOverItem = getDragOverItem(activeId, items[containerId]);
        const statusesOfContainer = getStatusesFor(currentTicket?.type, containerId as string);
        const hasSwitchedContainer = !statusesOfContainer.find(
          (status) => status.id === currentTicket?.status?.id,
        );
        const status = getFirstStatusFromStatusGroup(containerId as string);
        onTicketUpdate({
          ticketId: activeId,
          otherId: dragOverItem?.id,
          placement: dragOverItem?.placement,
          ...(hasSwitchedContainer && { statusId: status?.id }),
        });
      },
    });

    useEffect(() => {
      setItems(mapRowsToItems(columns, rows));
    }, [rows, columns, setItems]);

    const renderSortableItemDragOverlay = useCallback(
      (id: UniqueIdentifier) => {
        const row = rows.find((row) => row.id === id);

        if (!row) {
          return null;
        }

        return <KanbanCard row={row} />;
      },
      [rows],
    );

    if (!isLoading && !rows.length && emptyStateProps) {
      return <TableEmptyState {...emptyStateProps} />;
    }

    return (
      <KanbanContext.Provider
        value={{
          isDragging,
          onCardClick,
          renderCardContent,
          onTicketCreate,
          ...props,
        }}
      >
        <DndContext {...props}>
          <KanbanBoard columns={columns} items={items} />
          {createPortal(
            <DragOverlay>{activeId ? renderSortableItemDragOverlay(activeId) : null}</DragOverlay>,
            document.body,
          )}
        </DndContext>
      </KanbanContext.Provider>
    );
  },
  { name: 'Kanban' },
);
