import { Tag } from '@elseu/sdu-titan';
import React from 'react';

type Props = {
  classification: string;
};

export const ClientClassificationTag: React.FC<Props> = ({ classification }) => {
  return (
    <Tag status={classification === 'private' ? 'information' : 'custom1'}>
      {classification === 'private' ? 'Natuurlijk persoon' : 'Rechtspersoon'}
    </Tag>
  );
};
