import './assets/styles/FileUploader.scss';

import type { MenuItemProps } from '@elseu/sdu-titan';
import { ActionMenu, Clickable, MenuDivider, MenuItem, UploadIcon } from '@elseu/sdu-titan';
import type { ActionMenuProps } from '@elseu/sdu-titan/dist/types/components/ActionMenu/ActionMenu';
import classNames from 'classnames';
import { filesize } from 'filesize';
import React, { useCallback } from 'react';
import type { Accept, FileError, FileRejection } from 'react-dropzone';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { v1 as uuidv1 } from 'uuid';

import FileUploaderStatus from './FileUploaderStatus';

// activate file uploader trought action menu
export const FileUploaderMenuValue = '!FILEUPLOADER!';

export type FileUploaderItem = {
  id: string;
  file: File;
  hasError: boolean;
  errorMessage?: string;
};

export const errorCodeToHumanString = (errorCode: FileError, maxSize: number) => {
  const maxFileSizeHumanReadable = filesize(maxSize, { round: 0 });

  switch (errorCode.code) {
    case ErrorCode.FileTooSmall:
      return 'Dit bestand is te klein.';
    case ErrorCode.FileTooLarge:
      return `Dit bestand is te groot, het bestand mag niet groter zijn dan ${maxFileSizeHumanReadable}.`;
    case ErrorCode.FileInvalidType:
      return 'Dit is een ongeldig bestandstype.';
    case ErrorCode.TooManyFiles:
      return 'Teveel bestanden gekozen';
    default:
      return `Onbekende fout (${errorCode.code}: ${errorCode.message})`;
  }
};
export type ActionMenuItem = MenuItemProps | '-';

export type FileUploaderProps = {
  isUploading?: boolean;
  uploadingMax?: number;
  uploadingIndex?: number;
  isMultiple?: boolean;
  /**
   * Look for more information here:
   * https://react-dropzone.js.org/#src and
   * https://developer.mozilla.org/en-US/docs/Web/API/window/showOpenFilePicker
   */
  accept?: Accept;
  maxFiles?: number;
  hasError?: boolean;
  errorMessage?: string;
  isDisabled?: boolean;
  actionMenuItems?: ActionMenuItem[];
  actionMenuPopoverWidth?: ActionMenuProps['popoverWidth'];
  maxFileSize?: number;
  minFileSize?: number;
  onFilesChange: (files: FileUploaderItem[]) => void;
  uploadMaxDescription?: string;
};

const ActionMenuTarget: React.FC = () => (
  <div className="c-file-uploader__uploader__title">Bestanden toevoegen</div>
);

const FileUploader: React.FC<FileUploaderProps> = ({
  isMultiple = true,
  isUploading = false,
  isDisabled,
  uploadingMax = 1,
  uploadingIndex = 1,
  actionMenuItems = [],
  actionMenuPopoverWidth,
  maxFileSize = 60000000,
  maxFiles = 0,
  minFileSize = 1,
  accept,
  uploadMaxDescription,
  onFilesChange,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      const acceptRetValue: FileUploaderItem[] = acceptedFiles.map((file) => ({
        id: uuidv1(),
        file,
        hasError: false,
      }));
      const errorRetValue: FileUploaderItem[] = rejectedFiles.map((file) => ({
        id: uuidv1(),
        file: file.file,
        hasError: true,
        errorMessage: file.errors
          .map((error) => errorCodeToHumanString(error, maxFileSize))
          .join(', '),
      }));

      onFilesChange([...acceptRetValue, ...errorRetValue]);
    },
    [maxFileSize, onFilesChange],
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    maxFiles,
    disabled: isDisabled,
    accept,
    multiple: isMultiple,
    noClick: actionMenuItems.length > 0,
    noKeyboard: actionMenuItems.length > 0,
    maxSize: maxFileSize,
    minSize: minFileSize,
  });

  return (
    <div
      className={classNames('c-file-uploader', {
        'c-file-uploader__disabled': isDisabled,
      })}
    >
      {isUploading && (
        <FileUploaderStatus uploadingIndex={uploadingIndex} uploadingMax={uploadingMax} />
      )}
      {!isUploading && (
        <div
          {...getRootProps({
            className: classNames('c-file-uploader__uploader', {
              'c-file-uploader__uploader--is-dragging': isDragActive,
              'c-file-uploader__uploader--not-dragging': !isDragActive,
            }),
          })}
        >
          <input {...getInputProps()} />
          <div className="c-file-uploader__uploader__default">
            <div className="c-file-uploader__uploader__icon">
              <UploadIcon />
            </div>
            <div>
              {actionMenuItems.length > 0 ? (
                <ActionMenu
                  defaultShown={false} // temp-fix to get ActionMenu working
                  label="Open menu"
                  popoverOptions={{
                    strategy: 'fixed',
                  }}
                  popoverPlacement="bottom-start"
                  popoverWidth={actionMenuPopoverWidth}
                  trigger={
                    <Clickable>
                      <ActionMenuTarget />
                    </Clickable>
                  }
                >
                  {actionMenuItems.map((item, index) => {
                    if (item === '-') {
                      // eslint-disable-next-line react/no-array-index-key
                      return <MenuDivider key={`divider${index}`} />;
                    }
                    const menuProps =
                      item.item.value === FileUploaderMenuValue
                        ? {
                            ...item,
                            onClick: () => open(),
                          }
                        : item;

                    return (
                      <MenuItem key={menuProps.item.value} isDisabled={isDisabled} {...menuProps} />
                    );
                  })}
                </ActionMenu>
              ) : (
                <ActionMenuTarget />
              )}
              <div className="c-file-uploader__uploader__description">
                Of sleep uw bestanden hier naartoe om te uploaden {uploadMaxDescription}
              </div>
            </div>
          </div>
          <div className="c-file-uploader__uploader__drag">
            <div className="c-file-uploader__uploader__text">
              Sleep uw bestanden hier naartoe om te uploaden {uploadMaxDescription}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploader;
