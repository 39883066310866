import type { FilterItemProp } from '@elseu/sdu-titan';
import { Box, CheckboxTree, DatePicker } from '@elseu/sdu-titan';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const CUSTOM_DATE_VALUE = '!custom';
const DATE_REGEXP = /(\d{4}-\d{2}-\d{2})_(\d{4}-\d{2}-\d{2})/;

const Container = styled(Box)`
  overflow-y: auto;
  max-height: calc(52px * 5);
`;

type FilterDateTimeItem = {
  value: string;
  label: string;
};

type Props = FilterItemProp<string | undefined> & {
  items: FilterDateTimeItem[];
};

const FilterDateTime: React.FC<Props> = ({ value, items, onChange }) => {
  const [showDatePickers, setShowDatePickers] = useState<boolean>(value === CUSTOM_DATE_VALUE);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const valueMemo = useMemo(() => {
    if (showDatePickers) {
      return CUSTOM_DATE_VALUE;
    }

    if (value && DATE_REGEXP.test(value)) {
      return CUSTOM_DATE_VALUE;
    }

    return value;
  }, [showDatePickers, value]);

  useEffect(() => {
    if (value && DATE_REGEXP.test(value)) {
      // extract the dates and set them
      const matches = DATE_REGEXP.exec(value);
      if (matches !== null) {
        setStartDate(dayjs(matches[1]).toDate());
        setEndDate(dayjs(matches[2]).toDate());

        setShowDatePickers(true);
      }
    }
  }, [value]);

  useEffect(() => {
    if (!showDatePickers && !DATE_REGEXP.exec(value ?? '')) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [showDatePickers, value]);

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      const startDateValue = dayjs(startDate).format('YYYY-MM-DD');
      const endDateValue = dayjs(endDate).format('YYYY-MM-DD');

      const retValue = `${startDateValue}_${endDateValue}`;
      if (retValue !== value) {
        onChange(retValue);
      }
    }
  }, [startDate, endDate, onChange, value]);

  return (
    <Container>
      <CheckboxTree
        data={items}
        value={valueMemo ? [valueMemo] : undefined}
        onChange={(values) => {
          const value = values.length > 0 ? values[0] : undefined;
          if (value !== '!custom') {
            setStartDate(null);
            setEndDate(null);
            onChange(value);
          }
          setShowDatePickers(value === CUSTOM_DATE_VALUE);
        }}
      />
      {showDatePickers && (
        <Box>
          <DatePicker
            label="Van"
            maxDate={endDate !== null ? endDate : undefined}
            name="startDate"
            selectedDate={startDate}
            spaceAfter={4}
            onChange={setStartDate}
          />
          <DatePicker
            label="Tot"
            minDate={startDate !== null ? startDate : undefined}
            name="endDate"
            selectedDate={endDate}
            onChange={setEndDate}
          />
        </Box>
      )}
    </Container>
  );
};

export default FilterDateTime;
