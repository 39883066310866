import type { CrmEditFieldType } from '../types';
import * as InputRenders from './InputRenders';
import * as ViewRenders from './ViewRenders';

export const ClientFieldsForBusiness: CrmEditFieldType[] = [
  {
    name: 'showClientId',
    label: 'Klantnummer',
    renderEdit: InputRenders.textFieldRender,
  },
  {
    name: 'name',
    label: 'Statutaire naam',
    renderEdit: InputRenders.textFieldRender,
    renderView: ViewRenders.textFieldRender,
  },
  {
    name: 'kvkNumber',
    label: 'KVK nummer',
    renderEdit: InputRenders.textFieldRender,
    renderView: ViewRenders.textFieldRender,
  },
  {
    name: 'subOffice',
    label: 'Vestiging',
    renderEdit: InputRenders.subOfficeFieldRender,
    renderView: ViewRenders.subOfficeFieldRender,
  },
  {
    name: 'fiscalNumber',
    label: 'RSIN',
    renderEdit: InputRenders.textFieldRender,
    renderView: ViewRenders.textFieldRender,
  },
  {
    name: 'fiscalNumberOb',
    label: 'BTW nummer',
    renderEdit: InputRenders.textFieldRender,
    renderView: ViewRenders.textFieldRender,
  },
  {
    name: 'sbiCode',
    label: 'SBI code',
    renderEdit: InputRenders.textFieldRender,
    renderView: ViewRenders.textFieldRender,
  },
  {
    name: 'fiscalNumberLh',
    label: 'LH nummer',
    renderEdit: InputRenders.textFieldRender,
    renderView: ViewRenders.textFieldRender,
  },
  {
    name: 'primaryClientUser',
    label: 'Primair contactpersoon',
    renderView: ViewRenders.clientUserRender,
  },
  {
    name: 'contactPersons',
    label: 'Cliëntverantwoordelijke(n)',
    renderView: ViewRenders.contactPersonsRender,
  },
];

export const ClientFieldsForPrivate: CrmEditFieldType[] = [
  {
    name: 'showClientId',
    label: 'Klantnummer',
    renderEdit: InputRenders.textFieldRender,
  },
  {
    name: 'name',
    label: 'Voornamen',
    renderEdit: InputRenders.textFieldRender,
    renderView: ViewRenders.textFieldRender,
  },
  {
    name: 'initials',
    label: 'Initialen',
    renderEdit: InputRenders.textFieldRender,
    renderView: ViewRenders.textFieldRender,
  },
  {
    name: 'infix',
    label: 'Tussenvoegsel',
    renderEdit: InputRenders.textFieldRender,
    renderView: ViewRenders.textFieldRender,
  },
  {
    name: 'surname',
    label: 'Achternaam',
    renderEdit: InputRenders.textFieldRender,
    renderView: ViewRenders.textFieldRender,
  },
  {
    name: 'gender',
    label: 'Geslacht',
    renderEdit: InputRenders.radioGroupFieldRender,
    renderView: ViewRenders.radioGroupFieldRender,
    renderOptions: {
      variant: 'horizontal-start',
      options: [
        {
          value: 'male',
          label: 'Man',
        },
        {
          value: 'female',
          label: 'Vrouw',
        },
      ],
    },
  },
  {
    name: 'subOffice',
    label: 'Vestiging',
    renderEdit: InputRenders.subOfficeFieldRender,
    renderView: ViewRenders.subOfficeFieldRender,
  },
  {
    name: 'primaryClientUser',
    label: 'Primair contactpersoon',
    renderView: ViewRenders.clientUserRender,
  },
  {
    name: 'contactPersons',
    label: 'Cliëntverantwoordelijke(n)',
    renderView: ViewRenders.contactPersonsRender,
  },
];

export const ClientFieldsContact: CrmEditFieldType[] = [
  {
    name: 'email',
    label: 'E-mail',
    renderEdit: InputRenders.textFieldRender,
    renderView: ViewRenders.emailFieldRender,
  },
  {
    name: 'phone',
    label: 'Telefoon',
    renderEdit: InputRenders.textFieldRender,
    renderView: ViewRenders.phoneFieldRender,
  },
  {
    name: 'fax',
    label: 'Fax',
    renderEdit: InputRenders.textFieldRender,
    renderView: ViewRenders.phoneFieldRender,
  },
];

export const ClientFieldQuickForBusiness: CrmEditFieldType[] = [
  {
    name: 'kvkNumber',
    label: 'KVK nummer',
    renderView: ViewRenders.textFieldRender,
  },
  {
    name: 'sbiCode',
    label: 'SBI code',
    renderView: ViewRenders.textFieldRender,
  },
  {
    name: 'fiscalNumber',
    label: 'RSIN',
    renderView: ViewRenders.textFieldRender,
  },
  {
    name: 'fiscalNumberOb',
    label: 'BTW nummer',
    renderView: ViewRenders.textFieldRender,
  },
  {
    name: 'subOffice',
    label: 'Vestiging',
    renderView: ViewRenders.subOfficeFieldRender,
  },
  {
    name: 'physicalAddress',
    label: 'Fysiek adres',
    renderView: ViewRenders.addressRender,
  },
];

export const ClientFieldQuickForBusinessFooter: CrmEditFieldType[] = [
  {
    name: 'primaryClientUser',
    label: 'Primair contactpersoon',
    renderView: ViewRenders.clientUserRender,
  },
  {
    name: 'contactPersons',
    label: 'Cliëntverantwoordelijke(n)',
    renderView: ViewRenders.contactPersonsRender,
  },
];

export const ClientFieldQuickForPrivate: CrmEditFieldType[] = [
  {
    name: 'email',
    label: 'E-mail',
    renderView: ViewRenders.emailFieldRender,
  },
  {
    name: 'phone',
    label: 'Telefoon',
    renderView: ViewRenders.phoneFieldRender,
  },
  {
    name: 'subOffice',
    label: 'Vestiging',
    renderView: ViewRenders.subOfficeFieldRender,
  },
  {
    name: 'gender',
    label: 'Geslacht',
    renderView: ViewRenders.radioGroupFieldRender,
    renderOptions: {
      variant: 'horizontal-start',
      options: [
        {
          value: 'male',
          label: 'Man',
        },
        {
          value: 'female',
          label: 'Vrouw',
        },
      ],
    },
  },
  {
    name: 'physicalAddress',
    label: 'Fysiek adres',
    renderView: ViewRenders.addressRender,
  },
];

export const ClientFieldQuickForPrivateFooter: CrmEditFieldType[] = [
  {
    name: 'contactPersons',
    label: 'Cliëntverantwoordelijke(n)',
    renderView: ViewRenders.contactPersonsRender,
  },
];
