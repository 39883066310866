import {
  Box,
  Divider,
  Dropdown,
  ListBox,
  ListBoxItemCheckmark,
  ListBoxItemDefault,
  Text,
} from '@elseu/sdu-titan';
import React from 'react';

import { IPermissionRole } from './helpers';

const options = [
  {
    label: 'Kan bekijken',
    sublabel: 'Gebruikers kunnen bekijken en downloaden',
    value: IPermissionRole.ROLE_VIEWER,
  },
  {
    label: 'Kan bewerken',
    sublabel: 'Gebruikers kunnen bewerken, verwijderen en bestanden toevoegen',
    value: IPermissionRole.ROLE_EDITOR,
  },
  {
    label: 'Eigenaar',
    sublabel: 'Volledige toegang',
    value: IPermissionRole.ROLE_OWNER,
  },
];

export interface IPermissionRoleSelect {
  isDisabled: boolean;
  isSingleAdmin?: boolean;
  role: IPermissionRole;
  disabledRoles?: IPermissionRole[];
  onChange: (role: IPermissionRole) => void;
  onRemove?: () => void;
}

export const PermissionRoleSelect = ({
  isDisabled = false,
  isSingleAdmin = false,
  disabledRoles = [],
  role,
  onChange,
  onRemove,
}: IPermissionRoleSelect) => (
  <>
    <Dropdown
      buttonSize="s"
      buttonText={options.filter((option) => option.value === role)[0].label}
      defaultIsShown={false}
      isDisabled={isDisabled}
      popoverPlacement="bottom-end"
      popoverWidth={428}
      popoverZIndex={1000}
    >
      {isSingleAdmin ? (
        <Box px={4} py={4}>
          Stel eerst beheerrechten in voor een andere gebruiker voordat je deze gebruiker
          verwijdert.
        </Box>
      ) : (
        <>
          <ListBox
            isRequired
            items={options}
            label=""
            spaceBetween={0}
            values={[role]}
            onSelect={(value) => onChange(value.value as IPermissionRole)}
          >
            {options
              .filter((item) => !disabledRoles.includes(item.value))
              .map((item) => (
                <ListBoxItemCheckmark key={item.value} item={item}>
                  <Text isBlock color="grey80" spaceAfter={1} type="labelBold">
                    {item.label}
                  </Text>
                  <Text isBlock color="grey70" type="label">
                    {item.sublabel}
                  </Text>
                </ListBoxItemCheckmark>
              ))}
          </ListBox>

          {onRemove && <Divider spaceAfter={4} spaceBefore={4} />}

          {onRemove && (
            <ListBox items={[{ value: 'none', label: '' }]} label="" onSelect={() => onRemove()}>
              <ListBoxItemDefault item={{ value: 'none', label: '' }}>
                <Box pl={4}>
                  <Text color="secondary40" type="label">
                    Toegang verwijderen
                  </Text>
                </Box>
              </ListBoxItemDefault>
            </ListBox>
          )}
        </>
      )}
    </Dropdown>
  </>
);
