import * as Sentry from '@sentry/react';
import type { PropsWithChildren } from 'react';
import React from 'react';

import type { Ticket } from '../../../entity/dossiers/types';

type TicketErrorBoundaryProps = {
  ticket: Partial<Ticket>;
};

export const TicketErrorBoundary = ({
  children,
  ticket,
}: PropsWithChildren<TicketErrorBoundaryProps>) => {
  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setContext('ticket', {
          id: ticket.id,
          name: ticket.name,
        });
      }}
      fallback={<span>Error bij ophalen van data {ticket.id}</span>}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
