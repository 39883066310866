import { ActionMenu, Button, ChevronDownIcon, MenuItem } from '@elseu/sdu-titan';
import type { ActionMenuProps } from '@elseu/sdu-titan/dist/types/components/ActionMenu/ActionMenu';
import { ticketTypeObject } from 'constants/TicketTypeObject';
import type { Status, TicketType } from 'entity/dossiers/types';
import React, { useCallback, useState } from 'react';

import { TicketEditModal } from './TicketEditModal';

const ticketTypes = ['dossier', 'task', 'note_email', 'note_phone', 'note_conversation'];
const ticketTypesOptions = Object.values(ticketTypeObject)
  .filter((item) => ticketTypes.includes(item.id as TicketType))
  .map((item) => ({
    label: item.label,
    value: item.id,
    icon: item.icon,
  }));
interface CreateTicketMenuProps {
  trigger?: ActionMenuProps['trigger'];
  initialStatus?: Status;
  onCreate?: () => void;
}
const CreateTicketMenu: React.FC<CreateTicketMenuProps> = ({
  trigger = (
    <Button iconRight={<ChevronDownIcon />} size="S" variant="clear">
      Aanmaken
    </Button>
  ),
  initialStatus,
  onCreate,
}) => {
  const [ticketType, setTicketType] = useState<TicketType>();

  const handleCreate = useCallback(() => {
    onCreate?.();
  }, [onCreate]);

  return (
    <>
      <ActionMenu label="New" trigger={trigger}>
        {ticketTypesOptions.map((item) => (
          <MenuItem
            key={item.value}
            item={{
              label: item.label,
              value: item.value,
            }}
            prefixAdornment={item.icon}
            onClick={() => setTicketType(item.value)}
          />
        ))}
      </ActionMenu>
      <TicketEditModal
        initialStatus={initialStatus}
        isShown={!!ticketType}
        ticketType={ticketType}
        onClose={() => setTicketType(undefined)}
        onUpdate={handleCreate}
      />
    </>
  );
};

export default CreateTicketMenu;
