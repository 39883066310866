import ApplicationConstants from 'constants/ApplicationConstants';
import DataModel, { PropTypes as ModelPropTypes } from 'models/base/DataModel';
import Label from 'models/dossier/Label';
import Team from 'models/system/Team';

import Topic from '../dossier/Topic';
import ModelType from '../me/ModelType';
import User from '../me/User';

/**
 * Settings
 */
export default class Settings extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    topics: ModelPropTypes.arrayOf(ModelPropTypes.shape(Topic.propTypes)),
    modelTypes: ModelPropTypes.arrayOf(ModelPropTypes.shape(ModelType.propTypes)),
    ownSourceTypes: ModelPropTypes.arrayOf(ModelPropTypes.shape(ModelType.propTypes)),
    users: ModelPropTypes.arrayOf(ModelPropTypes.shape(User.propTypes)),
    notification: ModelPropTypes.shape({
      shouldSendNotifications: ModelPropTypes.bool,
      shouldSendDailyTasksNotifications: ModelPropTypes.bool,
      shouldSendWeeklyTasksNotifications: ModelPropTypes.bool,
    }),
    cloudLinkTypes: ModelPropTypes.arrayOf(
      ModelPropTypes.shape({
        id: ModelPropTypes.string,
        name: ModelPropTypes.string,
      }),
    ),
    crmManagedByPinkWeb: ModelPropTypes.bool,
    activePlugins: ModelPropTypes.arrayOf(ModelPropTypes.string),
    labels: ModelPropTypes.arrayOf(ModelPropTypes.shape(Label.propTypes)),
    systemTeams: ModelPropTypes.shape({
      everyone: ModelPropTypes.shape(Team.propTypes),
      dossier: ModelPropTypes.shape(Team.propTypes),
    }),
    dossierAddTeamsFromClients: ModelPropTypes.bool,
    brandingName: ModelPropTypes.string,
    brandingImage: ModelPropTypes.string,
  };

  /**
   * Topics
   * @type {Topic[]}
   */
  topics = [];

  /**
   * Users
   * @type {User[]}
   */
  users = [];

  /**
   * All available tool types.
   * @type {ModelType[]}
   */
  modelTypes = [];

  /**
   * All available legal source types.
   * @type {OwnSourceType[]}
   */
  ownSourceTypes = [];

  /**
   * Notification
   * @type {object}
   */
  notification;

  /**
   * Cloud link types
   *
   * @type {CloudLinkType[]}
   */
  cloudLinkTypes = [];

  /**
   * CRM setting element
   *
   * @type {boolean}
   */
  crmManagedByPinkWeb = false;

  /**
   * Plugins
   *
   * @type {string[]}
   */
  activePlugins = [];

  /**
   * @type {Label[]}
   */
  labels = [];

  /**
   * @type {{everyone: undefined}}
   */
  systemTeams = { everyone: undefined };

  /**
   * @type {boolean}
   */
  dossierAddTeamsFromClients = false;

  /**
   * @type {string}
   */
  brandingName;

  /**
   * @type {string}
   */
  brandingImage;

  /**
   * @return {Topic[]}
   */
  getTopics() {
    return this.topics;
  }

  /**
   * @return {User[]}
   */
  getUsers() {
    return this.users;
  }

  /**
   * @return {ModelType[]}
   */
  getModelTypes() {
    return this.modelTypes;
  }

  /**
   * @returns {OwnSourceType[]}
   */
  getOwnSourceTypes() {
    return this.ownSourceTypes;
  }

  /**
   * Retrieve all available cloud link types.
   *
   * @returns {CloudLinkType[]}
   */
  getCloudLinkTypes() {
    return this.cloudLinkTypes;
  }

  /**
   * Retrieve property crmManagedByPinkWeb
   *
   * @return {boolean}
   */
  getCrmManagedByPinkWeb() {
    return this.crmManagedByPinkWeb;
  }

  /**
   * Plugins property
   *
   * @returns {string[]}
   */
  getActivePlugins() {
    return this.activePlugins;
  }

  /**
   *
   * @param {string} pluginName
   * @return {boolean}
   */
  isPluginActive(pluginName) {
    return this.getActivePlugins().includes(pluginName);
  }

  /**
   * Get card labels

   * @returns {Label[]}
   */
  getLabels() {
    return this.labels;
  }

  /**
   * Get labels mapped for a phase select component
   *
   * @returns {{color: string, label: string, value: string}[]}
   */
  getLabelsForPhaseSelect() {
    return this.getLabels().map((label) => label.mapForPhaseSelect());
  }

  /**
   * Retrieve property crmManagedByPinkWeb
   *
   * @return {boolean}
   */
  isCrmManagedByPinkWeb() {
    return this.isPluginActive('pinkweb') || false;
  }

  /**
   * Whether the cloud links functionality is enabled.
   *
   * @return {boolean}
   */
  isCloudLinksEnabled() {
    return this.isPluginActive('onedrive') || false;
  }

  /**
   * Whether the sub office plugin is enabled.
   *
   * @return {boolean}
   */
  isSubOfficeEnabled() {
    return this.isPluginActive('subofficelink') || false;
  }

  /**
   * Whether the NDFR is enabled.
   *
   * @return {boolean}
   */
  isNdfrEnabled() {
    return this.isPluginActive('ndfr') || false;
  }

  /**
   * Whether the Visma XBTL functionality is enabled.
   *
   * @return {boolean}
   */
  isVismaXbrlEnabled() {
    return this.isPluginActive('vismaxbrl') || false;
  }

  /**
   * Returns the everyone system team
   *
   * @returns {Team|undefined}
   */
  getEveryoneTeam() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return this.systemTeams?.everyone ?? undefined;
  }

  /**
   * Returns the dossier team. If nothing is defined or found we will return the everyone team.
   *
   * @returns {Team|undefined}
   */
  getDossierTeam() {
    return this.hasDossierTeam() ? this.systemTeams.dossier : undefined;
  }

  /**
   * Returns if we have a system dossier team defined.
   * @returns {Boolean}
   */
  hasDossierTeam() {
    return this.systemTeams.dossier !== undefined;
  }

  /**
   * whether the dossier team select modal need te be shown when selecting a client
   *
   * @return {boolean}
   */
  isDossierAddTeamsFromClients() {
    return this.dossierAddTeamsFromClients;
  }

  /**
   * @return {string}
   */
  getBrandingName() {
    return this.brandingName;
  }

  /**
   * @return {string}
   */
  getBrandingImage() {
    return this.brandingImage;
  }

  /**
   * Note: make API to set this setting. But for now we use a environment variable.
   *
   * @return {boolean}
   */
  isCrmAllowedCreateNew() {
    return !ApplicationConstants.CRM_DISALLOW_NEW;
  }

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'topics') {
      value = Topic.createInstancesByArray(value);
    } else if (fieldName === 'users') {
      value = User.createInstancesByArray(value);
    } else if (
      fieldName === 'modelTypes' ||
      fieldName === 'ownSourceTypes' ||
      fieldName === 'cloudLinkTypes'
    ) {
      value = ModelType.createInstancesByArray(value);
    } else if (fieldName === 'labels') {
      value = Label.createInstancesByArray(value);
    } else if (fieldName === 'systemTeams') {
      value = {
        everyone: Team.createInstance(value.everyone),
        dossier: value.dossier ? Team.createInstance(value.dossier) : undefined,
      };
    }

    return super.set(fieldName, value);
  }
}
