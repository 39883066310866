import { Box, Flex, media, Modal, useSmallerThan } from '@elseu/sdu-titan';
import { CardSidebarTabProvider } from 'components/card/CardSidebarTabProvider';
import { ContactNoteEditController } from 'components/contactnote/ContactNoteEditController';
import { mapTicketTypeToSubType } from 'components/contactnote/helpers/mapSubType';
import { DossierEditController } from 'components/dossier/DossierEditController';
import { useLeaveConfirmCallback } from 'components/leaveprompt';
import { TaskEditController } from 'components/task/TaskEditController';
import { TaskSidebar } from 'components/task/TaskSidebar';
import { generateTicketUrl } from 'containers/tickets/src/utils/generateTicketUrl';
import type { Status, Ticket, TicketType } from 'entity/dossiers/types';
import { useGetCurrentUser, useProfileSettings } from 'hooks';
import { useTicketDetails } from 'hooks/queries/useTicketDetails';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { ContactNoteSidebar } from '../contactnote/ContactNoteSidebar';
import { createPermissions } from '../permission/helpers';
import { TicketTypeTag } from './TicketTypeTag';
export interface TicketEditModalProps {
  initialStatus?: Status;
  isShown: boolean;
  onClose: () => any;
  onUpdate?: () => any;
  ticket?: Ticket;
  ticketType?: TicketType;
}

const ModalContainer = styled(Flex)<{ fullHeight: boolean }>`
  ${({ fullHeight }) => fullHeight && `min-height: 80vh`};
`;

const ContentContainer = styled(Box)`
  flex: 1;
`;

const SidebarContainer = styled(Box)`
  width: 440px;
  ${media.lessThan('m')`
    width: 100%;
  `}
`;

const redirectTicketTypes = ['dossier', 'subdossier_document', 'subdossier_canvas'];

export const TicketEditModal = ({ isShown, onClose, ...props }: TicketEditModalProps) => {
  const { ticket, ticketType } = props;
  const methods = useForm();
  const { isDirty } = methods.formState;
  const navigate = useNavigate();

  // only confirm modal close when there are unsaved changes and the ticket
  // is not yet created
  const close = useLeaveConfirmCallback(isDirty && !ticket, onClose);

  if (ticket && ticketType && redirectTicketTypes.includes(ticketType)) {
    navigate(generateTicketUrl(ticket));
    return null;
  }

  return (
    <Modal isShown={isShown} size="xl" onClose={close}>
      {isShown && (
        <FormProvider {...methods}>
          <TicketEditModalContent onClose={close} {...props} />
        </FormProvider>
      )}
    </Modal>
  );
};

const TicketEditModalContent = ({
  ticket,
  ticketType,
  initialStatus,
  onUpdate,
  onClose,
}: Omit<TicketEditModalProps, 'isShown'>) => {
  const profile = useGetCurrentUser();
  const navigate = useNavigate();
  const [settings] = useProfileSettings();
  const [ticketId, setTicketId] = useState(ticket?.id);
  const [ticketSourceId, setTicketSourceId] = useState<string | undefined>();
  const { data: ticketData } = useTicketDetails(ticketId);
  const isSmallerThan = useSmallerThan('m');

  useEffect(() => {
    if (ticketData?.data) {
      setTicketId(ticketData.data.id);
      setTicketSourceId(ticketData.data.source.id);
    }
  }, [ticketData?.data]);

  if (!ticketType || (ticket && !ticketSourceId)) return null;

  const defaultValues = {
    assignedUser: profile.id,
    createdBy: profile.id,
    status: initialStatus?.id,
  };

  const ticketContentComponent = () => {
    switch (ticketType) {
      case 'task':
        return (
          <TaskEditController
            defaultValues={defaultValues}
            taskId={ticketSourceId}
            onSuccess={(task) => {
              setTicketSourceId(task.id);
              onUpdate?.();
            }}
          />
        );
      case 'note_conversation':
      case 'note_phone':
      case 'note_email':
        return (
          <ContactNoteEditController
            contactNoteId={ticketSourceId}
            defaultValues={{
              ...defaultValues,
              subType: mapTicketTypeToSubType(ticketType),
            }}
            onSuccess={(contactNote) => {
              setTicketSourceId(contactNote.id);
              onUpdate?.();
            }}
          />
        );
      case 'dossier':
        return (
          <DossierEditController
            defaultValues={{
              ...defaultValues,
              permissions: createPermissions(profile, settings),
            }}
            dossierId={ticketSourceId}
            onSuccess={(dossier) => {
              setTicketSourceId(dossier.id);
              navigate(`/beta/dossiers/${dossier.id}`);
              onUpdate?.();
            }}
          />
        );
      case 'subdossier_document':
      case 'subdossier_canvas':
      default:
        return <></>;
    }
  };

  const ticketSidebarComponent = () => {
    switch (ticketType) {
      case 'task':
        return (
          <CardSidebarTabProvider cardType="task" defaultActiveId="attachments">
            <TaskSidebar taskId={ticketSourceId} onClose={onClose} />
          </CardSidebarTabProvider>
        );
      case 'note_conversation':
      case 'note_phone':
      case 'note_email':
        return (
          <CardSidebarTabProvider cardType="contactNote" defaultActiveId="tasks">
            <ContactNoteSidebar contactNoteId={ticketSourceId} onClose={onClose} />
          </CardSidebarTabProvider>
        );
      case 'dossier':
      case 'subdossier_document':
      case 'subdossier_canvas':
      default:
        return null;
    }
  };

  const contentComponent = ticketContentComponent();
  const sidebarComponent = ticketSidebarComponent();

  return (
    <ModalContainer
      flexDirection={isSmallerThan ? 'column' : 'row'}
      fullHeight={!!ticketSourceId}
      gap={5}
    >
      <ContentContainer pl={10} pr={isSmallerThan || !sidebarComponent ? 10 : 0} py={10}>
        <TicketTypeTag spaceAfter={4} ticketType={ticketType} />
        {contentComponent}
      </ContentContainer>
      {sidebarComponent && (
        <SidebarContainer backgroundColor="grey10" px={6} py={6}>
          {sidebarComponent}
        </SidebarContainer>
      )}
    </ModalContainer>
  );
};
