import { Box, Text } from '@elseu/sdu-titan';
import React from 'react';

type Props = React.PropsWithChildren<{
  label: string;
}>;

const DetailsValueField: React.FC<Props> = ({ label, children }) => (
  <Box spaceAfter={4}>
    <Text isBlock spaceAfter={2} type="labelBold">
      {label}
    </Text>
    <Box>{children}</Box>
  </Box>
);

export default DetailsValueField;
