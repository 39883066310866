import './assets/styles/OneDriveFileDetails.scss';

import Avatar from 'components/avatar/Avatar';
import { ButtonAppearanceTypes } from 'components/button/Button';
import Date from 'components/date/Date';
import FormField from 'components/formfield/FormField';
import StrokeArrowRightIcon from 'components/icons/StrokeArrowRightIcon';
import ConfirmModal from 'components/modal/ConfirmModal';
import React from 'react';

type Props = {
  isDisabled?: boolean;
  name?: string;
  extension?: string;
  createdDateTime?: string;
  createdBy?: string;
  createdByProfile?: string;
  path?: string[];
  url?: string;
  cloudMetaInfo?: Record<string, any>;
  onClose: () => void;
};

/**
 * One Drive file details component
 *
 * @param {boolean} disabled
 * @param {string} name
 * @param {string} extension
 * @param {string} createdDateTime
 * @param {string} createdBy
 * @param {string} createdByProfile
 * @param {string} path
 * @param {string} url
 * @param {object} cloudMetaInfo
 * @param {function} onClose
 * @returns {*}
 * @constructor
 */
const OneDriveFileDetails: React.FC<Props> = ({
  isDisabled,
  name,
  extension,
  createdDateTime,
  createdBy,
  createdByProfile,
  path,
  url,
  cloudMetaInfo,
  onClose,
}) => (
  <ConfirmModal
    isCloseShown
    shouldUsePortal
    buttons={[
      {
        isDisabled,
        appearance: ButtonAppearanceTypes.APPEARANCE_DEFAULT,
        title: 'Sluiten',
        onClick: () => onClose(),
      },
    ]}
    className="c-one-drive-file-details"
    confirmButtonAlignment="left"
    isDisabled={isDisabled}
    title={
      <>
        Gegevens <em>{name !== undefined ? name : ''}</em>
      </>
    }
    onCancel={() => onClose()}
    onConfirm={() => onClose()}
  >
    {extension && <FormField label="Type">{extension}</FormField>}
    {cloudMetaInfo && (
      <FormField label="Gewijzigd">
        <div className="c-one-drive-file-details__date-field-value">
          <Date date={cloudMetaInfo.updatedAt} />
          {cloudMetaInfo.updatedBy && (
            <Avatar
              hasBorder
              isInline
              isRounded
              name={cloudMetaInfo.updatedBy}
              size={30}
              url={cloudMetaInfo.updatedByProfile}
            />
          )}
        </div>
      </FormField>
    )}
    {createdDateTime && (
      <FormField label="Aangemaakt">
        <div className="c-one-drive-file-details__date-field-value">
          <Date date={createdDateTime} />
          {createdBy && (
            <Avatar
              hasBorder
              isInline
              isRounded
              name={createdBy}
              size={30}
              url={createdByProfile}
            />
          )}
        </div>
      </FormField>
    )}
    {path && path.length > 0 && (
      <FormField label="Pad">
        <ul className="c-one-drive-file-details__path-list">
          {path.map((pathItem: string, pathIndex: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`path${pathIndex}`}>{pathItem}</li>
          ))}
        </ul>
      </FormField>
    )}
    {url && (
      <FormField label="Meer details">
        <a href={url} rel="noopener noreferrer" target="_blank">
          Open bestand
          <StrokeArrowRightIcon color="primary-blue" size={12} />
        </a>
      </FormField>
    )}
  </ConfirmModal>
);

export default OneDriveFileDetails;
