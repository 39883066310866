import './assets/styles/ContextGroupItem.scss';

import { ActionMenu, MenuItem } from '@elseu/sdu-titan';
import classNames from 'classnames';
import ListIcon from 'components/listicon/ListIcon';
import MarkdownViewer from 'components/markdownviewer/MarkdownViewer';
import type { IOptionItem } from 'components/options/Options';
import React from 'react';

type Props = {
  isDisabled?: boolean;
  context?: any;
  iconType?: string;
  title?: string;
  url?: string;
  description?: string | false;
  isTitleALink?: boolean;
  actions?: IOptionItem[];
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, context: any) => void;
};

/**
 * With url function
 *
 * @param {string} [url]
 * @param {*} [children]
 */
const withUrl = (url?: string, children?: any) =>
  url ? (
    <a href={url} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  ) : (
    children
  );

/**
 * ContextGroupItem
 *
 * @param {any} context,
 * @param {boolean} disabled,
 * @param {string} title,
 * @param {string} url,
 * @param {string} iconType,
 * @param {string} description,
 * @param {IOptionItem[]} actions,
 * @param {Function} onClick
 * @returns {React.ReactElement}
 */
const ContextGroupItem: React.FC<Props> = ({
  context,
  isDisabled,
  title,
  url,
  iconType,
  description,
  isTitleALink,
  actions,
  onClick,
}) => (
  <div className="c-context-group-item group__item">
    <div className="group__item__content">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className={classNames('group__item__content__context', {
          'group__item__content__context--link-style': isTitleALink,
        })}
        role="button"
        tabIndex={0}
        onClick={(event) => {
          onClick?.(event, context);
        }}
      >
        {withUrl(
          url,
          <div>
            <ListIcon className="group__item__content__context__icon" type={iconType} />
            {title && (
              <span
                className={classNames('group__item__content__context__title', {
                  'group__item__content__context__description__no-action':
                    actions && actions.length === 0,
                })}
              >
                {title}
              </span>
            )}
            {description && (
              <span
                className={classNames('group__item__content__context__description', {
                  'group__item__content__context__description__no-action':
                    actions && actions.length === 0,
                })}
              >
                <MarkdownViewer source={description} />
              </span>
            )}
          </div>,
        )}
      </div>
      {actions && actions.length > 0 && (
        <div className="group__item__content__actions">
          <ActionMenu
            defaultShown={false} // temp-fix to get ActionMenu working
            disabled={isDisabled}
            label="Acties"
            popoverOptions={{
              strategy: 'fixed',
            }}
            popoverPlacement="bottom-end"
          >
            {actions.map((action, index) => (
              <MenuItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                item={{
                  // @ts-ignore
                  label: action.label,
                }}
                onClick={(e) => {
                  action.onClick?.(e, context);
                }}
              />
            ))}
          </ActionMenu>
        </div>
      )}
    </div>
  </div>
);

ContextGroupItem.defaultProps = {
  isDisabled: false,
  iconType: 'document',
  description: false,
  actions: [],
  onClick: () => null,
};

export default ContextGroupItem;
