import { Flex } from '@elseu/sdu-titan';
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { useProfileSettings } from '../../../hooks';
import { useTeams } from '../../../hooks/queries';
import { CheckboxField } from '../../formfields/CheckboxField';
import type { IPermission } from '../../permission/helpers';
import {
  createPermissionsFromTeams,
  createPermissionsFromUsers,
  IPermissionType,
  mergePermissionsWithinPermissions,
  removePermissionsWithinPermissions,
  removePermissionWithinPermissions,
  sortPermissions,
  updatePermissionWithinPermissions,
} from '../../permission/helpers';
import { Permissions } from '../../permission/Permissions';

type DossierOptionsFormSectionProps = {
  isReadOnly?: boolean;
};

export const DossierPermissionsFormSection = ({
  isReadOnly = false,
}: DossierOptionsFormSectionProps) => {
  const { getValues, control } = useFormContext();

  const [settings] = useProfileSettings();
  const { teamsAsModels: teams } = useTeams();
  const users = settings.getUsers();

  const permissions = getValues().permissions;

  const availablePermissions = useMemo(
    () => [...createPermissionsFromUsers(users), ...createPermissionsFromTeams(teams)],
    [users, teams],
  );

  const filteredAvailablePermissions = useMemo(() => {
    const filteredPermissions = removePermissionsWithinPermissions(
      permissions,
      availablePermissions,
    );

    // make sure to add user permissions first, and then team permissions.
    return [
      ...sortPermissions(
        filteredPermissions.filter((permission) => permission.type === IPermissionType.TYPE_USER),
      ),
      ...sortPermissions(
        filteredPermissions.filter((permission) => permission.type === IPermissionType.TYPE_TEAM),
      ),
    ];
  }, [availablePermissions, permissions]);

  return (
    <Flex flexDirection="column" gap={6} spaceAfter={8}>
      <Controller
        control={control}
        name="permissions"
        render={({ field: { value = [], onChange } }) => {
          // make sure to add user permissions first, and then team permissions.
          const sortedPermissions = [
            ...sortPermissions(
              value.filter(
                (permission: IPermission) => permission.type === IPermissionType.TYPE_USER,
              ),
            ),
            ...sortPermissions(
              value.filter(
                (permission: IPermission) => permission.type === IPermissionType.TYPE_TEAM,
              ),
            ),
          ];

          return (
            <Permissions
              canManageOwners
              availablePermissions={filteredAvailablePermissions}
              isDisabled={isReadOnly}
              permissions={sortedPermissions}
              onAdd={(added) => {
                onChange(mergePermissionsWithinPermissions(added, sortedPermissions));
              }}
              onChange={(permission) => {
                onChange(updatePermissionWithinPermissions(permission, sortedPermissions));
              }}
              onRemove={(permission) => {
                onChange(removePermissionWithinPermissions(permission, sortedPermissions));
              }}
            />
          );
        }}
      />

      <CheckboxField
        label="Alleen zichtbaar voor gekoppelde gebruikers"
        name="isPrivateCollaboration"
        readOnly={isReadOnly}
      />
      <CheckboxField
        label="Dossier is door iedereen als template te gebruiken"
        name="isTemplate"
        readOnly={isReadOnly}
        title="test"
      />
    </Flex>
  );
};
