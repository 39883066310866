import type { FileUploaderItem } from 'components/fileuploader/FileUploader';
import FileUploader, { FileUploaderMenuValue } from 'components/fileuploader/FileUploader';
import React from 'react';

type Props = {
  isDisabled?: boolean;
  maxFileSize?: number;
  onNewCloudLink: () => void;
  onNewFileUpload: (files: FileUploaderItem[]) => void;
};

/**
 * Card attachment add button
 *
 * @param {boolean} isDisabled
 * @param {string} addFileButtonText
 * @param {function} onNewCloudLink
 * @param {function} onNewFileUpload
 * @returns {*}
 * @constructor
 */
const CardAttachmentAddButton: React.FC<Props> = ({
  isDisabled,
  maxFileSize,
  onNewCloudLink,
  onNewFileUpload,
}) => (
  <FileUploader
    actionMenuItems={[
      {
        item: {
          value: 'cloudLink',
          label: 'Cloud bestanden',
        },
        onClick: () => onNewCloudLink(),
      },
      {
        item: {
          value: FileUploaderMenuValue,
          label: 'Van uw computer',
        },
      },
    ]}
    isDisabled={isDisabled}
    isMultiple={true}
    maxFileSize={maxFileSize}
    onFilesChange={onNewFileUpload}
  />
);

export default CardAttachmentAddButton;
