import './assets/styles/AuditLog.scss';

import Date from 'components/date/Date';
import MarkdownViewer from 'components/markdownviewer/MarkdownViewer';
import moment from 'moment';
import React from 'react';

/**
 * Group the list on the given props property.
 * @param {array} list
 * @param {string} props
 * @return {*}
 */
const groupBy = (list: Record<string, any>, props: string) => {
  return list.reduce((a: Record<string, any>, b: Record<string, any>) => {
    (a[b[props]] = a[b[props]] || []).push(b);
    return a;
  }, {});
};

/**
 * Group and order the audit log items by day and time.
 * @param {array} items
 * @return {array}
 */
const groupAndOrderAuditLog = (items: any[]): Record<string, any> => {
  // add the day property to the items so we can group on this.
  items = items.map((item: any) => {
    const createdAtDate = moment(item.createdAt).format('YYYY-MM-DD');

    return { ...item, createdAtDay: createdAtDate };
  });

  // group items by day.
  items = groupBy(items, 'createdAtDay');

  // sort items by time.
  items = Object.values(items).map((value) => {
    // return value.filter()
    return value.sort((a: Record<string, any>, b: Record<string, any>) => {
      if (a.createdAt > b.createdAt) {
        return -1;
      }
      if (a.createdAt < b.createdAt) {
        return 1;
      }
      return 0;
    });
  });

  return items;
};

type Props = {
  items: any[];
};

/**
 * Audit log items holder with a header.
 * @param {array} items
 * @returns {*}
 * @constructor
 */
const AuditLogItems: React.FC<Props> = ({ items }) => {
  // sanity check if items where present.
  if (items.length === 0) {
    return null;
  }

  const firstItem = [...items].shift();
  const dateMoment = moment(firstItem.createdAt);
  const dateString = dateMoment.calendar(null, {
    lastDay: '[Gisteren]',
    sameDay: '[Vandaag]',
    nextDay: '[Morgen]',
    lastWeek: '[Vorige week] dddd',
    nextWeek: '[Volgende week] dddd',
    sameElse: 'D MMMM YYYY',
  });
  const fullTimestamp = dateMoment.format('DD-MM-YYYY');
  let lastDateTimeDisplay: string | null = null;

  return (
    <div className="audit-log__items">
      <div className="audit-log__items__date" title={fullTimestamp}>
        {dateString}
      </div>
      <div className="audit-log__items__log">
        {items.map((item, key) => {
          const { activityDescription, createdAt } = item;
          const createdAtFormatted = moment(createdAt).fromNow();
          const shouldRenderDate = createdAtFormatted !== lastDateTimeDisplay;
          if (shouldRenderDate) {
            lastDateTimeDisplay = createdAtFormatted;
          }

          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`audit-log--${key}`} className="audit-log__items__log__item">
              <div className="audit-log__items__log__item__timestamp" title={createdAtFormatted}>
                {shouldRenderDate && <Date date={createdAt} />}
              </div>
              <div className="audit-log__items__log__item__activity">
                <MarkdownViewer className="audit-log_item__activity" source={activityDescription} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

/**
 * Audit log component
 *
 * @param {array} items
 * @returns {*}
 * @constructor
 */
const AuditLog: React.FC<Props> = ({ items }) => {
  const auditItems = groupAndOrderAuditLog(items);
  return (
    <div className="audit-log">
      {Object.keys(auditItems).map((key) => (
        <AuditLogItems key={key} items={auditItems[key]} />
      ))}
    </div>
  );
};

export default AuditLog;
