import type { TableColumns } from '@elseu/sdu-titan';
import { Table, TableCommandBar, TableProvider } from '@elseu/sdu-titan';
import { useQuery } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import type { LinkSelectorElementProps } from 'components/card/CardSectionLinkDossierLinkModal';
import ColorTextLabel from 'components/colorlabel/ColorTextLabel';
import ArchiveIcon from 'components/icons/ArchiveIcon';
import MarkdownViewer from 'components/markdownviewer/MarkdownViewer';
import Radio from 'components/radio/Radio';
import RadioGroup from 'components/radio/RadioGroup';
import { normalizeSignal } from 'entity/signaling/normalizer';
import { normalizeSignalingSignalTableItem } from 'entity/table/normalizer';
import type { SignalingSignalTableItem } from 'entity/table/types';
import { normalizeOverviewResults } from 'helpers/normalizehelper';
import { useTitanTableState } from 'helpers/TitanTableHelpers';
import React, { useMemo, useState } from 'react';
import type JSendResponse from 'services/JSendResponse';
import type { ApiFilter } from 'types/types';

const DEFAULT_SORT = 'client';

const getSignalsOverview = (
  filter: ApiFilter[],
  sort: string | undefined,
  page = 1,
  pageSize: number,
) => {
  return Actions.getAPIService()
    .getSignaling()
    .searchSignal(filter, sort, page, pageSize)
    .then((data: JSendResponse) =>
      normalizeOverviewResults(data.getData(), 'signals', normalizeSignal),
    );
};

// sortmapping is outside component, because else it will rerender te component continuesly.
// might need to to fix the titan stateChangeCallBack
const sortMapping = [
  {
    sortName: 'client.name',
    tableAccessor: 'client',
  },
  {
    sortName: 'client.showClientId',
    tableAccessor: 'clientNumber',
  },
];

const SignalingSignalLinkTable: React.FC<LinkSelectorElementProps> = ({
  cardId,
  clients,
  dossierId,
  onSelect,
}) => {
  const { page, pageSize, filters, sort, tableProps } = useTitanTableState({
    defaultSort: [
      {
        id: DEFAULT_SORT,
        desc: false,
      },
    ],
    stateName: 'signaling-signals',
    sortMapping,
  });

  const [selectedValue, setSelectedValue] = useState<string | undefined>();

  const queryFilters: ApiFilter[] = useMemo(() => {
    return [
      ...filters,
      {
        name: 'clients',
        value: clients.toString(),
      },
    ];
  }, [filters, clients]);

  const query = useQuery({
    queryKey: [
      'signaling-signals',
      dossierId,
      cardId,
      {
        page,
        pageSize,
        sort,
        filters: queryFilters,
      },
    ],
    queryFn: () => getSignalsOverview(queryFilters, sort, page, pageSize),
  });

  const columns: TableColumns<SignalingSignalTableItem> = useMemo(
    () => [
      {
        header: ' ',
        accessorKey: 'id',
        enableSorting: false,
        size: 16,
        cell: ({ row }) => {
          return <Radio value={row.original.id} />;
        },
      },
      {
        header: 'Adviessignalering',
        accessorKey: 'signalingName',
        cell: ({ row }) => {
          return row.original.signalingName;
        },
      },
      {
        header: 'Toegewezen aan',
        accessorKey: 'assignedUser',
        enableSorting: false,
      },
      {
        header: 'Status omschrijving',
        accessorKey: 'statusDescription',
        enableSorting: false,
        cell: ({ row }) => {
          return !row.original.statusDescription ? (
            <span className="data__row__column__empty">Geen omschrijving</span>
          ) : (
            <MarkdownViewer source={row.original.statusDescription} />
          );
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        enableSorting: false,
        cell: ({ row }) => {
          return row.original.status ? (
            <ColorTextLabel isSmall color={row.original.status.color}>
              {row.original.status.name}
            </ColorTextLabel>
          ) : null;
        },
      },
      {
        header: ' ',
        accessorKey: 'isArchived',
        size: 20,
        enableSorting: false,
        cell: ({ row }) => {
          return row.original.isArchived ? (
            <ArchiveIcon color="secondary-dark-grey" size={16} title="Gearchiveerd" />
          ) : null;
        },
      },
    ],
    [],
  );

  const data = useMemo<SignalingSignalTableItem[]>(
    () =>
      (query.data?.results ?? []).map((item) => ({
        ...normalizeSignalingSignalTableItem(item),
        isCheckedInTable: false,
      })),
    [query.data?.results],
  );

  return (
    <div className="c-signaling-signal">
      <TableProvider
        key={`signaling-signal-link-overview-${pageSize}`}
        columns={columns}
        data={data}
        isLoading={query.isLoading}
        totalCount={query.data?.paging.numberOfResults ?? 0}
        {...tableProps}
      >
        <TableCommandBar />
        <RadioGroup
          selected={selectedValue}
          onChange={(value: string) => {
            setSelectedValue(value);
            onSelect(value);
          }}
        >
          <Table
            emptyStateProps={{
              title: 'Geen signaleringen',
              description: 'Er geen zijn signaleringen gevonden.',
            }}
            width="100%"
          />
        </RadioGroup>
      </TableProvider>
    </div>
  );
};

export default SignalingSignalLinkTable;
