import type { ClientSelectOptionType } from 'components/client/ClientSelectField';
import type { Team } from 'entity/system/types';
import { normalizeMap } from 'helpers/normalizehelper';

import { normalizeTeam } from '../system/normalizer';
import type { Address, Client, ClientUser, ContactPerson, SubOffice } from './types';

export const normalizeClient = (result: any): Client => ({
  id: result?.id,
  clientId: result?.clientId,
  showClientId: result?.showClientId !== null ? result?.showClientId : undefined,
  name: result?.name,
  phone: result?.phone,
  email: result?.email,
  users: normalizeMap<ClientUser>(result?.users, normalizeClientUser),
  fax: result?.fax,
  sbiCode: result?.sbiCode,
  kvkSize: result?.kvkSize,
  kvkNumber: result?.kvkNumber,
  fiscalNumber: result?.fiscalNumber,
  bsnNumber: result?.bsnNumber,
  fiscalNumberOb: result?.fiscalNumberOb,
  fiscalNumberLh: result?.fiscalNumberLh,
  initials: result?.initials,
  infix: result?.infix,
  surname: result?.surname,
  gender: result?.gender,
  physicalAddress: normalizeAddress(result?.physicalAddress),
  postalAddress: normalizeAddress(result?.postalAddress),
  classification: result?.classification ?? 'business',
  subOfficeName: result?.subOfficeName,
  subOffice: result.subOffice ? normalizeSubOffice(result?.subOffice) : undefined,
  recordChangeDateTime: result?.recordChangeDateTime,
  isManual: result?.isManual,
  metaInfo: result?.metaInfo,
  permissions: {
    canView: result?.permissions?.canView ?? false,
    canEdit: result?.permissions?.canEdit ?? false,
    canDelete: result?.permissions?.canDelete ?? false,
    canSetPermissions: result?.permissions?.canSetPermissions ?? false,
    canXbrlView: result?.permissions?.canXbrlView ?? false,
  },
  teams: normalizeMap<Team>(result?.teams, normalizeTeam),
  primaryClientUser: result.primaryClientUser
    ? normalizeClientUser(result?.primaryClientUser)
    : undefined,
  displayName: normalizeDisplayName(result),
  contactPersons: normalizeMap<ContactPerson>(result?.contactPersons, normalizeContactPerson),
  clientDossierId: result?.clientDossierId,
  clientFilesCardId: result?.clientFilesCardId,
  clientTasksCardId: result?.clientTasksCardId,
  clientTasklistId: result?.clientTasklistId,
});

export const normalizeDisplayName = (result: any, hideClientId?: boolean): string => {
  const nameParts = [];

  if (!hideClientId && result?.showClientId) {
    nameParts.push(`[${result?.showClientId}] - `);
  }

  if (result?.name) {
    nameParts.push(result?.name);
  }

  if (result?.classification === 'private') {
    if (result?.infix) {
      nameParts.push(result?.infix);
    }

    if (result?.surname) {
      nameParts.push(result?.surname);
    }
  }

  return nameParts.join(' ');
};

export const createEmptyClient = (): Client => ({
  classification: 'business',
  permissions: {
    canView: false,
    canEdit: false,
    canDelete: false,
    canSetPermissions: false,
    canXbrlView: false,
  },
});

export const normalizeClientUser = (result: any): ClientUser => {
  return {
    id: result?.id,
    userId: result?.userId,
    firstName: result?.firstName,
    initials: result?.initials,
    infix: result?.infix,
    surname: result?.surname,
    gender: result?.gender,
    phone: result?.phone,
    mobilePhone: result?.mobilePhone,
    email: result?.email,
    isManual: result?.isManual ?? true,
    recordChangeDateTime: result?.recordChangeDateTime,
    lang: result?.lang,
    locale: result?.locale,
    clients: normalizeMap<Client>(result?.clients, normalizeClient),
    permissions: {
      canView: result?.permissions?.canView ?? false,
      canEdit: result?.permissions?.canEdit ?? false,
      canDelete: result?.permissions?.canDelete ?? false,
    },
    displayName:
      result.firstName || result.infix || result.surname
        ? [result.firstName, result.infix, result.surname].join(' ').trim()
        : '',
  };
};

export const createEmptyClientUser = (): ClientUser => ({
  permissions: {
    canView: false,
    canEdit: false,
    canDelete: false,
  },
});

export const normalizeAddress = (result: any): Address => {
  return {
    street: result?.street,
    number: result?.number,
    addition: result?.addition,
    zipcode: result?.zipcode,
    place: result?.place,
    country: result?.country,
  };
};

export const normalizeSubOffice = (result: any): SubOffice => {
  return {
    id: result?.id,
    name: result?.name,
  };
};

export const normalizeClientForSelectField = (client: Client): ClientSelectOptionType => ({
  value: client.id ?? '',
  label: client.displayName ?? '',
  image: '',
  email: client.email,
  type: 'company',
  item: client,
});

export const normalizeContactPerson = (result: any): ContactPerson => ({
  id: result?.id,
  userId: result?.userId,
  referId: result?.referId,
  initials: result?.initials,
  infix: result?.infix,
  surname: result?.surname,
  email: result?.email,
  phone: result?.phone,
  mobilephone: result?.mobilephone,
  displayName: result?.displayName,
});
