import type { TicketType } from 'entity/dossiers/types';

export type ContactNoteSubType = 'phoneConversation' | 'email' | 'conversationNote';

export const mapTicketTypeToSubType = (ticketType: TicketType): ContactNoteSubType => {
  switch (ticketType) {
    case 'note_conversation':
      return 'conversationNote';
    case 'note_phone':
      return 'phoneConversation';
    case 'note_email':
      return 'email';
    default:
      throw new Error(`Unknown ticket type: ${ticketType}`);
  }
};
