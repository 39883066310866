import { ActionMenu, Box, DownloadPdfIcon, Link, MenuItem } from '@elseu/sdu-titan';
import ConfirmDialog from 'components/confirmdialog/ConfirmDialog';
import Date, { DateAppearanceTypes } from 'components/date/Date';
import type { LegalSource } from 'entity/dossiers/types';
import React, { useState } from 'react';
import styled from 'styled-components';

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing[2]};
`;

const StyledDate = styled(Date)`
  margin: 0 ${({ theme }) => theme.spacing[2]};
  color: ${({ theme }) => theme.designTokens.colors.grey70};
`;

const StyledLink = styled(Link)`
  display: inline;
`;

const StyledPdfIcon = styled(DownloadPdfIcon)`
  color: ${({ theme }) => theme.designTokens.colors.grey70};
  flex: 0 0 auto;
`;

const StyledTextContainer = styled(Box)`
  flex: 1 1 auto;
`;

type Props = {
  isDisabled?: boolean;
  isReadOnly?: boolean;
  item: LegalSource;
  onEdit: (item: LegalSource) => void;
  onDelete: (item: LegalSource) => void;
};

const LegalSourceListItem: React.FC<Props> = ({
  isDisabled,
  isReadOnly,
  item,
  onEdit,
  onDelete,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <StyledBox backgroundColor="grey5" borderRadius={2} px={6} py={4} spaceAfter={2}>
        <StyledTextContainer>
          <StyledLink to={item.url}>{item.name}</StyledLink>
          <StyledDate
            appearance={DateAppearanceTypes.APPEARANCE_SIMPLE_DATE}
            date={item.createdAt}
          />
        </StyledTextContainer>
        {item.saveCurrentVersion && (
          <StyledPdfIcon height="16" title="Opgeslagen als PDF" width="16" />
        )}
        {!isReadOnly && (
          <ActionMenu
            defaultShown={false} // temp-fix to get ActionMenu working
            disabled={isDisabled}
            label="Acties"
            popoverOptions={{
              strategy: 'fixed',
            }}
            popoverPlacement="bottom-end"
          >
            <MenuItem
              item={{
                label: 'Bewerken',
              }}
              onClick={() => onEdit(item)}
            />
            <MenuItem
              item={{
                label: 'Verwijderen',
              }}
              onClick={() => setShowDeleteModal(true)}
            />
          </ActionMenu>
        )}
      </StyledBox>
      {showDeleteModal && (
        <ConfirmDialog
          cancelTitle="Nee"
          confirmAppearance="danger"
          confirmTitle="Ja, verwijder"
          title="Jurdische bron verwijderen"
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={() => {
            onDelete(item);
            setShowDeleteModal(false);
          }}
        >
          Weet u zeker dat u de jurdische bron <strong>&quot;{item.name}&quot;</strong> wil
          verwijderen?
        </ConfirmDialog>
      )}
    </>
  );
};

export default LegalSourceListItem;
