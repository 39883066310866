import { normalizeAttachmentFolder, normalizeDossier } from 'entity/dossiers/normalizer';
import { convertToDate } from 'helpers/normalizehelper';

import type { Attachment, CloudAttachment, CloudLink } from './types';

export const normalizeAttachment = (result: any): Attachment => ({
  id: result.id,
  name: result.name,
  url: result.url,
  extension: result.extension,
  createdDateTime: convertToDate(result.createdDateTime),
  updatedDateTime: convertToDate(result.updatedDateTime),
  phase: result.phase,
  attachmentType: result.attachmentType,
  cardId: result.cardId,
  dossierId: result.dossierId,
  attachmentFolder:
    result?.attachmentFolder !== null
      ? normalizeAttachmentFolder(result?.attachmentFolder)
      : undefined,
  displayDate: convertToDate(result.displayDate),
  dossier: result.dossier ? normalizeDossier(result.dossier) : undefined,
});

export const normalizeCloudAttachment = (result: any): CloudAttachment => ({
  id: result.id,
  name: result.name,
  url: result.url,
  type: result.type,
  createdDateTime: convertToDate(result.createdDateTime),
  updatedDateTime: convertToDate(result.updatedDateTime),
  phase: result.phase,
  cardId: result.cardId,
  attachmentFolder:
    result?.attachmentFolder !== null
      ? normalizeAttachmentFolder(result?.attachmentFolder)
      : undefined,
  displayDate: convertToDate(result.displayDate),
});

export const normalizeCloudLink = (result: any): CloudLink => ({
  id: result.id,
  name: result.name,
  url: result.url,
  type: result.type,
  createdDateTime: convertToDate(result.createdDateTime),
  updatedDateTime: convertToDate(result.updatedDateTime),
  phase: result.phase,
  cardId: result.cardId,
  attachmentFolder:
    result?.attachmentFolder !== null
      ? normalizeAttachmentFolder(result?.attachmentFolder)
      : undefined,
});
