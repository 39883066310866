import DataModel, { PropTypes } from 'models/base/DataModel';
import Task from 'models/dossier/Task';

/**
 * TaskList
 */
export default class TaskList extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    name: PropTypes.string,
    position: PropTypes.number,
    createdDateTime: PropTypes.string,
    tasks: PropTypes.arrayOf(PropTypes.shape(Task.propTypes)),
  };

  /**
   * ID of task list
   * @type {string}
   */
  id;

  /**
   * Name of Task list
   * @type {string}
   */
  name;

  /**
   * Position of Task List
   * @type {string}
   */
  position;

  /**
   * Creation data of task list
   * @type {string}
   */
  createdDateTime;

  /**
   * Array of tasks
   * @type {Task[]|undefined}
   */
  tasks;

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'tasks') {
      value = Task.createInstancesByArray(value);
    }

    return super.set(fieldName, value);
  }

  /**
   * @returns {Task[]}
   */
  getTasks() {
    if (this.tasks === undefined) {
      this.tasks = [];
    }

    return this.tasks;
  }
}
