import { InputVariant } from '@elseu/sdu-titan';
import type { DatePickerFieldProps } from 'components/formfields/DatePickerField';
import { DatePickerField } from 'components/formfields/DatePickerField';
import { FormGroupHorizontal } from 'components/formgroup/FormGroupHorizontal';
import React from 'react';

export const TicketDeadlineFormField: React.FC<Partial<DatePickerFieldProps>> = (props) => {
  return (
    <FormGroupHorizontal label="Deadline">
      <DatePickerField
        isClearable
        isLabelHidden
        label="Deadline"
        name="deadlineDate"
        placeholder="Selecteer een deadline"
        spaceAfter={0}
        variant={InputVariant.CLEAR}
        {...props}
      />
    </FormGroupHorizontal>
  );
};
