import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';

/**
 * Template Task List API service
 */
export default class TemplateTaskListApi extends APIEndpoint {
  /**
   * Get template task lists
   *
   * @param {array} [filters=[]]
   * @param {string} [sort='']
   * @param {number} [page=1]
   * @param {number} [pageSize=10]
   * @return {JSendRequest}
   */
  getTemplateTaskLists(filters = [], sort = '', page = 1, pageSize = 10) {
    const url = this.buildUrl('template_task_list', filters, sort, page, pageSize);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  /**
   * Get template task list list for a card
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @return {Promise}
   */
  getTemplateTaskListsForCard(dossierId, cardId) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `cards/${cardId}/template_task_list`,
    );
  }

  /**
   * Get template task list list for a card
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @return {Promise}
   * @memberof TemplateTaskListApi
   */
  addTemplateTaskListsToCard(dossierId, cardId, templateTaskListId) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `cards/${cardId}/template_task_list/${templateTaskListId}`,
      JSendRequest.REQUEST_METHOD_POST,
    );
  }

  /**
   * Get template task list details
   *
   * @param {string} templateTaskListId
   * @return {JSendRequest}
   */
  getTemplateTaskListDetails(templateTaskListId) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `template_task_list/${templateTaskListId}`,
    );
  }

  /**
   * Create template task list item
   *
   * @param {object} values
   * @return {JSendRequest}
   */
  createTemplateTaskList(values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'template_task_list',
      JSendRequest.REQUEST_METHOD_POST,
      values,
    );
  }

  /**
   * Update template task list item
   *
   * @param {object} values
   * @return {JSendRequest}
   */
  updateTemplateTaskList(templateTasklistId, values) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `template_task_list/${templateTasklistId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      values,
    );
  }

  /**
   * Get template task list details
   *
   * @param {string} templateTaskListId
   * @return {JSendRequest}
   */
  deleteTemplateTaskList(templateTaskListId) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `template_task_list/${templateTaskListId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }
}
