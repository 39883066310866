import { useQueryClient } from '@tanstack/react-query';

type useInvalidateDossierCacheOptions = {
  clearClientDossier?: boolean;
  clearCards?: boolean;
  clearDossier?: boolean;
  clearOverview?: boolean;
  clearClients?: boolean;
};
type useInvalidateDossierReturn = (
  dossierId?: string | undefined,
  options?: useInvalidateDossierCacheOptions | undefined,
) => void;

const defaultOptions: useInvalidateDossierCacheOptions = {
  clearClientDossier: true,
  clearCards: true,
  clearDossier: true,
  clearOverview: true,
  clearClients: true,
};

export const useInvalidateDossierCache = (): useInvalidateDossierReturn => {
  const queryClient = useQueryClient();

  return (dossierId, options = defaultOptions) => {
    const definedOptions = {
      ...defaultOptions,
      ...options,
    };

    if (definedOptions.clearClientDossier === true) {
      queryClient.invalidateQueries({
        queryKey: ['client-dossiers'],
      });
      queryClient.invalidateQueries({
        queryKey: ['client-attachments-dossiers'],
      });
      queryClient.invalidateQueries({
        queryKey: ['client-attachments-dossiers-cards'],
      });
    }
    if (defaultOptions.clearClients === true) {
      queryClient.invalidateQueries({
        queryKey: ['clientusers-dossiers'],
      });
    }

    if (defaultOptions.clearOverview === true) {
      queryClient.invalidateQueries({
        queryKey: ['dossiers'],
      });
      queryClient.invalidateQueries({
        queryKey: ['dashboard'],
      });
    }

    if (dossierId) {
      if (defaultOptions.clearDossier === true) {
        queryClient.invalidateQueries({
          queryKey: ['dossier', dossierId],
        });
        queryClient.invalidateQueries({
          queryKey: ['dossier-auditlog', dossierId],
        });
      }

      if (definedOptions.clearCards === true) {
        // invalidate the cards also
        queryClient.invalidateQueries({
          queryKey: ['card', dossierId],
        });
        queryClient.invalidateQueries({
          queryKey: ['card-auditlog', dossierId],
        });
      }
    }
  };
};
