import React from 'react';

import { ReactComponent } from './assets/svg/EditorBold.svg';
import type { IconComponentProps } from './IconComponent';
import IconComponent from './IconComponent';

const EditorBold = (props: IconComponentProps) => IconComponent(props, <ReactComponent />);

EditorBold.propTypes = IconComponent.propTypes;
EditorBold.defaultProps = IconComponent.defaultProps;

export default EditorBold;
