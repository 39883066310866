import { Box, CloseIcon, Flex, IconButton, Text } from '@elseu/sdu-titan';
import Date from 'components/date/Date';
import type { Card } from 'entity/dossiers/types';
import React from 'react';

import { getTabIcon, useCardSidebarTabs } from './CardSidebarTabProvider';

interface CardSidebarToolbarProps {
  card?: Card;
  onClose?: () => void;
}
export const CardSidebarToolbar = ({ card, onClose }: CardSidebarToolbarProps) => {
  const { activeId, setActiveId, tabs } = useCardSidebarTabs();

  return (
    <Flex alignItems="center" gap={3} justifyContent="end" spaceAfter={4}>
      <Text color="grey60" type="labelTiny">
        {card?.metaInfo?.updatedAt && (
          <>
            Laatst gewijzigd:&nbsp;
            <Date autoUpdateEvery={1000 * 30} date={card.metaInfo.updatedAt} />
          </>
        )}
      </Text>

      <Flex alignItems="center" gap={1}>
        {card &&
          tabs.map((tab) => (
            <Flex key={tab.id} px={1} py={1}>
              <IconButton
                icon={getTabIcon(tab.id)}
                size={20}
                variant={activeId === tab.id ? 'primary' : 'secondary'}
                onClick={() => setActiveId(tab.id)}
              />
            </Flex>
          ))}
        {onClose && (
          <Box key="close" py={1}>
            <IconButton icon={<CloseIcon />} size={24} variant="secondary" onClick={onClose} />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};
