import { getProperty, hasProperty } from 'dot-prop';

type FormErrorItemsType = {
  _paths: {
    [key: string]: string;
  };
  [key: string]: string | unknown | any;
};

export class FormError extends Error {
  formItems: FormErrorItemsType = { _paths: {} };
  message: string;

  /**
   * Constructor
   *
   * @param response
   */
  constructor(response: any) {
    super(response.message || 'Unknown form error');

    // fix this in functions?
    this.formItems = response.data;
    this.message = response.message;
  }

  /**
   * Check if
   * @param {string} fieldName
   * @returns boolean
   */
  hasFieldError(fieldName: string): boolean {
    return hasProperty(this.formItems, fieldName);
  }

  /**
   * Get error message for a specific field
   *
   * @param {string} fieldName
   * @returns string | undefined
   */
  getErrorMessageForField(fieldName: string): string | undefined {
    const value: string = getProperty(this.formItems, fieldName);
    return value ? value.toString() : undefined;
  }

  /**
   * Get errors form a array
   *
   * @param {string} key
   * @returns Object
   */
  getArrayErrors(key: string): any {
    const errors: any[] = this.formItems[key];
    const errorKeys = Object.keys(errors);
    const retValue: any = {};

    errorKeys.forEach((errorKey: any) => {
      retValue[errorKey] = new FormError({
        data: errors[errorKey],
        message: '',
      });
    });

    return retValue;
  }
}
