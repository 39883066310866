import { EmptyState, Flex, Loader } from '@elseu/sdu-titan';
import { CardSidebar, CardSidebarContainer } from 'components/card/CardSidebar';
import { useContactNoteDetails } from 'hooks/queries';
import React from 'react';

import { CardSidebarToolbar } from '../card/CardSidebarToolbar';

interface ContactNoteSidebarProps {
  contactNoteId?: string;
  onClose?: () => void;
}
export const ContactNoteSidebar: React.FC<ContactNoteSidebarProps> = ({
  contactNoteId,
  onClose,
}) => {
  const { contactNote, query } = useContactNoteDetails(contactNoteId);

  const isLoading = query.isLoading && !contactNote;

  const content = (
    <>
      <CardSidebar
        card={contactNote?.contactNoteCard}
        dossierId={contactNote?.id || ''}
        emptyContent={
          <Flex justifyContent="center" px={4} py={20}>
            <EmptyState
              isCentered
              description="Koppel eerst aan een klant en ga daarna verder!"
              illustration="looking-glass"
              title="Je kunt bijna aan de slag"
            />
          </Flex>
        }
        onClose={onClose}
        onUpdate={() => query.refetch()}
      />
    </>
  );

  return (
    <CardSidebarContainer>
      <CardSidebarToolbar card={contactNote?.contactNoteCard} onClose={onClose} />
      {isLoading ? <Loader height={48} variant="spinner" /> : content}
    </CardSidebarContainer>
  );
};
