import React from 'react';

import { ReactComponent } from './assets/svg/EditorItalic.svg';
import type { IconComponentProps } from './IconComponent';
import IconComponent from './IconComponent';

const EditorItalic = (props: IconComponentProps) => IconComponent(props, <ReactComponent />);

EditorItalic.propTypes = IconComponent.propTypes;
EditorItalic.defaultProps = IconComponent.defaultProps;

export default EditorItalic;
