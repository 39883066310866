import type { AsyncSelectProps } from '@elseu/sdu-titan';
import { useQueryClient } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import type { AsyncSelectFieldProps } from 'components/formfields/AsyncSelectField';
import { AsyncSelectField } from 'components/formfields/AsyncSelectField';
import { FormGroupHorizontal } from 'components/formgroup/FormGroupHorizontal';
import { normalizeClient } from 'entity/crm/normalizer';
import type { Client } from 'entity/crm/types';
import { normalizeOverviewResults } from 'helpers/normalizehelper';
import React, { useCallback } from 'react';

const getClientSearchResults = async (search: string) => {
  const data = await Actions.getAPIService()
    .getCrm()
    .clients(search ? [{ name: 'search', value: search }] : [], 'name', 1, 25);
  return normalizeOverviewResults(data.getData(), 'clients', normalizeClient);
};

interface TicketClientSelectFieldProps extends Partial<AsyncSelectFieldProps> {
  isMandatory?: boolean;
}

export const TicketClientSelectField: React.FC<Partial<TicketClientSelectFieldProps>> = ({
  isMulti,
  isMandatory = false,
  ...props
}) => {
  const queryClient = useQueryClient();

  const loadOptions: AsyncSelectProps<boolean>['loadOptions'] = useCallback(
    async (inputValue: string) => {
      const response = await queryClient.fetchQuery({
        queryKey: ['clientSelectField', inputValue],
        queryFn: () => getClientSearchResults(inputValue),
        staleTime: 5000,
        gcTime: 5000,
      });
      return response.results
        .filter((item: Client) => !!item.id)
        .map((item: Client) => ({
          value: item.id!,
          label: item.displayName || item.name || '',
        }));
    },
    [queryClient],
  );

  const rules = isMandatory ? { required: 'Klant is verplicht' } : undefined;

  return (
    <FormGroupHorizontal label={isMandatory ? 'Klant*' : 'Klant'}>
      <AsyncSelectField
        defaultOptions
        isLabelHidden
        isSearchable
        buttonVariant="clear"
        isMulti={isMulti}
        label="Klant"
        loadOptions={loadOptions}
        name={isMulti ? 'clients' : 'client'}
        noOptionsMessage={() => 'Geen klanten gevonden'}
        placeholder="Selecteer een klant"
        rules={rules}
        spaceAfter={0}
        {...props}
      />
    </FormGroupHorizontal>
  );
};
