import { Text } from '@elseu/sdu-titan';
import React from 'react';

import type { Ticket } from '../../../entity/dossiers/types';
import { clientsToClientLabel } from './utils/clientsToClientLabel';

type TicketClientProps = {
  ticket: Ticket;
};

export const TicketClient = ({ ticket }: TicketClientProps) => {
  const { clients } = ticket;
  const clientLabel = clientsToClientLabel(clients);

  if (!clientLabel) {
    return null;
  }

  return (
    <Text color="grey70" type="paragraphSmall">
      {clientLabel}
    </Text>
  );
};
