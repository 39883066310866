import { Divider } from '@elseu/sdu-titan';
import { TopicSelectFormField } from 'components/dossier/forms/TopicSelectFormField';
import type { AsyncSelectOptionProps } from 'components/formfields/AsyncSelectField';
import { NotesField } from 'components/formfields/NotesField';
import { SubmitOnChangeForm } from 'components/formfields/SubmitOnChangeForm';
import { TicketAssignedUserFormField } from 'components/ticket/forms/formfields/TicketAssignedUserFormField';
import { TicketClientSelectField } from 'components/ticket/forms/formfields/TicketClientSelectField';
import { TicketCreatedByFormField } from 'components/ticket/forms/formfields/TicketCreatedByFormField';
import { TicketNameFormField } from 'components/ticket/forms/formfields/TicketNameFormField';
import { TicketStatusFormField } from 'components/ticket/forms/formfields/TicketStatusFormField';
import React, { useCallback } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useFormContext } from 'react-hook-form';
import type { Nullable } from 'types/types';

import { TicketSubmitButton } from '../../ticket/forms/formfields/TicketSubmitButton';
import type { TicketFormProps } from '../../ticket/forms/TicketFormProps';
import { DossierPermissionsFormSection } from './DossierPermissionsFormSection';

export type DossierFormValues = Nullable<{
  name?: string;
  assignedUser?: string;
  createdBy?: string;
  clients?: AsyncSelectOptionProps[];
  status?: string;
  description?: string;
  topics?: string[];
  permissions?: any;
  duplicateSkipAttachments?: boolean;
  duplicateSkipComments?: boolean;
  isPrivateCollaboration?: boolean;
  isTemplate?: boolean;
}>;

type DossierFormProps = {
  isReadOnly?: boolean;
} & TicketFormProps<DossierFormValues>;

export const DossierForm = ({ onSubmit, isReadOnly = false, mode }: DossierFormProps) => {
  const form = useFormContext<DossierFormValues>();

  const onFormSubmit: SubmitHandler<DossierFormValues> = useCallback(
    (formValues) => {
      if (!form.formState.isValid) {
        return;
      }

      onSubmit(formValues, form);
    },
    [onSubmit, form],
  );

  // when creating a new dossier, the form should be submitted manually by the user,
  // but when editing a dossier, the form should be submitted automatically when the form values change
  const FormComponent = mode === 'edit' ? SubmitOnChangeForm : 'form';

  return (
    <FormProvider {...form}>
      <FormComponent onSubmit={form.handleSubmit(onFormSubmit)}>
        <TicketNameFormField readOnly={isReadOnly} />

        <TicketStatusFormField isDisabled={isReadOnly} />

        <TicketCreatedByFormField />

        <TicketAssignedUserFormField readOnly={isReadOnly} />

        <TicketClientSelectField isMandatory isMulti readOnly={isReadOnly} />

        <TopicSelectFormField readOnly={isReadOnly} />

        <DossierPermissionsFormSection isReadOnly={isReadOnly} />

        <Divider />

        <NotesField isReadOnly={isReadOnly} name="description" />

        {mode === 'create' && (
          <TicketSubmitButton onClick={() => onSubmit(form.getValues(), form)}>
            Dossier aanmaken
          </TicketSubmitButton>
        )}
      </FormComponent>
    </FormProvider>
  );
};
