import './assets/styles/ContextGroups.scss';

import classNames from 'classnames';
import Button, { ButtonAppearanceTypes } from 'components/button/Button';
import AddCircleIcon from 'components/icons/AddCircleIcon';
import type { ContextGroup, ContextItem } from 'entity/dossiers/types';
import React from 'react';

import ContextGroupItem from './ContextGroupItem';
import type { ContextGroupsProps } from './types';

/**
 * ContextGroups
 *
 * @param {string} className
 * @param {string} prefix
 * @param {string} search
 * @param {array} groups
 * @param {array} actions
 * @param {boolean} isDisabled
 * @param {function} onGetActionsForItem
 * @param {function} onShouldShowActions
 * @param {function} onToolCreationClick
 * @param {boolean} isToolCreationAllowed
 * @param {string} toolCreationGroupTitle
 * @param {string} toolCreationTitle
 * @return {JSX.Element}
 * @constructor
 */
const ContextGroups: React.FC<ContextGroupsProps> = ({
  className,
  search,
  groups = [],
  actions,
  isDisabled = false,
  isToolCreationAllowed = true,
  toolCreationGroupTitle = 'Eigen tools en modellen',
  toolCreationTitle = 'Voeg eigen model of tool aan Addify toe',
  onGetActionsForItem,
  onShouldShowActions = (group, item) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return !!(group && item);
  },
  onToolCreationClick,
  onBerkelyItemClick,
}) => {
  /**
   * Get list of actions that an item can do.
   * First checks if the function onGetActionsForItem is set.
   * If not then look if actions variable is set and check if we should be shown by using the onShouldShowActions
   * function.
   */
  const getActions = (group: ContextGroup, item: ContextItem) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (onGetActionsForItem) {
      return onGetActionsForItem(group, item);
    } else {
      return onShouldShowActions(group, item) ? actions || [] : [];
    }
  };

  return (
    <ul className={classNames(className, 'c-context-groups groups')}>
      {groups.map((group) => {
        // filter groups and items on search query.
        const items = search
          ? group.items.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
          : group.items;

        // render group items (with search filter)
        return items.length === 0
          ? []
          : [
              <li key={`c-context-groups__group-${group.id}`} className="groups__group">
                {group.name}
                <ul className="groups__group__items">
                  {items.map((item) => (
                    <li
                      key={`c-context-groups__group-${group.id}-${item.id}`}
                      className="groups__group__items__item"
                    >
                      <ContextGroupItem
                        isTitleALink
                        actions={getActions(group, item)}
                        context={{
                          group,
                          item,
                        }}
                        description={item.description || ''}
                        iconType={item.type || ''}
                        isDisabled={isDisabled}
                        title={item.name || ''}
                        url={item.url || ''}
                        onClick={
                          item.isBerkleyModel
                            ? () => {
                                onBerkelyItemClick(item);
                              }
                            : undefined
                        }
                      />
                    </li>
                  ))}
                </ul>
              </li>,
            ];
      })}

      {/* when no own tool group is found we need to add the header of this group */}
      {isToolCreationAllowed &&
        !search &&
        groups.filter((group) => group.id === 'own-tool').length === 0 && (
          <li className="groups__group groups__group__create__title">{toolCreationGroupTitle}</li>
        )}

      {isToolCreationAllowed && !search && (
        <li className="groups__group groups__group__create">
          <Button
            appearance={ButtonAppearanceTypes.APPEARANCE_LINK}
            iconPrefix={<AddCircleIcon size={16} />}
            isDisabled={isDisabled}
            onClick={onToolCreationClick}
          >
            {toolCreationTitle}
          </Button>
        </li>
      )}
    </ul>
  );
};

export default ContextGroups;
