import { PropTypes } from 'models/base/DataModel';
import ContextItem from 'models/dossier/ContextItem';

import AttachmentFolder from '../attachment/AttachmentFolder';

/**
 * Attachment
 */
export default class Attachment extends ContextItem {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...ContextItem.propTypes,
    uploadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    displaySize: PropTypes.string,
    iconName: PropTypes.string,
    extension: PropTypes.string,
    createdDateTime: PropTypes.string,
    cardId: PropTypes.string,
    phase: PropTypes.string,
    attachmentFolder: PropTypes.shape(AttachmentFolder.propTypes),
    displayDate: PropTypes.string,
  };

  /**
   * Temporary upload identifier.
   * @type {string|number}
   */
  uploadId;

  /**
   * @type {string}
   */
  type = 'attachment';

  /**
   * @type {string}
   */
  contextType = 'attachment'; // @todo: find out why we use type AND contextType

  /**
   * Dossier phase this attachment is linked to.
   * @type {string}
   */
  phase;

  /**
   * Human-readable file size of this attachment.
   * @type {string}
   */
  displaySize;

  /**
   * Name of the icon to use.
   * @type {string}
   */
  iconName;

  /**
   * Extension
   * @type {string}
   */
  extension;

  /**
   * Date this attachment was created.
   * @type {string}
   */
  createdDateTime;

  /**
   * Card id
   *
   * @type {string}
   */
  cardId;

  /**
   * @type {AttachmentFolder|undefined}
   */
  attachmentFolder;

  /**
   * @type {string}
   */
  displayDate;

  /**
   * @returns {string|number}
   */
  getUploadId() {
    return this.uploadId;
  }

  /**
   * @returns {string}
   */
  getDisplaySize() {
    return this.displaySize;
  }

  /**
   * @returns {string}
   */
  getIconName() {
    return this.iconName;
  }

  /**
   * @return {string}
   */
  getExtension() {
    return this.extension;
  }

  /**
   * @returns {string}
   */
  getCreatedDateTime() {
    return this.createdDateTime;
  }

  /**
   * @returns {string}
   */
  getCardId() {
    return this.cardId;
  }

  /**
   * @return {string}
   */
  getPhase() {
    return this.phase;
  }

  /**
   * @return {AttachmentFolder}
   */
  getAttachmentFolder() {
    return this.attachmentFolder;
  }

  /**
   * @return {string}
   */
  getDisplayDate() {
    return this.displayDate;
  }

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'attachmentFolder') {
      value = AttachmentFolder.createInstance(value);
    }
    return super.set(fieldName, value);
  }
}
