import { arrayMove } from '@dnd-kit/sortable';
import type { TableColumns } from '@elseu/sdu-titan';
import {
  AlignStartHorizontalIcon,
  Avatar,
  Clickable,
  ContentSwitcher,
  ContentWrapper,
  Flex,
  Heading,
  spacing,
  Table,
  TableCommandBar,
  TablePropertiesIcon,
  TableProvider,
  Text,
} from '@elseu/sdu-titan';
import type { TableEmptyStateProps } from '@elseu/sdu-titan/dist/types/components/Table/TableEmptyState';
import { Kanban } from 'components/kanban/Kanban';
import TaskDeadlineDate from 'components/task/TaskDeadlineDate';
import CreateTicketMenu from 'components/ticket/CreateTicketMenu';
import { TicketEditModal } from 'components/ticket/TicketEditModal';
import TicketFilter from 'components/ticket/TicketFilter';
import TicketStatusSelectField from 'components/ticket/TicketStatusSelectField';
import { TicketTypeTag } from 'components/ticket/TicketTypeTag';
import { type Ticket, type WorkOverviewViewMode } from 'entity/dossiers/types';
import { sortTypes } from 'helpers/table';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useKanbanColumns } from './hooks/useColumns';
import { useTicketFilter } from './hooks/useTicketFilter';
import { useTicketListView } from './hooks/useTicketListView';
import { useTicketStatuses } from './hooks/useTicketStatuses';
import { useTicketWorkOverview } from './hooks/useTicketWorkOverview';
import { useViewMode, VIEW_MODE_KANBAN, VIEW_MODE_LIST } from './hooks/useViewMode';
import { TicketActions } from './TicketActions';
import TicketCardContent from './TicketCardContent';
import { TicketClient } from './TicketClient';
import { TicketErrorBoundary } from './TicketErrorBoundary';
import { TicketMetaData } from './TicketMetaData';
import { TicketParent } from './TicketParent';
import { formatCardName } from './utils/formatCardName';
import { generateTicketUrl, getTicketParentName } from './utils/generateTicketUrl';

const StyledContentWrapper = styled(ContentWrapper)`
  padding-top: ${spacing(10)};
`;

const TicketsContainer: React.FC = () => {
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useViewMode();
  const columns = useKanbanColumns();
  const { getStatusesFor } = useTicketStatuses();
  const [selectedTicket, setSelectedTicket] = useState<Ticket>();
  const { queryFilters, filters, applyFilters, resetFilters } = useTicketFilter();

  const { isLoading, updateTicket, tickets, setOptimisticTickets, refetch } = useTicketWorkOverview(
    {
      filter: queryFilters,
    },
  );

  const { handleDragEnd } = useTicketListView(tickets, {
    onChange: ({ activeId, overId, activeIndex, overIndex }) => {
      // update the local state optimistically
      setOptimisticTickets((tickets: Ticket[]) => arrayMove(tickets, activeIndex, overIndex));

      // in the meantime, update the ticket on the server,
      // which in turn will refetch the list of tickets
      updateTicket({
        ticketId: activeId as Ticket['id'],
        otherId: overId as Ticket['id'],
        placement: activeIndex < overIndex ? 'after' : 'before',
      });
    },
  });

  const tableColumns: TableColumns<Ticket> = useMemo(() => {
    const { date, ticketType } = sortTypes();
    return [
      {
        id: 'type',
        header: 'Type',
        accessorKey: 'type',
        sortingFn: ticketType,
        size: 160,
        cell: ({ row }) => {
          const ticket = row.original;
          return <TicketTypeTag ticketType={ticket.type} />;
        },
      },
      {
        id: 'name',
        header: 'Naam',
        accessorKey: 'name',
        sortingFn: 'alphanumeric',
        cell: ({ row: { original: ticket } }) => (
          <Flex flexDirection="column" gap={1}>
            <TicketErrorBoundary ticket={ticket}>
              <Flex alignItems="center" gap={2}>
                <Text type="labelBold">{formatCardName(ticket.name)}</Text>
                <TicketMetaData
                  numberOfComments={ticket.metaData.numberOfComments}
                  numberOfCompletedTasks={ticket.metaData.numberOfCompletedTasks}
                  numberOfTasks={ticket.metaData.numberOfTasks}
                />
              </Flex>

              <Flex alignItems="center" gap={1} justifyContent="flex-start">
                <>
                  <TicketClient ticket={ticket} />
                  {getTicketParentName(ticket) && (
                    <>
                      <Text color="grey70" type="paragraphSmall">
                        -
                      </Text>
                      <TicketParent ticket={ticket} />
                    </>
                  )}
                </>
              </Flex>
            </TicketErrorBoundary>
          </Flex>
        ),
      },
      {
        id: 'deadline',
        header: 'Deadline',
        accessorKey: 'deadline',
        sortingFn: date,
        size: 160,
        cell: ({ getValue }) => {
          const deadline = getValue<Ticket['deadline']>();
          if (!deadline) return null;
          return <TaskDeadlineDate date={deadline} format="D MMMM YYYY" />;
        },
      },
      {
        id: 'status',
        header: 'Status',
        accessorKey: 'status.name',
        size: 200,
        cell: ({ row }) => (
          <Clickable data-no-dnd onClick={(e) => e.stopPropagation()}>
            <TicketStatusSelectField
              availableLabels={getStatusesFor(row.original.type)}
              value={row.original.status?.id ?? ''}
              onChange={(newValue) => {
                updateTicket({
                  ticketId: row.original.id,
                  statusId: newValue,
                });
              }}
            />
          </Clickable>
        ),
      },
      {
        id: 'user',
        header: 'Toegewezen persoon',
        accessorKey: 'assignee.fullName',
        size: 220,
        cell: ({ row }) => {
          const assignee = row.original.assignee;
          if (!assignee) return null;
          return (
            <Flex gap={2}>
              <Avatar size={24} src={assignee.avatarUrl} />
              <Text type="label">{assignee.fullName}</Text>
            </Flex>
          );
        },
      },
      {
        id: 'actions',
        header: '',
        size: 64,
        cell: ({ row }) => <TicketActions hasPortal ticket={row.original} />,
      },
    ];
  }, [getStatusesFor, updateTicket]);

  const emptyStateProps: TableEmptyStateProps = {
    title: 'Geen taken om weer te geven',
    description: 'Een schone lei om iets nieuws te beginnen.',
  };

  if (isLoading) return null;

  return (
    <StyledContentWrapper
      hasPadding
      maxWidth={{
        m: 1200,
        l: 1328,
        xl: 1420,
        xxl: 1720,
      }}
    >
      <TableProvider
        enableSortingRemoval
        hasPagination
        isDraggable
        columns={tableColumns}
        data={tickets}
        getRowId={(row) => row.id}
        handleDragEnd={handleDragEnd}
        initialState={{
          pagination: {
            pageSize: 100,
          },
        }}
        labelPageSizeOption={({ pageSize }) => `${pageSize} rijen`}
        pageSizeOptions={[100]}
        onRowClick={(row) => navigate(generateTicketUrl(row.original))}
      >
        <>
          <TableCommandBar spaceAfter={10}>
            <Flex alignItems="center" flex="1 1 auto" justifyContent="space-between">
              <Heading heading="h2">Werkoverzicht</Heading>
              <Flex alignItems="center" gap={2}>
                <CreateTicketMenu onCreate={refetch} />
                <ContentSwitcher
                  defaultSelected={viewMode}
                  options={[
                    {
                      isDisabled: viewMode === VIEW_MODE_KANBAN,
                      label: <AlignStartHorizontalIcon />,
                      value: VIEW_MODE_KANBAN,
                    },
                    {
                      isDisabled: viewMode === VIEW_MODE_LIST,
                      label: <TablePropertiesIcon />,
                      value: VIEW_MODE_LIST,
                    },
                  ]}
                  onChange={(value) => setViewMode(value as WorkOverviewViewMode)}
                />
                <TicketFilter
                  filters={filters}
                  onChangeFilter={applyFilters}
                  onResetFilter={resetFilters}
                />
              </Flex>
            </Flex>
          </TableCommandBar>
          {viewMode === VIEW_MODE_KANBAN ? (
            <Kanban
              columns={columns}
              emptyStateProps={emptyStateProps}
              renderCardContent={(item) => <TicketCardContent ticket={item} />}
              // onCardClick={(ticket) => navigate(generateTicketUrl(ticket))}
              onCardClick={(ticket) => setSelectedTicket(ticket)}
              onTicketCreate={refetch}
              onTicketUpdate={updateTicket}
            />
          ) : (
            <Table emptyStateProps={emptyStateProps} width="100%" />
          )}
        </>
      </TableProvider>
      <TicketEditModal
        isShown={!!selectedTicket}
        ticket={selectedTicket}
        ticketType={selectedTicket?.type}
        onClose={() => setSelectedTicket(undefined)}
        onUpdate={refetch}
      />
    </StyledContentWrapper>
  );
};

export default TicketsContainer;
