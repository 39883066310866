import { useMutation } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import type { DossierPayload } from 'services/api/DossierApi';

interface useDossierMutationParams {
  id?: string;
  dossier: DossierPayload;
}

export const useDossierUpdate = () => {
  return useMutation({
    mutationFn: ({ id, dossier }: useDossierMutationParams) =>
      Actions.getAPIService()
        .getDossiers()
        .updateDossier(id || '', dossier),
  });
};
