import './assets/styles/ColorLabel.scss';

import classNames from 'classnames';
import { createColorSwatch } from 'helpers/colorhelper';
import React, { useState } from 'react';
import { usePopper } from 'react-popper';

import { useColorConverter } from '../../hooks/useColorConverter';

type Props = {
  className?: string;
  color: string;
  label?: string;
  isClickable?: boolean;
  hasShadow?: boolean;
  size?: 'S' | 'N';
};

type StylePropType = {
  [key: string]: any;
};

/**
 * Phase label component
 *
 * @param {string} className
 * @param {string} color
 * @param {string} label
 * @param {boolean} hasShadow
 * @param {boolean} clickable
 * @returns {*}
 * @constructor
 */
const ColorLabel: React.FC<Props> = ({
  className,
  color,
  label,
  hasShadow,
  size = 'N',
  isClickable = true,
}) => {
  const [openState, setOpenState] = useState(false);
  const [animate, setAnimate] = useState(false);
  const { statusColorToHex } = useColorConverter();
  const colorMan = createColorSwatch(statusColorToHex(color));
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [],
  });

  const componentStyle: StylePropType = {
    '--c-color-label-background-color': color,
    '--c-color-label-border-color': colorMan.border,
    '--c-color-label-text-color': colorMan.text,
    '--c-color-label-size': size === 'N' ? '12px' : '6px',
  };

  let extraSpanProps = {};
  if (isClickable) {
    extraSpanProps = {
      role: 'button',
      tabIndex: 0,
      onClick: (event: MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        setAnimate(true);
        setOpenState(!openState);
      },
    };
  }

  return (
    <span
      className={classNames('c-phase-label', className, {
        isClickable,
        'has-shadow': hasShadow,
        animate,
        open: openState,
        close: !openState,
      })}
      data-testid="colorlabel-span"
      style={componentStyle}
      title={!isClickable ? label : undefined}
      {...extraSpanProps}
    >
      <div ref={setReferenceElement} className="c-phase-label__bullet c-phase-label__color" />
      {label && isClickable && (
        <div
          ref={setPopperElement}
          className="c-phase-label__label c-phase-label__color"
          style={styles.popper}
          {...attributes.popper}
        >
          {label}
        </div>
      )}
    </span>
  );
};

ColorLabel.defaultProps = {
  color: '#ffffff',
  isClickable: true,
  hasShadow: false,
};

export default ColorLabel;
