import { useMutation, useQueryClient } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import type { Ticket } from 'entity/dossiers/types';

export const useRemoveTicket = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: Ticket['id']) => Actions.getAPIService().tickets().deleteTicket(id),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['tickets'] }),
  });
};
