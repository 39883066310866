import './assets/styles/TaskListItemEdit.scss';

import { Button, ButtonGroup, CloseIcon, DatePicker, IconButton } from '@elseu/sdu-titan';
import classNames from 'classnames';
import CheckBox from 'components/checkbox/CheckBox';
import FormField, { FormFieldAppearance } from 'components/formfield/FormField';
import TextAreaAutoSizeField from 'components/textareafield/TextAreaAutoSizeField';
import type { UserSelectFieldOption } from 'components/userselectfield/UserSelectField';
import UserSelectField from 'components/userselectfield/UserSelectField';
import type { User } from 'entity/system/types';
import { getDateMaxForTasks } from 'helpers/dateHelpers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

type Props = {
  className?: string;
  isDisabled?: boolean;
  name?: string;
  completedDateTime?: string;
  deadlineDate?: string;
  users?: UserSelectFieldOption[];
  assignedUser?: User;
  isNew?: boolean;
  isNameFocused?: boolean;
  placeholderText?: string;
  buttonSaveText?: string;
  buttonCancelText?: string;
  labelAssignUserText?: string;
  labelDeadlineDateText?: string;
  labelDeleteText?: string;
  onCheckboxChange?: (checked: boolean) => void;
  onDelete?: () => void;
  onSave: (values: any) => void;
  onCancel?: () => void;
};
/**
 * Task list item editor component
 *
 * @param {string} className
 * @param {boolean} isDisabled
 * @param {string} name
 * @param {string} completedDateTime
 * @param {string} deadlineDate
 * @param {object} users
 * @param {object} assignedUser
 * @param {boolean} isNew
 * @param {boolean} isNameFocused
 * @param {string} placeholderText
 * @param {string} buttonSaveText
 * @param {string} buttonCancelText
 * @param {string} labelAssignUserText
 * @param {string} labelDeadlineDateText
 * @param {string} labelDeleteText
 * @param {function} onCheckboxChange
 * @param {function} onDelete
 * @param {function} onSave
 * @param {function} onCancel
 * @return {*}
 * @constructor
 */
const TaskListItemEdit: React.FC<Props> = ({
  className,
  isDisabled,
  name,
  completedDateTime,
  deadlineDate,
  users,
  assignedUser,
  isNew,
  isNameFocused,
  placeholderText,
  buttonSaveText,
  buttonCancelText,
  labelAssignUserText,
  labelDeadlineDateText,
  labelDeleteText,
  onCheckboxChange,
  onDelete,
  onSave,
  onCancel,
}) => {
  const [nameValue, setNameValue] = useState<string | undefined>(name);
  /** @type {object|null|undefined} */
  const [assignedUserValue, setAssignedUserValue] = useState<any[] | null>(null);
  const [deadlineDateValue, setDeadlineDateValue] = useState<string | null | undefined>(
    deadlineDate || null,
  );

  const delayedDeadline = deadlineDateValue ? moment(deadlineDateValue).isBefore() : false;

  // set assigned user in correct model for user select.
  useEffect(() => {
    if (assignedUser) {
      setAssignedUserValue([
        {
          label: assignedUser.fullName,
          value: assignedUser.id,
          email: assignedUser.email,
          image: assignedUser.avatarUrl,
        },
      ]);
    } else {
      setAssignedUserValue(null);
    }
  }, [assignedUser]);

  useEffect(() => setNameValue(name), [name]);
  useEffect(() => setDeadlineDateValue(deadlineDate || null), [deadlineDate]);

  const doSaveEvent = () => {
    const selectedUser =
      assignedUserValue && assignedUserValue.length === 1 ? assignedUserValue[0] : undefined;
    const returnVal = {
      name: nameValue,
      deadlineDate: deadlineDateValue,
      assignedUser: selectedUser
        ? {
            id: selectedUser.value,
            email: selectedUser.email,
            fullName: selectedUser.label,
            avatarUrl: selectedUser.image,
          }
        : null,
    };
    onSave(returnVal);
  };

  return (
    <div className={classNames('c-task-list-item-edit', className)}>
      <div className="c-task-list-item-edit__top-bar">
        {isNew ? (
          <CheckBox
            className="c-task-list-item-edit__checkbox"
            isChecked={false}
            isDisabled={isDisabled}
            onChange={() => null}
          />
        ) : (
          <CheckBox
            className="c-task-list-item-edit__checkbox"
            isChecked={!!completedDateTime}
            isDisabled={isDisabled}
            onChange={(checked) => onCheckboxChange?.(checked)}
          />
        )}
        <TextAreaAutoSizeField
          className={classNames('c-task-list-item-edit__title', {
            'c-task-list-item__title--completed': !!completedDateTime,
          })}
          isDisabled={isDisabled}
          isFocused={isNameFocused}
          maxLength={255}
          placeholder={placeholderText}
          value={nameValue}
          onChange={setNameValue}
          onEnter={(nextValue) => {
            setNameValue(nextValue);
            doSaveEvent();
          }}
        />
        {!isNew && (
          <div className="c-task-list-item-edit__close-button">
            <IconButton
              hasBackground={false}
              icon={<CloseIcon />}
              isDisabled={isDisabled}
              size={20}
              variant="secondary"
            />
          </div>
        )}
      </div>
      <FormField appearance={FormFieldAppearance.INLINE} label={labelAssignUserText}>
        <UserSelectField
          isClearable
          className="c-task-list-item-edit__avatar"
          isDisabled={isDisabled}
          isMulti={false}
          options={users ?? []}
          value={assignedUserValue || []}
          onChange={setAssignedUserValue}
        />
      </FormField>
      <FormField appearance={FormFieldAppearance.INLINE} label={labelDeadlineDateText}>
        <DatePicker
          disabled={isDisabled}
          hasError={delayedDeadline}
          isClearable={!isDisabled}
          isLabelHidden={true}
          label=""
          maxDate={getDateMaxForTasks()}
          name="deadlineDate"
          placeholder=""
          selectedDate={deadlineDateValue ? moment(deadlineDateValue).toDate() : null}
          onChange={(value) => {
            if (value) {
              setDeadlineDateValue(moment(value).format('YYYY-MM-DD'));
            }
          }}
          onClear={() => {
            setDeadlineDateValue(null);
          }}
        />
      </FormField>
      <div className="c-task-list-item-edit__actions">
        <ButtonGroup>
          <Button
            className="c-task-list-item-edit__save-button"
            isDisabled={isDisabled || !(nameValue && nameValue.length > 0)}
            size="S"
            onClick={doSaveEvent}
          >
            {buttonSaveText}
          </Button>
          <Button
            className="c-task-list-item-edit__cancel-button"
            isDisabled={isDisabled}
            size="S"
            variant="clear"
            onClick={onCancel}
          >
            {buttonCancelText}
          </Button>
        </ButtonGroup>
        {!isNew && (
          <div className="c-task-list-item-edit__delete-button">
            <IconButton
              hasBackground={false}
              icon={
                <svg fill="none" height="20" viewBox="0 0 12 16" width="20">
                  {/* eslint-disable-next-line max-len */}
                  <path d="M11.7109 1.71094H8.57031V1.14062C8.57031 0.507812 8.0625 0 7.42969 0H4.57031C3.9375 0 3.42969 0.507812 3.42969 1.14062V1.71094H0.289062C0.125 1.71094 0 1.84375 0 2C0 2.15625 0.125 2.28125 0.289062 2.28125H0.570312L1.71094 14.8594C1.71094 15.4844 2.22656 16 2.85938 16H9.14062C9.77344 16 10.2891 15.4844 10.2891 14.8594L11.4297 2.28125H11.7109C11.875 2.28125 12 2.15625 12 2C12 1.84375 11.875 1.71094 11.7109 1.71094ZM4 1.14062C4 0.828125 4.25781 0.570312 4.57031 0.570312H7.42969C7.74219 0.570312 8 0.828125 8 1.14062V1.71094H4V1.14062ZM9.71094 14.8594C9.71094 15.1719 9.46094 15.4297 9.14062 15.4297H2.85938C2.53906 15.4297 2.28906 15.1719 2.28906 14.8594L1.14062 2.28125H10.8594L9.71094 14.8594ZM6 14.2812C6.15625 14.2812 6.28906 14.1562 6.28906 14V3.71094C6.28906 3.55469 6.15625 3.42969 6 3.42969C5.84375 3.42969 5.71094 3.55469 5.71094 3.71094V14C5.71094 14.1562 5.84375 14.2812 6 14.2812ZM4.01562 14.2891C4.17188 14.2734 4.29688 14.1406 4.28906 13.9844L3.71875 3.69531C3.71094 3.53906 3.57031 3.42188 3.41406 3.42969C3.25781 3.4375 3.14062 3.57031 3.14844 3.72656L3.71875 14.0156C3.72656 14.1719 3.85938 14.2969 4.01562 14.2891ZM7.98438 14.2891C8.14062 14.2969 8.27344 14.1719 8.28125 14.0156L8.85156 3.75C8.85938 3.59375 8.74219 3.45312 8.58594 3.44531C8.42969 3.4375 8.28906 3.55469 8.28125 3.71094L7.71094 13.9844C7.70312 14.1406 7.82812 14.2734 7.98438 14.2891Z" />
                </svg>
              }
              isDisabled={isDisabled}
              size={20}
              title={labelDeleteText}
              onClick={onDelete}
            />
          </div>
        )}
      </div>
    </div>
  );
};

TaskListItemEdit.defaultProps = {
  isDisabled: false,
  isNew: false,
  onCheckboxChange: () => null,
  onDelete: () => null,
  onSave: () => null,
  onCancel: () => null,
  placeholderText: 'Schrijf een taaknaam...',
  buttonSaveText: 'Opslaan',
  buttonCancelText: 'Annuleren',
  labelAssignUserText: 'Toewijzen',
  labelDeadlineDateText: 'Einddatum',
  labelDeleteText: 'Verwijderen',
};

export default TaskListItemEdit;
