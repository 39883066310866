import React from 'react';

import { ReactComponent } from './assets/svg/QuestionIcon.svg';
import type { IconComponentProps } from './IconComponent';
import IconComponent from './IconComponent';

const QuestionIcon = (props: IconComponentProps) => IconComponent(props, <ReactComponent />);

QuestionIcon.defaultProps = IconComponent.defaultProps;
QuestionIcon.propTypes = IconComponent.propTypes;

export default QuestionIcon;
