import DataModel, { PropTypes } from 'models/base/DataModel';

import Document from './Document';
import PartialConsideration from './PartialConsideration';
import Source from './Source';
import Tool from './Tool';

/**
 * ContextGroup
 */
export default class ContextGroup extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    name: PropTypes.string,
    type: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape(Source.propTypes),
        PropTypes.shape(Tool.propTypes),
        PropTypes.shape(PartialConsideration.propTypes),
      ]),
    ),
  };

  /**
   * @type {string}
   */
  name;

  /**
   * @type {string}
   */
  type;

  /**
   * @type {PartialConsideration[]|Source[]|Tool[]|undefined}
   */
  items;

  /**
   * Retrieve name of group
   *
   * @returns {string}
   */
  getName() {
    return this.name;
  }

  /**
   * Retrieve type of the group
   *
   * @returns {string}
   */
  getType() {
    return this.type;
  }

  /**
   * Retrieve the items in the group
   *
   * @returns {PartialConsideration[]|Source[]|Tool[]}
   */
  getItems() {
    if (!this.items) {
      this.items = [];
    }

    return this.items;
  }

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'items') {
      switch (this.getType()) {
        case 'tool':
          value = Tool.createInstancesByArray(value);
          break;
        case 'partial_consideration':
          value = PartialConsideration.createInstancesByArray(value);
          break;
        case 'source':
          value = Source.createInstancesByArray(value);
          break;
        default:
          value = Document.createInstancesByArray(value);
          break;
      }
    }

    return super.set(fieldName, value);
  }
}
