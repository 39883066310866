import { useQuery } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import type { StatusGroup } from 'entity/dossiers/types';

/**
 * Returns an array of status groups. Each status group
 * contains a list of statuses.
 */
export const useStatusGroups = (): StatusGroup[] => {
  const query = useQuery({
    queryKey: ['status-groups'],
    queryFn: () => {
      return Actions.getAPIService().status().getStatusGroups();
    },
  });

  return query.data?.data.statusGroups || [];
};
