import { StatusMessage } from '@elseu/sdu-titan';
import NdfrSearch from 'components/ndfr/NdfrSearch';
import ApplicationConstants from 'constants/ApplicationConstants';
import React from 'react';
import type { FormError } from 'utils/FormError';

export type LegalSourceNewItem = {
  name: string;
  url: string;
};

type Props = {
  isShown: boolean;
  errors?: FormError;
  onCreateNewItem: (values: LegalSourceNewItem) => void;
};

const LegalSourceNewNdfr: React.FC<Props> = ({ isShown, errors, onCreateNewItem }) => {
  if (!isShown) {
    return null;
  }

  return (
    <>
      {errors && (
        <StatusMessage spaceAfter={4} type="danger">
          {errors.message}
        </StatusMessage>
      )}
      <NdfrSearch
        onItemSelect={(item: any) => {
          onCreateNewItem({
            name: item.title,
            url: `${ApplicationConstants.APPLICATION_NDFR_DOMAIN}/content/${item.id}`,
          });
        }}
      />
    </>
  );
};

export default LegalSourceNewNdfr;
