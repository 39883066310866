import Actions from 'actions/Actions';
import Card from 'models/dossier/Card';
import Task from 'models/dossier/Task';
import { createContainer, createHook, createStore } from 'react-sweet-state';
import sweetFetchState from 'stores/utils/SweetFetchState';
import sweetPaging from 'stores/utils/SweetPagingState';

// #region state keys
export const STATE_CARDS = 'cards';
export const STATE_CARDS_PHASE_IDS = 'cardsPhaseIds';
export const STATE_CARDS_CREATE = 'cardsCreate';
export const STATE_CARDS_CREATE_BULK = 'cardsCreateBulk';
export const STATE_CARDS_SELECT = 'cardsSelect';
export const STATE_CARDS_UPDATE = 'cardsUpdate';
export const STATE_CARDS_DELETE = 'cardsDelete';

export const STATE_CARDS_TASKLIST_CREATE = 'cardTasklistCreate';
export const STATE_CARDS_TASKLIST_UPDATE = 'cardTasklistUpdate';
export const STATE_CARDS_TASKLIST_DELETE = 'cardTasklistDelete';

export const STATE_CARDS_TASK_CREATE = 'cardTaskCreate';
export const STATE_CARDS_TASK_UPDATE = 'cardTaskUpdate';
export const STATE_CARDS_TASK_DELETE = 'cardTaskDelete';

export const STATE_CARDS_COMMENTS = 'cardComments';
export const STATE_CARDS_COMMENTS_CREATE_STATE = 'cardCommentCreateState';
export const STATE_CARDS_COMMENTS_UPDATE_STATE = 'cardCommentUpdateState';
export const STATE_CARDS_COMMENTS_DELETE_STATE = 'cardCommentDeleteState';
// #endregion

// special card is for phases. (used for getting card information)
export const CARD_ANALYSE_ID = 'analyse';
export const CARD_INVENTORY_ID = 'inventory';
export const CARD_ADVICE_ID = 'advice';
export const CARD_EXECUTION_ID = 'execution';

const CARD_PHASE_KEYS = [CARD_ANALYSE_ID, CARD_INVENTORY_ID, CARD_ADVICE_ID, CARD_EXECUTION_ID];

// #region initial State
const initialState = {
  // keeps the ids of the cards of a given id. (this is used for updating cards details to the correct cardId)
  [STATE_CARDS_PHASE_IDS]: {},

  [STATE_CARDS]: sweetPaging.init(),
  [STATE_CARDS_SELECT]: sweetFetchState.init(),
  [STATE_CARDS_CREATE]: sweetFetchState.init(),
  [STATE_CARDS_CREATE_BULK]: sweetFetchState.init(),
  [STATE_CARDS_UPDATE]: sweetFetchState.init(),
  [STATE_CARDS_DELETE]: sweetFetchState.init(),
  // task list
  [STATE_CARDS_TASKLIST_CREATE]: sweetFetchState.init(),
  [STATE_CARDS_TASKLIST_UPDATE]: sweetFetchState.init(),
  [STATE_CARDS_TASKLIST_DELETE]: sweetFetchState.init(),
  // task list
  [STATE_CARDS_TASK_CREATE]: sweetFetchState.init(),
  [STATE_CARDS_TASK_UPDATE]: sweetFetchState.init(),
  [STATE_CARDS_TASK_DELETE]: sweetFetchState.init(),
  // comments
  [STATE_CARDS_COMMENTS]: sweetPaging.init(),
  [STATE_CARDS_COMMENTS_CREATE_STATE]: sweetFetchState.init(),
  [STATE_CARDS_COMMENTS_UPDATE_STATE]: sweetFetchState.init(),
  [STATE_CARDS_COMMENTS_DELETE_STATE]: sweetFetchState.init(),
};
// #endregion

/**
 *
 *
 * @param {string} dossierId
 * @param {Card} card
 * @param {string} phase
 */
const dispatchSetCardIdToSpecificPhase =
  (dossierId, card, phase) =>
  ({ getState, setState }) => {
    const cardPhaseIds = getState()[STATE_CARDS_PHASE_IDS];

    if (!cardPhaseIds[dossierId]) {
      cardPhaseIds[dossierId] = {};
    }
    cardPhaseIds[dossierId][phase] = card.id;

    setState({ [STATE_CARDS_PHASE_IDS]: cardPhaseIds });
  };

/**
 * Add a card to the select and page.
 *
 * @param {string} dossierId
 * @param {Card} card
 */
const dispatchAddCardToSelectAndPage =
  (dossierId, card) =>
  ({ getState, dispatch }) => {
    const selectCardId = getCardSelectId(getState(), dossierId, card.id);
    const { setContext: setContextSelect } = sweetFetchState.withContextId(
      STATE_CARDS_SELECT,
      selectCardId,
      dispatch,
      getState,
    );
    setContextSelect(card);
    dispatch(sweetPaging.addItem(STATE_CARDS, card));
  };

/**
 * Get correct context id for a card.
 *
 * @param {object} state
 * @param {string} dossierId
 * @param {string} cardId
 * @return {string}
 */
const getCardSelectId = (state, dossierId, cardId) => {
  const cardPhaseIds = state[STATE_CARDS_PHASE_IDS];
  let retValue = cardId;

  if (cardPhaseIds[dossierId]) {
    const dossierPhases = cardPhaseIds[dossierId];
    for (const phaseKey of CARD_PHASE_KEYS) {
      if (dossierPhases[phaseKey] === cardId) {
        retValue = `${dossierId}_${phaseKey}`;
        break;
      }
    }
  }

  return retValue;
};

const actions = {
  /**
   * Force the whole state to be reset.
   */
  resetState:
    () =>
    ({ setState }) => {
      setState(initialState);
    },

  resetFetchState: sweetFetchState.reset,

  getCardByCardId:
    (dossierId, cardId) =>
    ({ dispatch }) => {
      switch (cardId) {
        case CARD_INVENTORY_ID:
          dispatch(actions.getInventoryCard(dossierId));
          break;
        case CARD_ANALYSE_ID:
          dispatch(actions.getAnalysisCard(dossierId));
          break;
        case CARD_ADVICE_ID:
          dispatch(actions.getAdviceCard(dossierId));
          break;
        case CARD_EXECUTION_ID:
          dispatch(actions.getExecutionCard(dossierId));
          break;
        case 'new':
          break; // skip new card
        default:
          dispatch(actions.getCardDetails(cardId));
          break;
      }
    },

  /**
   * Get details of a specific card.
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @returns {function(...[*]=)}
   */
  getCardDetails:
    (cardId) =>
    ({ getState, dispatch }) => {
      const { doRequest, isBusy, setContext } = sweetFetchState.withContextId(
        STATE_CARDS_SELECT,
        cardId,
        dispatch,
        getState,
      );

      if (isBusy()) {
        return;
      }

      doRequest(Actions.getAPIService().detailsOfCard(cardId)).then(
        (response) => {
          const card = Card.createInstance(response.getData());
          setContext(card);
          dispatch(sweetPaging.addItem(STATE_CARDS, card));
        },
        () => null,
      );
    },

  /**
   * Get details of an inventory card.
   *
   * @param {string} dossierId
   */
  getInventoryCard:
    (dossierId) =>
    ({ getState, dispatch }) => {
      const cardIdContext = `${dossierId}_${CARD_INVENTORY_ID}`;
      const { doRequest, isBusy, setContext } = sweetFetchState.withContextId(
        STATE_CARDS_SELECT,
        cardIdContext,
        dispatch,
        getState,
      );

      if (isBusy()) {
        return;
      }

      doRequest(Actions.getAPIService().dossierInventory(dossierId)).then((response) => {
        const card = Card.createInstance(response.getData().card);
        setContext(card);
        dispatch(sweetPaging.addItem(STATE_CARDS, card));
        dispatch(dispatchSetCardIdToSpecificPhase(dossierId, card, CARD_INVENTORY_ID));
      });
    },

  /**
   * Get details of an analysis card. it will also return the sections of the cards.
   *
   * @param {string} dossierId
   * @returns {function(...[*]=)}
   */
  getAnalysisCard:
    (dossierId) =>
    ({ getState, dispatch }) => {
      const cardIdContext = `${dossierId}_${CARD_ANALYSE_ID}`;
      const { doRequest, isBusy, setContext } = sweetFetchState.withContextId(
        STATE_CARDS_SELECT,
        cardIdContext,
        dispatch,
        getState,
      );

      if (isBusy()) {
        return;
      }

      doRequest(Actions.getAPIService().dossierAnalysis(dossierId)).then(
        (response) => {
          const responseData = response.getData();
          const card = Card.createInstance(responseData.card);
          setContext(card);
          dispatch(sweetPaging.addItem(STATE_CARDS, card));
          dispatch(dispatchSetCardIdToSpecificPhase(dossierId, card, CARD_ANALYSE_ID));

          const cardSections = responseData.sections;
          const newCards = Card.createInstancesByArray([
            ...cardSections.facts.cards,
            ...cardSections.considerations.cards,
            ...cardSections.results.cards,
          ]);

          // add cards to the select and items state.
          newCards.forEach((newCard) =>
            dispatch(dispatchAddCardToSelectAndPage(dossierId, newCard)),
          );
        },
        () => null,
      );
    },

  /**
   * Get details of an advice card. it will also return the sections of the cards.
   *
   * @param {string} dossierId
   * @returns {function(...[*]=)}
   */
  getAdviceCard:
    (dossierId) =>
    ({ getState, dispatch }) => {
      const cardIdContext = `${dossierId}_${CARD_ADVICE_ID}`;
      const { doRequest, isBusy, setContext } = sweetFetchState.withContextId(
        STATE_CARDS_SELECT,
        cardIdContext,
        dispatch,
        getState,
      );

      if (isBusy()) {
        return;
      }

      doRequest(Actions.getAPIService().dossierAdvice(dossierId)).then((response) => {
        const card = Card.createInstance(response.getData().card);
        setContext(card);
        dispatch(sweetPaging.addItem(STATE_CARDS, card));
        dispatch(dispatchSetCardIdToSpecificPhase(dossierId, card, CARD_ADVICE_ID));
      });
    },

  /**
   * Get details of an analysis card. it will also return the sections of the cards.
   *
   * @param {string} dossierId
   * @returns {function(...[*]=)}
   */
  getExecutionCard:
    (dossierId) =>
    ({ getState, dispatch }) => {
      const cardIdContext = `${dossierId}_${CARD_EXECUTION_ID}`;
      const { doRequest, isBusy, setContext } = sweetFetchState.withContextId(
        STATE_CARDS_SELECT,
        cardIdContext,
        dispatch,
        getState,
      );

      if (isBusy()) {
        return;
      }

      doRequest(Actions.getAPIService().dossierExecution(dossierId)).then(
        (response) => {
          const card = Card.createInstance(response.getData().card);
          setContext(card);
          dispatch(sweetPaging.addItem(STATE_CARDS, card));
          dispatch(dispatchSetCardIdToSpecificPhase(dossierId, card, CARD_EXECUTION_ID));
        },
        () => null,
      );
    },

  /**
   * Create cards in bulk
   *
   * @param {string} dossierId
   * @param {any} cards
   * @returns {function(...[*]=)}
   */
  createCardsBulk:
    (dossierId, cards) =>
    ({ getState, dispatch }) => {
      const { doRequest, isBusy } = sweetFetchState.withContextId(
        STATE_CARDS_CREATE_BULK,
        dossierId,
        dispatch,
        getState,
      );

      if (isBusy()) {
        return;
      }

      doRequest(Actions.getAPIService().createCardBulk(dossierId, { cards })).then(
        (response) => {
          if (response.isSuccess()) {
            const newCards = Card.createInstancesByArray(response.getData().cards);
            newCards.forEach((newCard) => dispatch(sweetPaging.addItem(STATE_CARDS, newCard)));
          }
        },
        () => null,
      );
    },

  /**
   * Update card
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {any} values
   * @returns {function(...[*]=)}
   */
  updateCard:
    (dossierId, cardId, values) =>
    ({ getState, dispatch }) => {
      const selectCardId = getCardSelectId(getState(), dossierId, cardId);
      const { doRequest, isBusy, setContext } = sweetFetchState.withContextId(
        STATE_CARDS_UPDATE,
        cardId,
        dispatch,
        getState,
      );
      const { getContext: getContextSelect, setContext: setContextSelect } =
        sweetFetchState.withContextId(STATE_CARDS_SELECT, selectCardId, dispatch, getState);

      if (isBusy()) {
        return;
      }

      doRequest(Actions.getAPIService().updateCard(cardId, values)).then(
        (response) => {
          if (response.isSuccess()) {
            const card = getContextSelect();
            const responseCard = Card.createInstance(response.getData());
            const newCard = Card.createInstance({ ...card, ...responseCard });

            setContext(newCard);
            setContextSelect(newCard);
            dispatch(sweetPaging.addItem(STATE_CARDS, newCard));

            // is the card type task .update the context of card task update
            if (newCard.type === 'task' && newCard.taskList) {
              const { setContext: setContextTask } = sweetFetchState.withContextId(
                STATE_CARDS_TASK_UPDATE,
                cardId,
                dispatch,
                getState,
              );
              setContextTask(
                Task.createInstance({
                  id: newCard.id,
                  name: newCard.name,
                  deadlineDate: newCard.deadlineDate,
                  completedDateTime: newCard.completedDateTime,
                  assignedUser: newCard.assignedUser,
                  position: newCard.positionY,
                  taskListId: newCard.taskList.id,
                }),
              );
            }
          }
        },
        () => null,
      );
    },
};
// #endregion

const Store = createStore({
  name: 'CardState',
  initialState,
  actions,
});

export const CardContainer = createContainer(Store, {
  displayName: 'CardContainer',
});

export const useCardState = createHook(Store);
