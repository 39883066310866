import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import { normalizeSignal } from 'entity/signaling/normalizer';
import type { Signal } from 'entity/signaling/types';
import { useMemo } from 'react';

const SIGNAL_STALE_TIME = 60 * 1000; // 1 minute

type UseSignalDetailsReturnType = {
  signal?: Signal;
  query: UseQueryResult;
};

export const useSignalDetails = (
  signalingId: string | undefined,
  signalId: string | undefined,
): UseSignalDetailsReturnType => {
  const query = useQuery({
    queryKey: ['signal', signalingId, signalId],
    queryFn: async () => {
      const res = await Actions.getAPIService()
        .getSignaling()
        .getSignalingSignalDetails(signalingId ?? '', signalId ?? '');
      return res.isSuccess() ? normalizeSignal(res.getData()) : undefined;
    },
    staleTime: SIGNAL_STALE_TIME,
    enabled: !!(signalingId && signalId),
  });

  return useMemo(
    () => ({
      signal: query.data,
      query,
    }),
    [query],
  );
};
