import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';

/**
 * Label API
 */
export default class LabelApi extends APIEndpoint {
  /**
   * Get list of labels
   *
   * @returns {Promise}
   */
  getList() {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request('labels');
  }

  /**
   * Add label
   *
   * @param {object} data
   * @returns {Promise}
   */
  addLabel(data) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'labels',
      JSendRequest.REQUEST_METHOD_POST,
      data,
    );
  }

  /**
   * Modify label
   *
   * @param {string} labelId
   * @param {object} data
   * @returns {Promise}
   */
  modifyLabel(labelId, data) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `labels/${labelId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      data,
    );
  }

  /**
   * Remove label
   *
   * @param {string} labelId
   * @param {object} data
   * @returns {Promise}
   */
  deleteLabel(labelId, data) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `labels/${labelId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
      data,
    );
  }

  /**
   * Update the label priority values for all labels.
   *
   * @param {array} labelIds
   * @returns {Promise}
   */
  updateLabelPriority(labelIds) {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'labels/priority',
      JSendRequest.REQUEST_METHOD_POST,
      { priority: labelIds },
    );
  }
}
