import { ActionMenu, Box, Button, MenuItem, TabContent } from '@elseu/sdu-titan';
import CardSectionAttachmentsState from 'components/card/CardSectionAttachmentsState';
import CardSectionAuditLog from 'components/card/CardSectionAuditLog';
import CardSectionComments from 'components/card/CardSectionComments';
import CardSectionTaskList from 'components/card/CardSectionTaskList';
import { Lazy } from 'components/lazy/Lazy';
import { TicketEditController } from 'components/ticket/TicketEditController';
import { normalizeTaskList } from 'entity/dossiers/normalizer';
import type { Card } from 'entity/dossiers/types';
import { normalizeMap } from 'helpers/normalizehelper';
import { useGetCurrentUser, useProfileSettings } from 'hooks';
import { useDossierDetails } from 'hooks/queries';
import React, { useMemo, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router';
import styled from 'styled-components';

import ApplicationConstants from '../../constants/ApplicationConstants';
import CardContextGroup from './CardContextGroup';
import CardSection from './CardSection';
import CardSectionLegalSource from './CardSectionLegalSource';
import CardSectionLinkDossier, { CardSectionLinkDossierLinkTypes } from './CardSectionLinkDossier';
import { shouldRenderTab, useCardSidebarTabs } from './CardSidebarTabProvider';

export const CardSidebarContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

interface CardSidebarProps {
  card?: Card;
  dossierId: string;
  onUpdate?: () => void;
  onClose?: () => void;
  emptyContent?: React.ReactNode;
}
export const CardSidebar = ({
  card,
  dossierId,
  onUpdate,
  emptyContent = null,
}: CardSidebarProps) => {
  const { dossier } = useDossierDetails(dossierId);
  const [refreshComments, setRefreshComments] = useState(false);
  const [auditLogRefresh, setAuditLogRefresh] = useState(false);
  const [isContextGroupLoading, setIsContextGroupBusy] = useState(false);
  const { tabs } = useCardSidebarTabs();

  const [settings, settingsActions] = useProfileSettings();
  const navigate = useNavigate();
  const profile = useGetCurrentUser();

  const users = settingsActions.getUsers();
  const isCloudLinksEnabled = settings?.isCloudLinksEnabled();
  const dossierUsers = useMemo(() => users.map((item: any) => item.id), [users]);
  const userSuggestions = settingsActions.getUsersForMentions();
  const cloudLinkTypes = settings?.getCloudLinkTypes();

  const canEdit = dossier?.permissions.canEdit || false;

  if (!card) {
    return emptyContent;
  }

  return (
    <>
      {shouldRenderTab('tasks', tabs) && (
        <TabContent id="tasks">
          <CardSectionTaskList
            cardId={card.id}
            dossierId={dossierId}
            dossierTypeMessageText="deze notitie"
            dossierTypeTitleText="notitie"
            dossierUsers={dossierUsers}
            hasToConfirmUserLink={false}
            isDisabled={false}
            isReadOnly={!canEdit}
            newTaskListNamePrefill={card.name ? `Takenlijst ${card.name}` : undefined}
            taskLists={normalizeMap(card.taskLists || [], normalizeTaskList)}
            users={users}
            onTaskItemClick={(task) => {
              navigate(`tickets/${task.ticket?.id}`);
            }}
            onUpdate={() => {
              setAuditLogRefresh(true);
            }}
          />
        </TabContent>
      )}

      {shouldRenderTab('attachments', tabs) && (
        <TabContent id="attachments">
          <CardSectionAttachmentsState
            attachments={card.attachments || []}
            cardId={card.id || ''}
            cloudAttachments={card.cloudAttachments || []}
            cloudLinks={card.cloudLinks || []}
            cloudLinkTypes={cloudLinkTypes}
            dossierId={dossierId}
            isOneDriveEnabled={isCloudLinksEnabled}
            isReadOnly={!canEdit}
            onCardNeedsUpdate={() => {
              setAuditLogRefresh(true);
              onUpdate?.();
            }}
          />
        </TabContent>
      )}

      {shouldRenderTab('comments', tabs) && (
        <TabContent id="comments">
          <CardSectionComments
            editorHideAttachments
            cardId={card.id}
            dossierId={dossierId}
            dossierTypeMessageText="deze adviessignalering"
            dossierTypeTitleText="adviessignalering"
            dossierUsers={dossierUsers}
            isReadOnly={!canEdit}
            profile={profile}
            shouldRefreshComments={refreshComments}
            userSuggestions={userSuggestions}
            onUpdate={(type) => {
              if (type === 'loaded') {
                setRefreshComments(false);
              }

              if (type === 'deleted' || type === 'created' || type === 'updated') {
                setAuditLogRefresh(true);
              }
            }}
          />
        </TabContent>
      )}

      {shouldRenderTab('auditlog', tabs) && (
        <TabContent id="auditlog">
          <CardSectionAuditLog
            isOpen
            shouldHideOpenButton
            cardId={card.id}
            isRefresh={auditLogRefresh}
            onLoaded={() => setAuditLogRefresh(false)}
          />
        </TabContent>
      )}

      {shouldRenderTab('legal', tabs) && (
        <TabContent id="legal">
          <CardSectionLegalSource
            cardId={card.id}
            dossierId={dossierId}
            isDisabled={false}
            isReadOnly={!canEdit}
            legalSources={card.legalSources || []}
            onNeedsUpdate={() => {
              setAuditLogRefresh(true);
              onUpdate?.();
            }}
          />
        </TabContent>
      )}

      {shouldRenderTab('links', tabs) && (
        <TabContent id="links">
          <CardSectionLinkDossier
            cardId={card.id}
            dossierId={dossierId}
            isReadOnly={!canEdit}
            relatedCards={card.relatedCards || []}
            showLinkTypes={
              card.type === 'contact_note'
                ? [CardSectionLinkDossierLinkTypes.NORMAL]
                : [CardSectionLinkDossierLinkTypes.CONTACT_NOTE]
            }
            title={card.type === 'contact_note' ? 'Dossier(s)' : 'Contact-notities'}
            onCardsNeedsUpdate={() => {
              setAuditLogRefresh(true);
              onUpdate?.();
            }}
          />
        </TabContent>
      )}

      {shouldRenderTab('tools', tabs) && (
        <TabContent id="tools">
          <CardContextGroup
            canEdit={canEdit}
            cardId={card.id}
            contextGroups={card.contextGroups || []}
            dossierId={dossierId}
            isCloudLinksEnabled={isCloudLinksEnabled}
            isDisabled={!canEdit || isContextGroupLoading}
            modelTypes={settings.modelTypes}
            renderAs="tree"
            selectedTopics={dossier?.topics || []}
            stage={card.type}
            title="Tools en modellen"
            topics={settings.topics || []}
            onToolUpdated={(action) => {
              switch (action) {
                case 'tool-as-attachment:busy':
                case 'create-own-context:busy':
                case 'berkley-new-session:busy':
                case 'own-tool-as-attachment:busy':
                  setIsContextGroupBusy(true);
                  break;
                case 'tool-as-attachment:completed':
                case 'create-own-context:completed':
                case 'berkley-new-session:completed':
                case 'own-tool-as-attachment:completed':
                case 'own-tool:delete':
                  setIsContextGroupBusy(false);
                  onUpdate?.();
                  break;
                case 'create-own-context:error':
                case 'berkley-new-session:error':
                  setIsContextGroupBusy(false);
                  break;
                case 'tool-as-attachment:error':
                case 'own-tool-as-attachment:error':
                  setIsContextGroupBusy(false);
                  break;
                default:
              }
            }}
          />
        </TabContent>
      )}

      {shouldRenderTab('export', tabs) && (
        <TabContent id="export">
          <CardSection title="Analyse document">
            <ActionMenu
              label="Exporteer analyse document"
              popoverPlacement="bottom-start"
              trigger={
                <Button isFullWidth isDisabled={false}>
                  Exporteer analyse document
                </Button>
              }
            >
              <MenuItem
                item={{
                  label: 'Export naar PDF',
                }}
                onClick={() => {
                  window.open(
                    `${ApplicationConstants.APPLICATION_API_BASE_HOST}dossiers/${dossierId}/advice/export`,
                    '_blank',
                  );
                }}
              />
              <MenuItem
                item={{
                  label: 'Export naar Word',
                }}
                onClick={() => {
                  window.open(
                    `${ApplicationConstants.APPLICATION_API_BASE_HOST}dossiers/${dossierId}/advice/export_word`,
                    '_blank',
                  );
                }}
              />
            </ActionMenu>
          </CardSection>
        </TabContent>
      )}

      <Routes>
        <Route
          element={
            <Lazy>
              <TicketEditController
                onUpdate={() => {
                  setAuditLogRefresh(true);
                  onUpdate?.();
                }}
              />
            </Lazy>
          }
          path="tickets/:ticketId/*"
        />
      </Routes>
    </>
  );
};
