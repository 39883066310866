import { Avatar, Box, Flex, Heading, Text } from '@elseu/sdu-titan';
import TaskDeadlineDate from 'components/task/TaskDeadlineDate';
import { TicketStatusTag } from 'components/ticket/TicketStatusTag';
import { TicketTypeTag } from 'components/ticket/TicketTypeTag';
import { ticketTypeObject } from 'constants/TicketTypeObject';
import type { Ticket } from 'entity/dossiers/types';
import React from 'react';

import { useTicketStatuses } from './hooks/useTicketStatuses';
import { TicketActions } from './TicketActions';
import { TicketMetaData } from './TicketMetaData';
import { TicketParent } from './TicketParent';
import { clientsToClientLabel } from './utils/clientsToClientLabel';
import { formatCardName } from './utils/formatCardName';

const TicketCardContent: React.FC<{ ticket: Ticket }> = ({ ticket }) => {
  const { getStatusesFor } = useTicketStatuses();
  const possibleStatuses = getStatusesFor(ticket.type, ticket.status?.statusGroup?.id);
  return (
    <>
      <Flex gap={2} justifyContent="space-between" spaceAfter={4}>
        <Flex alignItems="start" flexWrap="wrap" gap={2}>
          <TicketTypeTag ticketType={ticket.type} />
          {possibleStatuses.length > 1 && <TicketStatusTag {...ticket} />}
        </Flex>
        <TicketActions ticket={ticket} />
      </Flex>
      <Heading heading="h4" spaceAfter={1}>
        {formatCardName(ticket.name)}
      </Heading>
      <Text isBlock color="grey70" spaceAfter={1} type="label">
        {clientsToClientLabel(ticket.clients)}
      </Text>
      <Box spaceAfter={5}>
        {!!ticket.deadline && (
          <TaskDeadlineDate
            date={ticket.deadline}
            format="D MMMM YYYY"
            renderAs="text"
            spaceAfter={1}
          />
        )}
      </Box>
      <Flex alignItems="center" flexDirection="row-reverse" gap={3} justifyContent="space-between">
        <Flex alignItems="center" gap={3}>
          <TicketMetaData {...ticket.metaData} />
          <Avatar size={24} src={ticket.assignee?.avatarUrl} />
        </Flex>
        <TicketParent icon={ticketTypeObject.dossier.icon} ticket={ticket} />
      </Flex>
    </>
  );
};

export default TicketCardContent;
