import DataModel, { PropTypes } from 'models/base/DataModel';
import Profile from 'models/me/Profile';

import AttachmentFolder from '../attachment/AttachmentFolder';

/**
 * Berkley Model Session
 */
export default class BerkleyModelSession extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    name: PropTypes.string,
    dossierId: PropTypes.string,
    cardId: PropTypes.string,
    berkleyModelId: PropTypes.string,
    berkleySessionId: PropTypes.string,
    createdBy: PropTypes.shape(Profile.propTypes),
    createdDateTime: PropTypes.string,
    attachmentFolder: PropTypes.shape(AttachmentFolder.propTypes),
  };

  /**
   * @type {string}
   */
  name;

  /**
   * @type {string}
   */
  dossierId;

  /**
   * @type {string}
   */
  cardId;

  /**
   * @type {string}
   */
  berkleyModelId;

  /**
   * @type {string}
   */
  berkleySessionId;

  /**
   * @type {Profile}
   */
  createdBy;

  /**
   * @type {string}
   */
  createdDateTime;

  /**
   * @type {AttachmentFolder|undefined}
   */
  attachmentFolder;

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'createdBy') {
      if (value !== null && value !== undefined) {
        value = Profile.createInstance(value);
      }
    } else if (fieldName === 'attachmentFolder') {
      value = AttachmentFolder.createInstance(value);
    }
    return super.set(fieldName, value);
  }
}
