import './assets/styles/TagListItem.scss';

import classNames from 'classnames';
import RemoveIcon from 'components/icons/RemoveIcon';
import React from 'react';

type Props = React.PropsWithChildren<{
  className?: string;
  element?: string;
  canDelete?: boolean;
  onDelete: () => void;
}>;

/**
 * Tag list item component
 */
const TagListItem: React.FC<Props> = ({
  className,
  element = 'span',
  canDelete = false,
  onDelete = () => null,
  children,
}) => {
  return React.createElement(
    element,
    {
      className: classNames('c-tag-list-item', className, { 'c-tag-list-item__delete': canDelete }),
    },
    [
      children,
      canDelete ? (
        <button className="c-tag-list-item__delete-button" type="button" onClick={onDelete}>
          <RemoveIcon size={12} />
        </button>
      ) : null,
    ],
  );
};

export default TagListItem;
