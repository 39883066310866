import { Input } from '@elseu/sdu-titan';
import React from 'react';
import type { FormError } from 'utils/FormError';

export type LegalSourceFormValues = {
  name?: string;
  comment?: string;
};

type Props = {
  isDisabled?: boolean;
  isReadOnly?: boolean;
  values?: LegalSourceFormValues;
  errors?: FormError;
  onChange: (item: LegalSourceFormValues) => void;
};

const LegalSourceForm: React.FC<Props> = ({ isDisabled, isReadOnly, values, errors, onChange }) => {
  return (
    <>
      <Input
        disabled={isDisabled}
        errorText={errors?.getErrorMessageForField('name')}
        hasError={errors?.hasFieldError('name')}
        label="Naam"
        name="name"
        readOnly={isReadOnly}
        spaceAfter={4}
        type="text"
        value={values?.name}
        onInputChange={(value) => onChange({ ...values, name: value })}
      />
      <Input
        disabled={isDisabled}
        errorText={errors?.getErrorMessageForField('comment')}
        hasError={errors?.hasFieldError('comment')}
        label="Opmerkingen"
        name="comment"
        readOnly={isReadOnly}
        rows={6}
        spaceAfter={8}
        type="textarea"
        value={values?.comment}
        onInputChange={(value) => onChange({ ...values, comment: value })}
      />
    </>
  );
};

export default LegalSourceForm;
