import classNames from 'classnames';
import FormField from 'components/formfield/FormField';
import TextField from 'components/textfield/TextField';
import React, { useEffect, useState } from 'react';

import FormDrawer from './FormDrawer';

type Props = {
  className?: string;
  isDisabled?: boolean;
  title?: string;
  isRequired?: boolean;
  isShown?: boolean;
  label?: string;
  value?: string;
  error?: string;
  zIndex?: number;
  isClearable?: boolean;
  onConfirm: (value?: string) => void;
  onCancel: () => void;
};

/**
 * Input form drawer
 *
 * @param {string} className
 * @param {boolean} isDisabled
 * @param {string} title
 * @param {boolean} isRequired
 * @param {string} label
 * @param {string} value
 * @param {string} error
 * @param {function} onConfirm
 * @param {function} onCancel
 * @returns {*}
 * @constructor
 */
const InputFormDrawer: React.FC<Props> = ({
  className,
  isDisabled,
  isShown,
  title,
  isRequired,
  isClearable,
  label,
  value,
  error,
  zIndex,
  onConfirm,
  onCancel,
}) => {
  const [valueState, setValueState] = useState<string | undefined>(value);
  const [valueErrorState, setValueErrorState] = useState<string | undefined>(undefined);

  useEffect(() => setValueState(value), [value]);
  useEffect(() => setValueErrorState(error), [error]);

  /**
   * On confirm event.
   */
  const onConfirmEvent = () => {
    if (isRequired === true) {
      if (valueState === undefined || valueState.trim().length === 0) {
        setValueErrorState('Dit veld is verplicht.');
        return;
      }
    }
    onConfirm(valueState);
    // reset value state
    setValueState(value);
    setValueErrorState(undefined);
  };

  // render
  return (
    <FormDrawer
      className={classNames('c-form-input-drawer', className)}
      isDisabled={isDisabled}
      isShown={isShown}
      title={title ?? ''}
      zIndex={zIndex}
      onCancel={() => {
        onCancel();
        setValueState(value);
      }}
      onConfirm={() => onConfirmEvent()}
    >
      <FormField error={valueErrorState || ''} label={label}>
        <TextField
          isFocused
          className="c-input-modal__text-field"
          hasError={!!valueErrorState}
          isClearable={isClearable}
          value={valueState}
          onChange={(textValue) => setValueState(textValue)}
          onClear={() => setValueState('')}
          onEnter={() => onConfirmEvent()}
        />
      </FormField>
    </FormDrawer>
  );
};

InputFormDrawer.defaultProps = {
  title: 'Invoer',
  value: '',
  isRequired: true,
  isShown: true,
  onConfirm: (_value) => {
    throw new Error('No onConfirm event given');
  },
  onCancel: () => {
    throw new Error('No onCancel event given');
  },
};

export default InputFormDrawer;
