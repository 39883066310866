import type { Spacings } from '@elseu/sdu-titan';
import { Box, Dropdown, Label, MenuItemOption, Text } from '@elseu/sdu-titan';
import type { DropdownProps } from '@elseu/sdu-titan/dist/types/components/Dropdown/Dropdown';
import type { AttachmentFolder } from 'entity/Attachment/types';
import { sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useBoolean } from 'react-use';
import styled from 'styled-components';
import { useBinding } from 'use-binding';

const depthToSpacing: Spacings[] = [0, 4, 8, 12, 16, 20, 24, 30];

export type AttachmentFolderSelectProps = {
  options: AttachmentFolder[];
  errorText?: string;
  hasError?: boolean;
  label?: string;
  buttonSize?: DropdownProps['buttonSize'];
  name: string;
  isLabelHidden?: boolean;
  isDisabled?: boolean;
  value?: string | null;
  disabledOptions?: string[];
  onChange: (value: string | undefined | null) => void;
  spaceAfter: Spacings;
};

type AttachmentFolderWithDepth = AttachmentFolder & {
  depth: number;
  children: AttachmentFolderWithDepth[];
};

type AttachmentFolderItemProps = {
  option: AttachmentFolderWithDepth;
  isChecked: boolean;
  isDisabled?: boolean;
  onChange: () => void;
};

type AttachmentFolderItemTreeProps = {
  isDisabled?: boolean;
  disabledOptions: string[];
  options: AttachmentFolderWithDepth[];
  selectedValue: string | undefined | null;
  onChange: (item: AttachmentFolderWithDepth) => void;
};

const getDepthOfItem = (item: AttachmentFolder, items: AttachmentFolder[]) => {
  if (item.parentAttachmentFolderId === undefined) {
    return 0;
  }

  let counter = 0;
  let lastParent = items.find((item1) => item1.id === item.parentAttachmentFolderId);

  while (counter < 10) {
    if (lastParent === undefined) {
      break;
    }

    counter++;
    lastParent = items.find((item1) => item1.id === lastParent?.parentAttachmentFolderId);
  }

  return counter;
};

const getParentItems = (
  parentId: string | undefined | null,
  items: AttachmentFolder[],
): AttachmentFolderWithDepth[] => {
  return sortBy(
    items
      .filter((item) => item.parentAttachmentFolderId === parentId)
      .map((item) => ({
        ...item,
        children: getParentItems(item.id, items),
        depth: getDepthOfItem(item, items),
      })),
    ['name'],
  );
};

const DropdownContainer = styled(Box)`
  & > Button {
    width: 100%;
    max-width: unset;
  }
`;

const DropdownItemsContainer = styled(Box)`
  max-height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const AttachmentFolderItem: React.FC<AttachmentFolderItemProps> = ({
  option,
  isChecked = false,
  isDisabled,
  onChange,
}) => {
  return (
    <Box pl={depthToSpacing[option.depth]}>
      <MenuItemOption
        isChecked={isChecked}
        isDisabled={isDisabled}
        item={{
          label: option.name ?? '',
          value: undefined,
        }}
        role="option"
        onClick={() => onChange()}
      />
    </Box>
  );
};

const AttachmentFolderItemTree: React.FC<AttachmentFolderItemTreeProps> = ({
  options,
  selectedValue,
  disabledOptions,
  isDisabled = false,
  onChange,
}) => {
  return options.map((option) => (
    <React.Fragment key={option.id}>
      <AttachmentFolderItem
        isChecked={option.id === selectedValue}
        isDisabled={disabledOptions.includes(option.id ?? '') || isDisabled}
        option={option}
        onChange={() => onChange(option)}
      />
      <AttachmentFolderItemTree
        disabledOptions={disabledOptions}
        isDisabled={disabledOptions.includes(option.id ?? '')}
        options={option.children}
        selectedValue={selectedValue}
        onChange={onChange}
      />
    </React.Fragment>
  ));
};

export const AttachmentFolderSelect: React.FC<AttachmentFolderSelectProps> = ({
  isDisabled,
  hasError,
  errorText,
  isLabelHidden,
  buttonSize,
  label,
  options,
  disabledOptions = [],
  value,
  spaceAfter,
  onChange,
}) => {
  const [isShown, setIsShown] = useBoolean(false);
  const [valueState, setValueState] = useBinding<string | undefined | null>(
    undefined,
    value,
    onChange,
  );
  const optionsTree = useMemo(() => {
    return getParentItems(undefined, options);
  }, [options]);

  const currentValue = useMemo(() => {
    if (valueState === null) {
      return '(Hoofdmap)';
    }

    return valueState ? options.find((option) => option.id === valueState)?.name : undefined;
  }, [options, valueState]);

  return (
    <Box spaceAfter={spaceAfter}>
      {!isLabelHidden && (
        <Label isHidden={isLabelHidden} spaceAfter={2}>
          {label}
        </Label>
      )}
      <DropdownContainer>
        <Dropdown
          buttonSize={buttonSize}
          buttonText={currentValue || 'Selecteer een map'}
          buttonVariant="border"
          isDisabled={isDisabled}
          isShown={isShown}
          popoverWidth={400}
          onToggle={setIsShown}
        >
          <DropdownItemsContainer>
            <AttachmentFolderItem
              isChecked={valueState === null}
              option={{
                id: '<NULL>',
                name: '(Hoofdmap)',
                depth: 0,
                children: [],
              }}
              onChange={() => {
                onChange(null);
                setIsShown(false);
              }}
            />
            <AttachmentFolderItemTree
              disabledOptions={disabledOptions}
              options={optionsTree}
              selectedValue={valueState}
              onChange={(item) => {
                setValueState(item.id ?? '');
                setIsShown(false);
              }}
            />
          </DropdownItemsContainer>
        </Dropdown>
      </DropdownContainer>
      {errorText && hasError && (
        <Text isBlock color="danger50" type="labelTiny">
          {errorText}
        </Text>
      )}
    </Box>
  );
};
