import { Loader } from '@elseu/sdu-titan';
import { format } from 'date-fns';
import type { Card, Task } from 'entity/dossiers/types';
import { useTaskDetails } from 'hooks/queries/useTaskDetails';
import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import type { TaskPayload } from 'services/api/TaskApi';

import { useTrySaveForm } from '../../hooks/useTrySaveForm';
import type { TicketFormSubmitHandler } from '../ticket/forms/TicketFormProps';
import { useTaskCreate } from './forms/hooks/useTaskCreate';
import { useTaskUpdate } from './forms/hooks/useTaskUpdate';
import type { TaskFormValues } from './forms/TaskForm';
import { TaskForm } from './forms/TaskForm';

const mapTaskToTaskFormValues = (
  task?: Card,
  defaultValues: TaskFormValues = {},
): TaskFormValues => {
  if (!task) {
    return {
      name: '',
      assignedUser: '',
      createdBy: '',
      deadlineDate: null,
      client: null,
      dossier: null,
      status: '',
      notes: '',
      ...defaultValues,
    };
  }

  return {
    id: task.id,
    name: task.name,
    assignedUser: task.assignedUser?.id,
    createdBy: task.createdBy?.id,
    deadlineDate: task.deadlineDate,
    client: task.client
      ? { label: task.client.displayName || '', value: task.client.id || '' }
      : null,
    dossier: task.dossier ? { label: task.dossier.name || '', value: task.dossier.id || '' } : null,
    status: task.label?.id,
    notes: task.notes || '',
  };
};

const mapTaskFormValuesToTaskPayload = (values: TaskFormValues): TaskPayload => ({
  position: 0,
  ...values,
  clientId: values.client?.value,
  deadlineDate: values.deadlineDate && format(values.deadlineDate, 'yyyy-MM-dd'),
});

interface TaskEditProps {
  taskId?: string;
  defaultValues?: TaskFormValues;
  onSuccess?: (task: Task) => void;
}
export const TaskEditController = ({ taskId, defaultValues, onSuccess }: TaskEditProps) => {
  const { reset } = useFormContext();
  const { task } = useTaskDetails(taskId);
  const mode = taskId ? 'edit' : 'create';

  useEffect(() => {
    reset(mapTaskToTaskFormValues(task, defaultValues));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, task]);

  const catchFormError = useTrySaveForm();

  const { mutateAsync: createTask, isSuccess: isCreated } = useTaskCreate();
  const { mutateAsync: updateTask } = useTaskUpdate();

  const handleSubmit: TicketFormSubmitHandler<TaskFormValues> = useCallback(
    async (formValues, form) => {
      // TODO: check if assignedUser in formValues is already in dossier, if not check with confirm

      const taskPayload = mapTaskFormValuesToTaskPayload(formValues);

      await catchFormError<TaskFormValues>(
        async () => {
          const data = await (mode === 'edit'
            ? updateTask({ id: taskId, task: taskPayload })
            : createTask({ task: taskPayload }));
          onSuccess?.(data.data);
        },
        form,
        'Taak opgeslagen',
      );
    },
    [catchFormError, createTask, mode, onSuccess, taskId, updateTask],
  );

  const showSpinner = mode === 'edit' && !task && !isCreated;

  if (showSpinner) {
    return <Loader height={48} variant="spinner" />;
  }

  return <TaskForm mode={mode} onSubmit={handleSubmit} />;
};
