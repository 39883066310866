import './assets/styles/RadioGroup.scss';

import React from 'react';
import { RadioGroup as ReactRadioGroup } from 'react-radio-group';

type Props = React.PropsWithChildren<{
  name?: string;
  error?: string | boolean;
  selected?: string | number | boolean;
  onChange: (value: any) => void;
}>;

/**
 * RadioGroup
 */
const RadioGroup: React.FC<Props> = ({ name, error, selected, onChange, children }) => (
  /* @ts-ignore (supresses 'ReactRadioGroup' cannot be used as a JSX component.) */
  <ReactRadioGroup
    className="c-radio-group"
    name={name}
    selectedValue={selected}
    onChange={onChange}
  >
    <>
      {children}
      {error && <span className="error__reason">{error}</span>}
    </>
  </ReactRadioGroup>
);

export default RadioGroup;
