import type { DebouncedFunc } from 'lodash';
import { debounce } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

interface SubmitOnChangeFormProps<FormValues extends FieldValues> {
  onSubmit: SubmitHandler<FormValues>;
}
export const SubmitOnChangeForm: React.FC<
  React.PropsWithChildren<SubmitOnChangeFormProps<any>>
> = ({ children, onSubmit }) => {
  const {
    watch,
    formState: { isValid },
  } = useFormContext();

  const handleSubmit: DebouncedFunc<SubmitHandler<any>> = useMemo(
    () =>
      debounce(async (formValues: any) => {
        onSubmit(formValues);
      }, 2000),
    [onSubmit],
  );

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      if (type !== 'change') return;
      handleSubmit(value);
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, isValid, watch]);

  return <form>{children}</form>;
};
