import { useMutation } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import type { ContactNotePayload } from 'services/api/ContactNoteApi';

import type { ContactNote } from '../../../entity/contactnote/types';

interface useContactNoteMutationParams {
  id?: ContactNote['id'];
  contactNote: ContactNotePayload;
}

export const useContactNoteUpdate = () => {
  return useMutation({
    mutationFn: ({ id, contactNote }: useContactNoteMutationParams) =>
      Actions.getAPIService().contactNotes().updateContactNote(id, contactNote),
  });
};
