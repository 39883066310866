import { useQueryClient } from '@tanstack/react-query';
import { useInvalidateFileManagerQuery } from 'components/filemanager/hooks/useInvalidateFileManager';

type useInvalidateCardReturn = ({
  dossierId,
  cardId,
}: {
  dossierId?: string;
  cardId?: string;
}) => void;

export const useInvalidateCardCache = (): useInvalidateCardReturn => {
  const queryClient = useQueryClient();
  const invalidateFileManagerQuery = useInvalidateFileManagerQuery();

  return ({ dossierId, cardId }) => {
    // invalidate cards with a real id
    queryClient.invalidateQueries({
      queryKey: ['card', cardId],
    });

    // invalidate contact note card caches
    queryClient.invalidateQueries({
      queryKey: ['contactnote', cardId],
    });

    // invalidate card caches where id is 'inventory', 'execution', 'analysis' or 'advice',
    // this is dependent on a dossier id
    queryClient.invalidateQueries({
      queryKey: ['card', dossierId, cardId],
    });

    queryClient.invalidateQueries({
      queryKey: ['card-auditlog', cardId],
    });

    if (dossierId) {
      invalidateFileManagerQuery(dossierId);
    }
  };
};
