import { useAccountState } from 'stores/account/AccountState';

/**
 * Use profile settings hooks
 *
 * @returns {*[]}
 */
export const useProfileSettings = () => {
  const [accountState] = useAccountState();
  /** @type {*|undefined} */
  const settings = accountState.me?.settings;

  /**
   * Get users
   *
   * @returns {*[]}
   */
  const getUsers = () => settings.users || [];

  /**
   * Get users for select field
   *
   * @returns {({image: string, label: string, type: string, value: (string|null), email: string}|*)[]}
   */
  const getUsersForSelectField = () => getUsers().map((user) => user.getSelectOptionObject());

  /**
   * Get users for mentions object in markdown editor.
   *
   * @returns {{avatarUrl: string, name: string, id: (string|null)}[]}
   */
  const getUsersForMentions = () => getUsers().map((user) => user.getUserMentionObject());

  return [settings, { getUsers, getUsersForSelectField, getUsersForMentions }];
};
