import { Block, DatePicker } from '@elseu/sdu-titan';
import type { DatePickerProps } from '@elseu/sdu-titan/dist/types/components/DatePicker/DatePicker';
import { max } from 'lodash';
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { dateTransform } from './helpers/dateTransform';
import { formFieldErrorProps } from './helpers/formFieldErrorProps';

export interface DatePickerFieldProps extends DatePickerProps {
  label: string;
  isErrorHidden?: boolean;
  isFutureDisabled?: boolean;
}

const DatePickerField = ({
  spaceAfter,
  name,
  maxDate,
  minDate,
  isFutureDisabled,
  isErrorHidden,
  ...props
}: DatePickerFieldProps) => {
  const { control } = useFormContext();
  const maxDateValue = useMemo(() => {
    if (maxDate) return dateTransform.input(maxDate) as Date;
    if (isFutureDisabled) {
      return dateTransform.input(new Date()) as Date;
    }
  }, [maxDate, isFutureDisabled]);

  const minDateValue = useMemo(() => {
    return max([minDate, dateTransform.input(minDate) as Date]);
  }, [minDate]);
  return (
    <Block spaceAfter={spaceAfter}>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange, ...field }, formState: { errors } }) => {
          return (
            <DatePicker
              {...formFieldErrorProps(errors, name, isErrorHidden)}
              autoComplete="off"
              defaultSelectedDate={value}
              maxDate={maxDateValue}
              minDate={minDateValue}
              options={{
                mode: 'single',
              }}
              selectedDate={value}
              spellCheck="false"
              onChange={(date) => onChange(dateTransform.output(date))}
              onClear={() => onChange(null)}
              {...field}
              {...props}
            />
          );
        }}
      />
    </Block>
  );
};

DatePickerField.defaultProps = {
  spaceAfter: 4,
};

export { DatePickerField };
