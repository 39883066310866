import { useTitanTableState } from 'helpers/TitanTableHelpers';
import { useFilterState } from 'hooks/useFilterState';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';

/**
 * Hook to manage the ticket filters.
 *
 * Returns the filters, the query filters and the functions to apply and reset the filters,
 * which in turn can be used to fetch tickets.
 */
export const useTicketFilter = () => {
  const { filters: titanFilters } = useTitanTableState({
    stateName: 'tickets-overview',
  });

  // all ticket overviews are default filtered by assigned to the current logged-in user.
  const currentUser = useGetCurrentUser();
  const { filtersValues, filters, applyFilters, resetFilters } = useFilterState({
    defaultFilters: {
      assignedUser: [currentUser.id],
    },
    saveFilter: true,
    filterName: 'tickets-overview',
  });

  return {
    filters,
    queryFilters: [...titanFilters, ...filtersValues],
    applyFilters,
    resetFilters,
  };
};
