import classNames from 'classnames';
import { mentionRegExpGlobal } from 'helpers/mentionhelper';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React from 'react';
import Markdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components';

const StyledMarkDown = styled(Markdown)`
  word-wrap: break-word;

  p {
    & {
      margin: 1rem 0;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    margin-left: 20px;
  }
`;
/**
 * Default plugins for markdown to use.
 *
 * Need more? you can look here for the plugins:
 * https://github.com/remarkjs/remark/blob/main/doc/plugins.md#list-of-plugins
 */
const defaultPlugins = [
  remarkBreaks, // add break support, without needing spaces.
  remarkGfm, // support for literal url and other.
];

const rehypePlugins = [
  [
    // add target=_blank to external links.
    rehypeExternalLinks,
    { rel: ['external', 'nofollow', 'noopener', 'noreferrer'], target: '_blank' },
  ],
];

/**
 * Parse mentions in the markdown.
 */
const parsMentions = (content: string): string => {
  let newContent = content;
  const matches = [...content.matchAll(mentionRegExpGlobal)];
  matches.forEach((matchResult) => {
    const tagText = matchResult[0]; // full match
    const mentionName = matchResult.groups?.name;
    newContent = newContent.replace(tagText, `**@${mentionName}**`);
  });

  return newContent;
};

type Props = {
  className?: string;
  source?: string;
  [key: string]: any;
};

/**
 * Wrapper for the markdown component with extra needed props
 */
const MarkdownViewer: React.FC<Props> = (props) => {
  const { className, source } = props;
  return (
    <StyledMarkDown
      className={classNames('c-markdown-viewer', className)}
      // @ts-ignore
      rehypePlugins={rehypePlugins}
      remarkPlugins={defaultPlugins}
      {...omit(props, ['source'])}
    >
      {parsMentions(source ?? '')}
    </StyledMarkDown>
  );
};

MarkdownViewer.propTypes = {
  className: PropTypes.string,
  source: PropTypes.string,
};

export default MarkdownViewer;
