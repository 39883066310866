import type { DragEndEvent, UniqueIdentifier } from '@dnd-kit/core';
import type { Ticket } from 'entity/dossiers/types';
import { useMemo } from 'react';

type UseTicketListViewOptions = {
  onChange: (payload: {
    activeId: UniqueIdentifier;
    overId: UniqueIdentifier;
    activeIndex: number;
    overIndex: number;
  }) => void;
};

/**
 * Hook to handle drag & drop events in the ticket list view.
 *
 * @param data
 * @param onChange
 */
export const useTicketListView = (data: Ticket[], { onChange }: UseTicketListViewOptions) => {
  const dataIds = useMemo<UniqueIdentifier[]>(() => data.map(({ id }) => id), [data]);

  // reorder rows after drag & drop
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const activeIndex = dataIds.indexOf(active.id);
      const overIndex = dataIds.indexOf(over.id);

      onChange({
        activeId: active.id,
        overId: over.id,
        overIndex,
        activeIndex,
      });
    }
  }

  return { handleDragEnd };
};
