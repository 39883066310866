import DataModel, { PropTypes } from 'models/base/DataModel';
import User from 'models/me/User';

/**
 * DossierUser
 */
export default class DossierUser extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    user: PropTypes.shape(User.propTypes),
    role: PropTypes.string,
  };

  /**
   * @type {User}
   */
  user;

  /**
   * @type {string}
   */
  role;

  /**
   * @returns {string}
   */
  getRole() {
    return this.role;
  }

  /**
   * @returns {User}
   */
  getUser() {
    return this.user;
  }

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'user') {
      value = User.createInstance(value);
    }
    return super.set(fieldName, value);
  }
}
