import './assets/styles/DossierToolForm.scss';

import classNames from 'classnames';
import AttachmentField from 'components/attachmentfield/AttachmentField';
import { FileUploaderMenuValue } from 'components/fileuploader/FileUploader';
import FormField from 'components/formfield/FormField';
import ListIcon from 'components/listicon/ListIcon';
import SelectField from 'components/selectfield/SelectField';
import SelectFieldOption from 'components/selectfield/SelectFieldOption';
import TextField from 'components/textfield/TextField';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

/**
 * DossierToolForm component represents all details that can be edited, duplicated or created for a single dossier.
 *
 * @param {string} className
 * @param {boolean} disabled
 * @param {object} error
 * @param {array} types
 * @param {object} selectedType
 * @param {array} topics
 * @param {array} selectedTopics
 * @param {string} name
 * @param {string} url
 * @param {array|object} attachment
 * @param {boolean} isTopicsHidden
 * @param {string} footerText
 * @param {function} onChange
 * @returns {JSX.Element}
 * @constructor
 */
const DossierToolForm = ({
  className,
  isDisabled,
  error,
  types,
  selectedType,
  topics,
  selectedTopics,
  name,
  url,
  attachment,
  isTopicsHidden,
  footerText,
  onChange,
}) => {
  const [showUrlField, setShowUrlField] = useState(!!url);

  return (
    <div className={classNames(className, 'c-dossier-tool-form', 'form')}>
      {!showUrlField && (
        <FormField className="c-dossier-tool-form__attachment" error={error?.file}>
          <AttachmentField
            actionMenuItems={[
              {
                item: {
                  value: 'link',
                  label: 'Link naar bestand',
                },
                onClick: () => {
                  setShowUrlField(true);
                },
              },
              {
                item: {
                  value: FileUploaderMenuValue,
                  label: 'Van je computer',
                },
              },
            ]}
            files={attachment ? [attachment] : []}
            hasError={!!error?.file}
            isDisabled={isDisabled}
            onAddFile={(fileItem) => onChange('attachment', fileItem)}
            onRemoveFile={() => onChange('attachment', false)}
          />
        </FormField>
      )}

      {showUrlField && (
        <div className="form__row form__row__no-top-margin">
          <FormField error={error?.url || ''} label="Link">
            <TextField
              hasError={!!error?.url}
              isDisabled={isDisabled}
              placeholder="Vul hier een unieke OneDrive, Google Drive, of Dropbox link toe"
              value={url}
              onChange={(value) => {
                onChange('url', value);
              }}
            />
          </FormField>
        </div>
      )}
      <div className="form__row form__row__no-top-margin">
        <FormField error={error?.name || ''} label="Naam">
          <TextField
            hasError={!!error?.name}
            isDisabled={isDisabled}
            placeholder="Model naam..."
            value={name}
            onChange={(value) => {
              onChange('name', value);
            }}
          />
        </FormField>
      </div>
      <div className="form__row form__row__type">
        <FormField error={error?.type || ''} label="Type">
          <SelectField
            error={error?.type}
            isDisabled={isDisabled}
            optionRenderer={(value) => (
              <SelectFieldOption icon={<ListIcon isInline type={value.value} />}>
                {value.label}
              </SelectFieldOption>
            )}
            options={types}
            placeholder="Kies het modeltype"
            value={selectedType}
            onChange={(value) => {
              onChange('type', value);
            }}
          />
        </FormField>
      </div>
      {isTopicsHidden !== true && (
        <div className="form__row form__row__no-top-margin">
          <FormField error={error?.topics || ''} label={"Binnen de thema's"}>
            <SelectField
              isMulti
              error={!!error?.topics}
              isDisabled={isDisabled}
              options={topics}
              placeholder="Kies één of meerdere onderwerpen"
              value={selectedTopics}
              onChange={(value) => {
                onChange('topics', value);
              }}
            />
          </FormField>
        </div>
      )}
      {footerText && <div className="form__row form__row__footer">{footerText}</div>}
    </div>
  );
};

DossierToolForm.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  attachment: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      uploadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      url: PropTypes.string,
      name: PropTypes.string,
      iconName: PropTypes.string,
      createdDateTime: PropTypes.string,
    }),
  ]),
  name: PropTypes.string,
  description: PropTypes.string,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  selectedTopics: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.string,
      }),
    ),
  ]),
  types: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  selectedType: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ]),
  isTopicsHidden: PropTypes.bool,
  footerText: PropTypes.any,
  onChange: PropTypes.func,
};

DossierToolForm.defaultProps = {
  types: [],
  topics: [],
  onChange: (_field, _value) => null,
};

export default DossierToolForm;
