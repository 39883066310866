import type { FilterItemProp } from '@elseu/sdu-titan';
import { FilterCheckbox } from '@elseu/sdu-titan';
import type { User } from 'entity/system/types';
import { useGetCurrentUser, useProfileSettings } from 'hooks';
import React, { useMemo } from 'react';

type FilterUserOption = {
  label: string;
  value: string;
};

type Props = FilterItemProp<string[]> & {
  hasSearch?: boolean;
  isMulti?: boolean;
  shouldShowCurrentUserFirst?: boolean;
  shouldShowUnassignedOption?: boolean;
  users?: FilterUserOption[];
};

const FilterUserSelect: React.FC<Props> = ({
  onChange,
  hasSearch = true,
  isMulti = true,
  shouldShowCurrentUserFirst = false,
  shouldShowUnassignedOption = false,
  users,
  value,
  ...otherProps
}) => {
  const [, { getUsers }] = useProfileSettings();
  const currentUser = useGetCurrentUser();
  const items: FilterUserOption[] = useMemo(() => {
    const availableUsers =
      users ??
      getUsers().map((user: User) => ({
        value: user.id,
        label: user.fullName,
      }));

    // when the user wants to see the Non-assigned filter option we add this manually on top of the list.
    if (shouldShowUnassignedOption) {
      availableUsers.unshift({
        value: '<NULL>',
        label: 'Niet toegewezen',
      });
    }

    // we check if the current user is in the list of available users, and keep track to the index, we want to move
    // this user around (if requested) and change the label to add (Mezelf) as a suffix.
    const currentUserIndex = availableUsers.findIndex((item: FilterUserOption) =>
      [currentUser.id, currentUser.email].some((value) => value === item.value),
    );
    if (currentUserIndex !== -1) {
      availableUsers[currentUserIndex].label = `${availableUsers[currentUserIndex].label} (Mezelf)`;
    }

    // when a current user is found and the current user needs to be rendered on top, we add it to the top of
    // the list.
    if (shouldShowCurrentUserFirst && currentUserIndex !== -1) {
      const currentUser = availableUsers[currentUserIndex];

      availableUsers.splice(currentUserIndex, 1);
      availableUsers.unshift(currentUser);
    }

    return availableUsers;
  }, [
    currentUser.email,
    currentUser.id,
    getUsers,
    shouldShowCurrentUserFirst,
    shouldShowUnassignedOption,
    users,
  ]);

  return (
    <FilterCheckbox
      {...otherProps}
      hasSearch={hasSearch}
      items={items}
      value={value}
      onChange={(values) => {
        if (isMulti) {
          onChange(values);
        } else {
          if (values.length > 0) {
            // filter out the value that is used before so that we only have one 1 item in array left that is new.
            const oldValues = value ?? [];
            const valueDiff = oldValues.filter((x) => values.includes(x));
            const newValues = values.filter((x) => !valueDiff.includes(x));

            onChange(newValues);
          } else {
            onChange([]);
          }
        }
      }}
    />
  );
};

export default FilterUserSelect;
