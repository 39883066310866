import User from 'models/me/User';

/**
 * CardUser
 */
export default class CardUser extends User {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...User.propTypes,
  };
}
