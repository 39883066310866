import Client from 'models/crm/Client';
import { useEffect, useState } from 'react';
import { STATE_CLIENTS_SELECT, useCrmState } from 'stores/crm/CrmState';
import { sweetFetchStateForContextID } from 'stores/utils/SweetFetchState';

export const useClientDetails = (clientId) => {
  const [crmState, crmActions] = useCrmState();
  const [client, setClient] = useState(Client.createInstance());
  const clientFetchState = sweetFetchStateForContextID(crmState[STATE_CLIENTS_SELECT], clientId);

  const isBusy = clientFetchState.isBusy;
  const isSuccess = clientFetchState.isSuccess;
  const selectingContext = clientFetchState.context;

  const doRequest = () => {
    if (clientId) {
      crmActions.getClientDetails(clientId);
    }
  };

  useEffect(doRequest, [clientId, crmActions]);
  useEffect(() => setClient(selectingContext || Client.createInstance()), [selectingContext]);

  return { client, isBusy, isSuccess, doRequest };
};
