import type {
  CollisionDetection,
  DragCancelEvent,
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  UniqueIdentifier,
} from '@dnd-kit/core';
import {
  closestCenter,
  getFirstCollision,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  pointerWithin,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

interface RepositionItemProps<Item> {
  id: UniqueIdentifier;
  containerFrom: UniqueIdentifier;
  containerTo: UniqueIdentifier;
  newIndex?: number;
  items: ContainersWithItems<Item>;
}

const repositionItem = <Item extends ItemWithId>({
  id,
  containerFrom,
  containerTo,
  newIndex = 0,
  items,
}: RepositionItemProps<Item>) => {
  const containerIndex = items[containerFrom].findIndex((item) => item.id === id);

  if (containerIndex === -1 || (containerFrom === containerTo && containerIndex === newIndex)) {
    return items;
  }

  const container = items[containerFrom][containerIndex];

  if (containerFrom === containerTo) {
    return {
      ...items,
      [containerTo]: arrayMove(items[containerTo], containerIndex, newIndex),
    };
  }

  return {
    ...items,
    [containerFrom]: items[containerFrom].filter((item) => item.id !== id),
    [containerTo]: [
      ...items[containerTo].slice(0, newIndex),
      container,
      ...items[containerTo].slice(newIndex, items[containerTo].length),
    ],
  };
};

type ItemWithId = { id: UniqueIdentifier };
export type ContainersWithItems<Item> = Record<UniqueIdentifier, Item[]>;

export interface UseDnDMultipleContainersReturnProps<Item> {
  activeId: UniqueIdentifier | null;
  isDragging: boolean;
  items: ContainersWithItems<Item>;
  onDragCancel: (event: DragCancelEvent) => void;
  onDragEnd: (event: DragEndEvent) => void;
  onDragOver: (event: DragOverEvent) => void;
  onDragStart: (event: DragStartEvent) => void;
  setItems: React.Dispatch<React.SetStateAction<ContainersWithItems<Item>>>;
  collisionDetectionStrategy: CollisionDetection;
  measuring: {
    droppable: {
      strategy: MeasuringStrategy;
    };
  };
  sensors: ReturnType<typeof useSensors>;
}

export interface UseDndMultupleContainersOnChangeEvent<Item> {
  items: ContainersWithItems<Item>;
  containerId: UniqueIdentifier;
  activeId: UniqueIdentifier;
}
export interface UseDnDMultipleContainersProps<Item> {
  items?: ContainersWithItems<Item>;
  onChange?: ({
    items,
    containerId,
    activeId,
  }: UseDndMultupleContainersOnChangeEvent<Item>) => void;
}
export const useDnDMultipleContainers = <Item extends ItemWithId>({
  items: initialItems = {},
  onChange,
}: UseDnDMultipleContainersProps<Item>): UseDnDMultipleContainersReturnProps<Item> => {
  const [isDragging, setIsDragging] = useState(false);
  const [items, setItems] = useState(initialItems);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in items,
          ),
        });
      }

      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0 ? pointerIntersections : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId != null) {
        if (overId in items) {
          const itemsInContainer = items[overId];

          if (itemsInContainer.length > 0) {
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  itemsInContainer.find((item) => item.id === container.id),
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items],
  );
  const [clonedItems, setClonedItems] = useState<ContainersWithItems<Item> | null>(null);

  const getContainerIdByItemId = useCallback(
    (itemId: UniqueIdentifier) => {
      if (itemId in items) {
        return itemId;
      }
      const containerIds = Object.keys(items);
      return (
        containerIds.find((statusGroupId) =>
          items[statusGroupId].find((item) => item.id === itemId),
        ) || null
      );
    },
    [items],
  );

  const resetState = useCallback(() => {
    setActiveId(null);
    setIsDragging(false);
  }, []);

  const onDragCancel = useCallback(() => {
    resetState();

    if (clonedItems) {
      setItems(clonedItems);
    }
    setClonedItems(null);
  }, [clonedItems, resetState]);

  const onDragStart = useCallback(
    ({ active }: DragStartEvent) => {
      setIsDragging(true);
      setActiveId(active.id);
      setClonedItems(items);
    },
    [items],
  );

  const onDragOver = useCallback(
    ({ active, over }: DragOverEvent) => {
      const overId = over?.id;

      if (!overId) {
        return;
      }

      const overContainerId = getContainerIdByItemId(overId);
      const activeContainerId = getContainerIdByItemId(active.id);

      if (!overContainerId || !activeContainerId) {
        return;
      }

      if (activeContainerId !== overContainerId) {
        setItems((items) => {
          const overItems = items[overContainerId];
          const overIndex = overItems.findIndex((item) => item.id === overId);

          let newIndex: number;

          if (overId in items) {
            newIndex = overItems.length + 1;
          } else {
            const isBelowOverItem =
              active.rect.current.translated &&
              active.rect.current.translated.top > over.rect.top + over.rect.height;

            const modifier = isBelowOverItem ? 1 : 0;

            newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
          }

          recentlyMovedToNewContainer.current = true;

          return repositionItem({
            id: active.id,
            containerFrom: activeContainerId,
            containerTo: overContainerId,
            newIndex,
            items,
          });
        });
      }
    },
    [getContainerIdByItemId],
  );

  const onDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      const containerId = getContainerIdByItemId(active.id);

      if (!containerId || !over?.id) {
        return resetState();
      }

      const overIndex = items[containerId].findIndex((item) => item.id === over.id);
      setItems((items) => {
        const newItems = repositionItem({
          id: active.id,
          containerFrom: containerId,
          containerTo: containerId,
          newIndex: overIndex,
          items,
        });
        onChange?.({
          containerId,
          activeId: active.id,
          items: newItems,
        });
        return newItems;
      });

      resetState();
    },
    [getContainerIdByItemId, items, onChange, resetState],
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  return useMemo(
    () => ({
      activeId,
      isDragging,
      items,
      onDragCancel,
      onDragEnd,
      onDragOver,
      onDragStart,
      setItems,
      collisionDetectionStrategy,
      measuring: {
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      },
      sensors,
    }),
    [
      activeId,
      collisionDetectionStrategy,
      isDragging,
      items,
      onDragCancel,
      onDragEnd,
      onDragOver,
      onDragStart,
      sensors,
    ],
  );
};
