import { useTicketDetails } from 'hooks/queries/useTicketDetails';
import React from 'react';
import { useNavigate, useParams } from 'react-router';

import type { TicketEditModalProps } from './TicketEditModal';
import { TicketEditModal } from './TicketEditModal';

export const TicketEditController: React.FC<Pick<TicketEditModalProps, 'onUpdate'>> = ({
  onUpdate,
}) => {
  const { ticketId } = useParams();
  const navigate = useNavigate();
  const { data } = useTicketDetails(ticketId);
  const ticket = data?.data;

  return (
    <TicketEditModal
      isShown={!!ticket}
      ticket={ticket}
      ticketType={ticket?.type}
      onClose={() => navigate(-1)}
      onUpdate={onUpdate}
    />
  );
};
