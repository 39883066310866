import DataModel, { PropTypes } from 'models/base/DataModel';
import Client from 'models/crm/Client';
import { getGenderText } from 'models/crm/GenderTypes';

/**
 * ClientUser
 */
export default class ClientUser extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    user_id: PropTypes.number,
    firstName: PropTypes.string,
    initials: PropTypes.string,
    infix: PropTypes.string,
    surname: PropTypes.string,
    gender: PropTypes.string,
    phone: PropTypes.string,
    mobilePhone: PropTypes.string,
    email: PropTypes.string,
    recordChangeDate: PropTypes.string,
    lang: PropTypes.string,
    locale: PropTypes.string,
    clients: PropTypes.arrayOf(PropTypes.shape(Client.propTypes)),
    isManual: PropTypes.bool,
    metaInfo: PropTypes.shape({
      countDossiers: PropTypes.number,
    }),
    permissions: PropTypes.shape({
      canView: PropTypes.bool,
      canEdit: PropTypes.bool,
      canDelete: PropTypes.bool,
    }),
  };

  /**
   * @type {string}
   */
  firstName;

  /**
   * @type {string}
   */
  initials;

  /**
   * @type {string}
   */
  infix;

  /**
   * @type {string}
   */
  surname;

  /**
   * @type {string}
   */
  gender;

  /**
   * @type {string}
   */
  phone;

  /**
   * @type {string}
   */
  mobilePhone;

  /**
   * @type {string}
   */
  email;

  /**
   * @type {string}
   */
  recordChangeDate;

  /**
   * @type {string}
   */
  lang;

  /**
   * @type {string}
   */
  locale;

  /**
   * @type {array}
   */
  clients = [];

  /**
   * @type {boolean|undefined}
   */
  isManual;

  /**
   * @type {object}
   */
  metaInfo;

  /**
   * @type {object|undefined}
   */
  permissions;

  /**
   * @return {string}
   */
  getDisplayName() {
    if (this.firstName || this.infix || this.surname) {
      return [this.firstName, this.infix, this.surname].join(' ').trim();
    }

    return '';
  }

  /**
   * @return {string}
   */
  getFirstName() {
    return this.firstName;
  }

  /**
   * @return {string}
   */
  getInitials() {
    return this.initials;
  }

  /**
   * @return {string}
   */
  getInfix() {
    return this.infix;
  }

  /**
   * @return {string}
   */
  getSurname() {
    return this.surname;
  }

  /**
   * @return {string}
   */
  getGender() {
    return this.gender;
  }

  /**
   * Get gender in dutch.
   *
   * @return {""|*}
   */
  getGenderText() {
    return this.getGender() && getGenderText(this.getGender());
  }

  /**
   * @return {string}
   */
  getPhone() {
    return this.phone;
  }

  /**
   * @return {string}
   */
  getMobilePhone() {
    return this.mobilePhone;
  }

  /**
   * @return {string}
   */
  getEmail() {
    return this.email;
  }

  /**
   * @return {string}
   */
  getRecordChangeDate() {
    return this.recordChangeDate;
  }

  /**
   * @return {string}
   */
  getLang() {
    return this.lang;
  }

  /**
   * @return {string}
   */
  getLocale() {
    return this.locale;
  }

  /**
   * @return {array}
   */
  getClients() {
    if (!this.clients) {
      return [];
    }
    return this.clients;
  }

  /**
   * @return {boolean}
   */
  getIsManual() {
    if (this.isManual === undefined) {
      return true;
    }
    return this.isManual;
  }

  /**
   * @return {object}
   */
  getMetaInfo() {
    return this.metaInfo;
  }

  /**
   * @return {string}
   */
  getAvatarUrl() {
    return '';
  }

  /**
   * @return {string}
   */
  getType() {
    return 'person';
  }

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'clients') {
      value = Client.createInstancesByArray(value);
    }

    return super.set(fieldName, value);
  }

  // region helper methods for client permissions.

  /**
   * @return {boolean}
   */
  getCanView() {
    return this.permissions?.canView || false;
  }

  /**
   * @return {boolean}
   */
  getCanEdit() {
    return this.permissions?.canEdit || false;
  }

  /**
   * @return {boolean}
   */
  getCanDelete() {
    return this.permissions?.canDelete || false;
  }

  // endregion
}
