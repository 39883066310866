import './assets/styles/OptionItem.scss';

import classNames from 'classnames';
import React from 'react';

type Props = React.PropsWithChildren<{
  className?: string;
  image?: string | React.ReactElement;
  isDisabled?: boolean;
  onClick: (event: any) => void;
}>;

/**
 * OptionItem
 *
 * @param {string} className
 * @param {string} image
 * @param {boolean} disabled
 * @param {function} onClick
 * @param {node} children
 * @returns {*}
 * @constructor
 */
const OptionItem: React.FC<Props> = ({ className, image, isDisabled, onClick, children }) => {
  let imageEl;
  if (image && !(typeof image === 'string')) {
    imageEl = <span className="c-option-item__image">{image}</span>;
  } else {
    imageEl = image && (
      <img alt="" className="c-option-item__image" height={20} src={image} width={20} />
    );
  }

  return (
    <li
      aria-hidden="true"
      className={classNames('c-option-item', className, { 'c-option-item--disabled': isDisabled })}
      role="menuitem"
      onClick={(evt) => {
        if (!isDisabled) {
          onClick(evt);
        }
      }}
    >
      {imageEl}
      <span className="c-option-item__label">{children}</span>
    </li>
  );
};

export default OptionItem;
