import './assets/styles/OneDriveUploader.scss';

import { StatusMessage } from '@elseu/sdu-titan';
import classNames from 'classnames';
import CloudBrowser, { FileBrowserSelectionType } from 'components/cloudbrowser/CloudBrowser';
import FileUploader, { FileUploaderMenuValue } from 'components/fileuploader/FileUploader';
import OneDriveIcon from 'components/icons/OneDriveIcon';
import InputModal from 'components/modal/InputModal';
import { filesize } from 'filesize';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

/**
 * OneDriveUploader component
 */
class OneDriveUploader extends Component {
  // references
  fileUploadRef = React.createRef();

  // state
  state = {
    newFilenameModalShow: false,
    newFilenameValue: undefined,
    newFileExtension: undefined,
    newFileType: undefined,
    openOneDriveBrowser: false,
  };

  /**
   * Event create a file for specific type.
   *
   * @param {string} extension
   * @param {string} type
   */
  onCreateFileClick = (extension, type) => {
    this.setState({
      newFilenameModalShow: true,
      newFilenameValue: 'naamloos',
      newFileExtension: extension,
      newFileType: type,
    });
  };

  /**
   * On confirm of new filename for a new file.
   *
   * @param {string} filename
   */
  onFilenameInputConfirm = (filename) => {
    const { onCreateFile } = this.props;
    if (onCreateFile) {
      const { newFileType } = this.state;
      this.setState({
        newFilenameModalShow: false,
      });
      onCreateFile(newFileType, filename);
    }
  };

  /**
   * Event when one drive file picker option is selected
   */
  openOneDriveFilePicker = () => {
    this.setState({ openOneDriveBrowser: true });
  };

  /**
   * Event one drive item selected.
   *
   * @param {string} itemId
   */
  onOneDriveFileSelected = (itemId) => {
    const { onOneDriveFile } = this.props;
    this.setState({ openOneDriveBrowser: false });
    if (onOneDriveFile) {
      onOneDriveFile(itemId);
    }
  };

  /**
   * Open file browser
   */
  openFileBrowser = () => {
    this.fileUploadRef.current.click();
  };

  /**
   * File selected file selcted
   */
  fileBrowserOnChange = () => {
    const { onUploadFile } = this.props;
    // check if onUploadFile is set
    if (!onUploadFile) {
      return;
    }

    // loop through files
    const filesItems = this.fileUploadRef.current.files;

    const files = [];
    for (let i = 0; i < filesItems.length; i++) {
      const file = filesItems[i];
      files.push(file);
    }
    // only the first filee will be send. for now!
    this.doUploadFileEvent(files);
  };

  /**
   * File upload event handling
   *
   * @param {array} filesItems
   */
  doUploadFileEvent = (filesItems) => {
    const { isReadOnly, onUploadFile } = this.props;
    if (isReadOnly) {
      return;
    }

    const retValue = filesItems.map((item) => ({
      uploadId: item.id,
      name: item.file.name,
      file: item.file,
      rejected: item.hasError,
      reason: item.errorMessage,
    }));
    onUploadFile(retValue);
  };

  /**
   * @inheritDoc
   */
  render() {
    const {
      className,
      isDisabled,
      isReadOnly,
      isUploading,
      error,
      maxFileSize,
      onErrorClose,
      onNewCloudLink,
      children,
      uploadCount,
      uploadIndex,
    } = this.props;
    const { newFilenameModalShow, newFilenameValue, openOneDriveBrowser } = this.state;

    const optionsItems = [
      {
        image: <OneDriveIcon />,
        item: {
          label: 'OneDrive',
          value: 'oneDrive',
        },
        onClick: () => this.openOneDriveFilePicker(),
      },
      {
        item: {
          label: 'Van uw computer',
          value: FileUploaderMenuValue,
        },
        onClick: () => this.openFileBrowser(),
      },
      {
        item: {
          value: 'newCloudLink',
          label: 'Cloud bestanden',
        },
        onClick: () => onNewCloudLink(),
      },
      '-',
      {
        item: {
          label: 'Word document',
          value: 'wordDocument',
        },
        onClick: () => this.onCreateFileClick(OneDriveUploader.CREATE_FILE_TYPE_DOCX, 'word'),
      },
      {
        item: {
          label: 'Excel-werkmap',
          value: 'excelDocument',
        },
        onClick: () =>
          this.onCreateFileClick(OneDriveUploader.CREATE_FILE_TYPE_XLSX, 'spreadsheet'),
      },
      {
        item: {
          label: 'Powerpoint-presentatie',
          value: 'powerpointDocument',
        },
        onClick: () =>
          this.onCreateFileClick(OneDriveUploader.CREATE_FILE_TYPE_PTTX, 'presentation'),
      },
    ];

    return (
      <div className={classNames('c-one-drive-uploader', className)}>
        <div className="c-one-drive-uploader__children">{children}</div>
        {error && error.length > 0 && (
          <StatusMessage
            defaultIsShown
            isCloseable
            isRounded
            spaceAfter={4}
            type="danger"
            onClose={() => onErrorClose()}
          >
            {error}
          </StatusMessage>
        )}
        {!isReadOnly && (
          <FileUploader
            isMultiple
            actionMenuItems={optionsItems}
            actionMenuPopoverWidth={250}
            isDisabled={isDisabled}
            isUploading={isUploading}
            maxFileSize={maxFileSize}
            uploadingIndex={uploadIndex}
            uploadingMax={uploadCount}
            onFilesChange={(files) => this.doUploadFileEvent(files)}
          />
        )}
        {newFilenameModalShow && (
          <InputModal
            label="Bestandsnaam"
            title="Nieuw OneDrive bestand"
            value={newFilenameValue}
            onCancel={() => this.setState({ newFilenameModalShow: false })}
            onConfirm={(value) => this.onFilenameInputConfirm(value)}
          />
        )}
        {openOneDriveBrowser && (
          <CloudBrowser
            selectionType={FileBrowserSelectionType.FILE_MULTIPLE}
            onCancel={() => this.setState({ openOneDriveBrowser: false })}
            onSelect={(item) => this.onOneDriveFileSelected(item)}
          />
        )}
      </div>
    );
  }
}

// constants
OneDriveUploader.CREATE_FILE_TYPE_DOCX = 'docx';
OneDriveUploader.CREATE_FILE_TYPE_XLSX = 'xlsx';
OneDriveUploader.CREATE_FILE_TYPE_PTTX = 'pptx';

OneDriveUploader.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isUploading: PropTypes.bool,
  maxFileSize: PropTypes.number,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]),
  onUploadFile: PropTypes.func,
  onOneDriveFile: PropTypes.func,
  onCreateFile: PropTypes.func,
  onErrorClose: PropTypes.func,
  addFileDragText: PropTypes.string,
  addFileErrorText: PropTypes.func,
  uploadCount: PropTypes.number,
  uploadIndex: PropTypes.number,
};

OneDriveUploader.defaultProps = {
  isDisabled: false,
  isReadOnly: false,
  deleting: [],
  uploadCount: 0,
  uploadIndex: 0,
  addFileDragText: 'Sleep uw bestanden hier naartoe om te uploaden',
  maxFileSize: 60000000,
  addFileErrorText: (size, maxFileSize) => {
    if (size) {
      const maxFileSizeHumanReadable = filesize(maxFileSize, { round: 0 });

      return `Dit bestand is te groot, het bestand mag niet groter zijn dan ${maxFileSizeHumanReadable}.`;
    }

    return 'Dit is een ongeldig bestandstype.';
  },
};

export default OneDriveUploader;
