import { Box, ErrorIcon, spacing, StatusMessage } from '@elseu/sdu-titan';
import { AnimatePresence, motion } from 'framer-motion';
import { useGlobalMessages } from 'hooks/useGlobalMessages';
import React from 'react';
import styled from 'styled-components';

// Ported from Titan until we can have animated floating elements.
// https://github.com/elseu/sdu-titan/blob/develop/src/animations/slideFade.ts#L26
const slideFadeBottom = {
  initial: { y: '40px', opacity: 0 },
  animate: { y: '0', opacity: 1 },
  exit: { y: '40px', opacity: 0 },
};

const FloatingContainer = styled(motion.div)`
  position: fixed;
  left: ${spacing(6)};
  bottom: ${spacing(6)};
  z-index: 1000000;
`;

const Floating = styled(motion.div)`
  box-sizing: border-box;
  width: 100%;
  max-width: 640px;
  padding-top: ${spacing(3)};
`;

export const GlobalError = () => {
  const { messages, removeMessage } = useGlobalMessages();

  return (
    <FloatingContainer>
      <AnimatePresence>
        {messages.map((error) => (
          <Floating key={error.createdAt} {...slideFadeBottom}>
            <StatusMessage
              defaultIsShown
              isCloseable
              isRounded
              isShown
              alignment="left"
              icon={<ErrorIcon />}
              type={error.type || 'danger'}
              onClose={() => removeMessage(error.key)}
            >
              <Box pl={2}>{error.message}</Box>
            </StatusMessage>
          </Floating>
        ))}
      </AnimatePresence>
    </FloatingContainer>
  );
};
