import type { SpaceProp } from '@elseu/sdu-titan';
import { Box, Flex, Text } from '@elseu/sdu-titan';
import type { ColorProp } from '@elseu/sdu-titan/dist/types/mixins/getColor';
import classNames from 'classnames';
import React from 'react';

type Props = React.PropsWithChildren<{
  className?: string;
  title?: string;
  action?: React.ReactNode;
  backgroundColor?: ColorProp;
  spaceAfter?: SpaceProp;
}>;

/**
 * CardSection
 *
 * @param {string} className
 * @param {string} title
 * @param {React.ReactNode} action
 * @param {node} children
 * @return {JSX.Element}
 * @constructor
 */
const CardSection: React.FC<Props> = ({
  backgroundColor = 'grey0',
  className,
  title,
  action,
  spaceAfter = 6,
  children,
}) => (
  <Box
    backgroundColor={backgroundColor}
    borderRadius={2}
    className={classNames('c-card-section section', className)}
    px={6}
    py={4}
    spaceAfter={spaceAfter}
  >
    <Flex alignItems="center" className="section__header">
      <Flex flex={1}>
        <Text className="section__header__title" type="labelLargeBold">
          {title}
        </Text>
      </Flex>
      {action && <Flex className="section__header__action">{action}</Flex>}
    </Flex>
    {children && (
      <Box className="section__content" py={4}>
        {children}
      </Box>
    )}
  </Box>
);

export default CardSection;
