import './assets/styles/ListItem.scss';

import classNames from 'classnames';
import CheckBox, { CheckboxColorTypes } from 'components/checkbox/CheckBox';
import React from 'react';

type Props = React.PropsWithChildren<{
  className?: string;
  isDisabled?: boolean;
  isCheckbox?: boolean;
  isChecked?: boolean;
  hasCheckboxOnHover?: boolean;
  isCheckboxCheckMarkAlwaysVisible?: boolean;
  isSelectable?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  onCheckboxChange?: (checked: boolean) => void;
}>;

/**
 * List item for the List component
 *
 * @param {string} className
 * @param {boolean} checkbox
 * @param {boolean} isSelectable
 * @param {boolean} isSelected
 * @param {boolean} isChecked
 * @param {boolean} hasCheckboxOnHover
 * @param {boolean} isCheckboxCheckMarkAlwaysVisible
 * @param {boolean} isDisabled
 * @param {function} onClick
 * @param {function} onCheckboxChange
 * @param {*} children
 * @returns {*}
 * @constructor
 */
const ListItem: React.FC<Props> = ({
  className,
  isCheckbox,
  isSelectable,
  isSelected,
  isChecked,
  hasCheckboxOnHover,
  isCheckboxCheckMarkAlwaysVisible,
  isDisabled,
  // #region events
  onClick,
  onCheckboxChange,
  // #endregion
  children,
}) => {
  // if prop button is set to true.
  let extraTextProps = {};
  if (isSelectable) {
    extraTextProps = {
      ...extraTextProps,
      tabIndex: 0,
      role: 'button',
      onClick,
    };
  }

  return (
    <li
      className={classNames(
        'c-list-item',
        {
          'c-list-item--has-checkbox': isCheckbox,
          'c-list-item--selectable': isSelectable,
          'c-list-item--selected': isSelected,
          'c-list-item--disabled': isDisabled,
          'c-list-item--checkbox-show-on-hover': hasCheckboxOnHover && !isChecked,
        },
        className,
      )}
    >
      <div className="c-list-item__text" {...extraTextProps}>
        {children}
      </div>
      {isCheckbox && (
        <div className="c-list-item__checkbox">
          <CheckBox
            color={CheckboxColorTypes.PRIMARY_BLUE}
            isChecked={isChecked}
            isCheckmarkShown={isCheckboxCheckMarkAlwaysVisible}
            isDisabled={isDisabled}
            onChange={(value) => onCheckboxChange?.(value)}
          />
        </div>
      )}
    </li>
  );
};

ListItem.defaultProps = {
  isCheckbox: false,
  isChecked: false,
  hasCheckboxOnHover: false,
  isCheckboxCheckMarkAlwaysVisible: false,
  isSelectable: false,
  isSelected: false,
  isDisabled: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onCheckboxChange: () => {},
};

export default ListItem;
