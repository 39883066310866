import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import { normalizeAnalyseSections, normalizeCard } from 'entity/dossiers/normalizer';
import type { AnalyseSections, Card } from 'entity/dossiers/types';
import { useEffect, useMemo } from 'react';
import type JSendResponse from 'services/JSendResponse';

const CARD_STALE_TIME = 60 * 1000; // 1 minute

type UseCardDetailsReturnType = {
  card?: Card;
  query: UseQueryResult;
};
type UseCardDetailsReturnTypeForPhase = UseCardDetailsReturnType & {
  sections?: AnalyseSections;
};

type CardPhaseIds = 'analyse' | 'inventory' | 'advice' | 'execution';

export const useCardDetails = (cardId: string | undefined): UseCardDetailsReturnType => {
  const query = useQuery({
    queryKey: ['card', cardId],
    queryFn: async () => {
      const res = await Actions.getAPIService().detailsOfCard(cardId ?? '');
      return res.isSuccess() ? normalizeCard(res.getData()) : undefined;
    },
    staleTime: CARD_STALE_TIME,
    enabled: !!cardId && cardId !== 'new',
  });

  return useMemo(
    () => ({
      card: query.data,
      query,
    }),
    [query],
  );
};

export const useCardDetailsForPhase = (
  dossierId: string | undefined,
  phase: CardPhaseIds,
): UseCardDetailsReturnTypeForPhase => {
  const queryClient = useQueryClient();
  const phaseQueryKey = useMemo(() => ['card', dossierId, phase], [dossierId, phase]);

  const query = useQuery({
    queryKey: phaseQueryKey,
    queryFn: async () => {
      const res = await getActionsBasedOnPhase(dossierId ?? '', phase);
      if (res.isSuccess() && res.getData()?.card) {
        return {
          card: normalizeCard(res.getData()?.card),
          sections: res.getData()?.sections
            ? normalizeAnalyseSections(res.getData()?.sections)
            : undefined,
        };
      }
      return {
        card: undefined,
        sections: undefined,
      };
    },
    staleTime: CARD_STALE_TIME,
    enabled: !!dossierId,
  });

  useEffect(() => {
    if (query.isSuccess && query.data.card?.id) {
      queryClient.setQueryData(['card', dossierId, query.data.card.id], query.data.card, {
        updatedAt: Date.now(),
      });
    }
  }, [query, queryClient, dossierId]);

  return useMemo(
    () => ({
      card: query.data?.card,
      sections: query.data?.sections,
      query,
    }),
    [query],
  );
};

const getActionsBasedOnPhase = (dossierId: string, phase: CardPhaseIds): Promise<JSendResponse> => {
  const apiService = Actions.getAPIService();

  switch (phase) {
    case 'inventory':
      return apiService.dossierInventory(dossierId);
    case 'analyse':
      return apiService.dossierAnalysis(dossierId);
    case 'advice':
      return apiService.dossierAdvice(dossierId);
    case 'execution':
      return apiService.dossierExecution(dossierId);
  }
};
