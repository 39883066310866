import { Divider } from '@elseu/sdu-titan';
import type { AsyncSelectOptionProps } from 'components/formfields/AsyncSelectField';
import { NotesField } from 'components/formfields/NotesField';
import { SubmitOnChangeForm } from 'components/formfields/SubmitOnChangeForm';
import { TicketStatusFormField } from 'components/ticket/forms/formfields/TicketStatusFormField';
import React, { useCallback } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useFormContext } from 'react-hook-form';
import type { Nullable } from 'types/types';

import { TicketAssignedUserFormField } from '../../ticket/forms/formfields/TicketAssignedUserFormField';
import { TicketClientSelectField } from '../../ticket/forms/formfields/TicketClientSelectField';
import { TicketCreatedByFormField } from '../../ticket/forms/formfields/TicketCreatedByFormField';
import { TicketNameFormField } from '../../ticket/forms/formfields/TicketNameFormField';
import { TicketSubmitButton } from '../../ticket/forms/formfields/TicketSubmitButton';
import type { TicketFormProps } from '../../ticket/forms/TicketFormProps';
import type { ContactNoteSubType } from '../helpers/mapSubType';

export type ContactNoteFormValues = Nullable<{
  id?: string;
  name?: string;
  assignedUser?: string | null;
  createdBy?: string | null;
  client?: AsyncSelectOptionProps | null;
  dossier?: AsyncSelectOptionProps | null;
  status?: string;
  notes?: string;
  subType?: ContactNoteSubType;
}>;

type ContactNoteFormProps = TicketFormProps<ContactNoteFormValues>;

export const ContactNoteForm = ({ onSubmit, mode }: ContactNoteFormProps) => {
  const form = useFormContext<ContactNoteFormValues>();

  const onFormSubmit: SubmitHandler<ContactNoteFormValues> = useCallback(
    (formValues) => {
      if (!form.formState.isValid) {
        return;
      }

      onSubmit(formValues, form);
    },
    [onSubmit, form],
  );

  const FormComponent = mode === 'edit' ? SubmitOnChangeForm : 'form';

  return (
    <FormProvider {...form}>
      <FormComponent onSubmit={form.handleSubmit(onFormSubmit)}>
        <TicketNameFormField />

        <TicketStatusFormField />

        <TicketCreatedByFormField />

        <TicketAssignedUserFormField />

        <TicketClientSelectField />

        <Divider />

        <NotesField name="notes" />

        {mode === 'create' && (
          <TicketSubmitButton onClick={() => onSubmit(form.getValues(), form)}>
            Contactnotitie aanmaken
          </TicketSubmitButton>
        )}
      </FormComponent>
    </FormProvider>
  );
};
