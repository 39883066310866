import type { WorkOverviewViewMode } from 'entity/dossiers/types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const VIEW_MODE_KANBAN = 'kanban';
export const VIEW_MODE_LIST = 'list';

const VIEW_MODE_QUERY_PARAM = 'viewMode';

type UseViewModeResult = [WorkOverviewViewMode, (viewMode: WorkOverviewViewMode) => void];

/**
 * Hook to handle the view mode of the work overview.
 * The view mode is stored in the URL query parameters,
 * so that the user can share a link to a specific view mode.
 */
export const useViewMode = (): UseViewModeResult => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const [viewMode, setViewMode] = useState<WorkOverviewViewMode>(
    queryParams.get('viewMode')?.toLocaleLowerCase() === VIEW_MODE_LIST
      ? VIEW_MODE_LIST
      : VIEW_MODE_KANBAN,
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.get(VIEW_MODE_QUERY_PARAM) !== viewMode) {
      queryParams.set(VIEW_MODE_QUERY_PARAM, viewMode);
      navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
    }
  }, [viewMode, navigate, location.pathname, location.search]);

  return [viewMode, setViewMode];
};
