import type { SelectFieldProps } from 'components/formfields/SelectField';
import { UserSelectField } from 'components/formfields/UserSelectField';
import { FormGroupHorizontal } from 'components/formgroup/FormGroupHorizontal';
import React from 'react';

export const TicketCreatedByFormField: React.FC<Partial<SelectFieldProps>> = (props) => {
  return (
    <FormGroupHorizontal label="Aangemaakt door">
      <UserSelectField
        isLabelHidden
        readOnly
        label="Aangemaakt door"
        name="createdBy"
        placeholder="Aangemaakt door"
        spaceAfter={0}
        type="clear"
        {...props}
      />
    </FormGroupHorizontal>
  );
};
