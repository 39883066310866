import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import type { OverviewResults } from 'entity/base/types';
import { normalizeSearchResult } from 'entity/search/normalizer';
import type { SearchResult } from 'entity/search/types';
import { normalizeOverviewResults } from 'helpers/normalizehelper';
import { useMemo } from 'react';
import type JSendResponse from 'services/JSendResponse';

const getSearch = (query: string, name: string, sort = 'relevance', page = 1, pageSize = 10) => {
  return Actions.getAPIService()
    .getSearch()
    .search(query, name, sort, page, pageSize)
    .then((data: JSendResponse) =>
      normalizeOverviewResults(data.getData(), 'results', normalizeSearchResult),
    );
};

type useSearchReturnType = {
  name: string;
  sort: string;
  page: number;
  query: string;
  searchQuery: UseQueryResult<OverviewResults<SearchResult>>;
  isLoading: boolean;
  results: SearchResult[];
  numberOfResults: number;
  totalPages: number;
};

export const useSearch = (
  query: string,
  name: string,
  sort = 'weight',
  page = 1,
): useSearchReturnType => {
  const searchQuery = useQuery({
    queryKey: ['search', query, name, sort, page],
    queryFn: () => getSearch(query, name, sort, page),
    staleTime: 60 * 1000,
  });

  return useMemo(
    () => ({
      name,
      sort,
      page,
      query,
      searchQuery,
      isLoading: searchQuery.isLoading,
      results: searchQuery.data?.results ?? [],
      totalPages: searchQuery.data?.paging.totalPages ?? 1,
      numberOfResults: searchQuery.data?.paging.numberOfResults ?? 0,
    }),
    [searchQuery, name, sort, page, query],
  );
};
