import { Box, InputVariant, SearchForm } from '@elseu/sdu-titan';
import Actions from 'actions/Actions';
import BerkleyNewSessionModal from 'components/berkley/BerkleyNewSessionModal';
import ConfirmDialog from 'components/confirmdialog/ConfirmDialog';
import ContextGroups from 'components/contextgroups/ContextGroups';
import ContextGroupsTree from 'components/contextgroups/ContextGroupsTree';
import DossierToolFormModal from 'components/dossier/DossierToolFormModal';
import type { ContextGroup, ContextItem, Topic } from 'entity/dossiers/types';
import React, { useEffect, useMemo, useState } from 'react';
import { STATE_CONTEXT_DELETE, useContextState } from 'stores/dossier/ContextState';
import { STATE_OWN_TOOLS_DELETE, useOwnToolsState } from 'stores/own-tools/OwnToolsState';
import { sweetFetchStateForContextID } from 'stores/utils/SweetFetchState';

import CardSection from './CardSection';

type Props = {
  isDisabled?: boolean;
  title?: string;
  dossierId: string;
  cardId?: string;
  contextGroups: ContextGroup[];
  selectedTopics: Topic[];
  modelTypes: any[];
  topics: Topic[];
  hasSearch?: boolean;
  canEdit?: boolean;
  canDeleteOtherContext?: boolean;
  toolCreationGroupTitle?: string;
  isCloudLinksEnabled?: boolean;
  searchPlaceholder?: string;
  stage?: string;
  isTopicsHidden?: boolean;
  considerationId?: string;
  considerationName?: string;
  shouldRenderCardSection?: boolean;
  renderAs?: 'default' | 'tree';
  onToolUpdated?: (action: string, payload?: string | undefined) => void;
};

type HolderProps = React.PropsWithChildren<{
  shouldRenderCardSection: boolean;
  title?: string;
}>;

const HolderSection: React.FC<HolderProps> = ({ shouldRenderCardSection, children, title }) =>
  shouldRenderCardSection ? (
    <CardSection title={title ?? ''}>{children}</CardSection>
  ) : (
    <Box>{children}</Box>
  );

/**
 * CardContextGroup component
 *
 * @param {boolean} isDisabled,
 * @param {string} title,
 * @param {string} dossierId,
 * @param {string|undefined} cardId,
 * @param {array} contextGroups,
 * @param {array} selectedTopics,
 * @param {array} topics,
 * @param {array} modelTypes,
 * @param {boolean} searchShow,
 * @param {boolean} canEdit,
 * @param {boolean} canDeleteOtherContext,
 * @param {string} toolCreationGroupTitle,
 * @param {boolean} isCloudLinksEnabled,
 * @param {string} searchPlaceholder
 * @param {string} stage
 * @param {boolean} isTopicsHidden
 * @param {string} considerationId
 * @param {string} considerationName
 * @param {function} onToolUpdated
 * @return {*}
 */
const CardContextGroup: React.FC<Props> = ({
  isDisabled,
  title,
  dossierId,
  cardId,
  contextGroups,
  selectedTopics,
  topics,
  modelTypes,
  hasSearch = true,
  canEdit,
  canDeleteOtherContext = false,
  toolCreationGroupTitle = 'Zit het gewenste model er niet tussen?',
  isCloudLinksEnabled,
  searchPlaceholder = 'Zoek tools of modellen',
  stage,
  isTopicsHidden = false,
  shouldRenderCardSection = true,
  considerationId,
  considerationName,
  renderAs = 'default',
  onToolUpdated = () => null,
}) => {
  const [ownToolsStore, ownToolActions] = useOwnToolsState();
  const [contextStore, contextActions] = useContextState();
  const [search, setSearch] = useState('');

  // #region delete own tool handling
  const [confirmOwnToolDeleteShow, setConfirmOwnToolDeleteShow] = useState(false);
  const [confirmOwnToolDeleteItem, setConfirmOwnToolDeleteItem] = useState<
    Record<string, any> | undefined
  >();
  const { isBusy: ownToolDeleteIsBusy, isSuccess: ownToolDeleteIsSuccess } =
    sweetFetchStateForContextID(
      ownToolsStore[STATE_OWN_TOOLS_DELETE],
      confirmOwnToolDeleteItem?.id,
    );

  useEffect(() => {
    if (ownToolDeleteIsSuccess && confirmOwnToolDeleteItem) {
      ownToolActions.resetFetchState(STATE_OWN_TOOLS_DELETE, confirmOwnToolDeleteItem.id);
      setConfirmOwnToolDeleteShow(false);
      setConfirmOwnToolDeleteItem(undefined);
      onToolUpdated('own-tool:delete');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownToolDeleteIsSuccess]);
  // #endregion

  // #region delete context item
  const [confirmDeleteShow, setConfirmDeleteShow] = useState(false);
  const [confirmDeleteItem, setConfirmDeleteItem] = useState<Record<string, any> | undefined>();
  const { isBusy: contextItemDeleteIsBusy, isSuccess: contextItemDeleteIsSuccess } =
    sweetFetchStateForContextID(contextStore[STATE_CONTEXT_DELETE], confirmDeleteItem?.id);

  useEffect(() => {
    if (contextItemDeleteIsSuccess && confirmDeleteItem) {
      contextActions.resetFetchState(STATE_CONTEXT_DELETE, confirmDeleteItem.id);
      setConfirmDeleteShow(false);
      setConfirmDeleteItem(undefined);
      onToolUpdated('context-item:delete');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextItemDeleteIsSuccess]);
  // #endregion

  // #region new tool
  const [createToolModalShow, setCreateToolModalShow] = useState(false);
  // #endregion

  // #region new berkley session
  const [createBerkeleySessionModalShow, setCreateBerkeleySessionModalShow] = useState(false);
  const [createBerkeleySessionModalItem, setCreateBerkeleySessionModalItem] = useState<
    ContextItem | undefined
  >(undefined);
  // #endregion

  // #region functions

  /**
   * Add tool from the context group to as cloud attachment and refresh the card.
   */
  const addToolAsCloudAttachment = (item: ContextItem) => {
    onToolUpdated('tool-as-attachment:busy');
    Actions.getAPIService()
      .getDossiers()
      .addToolAsCloudAttachment(dossierId, cardId ?? '', item.id ?? '', item.contextType ?? '')
      .then(
        () => {
          onToolUpdated('tool-as-attachment:completed');
        },
        (data) => {
          if (data.status === 'error') {
            onToolUpdated(
              'tool-as-attachment:error',
              `Er ging iets niet goed bij het toevoegen van de tool. (${data.message})`,
            );
          }
        },
      );
  };

  /**
   * Add own tool from context group as cloud attachment and refresh the card.
   */
  const addOwnToolAsCloudAttachment = (item: ContextItem) => {
    onToolUpdated('own-tool-as-attachment:busy');
    Actions.getAPIService()
      .getDossiers()
      .addOwnToolAsCloudAttachment(dossierId, cardId ?? '', item.id ?? '')
      .then(
        () => {
          onToolUpdated('own-tool-as-attachment:completed');
        },
        (data) => {
          onToolUpdated(
            'own-tool-as-attachment:error',
            `Er ging iets niet goed bij het toevoegen van de tool. (${data.message})`,
          );
        },
      );
  };
  // #endregion

  const groups: ContextGroup[] = useMemo(() => {
    return contextGroups.map((group) => ({
      ...group,
      items: group.items.map((item) => {
        return {
          ...item,
          url: item.isBerkleyModel ? undefined : item.url,
          berkleyUrl: item.isBerkleyModel ? item.url : undefined,
        };
      }),
    }));
  }, [contextGroups]);

  const ContextGroupElement = useMemo(
    () => (renderAs === 'tree' ? ContextGroupsTree : ContextGroups),
    [renderAs],
  );

  return (
    <HolderSection shouldRenderCardSection={shouldRenderCardSection} title={title}>
      {hasSearch && (
        <SearchForm
          autoComplete="off"
          placeholder={searchPlaceholder}
          spaceAfter={4}
          variant={InputVariant.FILLED}
          onInputChange={(value) => setSearch(value)}
          onSubmit={(value) => setSearch(value)}
        />
      )}
      <ContextGroupElement
        groups={groups}
        isDisabled={isDisabled}
        isToolCreationAllowed={canEdit}
        search={search}
        toolCreationGroupTitle={toolCreationGroupTitle}
        onBerkelyItemClick={(item) => {
          setCreateBerkeleySessionModalShow(true);
          setCreateBerkeleySessionModalItem(item);
        }}
        onGetActionsForItem={(group: any, item: any) => {
          let actions: any[] = [];

          const deleteActionItem = {
            label: 'Verwijderen',
            onClick: (event: any, context: any) => {
              const { item: contextItem, group: contextGroup } = context;

              if (contextGroup.id === 'own-tool') {
                setConfirmOwnToolDeleteShow(true);
                setConfirmOwnToolDeleteItem(contextItem);
              } else {
                setConfirmDeleteShow(true);
                setConfirmDeleteItem(contextItem);
              }
            },
          };

          if (canEdit) {
            switch (group.id) {
              case 'own-tool':
                if (isCloudLinksEnabled && item.hasAttachment) {
                  actions = [
                    {
                      label: 'Tool gebruiken',
                      onClick: () => addOwnToolAsCloudAttachment(item),
                    },
                  ];
                }
                actions.push(deleteActionItem);
                break;
              default:
                if (isCloudLinksEnabled && item.hasAttachment && item.isBerkleyModel === false) {
                  actions = [
                    {
                      label: 'Tool gebruiken',
                      onClick: () => addToolAsCloudAttachment(item),
                    },
                  ];
                }
                if (item.isBerkleyModel === true) {
                  actions = [
                    {
                      label: 'Sessie maken',
                      onClick: () => {
                        setCreateBerkeleySessionModalShow(true);
                        setCreateBerkeleySessionModalItem(item);
                      },
                    },
                    {
                      label: 'Openen',
                      onClick: () => {
                        window.open(item.berkleyUrl, '_blank');
                      },
                    },
                  ];
                }

                if (canDeleteOtherContext) {
                  actions.push(deleteActionItem);
                }
                break;
            }
          }
          return actions;
        }}
        onToolCreationClick={() => setCreateToolModalShow(true)}
      />

      {confirmOwnToolDeleteShow && (
        <ConfirmDialog
          cancelTitle="Nee"
          confirmAppearance="danger"
          confirmTitle="Ja, verwijder"
          isDisabled={ownToolDeleteIsBusy}
          title="Item verwijderen?"
          onCancel={() => setConfirmOwnToolDeleteShow(false)}
          onConfirm={() => {
            if (confirmOwnToolDeleteItem) {
              ownToolActions.deleteOwnTool(confirmOwnToolDeleteItem.id);
            }
          }}
        >
          Weet u zeker dat u dit item wilt verwijderen?
        </ConfirmDialog>
      )}

      {confirmDeleteShow && (
        <ConfirmDialog
          cancelTitle="Nee"
          confirmAppearance="danger"
          confirmTitle="Ja, verwijder"
          isDisabled={contextItemDeleteIsBusy}
          title="Item verwijderen?"
          onCancel={() => setConfirmDeleteShow(false)}
          onConfirm={() => {
            if (confirmDeleteItem) {
              contextActions.deleteContext(dossierId, cardId, confirmDeleteItem.id);
            }
          }}
        >
          Weet u zeker dat u dit item wilt verwijderen?
        </ConfirmDialog>
      )}

      {createToolModalShow && (
        <DossierToolFormModal
          considerationId={considerationId}
          considerationName={considerationName}
          isDisabled={isDisabled}
          isTopicsHidden={isTopicsHidden}
          modelTypes={modelTypes}
          selectedTopics={selectedTopics}
          stage={stage}
          topics={topics}
          onCancel={() => setCreateToolModalShow(false)}
          onSave={(state) => onToolUpdated(state)}
          onSaved={() => {
            setCreateToolModalShow(false);
          }}
        />
      )}

      {createBerkeleySessionModalShow && (
        <BerkleyNewSessionModal
          cardId={cardId}
          dossierId={dossierId}
          isDisabled={isDisabled}
          item={createBerkeleySessionModalItem}
          onBusy={() => onToolUpdated('berkley-new-session:busy')}
          onCancel={() => {
            setCreateBerkeleySessionModalShow(false);
            setCreateBerkeleySessionModalItem(undefined);
          }}
          onError={() => onToolUpdated('berkley-new-session:error')}
          onSaved={() => {
            setCreateBerkeleySessionModalShow(false);
            setCreateBerkeleySessionModalItem(undefined);
            onToolUpdated('berkley-new-session:completed');
          }}
        />
      )}
    </HolderSection>
  );
};

export default CardContextGroup;
