import classNames from 'classnames';
import React from 'react';

type Props = {
  className?: string;
  number: string;
};

/**
 * Component that renders a clickable telephone number.
 */
const Phone: React.FC<Props> = ({ className, number }) => (
  <a className={classNames('c-telephone', className)} href={`tel:${number}`}>
    {number}
  </a>
);

export default Phone;
