import type { SpaceProp } from '@elseu/sdu-titan';
import { Block } from '@elseu/sdu-titan';
import type { TicketNotesFieldProps } from 'components/ticket/TicketNotesField';
import TicketNotesField from 'components/ticket/TicketNotesField';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type NotesFieldProps = {
  name: string;
  spaceBefore?: SpaceProp;
  spaceAfter?: SpaceProp;
} & TicketNotesFieldProps;

const NotesField = ({ name, spaceAfter = 6, spaceBefore = 6, ...props }: NotesFieldProps) => {
  const { control } = useFormContext();

  return (
    <Block px={6} spaceAfter={spaceAfter} spaceBefore={spaceBefore}>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange }, formState: { errors } }) => (
          <TicketNotesField
            note={value}
            placeholder="Voeg je beschrijving toe"
            onNoteChange={onChange}
            {...props}
          />
        )}
      />
    </Block>
  );
};

export { NotesField };
