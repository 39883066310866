import { Box, Flex, Text } from '@elseu/sdu-titan';
import SelectField from 'components/selectfield/SelectField';
import React from 'react';

import type { IPermissionOption } from './helpers';
import { createPermissionTypeAvatar } from './helpers';

const MenuOption = ({ children, ...props }: any) => (
  <Flex alignItems="center">
    <Box pr={2}>{createPermissionTypeAvatar(props.data.type, props.data.label)}</Box>
    <Box>
      <Text color="grey90" type="labelBold">
        {props.data?.label}
      </Text>
      <Text isBlock color="grey70" type="label">
        {props.data?.subtitle}
      </Text>
    </Box>
  </Flex>
);

const MultiValueLabel = (props: any) => {
  const { data } = props;
  const { type, label, subtitle } = data;

  return (
    <Flex alignItems="center">
      <Box pr={2}>{createPermissionTypeAvatar(type, label, 32)}</Box>
      <Box>
        <Text color="grey90" type="labelBold">
          {label || subtitle}
        </Text>
      </Box>
    </Flex>
  );
};

type IPermissionUserSelect = {
  isDisabled?: boolean;
  options: IPermissionOption[];
  selected: IPermissionOption[];
  placeholder?: string;
  noResultsMessage?: string;
  onChange: (value: any) => void;
  [key: string]: any;
};

export const PermissionUserSelect: React.FC<IPermissionUserSelect> = ({
  isDisabled = false,
  options = [],
  selected = [],
  placeholder = 'Selecteer één of meerdere gebruikers en of groepen',
  noResultsMessage = 'Geen gebruikers of groepen gevonden',
  onChange,
  ...otherProps
}) => (
  <SelectField
    {...otherProps}
    className="c-user-select"
    componentMultiValueLabel={MultiValueLabel}
    isClearable={false}
    isDisabled={isDisabled}
    isMulti={true}
    isSearchable={true}
    noResultsMessage={noResultsMessage}
    optionRenderer={(props: any) => <MenuOption {...props} />}
    options={options}
    placeholder={placeholder}
    shouldBlurInputOnSelect={false}
    shouldHideSelectedOptions={true}
    value={selected}
    onChange={onChange}
  />
);
