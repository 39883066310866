import { useEffect, useRef, useState } from 'react';

/**
 * Handles outside click of component
 *
 * @returns {{
 * isClickedOutside: boolean,
 * ref: React.MutableRefObject<null>,
 * setIsClickedOutSide: React.Dispatch<React.SetStateAction<boolean>>
 * }}
 */
export const useOutsideComponentClicked = () => {
  const [isClickedOutside, setIsClickedOutSide] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (ref.current && !ref.current.contains(event.target)) {
      setIsClickedOutSide(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isClickedOutside, setIsClickedOutSide };
};
