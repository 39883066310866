import classNames from 'classnames';
import React from 'react';

type Props = {
  className?: string;
  email: string;
};

/**
 * Component that renders a clickable email.
 */
const Email: React.FC<Props> = ({ className, email }) => (
  <a className={classNames('c-email', className)} href={`mailto:${email}`}>
    {email}
  </a>
);

export default Email;
