import Team from 'models/system/Team';
import { useEffect, useState } from 'react';
import { STATE_TEAMS_SELECT, useTeamState } from 'stores/team/TeamState';
import { sweetFetchStateForContextID } from 'stores/utils/SweetFetchState';

/**
 * Get team detail hook
 *
 * @param {string} teamId
 * @param {boolean} forceRequestOnLoad
 * @returns {{doRequest: *, isBusy, team: self, isSuccess: *}}
 */
export const useTeamDetails = (teamId, forceRequestOnLoad = false) => {
  const [teamStore, teamActions] = useTeamState();
  const [team, setTeam] = useState(Team.createInstance({}));

  const teamFetchState = sweetFetchStateForContextID(teamStore[STATE_TEAMS_SELECT], teamId);
  const isBusy = teamFetchState.isBusy;
  const isSuccess = teamFetchState.isSuccess;
  const selectingContext = teamFetchState.context;

  const doRequest = () => teamActions.getTeam(teamId);
  const onHookLoaded = () => {
    // force loading even it is already in context.
    if (forceRequestOnLoad) {
      doRequest();
    }
    // look if the team is already grabbed. If so? we just use the context.
    if (isSuccess) {
      setTeam(selectingContext || Team.createInstance());
    } else if (!isBusy) {
      // do request if it is not already busy
      doRequest();
    }
  };

  // eslint-disable-next-line
  useEffect(onHookLoaded, [teamId, forceRequestOnLoad]);
  useEffect(() => setTeam(selectingContext || Team.createInstance()), [selectingContext]);

  return { team, isBusy, isSuccess, doRequest };
};
