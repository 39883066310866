import { useQuery } from '@tanstack/react-query';
import type { ApiFilter } from 'types/types';

import Actions from '../../actions/Actions';

export const useTickets = (queryFilter?: ApiFilter[]) =>
  useQuery({
    queryKey: ['tickets', queryFilter],
    queryFn: () => Actions.getAPIService().tickets().getTickets(queryFilter),
  });
