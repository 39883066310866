import dayjs from 'dayjs';

import type { Announcement } from './types';

export const normalizeAnnouncement = (result: any): Announcement => ({
  id: result.id,
  name: result.name,
  description: result.description,
  videoUrl: result.videoUrl,
  heroImage: result.heroImage,
  heroImageUrl: result.heroImageUrl,
  heroImageFileName: result.heroImageFileName,
  heroImageMimeType: result.heroImageMimeType,
  displayDate: dayjs(result.displayDate).toDate(),
  createdAt: dayjs(result.createdAt).toDate(),
  updatedAt: dayjs(result.updatedAt).toDate(),
});
