import './assets/styles/Avatar.scss';

import classNames from 'classnames';
import React from 'react';

import defaultImage from './assets/images/avatar.svg';
import defaultImageWithoutBorder from './assets/images/avatarWithoutBorder.svg';

/**
 * Predefined sizes
 */
export enum AvatarSizesEnum {
  SIZE_NORMAL = 20,
  SIZE_MEDIUM = 28,
  SIZE_LARGE = 40,
  SIZE_EXTRA_LARGE = 54,
  SIZE_LARGE_PROFILE = 150,
}

/**
 * Avatar props
 */
type AvatarProps = {
  className?: string;
  url?: string;
  name?: string;
  size?: number;
  isRounded?: boolean;
  hasBorder?: boolean;
  isInline?: boolean;
  hasBackground?: boolean;
  avatarComponent?: React.ReactElement;
};

/**
 * CSS variables overrides
 */
interface IAvatarStyleProperties extends React.CSSProperties {
  '--c-avatar-size': any;
}

/**
 * Avatar
 *
 * @param {string} className
 * @param {string} url
 * @param {JSX.Element} avatarComponent
 * @param {string} name
 * @param {number} size
 * @param {boolean} rounded
 * @param {boolean} hasBorder
 * @param {boolean} isInline
 * @param {boolean} hasBackground
 * @returns {JSX.Element}
 * @constructor
 */
const Avatar: React.FC<AvatarProps> = ({
  className,
  url,
  avatarComponent,
  name,
  size = AvatarSizesEnum.SIZE_NORMAL,
  isRounded = false,
  hasBorder = true,
  isInline = false,
  hasBackground = false,
}) => {
  const style: IAvatarStyleProperties = {
    '--c-avatar-size': `${size || AvatarSizesEnum.SIZE_NORMAL}px`,
  };

  return (
    <div
      className={classNames(
        'c-avatar',
        {
          'c-avatar--inline': isInline,
          'c-avatar--with-background': hasBackground,
        },
        className,
      )}
      style={style}
    >
      <div
        className={classNames('c-avatar__image', {
          'c-avatar__image--rounded': isRounded,
          'c-avatar__image--border': hasBorder,
        })}
      >
        {avatarComponent || (
          <img
            alt="represents a person."
            src={url || (hasBorder ? defaultImage : defaultImageWithoutBorder)}
          />
        )}
      </div>
      {name && <div className="c-avatar__label">{name}</div>}
    </div>
  );
};

export default Avatar;
