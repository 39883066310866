import Dossier from 'models/dossier/Dossier';
import { useMemo } from 'react';

import { useDossierDetails as useDossierDetailsQuery } from './queries';

type useDossierDetailsType = {
  dossier: Dossier;
  isBusy: boolean;
  hasError: boolean;
  isSuccess: boolean;
  doRequest: () => void;
};

export const useDossierDetails = (dossierId: string): useDossierDetailsType => {
  const { dossier, query } = useDossierDetailsQuery(dossierId);
  const dossierState: any = useMemo(() => Dossier.createInstance(dossier ?? {}), [dossier]);

  return {
    dossier: dossierState,
    isBusy: query.isLoading,
    hasError: query.isError,
    isSuccess: query.isSuccess,
    doRequest: () => query.refetch(),
  };
};
