import type { FilterItemProp, FilterItemProps, SpaceProp } from '@elseu/sdu-titan';
import { Filters, Flex } from '@elseu/sdu-titan';
import FilterDateTime from 'components/filterdatetime/FilterDateTime';
import FilterDossiers from 'components/filterdossiers/FilterDossiers';
import type { SelectFieldOptionType } from 'components/selectfield/SelectField';
import { useProfileSettings } from 'hooks';
import React, { useMemo } from 'react';

import FilterClient from '../filterclient/FilterClient';
import FilterSubOffice from '../filtersuboffice/FilterSubOffice';
import FilterTicketType from '../filtertickettype/FilterTicketType';
import FilterUserSelect from '../filteruserselect/FilterUserSelect';

type Props = {
  dossierId?: string;
  isDisabled?: boolean;
  isDefaultFilter?: boolean;
  dossiers?: SelectFieldOptionType[];
  filters: Record<string, any>;
  sortValue?: string;
  spaceAfter?: SpaceProp;
  labelDossierText?: string;
  labelDateText?: string;
  labelSortText?: string;
  labelCreatedByText?: string;
  onChangeFilter?: (filter: Record<string, any>) => void;
  onResetFilter?: () => void;
  children?: React.ReactNode;
};

// #region predefined variables
const filterDates = [
  {
    value: 'ever',
    label: 'Ooit',
  },
  {
    value: 'today',
    label: 'Vandaag',
  },
  {
    value: 'upcoming_7_days',
    label: 'Komende 7 dagen',
  },
  {
    value: 'upcoming_14_days',
    label: 'Komende 14 dagen',
  },
  {
    value: 'past',
    label: 'In het verleden',
  },
  {
    value: '!custom',
    label: 'Van...tot',
  },
];

// #endregion

const TicketFilter: React.FC<Props> = ({
  spaceAfter,
  filters,
  labelDossierText = 'Binnen dossier',
  labelDateText = 'Verloopt',
  onChangeFilter = () => null,
  onResetFilter = () => null,
  children,
}) => {
  const [, { getUsersForSelectField }] = useProfileSettings();
  const users = getUsersForSelectField();

  const filterItems: FilterItemProps[] = useMemo(() => {
    const retValue: FilterItemProps[] = [
      {
        title: 'Toegewezen aan',
        name: 'assignedUser',
        Renderer: (props: FilterItemProp<string[]>) => (
          <FilterUserSelect
            shouldShowCurrentUserFirst
            isMulti={true}
            users={(users ?? []).map((user: any) => ({
              label: user.label ?? '',
              value: user.value ?? '',
            }))}
            {...props}
          />
        ),
      },
      {
        title: labelDossierText,
        name: 'dossier',
        Renderer: (props: FilterItemProp<string | undefined>) => <FilterDossiers {...props} />,
      },
      {
        title: 'Toon van klant',
        name: 'client',
        Renderer: (props: FilterItemProp<string | undefined>) => <FilterClient {...props} />,
      },
      {
        title: 'Type',
        name: 'ticketType',
        Renderer: (props: FilterItemProp<string | undefined>) => <FilterTicketType {...props} />,
      },
      {
        title: labelDateText,
        name: 'deadlineDate',
        Renderer: (props: FilterItemProp<string | undefined>) => (
          <FilterDateTime {...props} items={filterDates} />
        ),
      },
      {
        title: 'Vestiging',
        name: 'subOffice',
        Renderer: (props: FilterItemProp<string | undefined>) => (
          <FilterSubOffice isAllOptionsShown {...props} />
        ),
      },
    ];

    return retValue;
  }, [labelDateText, labelDossierText, users]);

  return (
    <Flex
      alignItems="flex-end"
      flex="1 1 auto"
      gap={4}
      justifyContent="end"
      spaceAfter={spaceAfter}
    >
      {children}
      <Filters
        displayMode="drawer"
        filters={filterItems}
        values={filters}
        onApply={onChangeFilter}
        onClear={onResetFilter}
      />
    </Flex>
  );
};

export default TicketFilter;
