import LocalStorageService from 'services/LocalStorageService';

const ANNOUNCEMENT_LAST_ID = 'announcementLastId';

export const announcementSetLastMessageId = (id: string) => {
  LocalStorageService.setItem(ANNOUNCEMENT_LAST_ID, id);
};

export const announcementGetLastMessageId = () => {
  return LocalStorageService.getItem(ANNOUNCEMENT_LAST_ID);
};

export const announcementShowNotification = (ids: string[]) => {
  const lastMessageId = announcementGetLastMessageId();

  if (lastMessageId === null) {
    return true;
  }

  return ids.indexOf(lastMessageId) > 0;
};
