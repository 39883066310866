import 'react-circular-progressbar/dist/styles.css';
import './assets/styles/TaskTitle.scss';

import { ActionMenu, MenuItem } from '@elseu/sdu-titan';
import classNames from 'classnames';
import TextAreaAutoSizeField from 'components/textareafield/TextAreaAutoSizeField';
import React, { useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';

export type TaskTitleProps = {
  className?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  name?: string;
  taskLength: number;
  taskCompleted: number;
  isFilteredOnComplete?: boolean;
  onNameChange?: (name: string | undefined) => void;
  onTitleClick?: () => void;
  onMenuNewTask?: () => void;
  onMenuSwitchListState?: () => void;
  onMenuDeleteTaskList?: () => void;
};

/**
 * TaskTitle
 *
 * @param {string} className
 * @param {boolean} isDisabled
 * @param {boolean} isReadOnly
 * @param {string} name
 * @param {number} taskLength
 * @param {number} taskCompleted
 * @param {boolean} isFilteredOnComplete
 * @param {function} onNameChange
 * @param {function} onTitleClick
 * @param {function} onMenuNewTask
 * @param {function} onMenuSwitchListState
 * @param {function} onMenuDeleteTaskList
 * @returns {*}
 * @constructor
 */
const TaskTitle: React.FC<TaskTitleProps> = ({
  className,
  isDisabled,
  isReadOnly,
  name,
  taskLength,
  taskCompleted,
  isFilteredOnComplete,
  onNameChange,
  onTitleClick,
  onMenuNewTask,
  onMenuSwitchListState,
  onMenuDeleteTaskList,
}) => {
  const [nameValue, setNameValue] = useState<string | undefined>(name);
  const [editingMode, setEditingMode] = useState(false);

  const titleProps = {
    onClick: onTitleClick,
    role: onTitleClick !== undefined ? 'link' : '',
  };

  // read only mode
  if (isReadOnly) {
    return (
      <div className={classNames('c-task-title', className)}>
        <span
          className={classNames('c-task-title__name-readonly', {
            'c-task-title__name-link': onTitleClick !== undefined,
          })}
          {...titleProps}
        >
          {name}
        </span>
        <span className="c-task-title__meta">
          <CircularProgressbar
            background
            className="c-task-title__meta__progress"
            maxValue={taskLength}
            strokeWidth={50}
            value={taskCompleted}
          />
          <span className="c-task-title__meta__subtitle">
            {taskCompleted}/{taskLength} taken
          </span>
        </span>
      </div>
    );
  } else {
    // edit mode
    return (
      <div className={classNames('c-task-title', className)}>
        <div className="c-task-title__holder">
          {editingMode ? (
            <TextAreaAutoSizeField
              className="c-task-title__name"
              isDisabled={isDisabled}
              isFocused={editingMode}
              value={nameValue}
              onBlur={() => {
                setEditingMode(false);
                if (name !== nameValue) {
                  onNameChange?.(nameValue);
                }
              }}
              onChange={(value) => setNameValue(value)}
              onEnter={() => {
                setEditingMode(false);
                onNameChange?.(nameValue);
              }}
            />
          ) : (
            <>
              <span
                aria-hidden="true"
                className={classNames('c-task-title__name-readonly', 'c-task-title__name-editable')}
                role="button"
                tabIndex={0}
                onClick={() => {
                  if (!isDisabled) {
                    setEditingMode(true);
                  }
                }}
              >
                {nameValue}
              </span>
              <span className="c-task-title__meta">
                <CircularProgressbar
                  background
                  className="c-task-title__meta__progress"
                  maxValue={taskLength}
                  strokeWidth={50}
                  value={taskCompleted}
                />
                <span className="c-task-title__meta__subtitle">
                  {taskCompleted}/{taskLength} taken
                </span>
              </span>
            </>
          )}
        </div>
        <ActionMenu
          className="c-task-title__options-menu"
          defaultShown={false}
          disabled={isDisabled}
          label="Takenlijst acties"
          popoverOptions={{
            strategy: 'fixed',
          }}
          popoverPlacement="bottom-end"
          popoverWidth={250}
        >
          <MenuItem
            item={{
              label: 'Nieuwe taak',
            }}
            onClick={() => onMenuNewTask?.()}
          />
          <MenuItem
            item={{
              label: isFilteredOnComplete ? 'Afgevinkte taken verbergen' : 'Afgevinkte taken tonen',
            }}
            onClick={() => onMenuSwitchListState?.()}
          />
          <MenuItem
            item={{
              label: 'Verwijderen',
            }}
            onClick={() => onMenuDeleteTaskList?.()}
          />
        </ActionMenu>
      </div>
    );
  }
};

TaskTitle.defaultProps = {
  isDisabled: false,
  isReadOnly: false,
  taskLength: 0,
  taskCompleted: 0,
  onNameChange: () => null,
};

export default TaskTitle;
