import './assets/styles/AttachmentList.scss';

import { CloseIcon } from '@elseu/sdu-titan';
import classNames from 'classnames';
import AttachmentIcon from 'components/attachment/AttachmentIcon';
import Date from 'components/date/Date';
import Options from 'components/options/Options';
import React, { useMemo } from 'react';

type AttachmentListProps = {
  className?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  title?: string;
  items?: any[];
  options?: any[];
  deleting?: any[];
  onMenuItemSelect?: (item?: any, action?: any, event?: any) => void;
  onItemClick?: (item?: any) => void;
  onRemove?: (item?: any, key?: any) => void;
};

const AttachmentList: React.FC<AttachmentListProps> = ({
  className,
  isDisabled = false,
  isReadOnly = false,
  title,
  items = [],
  options = [],
  deleting = [],
  onMenuItemSelect,
  onItemClick,
  onRemove,
}) => {
  const itemsState = useMemo(() => {
    return items.map((item) => {
      return {
        __menuOptions: options.map((optionItem) => {
          return {
            onClick: (evt: any) =>
              onMenuItemSelect ? onMenuItemSelect(item, optionItem.action, evt) : () => null,
            ...optionItem,
          };
        }),
        ...item,
      };
    });
  }, [items, options, onMenuItemSelect]);

  /**
   * Add a href if url value is set.
   *
   * @param {object} item
   * @param {*} element
   * @returns {*}
   */
  const createClickableElement = (item: any, element: string) => {
    if (!onItemClick) {
      if (item.url?.length) {
        return (
          <a
            className="c-attachment-list__item__name__text"
            href={item.url}
            rel="noopener noreferrer"
            target="_blank"
          >
            {element}
          </a>
        );
      }
    } else {
      // eslint-disable-next-line
      return React.createElement(
        'a',
        {
          className: 'c-attachment-list__item__name__text',
          onClick: (event) => {
            event.preventDefault();
            onItemClick(item);
          },
        },
        element,
      );
    }

    return <span className="c-attachment-list__item__name__text">{element}</span>;
  };
  if (itemsState.length === 0) {
    return null;
  }

  // render
  return (
    <div
      className={classNames('c-attachment-list', className, {
        'c-attachment-list__disabled': isDisabled,
      })}
    >
      {title && <div className="c-attachment-list__title">{title}</div>}
      {itemsState.length > 0 && (
        <ul className="c-attachment-list__list">
          {itemsState.map((attachment, attachmentKey) => (
            <li
              key={`attachment-${attachment.id || attachment.uploadId}`}
              className={classNames('c-attachment-list__item', {
                'c-attachment-list__item-uploading': attachment.uploading,
                'c-attachment-list__item-error': attachment.reason,
                'c-attachment-list__item-deleting':
                  attachment.id && deleting.includes(attachment.id),
                'c-attachment-list__item-show-remove': !!onRemove && !isReadOnly,
              })}
            >
              <AttachmentIcon
                className="c-attachment-list__item__icon"
                extension={attachment.extension}
                type={attachment.iconName}
              />
              <div className="c-attachment-list__item__name">
                {isReadOnly ? attachment.name : createClickableElement(attachment, attachment.name)}
                {!isReadOnly && onRemove && (
                  <span
                    aria-hidden="true"
                    className="c-attachment-list__item__remove-button"
                    role="button"
                    tabIndex={0}
                    onClick={() => onRemove(attachment, attachmentKey)}
                  >
                    <CloseIcon />
                  </span>
                )}
              </div>
              <div className="c-attachment-list__item__date">
                <Date date={attachment.createdDateTime} />
              </div>
              {!isReadOnly && (
                <Options isRtl isDisabled={isDisabled} options={attachment.__menuOptions} />
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AttachmentList;
