/* eslint-disable max-len */
import React from 'react';

import IconComponent from './IconComponent';

const StrokeArrowRightIcon = (props) => {
  return IconComponent(
    props,
    <svg fill="none" viewBox="0 0 8 12">
      <path
        clipRule="evenodd"
        d="M4.82258 6L0 1.48679L1.58871 0L8 6L1.58871 12L0 10.5132L4.82258 6Z"
        fillRule="evenodd"
      />
    </svg>,
  );
};

StrokeArrowRightIcon.defaultProps = IconComponent.defaultProps;
StrokeArrowRightIcon.propTypes = IconComponent.propTypes;

export default StrokeArrowRightIcon;
