/**
 * For each element in an array of objects, append a `hasChildren` property that
 * is true if the `children` property is an non-empty array
 * @param {array} arr Input array
 * @returns Enriched array
 */
const addHasChildren = (arr: any[] = []): any[] =>
  arr.map((x: any) =>
    x.children?.length
      ? { ...x, hasChildren: true, children: addHasChildren(x.children) }
      : { ...x, hasChildren: false },
  );

export { addHasChildren };
