import type { Ticket } from 'entity/dossiers/types';
import { useTicketPosition } from 'hooks/mutate/useTicketPosition';
import { useUpdateTicket } from 'hooks/mutate/useUpdateTicket';
import { useTickets } from 'hooks/queries/useTickets';
import { useGlobalMessages } from 'hooks/useGlobalMessages';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useUnmount } from 'react-use';
import type { ApiFilter } from 'types/types';

import type { KanbanProps } from './useKanban';

type UseTicketListViewParams = {
  filter: ApiFilter[];
};

export type UseTicketWorkOverviewReturn = {
  // List of tickets
  tickets: Ticket[];
  // Set the list of tickets optimistically, useful for drag & drop whilst waiting for the server response
  setOptimisticTickets: Dispatch<SetStateAction<Ticket[]>>;
  // Update an individual ticket's status or position
  updateTicket: KanbanProps['onTicketUpdate'];
  // Refetch the list of tickets
  refetch: () => void;
  isLoading: boolean;
};

/**
 * Hook to handle the general logic for the ticket work overview.
 *
 * @param filter
 */
export const useTicketWorkOverview = ({
  filter,
}: UseTicketListViewParams): UseTicketWorkOverviewReturn => {
  const { mutateAsync: updateTicketAsync } = useUpdateTicket();
  const { mutateAsync: repositionTicketAsync } = useTicketPosition();
  const [showTooManyTicketsWarning, setShowTooManyTicketsWarning] = useState(true);

  const { isLoading, data: ticketsData, refetch } = useTickets(filter);
  const { addMessage, removeMessage } = useGlobalMessages();

  const [tickets, setTickets] = useState<Ticket[]>([]);

  // The list of tickets is stored in the local state,
  // because we want to be able to update the list optimistically
  // without affecting the true state of the tickets.
  useEffect(() => {
    setTickets(ticketsData?.data.tickets || []);
  }, [ticketsData]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!showTooManyTicketsWarning) {
      return;
    }

    if (tickets.length >= 100) {
      addMessage({
        key: 'max-tickets',
        message: 'Let op! Niet alles wordt getoond, pas de filters aan om alles te tonen.',
        type: 'warning',
        dismissAfter: 10000,
        onDismiss: () => {
          setShowTooManyTicketsWarning(false);
        },
      });
    } else {
      removeMessage('max-tickets');
    }
  }, [isLoading, showTooManyTicketsWarning, tickets.length, addMessage, removeMessage]);

  useUnmount(() => {
    removeMessage('max-tickets');
  });

  const updateTicket = useCallback<KanbanProps['onTicketUpdate']>(
    async ({ ticketId, statusId, otherId, placement = 'after' }) => {
      const mutations = [];

      if (statusId) {
        mutations.push(
          updateTicketAsync({
            id: String(ticketId),
            ticket: {
              status: statusId as any,
            },
          }),
        );
      }

      if (otherId) {
        mutations.push(
          repositionTicketAsync({
            id: ticketId as Ticket['id'],
            otherId: otherId as Ticket['id'],
            placement,
          }),
        );
      }

      await Promise.all(mutations);

      await refetch();
    },
    [refetch, updateTicketAsync, repositionTicketAsync],
  );

  return {
    tickets,
    setOptimisticTickets: setTickets,
    isLoading,
    updateTicket,
    refetch,
  };
};
