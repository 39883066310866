import React from 'react';

import { ReactComponent } from './assets/svg/EllipsisIcon.svg';
import type { IconComponentProps } from './IconComponent';
import IconComponent from './IconComponent';

const EllipsisIcon = (props: IconComponentProps) => IconComponent(props, <ReactComponent />);

EllipsisIcon.propTypes = IconComponent.propTypes;
EllipsisIcon.defaultProps = IconComponent.defaultProps;

export default EllipsisIcon;
