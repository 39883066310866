import { Avatar, BusinessIcon } from '@elseu/sdu-titan';
import { getInitials } from 'helpers/initial';
import DossierTeam from 'models/dossier/DossierTeam';
import DossierUser from 'models/dossier/DossierUser';
import type User from 'models/me/User';
import type Team from 'models/system/Team';
import React from 'react';

export enum ISignalingType {
  TYPE_EXCEL = 'excel',
  TYPE_QUERY = 'query',
}

export type IPermission = {
  id: string;
  name: string;
  email: string;
  type: IPermissionType;
  role: IPermissionRole;
  canSetRole: boolean;
  canRemove: boolean;
};

export enum IPermissionType {
  TYPE_USER = 'user',
  TYPE_TEAM = 'team',
}

export enum IPermissionRole {
  ROLE_EDITOR = 'EDITOR',
  ROLE_VIEWER = 'VIEWER',
  ROLE_OWNER = 'OWNER',
}

export type IPermissionOption = {
  label?: string;
  subtitle?: string;
  value?: string;
  type: IPermissionType;
};

type Size = 32 | 40 | 48 | 80 | 96;

export const createPermissionTypeAvatar = (
  type: IPermissionType,
  name: string,
  size: Size = 40,
) => {
  if (type === IPermissionType.TYPE_USER) {
    return <Avatar size={size}>{getInitials(name)}</Avatar>;
  }
  return (
    <Avatar size={size}>
      <BusinessIcon />
    </Avatar>
  );
};

export const createPermissionAvatar = (permission: IPermission, size: Size = 40) =>
  createPermissionTypeAvatar(permission.type, permission.name, size);

export const createPermissionsFromUsers = (users: User[], canManageOwners = false) =>
  users.map((user: User) => {
    return createUserPermission(
      user.getIdentity() ?? '',
      user.getFullName(),
      user.getEmail(),
      IPermissionRole.ROLE_VIEWER,
      canManageOwners,
      canManageOwners,
    );
  });

export const createPermissionsFromTeams = (teams: Team[], canManageOwners = false): IPermission[] =>
  teams.map((team: Team) => {
    return createTeamPermission(
      team.getIdentity() ?? '',
      team.getName(),
      IPermissionRole.ROLE_VIEWER,
      canManageOwners,
      canManageOwners,
    );
  });

export const roleToPermissionRole = (role: string): IPermissionRole => {
  if (role === 'EDITOR') {
    return IPermissionRole.ROLE_EDITOR;
  } else if (role === 'OWNER') {
    return IPermissionRole.ROLE_OWNER;
  }

  return IPermissionRole.ROLE_VIEWER;
};

export const createPermissionsFromDossierUsers = (
  dossierUsers: DossierUser[],
  canManageOwners = false,
): IPermission[] =>
  dossierUsers.map((dossierUser: DossierUser) => {
    const canManageRole = !canManageOwners
      ? dossierUser.getRole() !== IPermissionRole.ROLE_OWNER
      : true;

    return createUserPermission(
      dossierUser.getUser().getIdentity() ?? '',
      dossierUser.getUser().getFullName(),
      dossierUser.getUser().getEmail(),
      roleToPermissionRole(dossierUser.getRole()),
      canManageRole,
      canManageRole,
    );
  });

export const createPermissionsFromDossierTeams = (
  dossierTeams: DossierTeam[],
  canManageOwners = false,
) =>
  dossierTeams.map((dossierTeam: DossierTeam) => {
    const canManageRole = !canManageOwners
      ? dossierTeam.getRole() !== IPermissionRole.ROLE_OWNER
      : true;

    return createTeamPermission(
      dossierTeam.getTeam().getIdentity() ?? '',
      dossierTeam.getTeam().getName(),
      roleToPermissionRole(dossierTeam.getRole()),
      canManageRole,
      canManageRole,
    );
  });

export const createPermissions = (profile: any, settings: any) => [
  ...createPermissionsFromDossierUsers([
    DossierUser.createInstance({
      user: profile,
      role: 'OWNER',
    }) as unknown as DossierUser,
  ]),
  ...(settings.hasDossierTeam()
    ? createPermissionsFromDossierTeams([
        DossierTeam.createInstance({
          team: settings.getDossierTeam(),
          role: 'VIEWER',
        }) as unknown as DossierTeam,
      ])
    : []),
];

export const createUserPermission = (
  id: string,
  name: string,
  email: string,
  role: IPermissionRole,
  canRemove = true,
  canSetRole = true,
): IPermission => {
  return {
    id,
    name,
    email,
    role,
    canRemove,
    canSetRole,
    type: IPermissionType.TYPE_USER,
  };
};

export const createTeamPermission = (
  id: string,
  name: string,
  role: IPermissionRole,
  canRemove = true,
  canSetRole = true,
): IPermission => {
  return {
    id,
    name,
    role,
    canRemove,
    canSetRole,
    type: IPermissionType.TYPE_TEAM,
    email: '',
  };
};

export const mergePermissionsWithinPermissions = (
  toMerge: IPermission[],
  permissions: IPermission[] = [],
): IPermission[] => permissions.concat(toMerge);

export const updatePermissionWithinPermissions = (
  permission: IPermission,
  permissions: IPermission[] = [],
): IPermission[] =>
  permissions.map((item) => {
    if (item.id === permission.id && item.type === permission.type) {
      return permission;
    }
    return item;
  });

export const removePermissionWithinPermissions = (
  permission: IPermission,
  permissions: IPermission[] = [],
): IPermission[] =>
  permissions.filter((item) => {
    return !(item.id === permission.id && item.type === permission.type);
  });

export const removePermissionsWithinPermissions = (
  permissionsToRemove: IPermission[] = [],
  permissions: IPermission[] = [],
): IPermission[] =>
  permissions.filter((item) => {
    return !permissionsToRemove.some((filterItem) => {
      return item.id === filterItem.id && item.type === filterItem.type;
    });
  });

export const sortPermissions = (permissions: IPermission[] = []) =>
  permissions.sort((permissionA: IPermission, permissionB: IPermission) => {
    const nameA = permissionA.name.toUpperCase(); // ignore upper and lowercase
    const nameB = permissionB.name.toUpperCase(); // ignore upper and lowercase

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

export const permissionToOption = (permission: IPermission): IPermissionOption => ({
  value: permission.id,
  label: permission.name,
  subtitle: permission.email,
  type: permission.type,
});

export const permissionsToOptions = (permissions: IPermission[] = []): IPermissionOption[] =>
  permissions.map(permissionToOption);

export const optionToPermission = (
  permissionOption: IPermissionOption,
  role: IPermissionRole,
): IPermission => ({
  id: permissionOption.value ?? '',
  name: permissionOption.label ?? '',
  email: permissionOption.subtitle ?? '',
  type: permissionOption.type,
  role,
  canSetRole: false,
  canRemove: false,
});

export const optionsToPermissions = (
  permissionOptions: IPermissionOption[],
  role: IPermissionRole,
): IPermission[] =>
  permissionOptions.map((permissionOption) => optionToPermission(permissionOption, role));

export type DossierUserPostValue = {
  user: string;
  role: IPermissionRole;
};

export const permissionToDossierUserPostValue = (
  permission: IPermission,
): DossierUserPostValue => ({
  user: permission.id,
  role: permission.role,
});

export const createDossierUsersPostValuesFromPermissions = (
  permissions: IPermission[] = [],
): DossierUserPostValue[] =>
  permissions
    .filter((permission) => permission.type === IPermissionType.TYPE_USER)
    .map(permissionToDossierUserPostValue);

export type DossierTeamPostValue = {
  team: string;
  role: IPermissionRole;
};

export const permissionToDossierTeamPostValue = (
  permission: IPermission,
): DossierTeamPostValue => ({
  team: permission.id,
  role: permission.role,
});

export const createDossierTeamsPostValuesFromPermissions = (
  permissions: IPermission[] = [],
): DossierTeamPostValue[] =>
  permissions
    .filter((permission) => permission.type === IPermissionType.TYPE_TEAM)
    .map(permissionToDossierTeamPostValue);
