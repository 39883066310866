import { mapItemToSelectOption } from 'components/formfields/helpers/mapItemToSelectOption';
import type { SelectFieldProps } from 'components/formfields/SelectField';
import { SelectField } from 'components/formfields/SelectField';
import { FormGroupHorizontal } from 'components/formgroup/FormGroupHorizontal';
import { useProfileSettings } from 'hooks';
import React from 'react';

export const TopicSelectFormField: React.FC<Partial<SelectFieldProps>> = (props) => {
  const [settings] = useProfileSettings();
  const topics = settings.getTopics().map(mapItemToSelectOption);

  return (
    <FormGroupHorizontal label="Thema's">
      <SelectField
        isLabelHidden
        isMulti
        isSearchable
        buttonVariant="clear"
        label="Thema's"
        name="topics"
        options={topics}
        placeholder="Selecteer een thema"
        spaceAfter={0}
        type="clear"
        {...(props as any)}
      />
    </FormGroupHorizontal>
  );
};
