import { ThemeProvider } from '@elseu/sdu-titan';
import * as Sentry from '@sentry/react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AuthRedirectLoadState, AuthRedirectSaveState } from 'components/authredirect';
import { GlobalError } from 'components/globalerror/GlobalError';
import { Lazy } from 'components/lazy/Lazy';
import { LeavePromptProvider } from 'components/leaveprompt';
import { NotificationToasterPlacement } from 'components/notificationcenter/NotificationToasterPlacement';
import ApplicationConstants from 'constants/ApplicationConstants';
import ApplicationContainer from 'containers/application';
import TicketsContainer from 'containers/tickets';
import React, { lazy } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { useAccountState } from 'stores/account/AccountState';
import { useReactRouterLinkResolver, useReactRouterLinkWrapper } from 'utils/react-router-dom';

import { FullscreenError } from './components/errorboundary/FullscreenError';

// lazy load children route containers.
const AuthenticateContainer = lazy(() => import('containers/authenticate'));
const DashboardContainer = lazy(() => import('containers/dashboard'));
const BetaContainer = lazy(() => import('containers/beta'));
const DossiersContainer = lazy(() => import('containers/dossiers'));
const ClientsContainer = lazy(() => import('containers/crm'));
const SettingsContainer = lazy(() => import('containers/settings'));
const TasksContainer = lazy(() => import('containers/tasks'));
const SignalingContainer = lazy(() => import('containers/signaling'));
const ContactNoteContainer = lazy(() => import('containers/contactnote'));
const LogoutContainer = lazy(() => import('containers/logout/src/LogoutContainer'));
const LegalSourceContainer = lazy(() => import('containers/legalsource'));
const SearchContainer = lazy(() => import('containers/search'));
const NotFoundContainer = lazy(() => import('containers/notfound/src/NotFoundContainer'));

const AppRoutes = () => {
  const [accountState] = useAccountState();

  if (!accountState.authenticated) {
    return (
      <Routes>
        <Route
          element={
            <Lazy>
              <AuthenticateContainer />
            </Lazy>
          }
          path="authenticate/*"
        />
        <Route
          element={
            <Lazy>
              <LogoutContainer />
            </Lazy>
          }
          path="logout"
        />
        <Route
          element={
            <Lazy>
              <AuthRedirectSaveState />
            </Lazy>
          }
          path="*"
        />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route
          element={
            <Lazy>
              <AuthRedirectLoadState />
            </Lazy>
          }
          path="authenticate/*"
        />
        <Route
          element={
            <Lazy>
              <LegalSourceContainer />
            </Lazy>
          }
          path="ndfr_share"
        />
        <Route
          element={
            <ApplicationContainer>
              <Outlet />
            </ApplicationContainer>
          }
          path="/"
        >
          <Route
            index
            element={
              <Lazy>
                <DashboardContainer />
              </Lazy>
            }
            path="/"
          />
          <Route
            element={
              <Lazy>
                <SearchContainer />
              </Lazy>
            }
            path="search/*"
          />
          <Route
            element={
              <Lazy>
                <SignalingContainer />
              </Lazy>
            }
            path="signaling/*"
          />
          <Route
            element={
              <Lazy>
                <TasksContainer />
              </Lazy>
            }
            path="tasks/*"
          />
          <Route
            element={
              <Lazy>
                <TicketsContainer />
              </Lazy>
            }
            path="tickets/*"
          />
          <Route
            element={
              <Lazy>
                <DossiersContainer />
              </Lazy>
            }
            path="dossiers/*"
          />
          <Route
            element={
              <Lazy>
                <BetaContainer />
              </Lazy>
            }
            path="beta/*"
          />
          <Route
            element={
              <Lazy>
                <ClientsContainer />
              </Lazy>
            }
            path="crm/*"
          />
          <Route
            element={
              <Lazy>
                <ContactNoteContainer />
              </Lazy>
            }
            path="contact_note/*"
          />
          <Route
            element={
              <Lazy>
                <SettingsContainer />
              </Lazy>
            }
            path="settings/*"
          />
          <Route
            element={
              <Lazy>
                <LogoutContainer />
              </Lazy>
            }
            path="logout"
          />
          <Route
            element={
              <Lazy>
                <NotFoundContainer />
              </Lazy>
            }
            path="*"
          />
        </Route>
      </Routes>
    );
  }
};

/**
 * App component
 *
 * @returns {ReactElement}
 */
export const App = () => {
  // Create a client for React Query
  const queryCache = new QueryCache({
    onError: (error) => {
      // 401 Unauthorized return. Redirect to hub.
      // only redirect to the hub when the message does not start with HUB API:.
      // if the message starts with that, that means that the API key for the HUB is not setup correctly for the api.
      if (error.code === 401 && !error.message.startsWith('HUB API:')) {
        document.location = `${ApplicationConstants.APPLICATION_LOGIN_HUB_HOST}/auth/login`;
      }
    },
  });

  const queryClient = new QueryClient({
    queryCache,
  });

  return (
    <ThemeProvider
      useLinkResolver={useReactRouterLinkResolver}
      useLinkWrapper={useReactRouterLinkWrapper}
    >
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Sentry.ErrorBoundary fallback={<FullscreenError />}>
            <LeavePromptProvider>
              <GlobalError />
              <NotificationToasterPlacement />
              <AppRoutes />
            </LeavePromptProvider>
          </Sentry.ErrorBoundary>
          <ReactQueryDevtools buttonPosition="bottom-left" initialOpen={false} />
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
