import { Loader } from '@elseu/sdu-titan';
import { useCreateContactNote } from 'components/contactnote/hooks/useContactNoteCreate';
import { useContactNoteUpdate } from 'components/contactnote/hooks/useContactNoteUpdate';
import { mapItemToSelectOption } from 'components/formfields/helpers/mapItemToSelectOption';
import type { ContactNote } from 'entity/contactnote/types';
import { useContactNoteDetails } from 'hooks/queries';
import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import type { ContactNotePayload } from 'services/api/ContactNoteApi';

import { useTrySaveForm } from '../../hooks/useTrySaveForm';
import type { TicketFormSubmitHandler } from '../ticket/forms/TicketFormProps';
import type { ContactNoteFormValues } from './forms/ContactNoteForm';
import { ContactNoteForm } from './forms/ContactNoteForm';

const mapContactNoteToContactNoteFormValues = (
  contactNote?: ContactNote,
  defaultValues: ContactNoteFormValues = {},
): ContactNoteFormValues => {
  if (!contactNote?.contactNoteCard)
    return {
      name: '',
      assignedUser: '',
      createdBy: '',
      client: null,
      dossier: null,
      notes: '',
      status: '',
      subType: null,
      ...defaultValues,
    };
  const { contactNoteCard } = contactNote;
  return {
    id: contactNoteCard.id,
    name: contactNoteCard.name,
    assignedUser: contactNoteCard.assignedUser?.id,
    createdBy: contactNoteCard.createdBy?.id,
    client: contactNote.clients
      ? contactNote.clients.map((client) => ({
          value: client.client?.id || '',
          label: client.client?.displayName || '',
        }))[0]
      : null,
    dossier: mapItemToSelectOption(contactNoteCard.dossier),
    notes: contactNoteCard.notes,
    status: contactNoteCard.label?.id,
    subType: contactNoteCard.subType as any,
  };
};

const mapContactNoteFormValuesToContactNotePayload = (
  formValues: ContactNoteFormValues,
): ContactNotePayload => {
  return {
    name: formValues.name || '',
    client: formValues.client?.value,
    dossier: formValues.dossier?.value,
    notes: formValues.notes || '',
    subType: formValues.subType || '',
    label: formValues.status || '',
    user: formValues.assignedUser || '',
  };
};

interface ContactNoteEditProps {
  contactNoteId?: string;
  defaultValues?: ContactNoteFormValues;
  onSuccess?: (contactNote: ContactNote) => void;
}
export const ContactNoteEditController = ({
  contactNoteId,
  defaultValues,
  onSuccess,
}: ContactNoteEditProps) => {
  const { reset } = useFormContext();
  const { contactNote } = useContactNoteDetails(contactNoteId);
  const mode = contactNoteId ? 'edit' : 'create';

  useEffect(() => {
    reset(mapContactNoteToContactNoteFormValues(contactNote, defaultValues));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, contactNote]);

  const { mutateAsync: createContactNote, isSuccess: isCreated } = useCreateContactNote();
  const { mutateAsync: updateContactNote } = useContactNoteUpdate();

  const catchFormError = useTrySaveForm();

  const handleSubmit: TicketFormSubmitHandler<ContactNoteFormValues> = useCallback(
    async (formValues, form) => {
      const contactNotePayload = mapContactNoteFormValuesToContactNotePayload(formValues);

      await catchFormError<ContactNoteFormValues>(
        async () => {
          const data = await (contactNote
            ? updateContactNote({
                id: contactNoteId,
                contactNote: contactNotePayload,
              })
            : createContactNote({
                contactNote: contactNotePayload,
              }));

          onSuccess?.(data.data);
        },
        form,
        'Contactnotitie opgeslagen',
      );
    },
    [catchFormError, contactNote, contactNoteId, createContactNote, onSuccess, updateContactNote],
  );

  const showSpinner = mode === 'edit' && !contactNote && !isCreated;

  if (showSpinner) {
    return <Loader height={48} variant="spinner" />;
  }

  return <ContactNoteForm mode={mode} onSubmit={handleSubmit} />;
};
