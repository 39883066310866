import type { OnboardingStepProps } from '@elseu/sdu-titan';
import { Box, Image, Onboarding, Video } from '@elseu/sdu-titan';
import type { Announcement } from 'entity/announcement/types';
import { useAnnouncements } from 'hooks/queries/useAnnouncements';
import React, { useMemo } from 'react';
import styled from 'styled-components';

type Props = {
  isShown: boolean;
  onToggle: (isOpen: boolean) => void;
};

const VideoStyled = styled(Video)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ImageStyled = styled(Image)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const MediaWrapper = styled(Box)`
  display: block;
  width: 460px;
  height: 400px;
  position: relative;
`;

const MediaComponent: React.FC<{ item: Announcement }> = ({ item }) => {
  if (item.videoUrl) {
    return (
      <MediaWrapper>
        <VideoStyled type="youtube" url={item.videoUrl} width="100%" />
      </MediaWrapper>
    );
  }

  if (item.heroImageUrl) {
    return (
      <MediaWrapper>
        <ImageStyled
          alt={item.name}
          height={400}
          objectFit="contain"
          src={item.heroImageUrl}
          width={460}
        />
        ;
      </MediaWrapper>
    );
  }

  return null;
};

const labelDateFormat = new Intl.DateTimeFormat('nl-NL', {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

export const AnnouncementsDialog: React.FC<Props> = ({ isShown, onToggle }) => {
  const { data } = useAnnouncements();

  const steps: OnboardingStepProps[] = useMemo(() => {
    return (data ?? []).map((item) => ({
      label: labelDateFormat.format(item.displayDate),
      title: item.name,
      content: item.description ?? '',
      media: <MediaComponent item={item} />,
    }));
  }, [data]);

  if ((data ?? []).length === 0) {
    return null;
  }

  return <Onboarding isShown={isShown} stepsConfiguration={steps} onToggle={onToggle} />;
};
