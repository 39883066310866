import DataModel, { PropTypes } from 'models/base/DataModel';
import User from 'models/me/User';

/**
 * Legal source
 */
export default class LegalSource extends DataModel {
  static propTypes = {
    ...DataModel.propTypes,
    name: PropTypes.string,
    comment: PropTypes.string,
    url: PropTypes.string,
    type: PropTypes.string,
    saveCurrentVersion: PropTypes.bool,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    createdBy: PropTypes.shape(User.propTypes),
  };

  /**
   * @type {string}
   */
  name;

  /**
   * @type {string}
   */
  comment;

  /**
   * @type {string}
   */
  url;

  /**
   * @type {string}
   */
  type;

  /**
   * @type {string}
   */
  createdAt;

  /**
   * @type {string}
   */
  updatedAt;

  /**
   * type {User}
   */
  createdBy;

  /**
   * @type {boolean}
   */
  saveCurrentVersion = false;

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'createdBy') {
      value = User.createInstance(value);
    }

    return super.set(fieldName, value);
  }
}
