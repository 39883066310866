import type { CollisionDetection, UniqueIdentifier } from '@dnd-kit/core';
import {
  closestCenter,
  DndContext,
  getFirstCollision,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  pointerWithin,
  rectIntersection,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box, Button, Flex, PlusIcon, Text } from '@elseu/sdu-titan';
import ConfirmDialog from 'components/confirmdialog/ConfirmDialog';
import type { Task, TaskList } from 'entity/dossiers/types';
import { itemDetermineNewPosition } from 'helpers/taskListHelper';
import concat from 'lodash/concat';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import TaskListItemDisplay from './TaskListItemDisplay';
import TaskTitle from './TaskTitle';
import type { TaskListViewItem } from './types';

export type { TaskListViewItem };

const SortHandleIcon = () => (
  <svg fill="none" height="24" viewBox="0 0 14 24" width="24">
    {/* eslint-disable-next-line max-len */}
    <path
      d="M13.7471 7.5C13.8906 7.5 14 7.39062 14 7.24707C14 7.17871 13.9727 7.11719 13.9248 7.06934L7.1709 0.568359C7.12988 0.527344 7.06836 0.5 7 0.5C6.93164 0.5 6.87012 0.527344 6.8291 0.568359L0.0751953 7.06934C0.0273438 7.11719 0 7.17871 0 7.24707C0 7.39062 0.109375 7.5 0.25293 7.5C0.314453 7.5 0.375977 7.47266 0.423828 7.43164L7 1.09473L13.5762 7.43164C13.624 7.47266 13.6855 7.5 13.7471 7.5ZM7 23.5C7.06836 23.5 7.12988 23.4727 7.1709 23.4316L13.9248 16.9307C13.9727 16.8828 14 16.8213 14 16.7461C14 16.6094 13.8906 16.5 13.7471 16.5C13.6855 16.5 13.624 16.5273 13.5762 16.5684L7 22.9053L0.423828 16.5684C0.375977 16.5273 0.314453 16.5 0.25293 16.5C0.109375 16.5 0 16.6094 0 16.7461C0 16.8213 0.0273438 16.8828 0.0751953 16.9307L6.8291 23.4316C6.87012 23.4727 6.93164 23.5 7 23.5Z"
      fill="#CCCCCC"
    />
  </svg>
);

const SortHandleButton = styled.button<{ isDragging?: boolean }>`
  appearance: none;
  background-color: white;
  border: 0;
  cursor: move;
  cursor: ${(props) => (props.isDragging ? 'grabbing' : 'grab')};
  width: 1.6rem;
  outline: none;
`;

const HiddenSortHandleButton = styled(SortHandleButton)`
  visibility: hidden;
`;

const SortContentHolder = styled.div`
  flex: 1 1 auto;
`;

type BaseProps = {
  className?: string;
  isReadOnly?: boolean;
  isDisabled?: boolean;
};

type NewItemRendererType = {
  showNewItemUnder: string[];
  newItemRenderer: (taskList: TaskList) => React.ReactElement;
};

type TaskListEvents = {
  onTasklistNameChange?: (taskList: TaskList, name: string | undefined) => void;
  onTasklistNew?: () => void;
  onTasklistDelete?: (taskList: TaskList) => void;
  onNewTask?: (taskList: TaskList) => void;
};

type TaskEvents = {
  onTaskCheckboxChange?: (task: Task, taskList: TaskList, checked: boolean) => void;
  onTaskDelete?: (task: Task) => void;
  onTaskClick?: (task: Task) => void;
};

type TaskListDndProps = BaseProps &
  TaskEvents &
  TaskListEvents &
  NewItemRendererType & {
    tasklists: TaskListViewItem[];
    isSortingDisabled?: boolean;
    showNewItemUnder: string[];
    newItemRenderer: (taskList: TaskList) => React.ReactElement;
    onTasklistSort?: (taskList: TaskList, position: number) => void;
    onTaskSort?: (task: Task, taskList: TaskList, position: number) => void;
  };

type Items = Record<UniqueIdentifier, UniqueIdentifier[]>;

export const TaskListDnd: React.FC<TaskListDndProps> = ({
  tasklists,
  isSortingDisabled,
  isReadOnly,
  showNewItemUnder = [],
  newItemRenderer,
  onTasklistSort,
  onTaskSort,
  onTasklistNameChange,
  onTasklistDelete,
  onNewTask,
  onTaskCheckboxChange,
  onTaskClick,
  onTaskDelete,
}) => {
  const tasklistsByKey = useMemo<Record<UniqueIdentifier, TaskListViewItem>>(
    () => keyBy(tasklists, 'id'),
    [tasklists],
  );
  const tasksByKey = useMemo<Record<UniqueIdentifier, Task>>(() => {
    const tasks = tasklists.map((item) => item.tasks);
    return keyBy(concat(...tasks), 'id');
  }, [tasklists]);

  const [items, setItems] = useState<Items>(() => {
    const retValue = tasklists.map((item) => {
      return {
        id: item.id,
        subIds: item.tasks.map((item) => item.id),
      };
    });

    return mapValues(keyBy(retValue, 'id'), 'subIds');
  });

  const [containers, setContainers] = useState(Object.keys(items) as UniqueIdentifier[]);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);
  const needToSendOnSortEvent = useRef(false);
  const isSortingContainer = activeId ? containers.includes(activeId) : false;

  useEffect(() => {
    const retValue = tasklists.map((item) => {
      return {
        id: item.id,
        subIds: item.tasks.map((item) => item.id),
      };
    });
    const newItems = mapValues(keyBy(retValue, 'id'), 'subIds');

    setItems(newItems);
    setContainers(Object.keys(newItems) as UniqueIdentifier[]);
  }, [tasklists]);

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container: any) => container.id in items,
          ),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId != null) {
        if (overId in items) {
          const containerItems = items[overId];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) => container.id !== overId && containerItems.includes(container.id),
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift,
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container; otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items],
  );
  const [clonedItems, setClonedItems] = useState<Items | null>(null);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const findContainer = (id: UniqueIdentifier) => {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => items[key].includes(id));
  };

  const getIndex = (id: UniqueIdentifier) => {
    const container = findContainer(id);

    if (!container) {
      return -1;
    }

    return items[container].indexOf(id);
  };

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setItems(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  return (
    <DndContext
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      sensors={sensors}
      onDragCancel={onDragCancel}
      onDragEnd={({ active, over, delta }) => {
        if (active.id in items && over?.id) {
          // const activeIndex = containers.indexOf(active.id);
          const overIndex = containers.indexOf(over.id);

          if (onTasklistSort) {
            // determine new priority for task-list
            const topItemPosition =
              overIndex > 0
                ? tasklistsByKey[containers[overIndex - (delta.y > 0 ? 0 : 1)]].position
                : null;
            const bottomItemPosition =
              overIndex < containers.length - 1
                ? tasklistsByKey[containers[overIndex + (delta.y > 0 ? 1 : 0)]].position
                : null;

            const newPosition = itemDetermineNewPosition(topItemPosition, bottomItemPosition);

            requestAnimationFrame(() => {
              onTasklistSort(tasklistsByKey[active.id], newPosition);
            });
          }

          setContainers((containers) => {
            const activeIndex = containers.indexOf(active.id);
            const overIndex = containers.indexOf(over.id);

            return arrayMove(containers, activeIndex, overIndex);
          });
        }

        const activeContainer = findContainer(active.id);

        if (!activeContainer) {
          setActiveId(null);
          return;
        }

        const overId = over?.id;

        if (overId == null) {
          setActiveId(null);
          return;
        }

        const overContainer = findContainer(overId);

        if (overContainer) {
          const activeIndex = items[activeContainer].indexOf(active.id);
          const overIndex = items[overContainer].indexOf(overId);

          if (activeIndex !== overIndex || needToSendOnSortEvent.current) {
            const newContainerItems = arrayMove(items[overContainer], activeIndex, overIndex);

            if (onTaskSort) {
              const topItemPosition =
                overIndex > 0 ? tasksByKey[newContainerItems[overIndex - 1]].position : null;
              const bottomItemPosition =
                overIndex < newContainerItems.length - 1
                  ? tasksByKey[newContainerItems[overIndex + 1]].position
                  : null;
              const newPosition = itemDetermineNewPosition(topItemPosition, bottomItemPosition);
              onTaskSort(tasksByKey[active.id], tasklistsByKey[activeContainer], newPosition);
              needToSendOnSortEvent.current = false;
            }

            if (activeIndex !== overIndex) {
              setItems((items) => ({
                ...items,
                [overContainer]: newContainerItems,
              }));
            }
          }
        }

        setActiveId(null);
      }}
      onDragOver={({ active, over }) => {
        const overId = over?.id;

        if (overId == null || active.id in items) {
          return;
        }

        const overContainer = findContainer(overId);
        const activeContainer = findContainer(active.id);

        if (!overContainer || !activeContainer) {
          return;
        }

        if (activeContainer !== overContainer) {
          setItems((items) => {
            const activeItems = items[activeContainer];
            const overItems = items[overContainer];
            const overIndex = overItems.indexOf(overId);
            const activeIndex = activeItems.indexOf(active.id);

            let newIndex: number;

            if (overId in items) {
              newIndex = overItems.length + 1;
            } else {
              const isBelowOverItem =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.top > over.rect.top + over.rect.height;

              const modifier = isBelowOverItem ? 1 : 0;

              newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }

            recentlyMovedToNewContainer.current = true;
            needToSendOnSortEvent.current = true;

            return {
              ...items,
              [activeContainer]: items[activeContainer].filter((item) => item !== active.id),
              [overContainer]: [
                ...items[overContainer].slice(0, newIndex),
                items[activeContainer][activeIndex],
                ...items[overContainer].slice(newIndex, items[overContainer].length),
              ],
            };
          });
        }
      }}
      onDragStart={({ active }) => {
        setActiveId(active.id);
        setClonedItems(items);
      }}
    >
      <SortableContext items={[...containers]} strategy={verticalListSortingStrategy}>
        {containers.map(
          (containerId) =>
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            tasklistsByKey[containerId] !== undefined && (
              <TaskListSortItem
                key={containerId}
                isReadOnly={isReadOnly}
                isSortingDisabled={isSortingDisabled}
                newItemRenderer={newItemRenderer}
                showNewItemUnder={showNewItemUnder}
                taskList={tasklistsByKey[containerId]}
                onNewTask={onNewTask}
                onTaskCheckboxChange={onTaskCheckboxChange}
                onTaskClick={onTaskClick}
                onTaskDelete={onTaskDelete}
                onTasklistDelete={onTasklistDelete}
                onTasklistNameChange={onTasklistNameChange}
              >
                <SortableContext items={items[containerId]} strategy={verticalListSortingStrategy}>
                  {items[containerId].map(
                    (itemId) =>
                      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                      tasksByKey[itemId] !== undefined && (
                        <Box key={itemId} spaceAfter={4}>
                          <TaskSortItem
                            getIndex={getIndex}
                            isReadOnly={isReadOnly}
                            isSortingContainer={isSortingContainer}
                            isSortingDisabled={isSortingDisabled}
                            task={tasksByKey[itemId]}
                            taskList={tasklistsByKey[containerId]}
                            onTaskCheckboxChange={onTaskCheckboxChange}
                            onTaskClick={onTaskClick}
                            onTaskDelete={onTaskDelete}
                          />
                        </Box>
                      ),
                  )}
                </SortableContext>
                {!showNewItemUnder.includes(containerId.toString()) &&
                  items[containerId].length === 0 && (
                    <Box pl={8} spaceAfter={2}>
                      <Text isBlock color="grey60" type="labelTiny">
                        Deze lijst is leeg. Voeg een taak toe om te beginnen
                      </Text>
                    </Box>
                  )}
              </TaskListSortItem>
            ),
        )}
      </SortableContext>
    </DndContext>
  );
};

type TaskListSortItemProps = BaseProps &
  TaskListEvents &
  TaskEvents &
  NewItemRendererType &
  React.PropsWithChildren<{
    taskList: TaskListViewItem;
    isSortingDisabled?: boolean;
  }>;

const TaskListSortItem: React.FC<TaskListSortItemProps> = ({
  taskList,
  isSortingDisabled,
  isDisabled,
  isReadOnly,
  showNewItemUnder,
  newItemRenderer,
  onTasklistNameChange,
  onTasklistDelete,
  onNewTask,
  onTaskCheckboxChange,
  onTaskClick,
  onTaskDelete,
  children,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
    id: taskList.id,
    disabled: isSortingDisabled,
  });
  const [showCompletedItems, setShowCompletedItems] = useState<boolean>(true);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <>
      <Box ref={setNodeRef} style={style}>
        <Flex gap={2} spaceAfter={6}>
          {!isSortingDisabled && (
            <SortHandleButton
              isDragging={active?.id === taskList.id}
              type="button"
              {...attributes}
              {...listeners}
            >
              <SortHandleIcon />
            </SortHandleButton>
          )}
          <SortContentHolder>
            <TaskTitle
              isDisabled={isDisabled}
              isFilteredOnComplete={showCompletedItems}
              isReadOnly={isReadOnly}
              name={taskList.name}
              taskCompleted={taskList.completedTasks.length}
              taskLength={taskList.completedTasks.length + taskList.tasks.length}
              onMenuDeleteTaskList={() => setShowDeleteConfirm(true)}
              onMenuNewTask={() => onNewTask?.(taskList)}
              onMenuSwitchListState={() => setShowCompletedItems(!showCompletedItems)}
              onNameChange={(name) => onTasklistNameChange?.(taskList, name)}
            />
          </SortContentHolder>
        </Flex>
        <Box spaceAfter={1}>{children}</Box>
        {showCompletedItems && taskList.completedTasks.length > 0 && (
          <Box>
            {taskList.completedTasks.map((task) => (
              <Flex key={task.id} gap={1} spaceAfter={4}>
                <HiddenSortHandleButton />
                <TaskListItemDisplay
                  assignedUser={task.assignedUser}
                  completedDateTime={task.completedDateTime?.toISOString()}
                  deadlineDate={task.deadlineDate?.toISOString()}
                  isDisabled={isDisabled}
                  isReadOnlyAllowCheckbox={!isReadOnly}
                  isReadOnlyClickable={true}
                  name={task.name}
                  onCheckboxChange={(value) => onTaskCheckboxChange?.(task, taskList, value)}
                  onDelete={!isReadOnly ? () => onTaskDelete?.(task) : undefined}
                  onTitleClick={() => onTaskClick?.(task)}
                />
              </Flex>
            ))}
          </Box>
        )}
        <Box spaceAfter={1}>
          {!isReadOnly && !showNewItemUnder.includes(taskList.id) && onNewTask && (
            <Button
              iconLeft={<PlusIcon />}
              size="S"
              type="button"
              variant="clear"
              onClick={() => onNewTask(taskList)}
            >
              Taak toevoegen
            </Button>
          )}
        </Box>

        {showNewItemUnder.includes(taskList.id) && newItemRenderer(taskList)}
      </Box>
      <ConfirmDialog
        cancelTitle="Nee"
        confirmAppearance="danger"
        confirmTitle="Ja, verwijder"
        isShown={showDeleteConfirm}
        title="Verwijderen"
        zIndex={9999999} /* Quick fix until we completely switched to Titan */
        onCancel={() => setShowDeleteConfirm(false)}
        onConfirm={() => {
          onTasklistDelete?.(taskList);
          setShowDeleteConfirm(false);
        }}
      >
        Weet u zeker dat deze takenlijst wilt verwijderen?
      </ConfirmDialog>
    </>
  );
};

type TaskSortItemProps = BaseProps &
  TaskEvents & {
    task: Task;
    taskList: TaskList;
    isSortingContainer: boolean;
    isSortingDisabled?: boolean;
    getIndex: (id: UniqueIdentifier) => number;
  };

const TaskSortItem: React.FC<TaskSortItemProps> = ({
  task,
  taskList,
  isSortingContainer,
  isSortingDisabled,
  isDisabled,
  isReadOnly,
  onTaskCheckboxChange,
  onTaskClick,
  onTaskDelete,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
    id: task.id,
    disabled: isSortingDisabled,
  });
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <>
      <Box ref={isSortingContainer ? undefined : setNodeRef} spaceAfter={1} style={style}>
        <Flex gap={1}>
          {!isSortingDisabled && (
            <SortHandleButton
              isDragging={active?.id === task.id}
              type="button"
              {...attributes}
              {...listeners}
            >
              <SortHandleIcon />
            </SortHandleButton>
          )}
          <SortContentHolder>
            <TaskListItemDisplay
              assignedUser={task.assignedUser}
              completedDateTime={task.completedDateTime?.toISOString()}
              deadlineDate={task.deadlineDate?.toISOString()}
              isDisabled={isDisabled}
              isReadOnlyAllowCheckbox={!isReadOnly}
              isReadOnlyClickable={true}
              name={task.name}
              onCheckboxChange={(value) => onTaskCheckboxChange?.(task, taskList, value)}
              onDelete={!isReadOnly ? () => setShowDeleteConfirm(true) : undefined}
              onTitleClick={() => onTaskClick?.(task)}
            />
          </SortContentHolder>
        </Flex>
      </Box>
      <ConfirmDialog
        cancelTitle="Nee"
        confirmAppearance="danger"
        confirmTitle="Ja, verwijder"
        isShown={showDeleteConfirm}
        title="Verwijderen"
        zIndex={9999999} /* Quick fix until we completely switched to Titan */
        onCancel={() => setShowDeleteConfirm(false)}
        onConfirm={() => {
          onTaskDelete?.(task);
          setShowDeleteConfirm(false);
        }}
      >
        Weet u zeker dat deze taak wilt verwijderen?
      </ConfirmDialog>
    </>
  );
};

export const convertTaskListToTaskListViewItem = (taskList: TaskList): TaskListViewItem => {
  return {
    id: taskList.id,
    name: taskList.name,
    tasks: taskList.tasks.filter((item) => !item.completedDateTime),
    position: taskList.position,
    completedTasks: taskList.tasks.filter((item) => !!item.completedDateTime),
    taskList,
  };
};

export default TaskListDnd;
