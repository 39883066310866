/* eslint-disable import/no-duplicates */
import {
  addYears,
  endOfYear,
  format,
  formatDistance,
  formatRelative,
  parseISO,
  parseJSON,
} from 'date-fns';
import localeNL from 'date-fns/locale/nl';

type DateValueType = string | Date;

// i18n locale wrapper.
// this function comes from JES workflow and that one has lingui for translations.
// this object is basic wrapper to get correct locale for Addify
const i18n = {
  locale: 'nl-nl',
};

export function dateParse(dateValue: DateValueType): Date {
  if (dateValue instanceof Date) {
    return dateValue;
  }
  if (dateValue.length === 10) {
    return parseISO(dateValue);
  }
  return parseJSON(dateValue);
}

export function dateFormat(dateValue: DateValueType, dateFormat: string): string {
  return format(dateParse(dateValue), dateFormat, {
    locale: getDateLocale(i18n.locale),
  });
}

export function dateFormatRelative(dateValue: DateValueType): string {
  return formatRelative(dateParse(dateValue), new Date(), {
    locale: getDateLocale(i18n.locale),
  });
}

export function dateFormatDistanceFromNow(dateValue: DateValueType): string {
  return dateFormatDistance(dateValue, new Date());
}

export function dateFormatDistance(dateValue: DateValueType, dateBase: DateValueType): string {
  return formatDistance(dateParse(dateValue), dateParse(dateBase), {
    addSuffix: true,
    locale: getDateLocale(i18n.locale),
  });
}

// this function comes from JES workflow and that one has lingui for translations.
// this object is basic wrapper to get correct locale for Addify.
// for now we return dutch and ignore the _locale parameter
export function getDateLocale(_locale: string): Locale {
  return localeNL;
}

// 5 years should be enough for datepicker for tasks.
export function getDateMaxForTasks(): Date {
  return endOfYear(addYears(new Date(), 5));
}
