import { isUserLinkedToDossier } from 'containers/dossiers/src/helpers/dossierHelper';

export const mentionRegExp = /@(?<id>[^\s]+)<(?<name>.+?)>/i;
export const mentionRegExpGlobal = /@(?<id>[^\s]+)<(?<name>.+?)>/gi;

/**
 * Check if a mention id is linked to a dossier
 *
 * @param {array} dossierUsers
 * @param {object} mentionItem
 * @returns {boolean}
 */
export const isMentionUserLinkedToDossier = (dossierUsers, mentionItem) => {
  return isUserLinkedToDossier(dossierUsers, mentionItem.id);
};

/**
 * Remove mention tag from specific user
 *
 * @param {string} content
 * @param {string} userId
 * @returns {string}
 */
export const removeMentionFromContent = (content, userId) => {
  let newContent = content;
  const matchResults = [...newContent.matchAll(mentionRegExpGlobal)];

  matchResults.forEach((matchResult) => {
    const tagText = matchResult[0]; // full match
    const matchUserId = matchResult.groups.id;
    const mentionName = matchResult.groups.name;

    if (matchUserId === userId) {
      newContent = newContent.replace(tagText, mentionName);
    }
  });

  return newContent;
};

/**
 * Search user by id
 *
 * @param userSuggestions
 * @param userId
 * @returns {undefined|*}
 */
export const searchUserSuggestionById = (userSuggestions, userId) => {
  const filtered = userSuggestions.filter((user) => user.id === userId);
  if (filtered.length === 1) {
    return filtered[0];
  }
  return undefined;
};
