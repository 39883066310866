import type { AttachmentFolderTableItem } from 'components/attachmentfolder/AttachmentFolderTable';
import dayjs from 'dayjs';
import type { AllAttachment, Dossier } from 'entity/dossiers/types';
import type { UserWithRole } from 'entity/system/types';
import LocalStorageService from 'services/LocalStorageService';

import { addTimeToDate, createDateSort } from './generalHelper';

const FORMAT_DATE = 'YYYY-MM-DDTHH:mm:ssZ';

/**
 * Map allattachments to tables items
 *
 * @param {Attachment[]} attachments
 * @return {AttachmentFolderTableItem[]}
 */
export function convertAttachmentToTableItems(
  attachments: AllAttachment[],
): AttachmentFolderTableItem[] {
  return attachments.map((fileItem: AllAttachment) => {
    const updatedAt = dayjs(fileItem.updatedDateTime).format(FORMAT_DATE);
    const createdAt = fileItem.displayDate
      ? addTimeToDate(fileItem.displayDate, dayjs(fileItem.createdDateTime).format(FORMAT_DATE))
      : dayjs(fileItem.updatedDateTime).format(FORMAT_DATE);

    return {
      id: fileItem.id || '<NULL>',
      name: fileItem.name,
      cardId: fileItem.cardId,
      folderId: fileItem.attachmentFolder?.id || '<NULL>',
      folderName: fileItem.attachmentFolder?.name || 'Niet gekoppeld',
      phase: fileItem.phase,
      createdAt,
      createdAtSort: createDateSort(createdAt),
      updatedAt,
      updatedAtSort: createDateSort(updatedAt),
      attachmentType: fileItem.attachmentType,
      canDelete: true,
      isFolder: false,
      url: fileItem.url,
    };
  });
}

export function getUsersWithRoles(dossier: Dossier, roles: string[] = []): UserWithRole[] {
  return (dossier.users ?? []).filter((user: UserWithRole) => roles.includes(user.role ?? ''));
}

/**
 * Get the default color used for the given section, defaults to yellow (#fff199)
 * Results: "#cfe4a8"
 * Facts:   "#99d5f1"
 * Default  "#fff199"
 *
 * @param {string} section
 * @return {string}
 */
export function getColorBySectionName(section: string): string {
  if (section === 'results') {
    return '#cfe4a8';
  } else if (section === 'facts') {
    return '#99d5f1';
  }

  return '#fff199';
}

/**
 * Get the subtitle used for the given section, defaults to "Open overweging".
 * Results: "Eigen beslissing"
 * Facts: "Eigen feit"
 * Default "Open overweging"
 */
export function getSubtitleBySectionName(section: string): string {
  if (section === 'results') {
    return 'Open beslissing';
  } else if (section === 'facts') {
    return 'Open feit';
  }

  return 'Open overweging';
}

/**
 * Get the last phase that the user visit in a dossier.
 */
const getLastVisitPhase = (dossierId: string) => {
  return LocalStorageService.getItem(createVisitPhaseStorageKey(dossierId), 'inventory');
};

/**
 * Create storage key for storing and retrieving last phase of a dossier
 */
export const createVisitPhaseStorageKey = (dossierId: string) => {
  return `dossier_${dossierId}_phase`;
};

/**
 * Create url for a specific dossier
 */
export const createLastVisitPhaseUrl = (dossierId: string) => {
  const phase = getLastVisitPhase(dossierId);
  return `/dossiers/${phase}/${dossierId}`;
};

/**
 * Sets the last phase that the user visit in a dossier.
 */
export const setLastVisitPhase = (dossierId: string, phase: string) => {
  const lastVisitPhaseKey = createVisitPhaseStorageKey(dossierId);
  LocalStorageService.setItem(lastVisitPhaseKey, phase);

  // purge the localstorage to only store the last maxItems dossier history items.
  // @see https://trello.com/c/b8DM0fzD/469-bug-wit-scherm-inventarisatie-fase
  let itemCount = 0;
  const maxItems = 20;
  Object.keys(LocalStorageService.getItems()).forEach((key) => {
    // only purge dossier_ items.
    if (key.startsWith('dossier_')) {
      itemCount++;
      // make sure we don't purge the last item, and only purge if we have reached the
      // maxItems count.
      if (key !== lastVisitPhaseKey && itemCount >= maxItems) {
        LocalStorageService.removeItem(key);
      }
    }
  });
};
