import './assets/styles/AuditLogWithPaging.scss';

import AuditLog from 'components/auditlog/AuditLog';
import LoadMore from 'components/loadmore/LoadMore';
import type { Paging } from 'entity/base/types';
import React from 'react';

type Props = {
  items?: any[];
  paging?: Paging;
  isLoading?: boolean;
  loadingTitle?: string;
  emptyText?: string;
  noMoreResults?: string;
  onNextPage: () => void;
};

/**
 * AuditLogWithPaging
 *
 * @param {array} items
 * @param {function} onNextPage
 * @param {object} paging
 * @param {boolean} isLoading
 * @param {string} loadingTitle
 * @param {string} showMoreTitle
 * @param {string} emptyText
 * @param {string} noMoreResults
 * @return {*}
 * @constructor
 */
const AuditLogWithPaging: React.FC<Props> = ({
  items,
  onNextPage = () => null,
  paging,
  isLoading = false,
  loadingTitle = 'Bezig met ophalen...',
  emptyText = 'Er zijn nog geen activiteiten vastgelegd.',
  noMoreResults = 'Dit is het einde van de lijst.',
}) => (
  <div className="audit-log-with-paging">
    {/* region items */}
    {items && <AuditLog items={items} />}
    {/* endregion */}

    {/* region loading */}
    {isLoading && <div className="audit-log-with-paging__list__loading">{loadingTitle}</div>}
    {/* endregion */}

    {!isLoading && items?.length === 0 && (
      <div className="audit-log-with-paging__list__empty">{emptyText}</div>
    )}

    {/* region more button */}
    {!isLoading &&
      paging &&
      items &&
      paging.nextPage !== paging.page &&
      paging.numberOfResults > items.length && (
        <div className="audit-log-with-paging__list__paging">
          <LoadMore hasMoreContent={true} onLoadMore={onNextPage} />
        </div>
      )}
    {/* endregion */}

    {/* region end of the list */}
    {!isLoading &&
      paging &&
      items &&
      paging.numberOfResults > 0 &&
      paging.numberOfResults === items.length && (
        <div className="audit-log-with-paging__list__no-more-results">{noMoreResults}</div>
      )}
    {/* endregion */}
  </div>
);

export default AuditLogWithPaging;
