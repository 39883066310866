import Actions from 'actions/Actions';
import ContextGroup from 'models/dossier/ContextGroup';
import { createHook, createStore } from 'react-sweet-state';
import sweetFetchState from 'stores/utils/SweetFetchState';

// #region state keys
export const STATE_CONTEXT_OF_CARD = 'contextOfCard';
export const STATE_CONTEXT_CREATE = 'contextCreate';
export const STATE_CONTEXT_DELETE = 'contextDelete';
// #endregion

const initialState = {
  [STATE_CONTEXT_OF_CARD]: sweetFetchState.init(),
  [STATE_CONTEXT_CREATE]: sweetFetchState.init(),
  [STATE_CONTEXT_DELETE]: sweetFetchState.init(),
};

const actions = {
  resetFetchState: sweetFetchState.reset,

  /**
   * Get context group of card
   *
   * @param {string} dossierId
   * @param {string} cardId
   */
  contextOfCardInDossier:
    (dossierId, cardId) =>
    ({ getState, dispatch }) => {
      const { doRequest, isBusy, setContext } = sweetFetchState.withContextId(
        STATE_CONTEXT_OF_CARD,
        cardId,
        dispatch,
        getState,
      );

      if (isBusy()) {
        return;
      }

      doRequest(Actions.getAPIService().contextOverview(dossierId, cardId)).then(
        (response) => {
          if (response.isSuccess()) {
            const contextGroups = ContextGroup.createInstancesByArray(
              response.getData().contextGroups,
            );
            setContext(contextGroups);
          }
        },
        () => null,
      );
    },

  /**
   * Add context to a card.
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} contextId
   * @param {string} contextType
   * @param {string} comment
   */
  addContext:
    (dossierId, cardId, contextId, contextType, comment) =>
    ({ getState, dispatch }) => {
      const { doRequest, isBusy } = sweetFetchState.withContextId(
        STATE_CONTEXT_CREATE,
        contextId,
        dispatch,
        getState,
      );

      if (isBusy()) {
        return;
      }

      doRequest(
        Actions.getAPIService().addContext(
          dossierId,
          cardId,
          contextId,
          contextType,
          comment.length > 0 ? comment : null,
        ),
      );
    },

  /**
   * Delete context to a card.
   *
   * @param {string} dossierId
   * @param {string} cardId
   * @param {string} contextId
   */
  deleteContext:
    (dossierId, cardId, contextId) =>
    ({ getState, dispatch }) => {
      const { doRequest, isBusy } = sweetFetchState.withContextId(
        STATE_CONTEXT_DELETE,
        contextId,
        dispatch,
        getState,
      );

      if (isBusy()) {
        return;
      }

      doRequest(Actions.getAPIService().deleteContext(dossierId, cardId, contextId));
    },
};

const Store = createStore({
  name: 'ContextState',
  initialState,
  actions,
});

export const useContextState = createHook(Store);
