import { useMutation } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import type { ContactNotePayload } from 'services/api/ContactNoteApi';

export const useCreateContactNote = () => {
  return useMutation({
    mutationFn: ({ contactNote }: { contactNote: ContactNotePayload }) =>
      Actions.getAPIService().contactNotes().createContactNote(contactNote),
  });
};
