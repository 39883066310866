import './assets/styles/FormField.scss';

import { Label, Text } from '@elseu/sdu-titan';
import classNames from 'classnames';
import React from 'react';

export enum FormFieldAppearance {
  DEFAULT = 'default',
  INLINE = 'inline',
}

type Props = React.PropsWithChildren<{
  id?: string;
  className?: string;
  label?: string | React.ReactElement;
  error?: string;
  isRequired?: boolean;
  isOptional?: boolean;
  optionalText?: string;
  footer?: string | React.ReactElement;
  appearance?: FormFieldAppearance;
  hasBottomSpace?: boolean;
}>;

/**
 * FormField
 *
 * @param {string} id
 * @param {string} className
 * @param {string|node} label
 * @param {boolean} isRequired
 * @param {boolean} optional
 * @param {string} optionalText
 * @param {string} footer
 * @param {string} error
 * @param {string} appearance
 * @param {boolean} hasBottomSpace
 * @param {node} children
 * @return {*}
 * @constructor
 */
const FormField: React.FC<Props> = ({
  id,
  className,
  label,
  isRequired,
  error,
  isOptional,
  optionalText,
  footer,
  appearance = FormFieldAppearance.DEFAULT,
  hasBottomSpace = false,
  children,
}) => (
  <div
    className={classNames('c-form-field', `c-form-field__${appearance}`, className, {
      'c-form-field__error': error,
      'c-form-field--add-bottom-margin': hasBottomSpace,
    })}
  >
    {(label || isOptional) && (
      <label className="c-form-field__label" htmlFor={id}>
        <Label required={isRequired}>{label}</Label>
        {isOptional && (
          <span className="c-form-field__label__optional">
            <Text color="grey40" type="label">
              {optionalText}
            </Text>
          </span>
        )}
      </label>
    )}
    <div className="c-form-field__component">{children}</div>
    {error && (
      <div className="c-form-field__error-reason">
        <Text color="secondary40" type="labelTiny">
          {error}
        </Text>
      </div>
    )}
    {footer && <div className="c-form-field__footer">{footer}</div>}
  </div>
);

export default FormField;
