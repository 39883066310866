import { Divider } from '@elseu/sdu-titan';
import type { AsyncSelectOptionProps } from 'components/formfields/AsyncSelectField';
import { NotesField } from 'components/formfields/NotesField';
import { SubmitOnChangeForm } from 'components/formfields/SubmitOnChangeForm';
import { TicketAssignedUserFormField } from 'components/ticket/forms/formfields/TicketAssignedUserFormField';
import { TicketClientSelectField } from 'components/ticket/forms/formfields/TicketClientSelectField';
import { TicketCreatedByFormField } from 'components/ticket/forms/formfields/TicketCreatedByFormField';
import { TicketDeadlineFormField } from 'components/ticket/forms/formfields/TicketDeadlineFormField';
import { TicketNameFormField } from 'components/ticket/forms/formfields/TicketNameFormField';
import { TicketStatusFormField } from 'components/ticket/forms/formfields/TicketStatusFormField';
import React, { useCallback } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useFormContext } from 'react-hook-form';
import type { Nullable } from 'types/types';

import { TicketSubmitButton } from '../../ticket/forms/formfields/TicketSubmitButton';
import type { TicketFormProps } from '../../ticket/forms/TicketFormProps';

export type TaskFormValues = Nullable<{
  id?: string;
  name?: string;
  assignedUser?: string;
  createdBy?: string;
  deadlineDate?: Date;
  client?: AsyncSelectOptionProps;
  dossier?: AsyncSelectOptionProps;
  status?: string;
  notes?: string;
}>;

type TaskFormProps = TicketFormProps<TaskFormValues>;

export const TaskForm = ({ onSubmit, mode }: TaskFormProps) => {
  const form = useFormContext<TaskFormValues>();

  const onFormSubmit: SubmitHandler<TaskFormValues> = useCallback(
    (formValues) => {
      if (!form.formState.isValid) {
        return;
      }

      onSubmit(formValues, form);
    },
    [onSubmit, form],
  );

  const FormComponent = mode === 'edit' ? SubmitOnChangeForm : 'form';

  return (
    <FormProvider {...form}>
      <FormComponent onSubmit={form.handleSubmit(onFormSubmit)}>
        <TicketNameFormField />

        <TicketStatusFormField />

        <TicketCreatedByFormField />

        <TicketAssignedUserFormField />

        <TicketDeadlineFormField />

        <TicketClientSelectField isMandatory isDisabled={mode === 'edit'} />

        <Divider />

        <NotesField name="notes" />

        {mode === 'create' && (
          <TicketSubmitButton onClick={() => onSubmit(form.getValues(), form)}>
            Taak aanmaken
          </TicketSubmitButton>
        )}
      </FormComponent>
    </FormProvider>
  );
};
