import { EmptyState, Flex, Loader } from '@elseu/sdu-titan';
import { CardSidebar, CardSidebarContainer } from 'components/card/CardSidebar';
import { useTaskDetails } from 'hooks/queries/useTaskDetails';
import React from 'react';

import { CardSidebarToolbar } from '../card/CardSidebarToolbar';

interface TaskSidebarProps {
  taskId?: string;
  onClose?: () => void;
}
export const TaskSidebar: React.FC<TaskSidebarProps> = ({ taskId, onClose }) => {
  const { task, query } = useTaskDetails(taskId);

  const isLoading = query.isLoading && !task;

  const content = (
    <>
      <CardSidebar
        card={task}
        dossierId={task?.dossier?.id || ''}
        emptyContent={
          <Flex justifyContent="center" px={4} py={20}>
            <EmptyState
              isCentered
              description="Koppel eerst aan een klant en ga daarna verder!"
              illustration="looking-glass"
              title="Je kunt bijna aan de slag"
            />
          </Flex>
        }
        onClose={onClose}
        onUpdate={() => query.refetch()}
      />
    </>
  );

  return (
    <CardSidebarContainer>
      <CardSidebarToolbar card={task} onClose={onClose} />
      {isLoading ? <Loader height={48} variant="spinner" /> : content}
    </CardSidebarContainer>
  );
};
