import { get } from 'lodash';
import React from 'react';
import type { FieldErrors, FieldValues } from 'react-hook-form';

export const formFieldErrorProps = <Errors extends FieldErrors<FieldValues>, Name extends string>(
  errors: Errors,
  name: Name | Name[],
  hide = false,
) => {
  if (hide)
    return {
      hasError: false,
    };
  const error = !Array.isArray(name)
    ? get(errors, name)
    : name.map((n) => get(errors, n)).find(Boolean);
  const message = error?.message;
  const isValidMessage = typeof message === 'string' || React.isValidElement(message);
  const errorText = isValidMessage ? message : undefined;
  return { hasError: !!error, errorText };
};
