import DataModel, { PropTypes } from 'models/base/DataModel';
import User from 'models/me/User';

/**
 * Task model
 */
export default class Task extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    name: PropTypes.string,
    position: PropTypes.number,
    createdDateTime: PropTypes.string,
    assignedUser: PropTypes.shape(User.propTypes),
    deadlineDate: PropTypes.string,
    completedDateTime: PropTypes.string,
    url: PropTypes.string,
    taskListId: PropTypes.string,
  };

  /**
   * Name of Task list
   * @type {string}
   */
  name;

  /**
   * Position of Task List
   * @type {string}
   */
  position;

  /**
   * Creation data of task list
   * @type {string}
   */
  createdDateTime;

  /**
   * Assigned user
   * @type {User}
   */
  assignedUser;

  /**
   * Deadline date
   * @type {string}
   */
  deadlineDate;

  /**
   * Completed date
   * @type {string}
   */
  completedDateTime;

  /**
   * Url
   * @type {string}
   */
  url;

  /**
   * taskListId
   *
   * @type {string}
   */
  taskListId;

  /**
   * @inheritDoc
   */
  set(fieldName, value) {
    if (fieldName === 'assignedUser') {
      value = User.createInstance(value);
    }

    return super.set(fieldName, value);
  }

  /**
   * @return {User}
   */
  getAssignedUser() {
    return this.assignedUser;
  }
}
