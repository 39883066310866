import Actions from 'actions/Actions';
import ContactNote from 'models/crm/ContactNote';
import { createHook, createStore } from 'react-sweet-state';
import sweetFetchState from 'stores/utils/SweetFetchState';
import sweetPaging from 'stores/utils/SweetPagingState';

// region all states that hold data
export const STATE_CONTACT_NOTES = 'contactNotesClient';
export const STATE_CONTACT_NOTES_CLIENT = 'contactNotesClient';
export const STATE_CONTACT_NOTES_SELECT = 'contactNotesSelect';
export const STATE_CONTACT_NOTES_CREATE = 'contactNotesSelect';
export const STATE_CONTACT_NOTES_UPDATE = 'contactNotesUpdate';
export const STATE_CONTACT_NOTES_DELETE = 'contactNotesDelete';
export const STATE_CONTACT_NOTES_ARCHIVE = 'contactNotesArchive';
// endregion

/**
 * Communication store
 */
const Store = createStore({
  name: 'ContactNoteState',
  initialState: {
    [STATE_CONTACT_NOTES]: sweetPaging.init(),
    [STATE_CONTACT_NOTES_CLIENT]: sweetPaging.init(),
    [STATE_CONTACT_NOTES_SELECT]: sweetFetchState.init(),
    [STATE_CONTACT_NOTES_CREATE]: sweetFetchState.init(),
    [STATE_CONTACT_NOTES_UPDATE]: sweetFetchState.init(),
    [STATE_CONTACT_NOTES_DELETE]: sweetFetchState.init(),
    [STATE_CONTACT_NOTES_ARCHIVE]: sweetFetchState.init(),

    contactNotesLastSort: undefined,
    contactNotesLastFilter: undefined,
    contactNotesLastPage: undefined,
    contactNotesLastPageSize: undefined,
  },
  actions: {
    /**
     * Reset a fetch state
     */
    resetFetchState: sweetFetchState.reset,

    /**
     * Get contact notes
     *
     * @param filter
     * @param sort
     * @param page
       @param pageSize
     * @param reset
     * @returns {(function({getState: *, dispatch: *}): void)|*}
     */
    getContactNotes:
      (filter, sort, page, pageSize) =>
      ({ getState, setState, dispatch }) => {
        const { doRequest, isBusy } = sweetPaging.state(
          STATE_CONTACT_NOTES,
          (response) => ContactNote.createInstancesByArray(response.data.contactNotes),
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        // set last sort and filter
        setState({
          contactNotesLastSort: sort,
          contactNotesLastFilter: filter,
          contactNotesLastPage: page,
          contactNotesLastPageSize: pageSize,
        });

        doRequest(
          Actions.getAPIService().contactNotes().getContactNotes(filter, sort, page, pageSize),
          true,
        );
      },

    /**
     * Refreshes the current contact notes state.
     *
     * @returns
     */
    refreshCurrentContactNotes:
      () =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy } = sweetPaging.state(
          STATE_CONTACT_NOTES,
          (response) => ContactNote.createInstancesByArray(response.data.contactNotes),
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        const {
          contactNotesLastSort,
          contactNotesLastFilter,
          contactNotesLastPage,
          contactNotesLastPageSize,
        } = getState();

        doRequest(
          Actions.getAPIService()
            .contactNotes()
            .getContactNotes(
              contactNotesLastFilter,
              contactNotesLastSort,
              contactNotesLastPage,
              contactNotesLastPageSize,
            ),
          true,
        );
      },

    /**
     * Get contact notes of client
     *
     * @param clientId
     * @param filter
     * @param sort
     * @param reset
     * @returns {(function({getState: *, dispatch: *}): void)|*}
     */
    getContactNotesFromClient:
      (clientId, filter, sort, reset = true) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy, getPaging } = sweetPaging.state(
          STATE_CONTACT_NOTES_CLIENT,
          (response) => ContactNote.createInstancesByArray(response.data.contactNotes),
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        const nextPage = reset ? 1 : getPaging().getNextPage();
        doRequest(
          Actions.getAPIService()
            .contactNotes()
            .getContactNotesFromClient(clientId, filter, sort, nextPage),
          reset,
        );
      },

    /**
     * Get a contact note
     *
     * @param contactNoteId
     * @returns {(function({getState: *, dispatch: *}): void)|*}
     */
    getContactNote:
      (contactNoteId) =>
      ({ getState, dispatch }) => {
        return new Promise((resolve, reject) => {
          const { setContext, doRequest, isBusy } = sweetFetchState.withContextId(
            STATE_CONTACT_NOTES_SELECT,
            contactNoteId,
            dispatch,
            getState,
          );

          if (isBusy()) {
            return;
          }

          doRequest(Actions.getAPIService().contactNotes().getContactNote(contactNoteId)).then(
            (response) => {
              if (response.isSuccess()) {
                const nextContactNote = ContactNote.createInstance(response.data);
                setContext(nextContactNote);
                resolve(nextContactNote);
              }
            },
            reject,
          );
        });
      },

    /**
     * Create a contact note
     *
     * @param {object} values
     * @returns {(function({getState: *, dispatch: *}))|*}
     */
    createContactNote:
      (values) =>
      ({ getState, dispatch }) => {
        return new Promise((resolve, reject) => {
          const { setContext, doRequest, isBusy } = sweetFetchState.state(
            STATE_CONTACT_NOTES_CREATE,
            dispatch,
            getState,
          );

          if (isBusy()) {
            return;
          }

          doRequest(Actions.getAPIService().contactNotes().createContactNote(values)).then(
            (response) => {
              if (response.isSuccess()) {
                const nextContactNote = ContactNote.createInstance(response.data);
                setContext(nextContactNote);
                dispatch(sweetPaging.addItem(STATE_CONTACT_NOTES_CLIENT, nextContactNote));
                dispatch(sweetPaging.addItem(STATE_CONTACT_NOTES, nextContactNote));
                resolve(nextContactNote);
              }
            },
            reject,
          );
        });
      },

    /**
     * Update contact note
     *
     * @param {string} contactNoteId
     * @param {object} values
     * @returns {(function({getState?: *, dispatch?: *}): void)|*}
     */
    updateContactNote:
      (contactNoteId, values) =>
      ({ getState, dispatch }) => {
        return new Promise((resolve, reject) => {
          const { setContext, doRequest, isBusy } = sweetFetchState.withContextId(
            STATE_CONTACT_NOTES_UPDATE,
            contactNoteId,
            dispatch,
            getState,
          );

          if (isBusy()) {
            return;
          }

          doRequest(
            Actions.getAPIService().contactNotes().updateContactNote(contactNoteId, values),
          ).then((response) => {
            if (response.isSuccess()) {
              const nextContactNote = ContactNote.createInstance(response.data);
              setContext(nextContactNote);
              dispatch(sweetPaging.addItem(STATE_CONTACT_NOTES_CLIENT, nextContactNote));
              dispatch(sweetPaging.addItem(STATE_CONTACT_NOTES, nextContactNote));
              resolve(nextContactNote);
            }
          }, reject);
        });
      },

    /**
     * Archive contact note
     *
     * @param {string} contactNoteId
     * @returns {(function({getState?: *, dispatch?: *}): void)|*}
     */
    archiveContactNote:
      (contactNoteId) =>
      ({ getState, dispatch }) => {
        return new Promise((resolve, reject) => {
          const { setContext, doRequest, isBusy } = sweetFetchState.withContextId(
            STATE_CONTACT_NOTES_ARCHIVE,
            contactNoteId,
            dispatch,
            getState,
          );
          const { setContext: setSelectContext } = sweetFetchState.withContextId(
            STATE_CONTACT_NOTES_SELECT,
            contactNoteId,
            dispatch,
            getState,
          );

          if (isBusy()) {
            return;
          }

          return doRequest(
            Actions.getAPIService().contactNotes().archiveContactNote(contactNoteId),
          ).then((response) => {
            if (response.isSuccess()) {
              const nextContactNote = ContactNote.createInstance(response.data);
              setSelectContext(nextContactNote);
              setContext(nextContactNote);
              resolve(nextContactNote);

              dispatch(sweetPaging.addItem(STATE_CONTACT_NOTES_CLIENT, nextContactNote));
              dispatch(sweetPaging.addItem(STATE_CONTACT_NOTES, nextContactNote));
            }
          }, reject);
        });
      },

    /**
     * Unarchive contact note
     *
     * @param {string} contactNoteId
     * @returns {(function({getState?: *, dispatch?: *}): void)|*}
     */
    unarchiveContactNote:
      (contactNoteId) =>
      ({ getState, dispatch }) => {
        return new Promise((resolve, reject) => {
          const { setContext, doRequest, isBusy } = sweetFetchState.withContextId(
            STATE_CONTACT_NOTES_ARCHIVE,
            contactNoteId,
            dispatch,
            getState,
          );
          const { setContext: setSelectContext } = sweetFetchState.withContextId(
            STATE_CONTACT_NOTES_SELECT,
            contactNoteId,
            dispatch,
            getState,
          );

          if (isBusy()) {
            return;
          }

          doRequest(
            Actions.getAPIService().contactNotes().unarchiveContactNote(contactNoteId),
          ).then((response) => {
            if (response.isSuccess()) {
              const nextContactNote = ContactNote.createInstance(response.data);
              setSelectContext(nextContactNote);
              setContext(nextContactNote);
              resolve(nextContactNote);

              dispatch(sweetPaging.addItem(STATE_CONTACT_NOTES_CLIENT, nextContactNote));
              dispatch(sweetPaging.addItem(STATE_CONTACT_NOTES, nextContactNote));
            }
          }, reject);
        });
      },

    /**
     * Delete contact note
     *
     * @param {string} contactNoteId
     * @returns {(function({getState: *, dispatch: *}): void)|*}
     */
    deleteContactNote:
      (contactNoteId) =>
      ({ getState, dispatch }) => {
        return new Promise((resolve, reject) => {
          const { doRequest, isBusy } = sweetFetchState.withContextId(
            STATE_CONTACT_NOTES_DELETE,
            contactNoteId,
            dispatch,
            getState,
          );

          if (isBusy()) {
            isBusy();
          }

          doRequest(Actions.getAPIService().contactNotes().deleteContactNote(contactNoteId)).then(
            (response) => {
              dispatch(sweetPaging.removeItem(STATE_CONTACT_NOTES_CLIENT, contactNoteId));
              dispatch(sweetPaging.removeItem(STATE_CONTACT_NOTES, contactNoteId));
              resolve();
            },
            reject,
          );
        });
      },
  },
});

export const useContactNoteState = createHook(Store);
