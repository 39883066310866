import DataModel, { PropTypes } from 'models/base/DataModel';

/**
 * Own tool model
 */
export default class OwnTool extends DataModel {
  static propTypes = {
    ...DataModel.propTypes,
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
    fileName: PropTypes.string,
    stage: PropTypes.string,
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    consideration: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
    createdDateTime: PropTypes.string,
    createdBy: PropTypes.string,
    hasAttachment: PropTypes.bool,
  };

  /**
   * @type {string}
   */
  name;

  /**
   * @type {string}
   */
  description;

  /**
   * @type {string}
   */
  type;

  /**
   * @type {string}
   */
  url;

  /**
   * @type {string}
   */
  fileName;

  /**
   * @type {string}
   */
  stage;

  /**
   * @type {array}
   */
  topics;

  /**
   * @type {object}
   */
  consideration;

  /**
   * @type {string}
   */
  createdDateTime;

  /**
   * @var {string}
   */
  createdBy;

  /**
   * @var {boolean}
   */
  hasAttachment;

  /**
   * @returns {string}
   */
  getName() {
    return this.name;
  }

  /**
   * @returns {string}
   */
  getDescription() {
    return this.description;
  }

  /**
   * @returns {string}
   */
  getType() {
    return this.type;
  }

  /**
   * @returns {string}
   */
  getUrl() {
    return this.url;
  }

  /**
   * @returns {string}
   */
  getStage() {
    return this.stage;
  }

  /**
   * @returns {Array}
   */
  getTopics() {
    return this.topics;
  }

  /**
   * @returns {Object}
   */
  getConsideration() {
    return this.consideration;
  }

  /**
   * @returns {string}
   */
  getCreatedDateTime() {
    return this.createdDateTime;
  }

  /**
   * @returns {string}
   */
  getCreatedBy() {
    return this.createdBy;
  }

  /**
   * @return {boolean}
   */
  getHasAttachment() {
    return this.hasAttachment;
  }
}
