import type { ApiFilter } from 'types/types';

import type { Ticket } from '../../entity/dossiers/types';
import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';
import type { JSendResponse } from './types';

type GetTicketsResponse = JSendResponse<{
  tickets: Ticket[];
}>;

type GetTicketResponse = JSendResponse<Ticket>;
type UpdateTicketResponse = JSendResponse<Ticket>;
type DeleteTicketResponse = JSendResponse;

export type RepositionTicketPayload = {
  otherId: Ticket['id'];
  placement: 'before' | 'after';
};

export default class TicketApi extends APIEndpoint {
  /**
   * Returns a list of tickets.
   */
  getTickets(
    filters: ApiFilter[] = [],
    sort = '',
    page = 1,
    pageSize = 100,
  ): Promise<GetTicketsResponse> {
    const url = this.buildUrl('tickets', filters, sort, page, pageSize);

    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  /**
   * Returns a single ticket.
   */
  getTicketDetails(id: Ticket['id']): Promise<GetTicketResponse> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(`tickets/${id}`);
  }

  /**
   * Updates a single ticket.
   */
  updateTicket(id: Ticket['id'], ticket: Partial<Ticket>): Promise<UpdateTicketResponse> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `tickets/${id}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      ticket,
    );
  }

  repositionTicket(
    ticketId: Ticket['id'],
    { otherId, placement }: RepositionTicketPayload,
  ): Promise<UpdateTicketResponse> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `tickets/${ticketId}/reposition`,
      JSendRequest.REQUEST_METHOD_PATCH,
      {
        otherId,
        placement,
      },
    );
  }

  /**
   * Deletes a single ticket.
   */
  deleteTicket(id: Ticket['id']): Promise<DeleteTicketResponse> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `tickets/${id}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }
}
