import Actions from 'actions/Actions';
import DossierToolFrom from 'components/dossier/DossierToolForm';
import ConfirmModal from 'components/modal/ConfirmModal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const DossierToolFormModal = ({
  isDisabled,
  modelTypes,
  topics,
  stage,
  considerationId,
  considerationName,
  selectedTopics,
  isTopicsHidden,
  onSave,
  onSaved,
  onCancel,
}) => {
  const [attachment, setAttachment] = useState();
  const [values, setValues] = useState({});
  const [disabledState, setDisabledState] = useState(isDisabled);
  const [error, setError] = useState();
  useEffect(() => setDisabledState(isDisabled), [isDisabled]);

  const types =
    modelTypes?.map((modelType) => ({
      value: modelType.getIdentity(),
      label: modelType.getName(),
    })) || [];

  const selectedTopicsMapped =
    selectedTopics?.map((topic) => {
      return {
        value: topic.id,
        label: topic.name,
      };
    }) || [];

  // selected topics effect.
  const setSelectedTopicsEffect = () => {
    setValues({
      ...values,
      topics: selectedTopicsMapped,
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setSelectedTopicsEffect, [selectedTopics]);

  /**
   * Create new context
   *
   * @param {object} values
   * @param {File} attachment
   */
  const createNewContext = ({ name, type, topics, url }, attachment) => {
    setDisabledState(true);
    onSave('create-own-context:busy');
    const topicIds = topics?.map((topic) => topic.value) || [];

    Actions.getAPIService()
      .createOwnContext(name, stage, type?.value, topicIds, attachment, url)
      .then(
        () => {
          onSave('create-own-context:completed');
          onSaved();
        },
        (data) => {
          if (data.status === 'error') {
            setError(data.data);
            onSave('create-own-context:error');
          }
        },
      )
      .finally(() => setDisabledState(false));
  };

  const createNewContextForConsideration = ({ name, type, url }, attachment) => {
    Actions.getAPIService()
      .createOwnContextForConsideration(name, type?.value, considerationId, attachment, url)
      .then(
        () => {
          onSave('create-own-context:completed');
          onSaved();
        },
        (data) => {
          if (data.status === 'error') {
            setError(data.data);
            onSave('create-own-context:error');
          }
        },
      )
      .finally(() => setDisabledState(false));
  };

  const hasUrlValue = values.url !== undefined && values.url !== '';
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const hasAttachment = attachment !== undefined;

  return (
    <ConfirmModal
      shouldUsePortal
      confirmTitle="Voeg toe"
      isConfirmDisabled={!(hasUrlValue || hasAttachment)}
      isDisabled={isDisabled}
      title="Voeg eigen model of tool aan Addify toe"
      onCancel={() => onCancel()}
      onConfirm={() => {
        if (stage) {
          createNewContext(values, attachment?.file);
          return;
        }
        if (considerationId) {
          createNewContextForConsideration(values, attachment?.file);
          return;
        }
        throw new Error('No stage or considerationId set. Hmm...should not be possible.');
      }}
    >
      <DossierToolFrom
        attachment={attachment}
        error={error}
        footerText={
          isTopicsHidden && (
            <span>
              Dit model wordt voortaan standaard aangeboden binnen de overweging:{' '}
              <strong>
                <i>{considerationName}</i>
              </strong>
            </span>
          )
        }
        isDisabled={disabledState}
        isTopicsHidden={isTopicsHidden}
        name={values.name}
        selectedTopics={values.topics}
        topics={
          topics?.map((topic) => ({
            value: topic.getIdentity(),
            label: topic.getName(),
          })) || []
        }
        types={types}
        url={values.url}
        onChange={(name, value) => {
          if (name !== 'attachment') {
            setValues({
              ...values,
              [name]: value,
            });
          } else {
            setAttachment(value);
          }
        }}
      />
    </ConfirmModal>
  );
};

DossierToolFormModal.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  modelTypes: PropTypes.array,
  topics: PropTypes.array,
  stage: PropTypes.string,
  considerationId: PropTypes.string,
  considerationName: PropTypes.string,
  selectedTopics: PropTypes.array,
  isTopicsHidden: PropTypes.bool,
  onSave: PropTypes.func,
  onSaved: PropTypes.func,
  onCancel: PropTypes.func,
};

export default DossierToolFormModal;
