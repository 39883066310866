import './assets/styles/CardSectionLinkDossierCreateDossier.scss';

import Actions from 'actions/Actions';
import FormField from 'components/formfield/FormField';
import LabelSelect from 'components/labelselect/LabelSelect';
import ConfirmModal from 'components/modal/ConfirmModal';
import { convertLabelForPhaseSelect } from 'entity/dossiers/converter';
import type { Card, RelatedCard } from 'entity/dossiers/types';
import { useProfileSettings } from 'hooks/';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  isDisabled?: boolean;
  dossierId: string;
  card: Card;
  relatedCards: RelatedCard[];
  clients: string[];
  showLinkTypes: string[];
  onCancel: () => void;
  onBusy: (isBusy: boolean) => void;
  onCardNeedsUpdate: () => void;
};

/**
 * Link types that can be used for the dossier.
 */
const linkTypes = [
  {
    name: 'Adviessignaleringen',
    type: 'SIGNAL',
    cardType: 'signal',
    labelKey: 'labels',
    getTitle: (item: Card) => item.dossier?.name,
  },
  {
    name: 'Notities',
    type: 'CONTACT_NOTE',
    cardType: 'contact_note',
    labelKey: 'labels',
    getTitle: (item: Card) => item.name,
  },
];

const CardSectionLinkDossierCreateDossier: React.FC<Props> = ({
  isDisabled,
  dossierId,
  card,
  relatedCards,
  clients,
  showLinkTypes,
  onCancel,
  onBusy,
  onCardNeedsUpdate,
}) => {
  const navigate = useNavigate();
  const [labelValues, setLabelValues] = useState<Record<string, any>>([]);
  const [settings] = useProfileSettings();

  // possible types that is going to be displayed.
  const displayTypes = useMemo(
    () => [
      linkTypes.find((linkItem) => linkItem.cardType === card.type),
      ...linkTypes.filter((linkItem) => showLinkTypes.includes(linkItem.type)),
    ],
    [showLinkTypes, card.type],
  );

  // list of items to display. filter out types that is not linked to a card.
  const listItems = useMemo(() => {
    const allCards = [card, ...relatedCards];
    const result: any[] = [];
    displayTypes.forEach((linkType) => {
      const linkCards = allCards.filter((relatedCard) => relatedCard.type === linkType?.cardType);
      if (linkCards.length > 0) {
        result.push({
          ...linkType,
          cards: linkCards,
        });
      }
    });
    return result;
  }, [displayTypes, card, relatedCards]);

  /**
   * Set label value
   *
   * @param {card} item
   * @param {object} cardValue
   */
  const setLabelValue = (item: Card, cardValue: any) => {
    if (item.id) {
      setLabelValues({
        ...labelValues,
        [item.id]: cardValue,
      });
    }
  };

  /**
   * Get label value for a card from the state or the card itself.
   * @param {Card} item
   * @returns {*}
   */
  const getLabelValue = (item: Card) => {
    if (item.id && labelValues[item.id]) {
      return labelValues[item.id];
    }

    return item.label ? convertLabelForPhaseSelect(item.label) : undefined;
  };

  /**
   * Confirm save
   */
  const confirmSave = () => {
    const allCards = [card, ...relatedCards];

    const labelsToChange: any[] = [];

    // determine which card labels are changes and create array that
    const labelValuesKeys = Object.keys(labelValues);
    labelValuesKeys.forEach((itemKey) => {
      const findCard = allCards.find((allCardItem) => allCardItem.id === itemKey);
      if (findCard) {
        // check if the label is the same id, if so then we will skip the change
        if (findCard.label && findCard.label.id === labelValues[itemKey].value) {
          return;
        }

        // label value is changed or empty but filled by the labelVales
        labelsToChange.push({
          cardId: findCard.id,
          dossierId: findCard.dossier?.id || dossierId,
          labelId: labelValues[itemKey].value,
        });
      }
    });

    // update card labels
    if (labelsToChange.length > 0) {
      onBusy(true);
      const result = labelsToChange.reduce((accumulatorPromise, changeItem) => {
        return accumulatorPromise.then(() => {
          return Actions.getAPIService()
            .updateCard(changeItem.cardId, {
              label: changeItem.labelId,
            })
            .catch(() => null);
        });
      }, Promise.resolve());

      result.then(() => {
        onBusy(false);
        onCardNeedsUpdate();
        gotoCreateDossier();
      });
    } else {
      // goto dossier
      gotoCreateDossier();
    }
  };

  /**
   * Goto create dossier endpoint
   */
  const gotoCreateDossier = () => {
    const clientId = clients.length > 0 ? `&client=${clients[0]}` : '';
    navigate(`/dossiers/new/?linkCard=${card.id}${clientId}`);
  };

  const getLabelOptions = useCallback(
    (labelKey: string) => {
      switch (labelKey) {
        case 'signalingLabels':
          return settings.getLabelsForPhaseSelect();
        case 'contactNotesLabels':
          return settings.getLabelsForPhaseSelect();
        default:
          return [];
      }
    },
    [settings],
  );

  return (
    <ConfirmModal
      shouldUsePortal
      className="c-card-section-link-dossier-create-dossier"
      confirmTitle="Opslaan en aanpassen"
      isDisabled={isDisabled}
      title="Nieuw dossier starten"
      onCancel={() => onCancel()}
      onConfirm={() => {
        confirmSave();
      }}
    >
      <p>
        U gaat een nieuw dossier aanmaken, wilt u de status van onderstaande koppeling(-en) updaten?
      </p>
      {listItems.map((listItem) => (
        <div
          key={`type-${listItem.type}`}
          className="c-card-section-link-dossier-create-dossier__section"
        >
          <div className="c-card-section-link-dossier-create-dossier__section__title">
            {listItem.name}
          </div>
          {listItem.cards.map((relatedCard: RelatedCard, index: number) => (
            <FormField
              // eslint-disable-next-line react/no-array-index-key
              key={`${listItem.type}-${index}`}
              className="c-card-section-link-dossier-create-dossier__section__item"
              label={listItem.getTitle(relatedCard)}
            >
              <LabelSelect
                className="c-card-section-link-dossier-create-dossier__label-select"
                isDisabled={isDisabled}
                options={getLabelOptions(listItem.labelKey)}
                value={getLabelValue(relatedCard)}
                onChange={(value) => setLabelValue(relatedCard, value)}
              />
            </FormField>
          ))}
        </div>
      ))}
    </ConfirmModal>
  );
};

export default CardSectionLinkDossierCreateDossier;
