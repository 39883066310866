import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import CardSectionCommentsView from 'components/card/CardSectionCommentsView';
import { normalizeComment } from 'entity/dossiers/normalizer';
import type { User } from 'entity/system/types';
import { normalizeOverviewResults } from 'helpers/normalizehelper';
import { useInvalidateDossierCache } from 'hooks/invalidate/useInvalidateDossierCache';
import Attachment from 'models/dossier/Attachment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useIntersection } from 'react-use';
import type JSendResponse from 'services/JSendResponse';

import FetchState from '../../services/FetchState';

type Props = {
  dossierId: string;
  cardId?: string;
  shouldRefreshComments?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming
  editorHideAttachments?: boolean;
  dossierTypeTitleText?: string;
  dossierTypeMessageText?: string;
  userSuggestions: any[];
  onBusy?: (isBusy: boolean) => void;
  onUpdate: (state: string) => void;
  onAttachmentAdded?: (attachment: Attachment) => void;
  profile: User;
  dossierUsers: string[];
  [key: string]: any;
};

const getComments = (cardId: string, page = 1) => {
  return Actions.getAPIService()
    .comments(cardId, page)
    .then((response: JSendResponse) =>
      normalizeOverviewResults(response.getData(), 'comments', normalizeComment),
    );
};

/**
 * Card section comments
 *
 * @constructor
 */
const CardSectionComments: React.FC<Props> = (props) => {
  const {
    dossierId,
    cardId,
    shouldRefreshComments,
    editorHideAttachments,
    dossierTypeTitleText,
    dossierTypeMessageText,
    profile,
    userSuggestions,
    dossierUsers,
    onBusy = () => null,
    onUpdate = () => null,
    onAttachmentAdded = () => null,
  } = props;
  const invalidateDossierCache = useInvalidateDossierCache();
  const [resetEditorState, setResetEditorState] = useState(false);
  const [errorsState, setErrorsState] = useState<Record<string, any>>({});
  const [dossierNeedsUpdate, setDossierNeedsUpdate] = useState(false);
  const intersectionRef = useRef<HTMLDivElement | null>(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  const commentsVisibleInViewport = useMemo(
    () => intersection?.isIntersecting || false,
    [intersection],
  );

  const { data, isFetching, isFetchingNextPage, fetchNextPage, refetch } = useInfiniteQuery({
    queryKey: ['card-comments', cardId],
    queryFn: ({ pageParam = 1 }) => getComments(cardId ?? '', pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage) =>
      lastPage.paging.totalPages !== lastPage.paging.page ? lastPage.paging.nextPage : undefined,
    staleTime: 60 * 1000, // 1 minute
    enabled: !!cardId && !(cardId === 'new') && commentsVisibleInViewport,
  });

  // #region mutations
  const addAttachmentMutation = useMutation({
    mutationFn: (attachment: File) =>
      Actions.getAPIService().createAttachment(dossierId, cardId ?? '', attachment),
  });
  const addCommentMutation = useMutation({
    mutationFn: (values: {
      content: string;
      parentComment?: Record<string, any>;
      context?: any;
      dossierNeedsUpdate: boolean;
    }) => {
      if (values.parentComment) {
        return Actions.getAPIService().createCommentReaction(
          dossierId,
          cardId ?? '',
          values.parentComment.id,
          values.content,
          values.context?.contextType,
          values.context?.contextId,
        );
      }
      return Actions.getAPIService().createComment(
        dossierId,
        cardId ?? '',
        values.content,
        values.context.contextType,
        values.context.contextId,
      );
    },
    onSuccess: (_data, variables) => {
      setResetEditorState(true);
      onUpdate('created');
      refetch();
      if (variables.dossierNeedsUpdate) {
        invalidateDossierCache(dossierId, { clearCards: false });
        setDossierNeedsUpdate(false);
      }
    },
  });

  const updateCommentMutation = useMutation({
    mutationFn: (values: {
      comment: Record<string, any>;
      content: string;
      context?: any;
      dossierNeedsUpdate: boolean;
    }) =>
      Actions.getAPIService().updateComment(
        dossierId,
        cardId ?? '',
        values.comment.id,
        values.content,
        values.context.contextType,
        values.context.contextId,
      ),
    onSuccess: (_data, variables) => {
      setResetEditorState(true);
      onUpdate('updated');
      refetch();
      if (variables.dossierNeedsUpdate) {
        invalidateDossierCache(dossierId, { clearCards: false });
        setDossierNeedsUpdate(false);
      }
    },
  });

  const deleteCommentMutation = useMutation({
    mutationFn: (commentId: string) =>
      Actions.getAPIService().deleteComment(dossierId, cardId ?? '', commentId),
    onSuccess: () => {
      onUpdate('deleted');
      refetch();
    },
  });

  // #endregion

  // refresh comments if
  useEffect(() => {
    if (shouldRefreshComments && dossierId && cardId && cardId !== 'new') {
      refetch();
    }
  }, [shouldRefreshComments, dossierId, cardId, refetch]);

  // busy state handler
  const isBusy =
    addCommentMutation.isPending ||
    updateCommentMutation.isPending ||
    deleteCommentMutation.isPending;
  useEffect(() => {
    onBusy(isBusy);
  }, [isBusy, onBusy]);

  // #region functions

  /**
   * Set error for comment
   *
   * @param {string} commentId
   * @param {string} errorMessage
   */
  const setErrorForComment = (commentId: string, errorMessage: string) => {
    setErrorsState((prevErrorState) => {
      return {
        ...prevErrorState,
        [commentId]: errorMessage,
      };
    });
  };

  /**
   * Clear an error for a comment
   *
   * @param {string} commentId
   */
  const clearErrorForComment = (commentId: string) => {
    setErrorsState((prevErrorState) => {
      const newErrorState = { ...prevErrorState };
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete newErrorState[commentId];

      return newErrorState;
    });
  };

  /**
   * Create or update a comment with possible context
   */
  const doNewOrEditComment = async (
    content: string,
    context: Record<string, any> | undefined,
    comment: Record<string, any> | undefined = undefined,
    parentComment: Record<string, any> | undefined = undefined,
  ) => {
    let contextId;
    let contextType;

    const commentId = comment?.id || `new-${parentComment?.id || ''}`;

    onBusy(true);
    setResetEditorState(false);
    clearErrorForComment(commentId);

    // #region upload attachment
    if (context) {
      contextId = context.id;
      contextType = context.contextType;

      if (contextId === undefined && contextType === 'attachment') {
        const attachment = context.file;
        try {
          const attachmentResult = await addAttachmentMutation.mutateAsync(attachment);
          if (attachmentResult.status === 'success') {
            // TODO: should use normalizer attachment when cards are converted.
            // @ts-ignore
            onAttachmentAdded(Attachment.createInstance(attachmentResult?.data));
            contextId = attachmentResult.data.id;
          }
        } catch (err: any) {
          const errorState = FetchState.getErrorState(err);
          setErrorForComment(
            commentId,
            errorState.getErrorData().file || errorState.getErrorMessage(),
          );
          onBusy(false);
          return;
        }
      }
    }
    const commentContext = {
      contextId,
      contextType,
    };
    // #endregion

    if (comment?.id) {
      // edit comment
      updateCommentMutation.mutate({
        content,
        comment,
        context: commentContext,
        dossierNeedsUpdate,
      });
    } else {
      // new comment
      addCommentMutation.mutate({
        content,
        parentComment,
        context: commentContext,
        dossierNeedsUpdate,
      });
    }
  };

  /**
   * Delete comment
   */
  const doDeleteComment = (comment: any) => {
    deleteCommentMutation.mutate(comment.id);
    onUpdate('delete');
  };
  // #endregion

  const values = data?.pages
    .map(({ results }) => results)
    .reduce((prevValues, currentValues) => [...prevValues, ...currentValues]);
  const paging = data?.pages.at(-1)?.paging;

  // render
  return (
    <div ref={intersectionRef}>
      <CardSectionCommentsView
        {...props}
        comments={values ?? []}
        dossierTypeMessageText={dossierTypeMessageText}
        dossierTypeTitleText={dossierTypeTitleText}
        dossierUsers={dossierUsers}
        editorHideAttachments={editorHideAttachments}
        errors={errorsState}
        isLoading={isFetching || isFetchingNextPage}
        paging={paging}
        profile={profile}
        shouldResetEditorState={resetEditorState}
        userSuggestions={userSuggestions}
        onCommentDelete={doDeleteComment}
        onCommentEdit={(content, context, comment) => doNewOrEditComment(content, context, comment)}
        onCommentNew={(content, context, parentComment) =>
          doNewOrEditComment(content, context, undefined, parentComment)
        }
        onNewUserForMention={() => setDossierNeedsUpdate(true)}
        onNextPage={() => fetchNextPage()}
      />
    </div>
  );
};

export default CardSectionComments;
