import { useQuery } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import { normalizeTaskTableItem } from 'entity/table/normalizer';
import { normalizeOverviewResults } from 'helpers/normalizehelper';
import type JSendResponse from 'services/JSendResponse';
import type { ApiFilter } from 'types/types';

const getTasksForDossier = (
  dossierId: string,
  filter: ApiFilter[],
  sort: string | undefined,
  page = 1,
  pageSize: number,
) => {
  return Actions.getAPIService()
    .getDossierTaskList(dossierId, filter, sort, page, pageSize)
    .then((response: JSendResponse) =>
      normalizeOverviewResults(response.getData(), 'tasks', normalizeTaskTableItem),
    );
};

export const useDossierTasksOverview = (
  dossierId: string,
  filters: ApiFilter[],
  sort: string | undefined,
  page = 1,
  pageSize: number,
) => {
  return useQuery({
    queryKey: ['dossier-tasks', dossierId, page, pageSize, sort, filters],
    queryFn: () => getTasksForDossier(dossierId, filters, sort, page, pageSize),
    enabled: !!dossierId,
  });
};
