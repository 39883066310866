import { useMemo } from 'react';

import { useStatusGroups } from './useStatusGroups';

type KanbanColumn = {
  id: string;
  name: string;
  subtitle: string;
  style: 'default' | 'highlight';
  items: string[];
  isAddAllowed?: boolean;
};

/**
 * Returns the columns for the kanban board, based on the status groups
 * from the backend.
 */
export const useKanbanColumns = (): KanbanColumn[] => {
  const statusGroups = useStatusGroups();

  return useMemo(
    () =>
      statusGroups.map((statusGroup, index) => ({
        ...statusGroup,
        subtitle: index === statusGroups.length - 1 ? 'Laatste 3 dagen' : '',
        style: index === statusGroups.length - 1 ? 'highlight' : 'default',
        items: [],
        isAddAllowed: index !== statusGroups.length - 1,
      })),
    [statusGroups],
  );
};
