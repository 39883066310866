import { useMutation } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import type { DossierPayload } from 'services/api/DossierApi';

export const useDossierCreate = () => {
  return useMutation({
    mutationFn: ({ dossier }: { dossier: DossierPayload }) =>
      Actions.getAPIService().getDossiers().createDossier(dossier),
  });
};
