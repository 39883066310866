import { normalizeDisplayName } from 'entity/crm/normalizer';
import DataModel, { PropTypes } from 'models/base/DataModel';

/**
 * Person
 */
export default class Client extends DataModel {
  /**
   * @inheritDoc
   */
  static propTypes = {
    ...DataModel.propTypes,
    name: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    clientId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    showClientId: PropTypes.string,
  };

  /**
   * Person name.
   * @type {string}
   */
  name;

  /**
   * Person email.
   * @type {string}
   */
  email;

  /**
   * Person first name.
   * @type {string}
   */
  firstName;

  /**
   * Person last name.
   * @type {string}
   */
  lastName;

  /**
   * @type {string|number}
   */
  clientId;

  /**
   * @type {string}
   */
  showClientId;

  /**
   * @type {string}
   */
  infix;

  /**
   * @type {string}
   */
  surname;

  /**
   * @returns {string}
   */
  getName() {
    return this.name;
  }

  /**
   * @returns {string}
   */
  getEmail() {
    return this.name;
  }

  /**
   * @returns {string}
   */
  getFirstName() {
    return this.firstName;
  }

  /**
   *
   * @returns {string}
   */
  getLastName() {
    return this.lastName;
  }

  /**
   * @return {string}
   */
  getAvatarUrl() {
    return '';
  }

  /**
   * @return {string|number}
   */
  getClientId() {
    return this.clientId;
  }

  /**
   * @return {string}
   */
  getShowClientId() {
    return this.showClientId;
  }

  /**
   * @return {string}
   */
  getDisplayName() {
    return normalizeDisplayName(this);
  }
}
