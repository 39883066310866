import './assets/styles/CardPanel.scss';

import classNames from 'classnames';
import type { IOptionItem } from 'components/options/Options';
import Options from 'components/options/Options';
import React, { useState } from 'react';

type Props = React.PropsWithChildren<{
  className?: string;
  isDisabled?: boolean;
  color?: string;
  title?: string;
  actions?: IOptionItem[];
}>;

type StylePropType = {
  [key: string]: any;
};

/**
 * Card panel component
 *
 * @param {string} className
 * @param {boolean} disabled
 * @param {string} color
 * @param {string} title
 * @param {array} actions
 * @param {*} children
 * @param {object} extraProps
 * @returns {JSX.Element}
 * @constructor
 */
const CardPanel: React.FC<Props> = ({
  className,
  isDisabled,
  color,
  title,
  actions,
  children,
  ...extraProps
}) => {
  const [isToggled, setIsToggled] = useState(false);
  const styleProps: StylePropType = {
    '--c-card-panel-background': color,
  };

  return (
    <div
      className={classNames('c-card-panel', className, {
        'c-card-panel--disabled': isDisabled,
      })}
      style={styleProps}
      {...extraProps}
    >
      <div className="c-card-panel__title">
        <div className="c-card-panel__title__text">{title}</div>
        {actions && actions.length > 1 && (
          <div className="c-card-panel__title__actions">
            <Options
              isRtl
              isDisabled={isDisabled}
              isToggled={isToggled}
              options={actions}
              onToggle={(show) => setIsToggled(show)}
            />
          </div>
        )}
        {actions && actions.length === 1 && (
          <div className="c-card-panel__title__actions">
            <span
              aria-hidden="true"
              className={classNames('c-card-panel__title__actions__button', {
                'c-card-panel__title__actions__button--disabled': isDisabled,
              })}
              role="button"
              tabIndex={0}
              title={actions[0].label.toString()}
              onClick={(event) => {
                if (!isDisabled && actions[0].onClick) {
                  actions[0].onClick(event, undefined);
                }
              }}
            >
              {actions[0].image}
            </span>
          </div>
        )}
      </div>
      <div className="c-card-panel__content">{children}</div>
    </div>
  );
};

CardPanel.defaultProps = {
  isDisabled: false,
  color: '#ffffff',
  actions: undefined,
};

export default CardPanel;
