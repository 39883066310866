import type { AsyncSelectProps } from '@elseu/sdu-titan';
import { AsyncSelect, Block } from '@elseu/sdu-titan';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import type { FormValueTransform } from './helpers/defaultTransform';
import { defaultTransform } from './helpers/defaultTransform';
import { formFieldErrorProps } from './helpers/formFieldErrorProps';

const getDefaultTransform = (isMulti?: boolean) =>
  isMulti
    ? { input: defaultTransform.input, output: (value: any) => value || [] }
    : defaultTransform;

export interface AsyncSelectOptionProps {
  label: string;
  value: string;
}
export interface AsyncSelectFieldProps extends AsyncSelectProps<boolean> {
  transform?: FormValueTransform;
  isErrorHidden?: boolean;
  testId?: string;
  rules?: Record<string, any>;
}

export const AsyncSelectField = ({
  name,
  transform: customTransform,
  spaceAfter = 6,
  rules,
  isErrorHidden = false,
  isCreateAllowed,
  defaultValue,
  isMulti,
  ...props
}: AsyncSelectFieldProps) => {
  const { control } = useFormContext();
  const transform = customTransform || getDefaultTransform(isMulti);

  return (
    <Block spaceAfter={spaceAfter}>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange, ...field }, formState: { errors } }) => (
          <AsyncSelect
            {...formFieldErrorProps(errors, name, isErrorHidden)}
            autoComplete="off"
            buttonWidth="100%"
            defaultValue={defaultValue}
            isCreateAllowed={isCreateAllowed}
            isMulti={isMulti}
            menuPosition="fixed"
            spellCheck="false"
            value={transform.input(value)}
            onChange={(value) => onChange(transform.output(value))}
            {...props}
            {...field}
          />
        )}
        rules={rules}
      />
    </Block>
  );
};
