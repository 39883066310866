import ConfirmDialog from 'components/confirmdialog/ConfirmDialog';
import NotesEditor from 'components/editor/NotesEditor';
import MarkdownViewer from 'components/markdownviewer/MarkdownViewer';
import { isMentionUserLinkedToDossier, removeMentionFromContent } from 'helpers/mentionhelper';
import React, { useEffect, useRef, useState } from 'react';

export type TicketNotesFieldProps = {
  note?: string;
  isDisabled?: boolean;
  dossierUsers?: string[];
  userSuggestions?: any[];
  isReadOnly?: boolean;
  readOnlyText?: string;
  placeholder?: string;
  dossierTypeTitleText?: string;
  dossierTypeMessageText?: string;
  onPostNote?: (value: string | undefined) => void;
  onNoteChange?: (value: string | undefined) => void;
  onDirty?: () => void;
};

/**
 * TicketNotesField component
 */
const TicketNotesField: React.FC<TicketNotesFieldProps> = ({
  note = '',
  isDisabled = false,
  dossierUsers = [],
  userSuggestions = [],
  isReadOnly = false,
  readOnlyText = 'Geen aantekeningen.',
  placeholder,
  dossierTypeTitleText = 'dossier',
  dossierTypeMessageText = 'dit dossier',
  onPostNote = () => null,
  onNoteChange = () => null,
  onDirty = () => null,
}) => {
  // note value state
  const editorRef = useRef<any>(null);
  const [noteValue, setNoteValue] = useState(note);
  useEffect(() => setNoteValue(note), [note]);

  const [editorFocus, setEditorFocus] = useState<boolean>(false);

  // confirm user add.
  const [confirmAssignUserOpen, setConfirmAssignUserOpen] = useState<boolean>(false);
  const [confirmAssignUser, setConfirmAssignUser] = useState<Record<string, any>>({});
  const [editorForceVisible, setEditorForceVisible] = useState<boolean>(false);

  const sendNoteChange = (postContent: string | undefined) => {
    if (note !== postContent) {
      onNoteChange(postContent);
      onPostNote(postContent);
    }
  };

  const handleMentionConfirm = (confirmed: boolean) => {
    setConfirmAssignUserOpen(false);

    // remove mentioned user and set new content
    if (!confirmed) {
      const newNote = removeMentionFromContent(noteValue, confirmAssignUser.id);
      setNoteValue(newNote);
      onNoteChange(newNote);
    } else {
      // confirmed just send changes of noteValue state back.
      onNoteChange(noteValue);
    }
    setEditorFocus(true);
  };

  return (
    <>
      {!isReadOnly ? (
        <NotesEditor
          ref={editorRef}
          content={noteValue}
          isDisabled={isDisabled}
          isEditorFocused={editorFocus}
          isEditorForcedVisible={editorForceVisible}
          placeholder={placeholder}
          userSuggestions={userSuggestions}
          onChange={(value) => {
            setNoteValue(value);
            if (!confirmAssignUserOpen) {
              setEditorForceVisible(false);
            }
          }}
          onDirty={onDirty}
          onEditorBlur={() => setTimeout(() => setEditorFocus(false), 100)}
          onEditorFocus={() => setEditorFocus(true)}
          onMentionAdded={(mention) => {
            if (!isMentionUserLinkedToDossier(dossierUsers, mention)) {
              setEditorForceVisible(true);
              setConfirmAssignUserOpen(true);
              setConfirmAssignUser(mention);
            }
          }}
          onPost={(postContent) => {
            if (!confirmAssignUserOpen) {
              sendNoteChange(postContent);
              setEditorForceVisible(false);
            }
          }}
        />
      ) : (
        <MarkdownViewer source={note || readOnlyText} />
      )}
      {confirmAssignUserOpen && (
        <ConfirmDialog
          cancelTitle="Nee"
          confirmTitle="Ja, koppel"
          isDisabled={isDisabled}
          title={`Gebruiker koppelen aan ${dossierTypeTitleText}`}
          onCancel={() => handleMentionConfirm(false)}
          onConfirm={() => handleMentionConfirm(true)}
        >
          De geselecteerde gebruiker is niet gekoppeld aan {dossierTypeMessageText}.<br />
          Wilt u de gebruiker koppelen als bewerker aan {dossierTypeMessageText} en een notificatie
          sturen?
        </ConfirmDialog>
      )}
    </>
  );
};

export default TicketNotesField;
