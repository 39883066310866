import { List, ListItem } from '@elseu/sdu-titan';
import React, { useCallback } from 'react';
import type { UseFormReturn } from 'react-hook-form/dist/types';
import type { FieldValues } from 'react-hook-form/dist/types/fields';
import type { FieldPath } from 'react-hook-form/dist/types/path';

import { useGlobalMessages } from './useGlobalMessages';

const FORM_ERROR_KEY = 'form-error';
const FORM_SAVED_KEY = 'form-saved';

/**
 * Hook to try to save a form and handle errors.
 *
 * It will set call the setError function for a field
 * if it is found, otherwise it will show a global error.
 */
export const useTrySaveForm = () => {
  const { addMessage, clear } = useGlobalMessages();

  return useCallback(
    async function <T extends FieldValues>(
      fn: () => Promise<unknown | void>,
      form: UseFormReturn<T>,
      successMessage = 'Wijzigingen zijn opgeslagen',
    ) {
      try {
        form.clearErrors();
        clear();

        await fn();

        addMessage({
          key: FORM_SAVED_KEY,
          message: successMessage,
          type: 'success',
        });
      } catch (error: any) {
        const globalMessages: any[] = [];

        if (error.data) {
          const fields = Object.keys(error.data).filter((field) => field !== '_paths') as Array<
            FieldPath<T>
          >;
          const formFields = Object.keys(form.getValues());

          const availableFormFields = fields.filter((key: any) => formFields.includes(key));
          const globalFields = fields.filter((key: any) => !formFields.includes(key));

          // Set errors on the form fields
          for (const key of availableFormFields) {
            form.setError(key, { message: error.data[key] });
          }

          // For all other errors, show a global message
          for (const key of globalFields) {
            globalMessages.push({ key, value: error.data[key] });
          }

          // Show a global message if there are errors in the form,
          // unless there are already global messages
          if (availableFormFields.length > 0 && globalMessages.length === 0) {
            addMessage({
              key: FORM_ERROR_KEY,
              message: 'Er zijn fouten gevonden in het formulier. Deze zijn gemarkeerd.',
              type: 'warning',
            });
          }
        } else {
          globalMessages.push({ key: 'error', value: error.message });
        }

        if (globalMessages.length > 0) {
          const message = (
            <List>
              {globalMessages.map((msg) => (
                <ListItem key={msg.key}>
                  <strong>{msg.key}</strong>
                  <br />
                  {msg.value}
                </ListItem>
              ))}
            </List>
          );

          addMessage({
            key: FORM_ERROR_KEY,
            message,
            type: 'warning',
            dismissAfter: 10000,
          });
        }
      }
    },
    [clear, addMessage],
  );
};
