import './assets/styles/Comment.scss';

import { ActionMenu, Avatar, Box, Card, Flex, MenuItem, Text } from '@elseu/sdu-titan';
import classNames from 'classnames';
import Date from 'components/date/Date';
import ListIcon from 'components/listicon/ListIcon';
import MarkdownViewer from 'components/markdownviewer/MarkdownViewer';
import type { IOptionItem } from 'components/options/Options';
import type { ContextItem } from 'entity/dossiers/types';
import type { User } from 'entity/system/types';
import React from 'react';

type Props = {
  className?: string;
  currentUser: User;
  user: User;
  createdDateTime?: Date;
  actions?: IOptionItem[];
  content: string;
  context?: ContextItem;
  isDisabled?: boolean;
  isReadOnly?: boolean;
};

/**
 * Comment
 * @param {string} className
 * @param {User} user
 * @param {Date} createdDateTime
 * @param {array} actions
 * @param {string} content
 * @param {object} context
 * @param {boolean} isDisabled
 * @param {boolean} isReadOnly
 * @return {JSX.Element}
 * @constructor
 */
const Comment: React.FC<Props> = ({
  user,
  currentUser,
  createdDateTime,
  actions = [],
  content,
  context,
  isDisabled = false,
  isReadOnly = false,
}) => (
  <>
    <Flex gap={1} justifyContent="space-between" spaceAfter={2}>
      <Flex alignItems="center" gap={1}>
        <Avatar size={16} src={user.avatarUrl} />
        <Text type="labelSmallBold">{user.fullName}</Text>
      </Flex>
      <Flex alignItems="center" gap={2}>
        <Text color="grey70" type="labelSmall">
          <Date
            autoUpdateEvery={1000 * 30}
            className="comment__message__header__date"
            date={createdDateTime}
          />
        </Text>
        {actions.length > 0 && (
          <ActionMenu
            defaultShown={false}
            disabled={isDisabled}
            label="Acties"
            popoverOptions={{
              strategy: 'fixed',
            }}
            popoverPlacement="bottom-end"
          >
            {actions.map((action, index) => (
              <MenuItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                isDisabled={action.isDisabled}
                item={{
                  // @ts-ignore
                  label: action.label,
                }}
                onClick={(e) => {
                  action.onClick?.(e, context);
                }}
              />
            ))}
          </ActionMenu>
        )}
      </Flex>
    </Flex>

    {context && (
      <Box className="comment__message__context">
        <Box className={classNames('context', { 'with-description': context.description })}>
          {isReadOnly ? (
            <>
              <span className="context__header">
                <ListIcon className="context__icon" type={context.iconName || context.type} />
                <span className="context__title context__title--disabled">{context.name}</span>
              </span>
              <span className="context__description">{context.description}</span>
            </>
          ) : (
            <a href={context.url} rel="noopener noreferrer" target="_blank">
              <span className="context__header">
                <ListIcon className="context__icon" type={context.iconName || context.type} />
                <span className="context__title">{context.name}</span>
              </span>
              <span className="context__description">{context.description}</span>
            </a>
          )}
        </Box>
      </Box>
    )}

    <Card backgroundVariant={currentUser.id === user.id ? 'tertiary' : 'grey'}>
      <MarkdownViewer source={content} />
    </Card>
  </>
);

export default Comment;
