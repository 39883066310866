import type { AccordionItemProps, AccordionTreeDataProps } from '@elseu/sdu-titan';
import { AccordionItem, AccordionTree, Box, Highlighter, Link, PlusIcon } from '@elseu/sdu-titan';
import type { ContextGroup, ContextItem } from 'entity/dossiers/types';
import { addHasChildren } from 'helpers/addHasChildren';
import { pick } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import type { TreeSource, TreeState } from 'use-tree';
import { staticTreeSource } from 'use-tree';

import ContextGroupItem from './ContextGroupItem';
import type { ContextGroupsProps } from './types';

const GroupItem = styled.li`
  padding: ${({ theme }) =>
    // eslint-disable-next-line max-len
    `${theme.components.accordion.itemVerticalPadding} ${theme.components.accordion.itemPaddingRight} ${theme.components.accordion.itemVerticalPadding} ${theme.components.accordion.itemPaddingLeft}`};
`;

const ContextGroupItemTree: React.FC<AccordionItemProps> = ({ label }) => (
  <GroupItem>{label}</GroupItem>
);

/**
 * ContextGroups
 */
const ContextGroupsTree: React.FC<ContextGroupsProps> = ({
  search,
  groups = [],
  actions,
  isDisabled = false,
  isToolCreationAllowed = true,
  toolCreationTitle = 'Voeg eigen model of tool aan Addify toe',
  onGetActionsForItem,
  onShouldShowActions = (group, item) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return !!(group && item);
  },
  onToolCreationClick,
  onBerkelyItemClick,
}) => {
  const [treeState, setTreeState] = useState<TreeState>({});

  /**
   * Get list of actions that an item can do.
   * First checks if the function onGetActionsForItem is set.
   * If not then look if actions variable is set and check if we should be shown by using the onShouldShowActions
   * function.
   */
  const getActions = (group: ContextGroup, item: ContextItem) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (onGetActionsForItem) {
      return onGetActionsForItem(group, item);
    } else {
      return onShouldShowActions(group, item) ? actions || [] : [];
    }
  };

  const items = useMemo<TreeSource<AccordionTreeDataProps>>(() => {
    const treeData = groups.map((group) => {
      const items = search
        ? group.items.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
        : group.items;

      const groupItem = pick(group, ['id', 'name', 'type']);

      return {
        id: group.id,
        label: group.name,
        children: items.map((item) => ({
          id: item.id,
          label: item.name,
          item,
          group: groupItem,
        })),
      };
    });

    const filteredTreeData =
      search === '' ? treeData : treeData.filter((group) => group.children.length > 0);
    const dataWithHasChildren = addHasChildren(filteredTreeData);
    return staticTreeSource<AccordionTreeDataProps>(dataWithHasChildren);
  }, [groups, search]);

  const terms = search ? [search] : [];

  useEffect(() => {
    if (search === '') {
      return;
    }

    setTreeState((prevValue) => ({
      ...prevValue,
      expandedIds: groups.reduce(
        (all: { [key: string]: boolean }, { id }) => ({
          ...all,
          ...(id ? { [id]: true } : {}),
        }),
        {},
      ),
    }));
  }, [search, groups]);

  return (
    <>
      <AccordionTree
        customItemRenderer={({ label, ...props }) => {
          // @ts-ignore they exist in the props.
          const { group, item } = props;
          // is a tool  or something
          if (group && item) {
            return (
              <ContextGroupItemTree
                label={
                  <Highlighter terms={terms}>
                    <ContextGroupItem
                      isTitleALink
                      actions={getActions(group, item)}
                      context={{
                        group,
                        item,
                      }}
                      description={item.description || ''}
                      iconType={item.type || ''}
                      isDisabled={isDisabled}
                      title={item.name || ''}
                      url={item.url || ''}
                      onClick={
                        item.isBerkleyModel
                          ? () => {
                              onBerkelyItemClick(item);
                            }
                          : undefined
                      }
                    />
                  </Highlighter>
                }
                {...props}
              />
            );
          }
          return <AccordionItem label={label} {...props} />;
        }}
        hasToggleAll={true}
        tree={items}
        treeState={treeState}
        onStateChange={setTreeState}
      />
      {isToolCreationAllowed && !search && (
        <Box pt={4}>
          <Link prefixAdornment={<PlusIcon />} onClick={onToolCreationClick}>
            {toolCreationTitle}
          </Link>
        </Box>
      )}
    </>
  );
};

export default ContextGroupsTree;
