import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import { normalizeDossier } from 'entity/dossiers/normalizer';
import type { Dossier } from 'entity/dossiers/types';
import { useMemo } from 'react';

type useDossierDetailsReturnType = {
  dossier?: Dossier;
  query: UseQueryResult;
};

export const useDossierDetails = (dossierId: string | undefined): useDossierDetailsReturnType => {
  const query = useQuery({
    queryKey: ['dossier', dossierId],
    queryFn: async () => {
      const res = await Actions.getAPIService()
        .getDossiers()
        .getDossier(dossierId ?? '');
      return res.isSuccess() ? normalizeDossier(res.getData()) : undefined;
    },
    staleTime: 60 * 1000,
    enabled: !!dossierId,
  });

  return useMemo(
    () => ({
      dossier: query.data,
      query,
    }),
    [query],
  );
};
