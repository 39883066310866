import { useMutation, useQueryClient } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import FormDrawer from 'components/formdrawer/FormDrawer';
import type { LegalSource } from 'entity/dossiers/types';
import { useInvalidateCardCache } from 'hooks/invalidate/useInvalidateCardCache';
import React, { useEffect, useState } from 'react';
import { FormError } from 'utils/FormError';

import type { LegalSourceFormValues } from './LegalSourceForm';
import LegalSourceForm from './LegalSourceForm';

type Props = {
  isDisabled?: boolean;
  isReadOnly?: boolean;
  dossierId: string;
  cardId: string;
  item?: LegalSource;
  onSaved: () => void;
  onCancel: () => void;
};

const LegalSourceEdit: React.FC<Props> = ({
  isDisabled,
  dossierId,
  cardId,
  isReadOnly,
  item,
  onSaved,
  onCancel,
}) => {
  const [values, setValues] = useState<LegalSourceFormValues>({});
  const [formError, setFormError] = useState<FormError | undefined>();
  const queryClient = useQueryClient();
  const invalidateCardCache = useInvalidateCardCache();

  useEffect(() => {
    if (item) {
      setValues({
        name: item.name,
        comment: item.comment,
      });
    }
  }, [item]);

  const updateLegalSource = useMutation({
    mutationFn: () =>
      Actions.getAPIService()
        .legalSource()
        .updateLegalSource(dossierId, cardId, item?.id as string, values),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['legalsource', item?.id],
      });
      invalidateCardCache({ dossierId, cardId });
      onSaved();
    },
    onError: (err: any) => {
      if (err.code && err.code === 400) {
        setFormError(new FormError(err));
      }
    },
  });

  return (
    <FormDrawer
      confirmTitle="Opslaan"
      isLoading={updateLegalSource.isPending}
      loadingText="Bezig met opslaan"
      title="Juridsche bron bewerken"
      onCancel={() => {
        if (updateLegalSource.isPending) {
          return;
        }
        onCancel();
      }}
      onConfirm={() => {
        updateLegalSource.mutate();
      }}
    >
      <LegalSourceForm
        errors={formError}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        values={values}
        onChange={setValues}
      />
    </FormDrawer>
  );
};

export default LegalSourceEdit;
