import './assets/styles/ColorTextLabel.scss';

import classNames from 'classnames';
import { createColorSwatch } from 'helpers/colorhelper';
import React from 'react';

import { useColorConverter } from '../../hooks/useColorConverter';

type Props = React.PropsWithChildren<{
  color?: string;
  isSmall?: boolean;
}>;

type StylePropType = {
  [key: string]: any;
};

/**
 * SignalLabel
 *
 * @param {string} color
 * @param {boolean} isSmall
 * @param {JSX.Element} children
 * @return {JSX.Element}
 * @constructor
 */
const ColorTextLabel: React.FC<Props> = ({ color, isSmall, children }) => {
  const { statusColorToHex } = useColorConverter();
  const swatch = createColorSwatch(statusColorToHex(color));

  const style: StylePropType = {
    '--c-color-label-background-color': color,
    '--c-color-label-border-color': swatch.border,
    '--c-color-label-text-color': swatch.text,
  };

  return (
    <div
      className={classNames('c-color-text-label', { 'c-color-text-label--small': isSmall })}
      style={style}
    >
      {children}
    </div>
  );
};

ColorTextLabel.defaultProps = {
  color: '#ffffff',
  isSmall: false,
};

export default ColorTextLabel;
