import './assets/styles/FileBrowser.scss';

import { Checkbox, FileIcon, FolderIcon } from '@elseu/sdu-titan';
import classNames from 'classnames';
import Radio from 'components/radio/Radio';
import RadioGroup from 'components/radio/RadioGroup';
import React, { useEffect, useState } from 'react';

export enum FileBrowserSelectionType {
  FILE = 'file',
  FOLDER = 'folder',
  FILE_MULTIPLE = 'file-multiple',
}

enum FileBrowserSelectType {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
}

type FileBrowserItemProps = {
  ns?: string;
  id?: string;
  type?: FileBrowserSelectionType;
  name?: string;
  isSelectable?: boolean;
  selectType: FileBrowserSelectType;
  isSelected: boolean;
  onClick: () => void;
  onChecked?: (checked: boolean) => void;
};

type FileBrowserProps = {
  className?: string;
  onLoadItems: (
    loadCallBack: (items: any) => void,
    rejectCallBack: () => void,
    id?: string,
  ) => void;
  onSelectItem: (value: any, data: any) => void;
  selectionType: FileBrowserSelectionType;
};

/**
 * FileBrowserItem
 * @param {string} ns
 * @param {string} id,
 * @param {string} type
 * @param {string} name
 * @param {boolean} selectable
 * @param {function} onClick
 * @returns {*}
 * @constructor
 */
const FileBrowserItem: React.FC<FileBrowserItemProps> = ({
  ns = 'file-browser__item',
  id,
  type,
  name,
  isSelectable = false,
  selectType = FileBrowserSelectType.RADIO,
  isSelected,
  onClick,
  onChecked,
}) => (
  <div className={`${ns}`}>
    <div className={`${ns}__action`}>
      {isSelectable && selectType === FileBrowserSelectType.RADIO && (
        <Radio id={`${ns}__item__${id}`} value={id} />
      )}
      {isSelectable && selectType === FileBrowserSelectType.CHECKBOX && (
        <Checkbox
          isChecked={isSelected}
          label=""
          name={`${ns}__item__${id}`}
          value={id}
          onChange={onChecked}
        />
      )}
    </div>
    <div
      aria-hidden="true"
      className={`${ns}__icon`}
      role="button"
      tabIndex={0}
      onClick={() => {
        if (type === FileBrowserSelectionType.FOLDER) {
          onClick();
        } else {
          if (selectType === FileBrowserSelectType.CHECKBOX) {
            onChecked?.(!isSelected);
          }
        }
      }}
    >
      {type === FileBrowserSelectionType.FOLDER ? <FolderIcon /> : <FileIcon />}
    </div>
    <div
      aria-hidden="true"
      className={`${ns}__name`}
      role="button"
      tabIndex={0}
      onClick={() => {
        if (type === FileBrowserSelectionType.FOLDER) {
          onClick();
        } else {
          if (selectType === FileBrowserSelectType.CHECKBOX) {
            onChecked?.(!isSelected);
          }
        }
      }}
    >
      {name}
    </div>
  </div>
);

/**
 * FileBrowser
 *
 * @param {string} className
 * @param {boolean} disabled
 * @param {function} onLoadItems,
 * @param {function} onSelectItem,
 * @param {string} selectionType,
 * @param {object} props
 * @return {*}
 * @constructor
 */
const FileBrowser: React.FC<FileBrowserProps> = ({
  className,
  onLoadItems,
  onSelectItem,
  selectionType,
  ...props
}) => {
  const ns = 'file-browser';
  const [parent, setParent] = useState<string | false | undefined>(false);
  const [ids, setIds] = useState<string[]>([]);
  const [content, setContent] = useState<any[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [disabled, setDisabled] = useState(true);

  /**
   * Will handle the loading state and call of the onLoadItems callback to
   * actually call items. Id is optional this allows the onLoadItems callback
   * to know if we want to load child content.
   * @param {string|null} id
   */
  const loadContent = (id?: string) => {
    setDisabled(true);

    // when tracking of history is requested, keep track of id only once.
    if (id !== undefined && !ids.includes(id)) {
      setIds([...ids, id]);
    }

    // call parent to provide content, optional id of the element to fetch
    // the content of and a success and failure method to call when content
    // was ready or failed.
    onLoadItems(
      (items: any) => {
        setContent(items);
        setParent(id);
        setDisabled(false);
      },
      () => {
        setParent(id);
        setDisabled(false);
      },
      id,
    );
  };

  // initialize the first onLoadItems call when the component is initialized.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadContent, []);

  return (
    <div
      className={classNames(ns, {
        [`${ns}--disabled`]: disabled,
      })}
      {...props}
    >
      <div className={`${ns}__list`}>
        {parent !== undefined && (
          <FileBrowserItem
            isSelected={false}
            name="..."
            selectType={
              selectionType === FileBrowserSelectionType.FILE_MULTIPLE
                ? FileBrowserSelectType.CHECKBOX
                : FileBrowserSelectType.RADIO
            }
            type={FileBrowserSelectionType.FOLDER}
            onClick={() => {
              if (disabled) {
                return;
              }

              // setup a copy of the ids stack, we are going to manipulate it, and remove
              // the current index.
              const nextIds = [...ids].slice(0, -1);

              // slice off the last index, this index is the previous item that we are going
              // to request the new content for.
              const previousId = nextIds[nextIds.length - 1];

              // save the modified id stack without the current index.
              setIds(nextIds);

              loadContent(previousId);
            }}
          />
        )}
        <RadioGroup
          selected={selected.length > 0 && selected[0]}
          onChange={(value) => {
            if (disabled || selected === value) {
              return;
            }

            // extract entire data object for convenience.
            const data = content.find((contentData) => contentData.id === value);

            setSelected([value]);
            onSelectItem([value], data);
          }}
        >
          {content.map(({ id, type, name }, index) => (
            <FileBrowserItem
              // eslint-disable-next-line react/no-array-index-key
              key={`${ns}__list__item-${index}`}
              id={id}
              isSelectable={
                // item is selectable if selection type is a folder and the item is also a folder or
                // if the item is a file and the selection type is not a folder.
                (selectionType === FileBrowserSelectionType.FOLDER &&
                  type === FileBrowserSelectionType.FOLDER) ||
                (selectionType === FileBrowserSelectionType.FILE &&
                  type === FileBrowserSelectionType.FILE) ||
                (selectionType === FileBrowserSelectionType.FILE_MULTIPLE &&
                  type === FileBrowserSelectionType.FILE)
              }
              isSelected={selected.includes(id)}
              name={name}
              selectType={
                selectionType === FileBrowserSelectionType.FILE_MULTIPLE
                  ? FileBrowserSelectType.CHECKBOX
                  : FileBrowserSelectType.RADIO
              }
              type={type}
              onChecked={(checked) => {
                setSelected((prevSelected: string[]) => {
                  let newItems = [...prevSelected];
                  if (checked) {
                    newItems.push(id);
                  } else {
                    newItems = newItems.filter((item) => item !== id);
                  }
                  const data = content.find((contentData) => contentData.id === id);
                  onSelectItem(newItems, data);
                  return newItems;
                });
              }}
              onClick={() => {
                if (disabled) {
                  return;
                }

                loadContent(id);
              }}
            />
          ))}
        </RadioGroup>
      </div>
    </div>
  );
};

FileBrowser.defaultProps = {
  selectionType: FileBrowserSelectionType.FILE,
};

export default FileBrowser;
