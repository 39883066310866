import './assets/styles/DossierCardPreview.scss';

import classNames from 'classnames';
import Avatar from 'components/avatar/Avatar';
import AvatarClient from 'components/avatar/AvatarClient';
import CardPanel from 'components/card/CardPanel';
import ColorLabel from 'components/colorlabel/ColorLabel';
import Date from 'components/date/Date';
import type { IOptionItem } from 'components/options/Options';
import Taglist from 'components/taglist/TagList';
import type { Dossier } from 'entity/dossiers/types';
import React from 'react';
import { Link } from 'react-router-dom';

import { useColorConverter } from '../../hooks/useColorConverter';

type Props = {
  isDisabled?: boolean;
  item: Dossier;
  link?: string;
  actions: IOptionItem[];
};

/**
 * Dossier card preview
 *
 * @param {boolean} isDisabled
 * @param {Dossier} item
 * @param {string} link
 * @param {array} actions
 * @returns {JSX.Element}
 * @constructor
 */
const DossierCardPreview: React.FC<Props> = ({ isDisabled, item, link, actions }) => {
  const { id, name, clients, updatedAt, topics, owners, editors, phaseLabels } = item;

  const { statusColorToHex } = useColorConverter();

  const previewCard = (
    <CardPanel
      actions={actions}
      className={classNames('c-dossier-card-preview', {
        'c-dossier-card-preview--has-link': !!link,
      })}
      isDisabled={isDisabled}
      title="Dossier"
    >
      <div className="c-dossier-card-preview__content">
        <div className="c-dossier-card-preview__name">{name}</div>
        <div className="c-dossier-card-preview__date">
          <Date date={updatedAt} />
        </div>
        <div className="c-dossier-card-preview__topics">
          <Taglist tags={topics.map((topic) => topic.name ?? '').filter((name) => name !== '')} />
        </div>
        <div className="c-dossier-card-preview__users">
          <div className="c-dossier-card-preview__users__owners">
            {owners?.map((user) => (
              <Avatar key={user.id} isInline isRounded size={30} url={user.avatarUrl} />
            ))}
          </div>
          <div className="c-dossier-card-preview__users__editors">
            {editors?.map((user) => (
              <Avatar key={user.id} isInline isRounded size={24} url={user.avatarUrl} />
            ))}
          </div>
        </div>
        <div className="c-dossier-card-preview__clients">
          {clients?.map(({ client }) => (
            <div
              key={id ?? `${client?.id ?? ''}`}
              className="c-dossier-card-preview__clients__item"
            >
              <AvatarClient name={client?.displayName} size={24} />
            </div>
          ))}
        </div>
        <div className="c-dossier-card-preview__phases">
          <ul className="c-dossier-card-preview__phases__breadcrumb">
            <li>
              Inventarisatie
              {phaseLabels.inventory && (
                <ColorLabel
                  className="c-dossier-card-preview__phases__breadcrumb__label"
                  color={statusColorToHex(phaseLabels.inventory.color)}
                  label={phaseLabels.inventory.name}
                />
              )}
            </li>
            <li>
              Analyse
              {phaseLabels.analysis && (
                <ColorLabel
                  className="c-dossier-card-preview__phases__breadcrumb__label"
                  color={statusColorToHex(phaseLabels.analysis.color)}
                  label={phaseLabels.analysis.name}
                />
              )}
            </li>
            <li>
              Advies
              {phaseLabels.advice && (
                <ColorLabel
                  className="c-dossier-card-preview__phases__breadcrumb__label"
                  color={statusColorToHex(phaseLabels.advice.color)}
                  label={phaseLabels.advice.name}
                />
              )}
            </li>
            <li>
              Uitvoering
              {phaseLabels.execution && (
                <ColorLabel
                  className="c-dossier-card-preview__phases__breadcrumb__label"
                  color={statusColorToHex(phaseLabels.execution.color)}
                  label={phaseLabels.execution.name}
                />
              )}
            </li>
          </ul>
        </div>
      </div>
    </CardPanel>
  );

  if (link) {
    return (
      <Link className="c-dossier-card-preview__link" to={link}>
        {previewCard}
      </Link>
    );
  }

  return previewCard;
};

export default DossierCardPreview;
