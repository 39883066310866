import './assets/styles/ConfirmModal.scss';

import classNames from 'classnames';
import Button, { ButtonAppearanceTypes } from 'components/button/Button';
import Modal from 'components/modal/Modal';
import React, { useEffect, useRef } from 'react';

export type ConfirmButtonProps = {
  title: string | React.ReactNode;
  appearance: ButtonAppearanceTypes;
  onClick: () => void;
  isDisabled?: boolean;
};

type Props = React.PropsWithChildren<{
  className?: string;
  isDisabled?: boolean;
  isConfirmDisabled?: boolean;
  title?: string | React.ReactNode;
  confirmTitle?: string | React.ReactNode;
  confirmAppearance?: ButtonAppearanceTypes;
  cancelTitle?: string;
  confirmButtonAlignment?: 'left' | 'center' | 'right';
  isCloseShown?: boolean;
  isFocusedOnShow?: boolean;
  shouldUsePortal?: boolean;
  buttons?: ConfirmButtonProps[];
  onConfirm: () => void;
  onCancel: () => void;
}>;

/**
 * ConfirmModal
 *
 * @param {Object} props
 * @return {JSX.Element}
 * @constructor
 */
const ConfirmModal: React.FC<Props> = ({
  className,
  title,
  isDisabled,
  isConfirmDisabled,
  confirmTitle,
  onConfirm,
  cancelTitle,
  onCancel,
  confirmButtonAlignment,
  isCloseShown,
  buttons,
  children,
  isFocusedOnShow,
  shouldUsePortal,
}) => {
  const classes = classNames(className, 'c-confirm-modal');
  const confirmButton = useRef<HTMLButtonElement>(null);

  const focusConfirmButtonEffect = () => {
    if (isFocusedOnShow) {
      confirmButton.current?.focus();
    }
  };
  // eslint-disable-next-line
  useEffect(focusConfirmButtonEffect, []);

  return (
    <Modal
      className={classes}
      header={{
        title,
        onClose: isCloseShown ? onCancel : undefined,
      }}
      shouldUsePortal={shouldUsePortal}
    >
      <div className="dialog__form">
        <div className="dialog__form__row">{children}</div>
        <div
          className={classNames(
            'dialog__form__row',
            'dialog__form__row__buttons',
            `dialog__form__row__${confirmButtonAlignment}`,
          )}
        >
          {buttons && buttons.length > 0 ? (
            buttons.map((button, buttonIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <Button key={`confirm-${buttonIndex}`} {...button} isDisabled={isDisabled}>
                {button.title}
              </Button>
            ))
          ) : (
            <>
              <Button
                buttonRef={confirmButton}
                className="c-confirm-modal__confirm-button"
                isDisabled={isDisabled || isConfirmDisabled}
                onClick={onConfirm}
              >
                {confirmTitle}
              </Button>
              <Button
                appearance={ButtonAppearanceTypes.APPEARANCE_LINK}
                className="c-confirm-modal__cancel-button"
                isDisabled={isDisabled}
                onClick={onCancel}
              >
                {cancelTitle}
              </Button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  isCloseShown: false,
  title: 'Confirm',
  confirmTitle: 'Ok',
  onConfirm: () => null,
  cancelTitle: 'Annuleren',
  onCancel: () => null,
  confirmButtonAlignment: 'right',
  buttons: [],
  isFocusedOnShow: false,
  shouldUsePortal: false,
};

export default ConfirmModal;
