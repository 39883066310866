// if the component DossierTable is not used anymore. Rname this component to DossierTable
import './assets/styles/DossierTableView.scss';

import type { TableColumns } from '@elseu/sdu-titan';
import {
  ActionMenu,
  Button,
  Link,
  LockAltIcon,
  MenuItem,
  PlusIcon,
  StatusMessage,
  Table,
  TableCommandBar,
  TableProvider,
  Text,
} from '@elseu/sdu-titan';
import classNames from 'classnames';
import { ClientQuickLookMultiple } from 'components/client/ClientQuickLookMultiple';
import ConfirmDialog from 'components/confirmdialog/ConfirmDialog';
import Date, { DateAppearanceTypes } from 'components/date/Date';
import ArchiveIcon from 'components/icons/ArchiveIcon';
import Radio from 'components/radio/Radio';
import TagDrawerList from 'components/tagdrawerlist/TagDrawerList';
import { normalizeDossierTableItem } from 'entity/table/normalizer';
import type { DossierTableItem } from 'entity/table/types';
import type { TableProviderStateProps } from 'helpers/TitanTableHelpers';
import { useDossierArchiveMutate, useDossierDeleteMutate } from 'hooks/mutate/useDossierMutate';
import Dossier from 'models/dossier/Dossier';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

export type { DossierTableItem };
export { normalizeDossierTableItem };

// :nth-last-child
const AlignedTable = styled.div`
  th:nth-last-child(2),
  td:nth-last-child(2) {
    padding-left: 0;
    padding-right: 0;
  }

  th:last-child,
  td:last-child {
    padding-left: 0.75rem;
  }
`;

type Props = {
  view: 'dossiers' | 'clients' | 'clientUsers' | 'relatedCard';
  data: DossierTableItem[];
  tab?: string;
  hasNewButton?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  totalCount: number;
  defaultPageSize?: number;
  commandBarContent?: React.ReactNode;
  tableStateProps: TableProviderStateProps;
  onNewButtonClick?: () => void;
};

const columnViewSettings: Record<string, string[]> = {
  dossiers: ['name', 'topics', 'clients', 'owners', 'updatedAt', 'isArchived', 'id'],
  clients: ['name', 'topics', 'owners', 'updatedAt', 'isArchived', 'id'],
  clientUsers: ['name', 'topics', 'clients', 'owners', 'updatedAt', 'isArchived', 'id'],
  relatedCard: ['inventoryCardId', 'name', 'topics', 'owners', 'updatedAt', 'isArchived'],
};

const DossierTableView: React.FC<Props> = ({
  view,
  tab,
  data,
  hasNewButton,
  isDisabled,
  isLoading,
  totalCount,
  defaultPageSize,
  commandBarContent,
  tableStateProps,
  onNewButtonClick,
}) => {
  const navigate = useNavigate();

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState<boolean>(false);
  const [deleteContext, setDeleteContext] = useState<DossierTableItem | undefined>(undefined);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState<string | undefined>(undefined);

  const deleteDossier = useDossierDeleteMutate({
    onSuccess: () => {
      setIsDeleteConfirmOpen(false);
      setDeleteContext(undefined);
      setDeleteErrorMessage(undefined);
    },
    onError: (error: any) => setDeleteErrorMessage(error.message),
  });

  const archiveToggleDossier = useDossierArchiveMutate({});

  const usableColumns: TableColumns<DossierTableItem> = useMemo(
    () => [
      {
        id: 'inventoryCardId',
        header: ' ',
        accessorKey: 'inventoryCardId',
        enableSorting: false,
        size: 16,
        cell: ({ row }) => {
          return <Radio value={row.original.inventoryCardId} />;
        },
      },
      {
        id: 'name',
        header: 'Naam',
        accessorKey: 'name',
        cell: ({ row }) =>
          row.original.canView ? (
            <Link
              className={classNames({
                'c-dossier-table-view__archived-item': row.original.isArchived,
              })}
              to={Dossier.createLastVisitPhaseUrl(row.original.id)}
            >
              {row.original.name}
            </Link>
          ) : (
            <Text
              className={classNames({
                'c-dossier-table-view__archived-item': row.original.isArchived,
              })}
              type="paragraph"
            >
              {row.original.name}
            </Text>
          ),
      },
      {
        id: 'topics',
        header: "Thema's",
        accessorKey: 'topics',
        size: 175,
        enableSorting: false,
        cell: ({ row }) => (
          <TagDrawerList
            className={classNames({
              'c-dossier-table-view__archived-item': row.original.isArchived,
            })}
            header="Thema's"
            items={row.original.topics.map((topic) => ({
              key: topic.id ?? '',
              name: topic.name ?? '',
            }))}
          />
        ),
      },
      {
        id: 'clients',
        header: 'Klant(en)',
        accessorKey: 'clients',
        size: 175,
        enableSorting: false,
        cell: ({ row }) => (
          <ClientQuickLookMultiple
            className={classNames({
              'c-dossier-table-view__archived-item': row.original.isArchived,
            })}
            firstItemType="text"
            header="Klant(en)"
            items={row.original.clients.map((client) => ({
              id: client.client?.id ?? '',
              name: client.client?.displayName ?? '',
            }))}
          />
        ),
      },
      {
        id: 'owners',
        header: 'Eigenaars',
        accessorKey: 'owners',
        size: 175,
        enableSorting: false,
        cell: ({ row }) => {
          if (row.original.owners.length === 0) {
            return null;
          }
          return (
            <span
              className={classNames({
                'c-dossier-table-view__archived-item': row.original.isArchived,
              })}
            >
              {row.original.owners.map((owner) => owner.fullName).join(', ')}
            </span>
          );
        },
      },
      {
        id: 'updatedAt',
        header: 'Gewijzigd op',
        accessorKey: 'updatedAt',
        size: 181,
        cell: ({ row }) => (
          <Date
            appearance={DateAppearanceTypes.APPEARANCE_DEFAULT}
            className={classNames({
              'c-dossier-table-view__archived-item': row.original.isArchived,
            })}
            date={row.original.updatedAt}
          />
        ),
      },
      {
        id: 'isArchived',
        header: ' ',
        size: 20,
        accessorKey: 'isArchived',
        enableSorting: false,
        cell: ({ row }) =>
          row.original.isArchived ? (
            <span title="Gearchiveerd">
              <ArchiveIcon color="secondary-dark-grey" size={16} />
            </span>
          ) : null,
      },
      {
        id: 'id',
        header: ' ',
        accessorKey: 'id',
        size: 20,
        enableSorting: false,
        cell: ({ row }) =>
          !row.original.canView ? (
            <span title="Details zijn niet toegankelijk voor u.">
              <LockAltIcon />
            </span>
          ) : (
            <ActionMenu
              defaultShown={false} // temp-fix to get ActionMenu working
              label="Acties"
              popoverOptions={{
                strategy: 'fixed',
              }}
              popoverPlacement="bottom-end"
            >
              <MenuItem
                isDisabled={!row.original.canEdit}
                item={{
                  label: 'Activiteiten log',
                }}
                onClick={() =>
                  navigate(`${Dossier.createLastVisitPhaseUrl(row.original.id)}/audit`)
                }
              />
              {!row.original.isArchived ? (
                <MenuItem
                  isDisabled={!row.original.canEdit}
                  item={{
                    label: 'Archiveren',
                  }}
                  onClick={() =>
                    archiveToggleDossier.mutate({
                      dossierId: row.original.id,
                      isArchived: row.original.isArchived,
                    })
                  }
                />
              ) : (
                <></>
              )}
              <MenuItem
                item={{
                  label: 'Bekijken',
                }}
                onClick={() => navigate(Dossier.createLastVisitPhaseUrl(row.original.id))}
              />
              <MenuItem
                isDisabled={!row.original.canView}
                item={{
                  label: 'Bewerken',
                }}
                onClick={() => navigate(`/dossiers/edit/${row.original.id}`)}
              />
              <MenuItem
                isDisabled={!row.original.canDuplicate}
                item={{
                  label: 'Dupliceren',
                }}
                onClick={() => navigate(`/dossiers/duplicate/${row.original.id}`)}
              />
              {row.original.isArchived ? (
                <MenuItem
                  isDisabled={!row.original.canEdit}
                  item={{
                    label: 'Heropenen',
                  }}
                  onClick={() =>
                    archiveToggleDossier.mutate({
                      dossierId: row.original.id,
                      isArchived: row.original.isArchived,
                    })
                  }
                />
              ) : (
                <></>
              )}
              <MenuItem
                isDisabled={!row.original.canDelete}
                item={{
                  label: 'Mail naar dossier',
                }}
                onClick={() => {
                  const url =
                    view === 'dossiers'
                      ? `/dossiers/${tab}/${row.original.id}/mailto`
                      : `${Dossier.createLastVisitPhaseUrl(row.original.id)}/mailto`;
                  navigate(url);
                }}
              />
              <MenuItem
                isDisabled={!row.original.canDelete}
                item={{
                  label: 'Verwijderen',
                }}
                onClick={() => {
                  setDeleteErrorMessage(undefined);
                  setDeleteContext(row.original);
                  setIsDeleteConfirmOpen(true);
                }}
              />
            </ActionMenu>
          ),
      },
    ],
    [navigate, archiveToggleDossier, view, tab],
  );

  const columns: TableColumns<DossierTableItem> = useMemo(() => {
    const fieldsToFilter = columnViewSettings[view];
    // @ts-ignore
    return usableColumns.filter((column) =>
      column.id
        ? // @ts-ignore
          fieldsToFilter.includes(column.id)
        : false,
    );
  }, [view, usableColumns]);

  return (
    <>
      <TableProvider
        key={`dossier-table-view-${tab}-${view}-${defaultPageSize}`} // to make sure it respects the default page size.
        columns={columns}
        data={data}
        isLoading={isLoading}
        totalCount={totalCount}
        {...tableStateProps}
      >
        <TableCommandBar
          buttons={[
            hasNewButton ? (
              <Button
                key="new_button"
                iconLeft={<PlusIcon />}
                isDisabled={isDisabled || isLoading}
                onClick={onNewButtonClick}
              >
                Nieuw dossier
              </Button>
            ) : (
              <span key="temp_fix" />
            ),
          ]}
        >
          {commandBarContent}
        </TableCommandBar>
        <AlignedTable>
          <Table
            emptyStateProps={{
              title: 'Geen resultaten',
              description: 'Er zijn geen dossiers gevonden.',
            }}
            width="100%"
          />
        </AlignedTable>
      </TableProvider>
      <ConfirmDialog
        cancelTitle="Nee"
        confirmAppearance="danger"
        confirmTitle="Ja, verwijder"
        isDisabled={isLoading}
        isShown={isDeleteConfirmOpen}
        size="m"
        title="Verwijder dossier"
        onCancel={() => {
          setIsDeleteConfirmOpen(false);
          setDeleteContext(undefined);
        }}
        onConfirm={() => {
          if (deleteContext) {
            deleteDossier.mutate(deleteContext.id);
          }
        }}
      >
        <StatusMessage isShown={!!deleteErrorMessage} spaceAfter={4} type="danger">
          {deleteErrorMessage}
        </StatusMessage>
        Weet u zeker dat u het dossier <strong>{deleteContext?.name}</strong> wil verwijderen?
      </ConfirmDialog>
    </>
  );
};

export default DossierTableView;
