import dayjs from 'dayjs';

// We do not allow dates before 1-1-1800
export const minDate = new Date(-5364662400000);
export const isEmptyDate = (date: Date | null | undefined) =>
  !date || dayjs(date).isBefore(dayjs(minDate));

// Converts
// 2021-10-15T00:00:00Z (UTC), which is the same as
// 2021-10-14T20:00:00-04:00 (Local timezone)
// to
// 2021-10-15T00:00:00-04:00 (Local timezone)
// effectively overwriting the timezone from UTC to the local timezone without changing the time.
const utcToLocal = (date: Date): Date => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds(),
  );
};

// Converts
// 2021-10-15T00:00:00-04:00 (Local timezone)
// to
// 2021-10-15T00:00:00Z (UTC), which is the same as
// 2021-10-14T20:00:00-04:00 (Local timezone)
// effectively overwriting the timezone from the local timezone to UTC without changing the time.
export const localToUtc = (date: Date | null): Date => {
  if (!date) date = new Date();
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    ),
  );
};

export const dateTransform = {
  // All dates are stored as timestamps in UTC with time 00:00:00 (midnight).
  // When these timestamps get loaded into Javascript (`new Date(timestamp)`),
  // they automatically change to the local timezone.
  // Since the datepicker assumes local timezone dates we need to convert a
  // UTC date that is at midnight to the exact same date and time but with
  // the local timezone.
  input: (date?: Date | null) => {
    if (isEmptyDate(date) || !date || date.toString() === 'Invalid Date') {
      return;
    }
    return utcToLocal(date);
  },
  // Convert back to a UTC date.
  output: (date: Date | null) => {
    if (!date || date.toString() === 'Invalid Date') {
      return;
    }
    return localToUtc(date);
  },
};
