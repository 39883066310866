import Actions from 'actions/Actions';
import Client from 'models/crm/Client';
import ClientUser from 'models/crm/ClientUser';
import { createHook, createStore } from 'react-sweet-state';
import sweetFetchState from 'stores/utils/SweetFetchState';
import sweetPaging from 'stores/utils/SweetPagingState';

// #region all states that hold data
export const STATE_CLIENTS = 'clients';
export const STATE_CLIENTS_SELECT = 'clientsSelect';
export const STATE_CLIENTS_CREATE = 'clientsCreate';
export const STATE_CLIENTS_UPDATE = 'clientsUpdate';
export const STATE_CLIENTS_DELETE = 'clientsDelete';
export const STATE_CLIENTS_SET_PERMISSIONS = 'clientsSetPermissions';
export const STATE_CLIENT_USERS = 'clientUsers';
export const STATE_CLIENT_USERS_SELECT = 'clientUsersSelect';
export const STATE_CLIENT_USERS_CREATE = 'clientUsersCreate';
export const STATE_CLIENT_USERS_UPDATE = 'clientUsersUpdate';
export const STATE_CLIENT_USERS_DELETE = 'clientUsersDelete';
// #endregion

const Store = createStore({
  name: 'CrmState',
  initialState: {
    [STATE_CLIENTS]: sweetPaging.init(),
    [STATE_CLIENTS_SELECT]: sweetFetchState.init(),
    [STATE_CLIENTS_CREATE]: sweetFetchState.init(),
    [STATE_CLIENTS_UPDATE]: sweetFetchState.init(),
    [STATE_CLIENTS_DELETE]: sweetFetchState.init(),
    [STATE_CLIENTS_SET_PERMISSIONS]: sweetFetchState.init(),
    [STATE_CLIENT_USERS]: sweetPaging.init(),
    [STATE_CLIENT_USERS_SELECT]: sweetFetchState.init(),
    [STATE_CLIENT_USERS_CREATE]: sweetFetchState.init(),
    [STATE_CLIENT_USERS_UPDATE]: sweetFetchState.init(),
    [STATE_CLIENT_USERS_DELETE]: sweetFetchState.init(),
  },
  actions: {
    /**
     * Reset a fetch state
     */
    resetFetchState: sweetFetchState.reset,

    /**
     * An overview of all clients stored with paging and filtering.
     *
     * @param {boolean} shouldReset Reset the current stored clients, filtering and paging.
     * @param {array} filter Array of filters to apply on the request.
     * @param {string} sort Name of the field to sort on.
     * @returns {function({getState: *, setState: *, dispatch?: *}): (undefined)}
     */
    getClientsOverview:
      (filter, sort, shouldReset = true) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy, getPaging } = sweetPaging.state(
          STATE_CLIENTS,
          (response) => Client.createInstancesByArray(response.data[STATE_CLIENTS]),
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        const nextPage = shouldReset ? 1 : getPaging().getNextPage();
        doRequest(Actions.getAPIService().getCrm().clients(filter, sort, nextPage), shouldReset);
      },

    /**
     * Extended details of the given client identity.
     *
     * @param {string} clientId Identity of the client to fetch the extended details of.
     * @return {function({getState: *, setState: *}): (undefined)}
     */
    getClientDetails:
      (clientId) =>
      ({ getState, dispatch }) => {
        return new Promise((resolve, reject) => {
          const { doRequest, isBusy, setContext } = sweetFetchState.withContextId(
            STATE_CLIENTS_SELECT,
            clientId,
            dispatch,
            getState,
          );

          if (isBusy()) {
            return;
          }

          doRequest(Actions.getAPIService().getCrm().getClientDetails(clientId)).then(
            (response) => {
              if (response.isSuccess()) {
                const client = sweetPaging.getItem(clientId, getState()[STATE_CLIENTS]) || {};
                const newClient = Client.createInstance({
                  ...client,
                  ...response.data,
                });
                setContext(newClient);
                dispatch(sweetPaging.addItem(STATE_CLIENTS, newClient));
                resolve(newClient);
              }
            },
            reject,
          );
        });
      },

    /**
     * Create a new client with the given properties. These properties match the Client model.
     *
     * @param {object} client Properties to add to the client.
     * @return {function({getState: *, setState: *}): (undefined)}
     */
    createClient:
      (client) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy, setContext } = sweetFetchState.state(
          STATE_CLIENTS_CREATE,
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(Actions.getAPIService().getCrm().createClient(client)).then(
          (response) => {
            if (response.isSuccess()) {
              const newClient = Client.createInstance(response.data);
              setContext(newClient);
              dispatch(sweetPaging.addItem(STATE_CLIENTS, newClient));
            }
          },
          () => null,
        );
      },

    /**
     * Reset the last created client details.
     *
     * @return {function({getState: *, setState: *}): (undefined)}
     */
    resetCreateClient:
      () =>
      ({ getState, dispatch }) => {
        const { isBusy } = sweetFetchState.state(STATE_CLIENTS_CREATE, dispatch, getState);

        if (isBusy()) {
          return;
        }

        dispatch(sweetFetchState.reset(STATE_CLIENTS_CREATE));
      },

    /**
     * Update the client properties of the given client.
     *
     * @param {string|number} clientId Identity of the client that we need to update.
     * @param {object} client Client properties that match the Client model.
     * @return {function({getState: *, setState: *}): (undefined)}
     */
    updateClient:
      (clientId, client) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy, setContext } = sweetFetchState.withContextId(
          STATE_CLIENTS_UPDATE,
          clientId,
          dispatch,
          getState,
        );
        const { setContext: setContextSelect } = sweetFetchState.withContextId(
          STATE_CLIENTS_SELECT,
          clientId,
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(Actions.getAPIService().getCrm().updateClient(clientId, client)).then(
          (response) => {
            if (response.isSuccess()) {
              const nextClient = Client.createInstance(response.data);
              setContext(nextClient);
              setContextSelect(nextClient);
              dispatch(sweetPaging.addItem(STATE_CLIENTS, nextClient));
            }
          },
          () => null,
        );
      },

    /**
     * Update permissions of a given client.
     *
     * @param {string} clientId
     * @param {array} teams
     * @returns {function({getState?: *, dispatch?: *}): undefined}
     */
    updateClientPermissions:
      (clientId, teams) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy, setContext } = sweetFetchState.withContextId(
          STATE_CLIENTS_SET_PERMISSIONS,
          clientId,
          dispatch,
          getState,
        );
        const { setContext: setContextSelect } = sweetFetchState.withContextId(
          STATE_CLIENTS_SELECT,
          clientId,
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(Actions.getAPIService().getCrm().clientSetPermissions(clientId, { teams })).then(
          (response) => {
            if (response.isSuccess()) {
              const nextClient = Client.createInstance(response.data);
              setContext(nextClient);
              setContextSelect(nextClient);
              dispatch(sweetPaging.addItem(STATE_CLIENTS, nextClient));
            }
          },
          () => null,
        );
      },

    /**
     * Reset the last updated client details of the given client.
     *
     * @param {string} clientId Identity to reset the last update state of.
     * @return {function({getState: *, setState: *}): (undefined)}
     */
    resetUpdateClient:
      (clientId) =>
      ({ getState, dispatch }) => {
        const { isBusy } = sweetFetchState.withContextId(
          STATE_CLIENTS_UPDATE,
          clientId,
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        dispatch(sweetFetchState.reset(STATE_CLIENTS_UPDATE, clientId));
      },

    /**
     * Delete an existing client entry based on the identity.
     *
     * @param {string} clientId
     * @returns {function({getState: *, setState: *})}
     */
    deleteClient:
      (clientId) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy } = sweetFetchState.withContextId(
          STATE_CLIENTS_DELETE,
          clientId,
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(Actions.getAPIService().getCrm().deleteClient(clientId)).then(
          () => {
            dispatch(sweetPaging.removeItem(STATE_CLIENTS, clientId));
          },
          () => null,
        );
      },

    /**
     * An overview of all client users stored with paging and filtering.
     *
     * @param {array} filter Array of filters to apply on the request.
     * @param {string} sort Name of the field to sort on.
     * @param {boolean} shouldReset Reset the current stored client users, filtering and paging.
     * @returns {function({getState: *, setState: *, dispatch?: *}): (undefined)}
     */
    getClientUsersOverview:
      (filter, sort, shouldReset) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy, getPaging } = sweetPaging.state(
          STATE_CLIENT_USERS,
          (response) => ClientUser.createInstancesByArray(response.data[STATE_CLIENT_USERS]),
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        const nextPage = shouldReset ? 1 : getPaging().getNextPage();
        doRequest(
          Actions.getAPIService().getCrm().clientUsers(filter, sort, nextPage),
          shouldReset,
        );
      },

    /**
     * Extended details of a given client user.
     *
     * @param {string} clientUserId Identity of the client user.
     * @return {function({getState: *, setState: *}): (undefined)}
     */
    getClientUserDetails:
      (clientUserId) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy, setContext } = sweetFetchState.withContextId(
          STATE_CLIENT_USERS_SELECT,
          clientUserId,
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(Actions.getAPIService().getCrm().getClientUserDetails(clientUserId)).then(
          (response) => {
            if (response.isSuccess()) {
              const clientUser =
                sweetPaging.getItem(clientUserId, getState()[STATE_CLIENT_USERS]) || {};
              const newClientUser = ClientUser.createInstance({
                ...clientUser,
                ...response.data,
              });
              setContext(newClientUser);
              dispatch(sweetPaging.addItem(STATE_CLIENT_USERS, newClientUser));
            }
          },
          () => null,
        );
      },

    /**
     * Create a new client user with the given properties.
     *
     * @param {object} clientUser Properties object that matches the ClientUser model.
     * @return {function({getState: *, setState: *}): (undefined)}
     */
    createClientUser:
      (clientUser) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy, setContext } = sweetFetchState.state(
          STATE_CLIENT_USERS_CREATE,
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(Actions.getAPIService().getCrm().createClientUser(clientUser)).then(
          (response) => {
            if (response.isSuccess()) {
              const newClientUser = ClientUser.createInstance(response.data);
              setContext(newClientUser);
              dispatch(sweetPaging.addItem(STATE_CLIENT_USERS, newClientUser));
            }
          },
          () => null,
        );
      },

    /**
     * Delete an existing client user entry based on the identity.
     *
     * @param {string} clientUserId Client user identity to delete.
     * @returns {function({getState: *, setState: *})}
     */
    deleteClientUser:
      (clientUserId) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy } = sweetFetchState.withContextId(
          STATE_CLIENT_USERS_DELETE,
          clientUserId,
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(Actions.getAPIService().getCrm().deleteClientUser(clientUserId)).then(() => {
          dispatch(sweetPaging.removeItem(STATE_CLIENT_USERS, clientUserId));
        });
      },

    /**
     * Update an existing client user with the given properties.
     *
     * @param {string|number} clientUserId Identity of the client user.
     * @param {object} clientUser Properties to update for the given client user, based on the ClientUser model.
     * @return {function({getState: *, setState: *}): (undefined)}
     */
    updateClientUser:
      (clientUserId, clientUser) =>
      ({ getState, dispatch }) => {
        const { doRequest, isBusy, setContext } = sweetFetchState.withContextId(
          STATE_CLIENT_USERS_UPDATE,
          clientUserId,
          dispatch,
          getState,
        );
        const { setContext: setContextSelect } = sweetFetchState.withContextId(
          STATE_CLIENT_USERS_SELECT,
          clientUserId,
          dispatch,
          getState,
        );

        if (isBusy()) {
          return;
        }

        doRequest(Actions.getAPIService().getCrm().updateClientUser(clientUserId, clientUser)).then(
          (response) => {
            if (response.isSuccess()) {
              const nextClientUser = ClientUser.createInstance(response.data);
              setContext(nextClientUser);
              setContextSelect(nextClientUser);
              dispatch(sweetPaging.addItem(STATE_CLIENT_USERS, nextClientUser));
            }
          },
        );
      },

    /**
     * Reset the last created client user details.
     *
     * @return {function({getState: *, setState: *}): (undefined)}
     */
    resetCreateClientUser:
      () =>
      ({ getState, dispatch }) => {
        const { isBusy } = sweetFetchState.state(STATE_CLIENT_USERS_CREATE, dispatch, getState);

        if (isBusy()) {
          return;
        }

        dispatch(sweetFetchState.reset(STATE_CLIENT_USERS_CREATE));
      },

    /**
     * Reset the last updated details of the given client user identity.
     *
     * @param {string} clientUserId Identity of the client user.
     * @return {function({getState: *, setState: *}): (undefined)}
     */
    resetUpdateClientUser:
      (clientUserId) =>
      ({ getState, dispatch }) => {
        const { isBusy } = sweetFetchState.state(STATE_CLIENT_USERS_UPDATE, dispatch, getState);

        if (isBusy()) {
          return;
        }

        dispatch(sweetFetchState.reset(STATE_CLIENT_USERS_UPDATE, clientUserId));
      },
  },
});

export const useCrmState = createHook(Store);
