import type { Status, StatusGroup, Ticket } from 'entity/dossiers/types';
import { useProfileSettings } from 'hooks/useProfileSettings';
import { useCallback, useMemo } from 'react';

/**
 * Each ticket type has a list of statuses. This hook provides
 * a way to get the statuses for a specific ticket type, or
 * to get the first status from a status group. This is useful
 * when a ticket is moved into another status group.
 */
export const useTicketStatuses = () => {
  const [settings] = useProfileSettings();

  const ticketStatusMapping: Record<Ticket['type'], Status[]> = useMemo(
    () => ({
      dossier: settings.getLabels(),
      note_conversation: settings.getLabels(),
      note_email: settings.getLabels(),
      note_phone: settings.getLabels(),
      task: settings.getLabels(),
      subdossier_canvas: settings.getLabels(),
      subdossier_document: settings.getLabels(),
    }),
    [settings],
  );

  /**
   * Get the statuses for a specific ticket type. Can be used to prefill
   * select fields.
   */
  const getStatusesFor = useCallback(
    (type: Ticket['type'], statusGroupId?: StatusGroup['id']) => {
      if (!statusGroupId) return ticketStatusMapping[type];
      return ticketStatusMapping[type].filter((status) => status.statusGroup?.id === statusGroupId);
    },
    [ticketStatusMapping],
  );

  /**
   * Returns the first status from a status group.
   */
  const getFirstStatusFromStatusGroup = useCallback(
    (statusGroupId: StatusGroup['id']) =>
      Object.values(ticketStatusMapping)
        .flat()
        .find((status) => status.statusGroup?.id === statusGroupId),
    [ticketStatusMapping],
  );

  return {
    getStatusesFor,
    getFirstStatusFromStatusGroup,
  };
};
