import './assets/styles/MentionStyles.scss';
import './assets/styles/RichTextEditor.scss';

import Editor from '@draft-js-plugins/editor';
import type { MentionData } from '@draft-js-plugins/mention';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention';
import classNames from 'classnames';
import Button from 'components/button/Button';
import EditorBoldIcon from 'components/icons/EditorBold';
import EditorItalicIcon from 'components/icons/EditorItalic';
import EditorListOlIcon from 'components/icons/EditorList-ol';
import EditorListUlIcon from 'components/icons/EditorList-ul';
import type {
  DraftHandleValue,
  DraftInlineStyle,
  EditorCommand,
  RawDraftContentState,
} from 'draft-js';
import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
  Modifier,
  RichUtils,
  SelectionState,
} from 'draft-js';
import { mentionRegExp, searchUserSuggestionById } from 'helpers/mentionhelper';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

type EditorTypeStyle = {
  name: string;
  type: string;
  icon: React.ReactElement;
};

type Props = {
  className?: string;
  isDisabled?: boolean;
  content?: string;
  // context?: Object;
  placeholder?: string;
  isFocused?: boolean;
  hasEditorSpellCheck?: boolean;
  isEditorSubmitHidden?: boolean;
  editorStyles?: EditorTypeStyle[];
  editorTypes?: EditorTypeStyle[];
  extraToolbarButtons?: React.ReactNode;
  isToolbarShownOnFocus?: boolean;
  postButtonTitle?: string;
  contextItem?: React.ReactElement;
  userSuggestions?: MentionData[];
  onChange?: (content: string) => void;
  onMentionAdded?: (mention: MentionData) => void;
  onPost?: (content?: string, contentState?: RawDraftContentState) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onEditorStateChange?: (content?: string) => void;
};

const mentionStyles = {
  mention: 'mention',
  mentionSuggestions: 'mentionSuggestions',
  mentionSuggestionsEntryContainer: 'mentionSuggestionsEntryContainer',
  mentionSuggestionsEntryContainerLeft: 'mentionSuggestionsEntryContainerLeft',
  mentionSuggestionsEntryContainerRight: 'mentionSuggestionsEntryContainerRight',
  mentionSuggestionsEntry: 'mentionSuggestionsEntry',
  mentionSuggestionsEntryFocused: 'mentionSuggestionsEntryFocused',
  mentionSuggestionsEntryText: 'mentionSuggestionsEntryText',
  mentionSuggestionsEntryTitle: 'mentionSuggestionsEntryTitle',
  mentionSuggestionsEntryAvatar: 'mentionSuggestionsEntryAvatar',
};

// region markdown settings
/**
 * Draft to markdown settings
 *
 * @type {Object}
 */
const draftToMarkdownSettings = {
  preserveNewlines: true,
  entityItems: {
    mention: {
      open: (entity: any) => {
        return `@${entity.data.mention.id}<`;
      },
      close: () => {
        return '>';
      },
    },
  },
};

/**
 * Markdown to draft settings
 *
 * @type {{preserveNewlines: boolean}}
 */
const markdownToDraftSettings = {
  preserveNewlines: true,
};
// endregion

/**
 * Parse markdown with mentions
 *
 * @param {string} content
 * @param {array} userSuggestions
 * @returns {ContentState|*}
 */
const parseMarkDown = (content: string, userSuggestions: any[]) => {
  // fix email address in mentions
  const fixedContent = content.replace(/@([^\s]+)<([^\s]+)@([^\s]+)>/g, '@$1<$2\\@$3>');

  // create editor state
  let contentState = convertFromRaw(markdownToDraft(fixedContent, markdownToDraftSettings));

  // check if mentions are in the content. If not we do not do any mention parsing.
  if (userSuggestions.length === 0 || content.match(mentionRegExp) === null) {
    return contentState;
  }
  const mentionsSelections: any[] = [];
  const blockMap = contentState.getBlockMap();
  blockMap.forEach((contentBlock) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (contentBlock) {
      const blockKey = contentBlock.getKey();
      let blockContent = contentBlock.getText();

      let matchResult = null;
      // @ts-ignore
      while ((matchResult = blockContent.match(mentionRegExp)) !== null) {
        const tagText = matchResult[0]; // full match
        // @ts-ignore
        const tagLength = matchResult[0].length;
        // @ts-ignore
        const tagStart = matchResult.index ?? 0;
        // @ts-ignore
        const userId = matchResult.groups?.id ?? '';
        // @ts-ignore
        const mentionName = matchResult.groups?.name ?? '';

        // search mention Id in the userSuggestions array and get the object data of it.
        const userMentionData = searchUserSuggestionById(userSuggestions, userId);
        // create entity of data for in the editor.
        let mentionEntityKey;
        if (userMentionData) {
          contentState = contentState.createEntity('mention', 'IMMUTABLE', {
            mention: userMentionData,
          });
          mentionEntityKey = contentState.getLastCreatedEntityKey();
        }

        // create selection state.
        const blockSelectionState = SelectionState.createEmpty(blockKey).merge({
          anchorOffset: tagStart,
          focusOffset: tagStart + tagLength,
        });

        mentionsSelections.push({
          blockSelectionState,
          mentionName,
          mentionEntityKey,
        });

        // get new plain text
        blockContent = blockContent.replace(tagText, mentionName);
      }
    }
  });

  mentionsSelections.forEach(({ blockSelectionState, mentionName, mentionEntityKey }) => {
    contentState = Modifier.replaceText(
      contentState,
      blockSelectionState,
      mentionName,
      undefined,
      mentionEntityKey,
    );
  });

  return contentState;
};

/**
 * Create a editor state
 * @param {string} content
 * @param {array} userSuggestions
 * @returns {EditorState}
 */
const createEditorState = (content: string, userSuggestions: any[]) => {
  // check if context or content is filled.
  if (content) {
    return EditorState.createWithContent(createContentState(content, userSuggestions));
  }
  // else we need to create a empty state.
  return EditorState.createEmpty();
};

/**
 * Create content state
 *
 * @param content
 * @param userSuggestions
 * @returns {ContentState|*}
 */
const createContentState = (content: string, userSuggestions: any[]) => {
  // use content that is markdown initial content
  if (content) {
    return parseMarkDown(content, userSuggestions);
  }
  return ContentState.createFromText('');
};

/**
 * Rich text editor
 *
 * @param {string} className
 * @param {boolean} disabled
 * @param {string} editorContent
 * @param {array} userSuggestions
 * @param {array} editorStyles
 * @param {array} editorTypes
 * @param {boolean} hasEditorSpellCheck
 * @param {boolean} isEditorSubmitHidden
 * @param {boolean} isFocused
 * @param {boolean} isToolbarShownOnFocus
 * @param {*} extraToolbarButtons
 * @param {*} contextItem
 * @param {string} placeholder
 * @param {string} postButtonTitle
 * @param {function} onMentionAdded
 * @param {function} onFocus
 * @param {function} onBlur
 * @param {function} onChange
 * @param {function} onPost
 * @returns {JSX.Element}
 * @constructor
 */
const RichTextEditor: React.FC<Props> = ({
  className,
  isDisabled,
  content: editorContent,
  userSuggestions,
  editorStyles,
  editorTypes,
  hasEditorSpellCheck,
  isEditorSubmitHidden,
  isFocused,
  isToolbarShownOnFocus,
  placeholder,
  postButtonTitle,
  extraToolbarButtons,
  contextItem,
  onMentionAdded,
  onChange,
  onFocus,
  onBlur,
  onPost,
  onEditorStateChange: onEditorStateChangeProp,
}) => {
  // region editor variables
  const editorRef = useRef<any>();
  const [editorState, setEditorState] = useState(() =>
    createEditorState(editorContent ?? '', userSuggestions ?? []),
  );
  const [remountEditor, setRemountEditor] = useState(1);
  const [currentStyle, setCurrentStyle] = useState<DraftInlineStyle | false>(false);
  const [currentBlockStyle, setCurrentBlockStyle] = useState<string | false>(false);
  const [isEditorFocussed, setIsEditorFocussed] = useState(isFocused);
  const [content, setContent] = useState<string | undefined>();
  const hasText = content && content.length > 0;
  // endregion

  // region mention variables
  const [isMentionOpen, setIsMentionOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(userSuggestions);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      theme: mentionStyles,
      supportWhitespace: true,
      entityMutability: 'IMMUTABLE',
      mentionRegExp: '[\\w\\.]',
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin];

    return {
      plugins,
      MentionSuggestions,
    };
  }, []);

  /**
   * Handle opening mention
   *
   * @param {boolean} _open
   */
  const onSuggestionsOpen = useCallback((_open: any) => setIsMentionOpen(_open), []);

  /**
   * Handle filtering of suggestions
   */
  const onSuggestionSearchChange = useCallback(
    ({ value: suggestionValue }: { value: any }) => {
      setSuggestions(defaultSuggestionsFilter(suggestionValue, userSuggestions ?? []));
    },
    [userSuggestions],
  );
  // endregion

  // region editor functions
  /**
   * Editor state change
   *
   * @param {EditorState} state
   */
  const onEditorStateChange = (state: EditorState | null) => {
    if (state) {
      // determine what type we are in, (so we know if we are in a ul list for example) and we can use this
      // to highlight menu options.
      const selection = state.getSelection();
      const currentBlockType = state
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
      const rawContentState = convertToRaw(state.getCurrentContent());
      const newContent = draftToMarkdown(rawContentState, draftToMarkdownSettings);

      setCurrentStyle(state.getCurrentInlineStyle());
      setCurrentBlockStyle(currentBlockType);
      setContent(newContent);
      setEditorState(state);

      // check if there are still decorators in the state. If not we will update remountEditor by adding 1.
      // this forces React to remount the component en give back the decorators.
      // the situation happens because race-condition with the editor focus and onChange focus happening.
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (state.getDecorator() === null) {
        const newValue = remountEditor + 1;
        setRemountEditor(newValue);
      }

      onEditorStateChangeProp?.(newContent);
    }
  };

  /**
   * Change editor style
   *
   * @param {MouseEvent} event
   * @param {string} styleType
   */
  const changeEditorStyle = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    styleType: string,
  ) => {
    event.preventDefault();
    onEditorStateChange(RichUtils.toggleInlineStyle(editorState, styleType));
  };

  /**
   * Change editor block style
   *
   * @param {MouseEvent} event
   * @param {string} blockType
   */
  const changeEditorBlockType = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    blockType: string,
  ) => {
    event.preventDefault();
    onEditorStateChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  /**
   * Handle editor key command
   *
   * @param {string} command
   * @param {object} newState
   */
  const handleEditorKeyCommand = (
    command: EditorCommand,
    newState: EditorState,
  ): DraftHandleValue => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (newState) {
      onEditorStateChange(RichUtils.handleKeyCommand(newState, command));
    }

    return 'not-handled';
  };
  // endregion

  // region handle changing.
  /**
   * Handle on content change when the editor is blurred.
   */
  const handleOnChange = () => {
    if (content !== editorContent) {
      onChange?.(content ?? '');
    }

    onBlur?.();
    setIsEditorFocussed(false);
  };

  /**
   * Update editor state when editorContent is updated.
   */
  const updateEditorStateFromProps = () => {
    const contentState = createContentState(editorContent ?? '', userSuggestions ?? []);
    // only update if the text are changed.
    const newContent = draftToMarkdown(convertToRaw(contentState), draftToMarkdownSettings);
    const oldContent = draftToMarkdown(
      convertToRaw(editorState.getCurrentContent()),
      draftToMarkdownSettings,
    );
    if (newContent !== oldContent) {
      const newEditorState = EditorState.push(editorState, contentState, 'insert-characters');
      onEditorStateChange(newEditorState);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateEditorStateFromProps, [editorContent]);

  const focusEffect = () => {
    if (editorRef.current) {
      if (isFocused) {
        setTimeout(() => {
          if (editorRef.current) {
            editorRef.current.focus();
            onFocus?.();
          }
        }, 250);
      } else {
        setTimeout(() => {
          if (editorRef.current) {
            editorRef.current.blur();
            onBlur?.();
          }
        }, 250);
      }
    }
  };
  // eslint-disable-next-line
  useEffect(focusEffect, [isFocused]);

  // detect show toolbar focus handling
  const hasToolbar = isToolbarShownOnFocus ? isEditorFocussed || isFocused : true;
  // endregion

  return (
    <div
      className="c-rich-text-editor"
      role="presentation"
      onClick={() => {
        if (editorRef.current) {
          editorRef.current.focus();
        }
      }}
    >
      <div className={classNames('c-rich-text-editor__editor', className)}>
        <Editor
          key={`editor${remountEditor}`}
          ref={editorRef}
          editorState={editorState}
          handleKeyCommand={handleEditorKeyCommand}
          placeholder={placeholder}
          plugins={plugins}
          readOnly={isDisabled}
          spellCheck={hasEditorSpellCheck}
          onBlur={() => handleOnChange()}
          onChange={onEditorStateChange}
          onFocus={() => {
            setIsEditorFocussed(true);
            onFocus?.();
          }}
        />
        <MentionSuggestions
          open={isMentionOpen}
          suggestions={suggestions ?? []}
          onAddMention={(mention) => {
            onMentionAdded?.(mention);
          }}
          onOpenChange={onSuggestionsOpen}
          onSearchChange={onSuggestionSearchChange}
        />
      </div>
      {contextItem && <div className="c-rich-text-editor__context-item">{contextItem}</div>}
      {hasToolbar && (
        <div
          className="c-rich-text-editor__toolbar"
          role="toolbar"
          onMouseDown={(event) => event.preventDefault()}
        >
          {editorStyles?.map((editorStyle) => (
            <button
              key={`editorStyle${editorStyle.type}`}
              className={classNames('c-rich-text-editor__toolbar__button', {
                'c-rich-text-editor__toolbar__button--active':
                  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
                  currentStyle && currentStyle.has(editorStyle.type),
              })}
              disabled={isDisabled}
              type="button"
              onMouseDown={(event) => changeEditorStyle(event, editorStyle.type)}
            >
              {editorStyle.icon}
            </button>
          ))}
          <div className="c-rich-text-editor__toolbar__separator" />
          {editorTypes?.map((editorType) => (
            <button
              key={`editorType${editorType.type}`}
              className={classNames('c-rich-text-editor__toolbar__button', {
                'c-rich-text-editor__toolbar__button--active':
                  currentBlockStyle === editorType.type,
              })}
              disabled={isDisabled}
              type="button"
              onMouseDown={(event) => changeEditorBlockType(event, editorType.type)}
            >
              {editorType.icon}
            </button>
          ))}
          {extraToolbarButtons && (
            <>
              <div className="c-rich-text-editor__toolbar__separator" />
              {extraToolbarButtons}
            </>
          )}
          {onPost && isEditorSubmitHidden !== true && (
            <>
              <div className="c-rich-text-editor__toolbar__fill" />
              <div className="c-rich-text-editor__toolbar__post-button">
                <Button
                  className="c-rich-text-editor__toolbar__post-button--button"
                  isDisabled={isDisabled || !hasText}
                  onMouseDown={(event) => {
                    event.preventDefault();
                    if (!isDisabled || !hasText) {
                      onPost(content, convertToRaw(editorState.getCurrentContent()));
                    }
                  }}
                >
                  {postButtonTitle}
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

RichTextEditor.defaultProps = {
  isDisabled: false,
  userSuggestions: [],
  hasEditorSpellCheck: true,
  isToolbarShownOnFocus: false,
  editorStyles: [
    {
      name: 'bold',
      type: 'BOLD',
      icon: <EditorBoldIcon />,
    },
    {
      name: 'italic',
      type: 'ITALIC',
      icon: <EditorItalicIcon />,
    },
    // {name: 'underline', type: 'UNDERLINE', icon: <EditorUnderlineIcon/>},
  ],
  editorTypes: [
    {
      name: 'ul-list',
      type: 'unordered-list-item',
      icon: <EditorListUlIcon />,
    },
    {
      name: 'ol-list',
      type: 'ordered-list-item',
      icon: <EditorListOlIcon />,
    },
  ],
  onChange: () => null,
  onFocus: () => null,
  onBlur: () => null,
  onMentionAdded: () => null,
  postButtonTitle: 'Opslaan',
};

export default RichTextEditor;
