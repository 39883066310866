import './assets/styles/CheckBox.scss';

import classNames from 'classnames';
import React from 'react';

export enum CheckboxColorTypes {
  PRIMARY_BLUE = 'c-checkbox__color-primary-blue',
  SECONDARY_GREEN = 'c-checkbox__color-secondary-green',
}

type Props = {
  className?: string;
  color?: CheckboxColorTypes;
  isChecked?: boolean;
  isDisabled?: boolean;
  isCheckmarkShown?: boolean;
  onChange: (checked: boolean) => void;
};

/**
 * Checkbox
 *
 * @param {boolean} isChecked
 * @param {boolean} isDisabled
 * @param {string} className
 * @param {string} color
 * @param {boolean} isCheckmarkShown
 * @param {function} onChange
 * @param {object} otherProps
 * @returns {*}
 * @constructor
 */
const CheckBox: React.FC<Props> = ({
  isChecked,
  isDisabled,
  className,
  color,
  isCheckmarkShown,
  onChange,
  ...otherProps
}) => (
  <label
    className={classNames('c-checkbox', className, color, {
      'c-checkbox--always-show-check-mark': isCheckmarkShown,
    })}
  >
    <input
      {...otherProps}
      checked={isChecked}
      disabled={isDisabled}
      type="checkbox"
      onChange={() => {
        onChange(!isChecked);
      }}
    />
    <span className="c-checkbox__span" />
  </label>
);

CheckBox.defaultProps = {
  isDisabled: false,
  isChecked: false,
  isCheckmarkShown: false,
  color: CheckboxColorTypes.SECONDARY_GREEN,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: () => {},
};

export default CheckBox;
