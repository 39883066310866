import './assets/styles/CardSectionTaskList.scss';

import { ActionMenu, EmptyState, Flex, Link, MenuItem, PlusIcon } from '@elseu/sdu-titan';
import { useMutation, useQuery } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import ConfirmDialog from 'components/confirmdialog/ConfirmDialog';
import InputFormDrawer from 'components/formdrawer/InputFormDrawer';
import { Lazy } from 'components/lazy/Lazy';
import type { TaskListViewItem } from 'components/task/TaskListDnd';
import TaskListDnd, { convertTaskListToTaskListViewItem } from 'components/task/TaskListDnd';
import TaskListItemEdit from 'components/task/TaskListItemEdit';
import { isUserLinkedToDossier } from 'containers/dossiers/src/helpers/dossierHelper';
import type { Task, TaskList } from 'entity/dossiers/types';
import { normalizeTemplateTaskList } from 'entity/templatetasklist/normalizer';
import type { TemplateTaskList } from 'entity/templatetasklist/types';
import { normalizeMap } from 'helpers/normalizehelper';
import { itemDetermineNewPosition } from 'helpers/taskListHelper';
import { useInvalidateCardCache } from 'hooks/invalidate/useInvalidateCardCache';
import { useInvalidateDossierCache } from 'hooks/invalidate/useInvalidateDossierCache';
import { useInvalidateSignalCache } from 'hooks/invalidate/useInvalidateSignalCache';
import { useInvalidateTaskCache } from 'hooks/invalidate/useInvalidateTaskCache';
import omit from 'lodash/omit';
import moment from 'moment';
import React, { lazy, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import CardSection from './CardSection';

// eslint-disable-next-line max-len
const DossierTaskCardDetailsContainer = lazy(
  () => import('../../containers/dossiers/src/DossiersTaskCardDetailsContainer'),
);

type Props = {
  dossierId: string;
  cardId: string | undefined;
  taskLists: TaskList[];
  isReadOnly?: boolean;
  isDisabled?: boolean;
  hasToConfirmUserLink?: boolean;
  users: any[];
  dossierUsers: string[];
  dossierTypeTitleText?: string;
  dossierTypeMessageText?: string;
  newTaskListNamePrefill?: string;
  onBusy?: (isBusy: boolean) => void;
  onUpdate: (type: string) => void;
  onTaskItemClick: (task: Task) => void;
};

/**
 * CardSectionTaskList
 */
const CardSectionTaskList: React.FC<Props> = ({
  dossierId,
  cardId,
  taskLists,
  users,
  dossierUsers,
  isDisabled,
  isReadOnly,
  newTaskListNamePrefill,
  dossierTypeTitleText,
  dossierTypeMessageText,
  hasToConfirmUserLink,
  onBusy = () => null,
  onUpdate,
  onTaskItemClick,
}) => {
  const [taskListState, setTaskListState] = useState<TaskList[]>(taskLists);
  const [showNewTaskListModal, setShowNewTaskListModal] = useState<boolean>(false);
  const [assignUserConfirm, setAssignUserConfirm] = useState<any | undefined>();
  const [showNewItemUnder, setShowNewItemUnder] = useState<string[]>([]);
  const invalidateCardCache = useInvalidateCardCache();
  const invaldiateDossierCache = useInvalidateDossierCache();
  const invalidateSignalingCache = useInvalidateSignalCache();
  const invalidateTaskCache = useInvalidateTaskCache();

  const templateTasksEnabled = cardId !== 'new' && !isReadOnly;
  const templateTasksResults = useQuery({
    queryKey: ['template-tasks', dossierId, cardId],
    queryFn: async () =>
      Actions.getAPIService()
        .templateTaskLists()
        .getTemplateTaskListsForCard(dossierId, cardId ?? '')
        .then((data) =>
          normalizeMap<TemplateTaskList>(
            data.getData().templateTaskLists,
            normalizeTemplateTaskList,
          ),
        ),
    enabled: templateTasksEnabled,
    refetchOnWindowFocus: false, // do not refetch when the window is focussed
    refetchOnReconnect: false, // do not refetch when the user reconnects to the network
    staleTime: 60 * 1000 * 10, // 10 minutes
  });

  const isPositionUpdate = useRef(false);

  const invalidateCardAndSendUpdate = useCallback(
    (update: string, invalidateDossier = false) => {
      if (invalidateDossier) {
        invaldiateDossierCache(dossierId, { clearCards: false });
        invalidateSignalingCache(dossierId);
      }
      invalidateCardCache({ dossierId, cardId });
      invalidateTaskCache(dossierId);
      onUpdate(update);
      isPositionUpdate.current = false;
    },
    [
      cardId,
      dossierId,
      invaldiateDossierCache,
      invalidateCardCache,
      invalidateSignalingCache,
      invalidateTaskCache,
      onUpdate,
    ],
  );

  // #region mutations
  // #region tasklists
  const createTaskListFromTemplateMutation = useMutation({
    mutationFn: (templateTaskList: TemplateTaskList) =>
      Actions.getAPIService()
        .templateTaskLists()
        .addTemplateTaskListsToCard(dossierId, cardId ?? '', templateTaskList.id),
    onSuccess: () => invalidateCardAndSendUpdate('tasklist:created'),
  });

  const createTaskListMutation = useMutation({
    mutationFn: (values: any) =>
      Actions.getAPIService().createCardTaskList(dossierId, cardId ?? '', values),
    onSuccess: (data) => {
      invalidateCardAndSendUpdate('tasklist:created');
      if (data?.data?.id) {
        setShowNewItemUnder((items) => [...items, data?.data?.id]);
      }
    },
  });

  const updateTaskListMutation = useMutation({
    mutationFn: (values: { id: string; [key: string]: any }) =>
      Actions.getAPIService().updateCardTaskList(
        dossierId,
        cardId ?? '',
        values.id,
        omit(values, ['id']),
      ),
    onSuccess: () => invalidateCardAndSendUpdate('tasklist:updated'),
  });

  const deleteTaskListMutation = useMutation({
    mutationFn: (taskList: TaskList) =>
      Actions.getAPIService().deleteCardTaskList(dossierId, cardId ?? '', taskList.id),
    onSuccess: () => invalidateCardAndSendUpdate('tasklist:deleted'),
  });

  // #endregion
  // #region tasks
  const createTaskMutation = useMutation({
    mutationFn: (values: { taskListId: string; [key: string]: any }) =>
      Actions.getAPIService().tasks().createTask(values),
    onSuccess: (_data, variables) => {
      invalidateCardAndSendUpdate('task:created', !!assignUserConfirm);
      setAssignUserConfirm(undefined);
      closeNewTask(variables.taskListId);
    },
  });

  const updateTaskMutation = useMutation({
    mutationFn: (values: { id: string; taskListId: string; [key: string]: any }) =>
      Actions.getAPIService()
        .tasks()
        .updateTask(values.id, omit(values, ['id', 'taskListId'])),
    onSuccess: (_data) => invalidateCardAndSendUpdate('task:updated'),
  });

  const deleteTaskMutation = useMutation({
    mutationFn: (task: Task) => Actions.getAPIService().tasks().deleteTask(task.id),
    onSuccess: () => invalidateCardAndSendUpdate('task:deleted'),
  });

  // #endregion
  // #endregion

  const createTaskList = useCallback(
    (value: string) => {
      let position = null;
      if (taskLists.length > 0) {
        // get first item position and set position
        position = taskLists[0].position;
      }
      const newPosition = itemDetermineNewPosition(null, position);

      createTaskListMutation.mutate({
        name: value,
        position: newPosition,
      });
      setShowNewTaskListModal(false);
    },
    [createTaskListMutation, taskLists],
  );

  const openCreateNewTasklist = useCallback(() => {
    setShowNewTaskListModal(true);
  }, []);

  const createNewTask = useCallback(
    (taskList: { id: any; tasks: string | any[] }, values: any) => {
      const assignedUser = values.assignedUser;
      const newValues: any = {
        ...values,
        taskListId: taskList.id,
        assignedUser: assignedUser?.id ? assignedUser.id : null,
      };

      // determine position
      let lastItemPosition = null;
      if (taskList.tasks.length > 0) {
        lastItemPosition = taskList.tasks[taskList.tasks.length - 1].position;
      }
      // if user was not yet linked to the dossier confirm this with the user.
      newValues.position = itemDetermineNewPosition(lastItemPosition, null);
      if (assignedUser?.id) {
        if (hasToConfirmUserLink && !isUserLinkedToDossier(dossierUsers, assignedUser.id)) {
          setAssignUserConfirm(newValues);
          return;
        }
      }
      createTaskMutation.mutate(newValues);
    },
    [dossierUsers, hasToConfirmUserLink, createTaskMutation],
  );

  const closeNewTask = useCallback((taskListId: string) => {
    setShowNewItemUnder((items) => items.filter((item) => item !== taskListId));
  }, []);

  const templateTaskMenuOptions = useMemo(() => {
    if (templateTasksResults.data) {
      return templateTasksResults.data.map((item) => ({
        label: item.name,
        onClick: () => createTaskListFromTemplateMutation.mutate(item),
      }));
    } else {
      return [];
    }
  }, [templateTasksResults.data, createTaskListFromTemplateMutation]);

  const tasklistsForDnd = useMemo(() => {
    return normalizeMap<TaskListViewItem>(taskListState, convertTaskListToTaskListViewItem);
  }, [taskListState]);

  const optionAssignedUsers = useMemo(
    () =>
      users.map((user) => ({
        value: user.id,
        label: user.fullName,
        email: user.email,
        image: user.avatarUrl,
        user,
      })),
    [users],
  );

  useEffect(() => {
    if (!isPositionUpdate.current) {
      setTaskListState(taskLists);
    }
  }, [taskLists]);

  // onBusy handling
  const isBusy =
    (templateTasksEnabled && templateTasksResults.isLoading) ||
    createTaskListFromTemplateMutation.isPending ||
    createTaskListMutation.isPending ||
    updateTaskListMutation.isPending ||
    createTaskMutation.isPending;

  useEffect(() => {
    onBusy(isBusy);
  }, [onBusy, isBusy]);

  return (
    <CardSection
      action={
        !isReadOnly && (
          <>
            {templateTaskMenuOptions.length > 0 ? (
              <ActionMenu
                defaultShown={false} // temp-fix to get ActionMenu working
                label="Nieuwe takenlijst"
                popoverPlacement="bottom-end"
                trigger={
                  <Link prefixAdornment={<PlusIcon />} onClick={openCreateNewTasklist}>
                    Nieuwe takenlijst
                  </Link>
                }
              >
                <>
                  <MenuItem
                    key="new"
                    item={{ label: 'Open takenlijst' }}
                    onClick={openCreateNewTasklist}
                  />
                  {(templateTasksResults.data ?? []).map((item) => (
                    <MenuItem
                      key={item.id}
                      item={{ label: item.name }}
                      onClick={() => createTaskListFromTemplateMutation.mutate(item)}
                    />
                  ))}
                </>
              </ActionMenu>
            ) : (
              <Link prefixAdornment={<PlusIcon />} onClick={openCreateNewTasklist}>
                Nieuwe takenlijst
              </Link>
            )}
          </>
        )
      }
      className="c-card-section-task-list"
      title="Taken"
    >
      {tasklistsForDnd.length === 0 && (
        <Flex justifyContent="center">
          <EmptyState isCentered description="Geen takenlijsten" title="" />
        </Flex>
      )}
      <TaskListDnd
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        isSortingDisabled={isReadOnly}
        newItemRenderer={(taskList: TaskList) => {
          return (
            <TaskListItemEdit
              isNameFocused
              isNew
              isDisabled={isDisabled || createTaskMutation.isPending}
              users={optionAssignedUsers}
              onCancel={() => closeNewTask(taskList.id)}
              onSave={(values) => createNewTask(taskList, values)}
            />
          );
        }}
        showNewItemUnder={showNewItemUnder}
        tasklists={tasklistsForDnd}
        onNewTask={(taskList) => {
          setShowNewItemUnder((items) =>
            !items.includes(taskList.id) ? [...items, taskList.id] : items,
          );
        }}
        onTaskCheckboxChange={(task, tasklist, checked) => {
          updateTaskMutation.mutate({
            id: task.id,
            taskListId: tasklist.id,
            completedDateTime: checked ? moment().format('YYYY[-]MM[-]DD[T]HH[:]mm[:]ssZ') : null,
          });
        }}
        onTaskClick={(task) => onTaskItemClick(task)}
        onTaskDelete={(task) => deleteTaskMutation.mutate(task)}
        onTasklistDelete={(taskList) => deleteTaskListMutation.mutate(taskList)}
        onTasklistNameChange={(taskList, newName) =>
          updateTaskListMutation.mutate({
            id: taskList.id,
            name: newName,
          })
        }
        onTasklistSort={(taskList, position) => {
          isPositionUpdate.current = true;
          updateTaskListMutation.mutate({
            id: taskList.id,
            position,
          });
        }}
        onTaskSort={(task, taskList, position) => {
          isPositionUpdate.current = true;
          updateTaskMutation.mutate({
            id: task.id,
            taskListId: task.taskListId ?? taskList.id,
            position,
            ...(taskList.id !== task.taskListId ? { taskList: taskList.id } : {}),
          });
        }}
      />
      <Routes>
        <Route
          element={
            <Lazy>
              <DossierTaskCardDetailsContainer
                dossierId={dossierId}
                onUpdateParentCard={() => onUpdate('task:update')}
              />
            </Lazy>
          }
          path="task/:taskId/*"
        />
      </Routes>
      <InputFormDrawer
        isClearable
        isRequired
        isDisabled={isDisabled}
        isShown={showNewTaskListModal}
        label="Naam"
        title="Nieuwe open takenlijst aanmaken"
        value={newTaskListNamePrefill}
        zIndex={1001000}
        onCancel={() => setShowNewTaskListModal(false)}
        onConfirm={(value) => createTaskList(value ?? '')}
      />
      <ConfirmDialog
        cancelTitle="Nee"
        className="c-action-delete-modal"
        confirmTitle="Ja, koppel"
        isDisabled={isDisabled || isBusy}
        isShown={!!assignUserConfirm}
        title={`Gebruiker koppelen aan ${dossierTypeTitleText}`}
        onCancel={() => setAssignUserConfirm(undefined)}
        onConfirm={() => {
          createTaskMutation.mutate(assignUserConfirm);
        }}
      >
        De geselecteerde gebruiker is niet gekoppeld aan {dossierTypeMessageText}.<br />
        Wilt u de gebruiker koppelen als bewerker aan {dossierTypeMessageText} en koppelen aan deze
        taak?
      </ConfirmDialog>
    </CardSection>
  );
};

CardSectionTaskList.defaultProps = {
  onBusy: (_isBusy) => null,
  onUpdate: () => null,
  dossierTypeTitleText: 'dossier',
  dossierTypeMessageText: 'dit dossier',
  hasToConfirmUserLink: true,
};

export default CardSectionTaskList;
