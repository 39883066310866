import { Loader } from '@elseu/sdu-titan';
import { useDossierCreate } from 'components/dossier/forms/hooks/useDossierCreate';
import { useDossierUpdate } from 'components/dossier/forms/hooks/useDossierUpdate';
import type { Dossier } from 'entity/dossiers/types';
import { useDossierDetails } from 'hooks/queries';
import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import type { DossierPayload } from 'services/api/DossierApi';

import { useTrySaveForm } from '../../hooks/useTrySaveForm';
import DossierTeamModel from '../../models/dossier/DossierTeam';
import DossierUserModel from '../../models/dossier/DossierUser';
import {
  createDossierTeamsPostValuesFromPermissions,
  createDossierUsersPostValuesFromPermissions,
  createPermissionsFromDossierTeams,
  createPermissionsFromDossierUsers,
} from '../permission/helpers';
import type { TicketFormSubmitHandler } from '../ticket/forms/TicketFormProps';
import type { DossierFormValues } from './forms/DossierForm';
import { DossierForm } from './forms/DossierForm';

const mapDossierToDossierFormValues = (
  dossier?: Dossier,
  defaultValues: DossierFormValues = {},
): DossierFormValues => {
  if (!dossier)
    return {
      name: '',
      assignedUser: '',
      createdBy: '',
      clients: [],
      status: '',
      description: '',
      topics: [],
      permissions: [],
      isPrivateCollaboration: false,
      isTemplate: false,
      ...defaultValues,
    };

  return {
    name: dossier.name,
    assignedUser: dossier.assignedUser?.id,
    createdBy: dossier.assignedUser?.id,
    clients:
      dossier.clients?.map((client) => ({
        label: client.client?.displayName || '',
        value: client.client?.id || '',
      })) || [],
    status: dossier.label?.id,
    description: dossier.description || '',
    topics: dossier.topics.map((topic) => topic.id || ''),
    permissions: [
      ...createPermissionsFromDossierUsers(
        DossierUserModel.createInstancesByArray(
          dossier.dossierUsers || [],
        ) as unknown as DossierUserModel[],
      ),
      ...createPermissionsFromDossierTeams(
        DossierTeamModel.createInstancesByArray(
          dossier.dossierTeams || [],
        ) as unknown as DossierTeamModel[],
      ),
    ],
    isPrivateCollaboration: dossier.isPrivateCollaboration || false,
    isTemplate: dossier.isTemplate || false,
  };
};

const mapDossierFormValuesToDossierPayload = ({
  clients,
  permissions,
  status,
  isTemplate,
  isPrivateCollaboration,
  duplicateSkipAttachments,
  duplicateSkipComments,
  ...values
}: DossierFormValues): DossierPayload => ({
  ...values,
  clients: clients?.map((client) => ({ client: client.value })) || [],
  label: status || '',
  dossierUsers: createDossierUsersPostValuesFromPermissions(permissions),
  dossierTeams: createDossierTeamsPostValuesFromPermissions(permissions),
  isTemplate: isTemplate || false,
  isPrivateCollaboration: isPrivateCollaboration || false,
  duplicateSkipAttachments: duplicateSkipAttachments || false,
  duplicateSkipComments: duplicateSkipComments || false,
});

interface DossierEditProps {
  dossierId?: string;
  defaultValues?: DossierFormValues;
  onSuccess?: (dossier: Dossier) => void;
}
export const DossierEditController = ({
  dossierId,
  defaultValues,
  onSuccess,
}: DossierEditProps) => {
  const { reset } = useFormContext();
  const { dossier } = useDossierDetails(dossierId);
  const mode = dossierId ? 'edit' : 'create';

  useEffect(() => {
    reset(mapDossierToDossierFormValues(dossier, defaultValues));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, dossier]);

  const catchFormError = useTrySaveForm();

  const { mutateAsync: createDossier, isSuccess: isCreated } = useDossierCreate();
  const { mutateAsync: updateDossier } = useDossierUpdate();

  const handleSubmit: TicketFormSubmitHandler<DossierFormValues> = useCallback(
    async (formValues, form) => {
      const dossierPayload = mapDossierFormValuesToDossierPayload(formValues);

      await catchFormError<DossierFormValues>(
        async () => {
          const data = await (dossierId
            ? updateDossier({ id: dossierId, dossier: dossierPayload })
            : createDossier({ dossier: dossierPayload }));

          onSuccess?.(data.data);
        },
        form,
        `Dossier opgeslagen`,
      );
    },
    [createDossier, dossierId, onSuccess, catchFormError, updateDossier],
  );

  const showSpinner = mode === 'edit' && !dossier && !isCreated;

  const isReadOnly = !(dossier?.permissions.canEdit ?? true);

  if (showSpinner) {
    return <Loader height={48} variant="spinner" />;
  }

  return <DossierForm isReadOnly={isReadOnly} mode={mode} onSubmit={handleSubmit} />;
};
