import { Box, EmptyState } from '@elseu/sdu-titan';
import React from 'react';
import styled from 'styled-components';

const CenterEmptyState = styled(EmptyState)`
  max-width: unset;

  & > span {
    margin-bottom: 0;
  }
`;

const LegalSourceListEmpty: React.FC = () => {
  return (
    <Box backgroundColor="grey5" pb={8} pt={8}>
      <CenterEmptyState
        isCentered
        description="Deze plek is nu nog leeg, maar hier komen al je juridische bronnen te staan."
        title="Voeg een juridische bron toe"
      />
    </Box>
  );
};

export default LegalSourceListEmpty;
