/**
 * Fetch state class
 *
 * A helper class to handle a fetch state after doing it.
 */
export default class FetchState {
  static STATES = {
    UNKNOWN: 'UNKNOWN', // running a fetch call
    SUCCESS: 'SUCCESS', // fetch state is done
    BUSY: 'BUSY', // running a fetch call
    ERROR: 'ERROR', // fetch state has error
  };

  /** @var {string} */
  currentState;

  /** @var {boolean} */
  error;

  /** @var {number} */
  errorCode;

  /** @var {object} */
  errorContext;

  /** @var {object} */
  errorData;

  /** @var {string} */
  errorMessage;

  /** @var {object} */
  context;

  /**
   * Constructor
   */
  constructor() {
    this.reset();
  }

  /**
   * Reset the class
   *
   * @returns {FetchState}
   */
  reset() {
    this.currentState = FetchState.STATES.UNKNOWN;
    this.context = undefined;
    this.error = false;
    this.errorCode = undefined;
    this.errorContext = undefined;
    this.errorData = undefined;
    this.errorMessage = undefined;

    return this;
  }

  /**
   * Set class to specific state.
   *
   * @param state
   * @returns {FetchState}
   */
  setCurrentState(state) {
    this.currentState = state;

    return this;
  }

  /**
   * Get current state
   *
   * @returns {string}
   */
  getCurrentState() {
    return this.currentState;
  }

  /**
   * Check if the state is a specific state
   *
   * @param state
   * @returns {boolean}
   */
  isCurrentState(state) {
    return this.currentState === state;
  }

  /**
   * Set the state to busy
   *
   * @returns {FetchState}
   */
  setBusy() {
    return this.setCurrentState(FetchState.STATES.BUSY);
  }

  /**
   * Returns if the state is busy
   *
   * @returns {boolean}
   */
  isBusy() {
    return this.isCurrentState(FetchState.STATES.BUSY);
  }

  /**
   * Check if state is success
   *
   * @returns {FetchState}
   */
  setSuccess() {
    return this.setCurrentState(FetchState.STATES.SUCCESS);
  }

  /**
   * Check if a state is successfull
   *
   * @returns {boolean}
   */
  isSuccess() {
    return this.isCurrentState(FetchState.STATES.SUCCESS);
  }

  /**
   * Set the state to error and handle de error message
   *
   * @param response
   * @param defaultMessage
   * @returns {FetchState}
   */
  setError(response, defaultMessage = 'Onbekende fout opgetreden') {
    this.setCurrentState(FetchState.STATES.ERROR);
    const message = response.getMessage ? response.getMessage() : response;
    const data = response.getData ? response.getData() : {};

    this.error = true;
    this.errorMessage = message || defaultMessage;
    this.errorContext = data;
    this.errorData = data;

    return this;
  }

  /**
   * Returns if the state has a error
   *
   * @returns {boolean}
   */
  hasError() {
    return this.error;
  }

  /**
   * Returns the error message
   *
   * @returns {string}
   */
  getErrorMessage() {
    return this.errorMessage;
  }

  /**
   * Returns the error context, which is basicly any data that belongs to the
   * jsend error data.
   *
   * @return {Object}
   */
  getErrorContext() {
    return this.errorContext;
  }

  /**
   * Return the error data
   *
   * @returns {Record<string, any>}
   */
  getErrorData() {
    return this.errorData;
  }

  /**
   * Get error state of response.
   *
   * @param {object} response
   * @returns {FetchState}
   */
  static getErrorMessage(response) {
    const state = new FetchState();
    state.setError(response);
    return state.getErrorMessage();
  }

  /**
   * Get fetchstate of response that failed.
   *
   * @param {object} response
   * @returns {FetchState}
   */
  static getErrorState(response) {
    const state = new FetchState();
    state.setError(response);
    return state;
  }

  setContext(context = {}) {
    this.context = context;
    return this;
  }

  getContext() {
    return this.context;
  }
}

// #endregion
