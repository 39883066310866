import { ChatAltIcon, Flex, TasklistIcon, Text } from '@elseu/sdu-titan';
import type { Ticket } from 'entity/dossiers/types';
import React from 'react';

type TicketMetaDataProps = Ticket['metaData'];

export const TicketMetaData = ({
  numberOfComments,
  numberOfTasks,
  numberOfCompletedTasks,
}: TicketMetaDataProps) => (
  <>
    {numberOfTasks > 0 && (
      <Flex alignItems="center" gap={1}>
        <TasklistIcon color="grey70" />
        <Text color="grey70" type="labelSmall">
          {numberOfCompletedTasks}/{numberOfTasks}
        </Text>
      </Flex>
    )}
    {numberOfComments > 0 && (
      <Flex alignItems="center" color="grey70" gap={1}>
        <ChatAltIcon color="grey70" />
        <Text color="grey70" type="labelSmall">
          {numberOfComments}
        </Text>
      </Flex>
    )}
  </>
);
