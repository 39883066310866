import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import { normalizeCard } from 'entity/dossiers/normalizer';
import type { Card } from 'entity/dossiers/types';
import { useMemo } from 'react';

type useTaskDetailsReturnType = {
  task?: Card;
  query: UseQueryResult;
};

export const useTaskDetails = (taskId: string | undefined): useTaskDetailsReturnType => {
  const query = useQuery({
    queryKey: ['task', taskId],
    queryFn: async () => {
      const res = await Actions.getAPIService().tasks().getTask(taskId);
      return normalizeCard(res.data);
    },
    enabled: !!taskId,
  });

  return useMemo(
    () => ({
      task: query.data,
      query,
    }),
    [query],
  );
};
