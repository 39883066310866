import React from 'react';

import { ReactComponent } from './assets/svg/EditorList-ul.svg';
import type { IconComponentProps } from './IconComponent';
import IconComponent from './IconComponent';

const EditorListUl = (props: IconComponentProps) => IconComponent(props, <ReactComponent />);

EditorListUl.propTypes = IconComponent.propTypes;
EditorListUl.defaultProps = IconComponent.defaultProps;

export default EditorListUl;
