/**
 * Ticket types with dutch labels
 */

import {
  CheckSquareIcon,
  ClipboardEditIcon,
  FileSearchIcon,
  FileType2Icon,
  GalleryVerticalEndIcon,
  MailIcon,
  PhoneCallIcon,
} from '@elseu/sdu-titan';
import type { StatusColors } from '@elseu/sdu-titan/dist/types/types/status-colors';
import type { TicketType } from 'entity/dossiers/types';
import React from 'react';

export interface TicketTypeObjectProps {
  id: TicketType;
  label: string;
  status: StatusColors;
  icon: JSX.Element;
}

type TicketTypes = Record<TicketType, TicketTypeObjectProps>;
export const ticketTypeObject = {
  dossier: {
    id: 'dossier',
    label: 'Dossier',
    status: 'information',
    icon: <GalleryVerticalEndIcon />,
  },
  subdossier_document: {
    id: 'subdossier_document',
    label: 'Subdossier',
    status: 'information',
    icon: <FileType2Icon />,
  },
  subdossier_canvas: {
    id: 'subdossier_canvas',
    label: 'Canvas',
    status: 'information',
    icon: <FileSearchIcon />,
  },
  task: {
    id: 'task',
    label: 'Taak',
    status: 'orange',
    icon: <CheckSquareIcon />,
  },
  note_phone: {
    id: 'note_phone',
    label: 'Telefoon',
    status: 'purple',
    icon: <PhoneCallIcon />,
  },
  note_email: {
    id: 'note_email',
    label: 'E-mail',
    status: 'purple',
    icon: <MailIcon />,
  },
  note_conversation: {
    id: 'note_conversation',
    label: 'Gespreksnotitie',
    status: 'purple',
    icon: <ClipboardEditIcon />,
  },
} satisfies TicketTypes;

export const getTicketTypeObject = (value: TicketType): TicketTypeObjectProps => {
  return ticketTypeObject[value];
};
