import './assets/styles/FileUploader.scss';

import React from 'react';

type Props = {
  uploadingMax?: number;
  uploadingIndex?: number;
};

const FileUploaderStatus: React.FC<Props> = ({ uploadingIndex = 1, uploadingMax = 1 }) => (
  <div className="c-file-uploader__is-uploading">
    <span className="c-file-uploader__is-uploading__text">
      Bezig met het uploaden van
      {uploadingIndex === 1 && uploadingMax === 1
        ? ' een bestand'
        : ` ${uploadingIndex} van de ${uploadingMax} bestanden.`}
    </span>
  </div>
);

export default React.memo(FileUploaderStatus);
