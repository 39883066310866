import React from 'react';

import IconComponent from './IconComponent';

const RemoveIcon = (props) =>
  IconComponent(
    props,
    <svg fill="none" viewBox="0 0 10 10">
      <path
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M7.07883 1.50716L7.78594 0.800049L9.20015 2.21426L8.49304 2.92137L6.41443 4.99998L8.49304 7.0786L9.20015 7.7857L7.78593 9.19992L7.07883 8.49281L5.00022 6.4142L2.92161 8.4928L2.21451 9.1999L0.800294 7.78569L1.5074 7.07858L3.586 4.99998L1.5074 2.92138L0.800293 2.21427L2.21451 0.800061L2.92161 1.50717L5.00022 3.58577L7.07883 1.50716Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>,
  );

RemoveIcon.propTypes = IconComponent.propTypes;
RemoveIcon.defaultProps = IconComponent.defaultProps;

export default RemoveIcon;
