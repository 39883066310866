import { useQueryClient } from '@tanstack/react-query';

import { useInvalidateCardCache } from './useInvalidateCardCache';

type useInvalidateSignalReturn = (signalingId?: string, signalId?: string) => void;

export const useInvalidateSignalCache = (): useInvalidateSignalReturn => {
  const queryClient = useQueryClient();
  const invalidateCardCache = useInvalidateCardCache();

  return (signalingId, signalId) => {
    queryClient.invalidateQueries({
      queryKey: ['signal', signalingId, signalId],
    });
    queryClient.invalidateQueries({
      queryKey: ['signaling-signals', signalingId],
    });

    // make sure that normal card cache is also cleared. (for auditlog)
    invalidateCardCache({ dossierId: signalingId, cardId: signalId });
  };
};
