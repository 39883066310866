import type { SelectFieldOptionType } from 'components/selectfield/SelectField';
import type { AttachmentFolder } from 'entity/Attachment/types';
import { normalizeClient } from 'entity/crm/normalizer';
import type { Client } from 'entity/crm/types';
import { normalizeTeam, normalizeUser, normalizeUserWithRole } from 'entity/system/normalizer';
import type { User, UserWithRole } from 'entity/system/types';
import { convertToDate, normalizeMap } from 'helpers/normalizehelper';

import type {
  Actuality,
  AllAttachment,
  AnalyseSections,
  AuditLog,
  Card,
  CardMetaInfo,
  Comment,
  CommentReaction,
  Consideration,
  ContextGroup,
  ContextItem,
  ContextType,
  Dossier,
  DossierClient,
  DossierTaskListOverview,
  DossierTeam,
  DossierUser,
  Label,
  LegalSource,
  RelatedCard,
  Section,
  Task,
  TaskList,
  TaskListSubItems,
  TaskOverview,
  Topic,
} from './types';

export const normalizeDossier = (result: any): Dossier => ({
  id: result.id,
  name: result.name,
  description: result.description,
  clients: normalizeMap<DossierClient>(result?.clients, normalizeDossierClient),
  topics: normalizeMap<Topic>(result?.topics, normalizeTopic),
  createdAt: convertToDate(result.createdAt),
  updatedAt: convertToDate(result.updatedAt),
  inventoryId: result.inventoryId,
  adviceId: result.adviceId,
  analysisId: result.analysisId,
  executionId: result.executionId,
  isArchived: result.isArchived ?? false,
  archivedAt: convertToDate(result.archivedAt),
  assignedUser: normalizeUser(result?.assignedUser),
  owners: normalizeMap<User>(result?.owners, normalizeUser),
  editors: normalizeMap<User>(result?.editors, normalizeUser),
  dossierUsers: normalizeMap<DossierUser>(result?.dossierUsers, normalizeDossierUser),
  dossierTeams: normalizeMap<DossierTeam>(result?.dossierTeams, normalizeDossierTeam),
  users: normalizeMap<UserWithRole>(result?.users, normalizeUserWithRole),
  isPrivateCollaboration: result.isPrivateCollaboration ?? false,
  isTemplate: result.isTemplate ?? false,
  label: result?.label ? normalizeLabel(result?.label) : createEmptyLabel(),
  permissions: {
    canView: result?.permissions?.canView ?? false,
    canEdit: result?.permissions?.canEdit ?? false,
    canDelete: result?.permissions?.canDelete ?? false,
    canArchive: result?.permissions?.canArchive ?? false,
    canAddCard: result?.permissions?.canAddCard ?? false,
    canManageTopics: result?.permissions?.canManageTopics ?? false,
    canManageOwners: result?.permissions?.canManageOwners ?? false,
    canDuplicate: result?.permissions?.canDuplicate ?? false,
  },
  phaseLabels: {
    inventory: result?.phaseLabels?.inventory
      ? normalizeLabel(result?.phaseLabels?.inventory)
      : createEmptyLabel(),
    analysis: result?.phaseLabels?.analysis
      ? normalizeLabel(result?.phaseLabels?.analysis)
      : createEmptyLabel(),
    advice: result?.phaseLabels?.advice
      ? normalizeLabel(result?.phaseLabels?.advice)
      : createEmptyLabel(),
    execution: result?.phaseLabels?.execution
      ? normalizeLabel(result?.phaseLabels?.execution)
      : createEmptyLabel(),
  },
  hasCloudAttachmentCopyQueue: result?.hasCloudAttachmentCopyQueue ?? false,
  cloudAttachmentCopyQueueCount: result?.cloudAttachmentCopyQueueCount ?? false,
  type: result?.type,
  subdossiers: result?.subdossiers?.map(normalizeCard),
});

export const normalizeDossierClient = (result: any): DossierClient => ({
  id: result.id,
  client: result.client ? normalizeClient(result.client) : undefined,
});

export const normalizeTopic = (result: any): Topic => ({
  id: result.id,
  name: result.name,
  isDeleted: result.isDeleted ?? false,
  isManual: result.isManual ?? false,
  createdDateTime: convertToDate(result.createdDateTime),
  createdBy: result.createdBy,
});

export const normalizeDossierUser = (result: any): DossierUser => ({
  id: result.id,
  user: normalizeUser(result.user),
  role: result.role,
});

export const normalizeDossierTeam = (result: any): DossierTeam => ({
  id: result.id,
  team: normalizeTeam(result.team),
  role: result.role,
});

export const normalizeLabel = (result: any): Label => ({
  id: result?.id,
  name: result?.name,
  color: result?.color,
  position: result?.position,
  isSystem: result?.isSystem ?? false,
});

export const createEmptyLabel = (): Label => ({
  name: 'Geen status',
  isSystem: false,
});

export const normalizeAttachmentFolder = (result: any): AttachmentFolder => ({
  id: result?.id,
  name: result?.name,
  parentAttachmentFolderId: result?.parentAttachmentFolderId ?? undefined,
  canDelete: result?.canDelete ?? true,
});

export const normalizeCard = (result: any): Card => ({
  id: result.id,
  name: result?.name,
  notes: result?.notes,
  subtitle: result?.subtitle,
  type: result?.type ?? 'own',
  subType: result?.subType,
  section: result?.section,
  color: result?.color,
  position: {
    x: result?.position?.x,
    y: result?.position?.y,
  },
  stackOrder: result?.stackOrder ?? 0,
  summary: result?.summary ?? [],
  assignedUser: result.assignedUser ? normalizeUser(result.assignedUser) : undefined,
  createdBy: result.createdBy ? normalizeUser(result.createdBy) : undefined,
  deadlineDate: convertToDate(result.deadlineDate),
  completedDateTime: convertToDate(result.completedDateTime),
  label: result.label ? normalizeLabel(result.label) : createEmptyLabel(),
  metaInfo: result?.metaInfo
    ? normalizeCardMetaInfo(result?.metaInfo)
    : {
        countAttachments: 0,
        countComments: 0,
        countReactions: 0,
      },
  relatedCards: normalizeMap<RelatedCard>(result?.relatedCards, normalizeRelatedCard),
  mailTo: result?.mailTo,
  contextGroups: normalizeMap<ContextGroup>(result?.contextGroups, normalizeContextGroup),
  taskLists: normalizeMap<TaskList>(result?.taskLists, normalizeTaskList),
  taskList: result?.taskList ? normalizeTaskList(result.taskList) : undefined,
  legalSources: normalizeMap<LegalSource>(result?.legalSources, normalizeLegalSource),
  allAttachments: normalizeMap<AllAttachment>(result?.allAttachments, normalizeAllAttachment),
  client: result?.client ? normalizeClient(result.client) : undefined,
  isArchived: result?.isArchived ?? false,
  consideration: result?.consideration ? normalizeConsideration(result.consideration) : undefined,
  attachments: result?.attachments || [],
  attachmentFolders: result?.attachmentFolders || [],
  cloudAttachments: result?.cloudAttachments || [],
  cloudLinks: result?.cloudLinks || [],
  berkleyModelSessions: result?.berkleyModelSessions || [],
  dossier: result?.dossier ? normalizeDossier(result?.dossier) : undefined,
});

export const normalizeCardMetaInfo = (result: any): CardMetaInfo => ({
  createdAt: convertToDate(result.createdAt),
  updatedAt: convertToDate(result.updatedAt),
  countAttachments: result?.countAttachments ?? 0,
  countComments: result?.countComments ?? 0,
  countReactions: result?.countReactions ?? 0,
  lastActivityAt: convertToDate(result.lastActivityAt),
  lastActivityBy: result.lastActivityBy ? normalizeUser(result.lastActivityBy) : undefined,
});

export const normalizeRelatedCard = (result: any): RelatedCard => ({
  id: result.id,
  name: result?.name,
  notes: result?.notes,
  subtitle: result?.subtitle,
  type: result?.type ?? 'own',
  section: result?.section,
  color: result?.color,
  position: {
    x: result?.position?.x,
    y: result?.position?.y,
  },
  stackOrder: result?.stackOrder ?? 0,
  summary: result?.summary ?? [],
  assignedUser: result.user ? normalizeUser(result.user) : undefined,
  deadlineDate: convertToDate(result.deadlineDate),
  completedDateTime: convertToDate(result.completedDateTime),
  label: result.label ? normalizeLabel(result.label) : createEmptyLabel(),
  metaInfo: normalizeCardMetaInfo(result?.metaInfo),
  dossier: result?.dossier ? normalizeDossier(result?.dossier) : undefined,
  isArchived: result.isArchived ?? false,
});

export const normalizeConsideration = (result: any): Consideration => ({
  id: result.id,
  name: result.name,
  url: result.url,
  isManual: result.isManual ?? false,
  isDeleted: result.isDeleted ?? false,
  canvasSections: result.canvasSections,
  partialConsiderations: normalizeMap<ContextType>(result.partialConsiderations, (item) =>
    normalizeContextType(item, 'partial_consideration'),
  ),
  actuality: normalizeMap<Actuality>(result.actuality, normalizeActuality),
  sources: normalizeMap<ContextType>(result.sources, (item) =>
    normalizeContextType(item, 'source'),
  ),
  ownSources: normalizeMap<ContextType>(result.ownSources, (item) =>
    normalizeContextType(item, 'source'),
  ),
  tools: normalizeMap<ContextType>(result.tools, (item) => normalizeContextType(item, 'tool')),
});

export const normalizeContextType = (result: any, type: string): ContextType => ({
  id: result.id,
  name: result.name,
  description: result.description,
  url: result.url,
  type,
  contextType: result.contextType,
  hasAttachment: result.hasAttachment ?? false,
  isBerkleyModel: result.isBerkleyModel ?? false,
  berkleyModelId: result.berkleyModelId,
});

export const normalizeActuality = (result: any): Actuality => ({
  id: result.id,
  name: result.name,
  description: result.description,
  url: result.url,
  type: result.type ?? '',
});

export const normalizeTaskList = (result: any): TaskList => ({
  id: result.id,
  name: result.name,
  position: result.position,
  createdDateTime: convertToDate(result.createdDateTime),
  tasks: normalizeMap<Task>(result.tasks, (item: any) => normalizeTask(item, result.id)),
});

export const normalizeTask = (result: any, overrideTaskListId: string | undefined): Task => ({
  id: result.id,
  name: result.name,
  position: result.position,
  createdDateTime: convertToDate(result.createdDateTime),
  assignedUser: normalizeUser(result.assignedUser),
  deadlineDate: convertToDate(result.deadlineDate),
  completedDateTime: convertToDate(result.completedDateTime),
  url: result.url,
  taskListId: result.taskListId ?? overrideTaskListId,
  taskList: result.taskList ? normalizeTaskList(result.taskList) : undefined,
  card: result.card ? normalizeCard(result.card) : undefined,
  ticket: result.ticket,
});

export const normalizeTopicForSelectField = (topic: Topic): SelectFieldOptionType => ({
  value: topic.id ?? '',
  label: topic.name ?? '',
  subtitle: topic.isManual ? 'Eigen thema' : 'Sdu thema',
  item: topic,
});

export const normalizeTaskOverview = (result: any): TaskOverview => ({
  id: result.id ?? '',
  name: result.name,
  permissions: {
    canView: result?.permissions?.canView ?? false,
    canEdit: result?.permissions?.canEdit ?? false,
    canDelete: result?.permissions?.canDelete ?? false,
    canAddCard: result?.permissions?.canAddCard ?? false,
    canManageTopics: result?.permissions?.canManageTopics ?? false,
    canManageUsers: result?.permissions?.canManageUsers ?? false,
  },
  cards: normalizeDossierTaskListOverview(result.cards ?? {}),
  clients: normalizeMap<Client>(result.clients, normalizeClient),
});

export const normalizeDossierTaskListOverview = (result: any): DossierTaskListOverview => ({
  inventory: result.inventory ? normalizeTaskListSubItems(result.inventory) : undefined,
  analysis: {
    facts: normalizeMap<TaskListSubItems>(result?.analysis?.facts ?? [], normalizeTaskListSubItems),
    considerations: normalizeMap<TaskListSubItems>(
      result?.analysis?.considerations ?? [],
      normalizeTaskListSubItems,
    ),
    results: normalizeMap<TaskListSubItems>(
      result?.analysis?.results ?? [],
      normalizeTaskListSubItems,
    ),
  },
  advice: result.advice ? normalizeTaskListSubItems(result.advice) : undefined,
  execution: result.execution ? normalizeTaskListSubItems(result.execution) : undefined,
  contactNote: normalizeMap<TaskListSubItems>(result?.contactNote ?? [], normalizeTaskListSubItems),
  signaling: normalizeMap<TaskListSubItems>(result?.signaling ?? [], normalizeTaskListSubItems),
});

export const normalizeTaskListSubItems = (result: any): TaskListSubItems => ({
  id: result?.id ?? '',
  name: result?.name ?? '',
  url: result?.url,
  taskLists: normalizeMap<TaskList>(result.taskLists ?? [], normalizeTaskList),
  client: result.client ? normalizeClient(result.client) : undefined,
});

export const normalizeContextGroup = (result: any): ContextGroup => ({
  id: result?.id ?? '',
  name: result?.name ?? '',
  type: result?.type ?? 'unknown',
  items: normalizeMap<ContextItem>(result?.items, normalizeContextItem),
});

export const normalizeContextItem = (result: any): ContextItem => ({
  id: result?.id ?? '',
  name: result?.name ?? '',
  description: result?.description,
  type: result?.type ?? '',
  url: result?.url ?? '',
  contextType: result.contextType,
  hasAttachment: result.hasAttachment ?? false,
  isBerkleyModel: result.isBerkleyModel ?? false,
  berkleyModelId: result.berkleyModelId,
  iconName: result?.iconName,
});

export const normalizeLegalSource = (result: any): LegalSource => ({
  id: result?.id ?? '',
  name: result?.name ?? '',
  comment: result?.comment,
  url: result?.url ?? '',
  type: result?.type ?? '',
  saveCurrentVersion: result?.saveCurrentVersion ?? false,
  createdAt: convertToDate(result.updatedAt),
  updatedAt: convertToDate(result.updatedAt),
  createdBy: result?.createdBy ? normalizeUser(result?.createdBy) : undefined,
});

export const normalizeAllAttachment = (result: any): AllAttachment => ({
  id: result?.id ?? '',
  name: result?.name ?? '',
  url: result?.url ?? '',
  type: result?.type ?? '',
  extension: result?.extension ?? '',
  createdDateTime: convertToDate(result.createdDateTime),
  updatedDateTime: convertToDate(result.updatedDateTime),
  phase: result?.phase ?? '',
  phaseType: result?.phaseType ?? '',
  attachmentType: result?.attachmentType ?? '',
  cardId: result?.cardId ?? '',
  dossierId: result?.dossierId ?? '',
  attachmentFolder: result.attachmentFolder
    ? normalizeAttachmentFolder(result.attachmentFolder)
    : undefined,
  dossier: result.dossier ? normalizeDossier(result.dossier) : undefined,
  card: result.card ? normalizeCard(result.card) : undefined,
  displayDate: result.displayDate,
});

const normalizeCommentReaction = (result: any): CommentReaction => ({
  id: result?.id ?? '',
  content: result?.content ?? '',
  createdDateTime: convertToDate(result.createdDateTime),
  user: normalizeUser(result?.user ?? {}),
  context: result?.context ? normalizeContextItem(result.context) : undefined,
});

export const normalizeComment = (result: any): Comment => ({
  ...normalizeCommentReaction(result),
  comments: normalizeMap<CommentReaction>(result?.comments, normalizeCommentReaction),
});

export const normalizeSection = (result: any): Section => ({
  label: result.label ?? '',
  cards: normalizeMap<Card>(result.cards, normalizeCard),
});

export const normalizeAnalyseSections = (result: any): AnalyseSections => ({
  facts: result.facts
    ? normalizeSection(result.facts)
    : {
        label: 'Facts',
        cards: [],
      },
  considerations: result.considerations
    ? normalizeSection(result.considerations)
    : {
        label: 'Considerations',
        cards: [],
      },
  results: result.results
    ? normalizeSection(result.results)
    : {
        label: 'Results',
        cards: [],
      },
});

export const normalizeAuditLog = (result: any): AuditLog => ({
  id: result?.id ?? '',
  createdAt: convertToDate(result.createdAt),
  activity: result?.activity ?? '',
  activityDescription: result?.activityDescription ?? '',
  userAgent: result?.userAgent,
  ipAddress: result?.ipAddress,
  additionalData: result?.additionalData,
  user: result?.user ? normalizeUser(result?.user) : undefined,
});

export const normalizeAssignedUser = (user: any) => ({
  value: user.id,
  label: user.fullName,
  email: user.email,
  image: user.avatarUrl,
  user,
});
