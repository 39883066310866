import type { Dossier } from 'entity/dossiers/types';
import type { ApiFilter } from 'types/types';

import type {
  DossierTeamPostValue,
  DossierUserPostValue,
} from '../../components/permission/helpers';
import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';
import type { JSendResponse } from './types';

export type DossierPayload = {
  name?: string | null;
  description?: string | null;
  isPrivateCollaboration?: boolean;
  isTemplate?: boolean;
  assignedUser?: string | null;
  clients?: Array<{ client: string }>;
  topics?: string[] | null;
  label?: string;
  dossierUsers?: DossierUserPostValue[];
  dossierTeams?: DossierTeamPostValue[];
  linkCard?: string;
  duplicateSkipAttachments?: boolean;
  duplicateSkipComments?: boolean;
};

type GetDossiersResponse = JSendResponse<{
  dossiers: Dossier[];
}>;

type UpdateDossierResponse = JSendResponse<Dossier>;
type DeleteDossierResponse = JSendResponse;

export default class DossierApi extends APIEndpoint {
  // TODO: move all functions that has todo with dossier to this class.
  // (This for now not a good idea. Middle in a release now)

  getDossiers(
    filters: ApiFilter[] = [],
    sort = '',
    page = 1,
    pageSize = 10,
  ): Promise<GetDossiersResponse> {
    const url = this.buildUrl('dossiers', filters, sort, page, pageSize);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(url);
  }

  getDossier(dossierId: Dossier['id']): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(`dossiers/${dossierId}`);
  }

  createDossier(dossier: DossierPayload): Promise<UpdateDossierResponse> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'dossiers',
      JSendRequest.REQUEST_METHOD_POST,
      dossier,
    );
  }

  duplicateDossier(dossierId: Dossier['id'], dossier: DossierPayload): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'dossiers',
      JSendRequest.REQUEST_METHOD_POST,
      {
        ...dossier,
        duplicateFromDossier: dossierId,
      },
    );
  }

  updateDossier(dossierId: Dossier['id'], dossier: DossierPayload): Promise<UpdateDossierResponse> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `dossiers/${dossierId}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      dossier,
    );
  }

  deleteDossier(dossierId: Dossier['id']): Promise<DeleteDossierResponse> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `dossiers/${dossierId}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }

  // #region archive
  dossierArchive(id: string): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `dossiers/${id}/archive`,
      JSendRequest.REQUEST_METHOD_PATCH,
    );
  }

  dossierUnArchive(id: string): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `dossiers/${id}/unarchive`,
      JSendRequest.REQUEST_METHOD_PATCH,
    );
  }

  // #endregion

  // #region attachments
  dossierAttachments(id: string, page = 1, maxPerPage = 10): Promise<any> {
    const url = this.buildUrl(`dossiers/${id}/attachments`, {}, '', page, maxPerPage);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      url,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  // #endregion

  // #region cloud attachments
  dossierCloudAttachments(id: string, page = 1, maxPerPage = 10): Promise<any> {
    const url = this.buildUrl(`dossiers/${id}/cloud_attachments`, {}, '', page, maxPerPage);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      url,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  addToolAsCloudAttachment(
    dossierId: string,
    cardId: string,
    id: string,
    contextType: string,
  ): Promise<any> {
    const url = `cards/${cardId}/cloud_attachments/tool`;
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      url,
      JSendRequest.REQUEST_METHOD_POST,
      {
        id,
        contextType,
      },
    );
  }

  addOwnToolAsCloudAttachment(dossierId: string, cardId: string, ownToolId: string): Promise<any> {
    const url = `cards/${cardId}/cloud_attachments/own_tool/${ownToolId}`;
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      url,
      JSendRequest.REQUEST_METHOD_POST,
      {},
    );
  }
  // #endregion

  // #region cloud links

  dossierCloudLinks(dossierId: string, page = 1, maxPerPage = 10): Promise<any> {
    const url = this.buildUrl(`dossiers/${dossierId}/cloud_links`, {}, '', page, maxPerPage);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      url,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  // #endregion

  getAttachmentsByDossier(
    dossierId: string,
    type: string,
    filter: any,
    sort = '',
    page = 1,
    maxPerPage = 10,
  ): Promise<any> {
    const url = this.buildUrl(`dossiers/${dossierId}/${type}`, filter, sort, page, maxPerPage);
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      url,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  createDossierExport(dossierId: string): Promise<any> {
    return fetch(`${this.getBaseUrl()}dossiers/${dossierId}/exportPdf`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Token ${this.getToken()}`,
      },
    });
  }

  dossierUserCreatedTasks(dossierId: string): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `dossiers/${dossierId}/users_created/tasks`,
      JSendRequest.REQUEST_METHOD_GET,
    );
  }

  bulkAttachmentsMoveToAttachmentFolder(
    dossierId: string,
    attachments: string[],
    attachmentFolderId: string | null,
  ): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `dossiers/${dossierId}/analysis/bulk/attachments`,
      JSendRequest.REQUEST_METHOD_PATCH,
      {
        attachments,
        attachmentFolder: attachmentFolderId,
      },
    );
  }

  bulkCloudAttachmentsMoveToAttachmentFolder(
    dossierId: string,
    cloudAttachments: string[],
    attachmentFolderId: string | null,
  ): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `dossiers/${dossierId}/analysis/bulk/cloud_attachments`,
      JSendRequest.REQUEST_METHOD_PATCH,
      {
        cloudAttachments,
        attachmentFolder: attachmentFolderId,
      },
    );
  }

  bulkCloudLinksMoveToAttachmentFolder(
    dossierId: string,
    cloudLinks: string[],
    attachmentFolderId: string | null,
  ): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `dossiers/${dossierId}/analysis/bulk/cloud_links`,
      JSendRequest.REQUEST_METHOD_PATCH,
      {
        cloudLinks,
        attachmentFolder: attachmentFolderId,
      },
    );
  }

  bulkBerkleySessionsMoveToAttachmentFolder(
    dossierId: string,
    berkleySessions: string[],
    attachmentFolderId: string | null,
  ): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `dossiers/${dossierId}/analysis/bulk/berkley_sessions`,
      JSendRequest.REQUEST_METHOD_PATCH,
      {
        berkleySessions,
        attachmentFolder: attachmentFolderId,
      },
    );
  }

  bulkAttachmentFolderToAttachmentFolder(
    dossierId: string,
    attachmentFolderIds: string[],
    attachmentFolderId: string | null,
  ): Promise<any> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `dossiers/${dossierId}/attachment_folders/bulk`,
      JSendRequest.REQUEST_METHOD_PATCH,
      {
        attachmentFolders: attachmentFolderIds,
        parentAttachmentFolder: attachmentFolderId,
      },
    );
  }
}
