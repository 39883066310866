import type { Team, TemplateAttachmentFolder, User, UserWithRole } from './types';

export const normalizeTeam = (result: any): Team => {
  return {
    id: result?.id,
    name: result?.name,
    description: result?.description,
    countUsers: result?.countUsers,
    countClients: result?.countClients,
    createdDateTime: result?.createdDateTime,
    subOffice: result?.subOffice,
    roleInGroupOfCurrentUser: result?.roleInGroupOfCurrentUser,
    permissions: result?.permissions,
    isDeleted: result?.isDeleted,
  };
};

export const normalizeUser = (result: any): User => ({
  id: result?.id,
  email: result?.email,
  fullName: result?.fullName,
  avatarUrl: result?.avatarUrl,
});

export const normalizeUserWithRole = (result: any): UserWithRole => ({
  id: result?.id,
  email: result?.email,
  fullName: result?.fullName,
  avatarUrl: result?.avatarUrl,
  role: result?.role,
});

export const normalizeTemplateAttachmentFolder = (result: any): TemplateAttachmentFolder => ({
  id: result.id,
  name: result.name,
});

export const normalizeUserForSelectOption = (user: User): any => ({
  value: user.id,
  label: user.fullName,
  image: user.avatarUrl,
  email: user.email,
  type: 'person',
  user: this,
});
