import { useQuery } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import { normalizeAnnouncement } from 'entity/announcement/normalizer';
import { normalizeMap } from 'helpers/normalizehelper';

export const useAnnouncements = () => {
  return useQuery({
    queryKey: ['announcements'],
    queryFn: async () => {
      const res = await Actions.getAPIService().anouncement().announcements();

      return res.isSuccess() ? normalizeMap(res.data.announcements, normalizeAnnouncement) : [];
    },
    staleTime: 60 * 1000 * 30,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
