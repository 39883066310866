import type { LegalSource } from 'entity/dossiers/types';
import React from 'react';

import LegalSourceListItem from './LegalSourceListItem';

type Props = {
  isDisabled?: boolean;
  isReadOnly?: boolean;
  items: LegalSource[];
  onItemEdit: (item: LegalSource) => void;
  onItemDelete: (item: LegalSource) => void;
};

const LegalSourceList: React.FC<Props> = ({
  isDisabled,
  isReadOnly,
  items,
  onItemEdit,
  onItemDelete,
}) => {
  return (
    <>
      {items.map((item: LegalSource) => (
        <LegalSourceListItem
          key={item.id}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          item={item}
          onDelete={onItemDelete}
          onEdit={onItemEdit}
        />
      ))}
    </>
  );
};

export default LegalSourceList;
