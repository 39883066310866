export enum LeavePromptAction {
  Close = 'Close',
  Confirm = 'Confirm',
  Show = 'Show',
}

export type ILeavePromptState = {
  isConfirmed: boolean;
  isShown: boolean;
};
