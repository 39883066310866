import { ActionMenu, Link, MenuItem, PlusIcon, StatusMessage, Text } from '@elseu/sdu-titan';
import Actions from 'actions/Actions';
import LegalSourceEdit from 'components/legalsource/LegalSourceEdit';
import LegalSourceList from 'components/legalsource/LegalSourceList';
import LegalSourceListEmpty from 'components/legalsource/LegalSourceListEmpty';
import type { LegalSourceNewItem } from 'components/legalsource/LegalSourceNewNdfr';
import LegalSourceNewNdfr from 'components/legalsource/LegalSourceNewNdfr';
import NdfrSearchSimple from 'components/legalsource/NdfrSearchSimple';
import type { LegalSource } from 'entity/dossiers/types';
import { useProfileSettings } from 'hooks';
import groupBy from 'lodash/groupBy';
import React, { useMemo, useState } from 'react';
import { FormError } from 'utils/FormError';

import CardSection from './CardSection';

const groupKeyToLabel: any = {
  ndfr: 'NDFR',
  Wolter: 'Wolters Kluwer',
  overheid_nl: 'overheid.nl',
  other: 'Overig',
};

enum NewLegalSourceInput {
  NDFR = 'ndfr',
  NDFR_SIMPLE = 'ndfr_simple',
}

type GroupedItemsType = {
  [key: string]: LegalSource[];
};

type Props = {
  isDisabled: boolean;
  isReadOnly: boolean;
  dossierId: string;
  cardId?: string;
  legalSources: LegalSource[];
  onNeedsUpdate: () => void;
  onBusy?: (busy: boolean) => void;
};

const CardSectionLegalSource: React.FC<Props> = ({
  isDisabled,
  isReadOnly,
  dossierId,
  cardId,
  legalSources,
  onNeedsUpdate,
  onBusy = () => null,
}) => {
  const [settings] = useProfileSettings();
  const [newLegalSourceShow, setNewLegalSourceShow] = useState<NewLegalSourceInput | undefined>(
    undefined,
  );
  const [legalSourceEditShow, setLegelSourceShow] = useState(false);
  const [legalSourceEditItem, setLegalSourceEditItem] = useState<LegalSource | undefined>();
  const [deleteError, setDeleteError] = useState<FormError | undefined>();
  const [createError, setCreateError] = useState<FormError | undefined>();

  const groupedItems: GroupedItemsType = useMemo(
    () => groupBy(legalSources, 'type'),
    [legalSources],
  );
  const groupKeys = useMemo(() => Object.keys(groupedItems), [groupedItems]);

  /**
   * Delete legal source
   *
   * @param {LegalSource} item
   */
  const doDelete = (item: LegalSource) => {
    onBusy(true);
    setDeleteError(undefined);
    Actions.getAPIService()
      .legalSource()
      .deleteLegalSource(dossierId, cardId as string, item.id)
      .then(() => onNeedsUpdate())
      .catch((err: any) => setDeleteError(new FormError(err)))
      .finally(() => onBusy(false));
  };

  const doCreate = (values: LegalSourceNewItem) => {
    onBusy(true);
    setCreateError(undefined);
    Actions.getAPIService()
      .legalSource()
      .createLegalSource(dossierId, cardId as string, values)
      .then(() => {
        onNeedsUpdate();
        setNewLegalSourceShow(undefined);
      })
      .catch((err: any) => {
        if (err.code && err.code === 400) {
          setCreateError(new FormError(err));
        }
      })
      .finally(() => onBusy(false));
  };

  const addNdfrSource = () => {
    if (settings.isNdfrEnabled()) {
      setNewLegalSourceShow(NewLegalSourceInput.NDFR);
    } else {
      setNewLegalSourceShow(NewLegalSourceInput.NDFR_SIMPLE);
    }
  };

  // #region render
  return (
    <CardSection
      action={
        !isReadOnly ? (
          <ActionMenu
            defaultShown={false} // temp-fix to get ActionMenu working
            disabled={isDisabled}
            label="Bron toevoegen"
            popoverPlacement="bottom-end"
            trigger={<Link prefixAdornment={<PlusIcon />}>Bron toevoegen</Link>}
          >
            <MenuItem item={{ label: 'NDFR' }} onClick={addNdfrSource} />
          </ActionMenu>
        ) : undefined
      }
      title="Juridische bronnen"
    >
      {deleteError && (
        <StatusMessage
          isCloseable
          spaceAfter={4}
          type="danger"
          onClose={() => setDeleteError(undefined)}
        >
          {deleteError.message}
        </StatusMessage>
      )}
      {legalSources.length === 0 &&
        (newLegalSourceShow ? (
          <>
            <LegalSourceNewNdfr
              isShown={newLegalSourceShow === NewLegalSourceInput.NDFR}
              onCreateNewItem={doCreate}
            />
            <NdfrSearchSimple
              isShown={newLegalSourceShow === NewLegalSourceInput.NDFR_SIMPLE}
              onSearched={() => setNewLegalSourceShow(undefined)}
            />
          </>
        ) : (
          <LegalSourceListEmpty />
        ))}

      {groupKeys.map((key) => {
        const items = groupedItems[key];
        return (
          <React.Fragment key={key}>
            <Text isBlock color="grey100" spaceAfter={3} type="labelBold">
              {groupKeyToLabel[key] || key}
            </Text>
            <LegalSourceList
              key={key}
              isReadOnly={isReadOnly}
              items={items}
              onItemDelete={doDelete}
              onItemEdit={(item) => {
                setLegalSourceEditItem(item);
                setLegelSourceShow(true);
              }}
            />
            {key === 'ndfr' && (
              <>
                <LegalSourceNewNdfr
                  errors={createError}
                  isShown={newLegalSourceShow === NewLegalSourceInput.NDFR}
                  onCreateNewItem={doCreate}
                />
                <NdfrSearchSimple
                  isShown={newLegalSourceShow === NewLegalSourceInput.NDFR_SIMPLE}
                  onSearched={() => setNewLegalSourceShow(undefined)}
                />
              </>
            )}
          </React.Fragment>
        );
      })}

      {legalSourceEditShow && (
        <LegalSourceEdit
          cardId={cardId || ''}
          dossierId={dossierId}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          item={legalSourceEditItem}
          onCancel={() => {
            setLegelSourceShow(false);
            setLegalSourceEditItem(undefined);
          }}
          onSaved={() => {
            setLegelSourceShow(false);
            setLegalSourceEditItem(undefined);
            onNeedsUpdate();
          }}
        />
      )}
    </CardSection>
  );
  // #endregion
};

export default CardSectionLegalSource;
