import type { ButtonProps } from '@elseu/sdu-titan';
import { Button, ModalActions } from '@elseu/sdu-titan';
import React from 'react';

export const TicketSubmitButton: React.FC<ButtonProps> = (props) => {
  return (
    <ModalActions>
      <Button {...props} />
    </ModalActions>
  );
};
