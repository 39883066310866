import type { Task } from '../../entity/dossiers/types';
import JSendRequest from '../JSendRequest';
import APIEndpoint from './APIEndpoint';
import type { JSendResponse } from './types';

export type TaskPayload = Record<string, any>;

type UpdateTaskResponse = JSendResponse<Task>;
type DeleteTaskResponse = JSendResponse;

export default class TaskApi extends APIEndpoint {
  /**
   * Get task
   */
  getTask(id?: Task['id']): Promise<UpdateTaskResponse> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(`tasks/${id}`);
  }

  /**
   * Creates a new task.
   */
  createTask(task: TaskPayload): Promise<UpdateTaskResponse> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      'tasks',
      JSendRequest.REQUEST_METHOD_POST,
      task,
    );
  }

  /**
   * Updates a single task.
   */
  updateTask(id: Task['id'], task: TaskPayload): Promise<UpdateTaskResponse> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `tasks/${id}`,
      JSendRequest.REQUEST_METHOD_PATCH,
      task,
    );
  }

  /**
   * Deletes a single task.
   */
  deleteTask(id: Task['id']): Promise<DeleteTaskResponse> {
    return new JSendRequest(this.getBaseUrl(), this.getToken()).request(
      `tasks/${id}`,
      JSendRequest.REQUEST_METHOD_DELETE,
    );
  }
}
