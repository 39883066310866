import { useMutation } from '@tanstack/react-query';

import Actions from '../../actions/Actions';
import type { Ticket } from '../../entity/dossiers/types';
import type { RepositionTicketPayload } from '../../services/api/TicketApi';

type MutationVariables = {
  id: Ticket['id'];
} & RepositionTicketPayload;

export const useTicketPosition = () => {
  return useMutation({
    mutationFn: ({ id, otherId, placement }: MutationVariables) =>
      Actions.getAPIService().tickets().repositionTicket(id, {
        otherId,
        placement,
      }),
  });
};
