import type { SortingFn } from '@tanstack/table-core';
import { getTicketTypeObject } from 'constants/TicketTypeObject';
import type { TicketType } from 'entity/dossiers/types';

const isEmpty = (value: any) => value === null || value === undefined || Number.isNaN(value);
const getRowValues = (aRow: any, bRow: any, id: any) => [aRow.getValue(id), bRow.getValue(id)];
const withEmptyCheck = (cb: (a: any, b: any) => number) => (aRow: any, bRow: any, id: any) => {
  const [a, b] = getRowValues(aRow, bRow, id);
  if (isEmpty(a) && isEmpty(b)) return 0;
  if (isEmpty(a)) return -1;
  if (isEmpty(b)) return 1;
  return cb(a, b);
};

export const sortTypes = (): Record<string, SortingFn<any>> => ({
  date: withEmptyCheck((a: string, b: string) => {
    const aDate = new Date(a);
    const bDate = new Date(b);

    if (aDate > bDate) return 1;
    if (bDate > aDate) return -1;
    return 0;
  }),
  boolean: withEmptyCheck((a: boolean, b: boolean) => {
    if (a === b) return 0;
    if (a) return 1;
    return -1;
  }),
  arrayLength: withEmptyCheck((a: any[], b: any[]) => {
    if (a.length > b.length) return 1;
    if (b.length > a.length) return -1;
    return 0;
  }),
  ticketType: withEmptyCheck((a: TicketType, b: TicketType) => {
    const aLabel = getTicketTypeObject(a).label;
    const bLabel = getTicketTypeObject(b).label;
    return aLabel.localeCompare(bLabel);
  }),
});
