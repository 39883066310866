/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import './assets/styles/CardSectionLinkDossierLinkModal.scss';

import { Box, StatusMessage } from '@elseu/sdu-titan';
import { useMutation } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import ContactNoteLinkTable from 'components/contactnote/ContactNoteLinkTable';
import DossierLinkDossierTable from 'components/dossier/DossierLinkDossierTable';
import ConfirmModal from 'components/modal/ConfirmModal';
import SignalingSignalLinkTable from 'components/signaling/SignalingSignalLinkTable';
import React, { useEffect, useMemo, useReducer, useState } from 'react';

const enum CardSectionLinkDossierLinkTypes {
  NORMAL = 'NORMAL',
  SIGNAL = 'SIGNAL',
  CONTACT_NOTE = 'CONTACT_NOTE',
}

type Props = {
  type: CardSectionLinkDossierLinkTypes;
  clients: string[];
  dossierId: string;
  cardId?: string;
  onClose: () => void;
  onLinked: () => void;
  onBusy: (isBusy: boolean) => void;
};

const initialState = {
  show: false,
  type: 'UNKNOWN',
  clients: [],
};

export type LinkSelectorElementProps = {
  isDisabled?: boolean;
  clients: string[];
  dossierId: string;
  cardId?: string;
  onSelect: (cardId: string) => void;
};

type LinkSelectorType = {
  name: string;
  type: CardSectionLinkDossierLinkTypes;
  Component: React.FC<LinkSelectorElementProps>;
};

const linkTypes: LinkSelectorType[] = [
  {
    name: 'Dossier',
    type: CardSectionLinkDossierLinkTypes.NORMAL,
    Component: DossierLinkDossierTable,
  },
  {
    name: 'Adviessignalering',
    type: CardSectionLinkDossierLinkTypes.SIGNAL,
    Component: SignalingSignalLinkTable,
  },
  {
    name: 'Notitie',
    type: CardSectionLinkDossierLinkTypes.CONTACT_NOTE,
    Component: ContactNoteLinkTable,
  },
];

/**
 * Card section link reducer for display information
 *
 * @param {object} state
 * @param {object} action
 * @returns {{show: boolean, cardType: string, type: string}|(*&{show: boolean, cardType: string, type: string})}
 */
const cardSectionReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'show':
      return {
        ...initialState,
        ...(action.data || {}),
        show: true,
      };
    case 'hide':
      return {
        ...initialState,
      };
    default:
      throw new Error(`Action ${action.type} not defined.`);
  }
};

/**
 * Link two cards from different dossier types.
 */
const CardSectionLinkDossierLinkModal: React.FC<Props> = ({
  type,
  clients,
  dossierId,
  cardId,
  onClose,
  onLinked,
  onBusy,
}) => {
  // get need extra link data information from type prop.
  const linkTypeData: LinkSelectorType = useMemo(() => {
    const linkTypeIndex = linkTypes.map((linkType) => linkType.type).indexOf(type);
    return linkTypes[linkTypeIndex];
  }, [type]);

  const [selectedItem, setSelectedItem] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const createRelatedCardMutate = useMutation<unknown, unknown, string>({
    mutationFn: (value) =>
      Actions.getAPIService().createRelatedCard(dossierId, cardId ?? '', value),
    onSuccess: () => {
      onLinked();
      onBusy(false);
    },
    onError: () => {
      setErrorMessage('De id van geselecteerde object kan niet gevonden worden.');
    },
  });

  const onHandleLinkedEvent = async () => {
    setErrorMessage(undefined);
    if (selectedItem) {
      createRelatedCardMutate.mutate(selectedItem);
    }
  };

  const isBusy = createRelatedCardMutate.isPending;
  useEffect(() => {
    onBusy(isBusy);
  }, [isBusy, onBusy]);

  return (
    <ConfirmModal
      shouldUsePortal
      className="c-card-section-link-dossier-link-modal"
      confirmTitle="Koppelen"
      isConfirmDisabled={!selectedItem}
      isDisabled={isBusy}
      title={`Een ${linkTypeData.name.toLowerCase()} koppelen`}
      onCancel={onClose}
      onConfirm={() => onHandleLinkedEvent()}
    >
      {errorMessage && (
        <StatusMessage spaceAfter={4} type="danger">
          {errorMessage}
        </StatusMessage>
      )}
      <Box>
        <linkTypeData.Component
          isDisabled
          cardId={cardId}
          clients={clients}
          dossierId={dossierId}
          onSelect={(cardId) => setSelectedItem(cardId)}
        />
      </Box>
    </ConfirmModal>
  );
};

export default CardSectionLinkDossierLinkModal;

/**
 * Card section dossier link modal reducer
 *
 * @returns {*}
 */
export const useCardSectionLinkDossierLinkModal = () => {
  return useReducer(cardSectionReducer, initialState);
};
