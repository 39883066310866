import { useIsFetching, useMutation, useQueryClient } from '@tanstack/react-query';
import Actions from 'actions/Actions';
import SelectField from 'components/selectfield/SelectField';
import React from 'react';
import type JSendResponse from 'services/JSendResponse';

type SubOfficeLinkSelectItem = {
  value?: string | null;
  label?: string;
};

type SubOfficeLinkSelectProps = {
  isDisabled?: boolean;
  isMulti?: boolean;
  className?: string;
  isCreatable?: boolean;
  isClearable?: boolean;
  isAllOptionsShown?: boolean;
  isNotLinkedOptionShown?: boolean;
  errors?: Record<any, any> | boolean | string;
  selected?: any[] | any | null;
  onChange?: (value?: SubOfficeLinkSelectItem | SubOfficeLinkSelectItem[]) => void;
  [key: string]: any;
};

/**
 * SubOfficeLinkSelect
 *
 * Allow the end user to select a sub office instance, this will handle fetching of sub offices, searching and creation.
 *
 * @param {boolean} isDisabled
 * @param {boolean} isMulti
 * @param {string} className
 * @param {boolean} isCreatable
 * @param {boolean} isClearable
 * @param {boolean} isAllOptionsShown
 * @param {boolean} isNotLinkedOptionShown
 * @param {array|object} selected
 * @param {function} onChange
 * @param {object|string} errors
 * @return {JSX.Element}
 * @constructor
 */
const SubOfficeLinkSelect: React.FC<SubOfficeLinkSelectProps> = ({
  isDisabled,
  isMulti,
  className,
  isCreatable,
  isClearable,
  isAllOptionsShown,
  isNotLinkedOptionShown,
  selected,
  errors,
  onChange,
  ...otherProps
}) => {
  const queryClient = useQueryClient();
  const isFetchingCount = useIsFetching({
    queryKey: ['subofficeLinkSelect'],
  });
  const createSubOffice = useMutation({
    mutationFn: (value: string) => Actions.getAPIService().getCrm().createSubOffice(value),
    onSuccess: (response: any) => {
      if (response.status === 'success') {
        if (isMulti) {
          onChange?.([
            ...selected,
            {
              value: response.data.id,
              label: response.data.name,
            },
          ]);
        } else {
          onChange?.({
            value: response.data.id,
            label: response.data.name,
          });
        }
        queryClient.invalidateQueries({
          queryKey: ['subofficeLinkSelect'],
        });
      }
    },
  });
  const defaultSubOffice = isAllOptionsShown
    ? {
        value: null,
        label: 'Alle',
      }
    : undefined;

  const requestOptionsPromise = async (completeValue: string) => {
    const response: JSendResponse = await queryClient.fetchQuery({
      queryKey: ['subofficeLinkSelect', completeValue],
      queryFn: () => Actions.getAPIService().getCrm().subOffices(completeValue, '', 1, 50),

      // 5 seconds
      staleTime: 5000,
      gcTime: 5000,
    });
    const data = response.getData() as Record<string, any>;
    let retValue = (data.subOffices || []).map((subOfficeInner: any) => ({
      value: subOfficeInner.id,
      label: subOfficeInner.name,
    }));

    if (!completeValue) {
      if (isAllOptionsShown) {
        retValue = [
          {
            value: null,
            label: 'Alle',
          },
          ...retValue,
        ];
      }
      if (isNotLinkedOptionShown) {
        retValue = [
          {
            value: '<NULL>',
            label: 'Alle (zonder vestiging)',
          },
          ...retValue,
        ];
      }
    }

    return retValue;
  };

  let values: SubOfficeLinkSelectItem[] | SubOfficeLinkSelectItem | undefined;
  if (selected !== undefined) {
    if (isMulti && selected instanceof Array) {
      values = selected.map((subOffice: any) => {
        return {
          value: subOffice.id || subOffice.value,
          label: subOffice.name || subOffice.label,
        };
      });
    } else {
      values =
        selected !== null
          ? ({
              value: selected.id || selected.value,
              label: selected.name || selected.label,
            } as SubOfficeLinkSelectItem)
          : defaultSubOffice;
    }
  }

  const isLoadingSubOffices = isFetchingCount > 0 || createSubOffice.isPending;

  return (
    <SelectField
      {...otherProps}
      isAsync
      className={className}
      error={!!errors}
      isClearable={isClearable}
      isCreatable={isCreatable}
      isDisabled={isDisabled}
      isLoading={isLoadingSubOffices}
      isMulti={isMulti}
      requestOptionsPromise={requestOptionsPromise}
      value={values}
      onChange={(value) => {
        onChange?.(value !== null ? value : undefined);
      }}
      onCreate={(value) => createSubOffice.mutate(value)}
    />
  );
};

export default SubOfficeLinkSelect;
