import { Flex } from '@elseu/sdu-titan';
import { FormGroupHorizontal } from 'components/formgroup/FormGroupHorizontal';
import { useTicketStatuses } from 'containers/tickets/src/hooks/useTicketStatuses';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import type { TicketStatusSelectFieldProps } from '../../TicketStatusSelectField';
import TicketStatusSelectField from '../../TicketStatusSelectField';

export const TicketStatusFormField: React.FC<
  Omit<TicketStatusSelectFieldProps, 'availableLabels' | 'value' | 'onChange'>
> = (props) => {
  const { getStatusesFor } = useTicketStatuses();
  const availableLabels = getStatusesFor('task');
  const { control } = useFormContext();

  return (
    <FormGroupHorizontal label="Status" spaceAfter={6}>
      <Flex>
        <Controller
          control={control}
          name="status"
          render={({ field: { value, onChange } }) => (
            <TicketStatusSelectField
              availableLabels={availableLabels}
              hasPortal={false}
              value={value}
              onChange={(status) => onChange(status)}
              {...props}
            />
          )}
        />
      </Flex>
    </FormGroupHorizontal>
  );
};
