import React from 'react';

import { useLeavePromptState } from './hooks';
import { LeavePrompt } from './LeavePrompt';
import { LeavePromptContext } from './LeavePromptContext';

export const LeavePromptProvider = ({ children }: any) => {
  const reducer = useLeavePromptState();

  return (
    <LeavePromptContext.Provider value={reducer}>
      <LeavePrompt />
      {children}
    </LeavePromptContext.Provider>
  );
};
