import type { Ticket } from 'entity/dossiers/types';

/**
 * Formats the clients to a client label, ie "Client A + 2"
 *
 * @param clients
 */
export const clientsToClientLabel = (clients: Ticket['clients']): string | null => {
  if (clients.length === 0) {
    return null;
  }

  const [mainClient, ...otherClients] = clients;

  if (otherClients.length === 0) {
    return mainClient.name;
  }

  return `${mainClient.name} +${otherClients.length}`;
};
