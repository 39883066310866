import { useCallback, useEffect, useMemo, useState } from 'react';
import LocalStorageService from 'services/LocalStorageService';
import type { ApiFilter } from 'types/types';

type useFilterStateReturn = {
  filtersValues: ApiFilter[];
  filters: Record<string, any>;
  applyFilters: (values: Record<string, any>) => void;
  resetFilters: () => void;
  storeFilters: (values: Record<string, any>) => void;
};

type useFilterStateParams = {
  defaultFilters?: Record<string, any>;
  saveFilter?: boolean;
  filterName?: string;
};

// #region filter storage
/**
 * create store key based around store name
 *
 * @param {string} filterName
 * @returns
 */
const storeKey = (filterName: string) => `filter_${filterName}`;

/**
 * Store filter to the localstorage
 *
 * @param {string} filterName
 * @param {Record<string, any>} filters
 */
const storeFilter = (filterName: string, filters: Record<string, any>) => {
  const keyName = storeKey(filterName);
  LocalStorageService.setItem(keyName, JSON.stringify(filters));

  // max of 20 items can be save to the localstorage.
  let itemCount = 0;
  const maxItems = 10;
  Object.keys(LocalStorageService.getItems()).forEach((key) => {
    // only purge dossier_ items.
    if (key.startsWith('filter_')) {
      itemCount++;
      // make sure we don't purge the last item, and only purge if we have reached the
      // maxItems count.
      if (key !== keyName && itemCount >= maxItems) {
        LocalStorageService.removeItem(key);
      }
    }
  });
};

/**
 * Load filter from storage
 *
 * @param {string} filterName
 * @param {Record<string, any>} defaultFilters
 * @returns {Record<string, any>}
 */
const loadFilter = (
  filterName: string,
  defaultFilters: Record<string, any>,
): Record<string, any> => {
  const returnValue = LocalStorageService.getItem(storeKey(filterName), undefined);
  return returnValue ? JSON.parse(returnValue) : defaultFilters;
};
// #endregion

export const useFilterState = ({
  defaultFilters = [],
  saveFilter = false,
  filterName,
}: useFilterStateParams): useFilterStateReturn => {
  const [filters, setFilters] = useState<Record<string, any>>(
    filterName ? loadFilter(filterName, defaultFilters) : defaultFilters,
  );
  const [currentFilterName, setCurrentFilterName] = useState<string>(filterName ?? '');

  useEffect(() => {
    if (currentFilterName !== filterName) {
      setFilters(filterName ? loadFilter(filterName, defaultFilters) : defaultFilters);
      setCurrentFilterName(filterName ?? '');
    }
  }, [filterName, currentFilterName, defaultFilters]);

  const filtersValues: ApiFilter[] = useMemo(() => {
    const filterObjects = Object.keys(filters);
    const retValue: ApiFilter[] = [];
    filterObjects.forEach((key: string) => {
      if (filters[key] && filters[key] !== undefined) {
        if (Array.isArray(filters[key])) {
          if (filters[key].length > 0) {
            retValue.push({
              name: key,
              value: filters[key].join(','),
            });
          }

          return;
        }

        retValue.push({
          name: key,
          value: filters[key],
        });
      }
    });

    return retValue;
  }, [filters]);

  useEffect(() => {
    if (saveFilter && filterName) {
      storeFilter(filterName, filters);
    }
  }, [filters, saveFilter, filterName]);

  const applyFilters = useCallback((values: Record<string, any>) => setFilters(values), []);
  const resetFilters = useCallback(() => setFilters(defaultFilters), [defaultFilters]);
  const storeFilters = useCallback(
    (values: Record<string, any>) => {
      if (filterName) {
        storeFilter(filterName, values);
      }
    },
    [filterName],
  );

  return {
    filtersValues,
    filters,
    applyFilters,
    resetFilters,
    storeFilters,
  };
};
