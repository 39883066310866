import type { SelectOptionProps } from '@elseu/sdu-titan';
import { Avatar } from '@elseu/sdu-titan';
import type { SelectFieldProps } from 'components/formfields/SelectField';
import { SelectField } from 'components/formfields/SelectField';
import type { User } from 'entity/me/types';
import { useProfileSettings } from 'hooks';
import React from 'react';

export const UserSelectField = React.memo(function (
  props: Omit<SelectFieldProps, 'isSearchable' | 'options'>,
) {
  const [, { getUsers }] = useProfileSettings();
  const userOptions: SelectOptionProps[] = getUsers().map((user: User) => ({
    value: user.id,
    label: user.fullName,
    description: user.email,
    prefixAdornment: <Avatar size={32} src={user.avatarUrl} />,
  }));

  return (
    <SelectField isSearchable buttonVariant="clear" options={userOptions} {...(props as any)} />
  );
});
