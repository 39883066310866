import { Box, Button, Drawer, DrawerContainer, DrawerContent, Text } from '@elseu/sdu-titan';
import React, { useState } from 'react';
import styled from 'styled-components';

import type { IPermission, IPermissionOption } from './helpers';
import { IPermissionRole, optionsToPermissions, permissionsToOptions } from './helpers';
import { PermissionRoleSelect } from './PermissionRoleSelect';
import { PermissionUserSelect } from './PermissionUserSelect';

const StyledFooter = styled.div`
  display: flex;
  gap: 1rem;
  margin-left: auto;
`;

type IPermissionEntitlementDrawer = {
  isDisabled: boolean;
  defaultRole?: IPermissionRole.ROLE_VIEWER;
  disabledRoles?: IPermissionRole[];
  permissions: IPermission[];
  userLabel?: string;
  userPlaceholder?: string;
  userNoResultsMessage?: string;
  header?: string;
  subtitle?: string;

  onSave: (permission: IPermission[]) => void;
  onClose: () => void;
};

export const PermissionDrawer = ({
  isDisabled = false,
  defaultRole = IPermissionRole.ROLE_VIEWER,
  disabledRoles = [],
  permissions,
  userLabel = 'Gebruikers en groepen',
  userPlaceholder = 'Selecteer één of meerdere gebruikers en of groepen',
  userNoResultsMessage = 'Geen gebruikers of groepen gevonden',
  header = 'Gebruikers en groepen',
  subtitle = 'Selecteer hieronder één of meerdere groepen om toe te voegen. De rol bepaalt welke bevoegdheden zij ' +
    'zullen krijgen.',
  onSave,
  onClose,
}: IPermissionEntitlementDrawer) => {
  const [selected, setSelected] = useState<IPermissionOption[]>([]);
  const [role, setRole] = useState<IPermissionRole>(defaultRole);
  const isValid = selected.length > 0;

  return (
    <Drawer isShown={true} position="right" width={480} onClose={onClose}>
      <DrawerContainer
        footer={
          <StyledFooter>
            <Button isDisabled={isDisabled} variant="clear" onClick={onClose}>
              Annuleren
            </Button>
            <Button
              isDisabled={isDisabled || !isValid}
              onClick={() => onSave(optionsToPermissions(selected, role))}
            >
              Opslaan
            </Button>
          </StyledFooter>
        }
        header={header}
        subtitle={subtitle}
      >
        <DrawerContent>
          <Box spaceAfter={8}>
            <Text isBlock={true} spaceAfter={3} type="labelBold">
              {userLabel}
            </Text>
            <PermissionUserSelect
              isDisabled={isDisabled}
              isMenuPortalActive={false}
              noResultsMessage={userNoResultsMessage}
              options={permissionsToOptions(permissions)}
              placeholder={userPlaceholder}
              selected={selected}
              onChange={(values) => setSelected(values)}
            />
          </Box>
          <Box spaceAfter={8}>
            <Text isBlock={true} spaceAfter={3} type="labelBold">
              Rol
            </Text>
            <PermissionRoleSelect
              disabledRoles={disabledRoles}
              isDisabled={isDisabled}
              role={role}
              onChange={setRole}
            />
          </Box>
        </DrawerContent>
      </DrawerContainer>
    </Drawer>
  );
};

export default PermissionDrawer;
