import './assets/styles/LabelSelect.scss';

import classNames from 'classnames';
import SelectField from 'components/selectfield/SelectField';
import { createColorSwatch } from 'helpers/colorhelper';
import React from 'react';

import { useColorConverter } from '../../hooks/useColorConverter';

type Props = {
  className?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  value?: any;
  options?: any[];
  isFloating?: boolean;
  hasShadow?: boolean;
  placeholder?: string;
  onChange: (item: any) => void;
  [key: string]: any;
};
/**
 * Phase select option renderer.
 *
 * @param {object} item
 * @returns {*}
 * @constructor
 */
const LabelSelectOption = (item: any) => {
  const { data } = item;
  const { label, color } = data;
  const { statusColorToHex } = useColorConverter();

  const colorSwatch = createColorSwatch(statusColorToHex(color));
  const optionStyle: Record<string, any> = {
    '--phase-select-option-background-color': colorSwatch.color,
    '--phase-select-option-border-color': colorSwatch.border,
  };

  return (
    <div className="c-phase-select__option" style={optionStyle}>
      <div className="c-phase-select__option__color" />
      <div className="c-phase-select__option__label">{label}</div>
    </div>
  );
};

/**
 * Phase select component
 *
 * @param {string} className
 * @param {boolean} isDisabled
 * @param {boolean} isReadOnly
 * @param {object} value
 * @param {array} options
 * @param {boolean} isFloating
 * @param {string} placeholder
 * @param {boolean} hasShadow
 * @param {function} onChange
 * @param {object} otherProps
 * @returns {*}
 * @constructor
 */
const LabelSelect: React.FC<Props> = ({
  className,
  isDisabled,
  isReadOnly,
  value = undefined,
  options = [],
  isFloating,
  hasShadow,
  placeholder,
  onChange = () => null,
  ...otherProps
}) => {
  // clone object
  const { statusColorToHex } = useColorConverter();

  const color = statusColorToHex(value?.color, 'light');
  const colorSwatch =
    color !== '#ffffff'
      ? createColorSwatch(color)
      : {
          color: '#ffffff',
          border: '#e5e5e5',
          borderFocus: '#4a90e2',
          text: '#000000',
        };
  const styles = {
    control: (provided: any) => ({
      ...provided,
      opacity: isDisabled ? '0.5' : '1',
      borderRadius: '2px',
      borderWidth: '1px',
      boxShadow: 'none !important',
      borderColor: `${colorSwatch.border} !important`,
      backgroundColor: `${colorSwatch.color} !important`,
      color: `${colorSwatch.text} !important`,
      '&:hover': {
        borderColor: `${colorSwatch.borderFocus} !important`,
        boxShadow: 'none',
      },
      '&--is-focused': {
        borderColor: `${colorSwatch.borderFocus} !important`,
        boxShadow: '0 3px 7px rgba(0, 0, 0, 0.25)',
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: colorSwatch.text,
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      display: 'flex',
    }),
  };

  return (
    <div
      className={classNames('c-phase-select', className, {
        'is-floating': isFloating,
        'has-shadow': hasShadow,
        disabled: isDisabled,
      })}
      style={
        {
          '--select-color-field-text-color': colorSwatch.text,
        } as Record<string, any>
      }
    >
      <SelectField
        {...otherProps}
        className="c-phase-select__select"
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        isSearchable={false}
        optionRenderer={(item) => LabelSelectOption(item)}
        options={options}
        placeholder={placeholder || 'Geen status'}
        styles={styles}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default LabelSelect;
