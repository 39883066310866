import type { SpaceProp } from '@elseu/sdu-titan';
import { Flex, Tag } from '@elseu/sdu-titan';
import { getTicketTypeObject } from 'constants/TicketTypeObject';
import type { TicketType } from 'entity/dossiers/types';
import React from 'react';

interface TicketTypeTagProps {
  ticketType: TicketType;
  /** The margin bottom of the component */
  spaceAfter?: SpaceProp;
}
export const TicketTypeTag = ({ ticketType, spaceAfter }: TicketTypeTagProps) => {
  const ticketTypeObject = getTicketTypeObject(ticketType);
  return (
    <Tag spaceAfter={spaceAfter} status={ticketTypeObject.status}>
      <Flex alignItems="center" gap={2}>
        {ticketTypeObject.icon} {ticketTypeObject.label}
      </Flex>
    </Tag>
  );
};
